import React, {useEffect, useRef, useState} from 'react';
import {Tab, Tabs} from "react-bootstrap";
import {BsSquareFill} from "react-icons/bs";
import axios from "axios";
import {BiCaretDown} from "react-icons/bi";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import AdminYmdEvent from "../../components/web/layoutsadmin/AdminYmdEvent";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import AdminSearchEvent from "../../components/web/layoutsadmin/AdminSearchEvent";
import {MdKeyboardArrowDown} from "react-icons/md";
import maCateImg from "../../assets/img/m-a-cate.png";

function AdminDepositSetPg() {

    /** region DataTable */

    const [depHistoryList, setDepHistoryList] = useState([]);
    const [ptHistoryList, setPtHistoryList] = useState([]);
    const [cpHistoryList, setCpHistoryList] = useState([]);
    const [depConfirmHistoryList, setDepConfirmHistoryList] = useState([]);
    const token = localStorage.getItem('adminToken');

    /** endregion */

    /** region Function */

    const [tabSt, setTabSt] = useState('');

    const handleSelectTabs = ((key) => {
        setTabSt(key);
    })

    //region ScrollPagination

    const [displayLimitDep, setDisplayLimitDep] = useState(10);
    const [displayLimitPt, setDisplayLimitPt] = useState(10);
    const [displayLimitCp, setDisplayLimitCp] = useState(10);
    const [displayLimitDepCon, setDisplayLimitDepCon] = useState(10);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;

        if (depHistoryList.length > displayLimitDep && displayLimitDep < 30) {
            setDisplayLimitDep(prevLimit => prevLimit + perPg);
        }
        if (ptHistoryList.length > displayLimitPt && displayLimitPt < 30) {
            setDisplayLimitPt(prevLimit => prevLimit + perPg);
        }
        if (cpHistoryList.length > displayLimitCp && displayLimitCp < 30) {
            setDisplayLimitCp(prevLimit => prevLimit + perPg);
        }
        if (depConfirmHistoryList.length > displayLimitDepCon && displayLimitDepCon < 30) {
            setDisplayLimitDepCon(prevLimit => prevLimit + perPg);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => window.removeEventListener('scroll', handleScroll);
    }, [depHistoryList.length, displayLimitDep, ptHistoryList.length, displayLimitPt, cpHistoryList.length, displayLimitCp, depConfirmHistoryList.length, displayLimitDepCon]);

    const loadMoreData = () => {
        setDisplayLimitDep(prevLimit => prevLimit + perPg);
        setDisplayLimitPt(prevLimit => prevLimit + perPg);
        setDisplayLimitCp(prevLimit => prevLimit + perPg);
        setDisplayLimitDepCon(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e.target.value);
        setperPg(newPerPage);
        setDisplayLimitDep(newPerPage);
        setDisplayLimitPt(newPerPage);
        setCurrentPage(1);
    };

    useEffect(() => {
        setDisplayLimitDep(perPg);
        setDisplayLimitPt(perPg);
    }, [perPg]);

    // endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);
    const [checkedPoint, setCheckedPoint] = useState([]);
    const [checkedCp, setCheckedCp] = useState([]);

    const checkBoxDepChange = (id) => {
        setChecked((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter((item) => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    const allcheckBoxDepChange = (e) => {
        let visibleData = searchDepData.slice(0, displayLimitDep);
        if (e.target.checked) {
            const visibleIds = visibleData.map((item) => item.seqNum);
            setChecked(visibleIds);
        } else {
            setChecked([]);
        }
    };

    const checkBoxPointChange = (id) => {
        setCheckedPoint((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter((item) => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    const allcheckBoxPointChange = (e) => {
        let visibleData = searchPtData.slice(0, displayLimitPt);
        if (e.target.checked) {
            const visibleIds = visibleData.map((item) => item.seqNum);
            setCheckedPoint(visibleIds);
        } else {
            setCheckedPoint([]);
        }
    };

    const checkBoxCpChange = (id) => {
        setCheckedCp((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter((item) => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    const allcheckBoxCpChange = (e) => {
        let visibleData = searchCpData.slice(0, displayLimitCp);
        if (e.target.checked) {
            const visibleIds = visibleData.map((item) => item.seqNum);
            setCheckedCp(visibleIds);
        } else {
            setCheckedCp([]);
        }
    };

    //endregion

    //region Search

    const [searchInput, setSearchInput] = useState('');
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const searchDepData = depHistoryList.filter((cData) => {
        return (
            cData?.memberDTO?.userName.toLowerCase().includes(searchInput.toLowerCase()) ||
            cData?.memberDTO?.id.toLowerCase().includes(searchInput.toLowerCase())
        );
    });

    const searchPtData = ptHistoryList.filter((cData) => {
        return (
            cData?.memberDTO?.userName.toLowerCase().includes(searchInput.toLowerCase()) ||
            cData?.memberDTO?.id.toLowerCase().includes(searchInput.toLowerCase())
        );
    });

    const searchCpData = cpHistoryList.filter((cData) => {
        return (
            cData?.memberDTO?.userName.toLowerCase().includes(searchInput.toLowerCase()) ||
            cData?.memberDTO?.id.toLowerCase().includes(searchInput.toLowerCase())
        );
    });

    const searchDepConData = depConfirmHistoryList.filter((cData) => {
        return (
            cData?.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
            cData?.userId?.toLowerCase().includes(searchInput.toLowerCase())
        );
    });

    //endregion

    // 예치금 내역조회
    const [depStartDate, setDepStartDate] = useState('');
    const [depEndDate, setDepEndDate] = useState('');
    const depStartHandle = (e) => {
        setDepStartDate(e.target.value);
    };
    const depEndHandle = (e) => {
        setDepEndDate(e.target.value);
    };

    // 적립금 내역조회
    const [ptStartDate, setPtStartDate] = useState('');
    const [ptEndDate, setPtEndDate] = useState('');
    const ptStartHandle = (e) => {
        setPtStartDate(e.target.value);
    };
    const ptEndHandle = (e) => {
        setPtEndDate(e.target.value);
    };

    // 쿠폰 내역조회
    const [cpStartDate, setCpStartDate] = useState('');
    const [cpEndDate, setCpEndDate] = useState('');
    const cpStartHandle = (e) => {
        setCpStartDate(e.target.value);
    };
    const cpEndHandle = (e) => {
        setCpEndDate(e.target.value);
    };

    // 예치금신청 내역조회
    const [depConfirmStartDate, setDepConfirmStartDate] = useState('');
    const [depConfirmEndDate, setDepConfirmEndDate] = useState('');
    const depConfirmStartHandle = (e) => {
        setDepConfirmStartDate(e.target.value);
    };
    const depConfirmEndHandle = (e) => {
        setDepConfirmEndDate(e.target.value);
    };

    // 예치금 & 적립금 공통지급
    const [selectedDepSerchCondition, setSelectedDepSerchCondition] = useState('userNm');
    const [depMemberInput, setDepMemberInput] = useState('');
    const [serchDepMemberList, setSerchDepMemberList] = useState([]);
    const [selectedDepMember, setSelectedDepMember] = useState(null);
    const [depositPayContent, setDepositPayContent] = useState('');
    const [depSumFl, setDepSumFl] = useState('');
    const [depositPrice, setDepositPrice] = useState('');

    const selectChangeDepCondition = (event) => {
        const value = event.target.value;
        if (value === '회원명') {
            setSelectedDepSerchCondition("userNm");
        } else if (value === '아이디') {
            setSelectedDepSerchCondition("userId");
        }
    };

    const depMemberInputChange = (e) => {
        setDepMemberInput(e.target.value);
    };


    const depositSerchKeyPress = (e) => {
        if (e.key === 'Enter') {
            selectDepositSerchMember();
        }
    };

    const selectedDepMemberChange = ((e) => {
        setSelectedDepMember(e.target.value);
    })

    const depPayContentInputChange = (e) => {
        setDepositPayContent(e.target.value);
    };

    const selectDepositSumFlChange = (event) => {
        setDepSumFl(event.target.value === 'plus' ? '2' : '3');
    };

    const depositPriceChange = (e) => {
        setDepositPrice(e.target.value);
    };

    useEffect(() => {
        selectAllMstCp();

        function handleClickOutside(event) {
            if (indiviCpUserNmRef.current && !indiviCpUserNmRef.current.contains(event.target)) {
                setIndiviCpUserNmShow(false);
            }
            if (indiviCpReturnMemberRef.current && !indiviCpReturnMemberRef.current.contains(event.target)) {
                setIndiviCpReturnMemberShow(false);
            }
            if (indiviCpListRef.current && !indiviCpListRef.current.contains(event.target)) {
                setIndiviCpListShow(false);
            }
            if (batchCpListRef.current && !batchCpListRef.current.contains(event.target)) {
                setBatchCpListShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // 예치금 type 커스텀 셀렉트
    const selectedDepTypeList =[
        { value: '4', label: "구분" },
        { value: '1', label: "사용" },
        { value: '0', label: "취소" },
        { value: '2', label: "지급" },
        { value: '3', label: "회수" }
    ];
    const [selectedDepType, setSelectedDepType] = useState(selectedDepTypeList[0]);
    const [selectedDepTypeShow, setSelectedDepTypeShow] = useState(false);
    const selectedDepTypeRef = useRef(null);

    const toggleDepTypeShow = () => {
        setSelectedDepTypeShow(!selectedDepTypeShow);
    };

    const handleDepTypeChange = (type) => {
        setSelectedDepType(type);
        setSelectedDepTypeShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (selectedDepTypeRef.current && !selectedDepTypeRef.current.contains(event.target)) {
                setSelectedDepTypeShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트

    // 적립금 type 커스텀 셀렉트
    const selectedPtTypeList = [
        { value: '4', label: "구분" },
        { value: '1', label: "사용" },
        { value: '0', label: "취소" },
        { value: '2', label: "지급" },
        { value: '3', label: "회수" }
    ];
    const [selectedPtType, setSelectedPtType] = useState(selectedPtTypeList[0]);
    const [selectedPtTypeShow, setSelectedPtTypeShow] = useState(false);
    const selectedPtTypeRef = useRef(null);

    const togglePtTypeShow = () => {
        setSelectedPtTypeShow(!selectedPtTypeShow);
    };

    const handlePtTypeChange = (type) => {
        setSelectedPtType(type);
        setSelectedPtTypeShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (selectedPtTypeRef.current && !selectedPtTypeRef.current.contains(event.target)) {
                setSelectedPtTypeShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트

    // 쿠폰 개별지급
    const selectedIndiviSerchCondition = ["회원명", "아이디"];
    const [selectedCpSerchCondition, setSelectedCpSerchCondition] = useState('userNm');
    const [cpMemberInput, setCpMemberInput] = useState('');
    const [serchCpMemberList, setSerchCpMemberList] = useState([]);
    const [selectedIndiviCpMemberId, setSelectedIndiviCpMemberId] = useState(null);
    const [selectedIndiviCpMemberNm, setSelectedIndiviCpMemberNm] = useState(null);

    const cpMemberInputChange = (e) => {
        setCpMemberInput(e.target.value);
    };
    const cpSerchKeyPress = (e) => {
        if (e.key === 'Enter') {
            selectCpSerchMember();
        }
    };

    const toggleIndiviCpUserNmShow = () => {
        setIndiviCpUserNmShow(!indiviCpUserNmShow);
    };
    const toggleIndiviCpReturnMemberShow = () => {
        setIndiviCpReturnMemberShow(!indiviCpReturnMemberShow);
    };
    const selectedCpMemberChange = ((member) => {
        setSelectedIndiviCpMemberId(member.id);
        setSelectedIndiviCpMemberNm(member.userName);
        setIndiviCpReturnMemberShow(false);
    })
    const handleIndiviConditionChange = (value) => {
        setSelectedCpSerchCondition(value === "회원명" ? "userNm" : "userId");
        setIndiviCpUserNmShow(false);
    };

    // 개별쿠폰 조회 & 선택
    const [serchIndiviCpList, setSerchIndiviCpList] = useState([]);
    const [selectedIndiviCpNm, setSelectedIndiviCpNm] = useState(null);
    const [selectedIndiviCpPrice, setSelectedIndiviCpPrice] = useState(null);
    const [selectedIndiviCpSeqnum, setSelectedIndiviCpSeqnum] = useState(null);

    const [indiviCpListShow, setIndiviCpListShow] = useState(false);
    const indiviCpListRef = useRef(null);

    const toggleIndiviCpListShow = () => {
        setIndiviCpListShow(!indiviCpListShow);
    };

    const selectedCpListChange = ((member) => {
        setSelectedIndiviCpSeqnum(member.seqNum);
        setSelectedIndiviCpNm(member.cpNm);
        setSelectedIndiviCpPrice(member.cpPrice);
        setIndiviCpListShow(false);
    })

    const indiviCpInit = (() => {
        setSelectedCpSerchCondition('userNm');
        setCpMemberInput('');
        setSerchCpMemberList([]);
        setSelectedIndiviCpMemberId(null);
        setSelectedIndiviCpMemberNm(null);
    })

    // 일괄쿠폰 지급&삭제
    const [serchBatchCpList, setSerchBatchCpList] = useState([]);
    const [selectedBatchCpNm, setSelectedBatchCpNm] = useState(null);
    const [selectedBatchCpPrice, setSelectedBatchCpPrice] = useState(null);
    const [selectedBatchCpSeqnum, setSelectedBatchCpSeqnum] = useState(null);

    const [batchCpListShow, setBatchCpListShow] = useState(false);
    const batchCpListRef = useRef(null);

    const [deleteCpShowSt, setDeleteCpShowSt] = useState(false);
    const [minusBatchCpShowSt, setMinusBatchCpShowSt] = useState(false);
    const [plusBatchCpShowSt, setPlusBatchCpShowSt] = useState(false);

    const deleteCpMsg1 = "선택한 쿠폰을 삭제하시겠습니까?"
    const deleteCpMsg2 = "모든 고객님들에게서 삭제됩니다"
    const minusBatchCpMsg1 = "선택한 쿠폰을 회수하시겠습니까?"
    const minusBatchCpMsg2 = "모든 고객님들에게서 회수됩니다"
    const plusBatchCpMsg1 = "선택한 쿠폰을 지급하시겠습니까?"
    const plusBatchCpMsg2 = "모든 고객님들에게 지급됩니다"

    const closeDeleteCpShow = (() => {
        setDeleteCpShowSt(false);
    })
    const closeMinusBatchCpShow = (() => {
        setMinusBatchCpShowSt(false);
    })
    const closePlusBatchCpShow = (() => {
        setPlusBatchCpShowSt(false);
    })

    const toggleBatchCpListShow = () => {
        setBatchCpListShow(!batchCpListShow);
    };

    const selectedBatchCpListChange = ((member) => {
        setSelectedBatchCpNm(member.seqNum);
        setSelectedBatchCpNm(member.cpNm);
        setSelectedBatchCpPrice(member.cpPrice);
        setBatchCpListShow(false);
    })

    const [newCpNm, setNewCpNm] = useState('');
    const [newCpPrice, setNewCpPrice] = useState('');
    const [newCpExpDate, setNewCpExpDate] = useState('');

    const handleNewCpNm = ((e) => {
        setNewCpNm(e.target.value);
    })

    const handleNewCpPrice = ((e) => {
        setNewCpPrice(e.target.value);
    })

    const handleNewCpExpDate = ((e) => {
        const input = e.target.value;

        if (/^\d{8}$/.test(input)) {
            const year = parseInt(input.substring(0, 4));
            const month = parseInt(input.substring(4, 6));
            const day = parseInt(input.substring(6, 8));
            const date = new Date(year, month - 1, day);

            if (date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day) {
                const today = new Date();
                const todayFormatted = new Date(today.getFullYear(), today.getMonth(), today.getDate());

                if (date >= todayFormatted) {
                    setNewCpExpDate(input);
                } else {
                    alert('날짜가 오늘보다 이전일 수 없습니다.');
                }
            } else {
                alert('올바른 날짜 형식이 아닙니다.[520]');
            }
        } else if (e.length === 8) {
            alert('올바른 날짜 형식이 아닙니다.[521]');
        } else {
            setNewCpExpDate(input);
        }
    })

    const newCpInit = (() => {
        setNewCpNm('');
        setNewCpPrice('');
        setNewCpExpDate('');
    })

    const formatDate = (dateStr) => {
        if (dateStr?.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }
    const [selectDepConfirmAllCheckBox, setSelectDepConfirmAllCheckBox] = useState(false);
    const [selectDepConfirmCheckData, setSelectDepConfirmCheckData] = useState([]);

    const handleSelectDepConfirmAllCheckBox = e => {
        setSelectDepConfirmAllCheckBox(e.target.checked);
        if (e.target.checked) {
            const filtered = depConfirmHistoryList.filter(dep => dep.status === "0");
            setSelectDepConfirmCheckData(filtered);
        } else {
            setSelectDepConfirmCheckData([]);
        }
    };

    const handleSelectDepConfirmCheckBox = dep => {
        if (selectDepConfirmCheckData.some(item => item.seqNum === dep.seqNum)) {
            setSelectDepConfirmCheckData(selectDepConfirmCheckData.filter(item => item.seqNum !== dep.seqNum));
        } else {
            setSelectDepConfirmCheckData([...selectDepConfirmCheckData, dep]);
        }
    };

    // 쿠폰 type 커스텀 셀렉트
    const selectedCpTypeList = [
        { value: '4', label: "구분" },
        { value: '1', label: "사용" },
        { value: '0', label: "취소" },
        { value: '2', label: "지급" },
        { value: '3', label: "회수" }
    ];
    const [selectedCpType, setSelectedCpType] = useState(selectedCpTypeList[0]);
    const [selectedCpTypeShow, setSelectedCpTypeShow] = useState(false);
    const selectedCpTypeRef = useRef(null);

    const toggleCpTypeShow = () => {
        setSelectedCpTypeShow(!selectedCpTypeShow);
    };

    const handleCpTypeChange = (type) => {
        setSelectedCpType(type);
        setSelectedCpTypeShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (selectedCpTypeRef.current && !selectedCpTypeRef.current.contains(event.target)) {
                setSelectedCpTypeShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트

    /** endregion */

    /** region Event */

    useEffect(() => {
        handleSelectTabs("예치금");
    }, [])

    useEffect(() => {
        switch (tabSt) {
            case "예치금":
                selectDepHistoryList();
                break;
            case "적립금":
                selectPtHistoryList();
                break;
            case "쿠폰":
                selectCpHistoryList()
                break;
            case "예치금신청":
                selectedAllDepHistory()
                break;
            default:
        }
    }, [tabSt]);

    useEffect(() => {
        const today = new Date();
        const oneMonthAgo = new Date(today);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const formatDateString = (date) => {
            return date.toISOString().split('T')[0];
        };

        setPtStartDate(formatDateString(oneMonthAgo));
        setPtEndDate(formatDateString(today));
    }, []);

    useEffect(() => {
        if (ptStartDate && ptEndDate && tabSt === "적립금") {
            selectPtHistoryList();
        }
    }, [ptStartDate, ptEndDate, tabSt, selectedPtType])

    const selectPtHistoryList = (() => {
        const formattedStartDate = ptStartDate.replace(/-/g, '');
        const formattedEndDate = ptEndDate.replace(/-/g, '');
        axios({
            method: 'get',
            url: '/member/selectPtHistoryList',
            params: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                tabSt: tabSt,
                type: selectedPtType.value
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.seqNum - a.seqNum);
                setPtHistoryList(sortedData)
            })
            .catch(() => {
                console.log("실패")
            })
    })

    useEffect(() => {
        const today = new Date();
        const oneMonthAgo = new Date(today);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const formatDateString = (date) => {
            return date.toISOString().split('T')[0];
        };

        setCpStartDate(formatDateString(oneMonthAgo));
        setCpEndDate(formatDateString(today));
    }, []);

    useEffect(() => {
        if (cpStartDate && cpEndDate && tabSt === "쿠폰") {
            selectCpHistoryList();
        }
    }, [cpStartDate, cpEndDate, tabSt, selectedCpType])

    const selectCpHistoryList = (() => {
        const formattedStartDate = cpStartDate.replace(/-/g, '');
        const formattedEndDate = cpEndDate.replace(/-/g, '');
        axios({
            method: 'get',
            url: '/member/selectCpHistoryList',
            params: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                tabSt: tabSt,
                type: selectedCpType.value
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.seqNum - a.seqNum);
                setCpHistoryList(sortedData)
            })
            .catch(() => {
                console.log("실패")
            })
    })

    useEffect(() => {
        const today = new Date();
        const oneMonthAgo = new Date(today);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const formatDateString = (date) => {
            return date.toISOString().split('T')[0];
        };

        setDepConfirmStartDate(formatDateString(oneMonthAgo));
        setDepConfirmEndDate(formatDateString(today));
    }, []);

    useEffect(() => {
        if (depConfirmStartDate && depConfirmEndDate && tabSt === "예치금신청") {
            selectedAllDepHistory();
        }
    }, [depConfirmStartDate, depConfirmEndDate, tabSt])

    const selectedAllDepHistory = (() => {
        const formattedStartDate = depConfirmStartDate.replace(/-/g, '');
        const formattedEndDate = depConfirmEndDate.replace(/-/g, '');
        axios({
            method: 'get',
            url: '/member/selectedAllDepHistory',
            params: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.seqNum - a.seqNum);
                setDepConfirmHistoryList(sortedData)
            })
            .catch(() => {
                console.log("실패")
            })
    })

    const selectAllMstCp = (() => {
        axios({
            method: 'get',
            url: '/coupon/selectAllMstCp',
        })

            .then((response) => {
                const cp = response.data.map(cp => ({
                    seqNum: cp.seqNum,
                    cpNm: cp.couponNm,
                    cpPrice: cp.couponPrice,
                }));
                setSerchIndiviCpList(cp);
                setSelectedIndiviCpSeqnum(response.data[0].seqNum);
                setSelectedIndiviCpNm(response.data[0].couponNm);
                setSelectedIndiviCpPrice(response.data[0].couponPrice);

                setSerchBatchCpList(cp);
                setSelectedBatchCpSeqnum(response.data[0].seqNum);
                setSelectedBatchCpNm(response.data[0].couponNm);
                setSelectedBatchCpPrice(response.data[0].couponPrice);
            })
            .catch(() => {
                console.log("쿠폰조회 실패")
            })
    })

    const selectDepositSerchMember = (() => {
        axios({
            method: 'get',
            url: '/member/selectSerchMember',
            params: {
                condition: selectedDepSerchCondition,
                memberInput: depMemberInput
            }
        })

            .then((response) => {
                const members = response.data.map(member => ({
                    id: member.id,
                    userName: member.userName
                }));
                setSerchDepMemberList(members);
                setSelectedDepMember(response.data[0].id);
            })
            .catch(() => {
                console.log("멤버조회 실패")
            })
    })

    const depositInfoInit = (() => {
        setSelectedDepSerchCondition('userNm');
        setSerchDepMemberList([]);
        setDepMemberInput('')
        setSelectedDepMember(null);
        setDepositPayContent('');
        setDepSumFl('2');
        setDepositPrice('');
    })

    const saveDeposit = (() => {
        axios({
            method: 'post',
            url: '/member/saveDeposit',
            headers: {
                Authorization: token
            },
            data: {
                content: depositPayContent,
                depPrice: tabSt === "예치금" ? depositPrice : 0,
                ptPrice: tabSt === "적립금" ? depositPrice : 0,
                type: depSumFl,
            },
            params: {
                email: selectedDepMember,
                depFl: tabSt === "예치금"
            }
        })
            .then(() => {
                if (tabSt === "예치금") {
                    selectDepHistoryList();
                } else {
                    selectPtHistoryList();
                }
            })
            .catch(() => {
                console.log("지급실패")
            })
    })

    useEffect(() => {
        const today = new Date();
        const oneMonthAgo = new Date(today);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const formatDateString = (date) => {
            return date.toISOString().split('T')[0];
        };

        setDepStartDate(formatDateString(oneMonthAgo));
        setDepEndDate(formatDateString(today));
    }, []);

    useEffect(() => {
        if (depStartDate && depEndDate && tabSt === "예치금") {
            selectDepHistoryList();
        }
    }, [depStartDate, depEndDate, tabSt, selectedDepType])

    const selectDepHistoryList = (() => {
        const formattedStartDate = depStartDate.replace(/-/g, '');
        const formattedEndDate = depEndDate.replace(/-/g, '');
        axios({
            method: 'get',
            url: '/member/selectDepHistoryList',
            params: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                tabSt: tabSt,
                type: selectedDepType.value
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.seqNum - a.seqNum);
                setDepHistoryList(sortedData)
            })
            .catch(() => {
                console.log("실패")
            })
    })

    const selectCpSerchMember = (() => {
        axios({
            method: 'get',
            url: '/member/selectSerchMember',
            params: {
                condition: selectedCpSerchCondition,
                memberInput: cpMemberInput
            }
        })

            .then((response) => {
                const members = response.data.map(member => ({
                    id: member.id,
                    userName: member.userName
                }));
                setSerchCpMemberList(members);
                setSelectedIndiviCpMemberId(response.data[0].id);
                setSelectedIndiviCpMemberNm(response.data[0].userName);

            })
            .catch(() => {
                console.log("멤버조회 실패")
            })
    })

    const [indiviCpUserNmShow, setIndiviCpUserNmShow] = useState(false);
    const [indiviCpReturnMemberShow, setIndiviCpReturnMemberShow] = useState(false);

    const indiviCpUserNmRef = useRef(null);
    const indiviCpReturnMemberRef = useRef(null);

    useEffect(() => {
        selectAllMstCp();

        function handleClickOutside(event) {
            if (indiviCpUserNmRef.current && !indiviCpUserNmRef.current.contains(event.target)) {
                setIndiviCpUserNmShow(false);
            }
            if (indiviCpReturnMemberRef.current && !indiviCpReturnMemberRef.current.contains(event.target)) {
                setIndiviCpReturnMemberShow(false);
            }
            if (indiviCpListRef.current && !indiviCpListRef.current.contains(event.target)) {
                setIndiviCpListShow(false);
            }
            if (batchCpListRef.current && !batchCpListRef.current.contains(event.target)) {
                setBatchCpListShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const saveIndiviCp = ((type) => {
        axios({
            method: 'post',
            url: '/coupon/saveIndiviCp',
            headers: {
                Authorization: token
            },
            params: {
                memberId: selectedIndiviCpMemberId,
                cpSeqnum: selectedIndiviCpSeqnum,
                type: type
            }
        })
            .then(() => {
                selectCpHistoryList();
                indiviCpInit();
            })
            .catch(() => {
                console.log("쿠폰조회 실패")
            })
    })

    const [selectedBatchCp, setSelectedBatchCp] = useState(null);

    const handleBatchCpSelect = (cp) => {
        setSelectedBatchCp(cp);  // 선택한 쿠폰을 상태에 저장
        setBatchCpListShow(false);
    };

    const saveBatchCp = (sumFl) => {
        if (!selectedBatchCp) {
            console.log("선택된 쿠폰이 없습니다.");
            return;
        }

        axios({
            method: 'post',
            url: '/coupon/saveBatchCp',
            headers: {
                Authorization: token
            },
            params: {
                cpSeqnum: selectedBatchCp.seqNum,
                sumFl: sumFl
            }
        })
            .then(() => {
                if (sumFl) {
                    setPlusBatchCpShowSt(false);
                } else {
                    setMinusBatchCpShowSt(false);
                }
                selectCpHistoryList();
            })
            .catch(() => {
                console.log("모두발급 실패");
            });
    };

    // const saveBatchCp = ((sumFl) => {
    //     axios({
    //         method: 'post',
    //         url: '/coupon/saveBatchCp',
    //         headers: {
    //             Authorization: token
    //         },
    //         params: {
    //             cpSeqnum: selectedBatchCpSeqnum,
    //             sumFl: sumFl
    //         }
    //     })
    //         .then(() => {
    //             if (sumFl) {
    //                 setPlusBatchCpShowSt(false);
    //             } else {
    //                 setMinusBatchCpShowSt(false);
    //             }
    //             selectCpHistoryList();
    //         })
    //         .catch(() => {
    //             console.log("모두발급 실패")
    //         })
    // })

    const deleteCoupon = (() => {
        axios({
            method: 'post',
            url: '/coupon/deleteCoupon',
            headers: {
                Authorization: token
            },
            params: {
                cpSeqnum: selectedBatchCp.seqNum,
            }
        })
            .then(() => {
                setDeleteCpShowSt(false);
                selectAllMstCp();
            })
            .catch(() => {
                console.log("모두발급 실패")
            })
    })

    const saveNewCoupon = (() => {
        axios({
            method: 'post',
            url: '/coupon/saveNewCoupon',
            headers: {
                Authorization: token
            },
            data: {
                couponNm: newCpNm,
                couponPrice: newCpPrice,
                couponExpDate: newCpExpDate
            }
        })
            .then(() => {
                newCpInit();
                selectAllMstCp();
            })
            .catch(() => {
                console.log("쿠폰생성 실패")
            })
    })

    const modifyDepConfirm = (() => {
        axios({
            method: 'post',
            url: '/member/modifyDepConfirm',
            headers: {
                Authorization: token
            },
            data: selectDepConfirmCheckData
        })
            .then((response) => {
                setDepConfirmHistoryList((currentList) => {
                    return currentList.map(item => {
                        if (selectDepConfirmCheckData.some(selectedItem => selectedItem.seqNum === item.seqNum)) {
                            return response.data.find(dataItem => dataItem.seqNum === item.seqNum) || item;
                        } else {
                            return item;
                        }
                    });
                });
            })
            .catch(() => {
                console.log("예치금확인 실패")
            })
    })

    // region Excel

    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const DateViewValue = year + month + day;

    const excelDown = (excelType) => {
        try {
            let selectedRows;
            switch(tabSt) {
                case "예치금":
                    selectedRows = depHistoryList.filter((item) => checked.includes(item.seqNum));
                    break;
                case "적립금":
                    selectedRows = ptHistoryList.filter((item) => checkedPoint.includes(item.seqNum));
                    break;
                case "쿠폰":
                    selectedRows = cpHistoryList.filter((item) => checkedCp.includes(item.seqNum));
                    break;
                case "예치금신청":
                    selectedRows = depConfirmHistoryList.filter((item) => selectDepConfirmCheckData.some((selected) => selected.seqNum === item.seqNum));
                    break;
                default:
                    alert('탭을 확인할 수 없습니다.', tabSt);
                    return;
            }

            const excel = require('exceljs');
            const workbook = new excel.Workbook();
            workbook.created = new Date();
            workbook.modified = new Date();

            function formatDepType(dep) {
                switch (dep.type) {
                    case "0":
                        return "취소";
                    case "1":
                        return "사용";
                    case "2":
                        return "지급";
                    default:
                        return "회수";
                }
            }

            function formatPtType(pt) {
                switch (pt.type) {
                    case "0":
                        return "취소";
                    case "1":
                        return "사용";
                    case "2":
                        return "지급";
                    default:
                        return "회수";
                }
            }

            function formatCpType(cp) {
                switch (cp.type) {
                    case "0":
                        return "취소";
                    case "1":
                        return "사용";
                    case "2":
                        return "지급";
                    default:
                        return "회수";
                }
            }

            function mapDataToExcelRowDEP(item, index) {
                return {
                    '번호': index + 1,
                    '회원명': item.memberDTO?.userName,
                    '아이디': item.memberDTO?.id,
                    '사용구분': formatDepType(item),
                    '내용': item.content,
                    '금액': item.depPrice,
                    '잔액': item.depBalance,
                    '일자': formatDate(item.regDate)
                };
            }

            function mapDataToExcelRowPT(item, index) {
                return {
                    '번호': index + 1,
                    '회원명': item.memberDTO?.userName,
                    '아이디': item.memberDTO?.id,
                    '사용구분': formatPtType(item),
                    '내용': item.content,
                    '금액': item.ptPrice,
                    '잔액': item.ptBalance,
                    '일자': formatDate(item.regDate)
                };
            }

            function mapDataToExcelRowCP(item, index) {
                return {
                    '번호': index + 1,
                    '회원명': item.memberDTO?.userName,
                    '아이디': item.memberDTO?.id,
                    '사용구분': formatCpType(item),
                    '내용': item.content,
                    '금액': item.cpPrice,
                    '일자': formatDate(item.regDate)
                };
            }

            function formatAmount(dep) {
                let amount = dep?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                if (dep.currency) {
                    amount += ` - ${dep.dollarPrice} ${dep.currency}`;
                }
                return amount;
            }

            function formatStatus(dep) {
                switch (dep.status) {
                    case "0":
                        return "대기";
                    case "1":
                        return "완료";
                    default:
                        return "취소";
                }
            }

            function mapDataToExcelRowDEPC(item, index) {
                return {
                    '번호': index + 1,
                    '입금완료일': formatDate(item.sendDate),
                    '회원명': item.userNm,
                    '아이디': item.userId,
                    '예금주': item.name,
                    '계좌': item.account,
                    '금액': formatAmount(item),
                    '구분': item.chargFl ? '환급' : '충전',
                    '상태': formatStatus(item)
                };
            }

            const sheetConfigs = {
                '예치금': {
                    name: '예치금',
                    columns: [
                        {header: '번호', key: '번호', hidden: false, height: 8,  width: 6, style: { alignment: { horizontal: 'center' }}},
                        {header: '회원명', key: '회원명', width: 22, style: { alignment: { horizontal: 'center' }}},
                        {header: '아이디', key: '아이디', width: 26},
                        {header: '사용구분', key: '사용구분', width: 9, style: { alignment: { horizontal: 'center' }}},
                        {header: '내용', key: '내용', width: 50},
                        {header: '금액', key: '금액', width: 12, style: { alignment: { horizontal: 'right' }}},
                        {header: '잔액', key: '잔액', width: 12, style: { alignment: { horizontal: 'right' }}},
                        {header: '일자', key: '일자', width: 12, style: { alignment: { horizontal: 'center' }}},
                    ],
                },
                '적립금': {
                    name: '적립금',
                    columns: [
                        {header: '번호', key: '번호', hidden: false, height: 8,  width: 6, style: { alignment: { horizontal: 'center' }}},
                        {header: '회원명', key: '회원명', width: 22, style: { alignment: { horizontal: 'center' }}},
                        {header: '아이디', key: '아이디', width: 26},
                        {header: '사용구분', key: '사용구분', width: 8, style: { alignment: { horizontal: 'center' }}},
                        {header: '내용', key: '내용', width: 50},
                        {header: '금액', key: '금액', width: 12, style: { alignment: { horizontal: 'right' }}},
                        {header: '잔액', key: '잔액', width: 12, style: { alignment: { horizontal: 'right' }}},
                        {header: '일자', key: '일자', width: 12, style: { alignment: { horizontal: 'center' }}},
                    ],
                },
                '쿠폰': {
                    name: '쿠폰',
                    columns: [
                        {header: '번호', key: '번호', hidden: false, height: 8,  width: 6, style: { alignment: { horizontal: 'center' }}},
                        {header: '회원명', key: '회원명', width: 22, style: { alignment: { horizontal: 'center' }}},
                        {header: '아이디', key: '아이디', width: 26},
                        {header: '사용구분', key: '사용구분', width: 8, style: { alignment: { horizontal: 'center' }}},
                        {header: '내용', key: '내용', width: 50},
                        {header: '금액', key: '금액', width: 12, style: { alignment: { horizontal: 'right' }}},
                        {header: '일자', key: '일자', width: 12, style: { alignment: { horizontal: 'center' }}},
                    ],
                },
                '예치금신청': {
                    name: '예치금신청',
                    columns: [
                        {header: '번호', key: '번호', hidden: false, height: 8,  width: 6, style: { alignment: { horizontal: 'center' }}},
                        {header: '입금완료일', key: '입금완료일', width: 12, style: { alignment: { horizontal: 'center' }}},
                        {header: '회원명', key: '회원명', width: 22, style: { alignment: { horizontal: 'center' }}},
                        {header: '아이디', key: '아이디', width: 26},
                        {header: '예금주', key: '예금주', width: 15, style: { alignment: { horizontal: 'center' }}},
                        {header: '계좌', key: '계좌', width: 50},
                        {header: '금액', key: '금액', width: 12, style: { alignment: { horizontal: 'right' }}},
                        {header: '구분', key: '구분', width: 12, style: { alignment: { horizontal: 'right' }}},
                        {header: '상태', key: '상태', width: 12, style: { alignment: { horizontal: 'center' }}},
                    ],
                }
            };

            const borderHeaderStyle = {
                top: { style: 'thin', color: { argb: 'FF000000' } },
                left: { style: 'thin', color: { argb: 'FF000000' } },
                bottom: { style: 'thin', color: { argb: 'FF000000' } },
                right: { style: 'thin', color: { argb: 'FF000000' } }
            };

            const applyBordersToRow = (row, border) => {
                row.eachCell({ includeEmpty: true }, (cell) => {
                    cell.border = border;
                });

            };

            const applyStylesToHeader = (sheet, height) => {
                const headerRow = sheet.getRow(1);
                headerRow.font = { name: '맑은 고딕', bold: true, size: 10 };
                headerRow.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
                headerRow.height = height;
                headerRow.eachCell(() => {});
                applyBordersToRow(headerRow, borderHeaderStyle);
            };

            const createSheet = (name, columns) => {
                const sheet = workbook.addWorksheet(name);
                sheet.columns = columns;
                applyStylesToHeader(sheet, 50);
                return sheet;
            };

            const addRowsToSheet = (sheet, rows, border) => {
                rows.forEach((item) => {
                    const row = sheet.addRow(item);
                    for (let i = 1; i <= sheet.columns.length; i++) {
                        row.getCell(i).font = { name: '맑은 고딕', size: 10 };
                    }
                    applyBordersToRow(row, border);
                });
            };

            let transformedSelectedRows;
            switch (excelType) {
                case '예치금':
                    transformedSelectedRows = selectedRows.map(mapDataToExcelRowDEP);
                    break;
                case '적립금':
                    transformedSelectedRows = selectedRows.map(mapDataToExcelRowPT);
                    break;
                case '쿠폰':
                    transformedSelectedRows = selectedRows.map(mapDataToExcelRowCP);
                    break;
                case '예치금신청':
                    transformedSelectedRows = selectedRows.map(mapDataToExcelRowDEPC);
                    break;
                default:
                    throw new Error(`잘못된 정보입니다.: ${excelType}`);
            }

            if (sheetConfigs[excelType]) {
                const sheet = createSheet(sheetConfigs[excelType].name, sheetConfigs[excelType].columns);
                applyStylesToHeader(sheet, 50, excelType);
                addRowsToSheet(sheet, transformedSelectedRows);
            }

            let fileName = `${excelType}_내역_${DateViewValue}.xlsx`;
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = fileName;
                anchor.click();
                window.URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.error(error);
        }
    };

    //endregion

    /** endregion */

    return (
        <div className="AdminDepositSetPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">{tabSt === "예치금" ? "예치금관리" : tabSt === "적립금" ? "적립금관리" : tabSt === "쿠폰" ? "쿠폰관리" : "예치금신청"}</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card">
                    <div className="admin-btn-wrap-r">
                        <button className="admin-btn-long admin-bt-color position-absolute"
                                style={{ right: "50px", top: "140px" }}
                                onClick={tabSt === "예치금" ? () => excelDown('예치금')
                                    : tabSt === "적립금" ? () => excelDown('적립금')
                                        : tabSt === "쿠폰" ? () => excelDown('쿠폰')
                                            : tabSt === "예치금신청" ? () => excelDown('예치금신청')
                                                : null}>
                                                {tabSt === "예치금" ? "EXCEL[예치]"
                                                    : tabSt === "적립금" ? "EXCEL[적립]"
                                                        : tabSt === "쿠폰" ? "EXCEL[쿠폰]"
                                                            : tabSt === "예치금신청" ? "EXCEL[신청]" : "EXCEL"}
                        </button>
                    </div>
                    <Tabs defaultActiveKey="예치금" onSelect={handleSelectTabs}>
                        {/*예치금탭*/}
                        <Tab eventKey="예치금" title="&nbsp;&nbsp;&nbsp;예치금&nbsp;&nbsp;&nbsp;">
                            <div className="m-t-20 m-b-40 dark-border" style={{display: "flex", flexDirection: "column", padding: "10px 5px 10px 5px",}}>
                                <div className="m-b-3">
                                    <label className="m-r-15 font-b dark-font" style={{fontSize: "14px"}}>회원검색</label>
                                    <select className="admin-combo m-r-7" style={{width: "90px", height: "25px", fontSize: "13px"}}
                                            onChange={selectChangeDepCondition}
                                            value={selectedDepSerchCondition === 'userNm' ? '회원명' : selectedDepSerchCondition === "userId" ? '아이디' : ''}>
                                        <option value="회원명">회원명</option>
                                        <option value="아이디">아이디</option>
                                    </select>
                                    <input onChange={(e) => depMemberInputChange(e)} value={depMemberInput} onKeyDown={depositSerchKeyPress}
                                           className="admin-input-line-basic" type="text" style={{width: "103px"}}/>
                                </div>
                                <div className=" m-b-3">
                                    <label className="m-r-15 font-b dark-font" style={{fontSize: "14px"}}>적용회원</label>
                                    <select onChange={selectedDepMemberChange} className="admin-combo m-r-7"
                                        style={{minWidth: "200px", height: "25px", fontSize: "13px", paddingRight: "20px"}}>
                                        {serchDepMemberList.map((member, i) => (
                                            <option key={i} value={member.id}>{member.id} / {member.userName}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="m-b-3">
                                    <label className="m-r-15 font-b dark-font" style={{fontSize: "14px"}}>지급내용</label>
                                    <input onChange={depPayContentInputChange} value={depositPayContent} className="admin-input-line-basic wd-200" type="text"/>
                                </div>
                                <div className="m-b-3">
                                    <label className="m-r-15 font-b dark-font" style={{fontSize: "14px"}}>적립금액</label>
                                    <select className="admin-combo m-r-7" value={depSumFl === '2' ? 'plus' : 'minus'} onChange={selectDepositSumFlChange}
                                            style={{width: "50px", height: "25px", fontSize: "14px", fontWeight: "bold"}}>
                                        <option value="plus">+</option>
                                        <option value="minus">-</option>
                                    </select>
                                    <input onChange={(e) => depositPriceChange(e)} value={depositPrice} className="admin-input-line-basic wd-80" type="text"/>
                                </div>
                                <div className="admin-btn-wrap-l m-t-5">
                                    <button onClick={depositInfoInit} className="admin-btn-short admin-btn-basic">취소</button>
                                    <button onClick={saveDeposit} className="admin-btn-short admin-btn-basic">지급</button>
                                </div>
                            </div>
                <div className="admin-event-wrap-btn">
                    <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                    <AdminYmdEvent startDate={depStartDate} endDate={depEndDate} startHandle={depStartHandle}
                                   endHandle={depEndHandle}/>
                    {/*여기서부터 커스텀 예치금 type 셀렉트*/}
                    <div ref={selectedDepTypeRef} style={{width: "80px", height:"30px", position: "relative", marginRight:"15px"}}>
                        <button className="selectParentBt" style={{width: "100%", height: "100%"}}
                                onClick={toggleDepTypeShow}>
                            <p className="font-t-cont font-s-13" style={{margin: "0"}}>{selectedDepType.label}</p>
                            <span style={{position: "absolute", left: "75%", top: "0", height: "100%", width: "20%", opacity:"0.3"}}>
                                <i style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%"
                                }}><BiCaretDown/></i>
                            </span>
                        </button>
                        {selectedDepTypeShow ?
                            <div style={{
                                width:"100%",
                                border: "solid",
                                borderWidth: "thin 1px",
                                height:"207px",
                                borderColor: "lightgray",
                                position: "absolute",
                                top:"100%",
                                bottom:"100%",
                                zIndex:1,
                                marginTop: "5px",
                                overflowY: "auto",
                                background:"white"
                            }}>
                                {selectedDepTypeList.map((type, index) => (
                                    <div className="selectChildBt" key={index}
                                         onClick={() => handleDepTypeChange(type)} style={{
                                        display: "flex", padding: "10px", justifyContent: "center",
                                        alignItems: "center", cursor: "pointer", fontSize: "14px", borderRadius: "4px", zIndex:99,
                                    }}><p className="font-t-cont font-s-14" style={{margin: "0"}}>{type.label}</p></div>
                                ))}
                            </div>
                            :
                            null
                        }
                    </div>
                        <AdminSearchEvent searchInput={searchInput} handleSearchInputChange={handleSearchInputChange}/>
                    </div>
                    <div className="m-t-15">
                        <div className="div-tb m-b-10">
                            <div className="div-tb-head ht-60">
                                <div style={{width: "40px"}}>
                                    <input className="admin-input-chkBox m-0-a" type="checkbox"
                                           checked={checked.length === depHistoryList.length}
                                           onChange={allcheckBoxDepChange}/>
                                </div>
                                <div style={{width: "40px"}}><p>번호</p></div>
                                <div style={{width: "150px"}}><p>회원명</p></div>
                                <div style={{width: "150px"}}><p>아이디</p></div>
                                <div style={{width: "100px"}}><p>사용구분</p></div>
                                <div style={{width: "250px"}}><p>내용</p></div>
                                <div style={{width: "100px"}}><p>금액(&#8361;)</p></div>
                                <div style={{width: "100px"}}><p>잔액(&#8361;)</p></div>
                                <div style={{width: "120px"}}><p>일자</p></div>
                            </div>
                            {searchDepData.slice(0, displayLimitDep).map((dep, i) => (
                                // {depHistoryList && depHistoryList.map((dep, i) => (
                                <div key={i} className="div-tb-body ht-70">
                                    <div style={{width: "40px"}}>
                                        <input className="admin-input-chkBox m-0-a" type="checkbox" name={dep.seqNum}
                                               checked={checked.includes(dep.seqNum)}
                                               onChange={() => checkBoxDepChange(dep.seqNum)}/>
                                    </div>
                                    <div style={{width: "40px"}}><p>{i + 1}</p></div>
                                    <div style={{width: "150px"}}><p className="font-b">{dep?.memberDTO?.userName}</p>
                                    </div>
                                    <div style={{width: "150px"}}><p>{dep?.memberDTO?.id}</p></div>
                                    <div style={{width: "100px"}}>
                                        <p className="font-b" style={{fontSize: "13px", color: dep?.type === '1' ? "#48dc50" : dep?.type === '2' ? "#7c9edc" : '#ff7575'}}>
                                            {dep?.type === '0' ? '취소' : dep?.type === '1' ? '사용' : dep?.type === '2' ? '지급' : '회수'}
                                        </p>
                                    </div>
                                    <div style={{width: "250px"}}><p>{dep?.content}</p></div>
                                    <div style={{width: "100px"}}><p
                                        className="font-b">{dep?.depPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                    </div>
                                    <div style={{width: "100px"}}>
                                        <p>{dep?.depBalance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                    <div style={{width: "120px"}}><p>{formatDate(dep?.regDate)}</p></div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {displayLimitDep >= 30 && searchDepData.length > displayLimitDep && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </Tab>
                        {/*적립금탭*/}
                        <Tab eventKey="적립금" title="&nbsp;&nbsp;&nbsp;적립금&nbsp;&nbsp;&nbsp;">
                            <div className="m-t-20 m-b-40 dark-border" style={{display: "flex", flexDirection: "column", padding: "10px 5px 10px 5px",}}>
                                <div className="m-b-3">
                                    <label className="m-r-15 font-b dark-font" style={{fontSize: "14px"}}>회원검색</label>
                                    <select className="admin-combo m-r-7" style={{width: "90px", height: "25px", fontSize: "13px"}} onChange={selectChangeDepCondition}
                                        value={selectedDepSerchCondition === 'userNm' ? '회원명' : selectedDepSerchCondition === "userId" ? '아이디' : ''}>
                                        <option value="회원명">회원명</option>
                                        <option value="아이디">아이디</option>
                                    </select>
                                    <input onChange={(e) => depMemberInputChange(e)} value={depMemberInput} onKeyDown={depositSerchKeyPress}
                                           className="admin-input-line-basic" type="text" style={{width: "103px"}}/>
                                </div>
                                <div className=" m-b-3">
                                    <label className="m-r-15 font-b dark-font" style={{fontSize: "14px"}}>적용회원</label>
                                    <select onChange={selectedDepMemberChange} className="admin-combo m-r-7"
                                        style={{minWidth: "200px", height: "25px", fontSize: "13px", paddingRight: "20px"}}>
                                        {serchDepMemberList.map((member, i) => (
                                            <option key={i} value={member.id}>{member.id} / {member.userName}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="m-b-3">
                                    <label className="m-r-15 font-b dark-font" style={{fontSize: "14px"}}>지급내용</label>
                                    <input onChange={depPayContentInputChange} value={depositPayContent} className="admin-input-line-basic wd-200" type="text"/>
                                </div>
                                <div className="m-b-3">
                                    <label className="m-r-15 font-b dark-font" style={{fontSize: "14px"}}>적립금액</label>
                                    <select className="admin-combo m-r-7" value={depSumFl === '2' ? 'plus' : 'minus'} onChange={selectDepositSumFlChange}
                                            style={{width: "50px", height: "25px", fontSize: "14px", fontWeight: "bold"}}>
                                        <option value="plus">+</option>
                                        <option value="minus">-</option>
                                    </select>
                                    <input onChange={(e) => depositPriceChange(e)} value={depositPrice} className="admin-input-line-basic wd-80" type="text"/>
                                </div>
                                <div className="admin-btn-wrap-l m-t-5">
                                    <button onClick={depositInfoInit} className="admin-btn-short admin-btn-basic">취소</button>
                                    <button onClick={saveDeposit} className="admin-btn-short admin-btn-basic">지급</button>
                                </div>
                            </div>
                            <div className="admin-event-wrap-btn">
                                <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                                <AdminYmdEvent startDate={ptStartDate} endDate={ptEndDate} startHandle={ptStartHandle} endHandle={ptEndHandle}/>
                                {/*여기서부터 커스텀 적립금 type 셀렉트*/}
                                <div ref={selectedPtTypeRef} style={{width: "80px", height:"30px", position: "relative", marginRight:"15px"}}>
                                    <button className="selectParentBt" style={{width: "100%", height: "100%"}}
                                            onClick={togglePtTypeShow}>
                                        <p className="font-t-cont font-s-13" style={{margin: "0"}}>{selectedPtType.label}</p>
                                        <span style={{position: "absolute", left: "75%", top: "0", height: "100%", width: "20%", opacity:"0.3"}}>
                                <i style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%"
                                }}><BiCaretDown/></i>
                            </span>
                                    </button>
                                    {selectedPtTypeShow ?
                                        <div style={{
                                            width:"100%",
                                            border: "solid",
                                            borderWidth: "thin 1px",
                                            height:"207px",
                                            borderColor: "lightgray",
                                            position: "absolute",
                                            top:"100%",
                                            bottom:"100%",
                                            zIndex:1,
                                            marginTop: "5px",
                                            overflowY: "auto",
                                            background:"white"
                                        }}>
                                            {selectedPtTypeList.map((type, index) => (
                                                <div className="selectChildBt" key={index}
                                                     onClick={() => handlePtTypeChange(type)} style={{
                                                    display: "flex", padding: "10px", justifyContent: "center",
                                                    alignItems: "center", cursor: "pointer", fontSize: "14px", borderRadius: "4px", zIndex:99,
                                                }}><p className="font-t-cont font-s-14" style={{margin: "0"}}>{type.label}</p></div>
                                            ))}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                <AdminSearchEvent searchInput={searchInput} handleSearchInputChange={handleSearchInputChange} />
                            </div>
                            <div className="m-t-15">
                                <div className="div-tb m-b-10">
                                    <div className="div-tb-head ht-60">
                                        <div style={{width: "40px"}}>
                                            <input className="admin-input-chkBox m-0-a" type="checkbox" checked={checkedPoint.length === ptHistoryList.length}
                                                   onChange={allcheckBoxPointChange}/>
                                        </div>
                                        <div style={{width: "40px"}}><p>번호</p></div>
                                        <div style={{width: "150px"}}><p>회원명</p></div>
                                        <div style={{width: "150px"}}><p>아이디</p></div>
                                        <div style={{width: "100px"}}><p>사용구분</p></div>
                                        <div style={{width: "250px"}}><p>내용</p></div>
                                        <div style={{width: "100px"}}><p>금액(&#8361;)</p></div>
                                        <div style={{width: "100px"}}><p>잔액(&#8361;)</p></div>
                                        <div style={{width: "120px"}}><p>일자</p></div>
                                    </div>
                                    {searchPtData.slice(0, displayLimitPt).map((pt, i) => (
                                    // {ptHistoryList && ptHistoryList.map((pt, i) => (
                                        <div key={i} className="div-tb-body ht-70">
                                            <div style={{width: "40px"}}>
                                                <input className="admin-input-chkBox m-0-a" type="checkbox" name={pt.seqNum} checked={checkedPoint.includes(pt.seqNum)}
                                                       onChange={() => checkBoxPointChange(pt.seqNum)}/>
                                            </div>
                                            <div style={{width: "40px"}}><p>{i + 1}</p></div>
                                            <div style={{width: "150px"}}><p className="font-b">{pt?.memberDTO?.userName}</p></div>
                                            <div style={{width: "150px"}}><p>{pt?.memberDTO?.id}</p></div>
                                            <div style={{width: "100px"}}>
                                                <p className="font-b" style={{fontSize:"13px", color: pt?.type === '1' ? "#48dc50" : pt?.type === '2' ? "#7c9edc" : '#ff7575'}}>
                                                    {pt?.type === '0' ? '취소' : pt?.type === '1' ? '사용' : pt?.type === '2' ? '지급' : '회수'}
                                                </p>
                                            </div>
                                            <div style={{width: "250px"}}><p>{pt?.content}</p></div>
                                            <div style={{width: "100px"}}>
                                                <p className="font-b">{pt?.ptPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                            </div>
                                            <div style={{width: "100px"}}><p>{pt?.ptBalance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "120px"}}><p>{formatDate(pt?.regDate)}</p></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {displayLimitPt >= 30 && searchPtData.length > displayLimitPt && (
                                <div className="text-center m-t-20">
                                    <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                        <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                                    </button>
                                </div>
                            )}
                        </Tab>
                        {/*쿠폰탭*/}
                        <Tab eventKey="쿠폰" title="&nbsp;&nbsp;&nbsp;&nbsp;쿠폰&nbsp;&nbsp;&nbsp;&nbsp;">
                            <div className="m-t-20">
                                <div style={{display: "flex", justifyContent: "space-between", marginBottom: "40px"}}>
                                    <div style={{width: "30%"}}>
                                        <div><BsSquareFill className="admin-tb-header-notice"/>개별관리</div>
                                        <div style={{
                                            border: "solid 1px #EAEAEA",
                                            height: "220px",
                                            width: "100%",
                                            marginTop: "10px",
                                            borderRadius: "6px",
                                            padding: "20px"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}>
                                                <div ref={indiviCpUserNmRef} style={{width: "45%", position: "relative"}}>
                                                    <button className="selectParentBt wd-100pc ht-40" onClick={toggleIndiviCpUserNmShow}>
                                                        <p>{selectedCpSerchCondition === "userNm" ? "회원명" : "아이디"}</p>
                                                        <span style={{
                                                            position: "absolute",
                                                            left: "75%",
                                                            top: "0",
                                                            height: "40px",
                                                            width: "20%"
                                                        }}>
                                                    <i style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "100%"
                                                    }}><BiCaretDown/></i>
                                                </span>
                                                    </button>
                                                    {indiviCpUserNmShow ?
                                                        <div style={{
                                                            position: 'absolute',
                                                            width: "100%",
                                                            border: "solid 1px lightgray",
                                                            height: "auto",
                                                            maxHeight: "300px",
                                                            zIndex: 1000,
                                                            marginTop: "5px",
                                                            backgroundColor: "white",
                                                            overflow: "auto"
                                                        }}>
                                                            {selectedIndiviSerchCondition.map((condition, i) => (
                                                                <div
                                                                    onClick={() => handleIndiviConditionChange(condition)}
                                                                    key={i} className="selectChildBt ht-40 align-center c-point font-s-14"
                                                                    style={{
                                                                        height: "40px",
                                                                        display: "flex",
                                                                        padding: "15px",
                                                                        justifyContent: "center"
                                                                    }}>{condition}
                                                                </div>
                                                            ))}
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                <div className="user-input-container ht-40" style={{width: "53%", borderRadius: "2px"}}>
                                                    <input maxLength={25} type="text" onChange={cpMemberInputChange} onKeyDown={cpSerchKeyPress}
                                                           className="ReviewInput user-input wd-100pc p-l-10 p-r-10 font-s-14" value={cpMemberInput}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                marginTop: "5px"
                                            }}>
                                                <div ref={indiviCpReturnMemberRef}
                                                     style={{width: "100%", position: "relative"}}>
                                                    <button className="selectParentBt wd-100pc ht-40" onClick={toggleIndiviCpReturnMemberShow}>
                                                        {selectedIndiviCpMemberId || selectedIndiviCpMemberNm ?
                                                            <p>{selectedIndiviCpMemberId} / {selectedIndiviCpMemberNm}</p>
                                                            :
                                                            null
                                                        }
                                                        <span style={{
                                                            position: "absolute",
                                                            left: "85%",
                                                            top: "0",
                                                            height: "40px",
                                                            width: "20%"
                                                        }}>
                                                    <i style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "100%"
                                                    }}><BiCaretDown/></i>
                                                </span>
                                                    </button>
                                                    {indiviCpReturnMemberShow ?
                                                        <div style={{
                                                            position: 'absolute',
                                                            width: "100%",
                                                            border: "solid 1px lightgray",
                                                            height: "auto",
                                                            maxHeight: "300px",
                                                            zIndex: 1000,
                                                            marginTop: "5px",
                                                            backgroundColor: "white",
                                                            overflow: "auto"
                                                        }}>
                                                            {serchCpMemberList && serchCpMemberList.map((member, i) => (
                                                                <div key={i} className="selectChildBt"
                                                                     onClick={() => selectedCpMemberChange(member)}
                                                                     style={{
                                                                         height: "40px",
                                                                         display: "flex",
                                                                         padding: "15px",
                                                                         justifyContent: "center",
                                                                         alignItems: "center",
                                                                         cursor: "pointer",
                                                                         fontSize: "14px",
                                                                     }}>{member.id} / {member.userName}
                                                                </div>
                                                            ))}

                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                marginTop: "5px"
                                            }}>
                                                <div ref={indiviCpListRef}
                                                     style={{width: "100%", position: "relative"}}>
                                                    <button className="selectParentBt"
                                                            style={{width: "100%", height: "40px"}}
                                                            onClick={toggleIndiviCpListShow}>
                                                        {selectedIndiviCpNm || selectedIndiviCpPrice ?
                                                            <p style={{margin: "0"}}>{selectedIndiviCpNm} / {selectedIndiviCpPrice}</p>
                                                            :
                                                            null
                                                        }
                                                        <span style={{
                                                            position: "absolute",
                                                            left: "85%",
                                                            top: "0",
                                                            height: "40px",
                                                            width: "20%"
                                                        }}>
                                                    <i style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "100%"
                                                    }}><BiCaretDown/></i>
                                                </span>
                                                    </button>

                                                    {indiviCpListShow ?
                                                        <div style={{
                                                            position: 'absolute',
                                                            width: "100%",
                                                            border: "solid",
                                                            height: "auto",
                                                            maxHeight: "300px",
                                                            borderWidth: "thin 1px",
                                                            borderColor: "lightgray",
                                                            zIndex: 1000,
                                                            marginTop: "5px",
                                                            backgroundColor: "white",
                                                            overflow: "auto"
                                                        }}>

                                                            {serchIndiviCpList && serchIndiviCpList.map((cp, i) => (
                                                                <div key={i} className="selectChildBt"
                                                                     onClick={() => selectedCpListChange(cp)}
                                                                     style={{
                                                                         height: "40px",
                                                                         display: "flex",
                                                                         padding: "15px",
                                                                         justifyContent: "center",
                                                                         alignItems: "center",
                                                                         cursor: "pointer",
                                                                         fontSize: "14px",
                                                                     }}>{cp.cpNm} / {cp.cpPrice}
                                                                </div>
                                                            ))}

                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            <div style={{
                                                height: "50px",
                                                display: "flex",
                                                marginTop: "10px",
                                                justifyContent: "space-between"
                                            }}>
                                                <button onClick={indiviCpInit} style={{
                                                    border: "solid 1px lightgray",
                                                    outline: "none",
                                                    width: "30%",
                                                    height: "40px",
                                                    fontSize: "12px",
                                                    margin: "0",
                                                    borderRadius: "4px",
                                                    color: "#A1A1A1",
                                                }}>초기화
                                                </button>
                                                <button onClick={() => saveIndiviCp('3')} className="orderDeleteBt" style={{
                                                    width: "33%",
                                                    height: "40px",
                                                    textAlign: "center",
                                                    fontSize: "14px",
                                                    borderRadius: "4px"
                                                }}>회수
                                                </button>
                                                <button onClick={() => saveIndiviCp('2')} className="orderItemHandleBt"
                                                        style={{
                                                            width: "33%",
                                                            height: "40px",
                                                            fontSize: "14px",
                                                            margin: "0",
                                                            borderRadius: "4px",
                                                        }}>지급
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                    <div style={{width: "30%"}}>
                                        <div>
                                            <BsSquareFill className="admin-tb-header-notice"/>일괄관리 & 삭제
                                        </div>
                                        <div style={{
                                            border: "solid 1px #EAEAEA",
                                            height: "220px",
                                            width: "100%",
                                            marginTop: "10px",
                                            borderRadius: "6px",
                                            padding: "20px"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <div ref={batchCpListRef} style={{width: "100%", position: "relative"}}>
                                                    <button className="selectParentBt wd-100pc ht-40" onClick={toggleBatchCpListShow}>
                                                        {selectedBatchCp ? (
                                                            <p>
                                                                {selectedBatchCp.cpNm} / {selectedBatchCp.cpPrice}
                                                            </p>
                                                        ) : null}
                                                        <span style={{
                                                            position: "absolute",
                                                            left: "85%",
                                                            top: "0",
                                                            height: "40px",
                                                            width: "20%"
                                                        }}>
                                                    <i style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "100%"
                                                    }}><BiCaretDown/></i>
                                                </span>
                                                    </button>
                                                    {batchCpListShow ?
                                                        <div style={{
                                                            position: 'absolute',
                                                            width: "100%",
                                                            border: "solid",
                                                            height: "auto",
                                                            maxHeight: "300px",
                                                            borderWidth: "thin 1px",
                                                            borderColor: "lightgray",
                                                            zIndex: 1000,
                                                            marginTop: "5px",
                                                            backgroundColor: "white",
                                                            overflow: "auto"
                                                        }}>
                                                            {serchBatchCpList && serchBatchCpList.map((cp, i) => (
                                                                <div key={i} className="selectChildBt"
                                                                     // onClick={() => selectedBatchCpListChange(cp)}
                                                                     onClick={() => handleBatchCpSelect(cp)}
                                                                     style={{
                                                                         height: "40px",
                                                                         display: "flex",
                                                                         padding: "15px",
                                                                         justifyContent: "center",
                                                                         alignItems: "center",
                                                                         cursor: "pointer",
                                                                         fontSize: "14px",
                                                                     }}>{cp.cpNm} / {cp.cpPrice}
                                                                </div>
                                                            ))}
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            <div style={{
                                                marginTop: "10px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}>
                                                <button onClick={() => setDeleteCpShowSt(true)} style={{
                                                    border: "solid 1px lightgray",
                                                    outline: "none",
                                                    width: "30%",
                                                    height: "40px",
                                                    fontSize: "12px",
                                                    margin: "0",
                                                    borderRadius: "4px",
                                                    color: "#A1A1A1",
                                                }}>쿠폰삭제
                                                </button>
                                                <button onClick={() => setMinusBatchCpShowSt(true)}
                                                        className="orderDeleteBt font-s-14 text-center ht-40" style={{width: "33%", borderRadius: "4px"}}>모두회수
                                                </button>
                                                <button onClick={() => setPlusBatchCpShowSt(true)}
                                                        className="orderItemHandleBt font-s-14 ht-40" style={{width: "33%", margin: "0", borderRadius: "4px",}}>모두지급
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                    <div style={{width: "30%"}}>
                                        <div>
                                            <BsSquareFill className="admin-tb-header-notice"/>
                                            쿠폰생성
                                        </div>
                                        <div style={{
                                            border: "solid 1px #EAEAEA",
                                            height: "220px",
                                            width: "100%",
                                            marginTop: "10px",
                                            borderRadius: "6px",
                                            padding: "20px"
                                        }}>
                                            <div className="user-input-container"
                                                 style={{height: "40px", borderRadius: "2px"}}>
                                                <input onChange={handleNewCpNm} className="ReviewInput user-input"
                                                       value={newCpNm}
                                                       placeholder="쿠폰명을 입력하세요"
                                                       style={{
                                                           width: "100%",
                                                           paddingLeft: "10px",
                                                           paddingRight: "10px",
                                                           fontSize: "14px",
                                                           color: "dimgray",
                                                       }} maxLength={25} type="text"
                                                />
                                            </div>
                                            <div className="user-input-container"
                                                 style={{height: "40px", borderRadius: "2px", marginTop: "5px"}}>
                                                <input onChange={handleNewCpPrice} className="ReviewInput user-input"
                                                       value={newCpPrice}
                                                       placeholder="금액을 입력하세요(ex/10000)"
                                                       style={{
                                                           width: "100%",
                                                           paddingLeft: "10px",
                                                           paddingRight: "10px",
                                                           fontSize: "14px",
                                                           color: "dimgray",
                                                       }} maxLength={5} type="text"
                                                />
                                            </div>
                                            <div className="user-input-container"
                                                 style={{height: "40px", borderRadius: "2px", marginTop: "5px"}}>
                                                <input onChange={handleNewCpExpDate} className="ReviewInput user-input"
                                                       value={newCpExpDate}
                                                       placeholder="만료기한을 입력하세요(ex/20240101)"
                                                       style={{
                                                           width: "100%",
                                                           paddingLeft: "10px",
                                                           paddingRight: "10px",
                                                           fontSize: "14px",
                                                           color: "dimgray",
                                                       }} maxLength={8} type="text"
                                                />
                                            </div>

                                            <div style={{
                                                height: "50px",
                                                display: "flex",
                                                marginTop: "10px",
                                                justifyContent: "space-between"
                                            }}>
                                                <button onClick={newCpInit} style={{
                                                    border: "solid",
                                                    borderWidth: "thin 1px",
                                                    borderColor: "lightgray",
                                                    outline: "none",
                                                    width: "30%",
                                                    height: "40px",
                                                    fontSize: "12px",
                                                    margin: "0",
                                                    borderRadius: "4px",
                                                    color: "#A1A1A1",
                                                }}>초기화
                                                </button>
                                                <button onClick={saveNewCoupon}
                                                        className="admin-btn-long admin-bt-color"
                                                        style={{
                                                            width: "66%",
                                                            height: "40px",
                                                            fontSize: "14px",
                                                            margin: "0",
                                                            borderRadius: "4px",
                                                        }}>생성
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="admin-event-wrap-btn">
                                <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                                <AdminYmdEvent startDate={cpStartDate} endDate={cpEndDate} startHandle={cpStartHandle} endHandle={cpEndHandle}/>
                                {/*여기서부터 커스텀 쿠폰 type 셀렉트*/}
                                <div ref={selectedCpTypeRef} style={{width: "80px", height:"30px", position: "relative", marginRight:"15px"}}>
                                    <button className="selectParentBt" style={{width: "100%", height: "100%"}}
                                            onClick={toggleCpTypeShow}>
                                        <p className="font-t-cont font-s-13" style={{margin: "0"}}>{selectedCpType.label}</p>
                                        <span style={{position: "absolute", left: "75%", top: "0", height: "100%", width: "20%", opacity:"0.3"}}>
                                <i style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%"
                                }}><BiCaretDown/></i>
                            </span>
                                    </button>
                                    {selectedCpTypeShow ?
                                        <div style={{
                                            width:"100%",
                                            border: "solid",
                                            borderWidth: "thin 1px",
                                            height:"207px",
                                            borderColor: "lightgray",
                                            position: "absolute",
                                            top:"100%",
                                            bottom:"100%",
                                            zIndex:1,
                                            marginTop: "5px",
                                            overflowY: "auto",
                                            background:"white"
                                        }}>
                                            {selectedCpTypeList.map((type, index) => (
                                                <div className="selectChildBt" key={index}
                                                     onClick={() => handleCpTypeChange(type)} style={{
                                                    display: "flex", padding: "10px", justifyContent: "center",
                                                    alignItems: "center", cursor: "pointer", fontSize: "14px", borderRadius: "4px", zIndex:99,
                                                }}><p className="font-t-cont font-s-14" style={{margin: "0"}}>{type.label}</p></div>
                                            ))}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                <AdminSearchEvent searchInput={searchInput} handleSearchInputChange={handleSearchInputChange} />
                            </div>
                            <div className="m-t-15">
                                <div className="div-tb m-b-10">
                                    <div className="div-tb-head ht-60">
                                        <div style={{width: "40px"}}>
                                            <input className="admin-input-chkBox m-0-a" type="checkbox" checked={checkedCp.length === cpHistoryList.length}
                                                   onChange={allcheckBoxCpChange}/>
                                        </div>
                                        <div style={{width: "40px"}}><p>번호</p></div>
                                        <div style={{width: "150px"}}><p>회원명</p></div>
                                        <div style={{width: "150px"}}><p>아이디</p></div>
                                        <div style={{width: "100px"}}><p>사용구분</p></div>
                                        <div style={{width: "270px"}}><p>내용</p></div>
                                        <div style={{width: "100px"}}><p>금액(&#8361;)</p></div>
                                        <div style={{width: "120px"}}><p>일자</p></div>
                                    </div>
                                    {searchCpData.slice(0, displayLimitCp).map((cp, i) => (
                                    // {cpHistoryList && cpHistoryList.map((cp, i) => (
                                        <div key={i} className="div-tb-body ht-70">
                                            <div style={{width: "40px"}}>
                                                <input className="admin-input-chkBox m-0-a" type="checkbox" name={cp.seqNum} checked={checkedCp.includes(cp.seqNum)}
                                                       onChange={() => checkBoxCpChange(cp.seqNum)}/>
                                            </div>
                                            <div style={{width: "40px"}}><p>{i + 1}</p></div>
                                            <div style={{width: "150px"}}><p className="font-b">{cp?.memberDTO?.userName}</p></div>
                                            <div style={{width: "150px"}}><p>{cp?.memberDTO?.id}</p></div>
                                            <div style={{width: "100px"}}>
                                                <p className="font-b" style={{fontSize:"14px", color: cp?.type === '1' ? "#48dc50" : cp?.type === '2' ? "#7c9edc" : '#ff7575'}}>
                                                    {cp?.type === '0' ? '취소' : cp?.type === '1' ? '사용' : cp?.type === '2' ? '지급' : '회수'}
                                                </p>
                                            </div>
                                            <div style={{width: "270px"}}><p className="font-b">{cp?.cpNm}</p></div>
                                            <div style={{width: "100px"}}><p>{cp?.cpPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "120px"}}><p>{formatDate(cp?.regDate)}</p></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {displayLimitCp >= 30 && searchCpData.length > displayLimitCp && (
                                <div className="text-center m-t-20">
                                    <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                        <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                                    </button>
                                </div>
                            )}
                        </Tab>
                        {/*예치금신청탭*/}
                        <Tab eventKey="예치금신청" title="예치금신청">
                            <div className="admin-event-wrap m-t-15" style={{marginBottom: "-5px"}}>
                                <div className="admin-event-wrap-btn">
                                    <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                                    <AdminYmdEvent startDate={depConfirmStartDate} endDate={depConfirmEndDate} startHandle={depConfirmStartHandle} endHandle={depConfirmEndHandle}/>
                                    <AdminSearchEvent searchInput={searchInput} handleSearchInputChange={handleSearchInputChange} />
                                </div>
                                <div className="admin-event-wrap-btn">
                                    <button onClick={modifyDepConfirm} className="admin-btn-long admin-bt-color">확인</button>
                                </div>
                            </div>
                            <div className="m-t-15">
                                <div className="div-tb m-b-10">
                                    <div className="div-tb-head ht-60">
                                        <div style={{width: "40px"}}>
                                            <input className="admin-input-chkBox m-0-a" type="checkbox" checked={selectDepConfirmAllCheckBox}
                                                   onChange={handleSelectDepConfirmAllCheckBox}/>
                                        </div>
                                        <div style={{width: "40px"}}><p>번호</p></div>
                                        <div style={{width: "150px"}}><p>입금완료일</p></div>
                                        <div style={{width: "150px"}}><p>회원명</p></div>
                                        <div style={{width: "160px"}}><p>아이디</p></div>
                                        <div style={{width: "150px"}}><p>예금주(ID)</p></div>
                                        <div style={{width: "250px"}}><p>계좌</p></div>
                                        <div style={{width: "120px"}}><p>금액</p></div>
                                        <div style={{width: "80px"}}><p>구분</p></div>
                                        <div style={{width: "80px"}}><p>상태</p></div>
                                    </div>
                                    {searchDepConData.slice(0, displayLimitDepCon).map((dep, i) => (
                                        <div key={i} className="div-tb-body ht-70">
                                            <div style={{width: "40px"}}>
                                                {dep.status === "0" ?
                                                    <input className="admin-input-chkBox m-0-a" type={"checkbox"}
                                                           checked={selectDepConfirmCheckData.some(item => item.seqNum === dep.seqNum)}
                                                           onChange={() => handleSelectDepConfirmCheckBox(dep)}/> : null
                                                }
                                            </div>
                                            <div style={{width: "40px"}}><p className="font-b">{i + 1}</p></div>
                                            <div style={{width: "150px"}}><p>{formatDate(dep.sendDate)}</p></div>
                                            <div style={{width: "150px"}}><p>{dep.userNm}</p></div>
                                            <div style={{width: "160px"}}><p>{dep.userId}</p></div>
                                            <div style={{width: "150px"}}><p>{dep.name}</p></div>
                                            <div style={{width: "250px"}}>
                                                <p>{dep.account}</p>
                                            </div>
                                            <div style={{width: "120px", display:"flex", flexDirection:"row", alignItems:"center"}}>
                                                <p>{dep?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                {dep.currency ?
                                                    <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                                        <a style={{margin:"0 5px"}}> - </a>
                                                        <a style={{
                                                            color: "darkorange",
                                                            fontWeight: "bold"
                                                        }}>{dep.dollarPrice}</a>
                                                        <a style={{
                                                            fontSize: "11px",
                                                            marginLeft:"5px"
                                                        }}>{dep.currency}</a>
                                                    </div> : null
                                                }
                                            </div>
                                            <div style={{width: "80px"}}>{dep.chargFl ? <p>충전</p> : <p>환급</p>}</div>
                                            <div style={{width: "80px"}}>
                                                {dep.status === "0" ?
                                                    <p style={{fontWeight: "bold", color: "#7C9EDC"}}>대기</p>
                                                    :
                                                    dep.status === "1" ?
                                                        <p>완료</p>
                                                        :
                                                        <p style={{fontWeight: "bold", color: "#EB939D"}}>취소</p>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {displayLimitDepCon >= 30 && searchDepConData.length > displayLimitDepCon && (
                                <div className="text-center m-t-20">
                                    <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                        <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                                    </button>
                                </div>
                            )}
                        </Tab>
                    </Tabs>
                </div>
            </div>
            {deleteCpShowSt ?
                <MsgSelectModal deleteCpShowSt={deleteCpShowSt} deleteCpMsg1={deleteCpMsg1} deleteCpMsg2={deleteCpMsg2}
                                closeDeleteCpShow={closeDeleteCpShow} deleteCoupon={deleteCoupon}/>
                :
                minusBatchCpShowSt ?
                    <MsgSelectModal minusBatchCpShowSt={minusBatchCpShowSt} minusBatchCpMsg1={minusBatchCpMsg1}
                                    minusBatchCpMsg2={minusBatchCpMsg2}
                                    closeMinusBatchCpShow={closeMinusBatchCpShow} saveBatchCp={saveBatchCp}/>
                    :
                    plusBatchCpShowSt ?
                        <MsgSelectModal plusBatchCpShowSt={plusBatchCpShowSt} plusBatchCpMsg1={plusBatchCpMsg1}
                                        plusBatchCpMsg2={plusBatchCpMsg2}
                                        closePlusBatchCpShow={closePlusBatchCpShow} saveBatchCp={saveBatchCp}/>
                        :
                        null
            }
        </div>
    );
}

export default AdminDepositSetPg
