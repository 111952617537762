import React, {useEffect, useState} from "react";
import commonHeader from "../../assets/img/commonHeader.png";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import reviewUpload from "../../assets/img/reviewUpload.png";
import commonClose from "../../assets/img/commonClose.png";
import {BiCaretDown} from "react-icons/bi";
import {BsTrash} from "react-icons/bs";
import photoUpload from "../../assets/img/reviewUpload.png";

function AdminPopupModifyPg() {

    const navigate = useNavigate();
    const {seqNum} = useParams();
    const LCAT = localStorage.getItem('adminToken');

    const [adminInfo, setAdminInfo] = useState("");
    const findMember = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data)
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (LCAT) {
            findMember();
        }
    }, [LCAT])


    // copy
    const [titleErrorMsg, setTitleErrorMsg] = useState(false);
    const [contentErrorMsg, setContentErrorMsg] = useState(false);

    const [titleText, setTitleText] = useState('');
    const handleChangeTitleText = (e) => {
        setTitleText(e.target.value);
    };

    const [photoContainer, setPhotoContainer] = useState([{ photoImg: reviewUpload, photoImgSt: false, photoImgError: false, photoImgOriNm: '' }]);
    const selectPopupData = () =>
        axios({
            method: 'get',
            url: '/banner/selectBannerData',
            headers: {
                Authorization: LCAT
            },
            params: {
                seqNum: seqNum
            }
        })
            .then((response) => {
                setTitleText(response.data.title);
                const bannerPhoto = response.data.bannerPhotoDTO[0];
                if (bannerPhoto) {
                    setPhotoContainer({ photoImg: "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/bannerImg/" + bannerPhoto.fileNm, photoImgSt: true, photoImgError: false, photoImgOriNm: '' })
                }
            })
            .catch(() => {
                console.log("리뷰조회실패")
            })

    useEffect(() => {
        if (seqNum) {
            selectPopupData();
        }
    }, [seqNum])


    const photoImgHandle = (e) => {
        const file = e.target.files[0];

        if (file) {
            if (!file.type.startsWith('image/')) {
                setPhotoContainer({
                    ...photoContainer,
                    photoImgError: true,
                });
            } else {
                setPhotoContainer({
                    ...photoContainer,
                    photoImg: URL.createObjectURL(file),
                    photoImgSt: true,
                    photoImgError: false,
                    file: file
                });
            }
        }
    };


    const photoImgInit = () => {
        setPhotoContainer({ photoImg: photoUpload, photoImgSt: false, photoImgError: false });
    };

    const validation = () => {
        if (titleText === '' || !titleText) setTitleErrorMsg(true);
        return !!(titleText)
    }

    useEffect(() => {
        if (titleText.length > 0) {
            setTitleErrorMsg(false);
        }
    }, [titleText])

    const popupModify = async () => {

        const newFormData = new FormData();
        newFormData.append('title', titleText);
        newFormData.append('photoImg', photoContainer.file);

        if (photoContainer.removeImg) {
            const filename = photoContainer.removeImg.split('/').pop(); // URL에서 파일 이름만 추출
            newFormData.append('removeImg', encodeURIComponent(filename));
        }

        return axios({
            method: 'post',
            url: '/banner/bannerModify',
            data: newFormData,
            params: {
                bannerSeqNum: seqNum,
                type: '0',
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: LCAT
            },
        })
            .then(() => {
                navigate("/btcorpad/adminpopup")
            });
    }

    const valiAndSave = () => {
        if (validation()) {
            popupModify();
        }
    }

    const moveToReturn = (() => {
        navigate("/btcorpad/adminpopup")
    })

    return(
        <div className="AdminNoticePg" style={{padding:"0 200px"}}>
            <div className="admin-work-title">
                <h5>팝업수정</h5>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        <div style={{width:"100%"}}>
                            <div style={{display:"flex", alignItems:"center", justifyContent:"flex-end"}}>

                                <div style={{height:"40px", marginBottom:"20px", display:"flex", justifyContent:"flex-end"}}>
                                    <img onClick={moveToReturn} alt="close" style={{width:"35px", height:"35px", opacity:"0.6", cursor:"pointer"}} src={commonClose}/>
                                </div>
                            </div>
                            {/*copy*/}
                            <div>
                                <div style={{height: "10%", display: "flex"}}>
                                    <div style={{width: "100%"}}>
                                        <div className="ReviewPgSearchFrame acc-icon">
                                            <div className="ReviewPgInputForm" style={{width: "100%"}}>
                                                <input className="ReviewInput user-input"
                                                       style={{width: "100%", height: "100%", padding: "0 10px"}}
                                                       type="text"
                                                       placeholder="" value={titleText} onChange={handleChangeTitleText}
                                                       maxLength={60}/>
                                                <label className="JoinLabel"
                                                       style={{fontSize: "14px", color: "darkgray"}}>제목</label>
                                            </div>
                                        </div>
                                        <div className="JoinErrorMsg">
                                            {titleErrorMsg && <p className="JoinErrMsg">&nbsp;제목을 입력해주세요</p>}
                                        </div>
                                    </div>
                                </div>
                                <div style={{height: "auto", display: "flex", marginTop: "20px"}}>
                                    <div style={{width: "100%"}}>
                                        <div style={{
                                            border: "solid",
                                            height: "500px",
                                            borderWidth: "thin 1px",
                                            borderColor: "#EAEAEA",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            {!photoContainer.photoImgSt ?
                                                <div style={{display: "block", textAlign:"center"}} onClick={(e) => {
                                                    const parentDiv = e.target.parentNode;
                                                    const fileInput = parentDiv.querySelector('input[type="file"]');
                                                    if (fileInput) fileInput.click();
                                                }}>
                                                    <input type="file" accept="image/*" style={{display: "none"}} onChange={photoImgHandle}/>
                                                    <img alt="uploadImg" style={{
                                                        width: "80px",
                                                        height: "80px",
                                                        padding: "10px",
                                                        filter: "opacity(0.2) drop-shadow(0 0 0 gray)",
                                                    }} src={reviewUpload}/>
                                                    <p style={{color: "rgba(112,112,109,0.67)", fontSize: "18px"}}>Click me!</p>
                                                    <div className="JoinErrorMsg">
                                                        {photoContainer.photoImgError && <p className="JoinErrMsg">&nbsp;사진만 업로드해주세요😅</p>}
                                                    </div>
                                                </div>
                                                :
                                                <div className="user-center" style={{width:"100%", display:"flex", height:"100%", position:"relative", padding:"10px"}}>
                                                    <img alt="photoImg" style={{
                                                        display:"flex",
                                                        justifyContent:"center",
                                                        alignItems:"center",
                                                        maxWidth:"100%",
                                                        maxHeight:"100%",
                                                    }} src={photoContainer.photoImg}/>
                                                    <button className="orderItemHandleBt" style={{
                                                        position:"absolute",
                                                        width: "40px",
                                                        height: "40px",
                                                        bottom:"10px",
                                                        right:"10px",
                                                        borderRadius:"4px",
                                                        opacity:"0.6"
                                                    }} onClick={()=> photoImgInit(photoContainer.photoImg)}>
                                                        <i style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontSize:"26px"
                                                        }}><BsTrash/></i>
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    height: "100px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <button className="orderItemHandleBt" style={{width: "100px", height: "50px", borderRadius:"4px"}} onClick={valiAndSave}>작성완료</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminPopupModifyPg
