import React, {useEffect, useRef, useState} from "react";
import '../../css/web/AdminGuideSetPg.css';
import maCateImg from "../../assets/img/m-a-cate.png";
import {useNavigate, useParams} from "react-router-dom";
import {GoPlus} from "react-icons/go";
import axios from "axios";
import {BiCaretDown} from "react-icons/bi";
import DOMPurify from "dompurify";

function AdminGuideSetPg() {

    /** region DataSet */

    const { seqNum } = useParams();

    const LCAT = localStorage.getItem('adminToken');

    const [guideTitle, setGuideTitle] = useState('');
    const [guideCont, setGuideCont] = useState('');
    const [guideImages, setGuideImages] = useState([]);

    /** endregion */

    /** region Function */

    const contentRef = useRef(null);
    const navigate = useNavigate();
    const langRef = useRef(null);

    const [formError, setFormError] = useState(false);

    const [langList, setLangList] = useState(["한국어", "English", "日本語", "中文語", "Indonesian"]);
    const [selectedLang, setSelectedLang] = useState('');
    const [langShow, setLangShow] = useState(false);

    const serviceRef = useRef(null);
    const [serviceList, setServiceList] = useState(["장바구니", "구매대행", "배송대행", "이체대행", "오프라인", "국내배송"]);
    const [selectedService, setSelectedService] = useState('');
    const [serviceShow, setServiceShow] = useState(false);

    const [selectedImages, setSelectedImages] = React.useState([]);

    const toggleLangShow = () => {
        setLangShow(!langShow);
    };

    const handleLangChange = (mst) => {
        setSelectedLang(mst);
        setLangShow(false);
    };

    const toggleServiceShow = () => {
        setServiceShow(!langShow);
    };

    const handleServiceChange = (mst) => {
        setSelectedService(mst);
        setServiceShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (langRef.current && !langRef.current.contains(event.target)) {
                setLangShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            if (serviceRef.current && !serviceRef.current.contains(event.target)) {
                setServiceShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleGuideImageUpload = (event) => {
        const files = Array.from(event.target.files);
        setSelectedImages(prevImages => {
            const allImages = [...prevImages, ...files];
            return allImages;
        });

        const newImages = files.map(file => URL.createObjectURL(file));
        newImages.forEach(imageURL => {
            insertImageAtCursor(imageURL);
        });
    };

    // const handleGuideImageUpload = (event) => {
    //     const files = Array.from(event.target.files);
    //
    //     files.forEach(file => {
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             const imageURL = reader.result;
    //             insertImageAtCursor(imageURL);
    //         };
    //         reader.readAsDataURL(file);
    //     });
    // };

    const insertImageAtCursor = (imageURL) => {
        const imgElement = document.createElement("img");
        imgElement.src = imageURL;
        imgElement.style.maxWidth = "100%";
        imgElement.style.minWidth = "768px";
        imgElement.style.display = "block";

        const contentEditableDiv = contentRef.current;
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);

        if (range && range.commonAncestorContainer === contentEditableDiv) {
            range.deleteContents();
            range.insertNode(imgElement);
            range.setStartAfter(imgElement);
            range.setEndAfter(imgElement);
            selection.removeAllRanges();
            selection.addRange(range);
            setGuideImages(prevImages => [...prevImages, imageURL]);
        } else {
            contentEditableDiv.appendChild(imgElement);
            setGuideImages(prevImages => [...prevImages, imageURL]);
        }
        // handleContentChange();
    };

    useEffect(() => {
        if (contentRef.current && !guideCont) {
            contentRef.current.innerHTML = '<p>본문 내용을 입력하세요.</p>';
        }
    }, [guideCont]);

    const handleContentChange = () => {
        if (contentRef.current) {
            const content = contentRef.current.innerHTML.trim();
            setGuideCont(content);
            if (!content || content === '<p>본문 내용을 입력하세요.</p>') {
                contentRef.current.innerHTML = '<p>본문 내용을 입력하세요.</p>';
            }
        }
    };

    // const handleContentChange = () => {
    //     if (contentRef.current) {
    //         const content = contentRef.current.innerHTML.trim();
    //
    //         const sanitizedContent = DOMPurify.sanitize(content, {
    //             ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'p', 'img', 'div', 'span'],
    //             ALLOWED_ATTR: ['href', 'src', 'alt', 'title', 'style', 'class'],
    //         });
    //
    //         setGuideCont(sanitizedContent);
    //
    //         if (!sanitizedContent || sanitizedContent === '<p>본문 내용을 입력하세요.</p>') {
    //             contentRef.current.innerHTML = '<p>본문 내용을 입력하세요.</p>';
    //         }
    //     }
    // };

    const convertToLang = ((lang) => {
        if (lang === "0") return "한국어"
        if (lang === "1") return "English"
        if (lang === "2") return "日本語"
        if (lang === "3") return "中文語"
        if (lang === "4") return "Indonesian"
    })

    /** endregion */

    /** region Event */

    useEffect(() => {
        return () => {
            selectedImages.forEach(image => URL.revokeObjectURL(image));
        };
    }, [selectedImages]);

    useEffect(() => {
        if (seqNum) {
            axios.get(`/guide/getSelectGuideData?seqNum=${seqNum}`)
                .then(response => {
                    const data = response.data;
                    const serviceType = data.gtype;
                    const langName = convertToLang(data.glang);

                    setGuideTitle(data.gtitle);
                    setGuideCont(data.gcont);
                    setSelectedLang(langName);

                    let serviceName = "";
                    switch (serviceType) {
                        case "P00":
                            serviceName = "장바구니";
                            break;
                        case "P01":
                            serviceName = "구매대행";
                            break;
                        case "P02":
                            serviceName = "오프라인";
                            break;
                        case "P03":
                            serviceName = "이체대행";
                            break;
                        case "P04":
                            serviceName = "국내배송";
                            break;
                        case "P05":
                            serviceName = "배송대행";
                            break;
                        default:
                            serviceName = serviceType;
                    }
                    setSelectedService(serviceName);
                    const guidePhoto = data.userGuidePhotoDTOS[0];

                    if (guidePhoto) {
                        console.log("guidePhoto22222 : ", guidePhoto)
                        setGuideImages({ photoImg: "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/useGuide/" + guidePhoto.fileNm, photoImgSt: true, photoImgError: false, photoImgOriNm: '' })
                    }
                    console.log("data : ", data)
                    setGuideImages(data.guideImages || []);
                    console.log("setGuideImages : ", guideImages)

                })
                .catch(error => {
                    console.error("수정data err:", error);
                });
        } else {
            setGuideTitle("");
            setGuideCont("");
            setSelectedLang("");
            setSelectedService("");
            setGuideImages([]);
        }
    }, [seqNum]);

    const guideSave = () => {
        let hasError = false;

        if (!selectedService || selectedService === "서비스") {
            hasError = true;
        }

        if (!selectedLang || selectedLang === "언어") {
            hasError = true;
        }

        if (!guideTitle || guideTitle.trim() === '') {
            hasError = true;
        }

        const content = contentRef.current.innerHTML.trim();
        if (!content || content === '<p>본문 내용을 입력하세요.</p>' || content === '') {
            hasError = true;
        }

        if (hasError) {
            setFormError(true);
            return;
        } else {
            setFormError(false);
        }

        const newFormData = new FormData();

        newFormData.append('guideTitle', guideTitle);
        newFormData.append('guideCont', contentRef.current.innerHTML);

        selectedImages.forEach((file, index) => {
            newFormData.append('guideImg', file, file.name);
        });

        const convertToLang = ((lang) => {
            if (lang === "0") return "한국어"
            if (lang === "1") return "English"
            if (lang === "2") return "日本語"
            if (lang === "3") return "中文語"
            if (lang === "4") return "Indonesian"
        })

        const lang = selectedLang === "한국어" ? 0 : selectedLang === "English" ? 1
            : selectedLang === "日本語" ? 2 : selectedLang === "中文語" ? 3 : 4;

        const service = selectedService === "장바구니" ? "P00" : selectedService === "구매대행" ? "P01"
            : selectedService === "오프라인" ? "P02" : selectedService === "이체대행" ? "P03" : selectedService === "국내배송" ? "P04" : "P05";

        newFormData.append('gType', service);
        newFormData.append('gLang', lang);

        return axios({
            method: 'post',
            url: '/guide/saveGuide',
            data: newFormData,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: LCAT
            },
        })
            .then(async (response) => {
                navigate('/btcorpad/pageset');
            });
    }

    const guideModifySave = () => {
        const newFormData = new FormData();

        newFormData.append('guideTitle', guideTitle);
        newFormData.append('guideCont', contentRef.current.innerHTML);

        selectedImages.forEach((file) => {
            newFormData.append('guideImg', file, file.name);
        });

        const lang = selectedLang === "한국어" ? 0 : selectedLang === "English" ? 1
            : selectedLang === "Japanese" ? 2 : selectedLang === "Chinese" ? 3 : 4;

        const service = selectedService === "장바구니" ? "P00" : selectedService === "구매대행" ? "P01"
            : selectedService === "오프라인" ? "P02" : selectedService === "이체대행" ? "P03" : selectedService === "국내배송" ? "P04" : "P05";

        newFormData.append('gType', service);
        newFormData.append('gLang', lang);

        return axios({
            method: 'put',
            url: `/guide/saveGuide/${seqNum}`,
            data: newFormData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
                navigate('/btcorpad/pageset');
            })
            .catch((error) => {
                console.error("Modify guide error:", error);
            });
    }

    /** endregion */

    return (
        <div id="AdminGuideSetPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">이용가이드 관리</p>
            </div>
            <div className="admin-wrap font-t-cont">
                <div className="admin-wrap-card guide-container">
                    <div className="display-flex flex-column guide-frame">
                        <div className="gf-gbn m-b-20">
                            <div className="display-flex flex-row wd-30pc">
                                <div className="wd-150 ht-40 m-r-20">
                                    <div ref={serviceRef} className="ht-100pc wd-100pc position-relative">
                                        <button className="selectParentBt wd-100pc ht-100pc" onClick={toggleServiceShow}>
                                            <p>{selectedService === "" ? "서비스" : selectedService}</p>
                                            <span style={{position: "absolute", left: "80%", top: "0", height: "100%", zIndex:10, width: "20%"}}>
                                                <i style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}><BiCaretDown/></i>
                                            </span>
                                        </button>
                                        {serviceShow ?
                                            <div className="wd-100pc ht-200 m-t-5 position-absolute" style={{
                                                overflowY:"scroll",
                                                border: "solid 1px lightgray",
                                                top: "100%",
                                                bottom: "100%",
                                                zIndex: 1,
                                            }}>
                                                {serviceList.map((service, index) => (
                                                    <div className="selectChildBt display-flex just-cont-center c-point font-s-14 align-center" key={index} value={service}
                                                         onClick={() => handleServiceChange(service)} style={{padding: "10px"}}>
                                                        {service}
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                <div className="wd-150 ht-40">
                                    <div ref={langRef} className="ht-100pc wd-100pc position-relative">
                                        <button className="selectParentBt wd-100pc ht-100pc" onClick={toggleLangShow}>
                                            <p>{selectedLang === "" ? "언어" : selectedLang}</p>
                                            <span style={{position: "absolute", left: "80%", top: "0", height: "100%", zIndex:10, width: "20%"}}>
                                                        <i style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            height: "100%"
                                                        }}><BiCaretDown/></i>
                                                    </span>
                                        </button>
                                        {langShow ?
                                            <div className="wd-100pc ht-200 m-t-5 position-absolute" style={{
                                                overflowY:"scroll",
                                                border: "solid 1px lightgray",
                                                top: "100%",
                                                bottom: "100%",
                                                zIndex: 1,
                                            }}>
                                                {langList.map((lang, index) => (
                                                    <div className="selectChildBt display-flex just-cont-center c-point font-s-14 align-center" key={index} value={lang}
                                                         onClick={() => handleLangChange(lang)} style={{padding: "10px"}}>
                                                        {lang}
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="wd-70pc display-flex flex-row just-cont-fend text-right">
                                {formError && <div className="font-c-cancel font-s-16 p-t-9 m-r-7">선택항목 또는 입력란을 확인해주세요.</div>}
                                {seqNum ?
                                    <button className="admin-bt-color ht-40 wd-120" type="submit" onClick={guideModifySave}>저장</button>
                                    :
                                    <button className="admin-bt-color ht-40 wd-120" type="submit" onClick={guideSave}>등록</button>
                                }
                            </div>
                        </div>
                        <div className="gf-title m-t-20">
                            <input className="gf-title-input font-t-title" type="text" id="guideTitle" placeholder="제목" maxLength="30"
                                   value={guideTitle}
                                   onChange={(e) => setGuideTitle(e.target.value)}
                            />
                        </div>
                        <div className="gf-cont p-b-10">
                            <div className="gf-cont-form p-b-40 p-l-15 p-r-15" contentEditable="true" ref={contentRef} dangerouslySetInnerHTML={{ __html: guideCont }}
                                onFocus={() => {
                                    const content = contentRef.current.innerHTML.trim();
                                    if (content === '<p>본문 내용을 입력하세요.</p>' || content === '') {
                                        contentRef.current.innerHTML = '';
                                    }
                                }}
                                onBlur={() => {
                                    handleContentChange();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="g-file-upload">
                <span className="g-img-upload" onClick={() => document.getElementById('image').click()}><GoPlus className="font-c-default font-s-21 m-t-2"/></span>
                <input type="file" id="image" accept="image/*" multiple onChange={handleGuideImageUpload} style={{ display: 'none' }} />
            </div>
        </div>
    );
}
export default AdminGuideSetPg;
