import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {BiCaretDown} from "react-icons/bi";
import commonHeader from "../../assets/img/commonHeader.png";
import weight from "../../assets/img/weight.png";
import {useTranslation} from "react-i18next";

function UserCompareShipPg() {

    const { t } = useTranslation();

    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({value: '', label: t('modSmallMenu3')});

    const [countryShow, setCountryShow] = useState(false);
    const countryRef = useRef(null);

    const toggleCountryShow = () => {
        setCountryShow(!countryShow);
    };

    const handleCountryChange = (country) => {
        console.log(country)
        setSelectedCountry(country);
        setCountryShow(false);
    };


    useEffect(() => {
        function handleClickOutside(event) {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setCountryShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const selectCountry = (() => {
        axios({
            method: 'get',
            url: '/order/selectCountry',
            // headers: {
            //     Authorization: LCAT ? LCAT : SSAT
            // },
        })
            .then((response) => {
                // const countries = response.data.map(country => ({
                //     value: country.countryCode,
                //     label: country.countryNm + '(' + country.countryEngNm + ')'
                // }));
                // setCountryList(countries);
                const countries = response.data
                    .map(country => ({
                        value: country.countryCode,
                        label: `${country.countryNm}(${country.countryEngNm})`,
                        engName: country.countryEngNm
                    }))
                    .sort((a, b) => a.engName.localeCompare(b.engName));

                setCountryList(countries.map(country => ({
                    value: country.value,
                    label: country.label
                })));
            })
            .catch((error) => {
                console.log(error)
            })
    })

    useEffect(() => {
        selectCountry();
    }, [])

    const [realWeight, setRealWeight] = useState('');
    const [wid, setWid] = useState('');
    const [len, setLen] = useState('');
    const [hei, setHei] = useState('');

    const [fedexAppWeight, setFedExAppWeight] = useState('');
    const [dhlAppWeight, setDhlAppWeight] = useState('');
    const [emsAppWeight, setEmsAppWeight] = useState('');
    const [upsAppWeight, setUpsAppWeight] = useState('');
    const [kpackAppWeight, setKpackAppWeight] = useState('');

    const [fedexPrice, setFedexPrice] = useState('');
    const [dhlPrice, setDhlPrice] = useState('');
    const [emsPrice, setEmsPrice] = useState('');
    const [upsPrice, setUpsPrice] = useState('');
    const [kpackPrice, setKpackPrice] = useState('');
    const [smallPackPrice, setSmallPackPrice] = useState('');
    const [seaPrice, setSeaPrice] = useState('');

    const initWeight = (() => {
        setRealWeight('');
        setWid('');
        setLen('');
        setHei('');
    })

    const findPrice = (weight, priceList) => {
        const weightInGrams = Math.round(weight * 1000); // kg를 그램으로 변환, 반올림 처리
        const weightKeys = Object.keys(priceList).map(key => parseInt(key.substring(1))); // "w500" -> 500
        const sortedWeights = weightKeys.sort((a, b) => a - b);

        for (let i = 0; i < sortedWeights.length; i++) {
            if (weightInGrams <= sortedWeights[i]) {
                return priceList[`w${sortedWeights[i]}`]; // 해당 무게에 맞는 가격 반환
            }
        }
        return t('cspWtNoSurch'); // 무게구간에 해당하지 않을 경우 '별도문의' 반환
    };

    // 실무게&가로&세로&높이 모두 입력될때만 DB에 조회쿼리
    useEffect(() => {
        if (selectedCountry.value !== '' && realWeight !== '' && wid !== '' && len !== '' && hei !== '') {
            selectedShipFee(selectedCountry.value);
        }
    }, [selectedCountry, realWeight, wid, len, hei])

    const selectedShipFee = ((crCd) => {
        axios({
            method: 'get',
            url: '/shipinfo/selectedShipFee',
            params: {
                crCd: crCd
            }
        })
            .then((response) => {
                const data = response.data;

                const divideBy6000 = wid * len * hei / 6000;
                const divideBy5000 = wid * len * hei / 5000;
                const fedexAppWeight = Math.max(realWeight, divideBy5000);
                const upsAppWeight = Math.max(realWeight, divideBy5000);
                const dhlAppWeight = Math.max(realWeight, divideBy5000);
                const emsAppWeight = Math.max(realWeight, divideBy6000);
                const kpackAppWeight = Math.max(realWeight, divideBy6000);
                const smallPackAppWeight = Math.max(realWeight);
                const seaAppWeight = Math.max(realWeight);
                // 위 두 계산값 모두 소숫점 2자리까지만 출력
                const formattedFedexAppWeight = parseFloat(fedexAppWeight.toFixed(2));
                const formattedUpsAppWeight = parseFloat(upsAppWeight.toFixed(2));
                const formattedDhlAppWeight = parseFloat(dhlAppWeight.toFixed(2));
                const formattedEmsAppWeight = parseFloat(emsAppWeight.toFixed(2));
                const formattedKpackAppWeight = parseFloat(kpackAppWeight.toFixed(2));
                const formattedSmallPackAppWeight = parseFloat(smallPackAppWeight.toFixed(2));
                const formattedSeaAppWeight = parseFloat(seaAppWeight.toFixed(2));

                setFedExAppWeight(formattedFedexAppWeight);
                setUpsAppWeight(formattedUpsAppWeight);
                setDhlAppWeight(formattedDhlAppWeight);
                setEmsAppWeight(formattedEmsAppWeight);
                setKpackAppWeight(formattedKpackAppWeight);

                // 가격찾고 서비스하지 않는 국가로 인해 null이면 '별도문의 반환'
                let fedexPrice = data.exrInfoFEDEXDTO ? findPrice(formattedFedexAppWeight, data.exrInfoFEDEXDTO) : t('cspWtNoSurch');
                let upsPrice = data.exrInfoUPSDTO ? findPrice(formattedUpsAppWeight, data.exrInfoUPSDTO) : t('cspWtNoSurch');
                let dhlPrice = data.exrInfoDHLDTO ? findPrice(formattedDhlAppWeight, data.exrInfoDHLDTO) : t('cspWtNoSurch');
                let emsPrice = data.exrInfoEMSDTO ? findPrice(formattedEmsAppWeight, data.exrInfoEMSDTO) : t('cspWtNoSurch');
                let kpackPrice = data.exrInfoEMSDTO ? findPrice(formattedKpackAppWeight, data.exrInfoEMSDTO) : t('cspWtNoSurch');
                let smallPackPrice = data.exrInfoEMSDTO ? findPrice(realWeight, data.exrInfoEMSDTO) : t('cspWtNoSurch');
                let seaPrice = data.exrInfoSHIPDTO ? findPrice(realWeight, data.exrInfoSHIPDTO) : t('cspWtNoSurch');

                // fedex 유류할증료 합산
                if (typeof fedexPrice === "number" && data.countryDTO && data.countryDTO.fedexFuelSurcharge > 0) {
                    // 백분율을 소수점으로 변환하여 fedexPrice에서 fedexFuelSurcharge값만큼 fedexPrice에 +=
                    const surchargeRate = data.countryDTO.fedexFuelSurcharge / 100;
                    const fuelSurcharge = fedexPrice * surchargeRate;
                    const roundedFuelSurcharge = Math.round(fuelSurcharge);
                    // 소숫점 제외한 전체 결과값인 fuelSurcharge값을 산출
                    fedexPrice += roundedFuelSurcharge;
                    // 전체 결과값에서 10원 단위로 반올림
                    fedexPrice = Math.round(fedexPrice / 10) * 10;
                }
                // fedex 운송수수료 합산
                if (typeof fedexPrice === "number" && data.countryDTO.fedexFee > 0) {
                    const additionalFedexFee = formattedFedexAppWeight * 10 * data.countryDTO.fedexFee;
                    fedexPrice += additionalFedexFee;
                }

                // ups 운송수수료 합산
                if (typeof upsPrice === "number" && data.countryDTO.upsFee > 0) {
                    const additionalUpsFee = formattedUpsAppWeight * 10 * data.countryDTO.upsFee;
                    upsPrice += additionalUpsFee;
                }

                // dhl 유류할증료 합산
                if (typeof dhlPrice === "number" && data.countryDTO && data.countryDTO.dhlFuelSurcharge > 0) {
                    // 백분율을 소수점으로 변환하여 dhlPrice에서 dhlFuelSurcharge값만큼 dhlPrice에 +=
                    const surchargeRate = data.countryDTO.dhlFuelSurcharge / 100;
                    const fuelSurcharge = dhlPrice * surchargeRate;
                    const roundedFuelSurcharge = Math.round(fuelSurcharge);
                    // 소숫점 제외한 전체 결과값인 fuelSurcharge값을 산출
                    dhlPrice += roundedFuelSurcharge;
                    // 전체 결과값에서 10원 단위로 반올림
                    dhlPrice = Math.round(dhlPrice / 10) * 10;
                }
                // dhl 운송수수료 합산
                if (typeof dhlPrice === "number" && data.countryDTO.dhlFee > 0) {
                    const additionalDhlFee = formattedFedexAppWeight * 10 * data.countryDTO.dhlFee;
                    dhlPrice += additionalDhlFee;
                }

                // ems 운송수수료 합산
                if (typeof emsPrice === "number" && data.countryDTO.emsFee > 0) {
                    const additionalEmsFee = formattedEmsAppWeight * 10 * data.countryDTO.emsFee;
                    emsPrice += additionalEmsFee;
                }

                // K-Packet 운송수수료 합산
                if (typeof kpackPrice === "number" && data.countryDTO.kpackFee > 0) {
                    const additionalKpackFee = formattedKpackAppWeight * 10 * data.countryDTO.kpackFee;
                    kpackPrice += additionalKpackFee;
                }

                // Small-Packet 운송수수료 합산
                if (typeof smallPackPrice === "number" && data.countryDTO.airSFee > 0) {
                    const additionalSmallPackFee = formattedSmallPackAppWeight * 10 * data.countryDTO.airSFee;
                    smallPackPrice += additionalSmallPackFee;
                }

                // sea 운송수수료 합산
                if (typeof seaPrice === "number" && (data.countryDTO.countryCode === "US" || data.countryDTO.countryCode === "CA")) {
                    const additionalSeaFee = formattedSeaAppWeight * 10 * data.countryDTO.seaFee;
                    seaPrice += additionalSeaFee;
                }

                setFedexPrice(fedexPrice);
                setUpsPrice(upsPrice);
                setDhlPrice(dhlPrice);
                setEmsPrice(emsPrice);
                setKpackPrice(kpackPrice);
                setSmallPackPrice(smallPackPrice);
                setSeaPrice(seaPrice);
            })
            .catch((error) => {
                console.log("배송사별 요금조회 실패 : ", error)
            })
    })

    return (
        <div>
            <div style={{
                minHeight: "700px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <div>
                    <div style={{display:"flex", height: "40px", justifyContent:"space-between",}}>
                        <div style={{display:"flex", justifyContent:"center"}}>
                            <img className="wd-30 ht-30" src={weight} alt="header"/>
                            <p className="font-t-title m-l-10 font-s-18 m-t-3">{t(`cspHeader`)}</p>
                        </div>
                    </div>
                    <div className="font-t-cont" style={{
                        width: "500px",
                        // height: "550px",
                        border:"solid",
                        borderWidth: "thin 1px",
                        borderColor: "#EAEAEA",
                        borderRadius: "6px",
                        padding: "20px"
                    }}>
                        <div style={{
                            borderBottom: "solid",
                            borderWidth: "thin 1px",
                            borderColor: "#EAEAEA",
                            height: "50px",
                            display: "flex",
                            alignItems: "center",
                            marginTop: "10px"
                        }}><p style={{margin: "0", fontSize:"15px"}}>{t(`cspSmallHeader1`)}</p></div>
                        {/*국가*/}
                        <div style={{height: "50px", display:"flex", marginTop:"10px"}}>
                            <p style={{fontSize:"14px", fontWeight:"lighter", width:"40%", display:"flex", alignItems:"center", height:"100%"}}>{t(`modSmallMenu3`)}</p>
                            <div style={{width:"60%"}}>
                                {/*여기서부터 커스텀 국가셀렉트*/}
                                <div ref={countryRef} style={{height: "90%", width: "100%", position: "relative", paddingTop:"6px"}}>
                                    <button className="selectParentBt" style={{width: "100%", height: "90%"}}
                                            onClick={toggleCountryShow}>
                                        <p style={{margin: "0"}}>{selectedCountry.label}</p>
                                        <span style={{position: "absolute", left: "85%", top: "5%", height: "100%", width: "20%"}}>
                                    <i style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%"
                                    }}><BiCaretDown/></i>
                                </span>
                                    </button>
                                    {countryShow ?
                                        <div style={{
                                            border: "solid",
                                            height: "400px",
                                            borderWidth: "thin 1px",
                                            borderColor: "lightgray",
                                            marginTop: "5px",
                                            overflowY: "scroll",
                                        }}>
                                            {countryList.map((country, index) => (
                                                <div className="selectChildBt" key={index}
                                                     onClick={() => handleCountryChange(country)} style={{
                                                    display: "flex", padding: "10px", justifyContent: "center",
                                                    alignItems: "center", cursor: "pointer", fontSize: "14px", borderRadius: "4px",
                                                }}>{country.label}</div>
                                            ))}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                {/*여기까지 커스텀 국가셀렉트*/}
                            </div>
                        </div>
                        {/*실무게*/}
                        <div style={{height: "50px", display:"flex", alignItems:"center"}}>
                            <div style={{width:"40%",display:"flex", height:"100%", alignItems:"center"}}>
                            <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter",display:"flex", alignItems:"center"}}>{t(`cspRw`)}(kg)</p>
                            </div>
                            <div className="user-input-container" style={{width:"60%", display:"flex", alignItems:"center", justifyContent:"center", height:"40px"}}>
                                <input type="text" className="user-input" style={{
                                    width: "100%", height: "35px", borderRadius: "4px",
                                    padding: "0 6px 0 6px", fontSize:"14px"
                                }} value={realWeight} onChange={(e)=> setRealWeight(e.target.value)}/>
                            </div>
                        </div>
                        {/*부피무게*/}
                        <div style={{height: "50px", display:"flex"}}>
                            <p style={{fontSize:"14px", fontWeight:"lighter", width:"40%", display:"flex", alignItems:"center", height:"100%"}}>{t(`cspV`)}(cm)</p>
                            <div style={{width:"60%", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                <div className="user-input-container user-center" style={{width:"30%", height:"35px"}}>
                                <input type="text" className="user-input" style={{
                                    width: "100%", height: "100%", borderRadius: "4px", fontSize:"14px", padding:"0 5px"
                                }} value={wid} onChange={(e)=> setWid(e.target.value)}/>
                                </div>
                                <span style={{display:"flex", justifyContent:"center", marginTop:"2%"}}>*</span>
                                <div className="user-input-container user-center" style={{width:"30%", height:"35px"}}>
                                    <input type="text" className="user-input" style={{
                                        width: "100%", height: "100%", borderRadius: "4px", fontSize:"14px", padding:"0 5px"
                                    }} value={len} onChange={(e)=> setLen(e.target.value)}/>
                                </div>
                                <span style={{display:"flex", justifyContent:"center", marginTop:"2%"}}>*</span>
                                <div className="user-input-container user-center" style={{width:"30%", height:"35px"}}>
                                    <input type="text" className="user-input" style={{
                                        width: "100%", height: "100%", borderRadius: "4px", fontSize:"14px", padding:"0 5px"
                                    }} value={hei}  onChange={(e)=> setHei(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        {/*적용무게*/}
                        <div style={{height: "50px", display:"flex"}}>
                            <p style={{fontSize:"14px", fontWeight:"lighter", width:"40%", display:"flex", alignItems:"center", height:"100%"}}>{t(`cspVw`)}</p>
                            {emsAppWeight && fedexAppWeight ?
                                <div style={{width: "60%", display: "flex", alignItems: "center", fontSize: "14px"}}>
                                    <span style={{color: "dimgray"}}>{emsAppWeight}kg</span>
                                    <span style={{padding: "0 5px"}}>~</span>
                                    <span style={{color: "dimgray"}}>{fedexAppWeight}kg</span>
                                </div>
                                :
                                null
                            }
                        </div>
                        <div style={{height:"50px", margin:"20px 0"}}>
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%"}}>
                                <button className="ReviewPgWriteBtn" style={{
                                    minWidth: "70px",
                                    height: "35px",
                                    fontSize: "13px",
                                    fontWeight:"bold",
                                    borderRadius:"4px",
                                    paddingLeft:"5px",
                                    paddingRight:"5px"
                                }} onClick={initWeight}>{t(`cspBt`)}
                                </button>
                            </div>
                        </div>
                        <div style={{
                            borderBottom: "solid",
                            borderWidth: "thin 1px",
                            borderColor: "#EAEAEA",
                            height: "50px",
                            display: "flex",
                            alignItems: "center",
                            marginTop: "20px"
                        }}><p style={{margin: "0", fontSize:"15px"}}>{t(`cspSmallHeader2`)}</p></div>

                        {/*FedEx 배송료*/}
                        <div style={{height: "35px", display:"flex", marginTop:"10px"}}>
                            <div style={{width:"70%", display:"flex", alignItems:"center"}}>
                            <p style={{margin:"0", fontSize:"13px", fontWeight:"lighter", display:"flex", alignItems:"center", color:"black"}}>FedEx</p>
                                { fedexAppWeight ?
                                <span style={{fontSize:"12px", color:"dimgray", marginLeft:"5px"}}>({t(`cspAw`)}
                                    <span style={{fontSize:"12px", fontWeight:"bold", color:"darkorange"}}> {fedexAppWeight}</span>kg)</span>
                                    :
                                    null
                                }
                            </div>
                            <div style={{width:"30%", fontSize:"13px", display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
                                {/*추후 FedEx 배송료+유류할증료 연동*/}
                                <span style={{color: !fedexPrice ? "black" : "orange", fontWeight: fedexPrice ? "bold" : null}}>{fedexPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                            </div>
                        </div>

                        {/*UPS 배송료*/}
                        <div style={{height: "35px", display:"flex"}}>
                            <div style={{width:"70%", display:"flex", alignItems:"center"}}>
                                <p style={{fontSize:"13px", fontWeight:"lighter", display:"flex", alignItems:"center", color:"black"}}>UPS</p>
                                { upsAppWeight ?
                                    <span style={{fontSize:"12px", color:"dimgray", marginLeft:"5px"}}>({t(`cspAw`)}
                                        <span style={{fontSize:"12px", fontWeight:"bold", color:"darkorange"}}> {upsAppWeight}</span>kg)</span>
                                    :
                                    null
                                }
                            </div>
                            <div style={{width:"30%", fontSize:"13px", display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
                                <span style={{color: !upsPrice ? "black" : "orange", fontWeight: upsPrice ? "bold" : null}}>{upsPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                            </div>
                        </div>

                        {/*/!*DHL 배송료*!/*/}
                        {/*<div style={{height: "35px", display:"flex"}}>*/}
                        {/*    <div style={{width:"70%", display:"flex", alignItems:"center"}}>*/}
                        {/*        <p style={{fontSize:"13px", fontWeight:"lighter", display:"flex", alignItems:"center", color:"black"}}>DHL</p>*/}
                        {/*        { dhlAppWeight ?*/}
                        {/*            <span style={{fontSize:"12px", color:"dimgray", marginLeft:"5px"}}>({t(`cspAw`)}*/}
                        {/*                <span style={{fontSize:"12px", fontWeight:"bold", color:"darkorange"}}> {dhlAppWeight}</span>kg)</span>*/}
                        {/*            :*/}
                        {/*            null*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*    <div style={{width:"30%", fontSize:"13px", display:"flex", alignItems:"center", justifyContent:"flex-end"}}>*/}
                        {/*        <span style={{color: !dhlPrice ? "black" : "orange", fontWeight: dhlPrice ? "bold" : null}}>{dhlPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*EMS 배송료*/}
                        <div style={{height: "35px", display:"flex"}}>
                            <div style={{width:"70%", display:"flex", alignItems:"center"}}>
                                <p style={{margin:"0", fontSize:"13px", fontWeight:"lighter", display:"flex", alignItems:"center", color:"black"}}>EMS</p>
                                { emsAppWeight ?
                                <span style={{fontSize:"12px", color:"dimgray", marginLeft:"5px"}}>({t(`cspAw`)}
                                    <span style={{fontSize:"12px", fontWeight:"bold", color:"darkorange"}}> {emsAppWeight}</span>kg)</span>
                                    :
                                    null
                                }
                            </div>
                            <div style={{width:"30%", fontSize:"13px", display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
                                {/*추후 EMS 배송료+코로나수수료 연동*/}
                                <span style={{color: !emsPrice ? "black" : "orange", fontWeight: emsPrice ? "bold" : null}}>{emsPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                            </div>
                        </div>

                        {/*K-P 배송료*/}
                        <div style={{height: "35px", display:"flex"}}>
                            <div style={{width:"70%", display:"flex", alignItems:"center"}}>
                                <p style={{fontSize:"13px", fontWeight:"lighter", display:"flex", alignItems:"center", color:"black"}}>K-Packet</p>
                                { kpackAppWeight ?
                                    <span style={{fontSize:"12px", color:"dimgray", marginLeft:"5px"}}>({t(`cspAw`)}
                                        <span style={{fontSize:"12px", fontWeight:"bold", color:"darkorange"}}> {kpackAppWeight}</span>kg)</span>
                                    :
                                    null
                                }
                            </div>
                            <div style={{width:"30%", fontSize:"13px", display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
                                <span style={{color: !kpackPrice ? "black" : "orange", fontWeight: kpackPrice ? "bold" : null}}>{kpackPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                            </div>
                        </div>

                        {/*Small-Packet 배송료*/}
                        <div style={{height: "35px", display:"flex"}}>
                            <div style={{width:"70%", display:"flex", alignItems:"center"}}>
                                <p style={{fontSize:"13px", fontWeight:"lighter", display:"flex", alignItems:"center", color:"black"}}>Small-Packet</p>
                                { realWeight ?
                                    <span style={{fontSize:"12px", color:"dimgray", marginLeft:"5px"}}>({t(`cspAw`)}
                                        <span style={{fontSize:"12px", fontWeight:"bold", color:"darkorange"}}> {realWeight}</span>kg)</span>
                                    :
                                    null
                                }
                            </div>
                            <div style={{width:"30%", fontSize:"13px", display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
                                <span style={{color: !smallPackPrice ? "black" : "orange", fontWeight: smallPackPrice ? "bold" : null}}>{smallPackPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                            </div>
                        </div>

                        {/*선편 배송료*/}
                        <div style={{height: "35px", display:"flex"}}>
                            <div style={{width:"70%", display:"flex", alignItems:"center"}}>
                                <p style={{fontSize:"13px", fontWeight:"lighter", display:"flex", alignItems:"center", color:"black"}}>Sea Parcel</p>
                                { realWeight ?
                                    <span style={{fontSize:"12px", color:"dimgray", marginLeft:"5px"}}>({t(`cspAw`)}
                                        <span style={{fontSize:"12px", fontWeight:"bold", color:"darkorange"}}> {realWeight}</span>kg)</span>
                                    :
                                    null
                                }
                            </div>
                            <div style={{width:"30%", fontSize:"13px", display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
                                {/*추후 선편배송료 연동*/}
                                <span style={{color: !seaPrice ? "black" : "orange", fontWeight: seaPrice ? "bold" : null}}>{seaPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                            </div>
                        </div>

                        <div style={{marginTop:"15px", height:"20px"}}><span style={{fontSize:"11px", color:"dimgray"}}>※ {t('cspNt1')}</span></div>
                        <div><span style={{fontSize:"11px", color:"#4B89DC"}}>※ {t('cspNt2')}</span></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserCompareShipPg
