import "../../css/mobile/MUserReviewPg.css";
import MainReview from "../../assets/img/commonHeader.png"
import {useNavigate} from "react-router-dom";
import MHeader from "../../components/mobile/MHeader";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import axios from "axios";
import noReviewPhoto from "../../assets/img/btorage_noimage.png";

const MUserReviewPg = () => {
    const {t} = useTranslation();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');
    const [hasClickedMore, setHasClickedMore] = useState(false);
    const navigate = useNavigate();

    const [reviewAllList, setReviewAllList] = useState([]);
    const [filteredReviews, setFilteredReviews] = useState([]);
    const [displayLimit, setDisplayLimit] = useState(30);
    const [searchText, setSearchText] = useState('');

    const selectReviewAllList = () =>
        axios({
            method: 'get',
            url: '/review/selectReviewAllList',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.seqNum - a.seqNum);
                setReviewAllList(sortedData)
                setFilteredReviews(response.data.slice(0, displayLimit));
            })

    useEffect(() => {
        selectReviewAllList();
    }, [])

    const formatDate = (dateStr) => {
        if (dateStr.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchText(value);
        const filtered = reviewAllList.filter(review =>
            review.title.toLowerCase().includes(value) ||
            review.country.toLowerCase().includes(value) ||
            review.shipco.toLowerCase().includes(value)
        );
        setFilteredReviews(filtered.slice(0, displayLimit));
    }

    const handleMore = () => {
        setDisplayLimit(prev => prev + 30);
        setFilteredReviews(reviewAllList.slice(0, displayLimit + 30));
        setHasClickedMore(true);
    }

    const newTextPrintFl = (regDate) => {
        // 오늘 날짜 객체를 생성
        const today = new Date();
        const todayFormatted = `${today.getFullYear()}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getDate().toString().padStart(2, '0')}`;
        // 입력된 날짜와 오늘 날짜를 비교
        return regDate === todayFormatted;
    };

    return (
        <div className='m-a-user-review-pg-container'>
            <MHeader title={t(`hdMenu5`)} url={"/"}/>
            <div className='m-a-user-review-pg-sub-title'>
                <img className='m-a-user-review-pg-sub-title-icon m-b-4' src={MainReview} alt=''/>
                <div className='m-a-user-review-pg-sub-title-text font-t-title'>{t(`rvHeader`)}</div>
            </div>
            <div className='m-a-user-review-pg-divider'/>
            <div className='m-a-user-review-pg-top-frame font-t-cont'>
                <input className='m-a-user-review-pg-top-input'
                       placeholder={t(`rvPlaceHd`)}
                       onChange={handleSearch}/>
                <div className='m-a-user-review-pg-top-button'
                     onClick={() => {
                         navigate('/UserReviewWritePg')
                     }}> {t(`rvdtBt`)}
                </div>
            </div>
            <div className='m-a-user-review-pg-review-frame font-t-cont'>
                {filteredReviews.slice(0, displayLimit).map((review, index) => (
                    <div className='m-a-user-review-pg-review-item' key={index}
                         onClick={() => {
                             navigate(`/UserReviewDetailPg/${review.seqNum}`)
                         }}>
                        {review && review.reviewPhotoDTO[0] && review.reviewPhotoDTO[0].fileNm ? (
                            <img className='m-a-user-review-pg-review-img'
                                 src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/reviewPhoto/" + review.reviewPhotoDTO[0].fileNm}
                                 alt='Img'/>
                        ) : (
                            <img className='m-a-user-review-pg-review-img' src={noReviewPhoto} alt='Img' style={{backgroundColor: "#f6f6f6"}}/>
                        )}
                        <div className='m-a-user-review-pg-review-sub'>[{review.country}-{review.shipco}]</div>
                        <div className='m-a-user-review-pg-review-title'>{review.title}</div>
                        <div className='m-a-user-review-pg-review-data'>
                            <div className='m-a-user-review-pg-review-date'>{formatDate(review.regDate)}</div>
                            {newTextPrintFl(review.regDate) && (
                                <div className='m-a-user-review-pg-review-new'>New</div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {
                ((!hasClickedMore && filteredReviews.length === displayLimit) ||
                    (hasClickedMore && displayLimit < reviewAllList.length)) &&
                <div className={'m-a-user-review-pg-more'} onClick={handleMore}> More</div>
            }
        </div>
    )
}

export default MUserReviewPg;
