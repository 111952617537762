import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../../css/mobile/MAdmin.css';
import '../../css/mobile/MAdminModiPg.css';
import back from "../../assets/img/commonBack.png";
import {useNavigate} from "react-router-dom";
import AdminProfileBase from "../../assets/img/AdminProfileBase.jpg";
import axios from "axios";
import {BiEdit, BiTrash} from "react-icons/bi";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import useTheme from "../../components/web/layoutscommon/DarkModeStorage";

function MAdminModiPg() {

    /** region DataTable */

    const [adminUserdata, setAdminUserData] = useState([]);
    const [userProfileImg, setUserProfileImg] = useState("");
    const [userId, setUserId] = useState("");
    const [userName, setUserName] = useState("");
    const [userNicName, setUserNicName] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [userMbNum, setUserMbNum] = useState("");
    const [userSeqNum, setUserSeqNum] = useState("");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    /** endregion */

    /** region Function */

    //region 다크모드

    const [theme, themeToggler] = useTheme();

    //endregion

    const SSAT = sessionStorage.getItem('adminToken');
    const LCAT = localStorage.getItem('adminToken');
    const navigate = useNavigate()
    const [profilePhotoLine, setProfilePhotoLine] = useState(AdminProfileBase);
    const [nicknameError, setNickNameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [isCurrentPasswordEntered, setIsCurrentPasswordEntered] = useState(false);
    const [isPasswordChangeAttempted, setIsPasswordChangeAttempted] = useState(false);
    const phoneRef = useRef();

    const formData = new FormData();
    const PhotoChangeHandler = (e) => {
        const file = e.target.files[0];
        formData.append('file', file);
        setProfilePhotoLine(URL.createObjectURL(e.target.files[0]))
    }

    const onChangeUserPhone = (e) => {
        const value = phoneRef.current.value.replace(/\D+/g, "");
        const numberLength = 11;

        let result;
        result = "";

        for (let i = 0; i < value.length && i < numberLength; i++) {
            switch (i) {
                case 3:
                    result += "-";
                    break;
                case 7:
                    result += "-";
                    break;

                default:
                    break;
            }
            result += value[i];
        }
        phoneRef.current.value = result;
        setUserPhone(e.target.value);
    };

    const formatPhoneNumber = (phone) => {
        if (!phone) return "";
        const match = phone.match(/^(\d{3})(\d{4})(\d{4})$/);
        if (match) {
            return `${match[1]}-${match[2]}-${match[3]}`;
        }
        return phone;
    };

    const onChangePassword = (e) => {
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()])(?=.*[0-9]).{8,20}$/;
        if ((!e.target.value || (passwordRegex.test(e.target.value)))) {
            setPasswordError(false);
        } else {
            setPasswordError(true);
        }
        setPassword(e.target.value);
        setIsCurrentPasswordEntered(e.target.value.length > 0);
        setIsPasswordChangeAttempted(e.target.value !== '');
    };

    const onChangeNewPassword = (e) => {
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()])(?=.*[0-9]).{8,20}$/;
        if ((!e.target.value || (passwordRegex.test(e.target.value)))) setNewPasswordError(false);
        else setNewPasswordError(true);

        if (!confirmPassword || e.target.value === confirmPassword) setConfirmPasswordError(false);
        else setConfirmPasswordError(true);
        setNewPassword(e.target.value);
        setIsPasswordChangeAttempted(e.target.value !== '');
    };

    const onChangeConfirmPassword = (e) => {
        if (newPassword === e.target.value) setConfirmPasswordError(false);
        else setConfirmPasswordError(true);
        setConfirmPassword(e.target.value);
        setIsPasswordChangeAttempted(e.target.value !== '');
    };

    //region Alert

    const [alertShow, setAlertShow] = useState(false);
    const msgConfirmData1 = "프로필정보를 수정하시겠습니까?";
    const msgConfirmData2 = "입력정보를 확인해주세요.";
    const conFirmAlert = (()=> {setAlertShow(true)})
    const modalCancel = useCallback (() => {setAlertShow(false);})

    //endregion

    /** endregion */

    /** region Event */

    const fileSelect = useRef(null);

    const photoChangeAndSave = (e) => {
        PhotoChangeHandler(e);
        profileSave(e).then();
    }

    useEffect(() => {
        setTimeout(() => {
            axios({
                method: 'get',
                url: '/member/modiInfo',
                headers: {
                    Authorization: LCAT ? LCAT : SSAT
                }
            })
                .then((response) => {
                    setAdminUserData(response.data);
                    setUserId(response.data[0].id)
                    setUserName(response.data[0].userName)
                    setUserNicName(response.data[0].nicName)
                    const formattedPhone = formatPhoneNumber(response.data[0].phone);
                    setUserPhone(formattedPhone);
                    setUserSeqNum(response.data[0].seqNum)
                    setUserMbNum(response.data[0].mbNum)
                    setUserProfileImg(response.data[0].profileDTO)

                    if (response.data[0].profileDTO === null) {
                        setProfilePhotoLine(AdminProfileBase)
                    } else {
                        setProfilePhotoLine("https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + response.data[0].profileDTO.filename)
                    }
                })
        },);
    }, [])

    const userInfoModify = async () => {
        let data = {
            id: userId,
            userName: userName,
            nicName: userNicName,
            phone: userPhone,
            password: newPassword,
            passwordConfirm: confirmPassword,
            lang: "관리자",
            mailRecFl: "Y",
            seqNum: userSeqNum,
            mbNum: userMbNum,
            userOutFl: "N",
            socialJoinFl: false
        };

        const isChangingPassword = password && newPassword && confirmPassword && (newPassword === confirmPassword);

        if (isChangingPassword) {
            data = {
                ...data,
                nowPassword: password,
                password: newPassword,
                passwordConfirm: confirmPassword,
            };
        }

        try {
            await axios.post('/member/adminInfoModify', data, {
                headers: {
                    Authorization: LCAT ? LCAT : SSAT
                },
                params: {
                    nowPassword: password,
                },
            });
            navigate("/btcorpad");
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const profileSave = async () => await axios({
        method: 'post',
        url: '/member/profileSave',
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: LCAT ? LCAT : SSAT
        },
        params: {
            email: userId
        },
        data: formData
    })
        .then(() => {})
        .catch(() => {})

    const profileDelete = () => axios({
        method: 'post',
        url: '/member/profileDelete',
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
        params: {
            id: userProfileImg.seqnum
        },
    })
        .then(() => {
            setProfilePhotoLine(AdminProfileBase);
        })
        .catch(() => {})

    const onChangeNickName = async (e) => {
        const userNickNameRegex = /^[a-zA-Zㄱ-힣][a-zA-Zㄱ-힣 ]*$/;
        if ((!e.target.value || (userNickNameRegex.test(e.target.value)))) setNickNameError(false);
        else setNickNameError(true);

        const newNickname = e.target.value;
        setUserNicName(newNickname);

        if (newNickname.length < 2 || newNickname.length > 5) {
            setNickNameError('닉네임은 2~5자 입니다.');
            return;
        }

        try {
            const { data } = await axios.get('/member/validateNickname', {
                params: {
                    nickname: newNickname,
                    userId: userId
                }
            });
            if (data.isDuplicate) {
                setNickNameError("'" + newNickname + "'" + "는(은) 이미 사용중입니다.");
            } else {
                setNickNameError('');
            }
        } catch (error) {
            console.error('Error: ', error);
            setNickNameError('닉네임 Err');
        }
    };

    /** endregion */

    return (
        <div id="m-a-Info-modi-pg-container" data-theme={theme}>
            <div className="m-a-common-header">
                <img onClick={()=> navigate("/btcorpad")} className="m-a-backIcon" src={back} alt="back"/>
                <div className="m-a-pg-header-text font-t-head">프로필수정</div>
            </div>
            <div className="m-a-fixed-container m-t-20 font-t-cont font-c-default">
                <div className="m-a-modi-pg-frame">
                    <div className="m-a-m-f-profileImg wd-150 ht-150 m-b-10">
                        <div className="admin-profile-modi">
                            <img className="admin-profile-modi-img" alt="" src={profilePhotoLine}/>
                            <div className="profile-modi-icon" style={{display: "flex", justifyContent: "space-around", position: "relative", bottom: "90px"}}>
                                <BiEdit className="font-s-32 pro-edit-icon font-c-btorage" onClick={() => fileSelect.current.click()} />
                                <input type="file" style={{display: "none"}} ref={fileSelect} accept=".jpg, .png" onChange={photoChangeAndSave}/>
                                <BiTrash className="font-s-32 pro-edit-icon font-c-btorage" onClick={profileDelete}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="m-a-modi-pg-frame">
                    <div className="m-a-m-f-profileInfo m-b-15">
                        <div className="">
                            {/*<label className="wd-30pc">아이디</label>*/}
                            <div className="m-a-input-frame ht-45 wd-70pc">
                                <input className="m-a-input" type={"text"} disabled={true} value={userId}/>
                            </div>
                        </div>
                        <div className="">
                            {/*<label className="wd-30pc">이름</label>*/}
                            <div className="m-a-input-frame ht-45 wd-70pc">
                                <input className="m-a-input" type={"text"} disabled={true} value={userName}/>
                            </div>
                        </div>
                        <div className="">
                            {/*<label className="wd-30pc">닉네임</label>*/}
                            <div className="m-a-input-frame ht-45 wd-70pc">
                                <input className="m-a-input" minLength="2" maxLength="5" placeholder="닉네임"
                                       onChange={onChangeNickName} type={"text"} value={userNicName}/>
                            </div>
                        </div>
                        {nicknameError && <span className="ErrMsg font-c-cont font-s-12" style={{position: "absolute", left: "31%", marginTop: "-9px"}}>{nicknameError}</span>}
                        <div className="">
                            {/*<label className="wd-30pc">연락처</label>*/}
                            <div className="m-a-input-frame ht-45 wd-70pc">
                                <input className="m-a-input" type="tel" name={userMbNum} ref={phoneRef} placeholder="연락처('-'제외)"
                                       onChange={onChangeUserPhone} value={userPhone}/>
                            </div>
                        </div>
                        <div className="">
                            {/*<label className="wd-30pc">현재비밀번호</label>*/}
                            <div className="m-a-input-frame ht-45 wd-70pc">
                                <input className="m-a-input" type={"password"} minLength="8" maxLength="20" placeholder="현재 비밀번호"
                                       onChange={onChangePassword} value={password}/>
                            </div>
                        </div>
                        {passwordError && <span className="ErrMsg font-c-cont font-s-12" style={{position: "absolute", left: "31%", marginTop: "-9px"}}>비밀번호가 일치하지 않습니다</span>}
                        <div className="">
                            {/*<label className="wd-30pc">변경비밀번호</label>*/}
                            <div className="m-a-input-frame ht-45 wd-70pc">
                                <input className="m-a-input" type={"password"} minLength="8" maxLength="20" placeholder="새 비밀번호"
                                       onChange={onChangeNewPassword} value={newPassword} disabled={!isCurrentPasswordEntered}/>
                            </div>
                        </div>
                        {newPasswordError && <span className="ErrMsg font-c-cont font-s-12" style={{position: "absolute", left: "31%", marginTop: "-9px"}}>8~20자 및 숫자/대,소문자/특수문자를 모두 포함</span>}
                        <div className="">
                            {/*<label className="wd-30pc">비밀번호확인</label>*/}
                            <div className="m-a-input-frame ht-45 wd-70pc">
                                <input className="m-a-input" type={"password"} minLength="8" maxLength="20" placeholder="새 비밀번호 확인"
                                       onChange={onChangeConfirmPassword} value={confirmPassword} disabled={!isCurrentPasswordEntered}/>
                            </div>
                        </div>
                        {confirmPasswordError && <span className="ErrMsg font-c-cont font-s-12" style={{position: "absolute", left: "31%", marginTop: "-9px"}}>비밀번호가 일치하지 않습니다</span>}
                    </div>
                </div>
            </div>
            <div className="m-a-m-fixed-bt-frame">
                <button className="m-a-colorBt-radius-n m-a-colorBt-text wd-100pc ht-45" disabled={!(isPasswordChangeAttempted || '' === nicknameError)}
                        onClick={conFirmAlert}>저장</button>
            </div>
            {alertShow === true ? <MsgSelectModal alertShow={alertShow} eventHandle1={modalCancel} eventHandle2={userInfoModify} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
        </div>
    );
}

export default MAdminModiPg
