import React, {useEffect, useState} from "react";
import {AiOutlineClose, AiOutlineDoubleRight} from "react-icons/ai";
import axios from "axios";
import {useTranslation} from "react-i18next";
import commonClose from "../../../assets/img/commonClose.png";

function ProdPriceDetail({closeDetail, paySeqnum}) {
    const { t } = useTranslation();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');


    const [payInfoList, setPayInfoList] = useState([{}]);
    const [prodList, setProdList] = useState([]);

    const selectPayinfoDetail = () => axios({
        method: 'get',
        url: '/order/selectTotalPayInfo/',
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
        params: {
            seqNum: paySeqnum
        },
    })
        .then((response) => {
            setPayInfoList(response.data)
            setProdList(response.data[0].orderdtDTO?.productsDTO)
        })
    useEffect(() => {
        const timer = setTimeout(() => {
            selectPayinfoDetail();
        })
        return () => {
            clearTimeout(timer);
        }
    }, [])


    const totalQuantity = prodList.reduce((acc, prod) => acc + prod?.quantity, 0);
    const totalProdExpense = (prodList[0]?.totalProdPrice + prodList[0]?.totalMallShipPrice + prodList[0]?.totalFee).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    return (
        <div className="bookContainer" style={{display: "flex", zIndex:"10"}}>


            <div className="bookFrame" style={{
                width: "600px",
                height: "700px",
                top: "calc(25vh - 120px)",
                left: "calc(50vw - 260px)",
                borderRadius: "5px"
            }}>

                <div style={{width: "100%", height: "100%"}}>
                    {/*상세헤더*/}
                    <div style={{height: "6%"}}>
                        <div style={{
                            height: "100%",
                            fontSize: "23px",
                            border: "solid",
                            borderWidth: "thin 1px",
                            color: "lightgray",
                            display: "flex"
                        }}>

                            <div style={{width: "92%", textAlign: "center", marginLeft: "55px"}}>
                                <p style={{
                                    color: "dimgrey",
                                    fontSize: "16px",
                                    width: "100%",
                                    marginTop: "7px"
                                }}>{t(`ppdHeader`)}</p>
                            </div>
                            <div onClick={closeDetail} style={{width: "8%", textAlign: "center", cursor:"pointer"}}>
                                <img style={{width:"30px", height:"30px", opacity:"0.6"}} src={commonClose}/>
                            </div>

                        </div>
                    </div>

                    {/*상세바디*/}
                    <div style={{height: "94%", overflowY: "scroll"}}>

                        <div style={{
                            border: "solid",
                            margin: "60px 50px 40px 50px",
                            borderWidth: "thin 1px",
                            borderColor: "lightgray",
                            borderRadius: "6px"
                        }}>
                            <div style={{
                                borderBottom: "solid",
                                borderWidth: "thin 1px",
                                borderColor: "lightgray",
                                width: "100%",
                                height: "40px",
                                display: "flex",
                                backgroundColor: "rgba(198,193,193,0.22)"
                            }}>
                                <span style={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0 4px 0 10px",
                                    fontSize: "14px"
                                }}><AiOutlineDoubleRight/></span>
                                <span style={{
                                    height: "100%",
                                    fontSize: "13px",
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: "bold"
                                }}>{t(`modPlHeader2`)}</span>
                            </div>

                            <div style={{
                                height: "50px",
                                display: "flex",
                                borderBottom: "solid",
                                borderWidth: "thin 1px",
                                borderColor: "lightgray"
                            }}>
                                <div style={{
                                    width: "10%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}><p style={{marginTop: "12px"}}>{t(`num`)}</p></div>
                                <div style={{width: "35%", display: "flex", justifyContent: "center"}}><p
                                    style={{marginTop: "12px"}}>{t(`prodNm`)}</p></div>
                                <div style={{width: "25%", display: "flex", justifyContent: "center"}}><p
                                    style={{marginTop: "12px"}}>{t(`prodOp`)}</p></div>
                                <div style={{width: "10%", display: "flex", justifyContent: "center"}}><p
                                    style={{marginTop: "12px"}}>{t(`prodQy`)}</p></div>
                                <div style={{width: "20%", display: "flex", justifyContent: "center"}}><p
                                    style={{marginTop: "12px"}}>{t(`modSHeader3`)}</p></div>
                            </div>


                            {/*추후 여기에 해당 금액 관련한 상품데이터들 .map으로 가져오기*/}
                            {prodList.map((prod, index) => (
                                <div key={index} style={{height: "40px", display: "flex"}}>
                                    <div style={{
                                        width: "10%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: "13px"
                                    }}>{index + 1}
                                    </div>
                                    <div style={{width: "35%", display: "flex", alignItems: "center", fontSize: "13px"}}>
                                        <span style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{prod?.name}</span>
                                    </div>
                                    <div style={{
                                        width: "25%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontSize: "13px"
                                    }}><span style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{prod?.option}</span>
                                    </div>
                                    <div style={{
                                        width: "10%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontSize: "13px"
                                    }}><span>{prod?.quantity?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></div>
                                    <div style={{
                                        width: "20%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontSize: "13px"
                                    }}><span>{(prod?.price + prod?.mallShipPrice + prod?.fee)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></div>
                                </div>
                            ))}
                        </div>

                        {payInfoList.map((pay, index) => (
                            <div key={index} style={{margin: "60px 50px 0 50px", color:"dimgrey"}}>
                                <div style={{
                                    height: "40px",
                                    borderBottom: "solid",
                                    borderWidth: "thin 1px",
                                    borderColor: "lightgray",
                                    display: "flex",
                                    marginBottom:"5px"
                                }}><p style={{fontSize:"16px", height:"100%", paddingTop:"8px"}}>{t(`modHeader10`)}</p></div>
                                <div style={{display:"flex", justifyContent:"space-between", wordBreak:"break-all", padding:"5px", fontSize:"13px"}}><span>{t(`prodQy`)}</span><span>{totalQuantity?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></div>
                                <div style={{display:"flex", justifyContent:"space-between", wordBreak:"break-all", padding:"5px", fontSize:"13px"}}><span>{t(`prodPr`)}</span><span>{pay.ttProdPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></div>
                                <div style={{display:"flex", justifyContent:"space-between", wordBreak:"break-all", padding:"5px", fontSize:"13px"}}><span>{t(`prodMallPr`)}</span><span>{pay.ttMallShipPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></div>
                                <div style={{display:"flex", justifyContent:"space-between", wordBreak:"break-all", padding:"5px", fontSize:"13px"}}><span>{t(`prodFee`)}</span><span>{pay.ttEtcFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></div>
                                <div style={{display:"flex", justifyContent:"space-between", wordBreak:"break-all", padding:"5px", fontSize:"13px", marginTop:"6px"}}><span>{t(`myUseDep`)}</span><span>{pay.depUsePrice === 0 || pay.depUsePrice === null ? 0 : <span>- {pay.depUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>}</span></div>
                                <div style={{display:"flex", justifyContent:"space-between", wordBreak:"break-all", padding:"5px", fontSize:"13px"}}><span>{t(`myUsePt`)}</span><span>{pay.pointUsePrice === 0 || pay.pointUsePrice === null ? 0 : <span>- {pay.pointUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>}</span></div>
                                <div style={{display:"flex", justifyContent:"space-between", wordBreak:"break-all", padding:"5px", fontSize:"13px"}}><span>{t(`dpdCpNm`)}</span><span>{pay.couponUsePrice === 0 || pay.couponUsePrice === null ? 0 : <span>- {pay.couponUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>}</span></div>

                                <div style={{
                                    height: "50px",
                                    borderBottom: "solid",
                                    borderWidth: "thin 1px",
                                    borderColor: "lightgray",
                                    display: "flex",
                                    alignItems: "end",
                                    justifyContent:"space-between",
                                    wordBreak:"break-all",
                                    padding:"5px",
                                    marginBottom:"50px"
                                }}><span style={{fontSize:"16px", fontWeight:"bold"}}>{t(`modFinalPay`)}</span>

                                        <span style={{
                                            color: "#FFAF34",
                                            fontWeight: "bold"
                                        }}>{pay?.payStatus === "PS0" ? 0 : pay?.lastPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </span>

                                </div>

                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProdPriceDetail
