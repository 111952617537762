import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../../css/web/AdminMainPg.css';
import '../../css/DarkMode.css';
import AdminDashBoardPg from "./AdminDashBoardPg";

import {BsEmojiDizzy, BsEmojiLaughing, BsEmojiNeutral, BsEmojiSmile, BsEmojiSunglasses, BsFillSunFill, BsMoonStarsFill} from "react-icons/bs";
import {IoSettingsOutline} from "react-icons/io5";
import {FaAngleDown, FaAngleRight} from "react-icons/fa"
import {GiGuitar} from "react-icons/gi";
import {
    BiBook,
    BiClipboard,
    BiFoodMenu,
    BiLogOut,
    BiPencil,
    BiPieChartAlt2,
    BiUser,
    BiUserCircle
} from "react-icons/bi";
import {TbWifi1} from "react-icons/tb";
import {
    AiOutlineClose,
    AiOutlineSearch,
    AiOutlineUser,
    AiOutlineUserAdd,
    AiOutlineUserDelete,
    AiOutlineUsergroupAdd
} from "react-icons/ai";

import BtorageWriteLogo from '../../assets/img/btorage_textlogo.png';
import AdminProfileBase from '../../assets/img/AdminProfileBase.jpg';

import AdminSalesStatusPg from "./AdminSalesStatusPg";
import AdminUserStatusPg from "./AdminUserStatusPg";
import AdminInflowStatusPg from "./AdminInflowStatusPg";
import AdminPaypalExPg from "./AdminPaypalExPg";
import AdminCustomsItemPg from "./AdminCustomsItemPg";
import AdminCurrencyRatePg from "./AdminCurrencyRatePg";
import AdminCountryInfoPg from "./AdminCountryInfoPg";
import AdminPaymentsListPg from "./AdminPaymentsListPg";
import AdminDepositWaitPg from "./AdminDepositWaitPg";
import AdminPayWaitPg from "./AdminPayWaitPg";
import AdminPageSetPg from "./AdminPageSetPg";
import AdminPopupPg from "./AdminPopupPg";
import AdminBannerPg from "./AdminBannerPg";
import AdminDepositSetPg from "./AdminDepositSetPg";
import AdminEmployeeInfoPg from "./AdminEmployeeInfoPg";
import AdminUserInfoPg from "./AdminUserInfoPg";
import AdminFAQPg from "./AdminFAQPg";
import AdminNoticePg from "./AdminNoticePg";
import AdminReviewPg from "./AdminReviewPg";
import AdminBoardPg from "./AdminBoardPg";
import AdminInScanPg from "./AdminInScanPg";
import AdminCancelItemPg from "./AdminCancelItemPg";
import AdminBuyItemPg from "./AdminBuyItemPg";
import AdminKorOutPg from "./AdminKorOutPg";
import AdminOrderChatPg from "./AdminOrderChatPg";
import {Link, Route, Routes, useNavigate} from "react-router-dom";
import AdminModiPg from "./AdminModiPg";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import axios from "axios";
import AdminVerManagementModal from "../../components/web/modal/AdminVerManagementModal";
import useTheme from "../../components/web/layoutscommon/DarkModeStorage";
import {RiRocket2Line} from "@react-icons/all-files/ri/RiRocket2Line";
import {AiOutlineLineChart} from "@react-icons/all-files/ai/AiOutlineLineChart";
import {AiOutlineBell} from "@react-icons/all-files/ai/AiOutlineBell";
import AdminOrderDtModiPg from "./AdminOrderDtModiPg";
import AdminReviewDetailPg from "./AdminReviewDetailPg";
import AdminReviewModifyPg from "./AdminReviewModifyPg";
import AdminFaqWritePg from "./AdminFaqWritePg";
import AdminNoticeWritePg from "./AdminNoticeWritePg";
import AdminNoticeModifyPg from "./AdminNoticeModifyPg";
import AdminNoticeDtPg from "./AdminNoticeDtPg";
import AdminFaqModifyPg from "./AdminFaqModifyPg";
import AdminFaqDtPg from "./AdminFaqDtPg";
import ErrorPathPg from "./ErrorPathPg";
import AdminReceiptList from "../../components/web/layoutsadmin/AdminReceiptList";
import {connect} from "../../services/WebsocketService";
import AdminAlarmList from "./AdminAlarmList";
import AdminDeliveryInfoPg from "./AdminDeliveryInfoPg";
import HeaderLogo from "../../assets/img/btorage_textlogo_w.png";
import {useWebSocket} from "../../services/WebsocketContext";
import AdminBannerWritePg from "./AdminBannerWritePg";
import AdminBannerModifyPg from "./AdminBannerModifyPg";
import AdminPopupWritePg from "./AdminPopupWritePg";
import AdminPopupModifyPg from "./AdminPopupModifyPg";
import AdminGuideSetPg from "./AdminGuideSetPg";

function AdminMainPg() {

    /** region DataTable */

    const navigate = useNavigate();
    // const userInfo = useSelector(state => state.user.userInfo);
    // const userTypeRe = userInfo?.userType;

    const [userCountTotalData, setUserCountTotalData] = useState(0);
    const [userCountFlwData, setUserCountFlwData] = useState(0);
    const [userCountLossData, setUserCountLossData] = useState(0);
    const [userCountTodayData, setUserCountTodayData] = useState(0);
    const [profilePhotoLine, setProfilePhotoLine] = useState(AdminProfileBase);
    const [userProfileImg, setUserProfileImg] = useState("");
    const [userType, setUserType] = useState("");
    const [userNicName, setUserNicName] = useState("");

    /** endregion DataTable*/

    /** region Function */

    const [alertShow, setAlertShow] = useState(false);
    const msgConfirmData1 = "업무상태를 변경하시겠습니까?";
    const msgConfirmData2 = "ON/OFF";
    const alert = (()=> {setAlertShow(true)})
    const modalCancel = useCallback (() => {setAlertShow(false);})

    const [isOpen, setIsOpen] = useState(false);
    const [activeButton, setActiveButton] = useState('MsgBell');
    const [modalOpen, setModalOpen] = useState(false);

    const LCAT = localStorage.getItem('adminToken');

    function toggleMenu(li) {
        setIsOpen(!isOpen);
        setActiveButton(li);
    }

    function handleClickOutside(event) {
        if (!event.target.closest('.navbar-ctg')) {
            setIsOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const convertCount = (count) => {
        if (count >= 1000000) {
            return (count / 1000000).toFixed(1) + 'm';
        } else if (count >= 1000) {
            return (count / 1000).toFixed(1) + 'k';
        } else {
            return count.toString();
        }
    };

    const date = new Date();
    const week = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ];
    const dayWeek = week[date.getDay()];
    const DateViewValue = '' + dayWeek;

    //region 접기 펼치기 애니메이션

    const [showSideMenu, setShowSideMenu] = useState(false);
    const [fadeInOut, setFadeInOut] = useState('');

    const [contLeft, setContLeft] = useState(false);
    const [contRight, setContRight] = useState('');

    const showMenuList = () => {
        if (showSideMenu) {
            setFadeInOut('fade-out');
            setTimeout(() => {
                setShowSideMenu(!showSideMenu);
            }, 200);
        } else {
            setFadeInOut('fade-in');
            setTimeout(() => {
                setShowSideMenu(!showSideMenu);
            }, 200);
        }
    };

    const showCont = () => {
        if (contLeft) {
            setContRight('cont-left');
            setContLeft(!contLeft);
            // setTimeout(() => {
            //     setContLeft(!contLeft);
            // }, 200);
        } else {
            setContRight('cont-right');
            setContLeft(!contLeft);
        }
    };

    const sideEvent = () => {
        setShowSideMenu(!showSideMenu);
        showMenuList();
        showCont();
    }

    const [isActive, setIsActive] = useState(false);
    const [searchText, setSearchText] = useState('');

    const searHandleToggle = (event) => {
        event.preventDefault();
        setIsActive(!isActive);
    };

    const searHandleClear = () => {
        setIsActive(false);
        setSearchText('');
    };

    const searHandleKeyPress = (event) => {
        if (event.key === 'Enter') {
            navigate("/btcorpad");
        }
    };

    //endregion

    //region 아코디언 아이콘 애니메이션

    const [showInfo01, setShowInfo01] = useState(false);
    const [showInfo02, setShowInfo02] = useState(false);
    const [showInfo03, setShowInfo03] = useState(false);
    const [showInfo04, setShowInfo04] = useState(false);
    const [showInfo05, setShowInfo05] = useState(false);
    const [showInfo06, setShowInfo06] = useState(false);
    const [showInfo07, setShowInfo07] = useState(false);

    //endregion

    //region 다크모드

    const [theme, themeToggler] = useTheme();

    //endregion

    function getUserRole(userType) {
        const roles = {
            1: "Staff",
            2: "Snr Staff",
            3: "Assistant",
            4: "Manager",
            5: "Owner",
        };

        return roles[userType] || "Unknown";
    }

    /** endregion */

    /** region Event */

    const [adminInfo, setAdminInfo] = useState([]);
    const selectedAdminInfo = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setAdminInfo(response.data)
            })
            .catch((error) => {
                console.log("Admin 조회실패 : ", error)
            })
    })

    useEffect(() => {
        if (LCAT) {
        selectedAdminInfo();
        }
    }, [LCAT]);

    const stompClientRef = useRef(null);
    const { subscribe, unsubscribe, websocketConnect, isConnected, stompClient } = useWebSocket();
    useEffect(() => {
        if (!adminInfo && !adminInfo.mbNum) return;
        const initializeSubscription = async () => {
            if (!isConnected || !stompClient) {
                stompClientRef.current = await websocketConnect(adminInfo.mbNum);
            } else {
                stompClientRef.current = stompClient;
            }
            const subscriptions = [];
            subscriptions.push(subscribe(`/topic/totalCount`, updatedTotalCount));
            subscriptions.push(subscribe(`/topic/followCount`, updatedFollowCount));
            subscriptions.push(subscribe(`/topic/lossCount`, updatedLossCount));
            subscriptions.push(subscribe(`/topic/todayCount`, updatedTodayCount));
            stompClientRef.current = subscriptions;
        };
        initializeSubscription();
        return () => {
            if (stompClientRef.current && stompClientRef.current.length > 0) {
                // 구독이 존재하는 경우에만 해제
                stompClientRef.current.forEach((sub) => {
                    unsubscribe(sub);
                });
            }
            stompClientRef.current = null;
        };
    }, [adminInfo, isConnected, subscribe, unsubscribe, stompClient]);



    // const updatedUserStatistics = (payload) => {
    //     const message = JSON.parse(payload.body);
    //     setUserCountTotalData(message.total);
    //     setUserCountFlwData(message.follow);
    //     setUserCountLossData(message.loss);
    // };



    const updatedTotalCount = ((payload) => {
        const message = JSON.parse(payload.body);
        setUserCountTotalData(message);
    })

    const updatedFollowCount = ((payload) => {
        const message = JSON.parse(payload.body);
        setUserCountFlwData(message);
    })

    const updatedLossCount = ((payload) => {
        const message = JSON.parse(payload.body);
        setUserCountLossData(message);
    })

    const updatedTodayCount = ((payload) => {
        const message = JSON.parse(payload.body);
        setUserCountTodayData(message);
    })


    const modify = () => {
        axios.post('/task/taskOpenAndClose', {})
            .then(() => {})
            .catch()
    }

    const openModal = () => {
        setModalOpen(true);
        document.body.style.overflow = "hidden";
    };
    const closeModal = () => {
        setModalOpen(false);
        document.body.style.overflow = "unset";
    };

    const mainReflash = () => {
        navigate("/btcorpad");
    }

    useEffect(() => {
        userCountTotalSelect();
        userCountFlwSelect();
        userCountLossSelect();
        userCountTodaySelect();
    }, [])

    const userStatisticsSelect = () => {
        axios({
            method: 'get',
            url: '/member/userstatistics',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                const data = response.data;
                setUserCountTotalData(data.total);
                setUserCountFlwData(data.follow);
                setUserCountLossData(data.loss);
            })
            .catch(() => {})
    };

    const userCountTotalSelect = (() => {
        axios({
            method: 'get',
            url: '/member/usertotalcount',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setUserCountTotalData(response.data);
            })
            .catch(() => {})
    })

    const userCountFlwSelect = (() => {
        axios({
            method: 'get',
            url: '/member/userflwcount',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setUserCountFlwData(response.data);
            })
            .catch(() => {})
    })

    const userCountLossSelect = (() => {
        axios({
            method: 'get',
            url: '/member/userlosscount',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setUserCountLossData(response.data);
            })
            .catch(() => {})
    })
    const userCountTodaySelect = (() => {
        axios({
            method: 'get',
            url: '/init/usertodaycount',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setUserCountTodayData(response.data);
            })
            .catch(() => {})
    })

    useEffect(() => {
        if (LCAT) {
            axios({
                method: 'get',
                url: '/member/modiInfo',
                headers: {
                    Authorization: LCAT
                }
            })
                .then((response) => {
                    setUserType(response.data[0].userType)
                    setUserNicName(response.data[0].nicName)
                    setUserProfileImg(response.data[0].profileDTO)

                    if (response.data[0].profileDTO === null) {
                        setProfilePhotoLine(AdminProfileBase)
                    } else {
                        setProfilePhotoLine("https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + response.data[0].profileDTO.filename)
                    }
                })
        }
    }, [])

    const logout = () => axios({
        method: 'post',
        url: '/member/logout',
        headers: {
            Authorization: LCAT
        }
    })
        .then(() => {
            localStorage.removeItem('adminToken')
            localStorage.removeItem('adminLastLoginTime')
            navigate("/btcorpad-login")
        })
        .catch(() => {
            localStorage.removeItem('adminToken')
            localStorage.removeItem('adminLastLoginTime')
            navigate("/btcorpad-login")
        })

    /** endregion */

    return (
        <div className="btcorpad" data-theme={theme}>
            <div className="wrapper">
                <div className="AdNavbar" id="DarkAndLight">
                    {/*<nav className={'ad-navbar navbar ' + contRight}>*/}
                    <nav className={`ad-navbar navbar ${showSideMenu ? 'cont-right' : 'cont-left'}`}>
                        <ul className="navbar-ctg ctg-menu-btn font-t-cont">
                            <li className="ctg-item item-ham">
                                <a className="item-link link-item">
                                    <input type={"checkbox"} id="trigger" className="trigger-btn"/>
                                    <label onClick={sideEvent} htmlFor="trigger" role={"button"} className="btorage-hover">
                                        <span id={"햄"}></span><span id={"버"}></span><span id={"거"}></span>
                                    </label>
                                </a>
                            </li>
                            <li className="ctg-item">
                                <a className="icon-hover item-link item-user">
                                    <AiOutlineUser className="icon-hover icon-user"/>
                                    Total
                                    <span className="user-count"> {convertCount(userCountTotalData)}</span>
                                </a>
                            </li>
                            <li className="ctg-item">
                                <a className="icon-hover item-link item-user">
                                    <AiOutlineUserAdd className="icon-hover icon-user"/>
                                    Follow
                                    <span className="user-count"> {convertCount(userCountFlwData)}</span>
                                </a>
                            </li>
                            <li className="ctg-item item-user">
                                <a className="icon-hover item-link item-user">
                                    <AiOutlineUserDelete className="icon-hover icon-user"/>
                                    Loss
                                    <span className="user-count"> {convertCount(userCountLossData)}</span>
                                </a>
                            </li>
                            <li className="ctg-item item-user">
                                <a className="icon-hover item-link item-user">
                                    <AiOutlineUsergroupAdd className="icon-hover icon-user"/>
                                    Today
                                    <span className="user-count"> {convertCount(userCountTodayData)}</span>
                                </a>
                            </li>
                            <li className="ctg-item">
                                <div className={`search-wrapper ${isActive ? 'active' : ''}`} style={{display: "block", paddingLeft: "0.8rem", paddingRight: "0.8rem"}}>
                                    <div className="input-holder">
                                        <input type="text" className="search-input font-t-cont" placeholder={isActive ? "검색어 입력 후 Enter" : ""} maxLength="20" value={searchText}
                                               onChange={(e) => setSearchText(e.target.value)}
                                               onKeyDown={searHandleKeyPress}/>
                                        {isActive ? (
                                            <AiOutlineClose className="search-icon icon-hover font-s-20 close" style={{ marginBottom: "0.2rem", marginRight: "0.2rem" }}
                                                            onClick={searHandleClear}/>
                                        ) : (
                                            <AiOutlineSearch className="search-icon icon-hover font-s-26" style={{ marginBottom: "0.2rem", marginRight: "0.2rem" }} onClick={searHandleToggle}/>
                                        )}
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul className="navbar-ctg">
                            {(userType === '4' || userType === '5') && (
                            <li className="ctg-item on-off">
                                <a className="item-link">
                                    <div className="switch-main">
                                        <label className="switch">
                                            <input type="checkbox" className="switch-input" onClick={alert}/>
                                            <span data-on="Start" data-off="End" className="switch-label"></span>
                                            <span className="switch-handle"></span>
                                        </label>
                                    </div>
                                </a>
                            </li>
                            )}
                            <li className="ctg-item item-user user-home">
                                <Link to={"/"} className="item-link" target="_blank">
                                    <RiRocket2Line className="icon-chg font-s-27"/>
                                </Link>
                            </li>
                            <li className="ctg-item">
                                <Link to="/btcorpad/inflowstatus" element={<AdminInflowStatusPg/>} className="item-link">
                                    <AiOutlineLineChart className="icon-hover font-s-27"/>
                                </Link>
                            </li>
                            <li className="ctg-item" style={{marginRight: "4px"}} onClick={() => toggleMenu('MsgBell')}>
                                <span className="item-link">
                                    <input type="checkbox" className="msg-chk"/>
                                    <AiOutlineBell className="icon-hover font-s-27"/>
                                    <span className="badge navbar-badge badge-danger">99</span>
                                    <div className="ctg-drop font-t-cont">
                                            {isOpen && activeButton === 'MsgBell' && (
                                                <div className="msgbell-page-wrap">
                                                    <div className="msgbell-chat">
                                                        <div className="admin-chat-profile-mini">
                                                            <img className="pro-img-mini" src={AdminProfileBase} alt=""/>
                                                        </div>
                                                        <div className="admin-chat-msg-mini">
                                                            <Link className="link-font-msg" to="/btcorpad/adminorderchat" element={<AdminOrderChatPg/>} style={{marginTop: "auto"}}>
                                                                sandra님의 Message...
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="msgbell-chat">
                                                        <div className="admin-chat-profile-mini">
                                                            <img className="pro-img-mini" src={AdminProfileBase} alt=""/>
                                                        </div>
                                                        <div className="admin-chat-msg-mini">
                                                            <Link className="link-font-msg" to="/btcorpad/adminorderchat" element={<AdminOrderChatPg/>} style={{marginTop: "auto"}}>
                                                                답장 안한 채팅만
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                </span>
                            </li>
                            <li className="ctg-item item-pro c-point" onClick={() => toggleMenu('Profile')}>
                                <div className="profile m-t-5">
                                    <span className="item-link-sub ling-pro">
                                        <div className="admin-profile-modi" style={{width: "30px", height: "30px"}}>
                                            <img className="admin-profile-modi-img" src={profilePhotoLine} alt=""/>
                                        </div>
                                        <div className="ctg-drop">
                                            {isOpen && activeButton === 'Profile' && (
                                                <div className="profile-page-wrap font-t-cont">
                                                    <div style={{display: "flex", flexDirection: "row", padding: "5px 5px 5px 10px", cursor: "default"}}>
                                                        <BiUserCircle className="dark-icon drop-icon-sub m-r-5" style={{fontSize: "22px"}}/>
                                                        <a className="dark-font">{`${userNicName} : ${getUserRole(userType)}`}</a>
                                                    </div>
                                                    <div className="profile-modi">
                                                        <BiPencil  className="dark-icon drop-icon-sub m-r-5" style={{fontSize: "22px"}}/>
                                                        <Link className="link-font wd-150" to="/btcorpad/adminInfoModi" element={<AdminModiPg/>}>프로필수정</Link>
                                                    </div>
                                                    <div className="profile-modi">
                                                        <BiLogOut className="dark-icon drop-icon-sub m-r-7" style={{fontSize: "22px", marginLeft: "-2px"}}/>
                                                        <a className="wd-150" onClick={logout}>로그아웃</a>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
                {/*<aside className={'sidebar ' + fadeInOut}>*/}
                <aside className={`sidebar ${showSideMenu ? 'fade-in' : 'fade-out'}`}>
                    <div className="side-logo-wrap">
                        <div className="c-point" onClick={mainReflash}>
                            {/*<Link onClick={mainReflash} to="#">*/}
                            <img className="ad-main-logo" src={BtorageWriteLogo} alt="admin-logo" style={{width: "147px", height: "32px"}}/>
                            {/*</Link>*/}
                        </div>
                        {/*<Link className="logo-tag" onClick={mainReflash} to={""}>*/}
                        {/*    <img className="ad-main-logo" src={BtorageWriteLogo} alt="admin-logo" style={{width: "147px", height: "32px"}}/>*/}
                        {/*</Link>*/}
                    </div>
                    <div className="side-main">
                        <nav className="side-nav">
                            <li className="nav-menu font-t-cont" role={"menu"}>
                                <div className="menu-ctg">
                                    <span className="icon-l-wrap">
                                        <BiFoodMenu className="side-icon-l"/>
                                    </span>
                                    <details className="ctg-dt">
                                        <summary className="ctg-sum nav-aco" onClick={() => setShowInfo01(!showInfo01)}>
                                            메인메뉴
                                        </summary>
                                        {(userType !== '1') && (
                                            <li className="nav-aco nav-top-item">
                                                <TbWifi1 className="side-icon-m"/>
                                                <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                      to="/btcorpad/pamentslist"
                                                      element={<AdminPaymentsListPg/>}>결제내역</Link>
                                            </li>
                                        )}
                                        {(userType !== '1') && (
                                        <li className="nav-aco">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/paywait"
                                                  element={<AdminPayWaitPg/>}>입금대기</Link>
                                        </li>
                                        )}
                                        {(userType !== '1') && (
                                        <li className="nav-aco">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/depositwait"
                                                  element={<AdminDepositWaitPg/>}>예치금대기</Link>
                                        </li>
                                        )}
                                        {(userType !== '1') && (
                                        <li className="nav-aco">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/adminorderchat"
                                                  element={<AdminOrderChatPg/>}>채팅&매크로관리</Link>
                                        </li>
                                        )}
                                        {(userType !== '1') && (
                                        <li className="nav-aco">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/korout"
                                                  element={<AdminKorOutPg/>}>국내출고관리</Link>
                                        </li>
                                        )}
                                        {(userType !== '1') && (
                                        <li className="nav-aco">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/buyitem"
                                                  element={<AdminBuyItemPg/>}>구매상품관리</Link>
                                        </li>
                                        )}
                                        {(userType !== '1') && (
                                        <li className="nav-aco">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/cancelitem"
                                                  element={<AdminCancelItemPg/>}>취소상품관리</Link>
                                        </li>
                                        )}
                                        <li className="nav-aco">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/inscan"
                                                  element={<AdminInScanPg/>}>입고스캔관리</Link>
                                        </li>
                                    </details>
                                    <span className="icon-r-wrap">
                                        {showInfo01 ?
                                            <FaAngleDown className="side-icon-r"/> :
                                            <FaAngleRight className="side-icon-r"/>}
                                    </span>
                                </div>
                                <div className="menu-ctg">
                                    <span className="icon-wrap"><BiClipboard className="side-icon-l"/></span>
                                    <details className="ctg-dt">
                                        <summary className="ctg-sum nav-aco" onClick={() => setShowInfo02(!showInfo02)}>
                                            게시글관리
                                        </summary>
                                        {(userType === '3' || userType === '4' || userType === '5') && (
                                        <li className="nav-aco nav-top-item">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/boardset"
                                                  element={<AdminBoardPg/>}>게시판설정</Link>
                                        </li>
                                        )}
                                        <li className="nav-aco">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/adminreview"
                                                  element={<AdminReviewPg/>}>이용후기</Link>
                                        </li>
                                        {(userType === '3' || userType === '4' || userType === '5') && (
                                        <li className="nav-aco">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/adminnotice"
                                                  element={<AdminNoticePg/>}>공지사항</Link>
                                        </li>
                                        )}
                                        {(userType === '3' || userType === '4' || userType === '5') && (
                                        <li className="nav-aco">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/adminfaq"
                                                  element={<AdminFAQPg/>}>FAQ</Link>
                                        </li>
                                        )}
                                    </details>
                                    <span className="icon-wrap">
                                        {showInfo02 ?
                                            <FaAngleDown className="side-icon-r"/> :
                                            <FaAngleRight className="side-icon-r"/>}
                                    </span>
                                </div>
                                {(userType !== '1') && (
                                <div className="menu-ctg">
                                    <span className="icon-wrap"><BiUser className="side-icon-l"/></span>
                                    <details className="ctg-dt">
                                        <summary className="ctg-sum nav-aco" onClick={() => setShowInfo03(!showInfo03)}>
                                            회원관리
                                        </summary>
                                        <li className="nav-aco nav-top-item">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/aduserinfo"
                                                  element={<AdminUserInfoPg/>}>회원정보</Link>
                                        </li>
                                        <li className="nav-aco">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/depositset"
                                                  element={<AdminDepositSetPg/>}>예적금관리</Link>
                                        </li>
                                        {(userType === '3' || userType === '4' || userType === '5') && (
                                        <li className="nav-aco">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/employeeinfo"
                                                  element={<AdminEmployeeInfoPg/>}>사원정보</Link>
                                        </li>
                                        )}
                                    </details>
                                    <span className="icon-wrap">
                                        {showInfo03 ?
                                            <FaAngleDown className="side-icon-r"/> :
                                            <FaAngleRight className="side-icon-r"/>}
                                    </span>
                                </div>
                                )}
                                {(userType === '3' || userType === '4' || userType === '5') && (
                                <div className="menu-ctg">
                                    <span className="icon-wrap">
                                        <BiBook className="side-icon-l"/>
                                    </span>
                                    <details className="ctg-dt">
                                        <summary className="ctg-sum nav-aco" onClick={() => setShowInfo04(!showInfo04)}>
                                            페이지관리
                                        </summary>
                                        <li className="nav-aco nav-top-item">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/adminbanner"
                                                  element={<AdminBannerPg/>}>배너설정</Link>
                                        </li>
                                        <li className="nav-aco">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/adminpopup"
                                                  element={<AdminPopupPg/>}>팝업설정</Link>
                                        </li>
                                        <li className="nav-aco">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/pageset"
                                                  element={<AdminPageSetPg/>}>페이지설정</Link>
                                        </li>
                                    </details>
                                    <span className="icon-wrap">
                                        {showInfo04 ?
                                            <FaAngleDown className="side-icon-r"/> :
                                            <FaAngleRight className="side-icon-r"/>}
                                    </span>
                                </div>
                                )}
                                {/*<div className="menu-ctg">*/}
                                {/*    <span className="icon-wrap">*/}
                                {/*        <IoTicketOutline className="side-icon-l"/>*/}
                                {/*    </span>*/}
                                {/*    <details className="ctg-dt">*/}
                                {/*        <summary className="ctg-sum nav-aco" onClick={() => setShowInfo05(!showInfo05)}>*/}
                                {/*            쿠폰관리*/}
                                {/*        </summary>*/}
                                {/*        <li className="nav-aco nav-top-item">*/}
                                {/*            <TbWifi1 className="side-icon-m"/>*/}
                                {/*            <Link style={{fontSize: "15px", marginLeft: "-20px"}}*/}
                                {/*                  to="/btcorpad/couponinfo"*/}
                                {/*                  element={<AdminCouponInfoPg/>}>쿠폰정보</Link>*/}
                                {/*        </li>*/}
                                {/*        <li className="nav-aco nav-top-item">*/}
                                {/*            <TbWifi1 className="side-icon-m"/>*/}
                                {/*            <Link style={{fontSize: "15px", marginLeft: "-20px"}}*/}
                                {/*                  to="/btcorpad/couponmanager"*/}
                                {/*                  element={<AdminCouponManagerPg/>}>고객쿠폰관리</Link>*/}
                                {/*        </li>*/}
                                {/*    </details>*/}
                                {/*    <span className="icon-wrap">*/}
                                {/*        {showInfo05 ?*/}
                                {/*            <FaAngleDown className="side-icon-r"/> :*/}
                                {/*            <FaAngleRight className="side-icon-r"/>}*/}
                                {/*    </span>*/}
                                {/*</div>*/}
                                <div className="menu-ctg">
                                    <span className="icon-wrap"><BiPieChartAlt2 className="side-icon-l"/></span>
                                    <details className="ctg-dt">
                                        <summary className="ctg-sum nav-aco" onClick={() => setShowInfo06(!showInfo06)}>
                                            통계관리
                                        </summary>
                                        <li className="nav-aco nav-top-item">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/salesstatus"
                                                  element={<AdminSalesStatusPg/>}>매출현황</Link>
                                        </li>
                                        <li className="nav-aco">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/userstatus"
                                                  element={<AdminUserStatusPg/>}>고객별이용현황</Link>
                                        </li>
                                        <li className="nav-aco">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/inflowstatus"
                                                  element={<AdminInflowStatusPg/>}>트래픽현황</Link>
                                        </li>
                                    </details>
                                    <span className="icon-wrap">
                                        {showInfo06 ?
                                            <FaAngleDown className="side-icon-r"/> :
                                            <FaAngleRight className="side-icon-r"/>}
                                    </span>
                                </div>
                                <div className="menu-ctg">
                                    <span className="icon-wrap"><GiGuitar className="side-icon-l"/></span>
                                    <details className="ctg-dt">
                                        <summary className="ctg-sum nav-aco" onClick={() => setShowInfo07(!showInfo07)}>
                                            기타관리
                                        </summary>
                                        {(userType === '4' || userType === '5') && (
                                        <li className="nav-aco nav-top-item">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/currencyrate"
                                                  element={<AdminCurrencyRatePg/>}>요금표관리</Link>
                                        </li>
                                        )}
                                        <li className="nav-aco">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/countryinfo"
                                                  element={<AdminCountryInfoPg/>}>국가정보</Link>
                                        </li>
                                        {(userType !== '1') && (
                                        <li className="nav-aco">
                                            <TbWifi1 className="side-icon-m"/>
                                            <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                  to="/btcorpad/customsitem"
                                                  element={<AdminCustomsItemPg/>}>통관품목설정</Link>
                                        </li>
                                        )}
                                        {(userType === '4' || userType === '5') && (
                                            <li className="nav-aco">
                                                <TbWifi1 className="side-icon-m"/>
                                                <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                      to="/btcorpad/paypalex"
                                                      element={<AdminPaypalExPg/>}>페이팔환율관리</Link>
                                            </li>
                                        )}
                                        {(userType === '4' || userType === '5') && (
                                            <li className="nav-aco">
                                                <TbWifi1 className="side-icon-m"/>
                                                <Link style={{fontSize: "17px", marginLeft: "-20px"}}
                                                      to="/btcorpad/deliveryInfo"
                                                      element={<AdminPaypalExPg/>}>배송사관리</Link>
                                            </li>
                                        )}
                                    </details>
                                    <span className="icon-wrap">
                                        {showInfo07 ?
                                            <FaAngleDown className="side-icon-r"/> :
                                            <FaAngleRight className="side-icon-r"/>}
                                    </span>
                                </div>
                            </li>
                        </nav>
                    </div>
                    <div className="nav-footer nav-aco font-t-cont">
                        <span>
                            <a className="footer-bt c-def font-s-18">{DateViewValue}&nbsp;
                                {/*{DateViewValue === 'Sunday' && <BsEmojiKiss style={{fontSize: "22px"}}/>}*/}
                                {DateViewValue === 'Monday' && <BsEmojiDizzy style={{fontSize: "22px"}}/>}
                                {DateViewValue === 'Tuesday' && <BsEmojiNeutral style={{fontSize: "22px"}}/>}
                                {DateViewValue === 'Wednesday' && <BsEmojiSmile style={{fontSize: "22px"}}/>}
                                {DateViewValue === 'Thursday' && <BsEmojiLaughing style={{fontSize: "22px"}}/>}
                                {DateViewValue === 'Friday' && <BsEmojiSunglasses style={{fontSize: "22px"}}/>}
                                {/*{DateViewValue === 'Saturday' && <BsEmojiHeartEyes style={{fontSize: "22px"}}/>}*/}
                            </a>
                        </span>
                        <span></span>
                        <span></span>
                        <span>
                            <a className="footer-bt c-point" onClick={themeToggler}>
                                {theme === "light" ? <BsMoonStarsFill className="light light-moon" style={{fontSize: "22px"}}/> : <BsFillSunFill className="dark dark-sun" style={{fontSize: "22px"}}/>}
                            </a>
                        </span>
                        <span>
                            <a className="footer-bt c-point">
                                <IoSettingsOutline style={{fontSize: "24px"}}/>
                            </a>
                        </span>
                    </div>
                </aside>
                <div className="AdMain">
                    {/*<AdminRealTimeRipple />*/}
                    {/*<div className={'main-contents ' + contRight}>*/}
                    <div className={`main-contents ${showSideMenu ? 'cont-right' : 'cont-left'}`}>
                        <Routes>
                            {/*<Route path="/charttotal" element={<AdminChartSumPg/>}/> /!*차트 한번에보기*!/*/}
                            <Route path="/adminInfoModi" element={<AdminModiPg/>}/> {/*프로필수정*/}
                            <Route path="/orderdtmodi/:ordNum" element={<AdminOrderDtModiPg/>}/> {/*주문서수정*/}

                            <Route path="/pamentslist" element={<AdminPaymentsListPg/>}/> {/*결제내역*/}
                            <Route path="/paywait" element={<AdminPayWaitPg/>}/> {/*입금대기*/}
                            <Route path="/depositwait" element={<AdminDepositWaitPg/>}/> {/*예치금대기*/}
                            <Route path="/adminorderchat" element={<AdminOrderChatPg/>}/> {/*주문서댓글*/}
                            <Route path="/korout" element={<AdminKorOutPg/>}/> {/*국내출고관리*/}
                            <Route path="/buyitem" element={<AdminBuyItemPg/>}/> {/*구매상품관리*/}
                            <Route path="/cancelitem" element={<AdminCancelItemPg/>}/> {/*취소상품관리*/}
                            <Route path="/inscan" element={<AdminInScanPg/>}/> {/*입고스캔관리*/}

                            <Route path="/boardset" element={<AdminBoardPg/>}/> {/*게시판설정*/}
                            <Route path="/adminreview" element={<AdminReviewPg/>}/> {/*이용후기*/}
                            <Route path="/adminreview-detail/:seqNum" element={<AdminReviewDetailPg/>}/>{/*이용후기상세*/}
                            <Route path="/adminreview-modify/:seqNum" element={<AdminReviewModifyPg/>}/>{/*이용후기수정*/}
                            <Route path="/adminnotice" element={<AdminNoticePg/>}/> {/*공지사항*/}
                            <Route path="/adminnotice-write" element={<AdminNoticeWritePg/>}/> {/*공지사항등록*/}
                            <Route path="/adminnotice-modify/:seqNum" element={<AdminNoticeModifyPg/>}/> {/*공지사항수정*/}
                            <Route path="/adminnotice-detail/:seqNum" element={<AdminNoticeDtPg/>}/> {/*공지사항상세*/}

                            <Route path="/adminfaq" element={<AdminFAQPg/>}/> {/*FAQ*/}
                            <Route path="/adminfaq-write" element={<AdminFaqWritePg/>}/> {/*FAQ작성*/}
                            <Route path="/adminfaq-modify/:seqNum" element={<AdminFaqModifyPg/>}/> {/*FAQ수정*/}
                            <Route path="/adminfaq-detail/:seqNum" element={<AdminFaqDtPg/>}/> {/*FAQ상세*/}

                            <Route path="/adminbanner" element={<AdminBannerPg/>}/> {/*배너*/}
                            <Route path="/adminbanner-write" element={<AdminBannerWritePg/>}/> {/*배너추가*/}
                            <Route path="/adminbanner-modify/:seqNum" element={<AdminBannerModifyPg/>}/> {/*배너수정*/}

                            <Route path="/adminpopup" element={<AdminPopupPg/>}/> {/*팝업*/}
                            <Route path="/adminpopup-write" element={<AdminPopupWritePg/>}/> {/*팝업추가*/}
                            <Route path="/adminpopup-modify/:seqNum" element={<AdminPopupModifyPg/>}/> {/*팝업수정*/}

                            <Route path="/aduserinfo" element={<AdminUserInfoPg/>}/> {/*회원정보*/}
                            <Route path="/depositset" element={<AdminDepositSetPg/>}/> {/*예적금관리*/}
                            <Route path="/employeeinfo" element={<AdminEmployeeInfoPg/>}/> {/*사원정보*/}

                            <Route path="/pageset" element={<AdminPageSetPg/>}/> {/*페이지설정*/}

                            <Route path="/salesstatus" element={<AdminSalesStatusPg/>}/> {/*매출통계*/}
                            <Route path="/userstatus" element={<AdminUserStatusPg/>}/> {/*고객별이용현황*/}
                            <Route path="/inflowstatus" element={<AdminInflowStatusPg/>}/> {/*트래픽현황*/}

                            <Route path="/currencyrate" element={<AdminCurrencyRatePg/>}/> {/*요율표관리*/}
                            <Route path="/countryinfo" element={<AdminCountryInfoPg/>}/> {/*국가정보*/}
                            <Route path="/customsitem" element={<AdminCustomsItemPg/>}/> {/*통관품목설정*/}
                            <Route path="/paypalex" element={<AdminPaypalExPg/>}/> {/*페이팔환율관리*/}
                            <Route path="/deliveryInfo" element={<AdminDeliveryInfoPg/>}/> {/*배송사관리*/}
                            <Route path="/receiptlist" element={<AdminReceiptList/>}/> {/*접수리스트*/}
                            <Route path="/alarmlist/:type" element={<AdminAlarmList/>}/> {/*대쉬보드 알람리스트*/}

                            <Route path="/guideSet" element={<AdminGuideSetPg/>}/> {/*이용가이드 작성*/}
                            <Route path="/guideSet/:seqNum" element={<AdminGuideSetPg/>}/> {/*이용가이드 수정*/}

                            <Route path="/" element={<AdminDashBoardPg/>}/> {/*Main*/}
                            <Route path="*" element={<ErrorPathPg/>}/>
                        </Routes>
                    </div>
                </div>
                <div className="AdFooter font-t-cont">
                    {/*<footer className={'ad-footer ' + contRight}>*/}
                    <footer className={`ad-footer ${showSideMenu ? 'cont-right' : 'cont-left'}`}>
                        <div className="footer-cv">
                            <b className="c-def">© Btorage Corp.</b>
                            <b className="sleep-shelter c-def" onDoubleClick={openModal}>Ver 1.0.0</b>
                        </div>
                    </footer>
                </div>
            </div>
            <AdminVerManagementModal open={modalOpen} close={closeModal}/>
            {alertShow === true ? <MsgSelectModal alertShow={alertShow} eventHandle1={modalCancel} eventHandle2={modify} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
        </div>
    );
}

export default AdminMainPg
