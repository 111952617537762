import React, {useEffect, useState} from 'react';
import {BiSolidUpArrow} from "react-icons/bi";

function TopBtn() {

    const [topBtn, setTopBtn] = useState(false);

    const topScroll = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        })
    }

    const handleScroll = () => {
        const scrollY = window.scrollY;
        const scrollThreshold = 2500;
        setTopBtn(scrollY > scrollThreshold);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="TopBtn font-t-cont">
            {topBtn && (
                <div className="TopBtn-bk c-point" onClick={topScroll}>
                    <span className="TopBtn-up">
                        <BiSolidUpArrow className="top-up-arrow font-c-default font-s-18"/>
                        <p className="font-b font-s-13">TOP</p>
                    </span>
                </div>
            )}
        </div>
    );
}

export default TopBtn
