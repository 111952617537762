import React, {useCallback, useEffect, useState} from 'react';
import {BsSquareFill} from "react-icons/bs";
import axios from "axios";
import AdminPagination from "../../components/web/layoutsadmin/AdminPagination";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import AdminSearchEvent from "../../components/web/layoutsadmin/AdminSearchEvent";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import {MdKeyboardArrowDown} from "react-icons/md";
import maCateImg from "../../assets/img/m-a-cate.png";

function AdminEmployeeInfoPg() {

    /**region DataTable */

    const [userEmployeeData, setUserEmployeeData] = useState([]);
    const [employeeReqData, setEmployeeReqData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    /** endregion */

    /** region Function */

    const SSAT = sessionStorage.getItem('adminToken');
    const LCAT = localStorage.getItem('adminToken');
    const MAX_DISPLAY_LIMIT = 20;
    const INITIAL_DISPLAY_LIMIT = 10;

    const formatPhoneNumber = (phoneNumber) => {
        const cleanedNumber = phoneNumber.replace(/\D/g, '');
        return cleanedNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    };

    const formatDate = (dateString) => {
        return `${dateString.substring(0, 4)}-${dateString.substring(4, 6)}-${dateString.substring(6, 8)}`;
    };

    //region Search

    const [searchInput, setSearchInput] = useState('');

    const handleSearchInputChange = (event) => setSearchInput(event.target.value);

    const searchData = userEmployeeData.filter((cData) =>
        cData.userName.toLowerCase().includes(searchInput.toLowerCase()) ||
        cData.id.toLowerCase().includes(searchInput.toLowerCase()) ||
        cData.nicName.toLowerCase().includes(searchInput.toLowerCase())
    );

    //endregion

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(INITIAL_DISPLAY_LIMIT);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [searchData.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
            if (searchData.length > displayLimit && displayLimit < 30) {
                setDisplayLimit(prevLimit => prevLimit + perPg);
            }
        }
    };

    const loadMoreData = () => setDisplayLimit((prevLimit) => prevLimit + perPg);

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [currentPage2, setCurrentPage2] = useState(1);
    const [perPg, setperPg] = useState(10);
    const [perPg2, setperPg2] = useState(5);

    const indexOfLast2 = currentPage2 * perPg2;
    const indexOfFirst2 = indexOfLast2 - perPg2;

    const pageChange2 = (page) => setCurrentPage2(page);

    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e.target.value);
        setperPg(newPerPage);
        setperPg2(newPerPage);
        setDisplayLimit(newPerPage);
        setCurrentPage(1);
        setCurrentPage2(1);
    };

    //endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);
    const [checkedReg, setCheckedReg] = useState([]);

    const checkBoxChange = (id) => {
        setChecked((prevSelectedData) =>
            prevSelectedData.includes(id) ? prevSelectedData.filter((item) => item !== id) : [...prevSelectedData, id]
        );
    };

    const allCheckBoxChange = (e) => {
        let visibleData = searchData.slice(0, displayLimit);
        setChecked(e.target.checked ? visibleData.map((item) => item.mbNum) : []);
    };

    const checkBoxRegChange = (id) => {
        setCheckedReg((prevSelectedData) =>
            prevSelectedData.includes(id) ? prevSelectedData.filter((item) => item !== id) : [...prevSelectedData, id]
        );
    };

    const allCheckBoxRegChange = (e) => {
        setCheckedReg(e.target.checked ? employeeReqData.map((item) => item.mbNum) : []);
    };

    //endregion

    //region Alert

    const [alertShow, setAlertShow] = useState(false);
    const [alertShow2, setAlertShow2] = useState(false);
    const msgConfirmData1 = "승인 하시겠습니까?";
    const msgConfirmData2 = "승인정보를 확인해주세요.";
    const msgConfirmData3 = "직책구분을 변경 하시겠습니까?";
    const msgConfirmData4 = "변경할 정보를 확인해주세요.";
    const conFirmAlert = () => setAlertShow(true);
    const modalCancel = useCallback(() => setAlertShow(false), []);

    const [pendingUserTypeSt, setPendingUserTypeSt] = useState(null);

    const typeStAlert = (newValue) => {
        setPendingUserTypeSt(newValue);
        setAlertShow2(true);
    };

    const modalCancel2 = useCallback (() => {setAlertShow2(false);})

    //endregion

    const handleRowClick = (user) => {
        setSelectedUser(user);
    };

    function getUserRole(userType) {
        const roles = {
            1: "Staff",
            2: "Snr Staff",
            3: "Assistant",
            4: "Manager",
            5: "Owner",
        };

        return roles[userType] || "Unknown";
    }

    /** endregion */

    /** region Event  */

    useEffect(() => {
        employeeSelect();
        employeesReqSelect();
    }, [])

    const employeeSelect = useCallback(() => {
        axios({
            method: 'get',
            url: '/member/adminuserselect',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                const filteredData = response.data.filter(item => item.role === "1");
                const sortedData = filteredData.sort((a, b) => a.userName.localeCompare(b.userName));
                setUserEmployeeData(sortedData);
            })
            .catch(() => {});
    }, [LCAT, SSAT]);

    const employeesReqSelect = useCallback(() => {
        axios({
            method: 'get',
            url: '/member/adminuserselect',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                const filteredData2 = response.data.filter(item => item.joinVerFl === "N" && item.role === "1");
                const sortedData = filteredData2.sort((a, b) => a.userName.localeCompare(b.userName));
                setEmployeeReqData(sortedData);
            })
            .catch(() => {});
    }, [LCAT, SSAT]);

    const reqConfirm = () => {
        if (checkedReg.length === 0) {
            setAlertShow(false);
            return;
        }
        setCheckedReg([]);
        axios
            .post('/member/reqconfirmupdate', checkedReg)
            .then(() => {
                employeesReqSelect();
                setAlertShow(false);
            })
            .catch()
    };

    const typeStUpdate = (newValue) => {
        axios.post('/member/userTypeStupdate', {
            mbNum: selectedUser.mbNum,
            userType: newValue
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: LCAT ? LCAT : SSAT
            }
        })
            .then(() => {
                setAlertShow2(false);
                setPendingUserTypeSt(null);
            })
            .catch((error) => {
                console.error("에러:", error);
            });
    };

    useEffect(() => {
        employeeSelect();
        employeesReqSelect();
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [employeeSelect, employeesReqSelect]);

    // region Excel

    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const DateViewValue = year + month + day;

    const excelDown = () => {
        try {
            const selectedRows = searchData.filter((item) => checked.includes(item.mbNum));

            const excel = require('exceljs');
            const workbook = new excel.Workbook();
            workbook.created = new Date();
            workbook.modified = new Date();

            function mapDataToExcelRow(item, index) {
                return {
                    '번호': index + 1,
                    '이름': item.userName,
                    '닉네임': item.nicName,
                    '계정': item.id,
                    '부서': item.userOutFl,
                    '직책': item.userOutFl,
                    '근무여부': item.userOutFl,
                    '연락처': item.phone,
                    '생년월일': item.byMd,
                    '가입일자': item.regDate,
                    '수정일시': item.modiTime,
                };
            }

            const sheetConfigs = {
                '사원정보': {
                    name: '사원정보',
                    columns: [
                        {header: '번호', key: '번호', hidden: false, width: 6, style: { alignment: { horizontal: 'center' }}},
                        {header: '이름', key: '이름', width: 13, style: { alignment: { horizontal: 'center' }}},
                        {header: '닉네임', key: '닉네임', width: 13, style: { alignment: { horizontal: 'center' }}},
                        {header: '계정', key: '계정', width: 22},
                        {header: '부서', key: '부서', width: 8, style: { alignment: { horizontal: 'center' }}},
                        {header: '직책', key: '직책', width: 8, style: { alignment: { horizontal: 'center' }}},
                        {header: '근무여부', key: '근무여부', width: 10, style: { alignment: { horizontal: 'center' }}},
                        {header: '연락처', key: '연락처', width: 15, style: { alignment: { horizontal: 'center' }}},
                        {header: '생년월일', key: '생년월일', width: 13, style: { alignment: { horizontal: 'center' }}},
                        {header: '가입일자', key: '가입일자', width: 13, style: { alignment: { horizontal: 'center' }}},
                        {header: '수정일시', key: '수정일시', width: 20, style: { alignment: { horizontal: 'center' }}},
                    ],
                }
            };

            const borderHeaderStyle = {
                top: { style: 'thin', color: { argb: 'FF000000' } },
                left: { style: 'thin', color: { argb: 'FF000000' } },
                bottom: { style: 'thin', color: { argb: 'FF000000' } },
                right: { style: 'thin', color: { argb: 'FF000000' } }
            };

            const applyBordersToRow = (row, border) => {
                row.eachCell({ includeEmpty: true }, (cell) => {
                    cell.border = border;
                });
            };

            const applyStylesToHeader = (sheet, height) => {
                const invisibleRow = sheet.getRow();
                invisibleRow.height = 1;

                const headerRow = sheet.getRow(1);
                headerRow.font = { name: '맑은 고딕', bold: true, size: 10 };
                headerRow.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
                headerRow.height = height;

                headerRow.eachCell(() => {});
                applyBordersToRow(headerRow, borderHeaderStyle);
            };

            const createSheet = (name, columns) => {
                const sheet = workbook.addWorksheet(name);
                sheet.columns = columns;
                applyStylesToHeader(sheet, 23);
                return sheet;
            };

            const addRowsToSheet = (sheet, rows, border) => {
                rows.forEach((item) => {
                    const row = sheet.addRow(item);
                    for (let i = 1; i <= sheet.columns.length; i++) {
                        row.getCell(i).font = { name: '맑은 고딕', size: 10 };
                    }
                    applyBordersToRow(row, border);
                });
            };

            let transformedSelectedRows;
            transformedSelectedRows = selectedRows.map(mapDataToExcelRow);

            if (sheetConfigs) {
                const sheet = createSheet(sheetConfigs['사원정보'].name, sheetConfigs['사원정보'].columns);
                applyStylesToHeader(sheet, 23);
                addRowsToSheet(sheet, transformedSelectedRows, borderHeaderStyle);
            }

            let fileName = `비토리지 사원정보_${DateViewValue}.xlsx`;
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = fileName;
                anchor.click();
                window.URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.error(error);
        }
    };

    // endregion

    /** endregion */

    return (
        <div className="AdminEmployeeInfoPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">사원정보</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        <div className="dark-font" style={{marginBottom: "12px"}}>
                            <BsSquareFill className="admin-tb-header-notice"/>가입요청
                        </div>
                        <div className="admin-event-wrap-btn">
                            <button onClick={conFirmAlert} className="admin-btn-long admin-bt-color">승인</button>
                        </div>
                    </div>
                    <div className="div-tb m-b-20">
                        <div className="div-tb-head ht-60">
                            <div style={{width: "40px"}}>
                                <input className="admin-input-chkBox m-0-a" type="checkbox" checked={checkedReg.length === employeeReqData.length} onChange={allCheckBoxRegChange}/>
                            </div>
                            <div style={{width: "110px"}}><p>이름</p></div>
                            <div style={{width: "120px"}}><p>닉네임</p></div>
                            <div style={{width: "150px"}}><p>계정</p></div>
                            <div style={{width: "150px"}}><p>연락처</p></div>
                            <div style={{width: "120px"}}><p>생년월일</p></div>
                            <div style={{width: "180px"}}><p>가입요청일시</p></div>
                        </div>
                        {employeeReqData.slice(indexOfFirst2, indexOfLast2).map((cData, i) => (
                            <div key={i} className="div-tb-body ht-70">
                                <div style={{width: "40px"}}>
                                    <input className="admin-input-chkBox m-0-a" type="checkbox" name={cData.mbNum} checked={checkedReg.includes(cData.mbNum)}
                                           onChange={() => checkBoxRegChange(cData.mbNum)}/>
                                </div>
                                <div className="font-b wd-110"><p>{cData.userName}</p></div>
                                <div style={{width: "120px"}}><p>{cData.nicName}</p></div>
                                <div style={{width: "150px"}}><p>{cData.id}</p></div>
                                <div style={{width: "150px"}}><p>{formatPhoneNumber(cData.phone)}</p></div>
                                <div style={{width: "120px"}}><p>{cData.byMd}</p></div>
                                <div style={{width: "180px"}}><p>{cData.regTime}</p></div>
                            </div>
                        ))}
                    </div>
                    <AdminPagination perPg={perPg2} totalData={employeeReqData.length} paginate={currentPage2} pageChange={pageChange2}/>
                </div>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        <div className="admin-event-wrap-btn">
                            <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                            <AdminSearchEvent searchInput={searchInput} handleSearchInputChange={handleSearchInputChange} />
                        </div>
                        <div className="admin-event-wrap-btn">
                            <button className="admin-btn-long admin-bt-color" onClick={excelDown}>EXCEL</button>
                        </div>
                    </div>
                    <div className="div-tb">
                        <div className="div-tb-head ht-60">
                            <div style={{ width: "40px" }}>
                                <input className="admin-input-chkBox m-0-a" type="checkbox" checked={checked.length === userEmployeeData.length} onChange={allCheckBoxChange} />
                            </div>
                            <div style={{ width: "110px" }}><p>이름</p></div>
                            <div style={{ width: "110px" }}><p>닉네임</p></div>
                            <div style={{ width: "180px" }}><p>계정</p></div>
                            <div style={{ width: "100px" }}><p>부서</p></div>
                            <div style={{ width: "120px" }}><p>직책</p></div>
                            <div style={{ width: "80px" }}><p>근무여부</p></div>
                            <div style={{ width: "130px" }}><p>연락처</p></div>
                            <div style={{ width: "110px" }}><p>생년월일</p></div>
                            <div style={{ width: "120px" }}><p>가입일자</p></div>
                            <div style={{ width: "170px" }}><p>수정일시</p></div>
                        </div>
                        {searchData.sort((a, b) => new Date(b.regDate) - new Date(a.regDate)).slice(0, displayLimit).map((cData, i) => (
                            <div key={i} className="div-tb-body ht-70" onClick={() => handleRowClick(cData)}>
                                <div style={{ width: "40px" }}>
                                    <input className="admin-input-chkBox m-0-a" type="checkbox" name={cData.mbNum} checked={checked.includes(cData.mbNum)} onChange={() => checkBoxChange(cData.mbNum)} />
                                </div>
                                <div className="font-b wd-110"><p>{cData.userName}</p></div>
                                <div style={{ width: "110px" }}><p>{cData.nicName}</p></div>
                                <div style={{ width: "180px" }}><p>{cData.id}</p></div>
                                <div style={{ width: "100px" }}><p>비토리지</p></div>
                                <div style={{ width: "120px" }}>
                                    <select className="admin-combo wd-110 ht-25 m-0-a font-s-14" disabled={cData.joinVerFl === 'N' || cData.userType === "5"}
                                            style={{ backgroundColor: cData.joinVerFl === 'N' ? '#dedede' : 'none' }}
                                            value={cData.userType} onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            if (cData.userType !== "5" || selectedValue === "5") {
                                                typeStAlert(selectedValue);
                                            }
                                    }}>
                                        <option value="1">1: Staff</option>
                                        <option value="2">2: Snr Staff</option>
                                        <option value="3">3: Assistant</option>
                                        <option value="4">4: Manager</option>
                                        <option value="5" disabled={true}>5: Owner</option>
                                    </select>
                                </div>
                                <div style={{ width: "80px" }}><p>{cData.userOutFl === 'N' ? '재직중' : '퇴사'}</p></div>
                                <div style={{ width: "130px" }}><p>{formatPhoneNumber(cData.phone)}</p></div>
                                <div style={{ width: "110px" }}><p>{cData.byMd}</p></div>
                                <div style={{ width: "120px" }}><p>{formatDate(cData.regDate)}</p></div>
                                <div style={{ width: "170px" }}><p>{cData.modiTime}</p></div>
                            </div>
                        ))}
                    </div>
                    {displayLimit >= MAX_DISPLAY_LIMIT && searchData.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown style={{ marginLeft: "3px", fontSize: "17px" }} />
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {alertShow === true ? <MsgSelectModal alertShow={alertShow} eventHandle1={modalCancel} eventHandle2={reqConfirm} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {alertShow2 === true ? <MsgSelectModal alertShow={alertShow2} eventHandle1={modalCancel2} eventHandle2={() => typeStUpdate(pendingUserTypeSt)} msgData1={msgConfirmData3} msgData2={msgConfirmData4}/> : null}
        </div>
    );
}

export default AdminEmployeeInfoPg
