import React, {useEffect, useRef, useState} from "react";
import {BiCaretDown} from "react-icons/bi";
import {BsTrash} from "react-icons/bs";
import axios from "axios";
import reviewUpload from "../../assets/img/reviewUpload.png";
import {useNavigate, useParams} from "react-router-dom";
import commonClose from '../../assets/img/commonClose.png'

function AdminReviewModifyPg() {

    const {seqNum} = useParams();
    const navigate = useNavigate();
    const LCAT = localStorage.getItem('adminToken');

    const [adminInfo, setAdminInfo] = useState("");
    const findMember = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data)
                console.log("어드민 : ", response.data)
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (LCAT) {
            findMember();
        }
    }, [LCAT])

    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({value: '', label: '수취국가'});

    const [titleErrorMsg, setTitleErrorMsg] = useState(false);
    const [countryErrorMsg, setCountryErrorMsg] = useState(false);
    const [shipcoErrorMsg, setShipcoErrorMsg] = useState(false);
    const [reviewErrorMsg, setReviewErrorMsg] = useState(false);

    // 여기서부터 커스텀 셀렉트
    const [countryShow, setCountryShow] = useState(false);
    const countryRef = useRef(null);

    const toggleCountryShow = () => {
        setCountryShow(!countryShow);
    };

    const handleCountryChange = (country) => {
        console.log(country)
        setSelectedCountry(country);
        setCountryShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setCountryShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트

    const selectCountry = (() => {
        axios({
            method: 'get',
            url: '/order/selectCountry',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                const countries = response.data
                    .map(country => ({
                        value: country.countryCode,
                        label: `${country.countryNm}(${country.countryEngNm})`,
                        engName: country.countryEngNm
                    }))
                    .sort((a, b) => a.engName.localeCompare(b.engName));

                setCountryList(countries.map(country => ({
                    value: country.value,
                    label: country.label
                })));
            })
            .catch((error) => {
                console.log(error)
            })
    })

    const selectReviewData = () =>
        axios({
            method: 'get',
            url: '/review/selectReviewData',
            headers: {
                Authorization: LCAT
            },
            params: {
                seqNum: seqNum
            }
        })
            .then((response) => {
                setTitleText(response.data.title);
                setReviewText(response.data.content);
                setSelectedShipco(response.data.shipco);

                const countryValue = response.data.country;
                const matchingCountry = countryList && countryList.find(country => {
                    const regex = /\(([^)]+)\)/;  // countryList.label 괄호 안의 텍스트 추출을 위한 정규표현식
                    const match = country.label.match(regex);
                    return match && match[1] === countryValue;  // 괄호 내의 영문명과 countryValue 비교
                });
                setSelectedCountry(matchingCountry);

                const reviewPhotos = response.data.reviewPhotoDTO;
                if (reviewPhotos && reviewPhotos.length > 0) {
                    setReviewContainers(prev => {
                        // 기존 사진 데이터를 포함한 배열 생성
                        const updatedContainers = reviewPhotos.map(photo => ({
                            reviewImg: "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/reviewPhoto/" + photo.fileNm,
                            reviewImgSt: true,
                            reviewImgError: false,
                            reviewImgFileOriNm: photo.fileOriNm
                        }));

                        // 마지막에 빈 폼 추가
                        updatedContainers.push({ reviewImg: reviewUpload, reviewImgSt: false, reviewImgError: false });

                        return updatedContainers;
                    });
                }
            })
            .catch(() => {
                console.log("리뷰조회실패")
            })

    useEffect(() => {
        if (seqNum && countryList.length > 0) {
            selectReviewData();
        }
    }, [countryList])


    useEffect(() => {
        selectCountry();
        bringToShipCo();
    }, [])

    const [shipcoList, setShipcoList] = useState([]); // DB데이터
    const [selectedShipco, setSelectedShipco] = useState('');

    const shipCode = "1"
    const bringToShipCo = () => axios({
        method: 'get',
        url: '/shipcompany/bringToShipCo/' + shipCode,
        headers: {
            Authorization: LCAT
        },
    })
        .then((response) => {
            setShipcoList(response.data)
        })

    // 여기서부터 커스텀 셀렉트
    const [shipcoShow, setShipcoShow] = useState(false);
    const shipcoRef = useRef(null);

    const toggleShipcoShow = () => {
        setShipcoShow(!shipcoShow);
    };

    const handleShipcoChange = (shipco) => {
        setSelectedShipco(shipco);
        setShipcoShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (shipcoRef.current && !shipcoRef.current.contains(event.target)) {
                setShipcoShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트

    const [titleText, setTitleText] = useState('');
    const handleChangeTitleText = (e) => {
        setTitleText(e.target.value);
    };

    const [reviewText, setReviewText] = useState('');
    const handleChangeReviewText = (e) => {
        setReviewText(e.target.value);
    };


    const [reviewContainers, setReviewContainers] = useState([{ reviewImg: reviewUpload, reviewImgSt: false, reviewImgError: false, reviewImgOriNm: '' }]);
    const [reviewRemoveContainers, setReviewRemoveContainers] = useState([{ reviewImg: ''}]);

    const reviewImgHandle = (idx) => (e) => {
        const file = e.target.files[0];

        if (file) {
            setReviewContainers((prev) => {
                const newContainers = [...prev];

                if (!file.type.startsWith('image/')) {
                    newContainers[idx] = {
                        ...newContainers[idx],
                        reviewImgError: true,
                    };
                    return newContainers;
                } else {
                    newContainers[idx] = {
                        ...newContainers[idx],
                        reviewImg: URL.createObjectURL(file),
                        reviewImgSt: true,
                        reviewImgError: false,
                        file: file
                    };
                    if (idx === newContainers.length - 1) {
                        addForm(newContainers);
                    }
                    return newContainers;
                }
            });
        }
    };

    const formData = new FormData();

    const reviewImgInit = (idx, reviewImg) => () => {
        setReviewRemoveContainers(prev => [...prev, { removeImg: reviewImg }]);
        setReviewContainers((prev) => {
            const newContainers = [...prev];

            newContainers.splice(idx, 1);

            const lastContainer = newContainers[newContainers.length - 1];
            if (lastContainer && lastContainer.reviewImgSt) {
                newContainers.push({ reviewImg: reviewUpload, reviewImgSt: false, reviewImgError: false });
            }

            return newContainers;
        });
        formData.delete('reviewImg');
    };


    const addForm = (newContainers) => {
        const lastContainer = newContainers[newContainers.length - 1];
        if (!lastContainer.reviewImgSt) {
            return;
        }

        newContainers.push({ reviewImg: reviewUpload, reviewImgSt: false, reviewImgError: false });
    };

    const validation = () => {
        if (titleText === '' || !titleText) setTitleErrorMsg(true);
        if (selectedCountry.value === '' || !selectedCountry) setCountryErrorMsg(true);
        if (selectedShipco === '' || !selectedShipco) setShipcoErrorMsg(true);
        if (reviewText === '' || !reviewText) setReviewErrorMsg(true);
        return !!(titleText && selectedCountry && selectedShipco && reviewText)
    }

    const reviewModify = async () => {

        const newFormData = new FormData();
        const crEngNm = selectedCountry.label.match(/\(([^)]+)\)/);

        newFormData.append('title', titleText);
        newFormData.append('country', crEngNm[1]);
        newFormData.append('shipco', selectedShipco);
        newFormData.append('reviewText', reviewText);

        reviewContainers.forEach((container, i) => {
            if (container.file) {
                newFormData.append('reviewImg', container.file);
            } else {
                newFormData.append('reviewImg', container.reviewImg);
            }
        });

        reviewRemoveContainers.forEach((remove, i) => {
            if (remove.removeImg) {
                const filename = remove.removeImg.split('/').pop(); // URL에서 파일 이름만 추출
                console.log("추출 후 : ", filename)
                newFormData.append('removeImg', encodeURIComponent(filename));
            }
        });

            return axios({
            method: 'post',
            url: '/review/reviewModify',
            data: newFormData,
            params: {
                reviewSeqNum: seqNum,
                // removeImg: removeImgFilenames
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(() => {
                navigate(`/btcorpad/adminreview-detail/${seqNum}`);
            });
    }

    const valiAndSave = () => {
        if (validation()) {
            reviewModify();
        }
    }

    const moveToReturn = (() => {
        navigate(`/btcorpad/adminreview-detail/${seqNum}`);
    })

    return(
        <div className="AdminReviewPg">
            <div className="admin-work-title">
                <h5>후기수정</h5>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card m-b-40" style={{padding:"30px 100px"}}>
                    <div style={{height:"40px", marginBottom:"20px", display:"flex", justifyContent:"flex-end"}}>
                        <img onClick={moveToReturn} alt="close" style={{width:"35px", height:"35px", opacity:"0.6", cursor:"pointer"}} src={commonClose}/>
                    </div>
                    <div>
                        <div style={{height: "10%", display: "flex"}}>
                            <div style={{width: "65%", paddingRight: "5px"}}>
                                <div className="ReviewPgSearchFrame acc-icon">
                                    <div className="ReviewPgInputForm" style={{width: "97%"}}>
                                        <input className="ReviewInput user-input"
                                               style={{width: "100%", marginLeft: "10px", height: "100%"}} type="text"
                                               placeholder="" value={titleText} onChange={handleChangeTitleText}/>
                                        <label className="JoinLabel" style={{fontSize: "14px"}}>제목</label>
                                    </div>
                                </div>
                                <div className="JoinErrorMsg">
                                    {titleErrorMsg && <p className="JoinErrMsg">&nbsp;제목을 입력해주세요</p>}
                                </div>
                            </div>
                            {/*국가*/}
                            <div style={{width: "20%", height: "60px", padding: "0 5px 0 5px"}}>
                                {/*여기서부터 커스텀 국가셀렉트*/}
                                <div ref={countryRef} style={{height: "100%", width: "100%", position: "relative"}}>
                                    <button className="selectParentBt" style={{width: "100%", height: "100%"}}
                                            onClick={toggleCountryShow}>
                                        <p style={{margin: "0"}}>{selectedCountry.label}</p>
                                        <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                <i style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%"
                                }}><BiCaretDown/></i>
                            </span>
                                    </button>
                                    {countryShow ?
                                        <div style={{
                                            width:"300px",
                                            border: "solid",
                                            height: "400px",
                                            borderWidth: "thin 1px",
                                            borderColor: "lightgray",
                                            position: "absolute",
                                            top:"100%",
                                            bottom:"100%",
                                            right:"-6%",
                                            zIndex:1,
                                            marginTop: "5px",
                                            overflowY: "scroll",
                                        }}>
                                            {countryList.map((country, index) => (
                                                <div className="selectChildBt" key={index}
                                                     onClick={() => handleCountryChange(country)} style={{
                                                    display: "flex", padding: "10px", justifyContent: "center",
                                                    alignItems: "center", cursor: "pointer", fontSize: "14px", borderRadius: "4px", zIndex:99,
                                                }}>{country.label}</div>
                                            ))}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                {/*여기까지 커스텀 국가셀렉트*/}
                                <div className="JoinErrorMsg">
                                    {countryErrorMsg && <p className="JoinErrMsg">&nbsp;국가를 선택해주세요</p>}
                                </div>
                            </div>
                            {/*배송사*/}
                            <div style={{width: "15%", height: "60px", padding: "0 5px 0 5px"}}>
                                {/*여기서부터 커스텀 배송사셀렉트*/}
                                <div ref={shipcoRef} style={{height: "100%", width: "100%", position: "relative"}}>
                                    <button className="selectParentBt" style={{width: "100%", height: "100%"}}
                                            onClick={toggleShipcoShow}>
                                        <p style={{margin: "0"}}>{selectedShipco}</p>
                                        <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                <i style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%"
                                }}><BiCaretDown/></i>
                            </span>
                                    </button>
                                    {shipcoShow ?
                                        <div style={{
                                            width:"100%",
                                            border: "solid",
                                            height: "166px",
                                            borderWidth: "thin 1px",
                                            borderColor: "lightgray",
                                            position: "absolute",
                                            top:"100%",
                                            bottom:"100%",
                                            zIndex:1,
                                            marginTop: "5px",
                                        }}>
                                            {shipcoList.map((shipco, index) => (
                                                <div className="selectChildBt" key={index} value={shipco}
                                                     onClick={() => handleShipcoChange(shipco)} style={{
                                                    display: "flex", padding: "10px", justifyContent: "center",
                                                    alignItems: "center", cursor: "pointer", fontSize: "14px",
                                                }}>{shipco}</div>
                                            ))}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                {/*여기까지 커스텀 배송사셀렉트*/}
                                <div className="JoinErrorMsg">
                                    {shipcoErrorMsg && <p className="JoinErrMsg">&nbsp;배송사를 선택해주세요</p>}
                                </div>
                            </div>
                        </div>
                        <div style={{height: "auto", display: "flex", marginTop: "20px"}}>
                            <div style={{width: "65%"}}>
                                <div className="ordChatInputContainer" style={{height: "100%", minHeight: "600px"}}>
                    <textarea
                        placeholder="내용"
                        spellCheck="false"
                        value={reviewText}
                        style={{
                            borderRadius: "6px",
                            height: "100%",
                            width: "100%",
                            borderWidth: "thin 1px",
                            borderColor: "lightgray",
                            resize: "none",
                            overflow: "auto",
                            outline: "none",
                            color: "dimgrey",
                            padding: "18px",
                            fontSize: "14px",
                            ':focus': {
                                borderColor: "orange"
                            },
                        }} onChange={handleChangeReviewText}/>
                                </div>
                                <div className="JoinErrorMsg">
                                    {reviewErrorMsg && <p className="JoinErrMsg">&nbsp;내용을 입력해주세요</p>}
                                </div>
                            </div>
                            <div style={{width: "35%", paddingLeft: "10px"}}>
                                {reviewContainers.map((container, idx) => (
                                    <div key={idx} style={{
                                        border: "solid",
                                        height: "305px",
                                        borderWidth: "thin 1px",
                                        borderColor: "lightgray",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        {!container.reviewImgSt ?
                                            <div style={{display: "block", textAlign:"center"}} onClick={(e) => {
                                                const parentDiv = e.target.parentNode;
                                                const fileInput = parentDiv.querySelector('input[type="file"]');
                                                if (fileInput) fileInput.click();
                                            }}>
                                                <input type="file" accept="image/*" style={{display: "none"}} onChange={reviewImgHandle(idx)}/>
                                                <img alt="uploadImg" style={{
                                                    width: "80px",
                                                    height: "80px",
                                                    padding: "10px",
                                                    filter: "opacity(0.2) drop-shadow(0 0 0 gray)",
                                                }} src={container.reviewImg}/>
                                                <p style={{color: "rgba(112,112,109,0.67)", fontSize: "18px"}}>Click me!</p>
                                                <div className="JoinErrorMsg">
                                                    {container.reviewImgError && <p className="JoinErrMsg">&nbsp;사진만 업로드해주세요😅</p>}
                                                </div>
                                            </div>
                                            :
                                            <div style={{width:"90%", display:"flex", height:"90%", position:"relative"}}>
                                                <img alt="reviewImg" style={{
                                                    display:"flex",
                                                    justifyContent:"center",
                                                    alignItems:"center",
                                                    width:"100%",
                                                    height:"100%"
                                                }} src={container.reviewImg}/>
                                                <button className="orderItemHandleBt" style={{
                                                    position:"absolute",
                                                    width: "40px",
                                                    height: "40px",
                                                    top:"85%",
                                                    left:"90%",
                                                    borderRadius:"4px",
                                                    opacity:"0.6"
                                                }} onClick={reviewImgInit(idx, container.reviewImg)}>
                                                    <i style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        fontSize:"26px"
                                                    }}><BsTrash/></i>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{
                            height: "100px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <button className="orderItemHandleBt" style={{width: "100px", height: "50px", borderRadius:"4px"}} onClick={valiAndSave}>작성완료</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminReviewModifyPg
