import React, {useCallback, useEffect, useState} from 'react';
import '../../css/mobile/MAdminUserInfo.css';
import '../../css/mobile/MAdmin.css';
import axios from "axios";
import {MdKeyboardArrowDown} from "react-icons/md";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import TopBtn from "../../components/web/layoutsadmin/TopBtn";
import maCateImg from "../../assets/img/m-a-cate.png";
import MAdminPageSmallEvent from "./MAdminPageSmallEvent";
import MAdminSearchEvent from "./MAdminSearchEvent";
import deposit from "../../assets/img/depositPay.png";
import pointPay from "../../assets/img/pointPay.png";
import mail from "../../assets/img/mailFl.png";
import outFl from "../../assets/img/outFl.png";

function MAdminUserInfoPg() {

    /**region DataTable */

    const [userData, setUserData] = useState([]);
    const [expandedRow, setExpandedRow] = useState(null); // 현재 펼쳐진 행의 index

    /** endregion */

    /** region Function */

    const LCAT = localStorage.getItem('adminToken');

    //region Search

    const [searchInput, setSearchInput] = useState('');
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const searchData = userData.filter((cData) => {
        return cData.userName.toLowerCase().includes(searchInput.toLowerCase());
    });

    //endregion

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        select();
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [searchData.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
        if (searchData.length > displayLimit && displayLimit < 30) {
            setDisplayLimit(prevLimit => prevLimit + perPg);
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    // region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        setperPg(parseInt(e));
        setDisplayLimit(parseInt(e));
        setCurrentPage(1);
    };

    const [popupVisible, setPopupVisible] = useState(false);

    const togglePopup = () => {
        setPopupVisible(!popupVisible);
    };

    //endregion

    const handleRowClick = (index) => {
        setExpandedRow(expandedRow === index ? null : index); // 클릭한 행의 index를 상태로 저장
    };

    //region Alert

    const [pendingUserOutFl, setPendingUserOutFl] = useState(null);
    const [alertShow, setAlertShow] = useState(false);
    const msgConfirmData1 = "선택된 회원이 탈퇴됩니다.";
    const msgConfirmData2 = "정말 탈퇴하시겠습니까?";
    // const outFlAlert = (()=> {setAlertShow(true)})
    const outFlAlert = (newValue) => {
        setPendingUserOutFl(newValue);
        setAlertShow(true);
    };

    const modalCancel = useCallback (() => {setAlertShow(false);})

    //endregion

    const [cData, setCData] = useState({ userOutFl: 'N' });
    const [outFlPopup, setOutFlPopup] = useState(false);

    /** endregion */

    /** region Event  */

    useEffect(() => {
        select();
    }, [])

    const select = () => {
        axios({
            method: 'get',
            url: '/member/adminuserselect',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                const filteredData = response.data.filter(item => item.role === "0");
                const sortedData = filteredData.sort((a, b) => a.userName.localeCompare(b.userName));
                setUserData(sortedData);
            })
            .catch(() => {})
    };

    const outFlUpdate = (newValue) => {
        axios.post('/member/userOutFlupdate', {
            mbNum: userData[expandedRow].mbNum,
            userOutFl: newValue
        }, {
            headers: {
                'Authorization': LCAT,
                'Content-Type': 'application/json',
            }
        })
            .then(() => {
                setAlertShow(false);
                setPendingUserOutFl(null);
                setUserData(prevUserData => {
                    const updatedUserData = [...prevUserData];
                    updatedUserData[expandedRow] = {
                        ...updatedUserData[expandedRow],
                        userOutFl: newValue
                    };
                    return updatedUserData;
                });
            })
            .catch((error) => {
                console.error("에러:", error);
            });
    };

    /** endregion */

    return (
        <div id="m-a-userinfo-pg-container">
            <div className="m-a-title-frame">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">회원정보</p>
            </div>
            <div className="m-a-card display-flex flex-column m-b-15">
                <div className="m-a-event-frame">
                    <div className="m-a-header-event">
                        <div className="m-a-header-event-combo">
                            <div className="m-a-combo font-t-cont font-c-default wd-55 ht-40 font-s-14 m-a-flex-jc-start m-r-10" onClick={togglePopup}>
                                <div className="font-t-cont">{perPg}</div>
                            </div>
                            {popupVisible && (
                                <MAdminPageSmallEvent perPg={perPg} handleSelectChange={handleSelectChange} togglePopup={togglePopup} />
                            )}
                        </div>
                    </div>
                    <div className="m-a-header-event">
                        <div className="m-a-header-event-search">
                            <MAdminSearchEvent searchInput={searchInput} handleSearchInputChange={handleSearchInputChange} />
                        </div>
                    </div>
                </div>
                <div className="m-a-tb-frame">
                    <div className="m-a-div-tb">
                        <div className="m-a-div-tb-head ht-60">
                            <div className="wd-35"><p>번호</p></div>
                            <div className="wd-110"><p>고객명</p></div>
                            <div className="wd-200"><p>아이디</p></div>
                            <div className="wd-70"><p>CS언어</p></div>
                            <div className="wd-70"><p>MB번호</p></div>
                            <div className="wd-90"><p>가입일자</p></div>
                        </div>
                        {searchData.slice(0, displayLimit).map((cData, i) => (
                            <div key={i}>
                                <div className="m-a-div-tb-body ht-70" onClick={() => handleRowClick(i)}>
                                    <div className="wd-35"><p>{indexOfFirst + i + 1}</p></div>
                                    <div className="wd-110"><p className="font-b">{cData.userName}</p></div>
                                    <div className="wd-200"><p className="font-b">{cData.id}</p></div>
                                    <div className="wd-70"><p>{cData.lang}</p></div>
                                    <div className="wd-70"><p>BT{cData.mbNum}</p></div>
                                    <div className="wd-90"><p>{`${cData.regDate.substring(2, 4)}-${cData.regDate.substring(4, 6)}-${cData.regDate.substring(6, 8)}`}</p></div>
                                </div>
                                {expandedRow === i && (
                                    <div className="m-a-user-dt-frame font-t-cont font-c-default font-s-14 p-l-40">
                                        <div className="m-a-u-d-1 p-t-5 p-b-5">
                                            <p className="m-r-20">
                                                <img className="wd-15 ht-15 m-b-2 m-r-5" alt="deposit" src={deposit}/>
                                                {cData.deposit.toLocaleString()}
                                            </p>
                                            <p className="m-r-20">
                                                <img className="wd-15 ht-15 m-b-2 m-r-5" alt="point" src={pointPay}/>
                                                {cData.point.toLocaleString()}
                                            </p>
                                            <p className="font-b">
                                                <img className="wd-15 ht-15 m-b-2 m-r-5" alt="mail" src={mail}/>
                                                {cData.mailRecFl}
                                            </p>
                                        </div>
                                        <div className="m-a-u-d-1 p-t-5 p-b-5">
                                            <div className="m-a-u-d-1 m-r-30">
                                                <p>가입일시:&nbsp;</p>
                                                <p>{cData.regTime}</p>
                                            </div>
                                            <div className="m-a-u-d-1">
                                                <p>수정일시:&nbsp;</p>
                                                <p>{cData.modiTime}</p>
                                            </div>
                                        </div>
                                        <div className="m-a-u-d-1 p-t-5 p-b-5">
                                            <img className="wd-15 ht-15 m-t-4 m-r-5" alt="out" src={outFl}/>
                                            <div className="m-a-combo wd-80 ht-23 font-s-12 m-r-15 font-b m-a-flex-jc-start" onClick={() => setOutFlPopup(true)}>
                                            {cData.userOutFl === 'Y' ? 'Y:탈퇴' : 'N:회원'}
                                            {outFlPopup && (
                                                <div className="m-a-page-popup-container" onClick={(e) => { e.stopPropagation(); setOutFlPopup(false); }}>
                                                    <div className="m-a-page-popup-frame" onClick={(e) => e.stopPropagation()}>
                                                        <div className="m-a-page-popup-header-frame">
                                                            <div className="font-t-title font-s-18 m-t-4">탈퇴/회원 선택</div>
                                                        </div>
                                                        <div className="m-a-page-popup-body-frame">
                                                            <div onClick={() => outFlAlert('Y')}
                                                                className={`m-a-card m-t-10 m-b-10 ${cData.userOutFl === 'Y' ? 'm-a-selected-option' : ''}`}>
                                                                Y:탈퇴
                                                            </div>
                                                            <div onClick={() => outFlAlert('N')}
                                                                className={`m-a-card m-t-10 m-b-10 ${cData.userOutFl === 'N' ? 'm-a-selected-option' : ''}`}>
                                                                N:회원
                                                            </div>
                                                        </div>
                                                        <div className="m-a-page-popup-footer-frame">
                                                            <div className="m-a-file-bt-container">
                                                                <button className="m-a-colorBt ht-40 m-b-5 wd-80" onClick={(e) => { e.stopPropagation(); setOutFlPopup(false); }}>
                                                                    <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            </div>
                                            <p>탈퇴일시:&nbsp;</p>
                                            <p>{cData.secTime}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    {displayLimit >= 30 && searchData.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {alertShow === true ?
                <MsgSelectModal alertShow={alertShow} eventHandle1={modalCancel} eventHandle2={() => outFlUpdate(pendingUserOutFl)} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/>
                : null}
            <TopBtn/>
        </div>
    );
}

export default MAdminUserInfoPg;
