import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import faq from '../../assets/img/mainFaq.png'
import axios from "axios";
import DOMPurify from "dompurify";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import maCateImg from "../../assets/img/m-a-cate.png";
import {BsEye} from "@react-icons/all-files/bs/BsEye";

function MAdminFaqDtPg() {

    const navigate = useNavigate();
    const {seqNum} = useParams();
    const LCAT = localStorage.getItem('adminToken');

    const moveToModifyPg = (() => {
        navigate(`/adminfaq-modify/${seqNum}`)
    })

    const [adminInfo, setAdminInfo] = useState("");
    const findMember = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data)
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (LCAT) {
            findMember();
        }
    }, [LCAT])

    const [faqDtData, setFaqDtData] = useState([]);
    const [photoList, setPhotoList] = useState([]);


    const selectFaqDtData = () =>
        axios({
            method: 'get',
            url: '/faq/selectFaqDtData',
            headers: {
                Authorization: LCAT
            },
            params: {
                seqNum: seqNum
            }
        })
            .then((response) => {
                setFaqDtData(response.data)
                setPhotoList(response.data.faqDtPhotoDTO)
            })

    useEffect(() => {
        if (seqNum) {
            selectFaqDtData();
        }
    }, [seqNum])

    const useSanitizedHTML = ((rawHTML) => {
        if (!rawHTML) return "";
        return DOMPurify.sanitize(rawHTML.replace(/\n/g, '<br>'));
    })
    const sanitizedContent = useSanitizedHTML(faqDtData?.content);

    const deleteFaqDtMsg1 = "해당 FAQ 게시글을 삭제하시겠습니까?"
    const deleteFaqDtMsg2 = "삭제 후 복구가 불가합니다"
    const [deleteFaqDtMsgShow, setDeleteFaqDtMsgShow] = useState(false);
    const closeDeleteFaqDtMsgShow = (() => {
        setDeleteFaqDtMsgShow(false);
    })

    const deleteFaqDt = (() => {
        axios({
            method: 'post',
            url: '/faq/deleteFaqDt',
            params: {
                faqDtSeqNum: seqNum,
            },
        })
            .then(() => {
                closeDeleteFaqDtMsgShow();
                navigate("/btcorpad/adminfaq");
            })
            .catch(() => {
                console.log("Faq Dt 삭제실패")
            })
    })

    const formatCutDate = (dateStr) => {
        if (dateStr && dateStr.length === 8) {
            const year = dateStr.slice(2, 4);
            const month = dateStr.slice(4, 6);
            const day = dateStr.slice(6, 8);
            return `${year}-${month}-${day}`;
        }
        return dateStr;
    };

    return(
        <div id="m-a-faqDt-pg-container">
            <div className="m-a-title-frame">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">FAQ상세</p>
            </div>
            <div className="m-a-card">
                <div className="display-flex flex-column wd-100pc">
                    <div className="display-flex flex-row wd-100pc border-c-b p-b-15 m-b-15">
                        <div className="display-flex wd-20pc">
                            <div className="m-a-header-profile-box-c wd-45 ht-70 m-0-a">
                                <img className="wd-100pc ht-100pc m-a-opc-06" alt="profileImg" src={faq}/>
                            </div>
                        </div>
                        <div className="display-flex flex-column wd-80pc">
                            <div className="display-flex flex-column wd-100pc p-l-7 font-c-default font-s-14 font-t-cont">
                                <div className="display-flex flex-row just-cont-sb">
                                    <div className="display-flex flex-row">
                                        <p className="m-r-3"><BsEye /></p>
                                        <p className="font-b m-t-1">{faqDtData?.view}</p>
                                    </div>
                                </div>
                                <div className="display-flex flex-row just-cont-sb">
                                    <p>관리자</p>
                                    <p>{formatCutDate(faqDtData?.regDate)}</p>
                                </div>
                            </div>
                            <div className="display-flex flex-row wd-100pc m-t-7">
                                <div className="display-flex flex-row just-cont-fstart wd-70pc p-l-7 font-s-13 font-t-title">
                                    <p>[{faqDtData?.faqMstDTO?.title}]</p>
                                    <p> {faqDtData?.title}</p>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-30pc font-s-14 font-t-cont">
                                    <p className="font-b font-c-progress-b" onClick={moveToModifyPg}>수정</p>
                                    <p className="font-b font-c-accent" onClick={()=> setDeleteFaqDtMsgShow(true)}>삭제</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="display-flex flex-column wd-100pc m-b-30">
                        <div className="display-flex just-cont-fstart m-t-15 font-s-14 font-t-cont font-c-default" style={{minHeight: "auto", maxHeight: "500px"}}>
                            <p dangerouslySetInnerHTML={{ __html: sanitizedContent }}></p>
                        </div>
                        <div className="display-flex flex-wrap">
                            {photoList && photoList.map((photo, index) => (
                                <div className="wd-100pc m-b-15" key={index} style={{boxSizing: "border-box"}}>
                                    <img className="wd-100pc ht-100pc" src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/writePhoto/" + photo.fileNm} alt="photoImg"
                                         style={{borderRadius:"4px"}}/>
                                </div>

                            ))}
                        </div>
                    </div>
                </div>
            </div>
            { deleteFaqDtMsgShow ?
                <MsgSelectModal deleteFaqDtMsgShow={deleteFaqDtMsgShow} deleteFaqDtMsg1={deleteFaqDtMsg1} deleteFaqDtMsg2={deleteFaqDtMsg2}
                                closeDeleteFaqDtMsgShow={closeDeleteFaqDtMsgShow} deleteFaqDt={deleteFaqDt}/>
                :
                null
            }
        </div>
    )
}

export default MAdminFaqDtPg
