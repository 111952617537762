import React, {useEffect, useRef, useState} from "react";
import commonClose from "../../../assets/img/commonClose.png";
import {BiCaretDown} from "react-icons/bi";

function AdminMstEdit({mstContent, modifyFaqMst, closeMsgModifyShow, mstAddShow, saveFaqMst, closeMstAddShow, defaultLang}) {

    const [faqMstContent, setFaqMstContent] = useState('');

    const handleFaqContentChange = (value) => {
        setFaqMstContent(value);
    };

    useEffect(()=> {
        if (mstContent) {
            setFaqMstContent(mstContent);
        }
    }, [])

    useEffect(() => {
        if (defaultLang) {
            setSelectedLang(defaultLang)
        }
    }, [defaultLang])

    const langList= ["Korean", "English", "Japanese", "Chinese", "Indonesian"]
    const [selectedLang, setSelectedLang] = useState('');
    const [langShow, setLangShow] = useState(false);
    const langRef = useRef(null);

    const [mstContentErrorMsg, setMstContentErrorMsg] = useState(false);
    const [langErrorMsg, setLangErrorMsg] = useState(false);

    const toggleLangShow = () => {
        setLangShow(!langShow);
    };

    const handleLangChange = (mst) => {
        setSelectedLang(mst);
        setLangShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (langRef.current && !langRef.current.contains(event.target)) {
                setLangShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const valiAndSave = () => {
        if (validation()) {
            if (mstAddShow) {
                saveFaqMst(faqMstContent, selectedLang)
            } else {
                modifyFaqMst(faqMstContent, selectedLang)
            }
        }
    }

    const validation = () => {
        if (faqMstContent === '' || !faqMstContent) setMstContentErrorMsg(true);
        if (selectedLang === '' || !selectedLang) setLangErrorMsg(true);
        return !!(faqMstContent && selectedLang)
    }

    useEffect(() => {
        if (faqMstContent.length > 0) {
            setMstContentErrorMsg(false);
        } if (!selectedLang || selectedLang !== '') {
            setLangErrorMsg(false);
        }
    }, [faqMstContent, selectedLang])

    return(
        <div className="bookContainer font-t-cont" style={{display: "flex", zIndex:"1000"}}>
            <div className="bookFrame" style={{
                minWidth: "400px",
                width: "auto",
                height: "auto",
                position: "fixed", // 또는 'absolute', 상황에 따라 다를 수 있음
                top: "45%",
                left: "50%",
                transform: "translate(-50%, -50%)", // 중앙 정렬을 위해
                borderRadius: "5px"
            }}>
                <div style={{width: "100%", height: "100%"}}>
                    {/*상세헤더*/}
                    <div style={{height: "45px"}}>
                        <div className="user-center" style={{
                            height: "100%",
                            fontSize: "23px",
                            border: "solid 1px lightgray",
                        }}>

                            <div className="user-center" style={{width: "100%", height:"100%"}}>
                                <div className="user-center">
                                <p className="font-t-cont" style={{
                                    height:"100%",
                                    fontSize: "16px",
                                    width: "100%"
                                }}>{mstAddShow ? "구분추가" : "구분수정"}</p>
                                </div>
                            </div>
                            <div onClick={mstAddShow ? closeMstAddShow : closeMsgModifyShow} style={{width: "40px", position:"absolute", right:"3px", top:"2px", textAlign: "center", cursor:"pointer"}}>
                                <img style={{width:"30px", height:"30px", opacity:"0.6"}} alt="closeImage" src={commonClose}/>
                            </div>

                        </div>
                    </div>
                    {/*상세바디*/}
                    <div style={{minHeight:"350px", display:"flex", flexDirection:"column", alignItems:"center", paddingTop:"40px"}}>

                        {/*Lang*/}
                        <div style={{width: "80%", height: "55px"}}>
                            {/*여기서부터 커스텀 Lang셀렉트*/}
                            <div ref={langRef}
                                 style={{height: "100%", width: "100%", position: "relative"}}>
                                <button className="selectParentBt"
                                        style={{width: "100%", height: "100%"}}
                                        onClick={toggleLangShow}>
                                    <p>{selectedLang === "" ? "배너언어" : selectedLang}</p>
                                    <span style={{
                                        position: "absolute",
                                        left: "80%",
                                        top: "0",
                                        height: "100%",
                                        zIndex:10,

                                        width: "20%"
                                    }}>
                                <i style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%"
                                }}><BiCaretDown/></i>
                            </span>
                                </button>
                                {langShow ?
                                    <div style={{
                                        width:"100%",
                                        height:"200px",
                                        overflowY:"scroll",
                                        border: "solid",
                                        borderWidth: "thin 1px",
                                        borderColor: "lightgray",
                                        position: "absolute",
                                        top: "100%",
                                        bottom: "100%",
                                        zIndex: 1,
                                        marginTop: "5px",
                                    }}>
                                        {langList.map((lang, index) => (
                                            <div className="selectChildBt" key={index} value={lang}
                                                 onClick={() => handleLangChange(lang)} style={{
                                                display: "flex",
                                                padding: "10px",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                cursor: "pointer",
                                                fontSize: "14px",
                                            }}>{lang}</div>
                                        ))}
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            {/*여기까지 커스텀 Lang셀렉트*/}
                        </div>
                        <div className="user-input-container" style={{width:"80%", height:"50px", marginTop:"10px"}}>
                            <input className="user-input" style={{width:"100%", height:"100%", padding:"0 10px", fontSize:"14px"}}
                                   value={faqMstContent} onChange={(e)=> handleFaqContentChange(e.target.value)} onKeyDown={(e) => {if(e.key === 'Enter') {
                                       valiAndSave()
                                   }
                                   }}
                            />
                        </div>

                        {mstContentErrorMsg ?
                            <div className="JoinErrorMsg" style={{marginTop:"10px"}}>
                                <p className="JoinErrMsg">&nbsp;내용을 입력해주세요</p>
                            </div>
                            :
                            null
                        }

                        {langErrorMsg ?
                            <div className="JoinErrorMsg" style={{marginTop:"10px"}}>
                                <p className="JoinErrMsg">&nbsp;언어를 선택해주세요</p>
                            </div>
                            :
                            null
                        }

                        <button onClick={valiAndSave} className="colorFullBt" style={{
                            width: "22%",
                            height: "50px",
                            textAlign: "center",
                            fontSize: "15px",
                            opacity: "0.6",
                            marginTop:"20px"
                        }}>완료
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminMstEdit
