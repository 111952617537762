import React from 'react';

function AdminYmdEvent({ startDate, endDate, startHandle, endHandle }) {
    return (
        <div className="font-t-cont m-r-15">
            <input type={"date"} className="wd-110 ht-30 ad-ymd-input admin-input-line font-s-13 p-l-5" min="1930-01-01" max="2099-12-31"
                   value={startDate} onChange={startHandle}/>
            <strong> - </strong>
            <input type={"date"} className="wd-110 ht-30 ad-ymd-input admin-input-line font-s-13 p-l-5" min="1930-01-01" max="2099-12-31"
                   value={endDate} onChange={endHandle}/>
        </div>
    );
}

export default AdminYmdEvent
