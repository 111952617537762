import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import complete from "../../assets/img/complete.png";
import "../../css/mobile/MUserPayComplPg.css";

const MUserPayComplPg = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const data = location.state.data;
    const navigate = useNavigate();

    const orderDetailPgMove = (ordNum) => () => {
        navigate(`/UserMyOrderPg/${ordNum}`);
    };

    return (
        <div className={'m-a-user-pay-compl-page-container'}>
            <div className={'m-a-user-pay-compl-contents'}>
                <img className={'m-a-user-pay-compl-icon'} src={complete} alt={''}/>
                <div className={'m-a-user-pay-compl-title'}>{t(`pcCont`)}</div>
                <div className={'m-a-user-pay-compl-divider'}/>

                <div className={'m-a-user-pay-compl-row'}>
                    <div className={'m-a-user-pay-compl-label'}>{t(`modNum`)}</div>
                    <div className={'m-a-user-pay-compl-value bold'}>
                        {data && data?.orderNumber}
                    </div>
                </div>
                <div className={'m-a-user-pay-compl-row'}>
                    <div className={'m-a-user-pay-compl-label'}>{t(`modPty`)}</div>
                    <div className={'m-a-user-pay-compl-value'}>
                        {data && data?.payWay === "PM01" ? 'Paypal'
                            : data?.payWay === "PM05" ? t(`modDep`)
                                : data?.payWay === "PM04" ? t(`modPt`)
                                    : null
                        }
                    </div>
                </div>
                <div className={'m-a-user-pay-compl-row'}>
                    <div className={'m-a-user-pay-compl-label'}>{t(`pcWon`)}</div>
                    <div className={'m-a-user-pay-compl-value bold primary'}>
                        {data &&
                            data?.lastPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                </div>
                {data && data?.payWay === "PM01" &&
                    <div className={'m-a-user-pay-compl-row'}>
                        <div className={'m-a-user-pay-compl-label'}>{t(`pcFor`)}</div>
                        <div className={'m-a-user-pay-compl-value bold primary'}>
                            {data && data?.lastForPrice}
                            <span className={'m-a-user-pay-compl-value pale'}>
                                &nbsp;{data && data?.forCurrency}
                            </span>
                        </div>
                    </div>
                }
            </div>
            <div className={'m-a-user-pay-compl-buttons'}>
                <div className={'m-a-user-pay-compl-button'}
                     onClick={() => navigate("/UserMyPg")}>{t(`hdMypg`)}</div>
                <div className={'m-a-user-pay-compl-button primary'}
                     onClick={orderDetailPgMove(data.orderNumber)}>{t(`modHeader`)}</div>
            </div>
        </div>
    )
}

export default MUserPayComplPg;