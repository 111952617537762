import "../../css/mobile/MJoinPg.css";
import React, {useCallback, useEffect, useRef, useState} from 'react';
import Logo from "../../assets/img/HeaderLogo.png";
import MHeader from "../../components/mobile/MHeader";
import Close from '../../assets/img/commonClose2.png'
import Expand from "../../assets/img/commonBottom.png";
import Document from "../../assets/img/termsView.png";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import CommonTermsPopup from "../../components/web/modal/CommonTermsPopup";
import SaveMsgModal from "../../components/web/modal/SaveMsgModal";

const MJoinPg = () => {
    const [isLanguageSelect, setIsLanguageSelect] = useState(false);
    // todo - 체크박스 변수 관리 각각 안하고 하나만 예시로 만들어둘게요.
    const [openPopUp, setOpenPopUp] = useState(false);
    // todo - 나중에 텍스트 import 해서 사용해주세요.
    const TermsOfUse = '이용 \n 약관'

    const {t} = useTranslation();
    const navigate = useNavigate()

    const [allCheck, setAllCheck] = useState(false);
    const [ServiceAgreeCheck, setServiceAgreeCheck] = useState(false);
    const [InformationCheck, setInformationCheck] = useState(false);
    const [MailReceptionCheck, setMailReceptionCheck] = useState(false);
    const [mailRecFl, setMailRecFl] = useState("N");
    const [useTermsAgree, setUseTermsAgree] = useState("N");
    const [infoGatAgree, setInfoGatAgree] = useState("N");
    const userType = "0";
    const userOutFl = "N"
    const socialJoinFl = false;

    const [userName, setUserName] = useState("");
    const [email1, setEmail1] = useState("");
    const [email2, setEmail2] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [lang, setLang] = useState("");

    const [emailError, setEmailError] = useState(false);
    const [idErrorCheck, setIdErrorCheck] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [langError, setLangError] = useState(false);
    const [agreeError, setAgreeError] = useState(false);
    const id = email1 + "@" + email2

    // "2022-12-28T21:15:07.109Z"  // 좌측이 몇번째 자리까지 가져올건지, 우측이 시작위치
    // 앞에서 네글자 가져오기 이런식으로 년/월/일 가져오고, 중간에 하이픈 및 : 없애기
    const dateToString = JSON.stringify(new Date())
    const year = dateToString.substring(1, 5) * 60 * 60 * 24 * 365; // 2022년
    const month = dateToString.substring(8, 6) * 60 * 60 * 24 * 30; // 12월
    const day = dateToString.substring(11, 9) * 60 * 60 * 24; // 29일
    const hours = dateToString.substring(14, 12) * 60 * 60; // 07시
    const minutes = dateToString.substring(17, 15) * 60; // 39분
    const seconds = dateToString.substring(20, 18) * 1; // 20초
    const atTime = year + month + day + hours + minutes + seconds; // 2022-12-29 // 07:39:20

    // 회원가입 페이지 동의 체크박스 Start
    const allCheckBox = () => {
        if (allCheck === false) {
            setAllCheck(true);
            setServiceAgreeCheck(true);
            setInformationCheck(true);
            setMailReceptionCheck(true);
        } else {
            setAllCheck(false);
            setServiceAgreeCheck(false);
            setInformationCheck(false);
            setMailReceptionCheck(false);
        }
    }

    const handleChange_AllAgree = (e) => {
        if (e.target.checked)
            setUseTermsAgree("Y") || setInfoGatAgree("Y") || setMailRecFl("Y")
        else setUseTermsAgree("N") || setInfoGatAgree("N") || setMailRecFl("N")
    }

    const serviceCheckBox = () => {
        if (ServiceAgreeCheck === false) {
            setServiceAgreeCheck(true)
        } else {
            setServiceAgreeCheck(false)
        }
    }

    const handleChange_useTermsAgree = (e) => {
        if (e.target.checked)
            setUseTermsAgree("Y");
        else setUseTermsAgree("N")
    }

    const infoCheckBox = () => {
        if (InformationCheck === false) {
            setInformationCheck(true)
        } else {
            setInformationCheck(false)
        }
    }

    const handleChange_infoGatAgree = (e) => {
        if (e.target.checked)
            setInfoGatAgree("Y");
        else setInfoGatAgree("N")
    }

    const mailCheckBox = () => {
        if (MailReceptionCheck === false) {
            setMailReceptionCheck(true)
        } else {
            setMailReceptionCheck(false)
        }
    }

    const handleChange_mailRecFl = (e) => {
        if (e.target.checked)
            setMailRecFl("Y");
        else setMailRecFl("N")
    }

    const JoinDbSave = () => axios.post("/member/join",
        {
            id: id,
            password: password,
            passwordConfirm: confirmPassword,
            userName: userName,
            lang: lang,
            mailRecFl: mailRecFl,
            infoGatAgree: infoGatAgree,
            useTermsAgree: useTermsAgree,
            userOutFl: userOutFl,
            userType: userType,
            joinVerFl: "N",
            socialJoinFl: socialJoinFl
        }
    )
        .then(() => {
            axios.post("/member/login",
                {
                    id: id,
                    password: password,
                    // withCredentials: true,
                    headers: {
                        "Content-Type": "application/json"
                    },
                })
                .then((response) => {
                    sessionStorage.setItem('AToken', response.headers.get('Authorization'))
                    const currentTime = new Date();
                    sessionStorage.setItem('lastLoginTime', currentTime);
                    navigate("/")
                })
        })

    // 리액트 회원가입 실시간 이메일중복 체크
    const IdCheck = async (id) => {
        if (id === undefined) return;
        await axios.get("/member/" + id).then((response) => {
            if (response.data === true) setIdErrorCheck(true);
            else setIdErrorCheck(false);
        });
    }

    useEffect(() => {
        IdCheck(id).then();
    }, [email1, email2]);

    useEffect(() => {
        if (ServiceAgreeCheck === true && InformationCheck === true && MailReceptionCheck === true) {
            setAllCheck(true)
        } else {
            setAllCheck(false)
        }
    }, [ServiceAgreeCheck, InformationCheck, MailReceptionCheck])

    // 회원가입 페이지 유효성검사 Start
    const onChangeEmail1 = (e) => { /* 첫번 째 이메일 주소가 xx또는 xx.xx 식의 형식이  아니면 에러메시지*/
        const emailRegex = /^[a-zA-Z-ㄱ-힣]{2,25}/; /*영문 2~25자*/
        if (!e.target.value || emailRegex.test(e.target.value)) setEmailError(false);
        else setEmailError(true);
        setEmail1(e.target.value);
    };

    const checkEmail1 = (e) => {
        onChangeEmail1(e);
        IdCheck().then();
    };

    const onChangeEmail2 = (e) => { /* 두번 째 이메일 주소가 xx.xx가 아니면 에러메시지*/
        const email2Regex = /([\w-.]+)((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(]?)$/
        if (!e.target.value || email2Regex.test(e.target.value)) setEmailError(false);
        else setEmailError(true);
        setEmail2(e.target.value);
    };

    const checkEmail2 = (e) => {
        onChangeEmail2(e);
        IdCheck().then();
    };

    const onChangePassword = (e) => { /*영문+숫자+특수문자 8~20자리*/
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()])(?=.*[0-9]).{8,20}$/;
        if ((!e.target.value || (passwordRegex.test(e.target.value)))) setPasswordError(false);
        else setPasswordError(true);

        if (!confirmPassword || e.target.value === confirmPassword) setConfirmPasswordError(false);
        else setConfirmPasswordError(true);
        setPassword(e.target.value);
    };

    const onChangeConfirmPassword = (e) => { /*비밀번호 확인(비밀번호와 다르면 에러메시지)*/
        if (password === e.target.value) setConfirmPasswordError(false);
        else setConfirmPasswordError(true);
        setConfirmPassword(e.target.value);
    };

    const onChangeName = (e) => {
        const userNameRegex = /^[a-zA-Z-ㄱ-힣]{2,25}/; /*영문 또는 한글 2~25자*/
        if ((!e.target.value || (userNameRegex.test(e.target.value)))) setNameError(false);
        else setNameError(true);
        setUserName(e.target.value);
    };

    const onChangeLang = (e) => {
        const langRegex = /^[a-zA-Z-ㄱ-힣]{2,25}/; /*영문 또는 한글 2~25자*/
        if ((!e.target.value || (langRegex.test(e.target.value)))) setLangError(false);
        else setLangError(true);
        setLang(e.target.value);
    };

    // 체크박스 유효성 검사 함수 2개
    const onChangeAllAgree = (e) => {
        if (allCheck === false) {
            setAgreeError(false);
        } else setAgreeError(true);
    };

    const onChangeAgree = (e) => {
        if (ServiceAgreeCheck || InformationCheck === !e.target.Check) {
            setAgreeError(false);
        } else setAgreeError(true);
    };

    // 메일인증시작
    const [certCode, setCertCode] = useState('');
    const handleCertCode = ((e) => {
        setCertCode(e);
    })

    const [codeSendSt, setCodeSendSt] = useState(false);
    const [certCompleteSt, setCertCompleteSt] = useState(false);
    const [certError, setCertError] = useState(false); // 인증 안 했을 때
    const [certCodeErrorCount, setCertCodeErrorCount] = useState(0);
    const [certInputError, setCertInputError] = useState(false); // 인증코드가 안 맞을 때
    const [certCodeInitMsg, setCertCodeInitMsg] = useState(false); // 10번 틀렸을 때
    const [validTime, setValidTime] = useState(300);
    const [timerId, setTimerId] = useState(null);
    const [saveMsgSt, setSaveMsgSt] = useState(false);
    const savePopupMsg = t('joinMsg');

    useEffect(() => {
        // 컴포넌트 언마운트 시 타이머 정리
        return () => {
            if (timerId) clearInterval(timerId);
        };
    }, [timerId]);

    const codeSend = (() => {
        if (email1 && email2 && !idErrorCheck) {
            setValidTime(300);
            setCodeSendSt(true);
            setCertCodeInitMsg(false);
            generateCertcode();
            startTimer();
        }
    })

    const startTimer = () => {
        // 기존 타이머가 있다면 초기화
        if (timerId) clearInterval(timerId);

        const newTimerId = setInterval(() => {
            setValidTime((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(newTimerId);
                    setCodeSendSt(false);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        setTimerId(newTimerId);
    };

    const certComplete = (() => {
        // 입력코드와 반환코드가 동일할 경우
        if (resCertCode > 0 && Number(certCode) === Number(resCertCode)) {
            setSaveMsgSt(true);
            setTimeout(() => setSaveMsgSt(false), 1000);
            setCertCompleteSt(true);
            setCertInputError(false);
            setCertError(false);
        } else {
            // 틀릴 경우
            const newCount = certCodeErrorCount + 1;
            setCertCodeErrorCount(newCount);
            // 1~9번 입력코드 틀렸을 경우
            if (newCount < 10) {
                setCertInputError(true);
            }
            // 10번 틀렸을 경우
            else if (newCount >= 10) {
                setCertCodeInitMsg(true);
                setCodeSendSt(false);
                setCertCodeErrorCount(0); // 카운트 초기화
            }
        }
    })

    const [resCertCode, setResCertCode] = useState('');
    const generateCertcode = (() => {
        axios({
            method: 'get',
            url: '/member/generateCertcode',
            params: {
                email: id,
                chatFl: false
            },
        })
            .then((response) => {
                setResCertCode(response.data);
            })
            .catch((error) => {
                console.log("인증생성실패 : ", error)
            })
    })
    // 메일인증종료

    const validation = () => {
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()])(?=.*[0-9]).{8,20}$/;

        let isValid = true;

        if (!certCompleteSt) {
            setCertError(true);
            isValid = false;
        }
        if ((!email1 || !email2) || email2 === 'none') {
            setEmailError(true);
            isValid = false;
        }
        if (!password || !passwordRegex.test(password)) {
            setPasswordError(true);
            isValid = false;
        }
        if (!confirmPassword) {
            setConfirmPasswordError(true);
            isValid = false;
        }
        if (!userName) {
            setNameError(true);
            isValid = false;
        }
        if (!lang || lang === '' || langSelectedOption.value === "none") {
            setLangError(true);
            isValid = false;
        }
        if (!useTermsAgree) {
            setAgreeError(true);
            isValid = false;
        }
        if (!infoGatAgree) {
            setAgreeError(true);
            isValid = false;
        }

        return isValid;
    };

    const errorAndSave = () => {
        if (validation()) {
            JoinDbSave()
        }
    };
    // 회원가입 페이지 유효성검사 End

    // 회원가입 소통언어 직접입력 인풋 Start
    // 소통언어 양식 셀렉트&인풋 전환 기능
    const [langChoiceReturn, setLangChoiceReturn] = useState(true);
    const LangHandle = useCallback(() => {
        setLangChoiceReturn(true);
        setLangSelectedOption(langOptions[0]);
    }, []);
    const [email2ChoiceReturn, setEmail2ChoiceReturn] = useState(true);
    const Email2Handle = useCallback(() => {
        setEmail2ChoiceReturn(true);
        setEmail2SelectedOption(email2Options[0]);
    }, []);

    // 이메일 수신동의 체크&언체크 메시지 표기 기능
    const [isChecked, setIsChecked] = useState(false)
    const MailState = useCallback((e) => {
        setIsChecked(e.target.checked);
    }, []);

    // 함수가 2개인 체크박스 기능들
    const AllAgreeCheck = (e) => {
        allCheckBox(e);
        onChangeAllAgree(e);
        handleChange_AllAgree(e);
    }

    const serviceCheck = (e) => {
        serviceCheckBox(e);
        onChangeAgree(e);
        handleChange_useTermsAgree(e);
    }

    const infoCheck = (e) => {
        infoCheckBox(e);
        onChangeAgree(e);
        handleChange_infoGatAgree(e);
    }

    const MailFiCheck = (e) => {
        mailCheckBox(e);
        handleChange_mailRecFl(e);
    }

    const email2Options = [
        {value: 'none', label: t('joinLabel2')},
        {value: 'naver.com', label: 'naver.com'},
        {value: 'gmail.com', label: 'gmail.com'},
        {value: 'hanmail.net', label: 'hanmail.net'},
        {value: 'hotmail.com', label: 'hotmail.com'},
        {value: 'daum.net', label: 'daum.net'},
        {value: 'nate.com', label: 'nate.com'},
        {value: 'qq.com', label: 'qq.com'},
        {value: 'yahoo.com', label: 'yahoo.com'},
        {value: 'yahoo.co.jp', label: 'yahoo.co.jp'},
        {value: 'outlook.com', label: 'outlook.com'},
        {value: 'icloud.com', label: 'icloud.com'},
        {value: '', label: t('mdSfIp')},
    ];

    const [email2SelectedOption, setEmail2SelectedOption] = useState(email2Options[0]);
    const [email2IsOpen, setEmail2IsOpen] = useState(false);
    const [email2FocusedIndex, setEmail2FocusedIndex] = useState(0);
    const email2DropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (email2DropdownRef.current && !email2DropdownRef.current.contains(e.target)) {
                setEmail2IsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const email2HandleSelect = (option, index) => {
        setEmail2SelectedOption(option);
        setEmail2FocusedIndex(index);
        setEmail2IsOpen(false);
        setEmail2(option.value);
        if (option.value === '') setEmail2ChoiceReturn(false);
        else setEmail2ChoiceReturn(true);
    };

    const email2HandleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setEmail2FocusedIndex((prevIndex) => {
                const newIndex = (prevIndex + 1) % email2Options.length;
                setEmail2SelectedOption(email2Options[newIndex]);
                setEmail2(email2Options[newIndex].value);
                if (email2Options[newIndex].value === '') setEmail2ChoiceReturn(false);
                else setEmail2ChoiceReturn(true);
                return newIndex;
            });
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setEmail2FocusedIndex((prevIndex) => {
                const newIndex = (prevIndex - 1 + email2Options.length) % email2Options.length;
                setEmail2SelectedOption(email2Options[newIndex]);
                setEmail2(email2Options[newIndex].value);
                if (email2Options[newIndex].value === '') setEmail2ChoiceReturn(false);
                else setEmail2ChoiceReturn(true);
                return newIndex;
            });
        } else if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            email2HandleSelect(email2Options[email2FocusedIndex], email2FocusedIndex);
        } else if (e.key === 'Escape') {
            setEmail2IsOpen(false);
        }
    };

    const email2HandleOnFocus = () => {
        if (email2FocusedIndex === -1) {
            setEmail2FocusedIndex(email2Options.findIndex((option) => option.value === email2SelectedOption.value));
        }
    };

    useEffect(() => {
        checkEmail2({target: {value: email2}});
    }, [email2]);

    const langOptions = [
        {value: 'none', label: t('lang')},
        {value: 'English', label: 'English'},
        {value: 'Japanese', label: 'Japanese'},
        {value: 'chinese', label: 'chinese'},
        {value: 'Indonesian', label: 'Indonesian'},
        {value: 'Korean', label: 'Korean'},
        {value: '', label: t('mdSfIp')},
    ];

    const [langSelectedOption, setLangSelectedOption] = useState(langOptions[0]);
    const [langIsOpen, setLangIsOpen] = useState(false);
    const [langFocusedIndex, setLangFocusedIndex] = useState(0);
    const langDropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (langDropdownRef.current && !langDropdownRef.current.contains(e.target)) {
                setLangIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const langHandleSelect = (option, index) => {
        if (option.value !== '' || option.value !== "none") {
            setLangError(false);
        }
        setLangSelectedOption(option);
        setLangFocusedIndex(index);
        setLangIsOpen(false);
        setLang(option.value);
        if (option.value === '') setLangChoiceReturn(false);
        else setLangChoiceReturn(true);
    };

    const langHandleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setLangFocusedIndex((prevIndex) => {
                const newIndex = (prevIndex + 1) % langOptions.length;
                setLangSelectedOption(langOptions[newIndex]);
                setLang(langOptions[newIndex].value);
                if (langOptions[newIndex].value === '') setLangChoiceReturn(false);
                else setLangChoiceReturn(true);
                return newIndex;
            });
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setLangFocusedIndex((prevIndex) => {
                const newIndex = (prevIndex - 1 + langOptions.length) % langOptions.length;
                setLangSelectedOption(langOptions[newIndex]);
                setLang(langOptions[newIndex].value);
                if (langOptions[newIndex].value === '') setLangChoiceReturn(false);
                else setLangChoiceReturn(true);
                return newIndex;
            });
        } else if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            langHandleSelect(langOptions[langFocusedIndex], langFocusedIndex);
        } else if (e.key === 'Escape') {
            setLangIsOpen(false);
        }
    };

    const langHandleOnFocus = () => {
        if (langFocusedIndex === -1) {
            setLangFocusedIndex(langOptions.findIndex((option) => option.value === langSelectedOption.value));
        }
    };

    const [termsOfUsePopup, setTermsOfUsePopup] = useState(false);
    const closeTermsOfUsePopup = (() => {
        setTermsOfUsePopup(false);
    })

    const [privacyPolicyPopup, setPrivacyPolicyPopup] = useState(false);
    const closePrivacyPolicyPopup = (() => {
        setPrivacyPolicyPopup(false);
    })


    return (
        <div className='m-a-join-pg-container'>
            <MHeader title={t(`hdJoin`)} url={"/"}/>
            <img className='m-a-join-pg-logo m-b-25 m-t-10' src={Logo} alt="BTORAGE"/>
            <div className={'m-a-join-pg-email-container'}>
                <input className='m-a-join-pg-input border half'
                       placeholder={t(`joinLabel1`)}
                       type="text"
                       value={email1}
                       onChange={!certCompleteSt ? checkEmail1 : null}/>
                @
                {email2ChoiceReturn && email2SelectedOption.value !== "" ? (
                    <div className={`m-a-join-pg-email-address-box border ${email2DropdownRef.current && 'focused'}`}
                         ref={email2DropdownRef}
                         tabIndex="0"
                         onKeyDown={email2HandleKeyDown}
                         onFocus={email2HandleOnFocus}
                         onClick={() => !certCompleteSt ? setEmail2IsOpen(!email2IsOpen) : null}>
                        <div className={'m-a-join-pg-email-address-text'}>
                            {email2SelectedOption.label}
                        </div>
                        <img className={'m-a-join-pg-email-address-expand-icon'} src={Expand} alt={''}/>
                        {email2IsOpen && (
                            <div className={'m-a-join-pg-email-address-list'}>
                                {email2Options.map((option, index) => (
                                    <div
                                        className={`m-a-join-pg-email-address-list-item ${index === email2FocusedIndex && 'selected'}`}
                                        key={option.value}
                                        onClick={() => email2HandleSelect(option, index)}>
                                        {option.label}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={'m-a-join-pg-email-address-box border'}>
                        <input className='m-a-join-pg-input half'
                               placeholder={t(`joinLabel2`)}
                               type="text"
                               value={email2}
                               onChange={(e) => !certCompleteSt ? checkEmail2(e) : null}
                        />
                        <img className={'m-a-join-pg-email-address-switch-icon'} src={Close} alt={''}
                             onClick={!certCompleteSt ? Email2Handle : null}/>
                    </div>
                )}
            </div>
            {emailError &&
                <div className={'m-a-join-pg-error'}>{t(`joinErr1`)}</div>
            }
            {idErrorCheck &&
                <div className={'m-a-join-pg-error'}>{t(`joinErr2`)}</div>
            }
            {!codeSendSt ? (
                <div className='m-a-join-pg-button' onClick={codeSend}> {t(`joinCodeBt`)}</div>
            ) : (
                <div className={'m-a-join-pg-cert-frame'}>
                    <div className={'border m-a-join-pg-cert-input-box'}>
                        <input className={'m-a-join-pg-cert-input'}
                               type="text" value={certCode}
                               onChange={(e) => handleCertCode(e.target.value)}
                               maxLength={6}/>
                        {codeSendSt && validTime !== 0 &&
                            <div className={'m-a-join-pg-cert-valid-time'}>
                                {validTime}{t(`joinS`)}
                            </div>}
                    </div>
                    <div className={'m-a-join-pg-cert-submit-button'} onClick={certComplete}>
                        {t(`joinCodeDoneBt`)}
                    </div>
                </div>
            )}
            {certError &&
                <div className={'m-a-join-pg-error'}>{t(`joinErr3`)}</div>
            }
            {certInputError && certCodeErrorCount > 0 &&
                <div className={'m-a-join-pg-error'}>{t(`joinErr4`)}</div>
            }
            {certCodeInitMsg &&
                <div className={'m-a-join-pg-error'}>{t(`joinErr6`)}</div>
            }
            <div className='m-a-join-pg-input-frame border'>
                <input className='m-a-join-pg-input'
                       type="password"
                       value={password} onChange={onChangePassword}
                       minLength="8" maxLength="20"
                       placeholder={t(`joinLabel3`)}/>
            </div>
            {passwordError && <div className={'m-a-join-pg-error'}>{t(`joinErr7`)}</div>}
            <div className='m-a-join-pg-input-frame border'>
                <input className='m-a-join-pg-input'
                       placeholder={t(`joinLabel4`)}
                       type="password"
                       value={confirmPassword}
                       onChange={onChangeConfirmPassword}
                       minLength="8" maxLength="20"/>
            </div>
            {confirmPasswordError &&
                <div className={'m-a-join-pg-error'}>{t(`joinErr8`)}</div>
            }
            <input className='m-a-join-pg-input border'
                   placeholder={t(`joinLabel5`)}
                   type="text"
                   value={userName} onChange={onChangeName}
                   minLength="2" maxLength="25"/>
            {nameError &&
                <div className={'m-a-join-pg-error'}>{t(`joinErr9`)}</div>
            }
            {langChoiceReturn && langSelectedOption.value !== "" ? (
                <div className={'m-a-join-pg-language-frame border'}
                     ref={langDropdownRef}
                     tabIndex="0"
                     onKeyDown={langHandleKeyDown}
                     onFocus={langHandleOnFocus}
                     onClick={() => setLangIsOpen(!langIsOpen)}
                >
                    <div className="m-a-join-pg-language">{langSelectedOption.label}</div>
                    <img className={`m-a-join-pg-select-icon ${langIsOpen ? 'reversed' : ''}`}
                         src={Expand} alt='Expand'/>
                    {langIsOpen && (
                        <div className={`m-a-join-pg-language-select`}
                             onChange={onChangeLang}>
                            {langOptions.map((option, index) => (
                                <div
                                    className={`m-a-join-pg-language-select-item  ${index === langFocusedIndex && 'selected'}`}
                                    key={option.value}
                                    onClick={() => langHandleSelect(option, index)}
                                >
                                    {option.label}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

            ) : (
                <div className={'m-a-join-pg-language-frame border'}>
                    <input className={'m-a-join-pg-language-select-input'}
                           type="text" placeholder={t(`joinLabel6`)}
                           value={lang}
                           onChange={onChangeLang}/>
                    <img className={'m-a-join-pg-language-select-switch-icon'} src={Close} alt={''}
                         onClick={LangHandle}/>
                </div>
            )}
            {langError &&
                <div className={'m-a-join-pg-error'}>{t(`joinErr10`)}</div>
            }
            <div className='m-a-join-pg-agreement-frame'>
                <div className='m-a-join-pg-checkbox-frame'>
                    <input className='m-a-join-pg-agreement-checkbox' type="checkbox"
                           style={{width: "18px", height: "18px", cursor: "pointer"}}
                           checked={allCheck} onClick={MailState} onChange={AllAgreeCheck}/>
                    <div className='m-a-join-pg-checkbox-text'>{t(`joinAgAll`)}</div>
                </div>
                <div className='m-a-join-pg-divider'/>
                {/*비토리지 이용약관*/}
                <div className='m-a-join-pg-checkbox-documented'>
                    <div className='m-a-join-pg-checkbox-frame'>
                        <input className={'m-a-join-pg-agreement-checkbox'}
                               type="checkbox"
                               style={{width: "18px", height: "18px", cursor: "pointer"}}
                               checked={ServiceAgreeCheck} onChange={serviceCheck}/>
                        <div className='m-a-join-pg-checkbox-text'>
                            {t(`joinAg1`)}
                            <div className='m-a-join-pg-checkbox-text mandatory'>({t(`essential`)})</div>
                        </div>
                    </div>
                    <img className='m-a-join-pg-document-icon' src={Document} alt='Document'
                         onClick={(e) => {
                             setTermsOfUsePopup(true)
                             e.stopPropagation()
                         }}/>
                </div>
                {/*개인정보처리방침*/}
                <div className='m-a-join-pg-checkbox-documented'>
                    <div className='m-a-join-pg-checkbox-frame'>
                        <input className={'m-a-join-pg-agreement-checkbox'}
                               type="checkbox"
                               style={{width: "18px", height: "18px", cursor: "pointer"}}
                               checked={InformationCheck} onChange={infoCheck}/>
                        <div className='m-a-join-pg-checkbox-text'>
                            {t(`joinAg2`)}
                            <div className='m-a-join-pg-checkbox-text mandatory'>({t(`essential`)})</div>
                        </div>
                    </div>
                    <img className='m-a-join-pg-document-icon' src={Document} alt='Document'
                         onClick={(e) => {
                             setPrivacyPolicyPopup(true)
                             e.stopPropagation()
                         }}/>
                </div>
                {/*메일수신동의*/}
                <div className='m-a-join-pg-checkbox-frame'
                     onClick={() => {
                         setIsChecked(!isChecked)
                     }}>
                    <input className={'m-a-join-pg-agreement-checkbox'}
                           type="checkbox"
                           style={{width: "18px", height: "18px", cursor: "pointer"}}
                           checked={MailReceptionCheck} onClick={MailState} onChange={MailFiCheck}/>
                    <div className='m-a-join-pg-checkbox-text'>
                        {t(`joinAg3`)}
                        <div className='m-a-join-pg-checkbox-text optional'>({t(`select`)})</div>
                    </div>
                </div>
                {!isChecked &&
                    <div className='m-a-join-pg-aggrement-desc'>
                        {t(`joinAgMailMsg`)}
                    </div>
                }
            </div>
            {agreeError && <div className='m-a-join-pg-error'>
                {t(`joinErr11`)}
            </div>}
            <button className='m-a-join-pg-button' onClick={errorAndSave}> {t(`joinBt`)}</button>
            {termsOfUsePopup ?
                <CommonTermsPopup termsOfUsePopup={termsOfUsePopup} closeTermsOfUsePopup={closeTermsOfUsePopup}/>
                :
                privacyPolicyPopup ?
                    <CommonTermsPopup privacyPolicyPopup={privacyPolicyPopup}
                                      closePrivacyPolicyPopup={closePrivacyPolicyPopup}/>
                    :
                    saveMsgSt ?
                        <SaveMsgModal message={savePopupMsg}/>
                        :
                        null
            }
        </div>
    )
}

export default MJoinPg;
