import React, {useEffect, useRef, useState} from "react";
import '../../css/mobile/MChatModifyModal.css'
import commonEdit from "../../assets/img/commonEdit.png";
import commonClose from "../../assets/img/commonClose.png";
import adminFileExchange from "../../assets/img/adminFileExchange.png";
import testImg from "../../assets/img/boxLogo.png";
import {useTranslation} from "react-i18next";

function MChatModifyModal({chatModifyFalseFn, messageToModify, chatFileModify, nonChatModifySt, nonChatModifyFalseFn, nonMessageToModify, nonChatFileModify}) {

    const { t } = useTranslation();

    const [modifyMessageText, setModifyMessageText] = useState(messageToModify ? messageToModify.chatKey : '');
    const [nonModifyMessageText, setNonModifyMessageText] = useState(nonMessageToModify ? nonMessageToModify.chatKey : '');
    const textareaRef = useRef(null);

    const handleChange = (e) => {
        if(nonChatModifySt) {
            setNonModifyMessageText(e.target.value);
        } else {
            setModifyMessageText(e.target.value);
        }
        const textarea = textareaRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = Math.min(textarea.scrollHeight, "100") + '%';
    };

    useEffect(() => {
        const textarea = textareaRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = Math.min(textarea.scrollHeight, "100") + '%';
    }, [messageToModify, nonMessageToModify])

    return (
        <div className="m-a-file-modal-container">
            <div className="m-a-file-modal-frame">
                <div className="m-a-file-modal-hd-frame">
                    <div className="m-a-file-icon-container">
                        <img alt='' src={commonEdit} className="m-a-file-icon"/>
                    </div>
                    <div className="m-a-file-head-text">
                        <div className="font-t-title font-s-18">Message Edit</div>
                    </div>
                    <div className="m-a-file-close">
                        <img onClick={nonChatModifySt ? nonChatModifyFalseFn : chatModifyFalseFn} className="m-a-file-close-img" src={commonClose} alt="close"/>
                    </div>
                </div>

                <div className="m-a-file-content-container user-center">



                    <div className="m-a-file-content-textarea-frame">
                        <textarea className="m-a-file-content-textarea font-t-cont"
                                  ref={textareaRef}
                                  placeholder={t(`cmmPlaceHd`)}
                                  spellCheck="false"
                                  value={nonChatModifySt ? nonModifyMessageText : modifyMessageText}
                                  onChange={handleChange}
                        >
                        </textarea>
                    </div>


                    {/*<div className="m-a-file-content-modifyFile-frame">*/}
                    {/*    <div className="m-a-file-content-modifyFile">*/}
                    {/*        <img className="m-a-file-content-modifyFile-img" src={testImg} alt="modifyFile"/>*/}
                    {/*    </div>*/}
                    {/*    <div className="m-a-file-content-exchange-button">*/}
                    {/*        <img className="m-a-file-content-exchange-button-img" src={adminFileExchange} alt="exchangeImg"/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                </div>

                <div className="m-a-file-bt-container">
                    { nonChatModifySt ?
                        <button className="m-a-colorBt ht-40 m-b-5 wd-80" onClick={()=> {nonChatFileModify(nonModifyMessageText, nonMessageToModify)}}>
                            <div className="m-a-colorBt-text font-t-cont">{t(`modRegModiBt`)}</div>
                        </button>
                        :
                        <button className="m-a-colorBt ht-40 m-b-5 wd-80" onClick={()=> {chatFileModify(modifyMessageText, messageToModify)}}>
                        <div className="m-a-colorBt-text font-t-cont">{t(`modRegModiBt`)}</div>
                        </button>
                    }

                </div>
            </div>
        </div>
    )
}

export default MChatModifyModal
