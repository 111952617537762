import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import commomHeader from '../../assets/img/commonHeader.png'
import commonGuide from '../../assets/img/commonGuide.png'
import detailCart from '../../assets/img/detailCart.png'
import detailOrder from '../../assets/img/detailOrder.png'
import detailOffline from '../../assets/img/detailOffline.png'
import detailTransfer from '../../assets/img/detailTransfer.png'
import detailDomestic from '../../assets/img/detailDomestic.png'
import detailDelivery from '../../assets/img/detailDelivery.png'
import GuideModal from "../../components/web/modal/GuideModal";

import guidePc1 from '../../assets/img/guidePc1.png'
import guidePc2 from '../../assets/img/guidePc2.png'
import guidePc3 from '../../assets/img/guidePc3.png'
import guidePc4 from '../../assets/img/guidePc4.png'
import guidePc5 from '../../assets/img/guidePc5.png'
import guidePc6 from '../../assets/img/guidePc6.png'
import guidePc7 from '../../assets/img/guidePc7.png'
import guidePc8 from '../../assets/img/guidePc8.png'

import guideOne from '../../assets/img/guideOne.png'
import guideTwo from '../../assets/img/guideTwo.png'
import guideThree from '../../assets/img/guideThree.png'
import guideFour from '../../assets/img/guideFour.png'
import guideFive from '../../assets/img/guideFive.png'
import guideSix from '../../assets/img/guideSix.png'

import comment from '../../assets/img/reviewComment.png'
import axios from "axios";
import noImage from "../../assets/img/NoImage.png";

function UserUsageGuidePg() {

    /** region DataSet */

    const { t } = useTranslation();

    const [guideData, setGuideData] = useState([]);

    /** endregion DataTable*/

    const [cartGuide, setCartGuide] = useState(false);
    const [orderGuide, setOrderGuide] = useState(true);
    const [offlineGuide, setOfflineGuide] = useState(false);
    const [transferGuide, setTransferGuide] = useState(false);
    const [domesticGuide, setDomesticGuide] = useState(false);
    const [deliveryGuide, setDeliveryGuide] = useState(false);

    const [cartGuidePopup, setCartGuidePopup] = useState(false);
    const [orderGuidePopup, setOrderGuidePopup] = useState(false);
    const [offlineGuidePopup, setOfflineGuidePopup] = useState(false);
    const [transferGuidePopup, setTransferGuidePopup] = useState(false);
    const [domesticGuidePopup, setDomesticGuidePopup] = useState(false);
    const [deliveryGuidePopup, setDeliveryGuidePopup] = useState(false);

    const cartGuideHeader = "장바구니 서비스 설명";
    // const cartGuideContent = "구매하고 싶은 한국 상품들을 고객님의 한국 쇼핑몰 계정의 장바구니에 담은 후 쇼핑몰 계정정보를 입력하여 주문서를 작성하시면 비토리지에서 고객님의 계정으로 로그인하여 장바구니의 상품들을 구매 후 해외로 배송해드리는 서비스입니다(구매하고자 하는 상품의 쇼핑몰 계정이 있어야 하며, 상품정보를 일일이 작성하지 않아도 되기 때문에 상품항목이 많을수록 간편합니다)";
    const orderGuideHeader = "구매대행 서비스 설명";
    // const orderGuideContent = "구매하고 싶은 한국 상품들을 상품 URL 주소를 입력하여 작성하시면 비토리지 측의 쇼핑몰 계정으로 주문 후 해외로 배송해드리는 서비스입니다(한국 쇼핑몰 계정이 없어도 원하는 상품들을 모두 구매할 수 있습니다)";
    const offlineGuideHeader = "오프라인 서비스 설명";
    const offlineGuideContent = "온라인에서는 판매하지 않고, 한국 오프라인 매장에서만 판매하는 상품을 구매하여 해외로 배송해드리는 서비스입니다.(수수료 부과)";
    const transferGuideHeader = "이체대행 서비스 설명";
    const transferGuideContent = "카드 결제가 불가능한 판매처나 국내 계좌로 한국 돈(KRW)을 이체하고 싶은 경우 대신 입금해드리는 서비스입니다.(수수료 부과)";
    const domesticGuideHeader = "국내배송 서비스 설명";
    // const domesticGuideContent = "구매하고 싶은 상품들을 주문서로 작성해주시면 비토리지 계정으로 구매 후, 한국(국내)로 배송해드리는 서비스입니다.(수수료 부과)";
    const deliveryGuideHeader = "배송대행 서비스 설명";
    const deliveryGuideContent = "비토리지에서 구매하지 않고, 고객님께서 직접 구매한 후 비토리지 물류센터로 주문해 주시거나 배송해 주시면 물류센터로부터 해외로 '배송만' 대행해 드리는 서비스입니다.";

    const changeSelectedGuide = ((guide) => {
        if (guide === 'cart') {
            setCartGuide(true);
            setOrderGuide(false);
            setOfflineGuide(false);
            setTransferGuide(false);
            setDomesticGuide(false);
            setDeliveryGuide(false);
        } else if (guide === 'order') {
            setCartGuide(false);
            setOrderGuide(true);
            setOfflineGuide(false);
            setTransferGuide(false);
            setDomesticGuide(false);
            setDeliveryGuide(false);
        } else if (guide === 'offline') {
            setCartGuide(false);
            setOrderGuide(false);
            setOfflineGuide(true);
            setTransferGuide(false);
            setDomesticGuide(false);
            setDeliveryGuide(false);
        } else if (guide === 'transfer') {
            setCartGuide(false);
            setOrderGuide(false);
            setOfflineGuide(false);
            setTransferGuide(true);
            setDomesticGuide(false);
            setDeliveryGuide(false);
        } else if (guide === 'domestic') {
            setCartGuide(false);
            setOrderGuide(false);
            setOfflineGuide(false);
            setTransferGuide(false);
            setDomesticGuide(true);
            setDeliveryGuide(false);
        } else if (guide === 'delivery') {
            setCartGuide(false);
            setOrderGuide(false);
            setOfflineGuide(false);
            setTransferGuide(false);
            setDomesticGuide(false);
            setDeliveryGuide(true);
        }
    })

    const openCartGuidePopup = (() => {
        setCartGuidePopup(true);
    })
    const openOrderGuidePopup = (() => {
        setOrderGuidePopup(true);
    })
    const openOfflineGuidePopup = (() => {
        setOfflineGuidePopup(true);
    })
    const openTransferGuidePopup = (() => {
        setTransferGuidePopup(true);
    })
    const openDomesticGuidePopup = (() => {
        setDomesticGuidePopup(true);
    })
    const openDeliveryGuidePopup = (() => {
        setDeliveryGuidePopup(true);
    })
    const closeCartGuidePopup = (() => {
        setCartGuidePopup(false);
    })
    const closeOrderGuidePopup = (() => {
        setOrderGuidePopup(false);
    })
    const closeOfflineGuidePopup = (() => {
        setOfflineGuidePopup(false);
    })
    const closeTransferGuidePopup = (() => {
        setTransferGuidePopup(false);
    })
    const closeDomesticGuidePopup = (() => {
        setDomesticGuidePopup(false);
    })
    const closeDeliveryGuidePopup = (() => {
        setDeliveryGuidePopup(false);
    })

    const groupByType = (guideData) => {
        return guideData.reduce((acc, guide) => {
            const type = guide.gtype;
            if (!acc[type]) {
                acc[type] = [];
            }
            acc[type].push(guide);
            return acc;
        }, {});
    };

    /** region Event */

    useEffect(() => {
        const selectGuideData = () => {
            axios.get('/guide/selectGuideList/')
                .then((response) => {
                    const groupedData = groupByType(response.data);
                    setGuideData(groupedData);
                });
        };
        selectGuideData();
    }, []);

    /** endregion */


    return(
        <div>
            <div style={{display:"flex", alignItems:"flex-start", padding:"10px 0"}}>
                <img className="wd-25 ht-25" src={commomHeader} alt="header"/>
                <p className="font-s-20 m-l-10 font-t-title font-c-default">{t(`hdSMenu2`)}</p>
            </div>
            <div className="display-flex just-cont-sb align-center m-t-20">
                {/*장바구니가이드*/}
                <div onClick={()=> changeSelectedGuide('cart')} style={{
                    width:"130px",
                    border: cartGuide ? "solid 2px darkorange" : null,
                    cursor:"pointer",
                    padding:"15px",
                    borderRadius:"10px"
                }}>
                    <div className="user-center ht-90">
                        <div className="user-center" style={{width:"80px", height:"70px", backgroundColor:"#F6F6F6", borderRadius:"10px"}}>
                            <img style={{width:"40px", height:"40px", opacity:"0.7"}} src={detailCart} alt="cart"/>
                        </div>
                    </div>
                    <div className="user-center font-t-cont">
                        <p className="text-center font-s-15">장바구니 가이드</p>
                    </div>
                </div>
                {/*구매대행가이드*/}
                <div onClick={()=> changeSelectedGuide('order')} style={{
                    width:"130px",
                    border: orderGuide ? "solid 2px darkorange" : null,
                    cursor:"pointer",
                    padding:"15px",
                    borderRadius:"10px"
                }}>
                    <div className="user-center" style={{height:"90px"}}>
                        <div className="user-center" style={{width:"80px", height:"70px", backgroundColor:"#F6F6F6", borderRadius:"10px"}}>
                            <img style={{width:"40px", height:"40px", opacity:"0.7"}} src={detailOrder} alt="order"/>
                        </div>
                    </div>
                    <div className="user-center font-t-cont">
                        <p className="text-center font-s-15">구매대행 가이드</p>
                    </div>
                </div>
                {/*오프라인가이드*/}
                <div onClick={()=> changeSelectedGuide('offline')} style={{
                    width:"130px",
                    border: offlineGuide ? "solid 2px darkorange" : null,
                    cursor:"pointer",
                    padding:"15px",
                    borderRadius:"10px"
                }}>
                    <div className="user-center" style={{height:"90px"}}>
                        <div className="user-center" style={{width:"80px", height:"70px", backgroundColor:"#F6F6F6", borderRadius:"10px"}}>
                            <img style={{width:"40px", height:"40px", opacity:"0.7"}} src={detailOffline} alt="offline"/>
                        </div>
                    </div>
                    <div className="user-center font-t-cont">
                        <p className="text-center font-s-15">오프라인 가이드</p>
                    </div>
                </div>
                {/*이체대행가이드*/}
                <div onClick={()=> changeSelectedGuide('transfer')} style={{
                    width:"130px",
                    border: transferGuide ? "solid 2px darkorange" : null,
                    cursor:"pointer",
                    padding:"15px",
                    borderRadius:"10px"
                }}>
                    <div className="user-center" style={{height:"90px"}}>
                        <div className="user-center" style={{width:"80px", height:"70px", backgroundColor:"#F6F6F6", borderRadius:"10px"}}>
                            <img style={{width:"40px", height:"40px", opacity:"0.7"}} src={detailTransfer} alt="transfer"/>
                        </div>
                    </div>
                    <div className="user-center font-t-cont">
                        <p className="text-center font-s-15">이체대행 가이드</p>
                    </div>
                </div>
                {/*국내배송가이드*/}
                <div onClick={()=> changeSelectedGuide('domestic')} style={{
                    width:"130px",
                    border: domesticGuide ? "solid 2px darkorange" : null,
                    cursor:"pointer",
                    padding:"15px",
                    borderRadius:"10px"
                }}>
                    <div className="user-center" style={{height:"90px"}}>
                        <div className="user-center" style={{width:"80px", height:"70px", backgroundColor:"#F6F6F6", borderRadius:"10px"}}>
                            <img style={{width:"40px", height:"40px", opacity:"0.7"}} src={detailDomestic} alt="domestic"/>
                        </div>
                    </div>
                    <div className="user-center font-t-cont">
                        <p className="text-center font-s-15">국내배송 가이드</p>
                    </div>
                </div>
                {/*배송대행가이드*/}
                <div onClick={()=> changeSelectedGuide('delivery')} style={{
                    width:"130px",
                    border: deliveryGuide ? "solid 2px darkorange" : null,
                    cursor:"pointer",
                    padding:"15px",
                    borderRadius:"10px"
                }}>
                    <div className="user-center" style={{height:"90px"}}>
                        <div className="user-center" style={{width:"80px", height:"70px", backgroundColor:"#F6F6F6", borderRadius:"10px"}}>
                            <img style={{width:"40px", height:"40px", opacity:"0.7"}} src={detailDelivery} alt="delivery"/>
                        </div>
                    </div>
                    <div className="user-center font-t-cont">
                        <p className="text-center font-s-15">배송대행 가이드</p>
                    </div>
                </div>
            </div>
            { cartGuide ?
                // 장바구니가이드 내용
                <div>
                    <div className="user-center ht-100 m-t-50" style={{borderBottom: "solid 1px #EAEAEA"}}>
                        <div className="user-center">
                            <p className="font-s-25 font-t-title">장바구니 가이드 절차</p>
                            <img onClick={openCartGuidePopup} className="wd-30 ht-30 m-l-10 c-point m-b-5" src={commonGuide} alt="guide"/>
                            <p className="font-t-cont font-s-13 m-l-3">(아이콘을 클릭해주세요)</p>
                        </div>
                    </div>
                    <div className="font-t-cont" style={{height:"150px", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"40px"}}>
                        {/*절차1*/}
                        <div>
                            <div style={{width:"150px", minHeight:"150px"}}>
                                <div className="user-center" style={{height:"90px", position:"relative"}}>
                                    <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideOne} alt="one"/>
                                    <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc1} alt="process1"/>
                                </div>
                                <div className="user-center">
                                    <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>장바구니담기</p>
                                </div>
                            </div>
                        </div>
                        {/*절차2*/}
                        <div>
                            <div style={{width:"150px", minHeight:"150px"}}>
                                <div className="user-center" style={{height:"90px", position:"relative"}}>
                                    <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideTwo} alt="two"/>
                                    <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc2} alt="process2"/>
                                </div>
                                <div className="user-center">
                                    <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>장바구니신청</p>
                                </div>
                            </div>
                        </div>
                        {/*절차3*/}
                        <div>
                            <div style={{width:"150px", minHeight:"150px"}}>
                                <div className="user-center" style={{height:"90px", position:"relative"}}>
                                    <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideThree} alt="three"/>
                                    <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc3} alt="process3"/>
                                </div>
                                <div className="user-center">
                                    <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>해외배송비 청구</p>
                                </div>
                            </div>
                        </div>
                        {/*절차4*/}
                        <div>
                            <div style={{width:"150px", minHeight:"150px"}}>
                                <div className="user-center" style={{height:"90px", position:"relative"}}>
                                    <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideFour} alt="four"/>
                                    <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc4} alt="process4"/>
                                </div>
                                <div className="user-center">
                                    <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>해외배송비 결제</p>
                                </div>
                            </div>
                        </div>
                        {/*절차5*/}
                        <div>
                            <div style={{width:"150px", minHeight:"150px"}}>
                                <div className="user-center" style={{height:"90px", position:"relative"}}>
                                    <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideFive} alt="five"/>
                                    <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc5} alt="process5"/>
                                </div>
                                <div className="user-center">
                                    <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>해외로 출발</p>
                                </div>
                            </div>
                        </div>
                        {/*절차6*/}
                        <div>
                            <div style={{width:"150px", minHeight:"150px"}}>
                                <div className="user-center" style={{height:"90px", position:"relative"}}>
                                    <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "lightgrayscale(100%) brightness(99%)"}}  src={guideSix} alt="six"/>
                                    <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc6} alt="process6"/>
                                </div>
                                <div className="user-center">
                                    <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>수령</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*장바구니 사진영역*/}
                    <div className="font-t-cont m-t-20 m-b-10">
                        <div className="ht-100pc wd-100pc">
                            <div className="userGuideContFrame p-t-25 p-b-20">
                                <div>
                                    {guideData['P00'] && guideData['P00'][0] && (
                                        <div key={guideData['P00'][0].gtitle}>
                                            <p className="font-t-title font-s-22">제목: {guideData['P00'][0].gtitle}</p>
                                            <div dangerouslySetInnerHTML={{ __html: guideData['P00'][0].gcont }} />
                                            {/*{guideData['P00'][0].userGuidePhotoDTOS && guideData['P00'][0].userGuidePhotoDTOS.length > 0 && (*/}
                                            {/*    guideData['P00'][0].userGuidePhotoDTOS.map((photo, index) => (*/}
                                            {/*        <img style={{maxWidth: "100%", maxHeight: "100%", objectFit: "contain", textAlign: "center",}}*/}
                                            {/*             src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/useGuide/" + guideData?.userGuidePhotoDTOS?.fileNm} alt="guideImg"/>*/}
                                            {/*    ))*/}
                                            {/*)}*/}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                orderGuide ?
                    // 구매대행가이드 내용
                    <div>
                        <div className="user-center ht-100 m-t-50" style={{borderBottom: "solid 1px #EAEAEA"}}>
                            <div className="user-center">
                                <p className="font-s-25 font-t-title">구매대행 가이드 절차</p>
                                <img onClick={openOrderGuidePopup} className="wd-30 ht-30 m-l-10 c-point m-b-5" src={commonGuide} alt="guide"/>
                                <p className="font-t-cont font-s-13 m-l-3">(아이콘을 클릭해주세요)</p>
                            </div>
                        </div>
                        <div className="font-t-cont" style={{height:"150px", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"40px"}}>
                            {/*절차1*/}
                            <div>
                                <div style={{width:"150px", minHeight:"150px"}}>
                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideOne} alt="one"/>
                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc2} alt="process2"/>
                                    </div>
                                    <div className="user-center">
                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>구매대행신청</p>
                                    </div>
                                </div>
                            </div>
                            {/*절차2*/}
                            <div>
                                <div style={{width:"150px", minHeight:"150px"}}>
                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideTwo} alt="two"/>
                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc4} alt="process2"/>
                                    </div>
                                    <div className="user-center">
                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>상품금액결제</p>
                                    </div>
                                </div>
                            </div>
                            {/*절차3*/}
                            <div>
                                <div style={{width:"150px", minHeight:"150px"}}>
                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideThree} alt="three"/>
                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc3} alt="process3"/>
                                    </div>
                                    <div className="user-center">
                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>해외배송비 청구</p>
                                    </div>
                                </div>
                            </div>
                            {/*절차4*/}
                            <div>
                                <div style={{width:"150px", minHeight:"150px"}}>
                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideFour} alt="four"/>
                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc4} alt="process4"/>
                                    </div>
                                    <div className="user-center">
                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>해외배송비 결제</p>
                                    </div>
                                </div>
                            </div>
                            {/*절차5*/}
                            <div>
                                <div style={{width:"150px", minHeight:"150px"}}>
                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideFive} alt="five"/>
                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc5} alt="process5"/>
                                    </div>
                                    <div className="user-center">
                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>해외로 출발</p>
                                    </div>
                                </div>
                            </div>
                            {/*절차6*/}
                            <div>
                                <div style={{width:"150px", minHeight:"150px"}}>
                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideSix} alt="six"/>
                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc6} alt="process6"/>
                                    </div>
                                    <div className="user-center">
                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>수령</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*구매대행 사진영역*/}
                        <div className="font-t-cont m-t-20 m-b-10">
                            <div className="ht-100pc wd-100pc">
                                <div className="userGuideContFrame p-t-25 p-b-20">
                                    <div>
                                        {guideData['P01'] && guideData['P01'][0] && (
                                            <div key={guideData['P01'][0].gtitle}>
                                                <p className="font-t-title font-s-22">제목: {guideData['P01'][0].gtitle}</p>
                                                <div dangerouslySetInnerHTML={{ __html: guideData['P01'][0].gcont }} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*기존꺼*/}
                        {/*<div className="font-t-cont" style={{marginTop:"20px"}}>*/}
                        {/*    <div>*/}
                        {/*        <div style={{display:"flex", alignItems:"center"}}>*/}
                        {/*            <img style={{width:"30px", height:"30px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideOne} alt="process1"/>*/}
                        {/*            <p style={{margin:"0", fontSize:"18px", marginLeft:"10px"}}>구매대행신청</p>*/}
                        {/*        </div>*/}
                        {/*        <div style={{marginTop:"20px", border:"solid 2px lightgray", borderRadius:"10px"}}>*/}
                        {/*            <div style={{height:"800px", width:"1244px", padding:"5px"}}>*/}
                        {/*                <img style={{width:"100%", height:"100%"}} src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/fixFile/guideTest.PNG"} alt="photo1"/>*/}
                        {/*            </div>*/}
                        {/*            <div style={{minHeight:"100px", borderTop:"solid", borderColor:"lightgray"}}>*/}
                        {/*                <div style={{height:"30px", display:"flex", alignItems:"center", padding:"10px", marginTop:"10px"}}>*/}
                        {/*                    <img style={{width:"25px", height:"25px", opacity:"0.7"}} src={comment} alt="comment"/>*/}
                        {/*                    <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", marginLeft:"5px"}}>Comment</p>*/}
                        {/*                </div>*/}
                        {/*                <div style={{padding:"0 10px"}}>*/}
                        {/*                    <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", letterSpacing:"1px", lineHeight:"26px"}}>*/}
                        {/*                        ※ 신청서를 작성해주세요*/}
                        {/*                    </p>*/}
                        {/*                </div>*/}
                        {/*                <div></div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    :
                    offlineGuide ?
                        // 오프라인가이드 내용
                        <div>
                            <div className="user-center ht-100 m-t-50" style={{borderBottom: "solid 1px #EAEAEA"}}>
                                <div className="user-center">
                                    <p className="font-s-25 font-t-title">오프라인 가이드 절차</p>
                                    <img onClick={openOfflineGuidePopup} className="wd-30 ht-30 m-l-10 c-point m-b-5" src={commonGuide} alt="guide"/>
                                    <p className="font-t-cont font-s-13 m-l-3">(아이콘을 클릭해주세요)</p>
                                </div>
                            </div>
                            <div className="font-t-cont" style={{height:"150px", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"40px"}}>
                                {/*절차1*/}
                                <div>
                                    <div style={{width:"150px", minHeight:"150px"}}>
                                        <div className="user-center" style={{height:"90px", position:"relative"}}>
                                            <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideOne} alt="one"/>
                                            <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc2} alt="process1"/>
                                        </div>
                                        <div className="user-center">
                                            <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>오프라인신청</p>
                                        </div>
                                    </div>
                                </div>
                                {/*절차2*/}
                                <div>
                                    <div style={{width:"150px", minHeight:"150px"}}>
                                        <div className="user-center" style={{height:"90px", position:"relative"}}>
                                            <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideTwo} alt="two"/>
                                            <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc4} alt="process2"/>
                                        </div>
                                        <div className="user-center">
                                            <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>상품금액결제</p>
                                        </div>
                                    </div>
                                </div>
                                {/*절차3*/}
                                <div>
                                    <div style={{width:"150px", minHeight:"150px"}}>
                                        <div className="user-center" style={{height:"90px", position:"relative"}}>
                                            <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideThree} alt="three"/>
                                            <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc3} alt="process3"/>
                                        </div>
                                        <div className="user-center">
                                            <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>해외배송비 청구</p>
                                        </div>
                                    </div>
                                </div>
                                {/*절차4*/}
                                <div>
                                    <div style={{width:"150px", minHeight:"150px"}}>
                                        <div className="user-center" style={{height:"90px", position:"relative"}}>
                                            <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideFour} alt="four"/>
                                            <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc4} alt="process4"/>
                                        </div>
                                        <div className="user-center">
                                            <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>해외배송비 결제</p>
                                        </div>
                                    </div>
                                </div>
                                {/*절차5*/}
                                <div>
                                    <div style={{width:"150px", minHeight:"150px"}}>
                                        <div className="user-center" style={{height:"90px", position:"relative"}}>
                                            <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideFive} alt="five"/>
                                            <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc5} alt="process5"/>
                                        </div>
                                        <div className="user-center">
                                            <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>해외로 출발</p>
                                        </div>
                                    </div>
                                </div>
                                {/*절차6*/}
                                <div>
                                    <div style={{width:"150px", minHeight:"150px"}}>
                                        <div className="user-center" style={{height:"90px", position:"relative"}}>
                                            <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideSix} alt="six"/>
                                            <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc6} alt="process6"/>
                                        </div>
                                        <div className="user-center">
                                            <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>수령</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*오프라인 사진영역*/}
                            <div className="font-t-cont m-t-20 m-b-10">
                                <div className="ht-100pc wd-100pc">
                                    <div className="userGuideContFrame p-t-25 p-b-20">
                                        <div>
                                            {guideData['P02'] && guideData['P02'][0] && (
                                                <div key={guideData['P02'][0].gtitle}>
                                                    <p className="font-t-title font-s-22">제목: {guideData['P02'][0].gtitle}</p>
                                                    <div dangerouslySetInnerHTML={{ __html: guideData['P02'][0].gcont }} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        transferGuide ?
                            // 이체대행가이드 내용
                            <div>
                                <div className="user-center ht-100 m-t-50" style={{borderBottom: "solid 1px #EAEAEA"}}>
                                    <div className="user-center">
                                        <p className="font-s-25 font-t-title">이체대행 가이드 절차</p>
                                        <img onClick={openTransferGuidePopup} className="wd-30 ht-30 m-l-10 c-point m-b-5" src={commonGuide} alt="guide"/>
                                        <p className="font-t-cont font-s-13 m-l-3">(아이콘을 클릭해주세요)</p>
                                    </div>
                                </div>
                                <div className="font-t-cont" style={{height:"150px", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"40px"}}>
                                    {/*절차1*/}
                                    <div>
                                        <div style={{width:"150px", minHeight:"150px"}}>
                                            <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideOne} alt="one"/>
                                                <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc2} alt="process1"/>
                                            </div>
                                            <div className="user-center">
                                                <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>이체대행신청</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/*절차2*/}
                                    <div>
                                        <div style={{width:"150px", minHeight:"150px"}}>
                                            <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideTwo} alt="two"/>
                                                <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc4} alt="process2"/>
                                            </div>
                                            <div className="user-center">
                                                <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>이체금액결제</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/*절차3*/}
                                    <div>
                                        <div style={{width:"150px", minHeight:"150px"}}>
                                            <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideThree} alt="three"/>
                                                <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc5} alt="process3"/>
                                            </div>
                                            <div className="user-center">
                                                <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>해외배송도 가능</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*이체대행 사진영역*/}
                                <div className="font-t-cont m-t-20 m-b-10">
                                    <div className="ht-100pc wd-100pc">
                                        <div className="userGuideContFrame p-t-25 p-b-20">
                                            <div>
                                                {guideData['P03'] && guideData['P03'][0] && (
                                                    <div key={guideData['P03'][0].gtitle}>
                                                        <p className="font-t-title font-s-22">제목: {guideData['P03'][0].gtitle}</p>
                                                        <div dangerouslySetInnerHTML={{ __html: guideData['P03'][0].gcont }} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            domesticGuide ?
                                // 국내배송가이드 내용
                                <div>
                                    <div className="user-center ht-100 m-t-50" style={{borderBottom: "solid 1px #EAEAEA"}}>
                                        <div className="user-center">
                                            <p className="font-s-25 font-t-title">국내배송 가이드 절차</p>
                                            <img onClick={openDomesticGuidePopup} className="wd-30 ht-30 m-l-10 c-point m-b-5" src={commonGuide} alt="guide"/>
                                            <p className="font-t-cont font-s-13 m-l-3">(아이콘을 클릭해주세요)</p>
                                        </div>
                                    </div>
                                    <div className="font-t-cont" style={{height:"150px", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"40px"}}>
                                        {/*절차1*/}
                                        <div>
                                            <div style={{width:"150px", minHeight:"150px"}}>
                                                <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                    <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideOne} alt="one"/>
                                                    <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc2} alt="process1"/>
                                                </div>
                                                <div className="user-center">
                                                    <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>국내배송신청</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/*절차2*/}
                                        <div>
                                            <div style={{width:"150px", minHeight:"150px"}}>
                                                <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                    <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideTwo} alt="two"/>
                                                    <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc4} alt="process2"/>
                                                </div>
                                                <div className="user-center">
                                                    <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>상품금액결제</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/*절차3*/}
                                        <div>
                                            <div style={{width:"150px", minHeight:"150px"}}>
                                                <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                    <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideThree} alt="three"/>
                                                    <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc7} alt="process3"/>
                                                </div>
                                                <div className="user-center">
                                                    <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>한국주소로 출발</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*국내배송 사진영역*/}
                                    <div className="font-t-cont m-t-20 m-b-10">
                                        <div className="ht-100pc wd-100pc">
                                            <div className="userGuideContFrame p-t-25 p-b-20">
                                                <div>
                                                    {guideData['P04'] && guideData['P04'][0] && (
                                                        <div key={guideData['P04'][0].gtitle}>
                                                            <p className="font-t-title font-s-22">제목: {guideData['P04'][0].gtitle}</p>
                                                            <div dangerouslySetInnerHTML={{ __html: guideData['P04'][0].gcont }} />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                deliveryGuide ?
                                    // 배송대행가이드 내용
                                    <div>
                                        <div className="user-center ht-100 m-t-50" style={{borderBottom: "solid 1px #EAEAEA"}}>
                                            <div className="user-center">
                                                <p className="font-s-25 font-t-title">배송대행 가이드 절차</p>
                                                <img onClick={openDeliveryGuidePopup} className="wd-30 ht-30 m-l-10 c-point m-b-5" src={commonGuide} alt="guide"/>
                                                <p className="font-t-cont font-s-13 m-l-3">(아이콘을 클릭해주세요)</p>
                                            </div>
                                        </div>
                                        <div className="font-t-cont" style={{height:"150px", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"40px"}}>
                                            {/*절차1*/}
                                            <div>
                                                <div style={{width:"150px", minHeight:"150px"}}>
                                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideOne} alt="one"/>
                                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc8} alt="process1"/>
                                                    </div>
                                                    <div className="user-center">
                                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>물류센터로 주문</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*절차2*/}
                                            <div>
                                                <div style={{width:"150px", minHeight:"150px"}}>
                                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideTwo} alt="two"/>
                                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc2} alt="process2"/>
                                                    </div>
                                                    <div className="user-center">
                                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>배송대행신청</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*절차3*/}
                                            <div>
                                                <div style={{width:"150px", minHeight:"150px"}}>
                                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideThree} alt="three"/>
                                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc3} alt="process3"/>
                                                    </div>
                                                    <div className="user-center">
                                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>해외배송비 청구</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*절차4*/}
                                            <div>
                                                <div style={{width:"150px", minHeight:"150px"}}>
                                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideFour} alt="four"/>
                                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc4} alt="process4"/>
                                                    </div>
                                                    <div className="user-center">
                                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>해외배송비 결제</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*절차5*/}
                                            <div>
                                                <div style={{width:"150px", minHeight:"150px"}}>
                                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideFive} alt="five"/>
                                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc5} alt="process5"/>
                                                    </div>
                                                    <div className="user-center">
                                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>해외로 출발</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*절차6*/}
                                            <div>
                                                <div style={{width:"150px", minHeight:"150px"}}>
                                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideSix} alt="six"/>
                                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc6} alt="process6"/>
                                                    </div>
                                                    <div className="user-center">
                                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>수령</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*배송대행 사진영역*/}
                                        <div className="font-t-cont m-t-20 m-b-10">
                                            <div className="ht-100pc wd-100pc">
                                                <div className="userGuideContFrame p-t-25 p-b-20">
                                                    <div>
                                                        {guideData['P05'] && guideData['P05'][0] && (
                                                            <div key={guideData['P05'][0].gtitle}>
                                                                <p className="font-t-title font-s-22">제목: {guideData['P05'][0].gtitle}</p>
                                                                <div dangerouslySetInnerHTML={{ __html: guideData['P05'][0].gcont }} />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
            }
            { cartGuidePopup ?
                <GuideModal cartGuidePopup={cartGuidePopup} closeCartGuidePopup={closeCartGuidePopup} cartGuideHeader={cartGuideHeader}/>
                :
                orderGuidePopup ?
                    <GuideModal orderGuidePopup={orderGuidePopup} closeOrderGuidePopup={closeOrderGuidePopup} orderGuideHeader={orderGuideHeader}/>
                    :
                    offlineGuidePopup ?
                        <GuideModal offlineGuidePopup={offlineGuidePopup} closeOfflineGuidePopup={closeOfflineGuidePopup}
                                    offlineGuideHeader={offlineGuideHeader} offlineGuideContent={offlineGuideContent}/>
                        :
                        transferGuidePopup ?
                            <GuideModal transferGuidePopup={transferGuidePopup} closeTransferGuidePopup={closeTransferGuidePopup}
                                        transferGuideHeader={transferGuideHeader} transferGuideContent={transferGuideContent}/>
                            :
                            domesticGuidePopup ?
                                <GuideModal domesticGuidePopup={domesticGuidePopup} closeDomesticGuidePopup={closeDomesticGuidePopup}
                                            domesticGuideHeader={domesticGuideHeader}/>
                                :
                                deliveryGuidePopup ?
                                    <GuideModal deliveryGuidePopup={deliveryGuidePopup} closeDeliveryGuidePopup={closeDeliveryGuidePopup}
                                                deliveryGuideHeader={deliveryGuideHeader} deliveryGuideContent={deliveryGuideContent}/>
                                    :
                                    null
            }
        </div>
    )
}

export default UserUsageGuidePg
