import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import MsgCheckModal from "./MsgCheckModal";

function NaverLoginLoading() {

    const location = useLocation();
    const navigate = useNavigate();

    const [naverLoginErrorMsg, setNaverLoginErrorMsg] = useState(false);
    const naverLoginErrorTrue = (() => {
        setNaverLoginErrorMsg(true);
    })
    const naverLoginErrorClose = (() => {
        setNaverLoginErrorMsg(false);
        navigate("/UserLoginPg")
    })

    const sendAccessInfo = async (deviceType, id) => {
        try {
            const response = await axios.post('/init/accessInfo', {
                userId: id,
                accessTime: new Date().toISOString(),
                machFl: deviceType
            });
        } catch (error) {
            console.error(error);
        }
    };

    const determineMachFl = () => {
        if (window.navigator.userAgent.includes('ReactNative')) {
            return 'A'; //App
        } else if (window.navigator.userAgent.includes('Mobi')) {
            return 'M'; //웹앱
        } else {
            return 'P'; //PC
        }
    };

    useEffect(() => {

        // UserLoginPg로부터 리다이렉트되면 code와 state가 반환됨
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');
        const state = queryParams.get('state');

        const fetchData = async () => {
            try {
                // code와 state를 가지고 백엔드에서 네이버유저 찾아서 로그인시키고 토큰반환
                const response = await axios.get(`/externalApi/naverLogin`, { params: { code, state : state } });
                const currentTime = new Date();
                localStorage.setItem('AToken', response.headers['authorization']);
                localStorage.setItem('lastLoginTime', currentTime);
                const userEmail = response.data;
                const deviceType = determineMachFl();
                await sendAccessInfo(deviceType, userEmail);

                let navigateBackTo = location.state?.from || "/";
                navigate(navigateBackTo);
            } catch (error) {
                naverLoginErrorTrue();
            }
        };

        if (state.length === 16 && code) {
            fetchData();
        } else {
            naverLoginErrorTrue();
        }


    }, []);






    // useEffect(() => {
    //     const fetchData = async () => {
    //         // UserLoginPg로부터 리다이렉트되면 code와 state가 반환됨
    //         const queryParams = new URLSearchParams(location.search);
    //         const code = queryParams.get('code');
    //         const state = queryParams.get('state');
    //         try {
    //             // code와 state를 가지고 백엔드에서 네이버유저 찾아서 로그인시키고 토큰반환
    //             const response = await axios.get(`/externalApi/naverLogin`, { params: { code, state : state } });
    //             localStorage.setItem('AToken', response.headers['authorization']); // 주의: 헤더 이름은 대소문자 구분 없이 사용할 수 있습니다.
    //             localStorage.setItem('oneAtTime', atTime);
    //             localStorage.setItem('twoAtTime', atTime);
    //             let navigateBackTo = location.state?.from || "/";
    //             navigate(navigateBackTo);
    //         } catch (error) {
    //             if (error.response && error.response.data === "NOT_JOINED") {
    //                 // 가입되어 있지 않으면 네이버 정보제공동의 창 띄우고 다시 naverLogin()함수 실행
    //                 const clientId = "id";
    //                 const redirectUri = "https://btorage.com/NaverLoginLoading";
    //                 const state = generateRandomString(16);
    //                 const naverAgreePopup = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&state=${state}`;
    //                 window.open(naverAgreePopup, "NaverLogin", "width=600, height=800");
    //             } else {
    //                 naverLoginErrorTrue();
    //             }
    //         }
    //     };
    //     fetchData();
    // }, []);







    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
        }}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '20px',
                color: '#fff',
            }}>
                <div style={{
                    border: '4px solid rgba(255, 255, 255, 0.3)',
                    borderRadius: '50%',
                    borderTop: '4px solid #fff',
                    width: '50px',
                    height: '50px',
                    marginRight: '10px',
                    animation: 'spin 2s linear infinite',
                }}></div>
            </div>


            { naverLoginErrorMsg &&
                <MsgCheckModal naverLoginErrorMsg={naverLoginErrorMsg} naverLoginErrorClose={naverLoginErrorClose}/>
            }
        </div>
    );
}

export default NaverLoginLoading;
