import React, {useState} from 'react';
import maCateImg from "../../assets/img/m-a-cate.png";

function AdminBoardPg() {

    /** region DataTable */

    const [boardData, setBoardData] = useState([]);

    /** endregion */

    /** region Function */

    /** endregion */

    /** region Event */

    /** endregion */
    
    return (
        <div className="AdminBoardPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">게시판설정</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card">
                    내용
                </div>
            </div>
        </div>
    );
}

export default AdminBoardPg
