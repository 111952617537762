import React, {useEffect, useRef, useState} from "react";
import withAuth from "../../auth/UserRouteAuth";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import mainNotice from "../../assets/img/mainNotice.png";
import {useTranslation} from "react-i18next";
import {connect} from "../../services/WebsocketService";
import PaypalModal from "../../components/web/modal/PaypalModal";
import commomHeader from "../../assets/img/commonHeader.png";
import {useWebSocket} from "../../services/WebsocketContext";

function UserDepChargPg() {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    const [memberInfo, setMemberInfo] = useState("");
    const findMember = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                setMemberInfo(response.data)
            })
            .catch(() => {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (SSAT || LCAT) {
            findMember();
        }
    }, [SSAT, LCAT])

    const stompClientRef = useRef(null);
    const { subscribe, unsubscribe, websocketConnect, isConnected, stompClient } = useWebSocket();
    useEffect(() => {
        if (!memberInfo && !memberInfo.mbNum) return;
        const initializeSubscription = async () => {
            if (!isConnected || !stompClient) {
                stompClientRef.current = await websocketConnect(memberInfo.mbNum);
            } else {
                stompClientRef.current = stompClient;
            }
        };
        initializeSubscription();
        return () => {
            if (stompClientRef.current && stompClientRef.current.length > 0) {
                // 구독이 존재하는 경우에만 해제
                stompClientRef.current.forEach((sub) => {
                    unsubscribe(sub);
                });
            }
            stompClientRef.current = null;
        };
    }, [memberInfo, isConnected, subscribe, unsubscribe, stompClient]);



    const [submitBtDisabled, setSubmitBtDisabled] = useState(false);

    const [nobankTab, setNobankTab] = useState(true);
    const [paypalTab, setPaypalTab] = useState(false);
    const [yuanTab, setYuanTab] = useState(false);

    const [nobankChargTab, setNobankChargTab] = useState(true);
    const [nobankRefundTab, setNobankRefundTab] = useState(false);
    const [paypalChargTab, setPaypalChargTab] = useState(true);
    const [paypalRefundTab, setPaypalRefundTab] = useState(false);
    const [yuanChargTab, setYuanChargTab] = useState(true);
    const [yuanRefundTab, setYuanRefundTab] = useState(false);

    const handleMainTab = ((tab) => {
        if (tab === "nobank") {
            setNobankTab(true);
            setPaypalTab(false);
            setYuanTab(false);
        } else if (tab === "paypal") {
            setSelectedDollar("USD")
            setNobankTab(false);
            setPaypalTab(true);
            setYuanTab(false);
        } else if (tab === 'yuan') {
            setSelectedDollar("CNY")
            setNobankTab(false);
            setPaypalTab(false);
            setYuanTab(true);
        }
    })

    const handleNobankAssiTab = ((tab) => {
        if (tab === "charg") {
            setNobankChargTab(true);
            setNobankRefundTab(false);
        } else {
            setNobankChargTab(false);
            setNobankRefundTab(true);
        }
    })

    const handlePaypalAssiTab = ((tab) => {
        if (tab === "charg") {
            setPaypalChargTab(true);
            setPaypalRefundTab(false);
        } else {
            setPaypalChargTab(false);
            setPaypalRefundTab(true);
        }
    })

    const handleYuanAssiTab = ((tab) => {
        if (tab === "charg") {
            setYuanChargTab(true);
            setYuanRefundTab(false);
        } else {
            setYuanChargTab(false);
            setYuanRefundTab(true);
        }
    })

    const btorageAccount = t(`dcMdMsg`);

    // 페이팔통화 셀렉트박스
    const dollarList = ['USD', 'CAD', 'AUD', 'NZD', 'EUR', 'JPY', 'SGD']
    const [selectedDollar, setSelectedDollar] = useState('USD');


    const [dollarShow, setDollarShow] = useState(false);
    const dollarRef = useRef(null);

    const toggleDollarShow = () => {
        setDollarShow(!dollarShow);
    };

    const selectChangeDollar = (e) => {
        setSelectedDollar(e);
        setDollarShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (dollarRef.current && !dollarRef.current.contains(event.target)) {
                setDollarShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getTodayFormatted = () => {
        let date = new Date();
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let day = String(date.getDate()).padStart(2, '0');
        setNobankChargSendDate(`${year}${month}${day}`);
        setYuanChargSendDate(`${year}${month}${day}`);
    };

    useEffect(() => {
        getTodayFormatted();
    }, []);

    const formatStringWithCommas = ((numberString) => {
        const numericString = numberString.replace(/[^\d]/g, '');
        return numericString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    })

    // 무통장충전
    const [nobankChargPrice, setNobankChargPrice] = useState('');
    const [nobankChargSendNm, setNobankChargSendNm] = useState('');
    const [nobankChargSendDate, setNobankChargSendDate] = useState('');

    const handleNobankChargPrice = ((e) => {
        const {value} = e.target;
        const formattedValue = formatStringWithCommas(value);
        setNobankChargPrice(formattedValue);
    })
    const handleNobankChargSendNm = ((e) => {
        setNobankChargSendNm(e.target.value);
    })
    const handleNobankChargSendDate = ((e) => {
        setNobankChargSendDate(e.target.value);
    })

    const saveNobankCharg = (() => {
        if (submitBtDisabled) return;
        try {
            axios({
                method: 'post',
                url: '/member/saveDepConfirmInfo',
                data: {
                    userNm: memberInfo.userName,
                    userId: memberInfo.id,
                    name: nobankChargSendNm,
                    account: btorageAccount,
                    price: Number(nobankChargPrice.replace(/,/g, "")),
                    chargFl: true,
                    sendDate: nobankChargSendDate,
                    status: '0',
                    autoPayFl: false
                },
            })
                .then(async (response) => {
                    saveDepositAlarmData(response.data);
                    navigate("/UserDepChargHistoryPg")
                })
                .catch(() => {
                    console.log("무통장충전 실패")
                    setSubmitBtDisabled(false);
                })
        } catch (error) {
            setSubmitBtDisabled(false);
        } finally {
            setSubmitBtDisabled(false);
        }
    })

    const nobankChargInit = (() => {
        setNobankChargPrice('');
        setNobankChargSendNm('');
        setNobankChargSendDate('');
    })


    // 무통장환급
    const [nobankRefundPrice, setNobankRefundPrice] = useState('');
    const [nobankRefundNm, setNobankRefundNm] = useState('');
    const [nobankRefundBankNm, setNobankRefundBankNm] = useState('');
    const [nobankRefundAccount, setNobankRefundAccount] = useState('');

    const handleNobankRefundPrice = ((e) => {
        const {value} = e.target;
        const formattedValue = formatStringWithCommas(value);
        setNobankRefundPrice(formattedValue);
    })
    const handleNobankRefundNm = ((e) => {
        setNobankRefundNm(e.target.value);
    })
    const handleNobankRefundBankNm = ((e) => {
        setNobankRefundBankNm(e.target.value);
    })
    const handleNobankRefundAccount = ((e) => {
        setNobankRefundAccount(e.target.value);
    })

    const saveNobankRefund = (() => {
        if (submitBtDisabled) return;
        try {
            axios({
                method: 'post',
                url: '/member/saveDepConfirmInfo',
                data: {
                    userNm: memberInfo.userName,
                    userId: memberInfo.id,
                    name: nobankRefundNm,
                    account: nobankRefundBankNm + nobankRefundAccount,
                    price: Number(nobankRefundPrice.replace(/,/g, "")),
                    chargFl: false,
                    status: '0',
                    autoPayFl: false
                },
            })
                .then(async (response) => {
                    saveDepositAlarmData(response.data);
                    navigate("/UserDepChargHistoryPg")
                })
                .catch(() => {
                    console.log(" 실패")
                    setSubmitBtDisabled(false);
                })
        } catch (error) {
            setSubmitBtDisabled(false);
        } finally {
            setSubmitBtDisabled(false);
        }
    })

    const nobankRefundInit = (() => {
        setNobankRefundPrice('');
        handleNobankRefundNm('');
        handleNobankRefundBankNm('');
        handleNobankRefundAccount('');
    })

    const formatNumber = (value) => {
        // 숫자 외의 모든 문자를 제거
        const number = value.replace(/[^\d]/g, "");
        // 세자리 수마다 콤마를 찍어서 반환
        return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // 페이팔충전
    const [paypalChargPrice, setPaypalChargPrice] = useState('');

    const handlePaypalChargPrice = ((e) => {
        const formattedValue = formatNumber(e.target.value);
        setPaypalChargPrice(formattedValue);
    })

    const openPaypalPopup = (() => {
        setPaypalPopupSt(true);
    })

    const savePaypalCharg = (() => {
            axios({
                method: 'post',
                url: '/member/saveDepConfirmInfo',
                data: {
                    userNm: memberInfo.userName,
                    userId: memberInfo.id,
                    name: memberInfo.userName,
                    account: 'Paypal Btorage',
                    price: Number(paypalChargPrice.replace(/,/g, "")),
                    chargFl: true,
                    sendDate: nobankChargSendDate,
                    status: '1', // API response.ok면 '1' 그대로 완료
                    currency: selectedDollar,
                    dollarPrice: paypalChargExPrice,
                    autoPayFl: true
                },
            })
                .then(async (response) => {
                    setPaypalPopupSt(false);
                    saveDepositAlarmData(response.data);
                    navigate("/UserDepChargHistoryPg")
                })
                .catch((error) => {
                    console.log("페이팔충전 실패 : ", error)
                    setSubmitBtDisabled(false);
                })
    })

    const paypalChargInit = (() => {
        setPaypalChargPrice('');
        setSelectedDollar('');
        setPaypalChargExPrice('');
        setPaypalChargExPrice(0);
    })


    // 페이팔환급
    const [paypalRefundPrice, setPaypalRefundPrice] = useState('');
    const [paypalRefundEmail, setPaypalRefundEmail] = useState('');

    const handlePaypalRefundPrice = ((e) => {
        const formattedValue = formatNumber(e.target.value);
        setPaypalRefundPrice(formattedValue);
    })
    const handlePaypalRefundEmail = ((e) => {
        setPaypalRefundEmail(e.target.value);
    })

    const savePaypalRefund = (() => {
        if (submitBtDisabled) return;
        try {
            axios({
                method: 'post',
                url: '/member/saveDepConfirmInfo',
                data: {
                    userNm: memberInfo.userName,
                    userId: memberInfo.id,
                    name: '',
                    account: paypalRefundEmail,
                    price: Number(paypalRefundPrice.replace(/,/g, "")),
                    chargFl: false,
                    sendDate: '',
                    status: '0',
                    currency: selectedDollar,
                    dollarPrice: paypalRefundExPrice,
                    autoPayFl: false
                },
            })
                .then(async (response) => {
                    saveDepositAlarmData(response.data);
                    navigate("/UserDepChargHistoryPg")
                })
                .catch(() => {
                    console.log(" 실패")
                    setSubmitBtDisabled(false);
                })
        } catch (error) {
            setSubmitBtDisabled(false);
        } finally {
            setSubmitBtDisabled(false);
        }
    })

    const paypalRefundInit = (() => {
        setPaypalRefundPrice('');
        setPaypalRefundExPrice('');
        setPaypalRefundEmail('');
    })

    // 위안충전
    const [yuanChargPrice, setYuanChargPrice] = useState('');
    const [yuanChargSendId, setYuanChargSendId] = useState('');
    const [yuanChargSendDate, setYuanChargSendDate] = useState('');

    const handleYuanChargPrice = ((e) => {
        const formattedValue = formatNumber(e.target.value);
        setYuanChargPrice(formattedValue);
    })
    const handleYuanChargSendId = ((e) => {
        setYuanChargSendId(e.target.value);
    })
    const handleYuanChargSendDate = ((e) => {
        setYuanChargSendDate(e.target.value);
    })

    const saveYuanCharg = (() => {
        if (submitBtDisabled) return;
        try {
            axios({
                method: 'post',
                url: '/member/saveDepConfirmInfo',
                data: {
                    userNm: memberInfo.userName,
                    userId: memberInfo.id,
                    name: yuanChargSendId,
                    account: btorageAccount,
                    price: Number(yuanChargPrice.replace(/,/g, "")),
                    chargFl: true,
                    sendDate: yuanChargSendDate,
                    status: '0',
                    currency: "CNY",
                    dollarPrice: yuanChargExPrice,
                    autoPayFl: false
                },
            })
                .then(async (response) => {
                    saveDepositAlarmData(response.data);
                    navigate("/UserDepChargHistoryPg")
                })
                .catch(() => {
                    console.log(" 실패")
                })
        } catch (error) {
            setSubmitBtDisabled(false);
        } finally {
            setSubmitBtDisabled(false);
        }
    })

    const yuanChargInit = (() => {
        setYuanChargPrice('');
        setYuanChargSendId('');
        setYuanChargSendDate('');
    })

    // 위안환급
    const [yuanRefundPrice, setYuanRefundPrice] = useState('');
    const [yuanRefundId, setYuanRefundId] = useState('');

    const handleYuanRefundPrice = ((e) => {
        const formattedValue = formatNumber(e.target.value);
        setYuanRefundPrice(formattedValue);
    })
    const handleYuanRefundId = ((e) => {
        setYuanRefundId(e.target.value);
    })

    const saveYuanRefund = (() => {
        if (submitBtDisabled) return;
        try {
            axios({
                method: 'post',
                url: '/member/saveDepConfirmInfo',
                data: {
                    userNm: memberInfo.userName,
                    userId: memberInfo.id,
                    name: '',
                    account: yuanRefundId,
                    price: Number(yuanRefundPrice.replace(/,/g, "")),
                    chargFl: false,
                    sendDate: '',
                    status: '0',
                    currency: "CNY",
                    dollarPrice: yuanRefundExPrice,
                    autoPayFl: false
                },
            })
                .then(async (response) => {
                    saveDepositAlarmData(response.data);
                    navigate("/UserDepChargHistoryPg")
                })
                .catch(() => {
                    console.log(" 실패")
                    setSubmitBtDisabled(false);
                })
        } catch (error) {
            setSubmitBtDisabled(false);
        } finally {
            setSubmitBtDisabled(false);
        }
    })

    const yuanRefundInit = (() => {
        setYuanRefundPrice('');
        setYuanRefundId('');
    })


    const saveDepositAlarmData = ((depositData) => {
        axios({
            method: 'post',
            url: '/order/saveDepositData',
            headers: {
                Authorization: LCAT ? LCAT : SSAT,
                'Content-Type': 'application/json',
            },
            data: depositData
        })
            .then(async () => {
                await stompClient.send("/app/depConfirmAlarm", {}, JSON.stringify({sendFl: true}));
            })
            .catch((error) => {
                console.log("알림데이터 저장실패 : ", error)
            })
    })

    const [paypalPopupSt, setPaypalPopupSt] = useState(false);
    const paypalPopupClose = (() => {
        setPaypalPopupSt(false);
    })

    const [usdExrInfo, setUsdExrInfo] = useState([]);
    const [paypalChargExPrice, setPaypalChargExPrice] = useState(0);
    const [paypalRefundExPrice, setPaypalRefundExPrice] = useState(0);
    const [yuanChargExPrice, setYuanChargExPrice] = useState(0);
    const [yuanRefundExPrice, setYuanRefundExPrice] = useState(0);

    const selectedAllExrInfo = (() => {
        axios({
            method: 'get',
            url: '/paypal/selectedAllExrInfo/',
        })
            .then((response) => {
                setUsdExrInfo(response.data)
            })
            .catch(() => {
                console.log("환율조회실패")
            })
    })

    useEffect(() => {
        selectedAllExrInfo();
    }, [])

    const calculateLastForPrice = () => {
        if (selectedDollar && usdExrInfo.length > 0) {
            const usdExInfo = usdExrInfo.find(info => info.curPrice === selectedDollar);
            let numericFinalPayExpense;

            if (paypalTab && paypalChargTab) {
                numericFinalPayExpense = parseFloat(paypalChargPrice.replace(/,/g, ''));
            } else if (paypalTab && paypalRefundTab) {
                numericFinalPayExpense = parseFloat(paypalRefundPrice.replace(/,/g, ''));
            } else if (yuanTab && yuanChargTab) {
                numericFinalPayExpense = parseFloat(yuanChargPrice.replace(/,/g, ''));
            } else if (yuanTab && yuanRefundTab) {
                numericFinalPayExpense = parseFloat(yuanRefundPrice.replace(/,/g, ''));
            }

            // 금액(50,000) / (환율(1333.14) - 요율(9.1%)) = 41.26usd
            if (!isNaN(numericFinalPayExpense) && usdExInfo) {
                const payRateAsRatio = usdExInfo.payRate / 100;
                const newLastForPrice = numericFinalPayExpense / (usdExInfo.payExr - (usdExInfo.payExr * payRateAsRatio));

                if (paypalTab && paypalChargTab) {
                    setPaypalChargExPrice(Number(newLastForPrice.toFixed(2)));
                } else if (paypalTab && paypalRefundTab) {
                    setPaypalRefundExPrice(Number(newLastForPrice.toFixed(2)));
                } else if (yuanTab && yuanChargTab) {
                    setYuanChargExPrice(Number(newLastForPrice.toFixed(2)));
                } else if (yuanTab && yuanRefundTab) {
                    setYuanRefundExPrice(Number(newLastForPrice.toFixed(2)));
                }
            }
        }
    }

    useEffect(() => {
        calculateLastForPrice();
    }, [selectedDollar, usdExrInfo, paypalChargPrice, paypalRefundPrice, yuanChargPrice, yuanRefundPrice]);

    return (
        <div>
            <div className="display-flex just-cont-center align-center">
                <div className="wd-50pc">
                    <div className="display-flex ht-60 just-cont-sb">
                        <div style={{display: "flex", alignItems: "flex-start", padding: "10px 0"}}>
                            <img className="wd-25 ht-25" src={commomHeader} alt="header"/>
                            <p className="font-s-20 m-l-10 font-t-title font-c-default">{t(`hdSMenu5`)}</p>
                        </div>
                        <p className="font-s-14 font-t-cont m-t-20 c-point" onClick={() => navigate("/UserDepChargHistoryPg")} style={{color: "#97B2E4"}}>{t('dcHistory')}</p>
                    </div>
                    <div className="display-flex just-cont-center font-t-cont font-s-15">
                        <button onClick={() => handleMainTab('nobank')} style={{
                            width: "200px",
                            height: "60px",
                            border: nobankTab ? "solid darkorange 3px" : "solid lightgray 1px",
                            borderRadius: "4px",
                        }}>{t(`dcBt1`)}
                        </button>
                        <button onClick={() => handleMainTab('paypal')} style={{
                            width: "200px",
                            height: "60px",
                            border: paypalTab ? "solid darkorange 3px" : "solid lightgray 1px",
                            borderRadius: "4px",
                            marginLeft: "10px",
                        }}>Paypal
                        </button>
                        <button onClick={() => handleMainTab('yuan')} style={{
                            width: "200px",
                            height: "60px",
                            border: yuanTab ? "solid darkorange 3px" : "solid lightgray 1px",
                            borderRadius: "4px",
                            marginLeft: "10px",
                        }}>Wechat/Alipay
                        </button>
                    </div>
                    <div className="font-t-cont" style={{
                        minHeight: "300px",
                        border: "solid 1px #E2E2E2",
                        marginTop: "20px",
                        padding: "10px 10px 20px 10px"
                    }}>
                        {nobankTab ?
                            <div className="user-center ht-60">
                                <div className="user-center wd-80" onClick={() => handleNobankAssiTab('charg')}>
                                    <p className="user-center c-point wd-50 ht-40 font-s-15"
                                       style={{borderBottom: nobankChargTab ? "solid darkorange" : "none"}}>{t(`st8`)}</p>
                                </div>
                                <div className="user-center wd-80" onClick={() => handleNobankAssiTab('refund')}>
                                    <p className="user-center wd-50 ht-40 font-s-15 c-point"
                                       style={{borderBottom: nobankRefundTab ? "solid darkorange" : "none"}}>{t(`st9`)}</p>
                                </div>
                            </div>
                            :
                            paypalTab ?
                                <div className="user-center ht-60">
                                    <div className="user-center wd-80" onClick={() => handlePaypalAssiTab('charg')}>
                                        <p className="user-center wd-50 ht-40 font-s-15 c-point"
                                           style={{borderBottom: paypalChargTab ? "solid darkorange" : "none"}}>{t(`st8`)}</p>
                                    </div>
                                    <div className="user-center wd-80" onClick={() => handlePaypalAssiTab('refund')}>
                                        <p className="user-center wd-50 ht-40 font-s-15 c-point"
                                           style={{borderBottom: paypalRefundTab ? "solid darkorange" : "none"}}>{t(`st9`)}</p>
                                    </div>
                                </div>
                                :
                                yuanTab ?
                                    <div className="user-center ht-60">
                                        <div className="user-center wd-80" onClick={() => handleYuanAssiTab('charg')}>
                                            <p className="user-center wd-50 ht-40 font-s-15 c-point"
                                               style={{borderBottom: yuanChargTab ? "solid darkorange" : "none"}}>{t(`st8`)}</p>
                                        </div>
                                        <div className="user-center wd-80" onClick={() => handleYuanAssiTab('refund')}>
                                            <p className="user-center wd-50 ht-40 font-s-15 c-point"
                                               style={{borderBottom: yuanRefundTab ? "solid darkorange" : "none"}}>{t(`st9`)}</p>
                                        </div>
                                    </div>
                                    :
                                    null
                        }
                        <div style={{padding: "10px"}}>
                            {/*무통장충전탭*/}
                            {nobankTab && nobankChargTab ?
                                <div>
                                    <div className="user-center">
                                        <div style={{
                                            width: "40%",
                                            backgroundColor: "#FFF9EF",
                                            height: "40px",
                                            display: "flex",
                                            alignItems: "center"
                                        }}>
                                            <p style={{
                                                margin: "0",
                                                fontSize: "13px",
                                                marginLeft: "10px",
                                                color: "#818181"
                                            }}>{t(`myBal`)}</p>
                                        </div>
                                        <div style={{
                                            width: "60%",
                                            height: "40px",
                                            marginLeft: "10px",
                                            display: "flex",
                                            alignItems: "center"
                                        }}>
                                            <p style={{
                                                margin: "0",
                                                fontSize: "13px",
                                                marginLeft: "10px",
                                                color: "#818181"
                                            }}>
                                                {memberInfo && memberInfo?.deposit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="user-center">
                                        <div style={{
                                            width: "40%",
                                            backgroundColor: "#FFF9EF",
                                            height: "40px",
                                            display: "flex",
                                            alignItems: "center"
                                        }}>
                                            <p style={{
                                                margin: "0",
                                                fontSize: "13px",
                                                marginLeft: "10px",
                                                color: "#818181"
                                            }}>{t(`dcDepPr`)}</p>
                                        </div>
                                        <div className="user-input-container"
                                             style={{width: "60%", height: "35px", marginLeft: "10px"}}>
                                            <input onChange={handleNobankChargPrice} value={nobankChargPrice}
                                                   className="ReviewInput user-input"
                                                   style={{
                                                       width: "100%",
                                                       paddingLeft: "10px",
                                                       paddingRight: "10px",
                                                       fontSize: "13px",
                                                   }} maxLength={25} type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="user-center">
                                        <div style={{
                                            width: "40%",
                                            backgroundColor: "#FFF9EF",
                                            height: "40px",
                                            display: "flex",
                                            alignItems: "center"
                                        }}>
                                            <p style={{
                                                margin: "0",
                                                fontSize: "13px",
                                                marginLeft: "10px",
                                                color: "#818181"
                                            }}>{t(`dcSd`)}</p>
                                        </div>
                                        <div className="user-input-container"
                                             style={{width: "60%", height: "35px", marginLeft: "10px"}}>
                                            <input onChange={handleNobankChargSendNm} value={nobankChargSendNm}
                                                   className="ReviewInput user-input"
                                                   style={{
                                                       width: "100%",
                                                       paddingLeft: "10px",
                                                       paddingRight: "10px",
                                                       fontSize: "13px",
                                                   }} maxLength={25} type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="user-center">
                                        <div style={{
                                            width: "40%",
                                            backgroundColor: "#FFF9EF",
                                            height: "40px",
                                            display: "flex",
                                            alignItems: "center"
                                        }}>
                                            <p style={{
                                                margin: "0",
                                                fontSize: "13px",
                                                marginLeft: "10px",
                                                color: "#818181"
                                            }}>{t(`dcSdDate`)}</p>
                                        </div>
                                        <div className="user-input-container"
                                             style={{width: "60%", height: "35px", marginLeft: "10px"}}>
                                            <input onChange={handleNobankChargSendDate} value={nobankChargSendDate}
                                                   className="ReviewInput user-input"
                                                   style={{
                                                       width: "100%",
                                                       paddingLeft: "10px",
                                                       paddingRight: "10px",
                                                       fontSize: "13px",
                                                   }} maxLength={8} type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="user-center" style={{marginTop: "20px"}}>
                                        <button onClick={nobankChargInit} className="admin-btn-long admin-bt-color"
                                                style={{
                                                    width: "80px",
                                                    height: "40px",
                                                    fontSize: "13px",
                                                    margin: "0",
                                                    borderRadius: "4px",
                                                }}>{t(`dcInitBt`)}
                                        </button>
                                        <button onClick={saveNobankCharg} className="orderItemHandleBt" disabled={submitBtDisabled}
                                                style={{
                                                    width: "80px",
                                                    height: "40px",
                                                    fontSize: "13px",
                                                    margin: "0",
                                                    borderRadius: "4px",
                                                    marginLeft: "5px"
                                                }}>{t(`dcDoneBt`)}
                                        </button>
                                    </div>
                                </div>
                                :
                                // 무통장환급탭
                                nobankTab && nobankRefundTab ?
                                    <div>
                                        <div className="user-center">
                                            <div style={{
                                                width: "40%",
                                                backgroundColor: "#FFF9EF",
                                                height: "40px",
                                                display: "flex",
                                                alignItems: "center"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "13px",
                                                    marginLeft: "10px",
                                                    color: "#818181"
                                                }}>{t(`myBal`)}</p>
                                            </div>
                                            <div style={{
                                                width: "60%",
                                                height: "40px",
                                                marginLeft: "10px",
                                                display: "flex",
                                                alignItems: "center"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "13px",
                                                    marginLeft: "10px",
                                                    color: "#818181"
                                                }}>
                                                    {memberInfo && memberInfo?.deposit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="user-center">
                                            <div style={{
                                                width: "40%",
                                                backgroundColor: "#FFF9EF",
                                                height: "40px",
                                                display: "flex",
                                                alignItems: "center"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "13px",
                                                    marginLeft: "10px",
                                                    color: "#818181"
                                                }}>{t(`dcRfPr`)}</p>
                                            </div>
                                            <div className="user-input-container"
                                                 style={{width: "60%", height: "35px", marginLeft: "10px"}}>
                                                <input onChange={handleNobankRefundPrice} value={nobankRefundPrice}
                                                       className="ReviewInput user-input"
                                                       style={{
                                                           width: "100%",
                                                           paddingLeft: "10px",
                                                           paddingRight: "10px",
                                                           fontSize: "13px",
                                                       }} maxLength={25} type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="user-center">
                                            <div style={{
                                                width: "40%",
                                                backgroundColor: "#FFF9EF",
                                                height: "40px",
                                                display: "flex",
                                                alignItems: "center"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "13px",
                                                    marginLeft: "10px",
                                                    color: "#818181"
                                                }}>{t(`modNbInfo3`)}</p>
                                            </div>
                                            <div className="user-input-container"
                                                 style={{width: "60%", height: "35px", marginLeft: "10px"}}>
                                                <input onChange={handleNobankRefundNm} value={nobankRefundNm}
                                                       className="ReviewInput user-input"
                                                       style={{
                                                           width: "100%",
                                                           paddingLeft: "10px",
                                                           paddingRight: "10px",
                                                           fontSize: "13px",
                                                       }} maxLength={25} type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="user-center">
                                            <div style={{
                                                width: "40%",
                                                backgroundColor: "#FFF9EF",
                                                height: "40px",
                                                display: "flex",
                                                alignItems: "center"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "13px",
                                                    marginLeft: "10px",
                                                    color: "#818181"
                                                }}>{t(`dcBkNm`)}</p>
                                            </div>
                                            <div className="user-input-container"
                                                 style={{width: "60%", height: "35px", marginLeft: "10px"}}>
                                                <input onChange={handleNobankRefundBankNm} value={nobankRefundBankNm}
                                                       className="ReviewInput user-input"
                                                       style={{
                                                           width: "100%",
                                                           paddingLeft: "10px",
                                                           paddingRight: "10px",
                                                           fontSize: "13px",
                                                       }} maxLength={25} type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="user-center">
                                            <div style={{
                                                width: "40%",
                                                backgroundColor: "#FFF9EF",
                                                height: "40px",
                                                display: "flex",
                                                alignItems: "center"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "13px",
                                                    marginLeft: "10px",
                                                    color: "#818181"
                                                }}>{t(`dcAcctNum`)}</p>
                                            </div>
                                            <div className="user-input-container"
                                                 style={{width: "60%", height: "35px", marginLeft: "10px"}}>
                                                <input onChange={handleNobankRefundAccount} value={nobankRefundAccount}
                                                       className="ReviewInput user-input"
                                                       style={{
                                                           width: "100%",
                                                           paddingLeft: "10px",
                                                           paddingRight: "10px",
                                                           fontSize: "13px",
                                                       }} maxLength={25} type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="user-center" style={{marginTop: "20px"}}>
                                            <button onClick={nobankRefundInit} className="admin-btn-long admin-bt-color"
                                                    style={{
                                                        width: "80px",
                                                        height: "40px",
                                                        fontSize: "13px",
                                                        margin: "0",
                                                        borderRadius: "4px",
                                                    }}>{t(`dcInitBt`)}
                                            </button>
                                            <button onClick={saveNobankRefund} disabled={submitBtDisabled} className="orderItemHandleBt"
                                                    style={{
                                                        width: "80px",
                                                        height: "40px",
                                                        fontSize: "13px",
                                                        margin: "0",
                                                        borderRadius: "4px",
                                                        marginLeft: "5px"
                                                    }}>{t(`dcDoneBt`)}
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    // 페이팔충전탭
                                    paypalTab && paypalChargTab ?
                                        <div>
                                            <div className="user-center">
                                                <div style={{
                                                    width: "40%",
                                                    backgroundColor: "#FFF9EF",
                                                    height: "40px",
                                                    display: "flex",
                                                    alignItems: "center"
                                                }}>
                                                    <p style={{
                                                        margin: "0",
                                                        fontSize: "13px",
                                                        marginLeft: "10px",
                                                        color: "#818181"
                                                    }}>{t(`myBal`)}</p>
                                                </div>
                                                <div style={{
                                                    width: "60%",
                                                    height: "40px",
                                                    marginLeft: "10px",
                                                    display: "flex",
                                                    alignItems: "center"
                                                }}>
                                                    <p style={{
                                                        margin: "0",
                                                        fontSize: "13px",
                                                        marginLeft: "10px",
                                                        color: "#818181"
                                                    }}>
                                                        {memberInfo && memberInfo?.deposit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="user-center">
                                                <div style={{
                                                    width: "40%",
                                                    backgroundColor: "#FFF9EF",
                                                    height: "40px",
                                                    display: "flex",
                                                    alignItems: "center"
                                                }}>
                                                    <p style={{
                                                        margin: "0",
                                                        fontSize: "13px",
                                                        marginLeft: "10px",
                                                        color: "#818181"
                                                    }}>{t(`dcDepPr`)}</p>
                                                </div>
                                                <div className="user-input-container"
                                                     style={{width: "60%", height: "35px", marginLeft: "10px"}}>
                                                    <input onChange={handlePaypalChargPrice} value={paypalChargPrice}
                                                           className="ReviewInput user-input"
                                                           style={{
                                                               width: "100%",
                                                               paddingLeft: "10px",
                                                               paddingRight: "10px",
                                                               fontSize: "13px",
                                                           }} maxLength={25} type="text"
                                                    />
                                                </div>
                                            </div>
                                            <div className="user-center">
                                                <div style={{
                                                    width: "40%",
                                                    backgroundColor: "#FFF9EF",
                                                    height: "40px",
                                                    display: "flex",
                                                    alignItems: "center"
                                                }}>
                                                    <p style={{
                                                        margin: "0",
                                                        fontSize: "13px",
                                                        marginLeft: "10px",
                                                        color: "#818181"
                                                    }}>{t(`pmHeader2`)}</p>

                                                    {/*페이팔통화 셀렉트*/}
                                                    <div ref={dollarRef} style={{
                                                        height: "30px",
                                                        width: "50px",
                                                        position: "relative",
                                                        marginLeft: "10px"
                                                    }}>
                                                        <button className="selectParentBt"
                                                                style={{width: "100%", height: "100%"}}
                                                                onClick={toggleDollarShow}>
                                                            <p style={{
                                                                margin: "0",
                                                                fontSize: "12px"
                                                            }}>{selectedDollar}</p>
                                                        </button>
                                                        {dollarShow ?
                                                            <div style={{
                                                                width: "100%",
                                                                border: "solid",
                                                                borderWidth: "thin 1px",
                                                                borderColor: "lightgray",
                                                                zIndex: 1,
                                                                marginTop: "5px",
                                                                overflowY: "auto",
                                                            }}>
                                                                {dollarList.map((dollar, index) => (
                                                                    <div className="selectChildBt" key={index}
                                                                         onClick={() => selectChangeDollar(dollar)}
                                                                         style={{
                                                                             display: "flex",
                                                                             padding: "10px",
                                                                             justifyContent: "center",
                                                                             alignItems: "center",
                                                                             cursor: "pointer",
                                                                             fontSize: "12px",
                                                                             borderRadius: "4px",
                                                                             zIndex: 99,
                                                                         }}>{dollar}</div>
                                                                ))}
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="user-input-container"
                                                     style={{width: "60%", height: "35px", marginLeft: "10px"}}>
                                                    <div style={{
                                                        width: "60%",
                                                        height: "100%",
                                                        marginLeft: "10px",
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}>
                                                        <p style={{
                                                            margin: "0",
                                                            fontSize: "13px",
                                                            color: "darkorange",
                                                            fontWeight: "bold"
                                                        }}>
                                                            {Number(paypalChargPrice.replace(/,/g, "")) > 0 ? paypalChargExPrice : null}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{padding: "10px 0"}}>
                                                <div style={{height: "40px", display: "flex", alignItems: "center"}}>
                                                    <img style={{width: "20px", height: "20px"}} src={mainNotice}/>
                                                    <p style={{margin: 0, marginLeft: "8px"}}>{t('dcTx4')}</p>
                                                </div>

                                                <div>
                                                    <span style={{
                                                        fontSize: "12px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        height: "25px"
                                                    }}>- {t(`dcTx1`)}
                                                        <p style={{
                                                            margin: "0 3px",
                                                            color: "darkorange",
                                                            fontWeight: "bold",
                                                            cursor: "pointer",
                                                            fontSize: "12px"
                                                        }}>[{t(`dcTx2`)}]</p>
                                                        <p style={{
                                                            margin: "0",
                                                            fontWeight: "lighter",
                                                            fontSize: "12px",
                                                            color: "black"
                                                        }}>{t(`dcTx3`)}</p>
                                                    </span>
                                                    <p style={{
                                                        margin: "0",
                                                        fontWeight: "lighter",
                                                        fontSize: "12px",
                                                        color: "black"
                                                    }}>- {t(`dcTx6`)}</p>
                                                </div>
                                            </div>
                                            <div className="user-center" style={{marginTop: "20px"}}>
                                                <button onClick={paypalChargInit}
                                                        className="admin-btn-long admin-bt-color"
                                                        style={{
                                                            width: "80px",
                                                            height: "40px",
                                                            fontSize: "13px",
                                                            margin: "0",
                                                            borderRadius: "4px",
                                                        }}>{t(`dcInitBt`)}
                                                </button>
                                                <button onClick={openPaypalPopup} className="orderItemHandleBt"
                                                        style={{
                                                            width: "80px",
                                                            height: "40px",
                                                            fontSize: "13px",
                                                            margin: "0",
                                                            borderRadius: "4px",
                                                            marginLeft: "5px"
                                                        }}>{t(`dcPayBt`)}
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        // 페이팔환급탭
                                        paypalTab && paypalRefundTab ?
                                            <div>
                                                <div className="user-center">
                                                    <div style={{
                                                        width: "40%",
                                                        backgroundColor: "#FFF9EF",
                                                        height: "40px",
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}>
                                                        <p style={{
                                                            margin: "0",
                                                            fontSize: "13px",
                                                            marginLeft: "10px",
                                                            color: "#818181"
                                                        }}>{t(`myBal`)}</p>
                                                    </div>
                                                    <div style={{
                                                        width: "60%",
                                                        height: "40px",
                                                        marginLeft: "10px",
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}>
                                                        <p style={{
                                                            margin: "0",
                                                            fontSize: "13px",
                                                            marginLeft: "10px",
                                                            color: "#818181"
                                                        }}>
                                                            {memberInfo && memberInfo?.deposit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="user-center">
                                                    <div style={{
                                                        width: "40%",
                                                        backgroundColor: "#FFF9EF",
                                                        height: "40px",
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}>
                                                        <p style={{
                                                            margin: "0",
                                                            fontSize: "13px",
                                                            marginLeft: "10px",
                                                            color: "#818181"
                                                        }}>{t(`dcRfPr`)}</p>
                                                    </div>
                                                    <div className="user-input-container"
                                                         style={{width: "60%", height: "35px", marginLeft: "10px"}}>
                                                        <input onChange={handlePaypalRefundPrice}
                                                               value={paypalRefundPrice}
                                                               className="ReviewInput user-input"
                                                               style={{
                                                                   width: "100%",
                                                                   paddingLeft: "10px",
                                                                   paddingRight: "10px",
                                                                   fontSize: "13px",
                                                               }} maxLength={25} type="text"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="user-center">
                                                    <div style={{
                                                        width: "40%",
                                                        backgroundColor: "#FFF9EF",
                                                        height: "40px",
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}>
                                                        <p style={{
                                                            margin: "0",
                                                            fontSize: "13px",
                                                            marginLeft: "10px",
                                                            color: "#818181"
                                                        }}>{t(`pmHeader2`)}</p>

                                                        {/*페이팔통화 셀렉트*/}
                                                        <div ref={dollarRef} style={{
                                                            height: "30px",
                                                            width: "50px",
                                                            position: "relative",
                                                            marginLeft: "10px"
                                                        }}>
                                                            <button className="selectParentBt"
                                                                    style={{width: "100%", height: "100%"}}
                                                                    onClick={toggleDollarShow}>
                                                                <p style={{
                                                                    margin: "0",
                                                                    fontSize: "12px"
                                                                }}>{selectedDollar}</p>
                                                            </button>
                                                            {dollarShow ?
                                                                <div style={{
                                                                    width: "100%",
                                                                    border: "solid",
                                                                    borderWidth: "thin 1px",
                                                                    borderColor: "lightgray",
                                                                    zIndex: 1,
                                                                    marginTop: "5px",
                                                                    overflowY: "auto",
                                                                }}>
                                                                    {dollarList.map((dollar, index) => (
                                                                        <div className="selectChildBt" key={index}
                                                                             onClick={() => selectChangeDollar(dollar)}
                                                                             style={{
                                                                                 display: "flex",
                                                                                 padding: "10px",
                                                                                 justifyContent: "center",
                                                                                 alignItems: "center",
                                                                                 cursor: "pointer",
                                                                                 fontSize: "12px",
                                                                                 borderRadius: "4px",
                                                                                 zIndex: 99,
                                                                             }}>{dollar}</div>
                                                                    ))}
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="user-input-container"
                                                         style={{width: "60%", height: "35px", marginLeft: "10px"}}>
                                                        <div style={{
                                                            width: "60%",
                                                            height: "100%",
                                                            marginLeft: "10px",
                                                            display: "flex",
                                                            alignItems: "center"
                                                        }}>
                                                            <p style={{
                                                                margin: "0",
                                                                fontSize: "13px",
                                                                color: "darkorange",
                                                                fontWeight: "bold"
                                                            }}>
                                                                {Number(paypalRefundPrice.replace(/,/g, "")) > 0 ? paypalRefundExPrice : null}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="user-center">
                                                    <div style={{
                                                        width: "40%",
                                                        backgroundColor: "#FFF9EF",
                                                        height: "40px",
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}>
                                                        <p style={{
                                                            margin: "0",
                                                            fontSize: "13px",
                                                            marginLeft: "10px",
                                                            color: "#818181"
                                                        }}>{t(`dcPpMail`)}</p>
                                                    </div>
                                                    <div className="user-input-container"
                                                         style={{width: "60%", height: "35px", marginLeft: "10px"}}>
                                                        <input className="ReviewInput user-input"
                                                               onChange={handlePaypalRefundEmail}
                                                               value={paypalRefundEmail}
                                                               style={{
                                                                   width: "100%",
                                                                   paddingLeft: "10px",
                                                                   paddingRight: "10px",
                                                                   fontSize: "13px",
                                                               }} maxLength={50} type="text"
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{padding: "10px 0"}}>
                                                    <div
                                                        style={{height: "40px", display: "flex", alignItems: "center"}}>
                                                        <img style={{width: "20px", height: "20px"}} src={mainNotice} alt=""/>
                                                        <p style={{margin: 0, marginLeft: "8px"}}>{t(`dcTx4`)}</p>
                                                    </div>

                                                    <div style={{fontSize: "12px", display: "block"}}>
                                                        <p style={{
                                                            margin: "0",
                                                            fontWeight: "lighter"
                                                        }}>- {t(`dcTx5`)}</p>
                                                        <p style={{
                                                            margin: "0",
                                                            fontWeight: "lighter"
                                                        }}>- {t(`dcTx6`)}</p>
                                                    </div>
                                                </div>
                                                <div className="user-center" style={{marginTop: "20px"}}>
                                                    <button onClick={paypalRefundInit}
                                                            className="admin-btn-long admin-bt-color"
                                                            style={{
                                                                width: "80px",
                                                                height: "40px",
                                                                fontSize: "13px",
                                                                margin: "0",
                                                                borderRadius: "4px",
                                                            }}>{t(`dcInitBt`)}
                                                    </button>
                                                    <button onClick={savePaypalRefund} disabled={submitBtDisabled} className="orderItemHandleBt"
                                                            style={{
                                                                width: "80px",
                                                                height: "40px",
                                                                fontSize: "13px",
                                                                margin: "0",
                                                                borderRadius: "4px",
                                                                marginLeft: "5px"
                                                            }}>{t(`dcDoneBt`)}
                                                    </button>
                                                </div>
                                            </div>
                                            :
                                            // 위챗충전탭
                                            yuanTab && yuanChargTab ?
                                                <div>
                                                    <div className="user-center">
                                                        <div style={{
                                                            width: "40%",
                                                            backgroundColor: "#FFF9EF",
                                                            height: "40px",
                                                            display: "flex",
                                                            alignItems: "center"
                                                        }}>
                                                            <p style={{
                                                                margin: "0",
                                                                fontSize: "13px",
                                                                marginLeft: "10px",
                                                                color: "#818181"
                                                            }}>{t(`myBal`)}</p>
                                                        </div>
                                                        <div style={{
                                                            width: "60%",
                                                            height: "40px",
                                                            marginLeft: "10px",
                                                            display: "flex",
                                                            alignItems: "center"
                                                        }}>
                                                            <p style={{
                                                                margin: "0",
                                                                fontSize: "13px",
                                                                marginLeft: "10px",
                                                                color: "#818181"
                                                            }}>
                                                                {memberInfo && memberInfo?.deposit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="user-center">
                                                        <div style={{
                                                            width: "40%",
                                                            backgroundColor: "#FFF9EF",
                                                            height: "40px",
                                                            display: "flex",
                                                            alignItems: "center"
                                                        }}>
                                                            <p style={{
                                                                margin: "0",
                                                                fontSize: "13px",
                                                                marginLeft: "10px",
                                                                color: "#818181"
                                                            }}>{t(`dcDepPr`)}</p>
                                                        </div>
                                                        <div className="user-input-container"
                                                             style={{width: "60%", height: "35px", marginLeft: "10px"}}>
                                                            <input onChange={handleYuanChargPrice}
                                                                   value={yuanChargPrice}
                                                                   className="ReviewInput user-input"
                                                                   style={{
                                                                       width: "100%",
                                                                       paddingLeft: "10px",
                                                                       paddingRight: "10px",
                                                                       fontSize: "13px",
                                                                   }} maxLength={25} type="text"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="user-center">
                                                        <div style={{
                                                            width: "40%",
                                                            backgroundColor: "#FFF9EF",
                                                            height: "40px",
                                                            display: "flex",
                                                            alignItems: "center"
                                                        }}>
                                                            <p style={{
                                                                margin: "0",
                                                                fontSize: "13px",
                                                                marginLeft: "10px",
                                                                color: "#818181"
                                                            }}>{t(`dcTx7`)}(CNY)</p>
                                                        </div>
                                                        <div className="user-input-container"
                                                             style={{width: "60%", height: "35px", marginLeft: "10px"}}>
                                                            <div style={{
                                                                width: "60%",
                                                                height: "100%",
                                                                marginLeft: "10px",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <p style={{
                                                                    margin: "0",
                                                                    fontSize: "13px",
                                                                    color: "darkorange",
                                                                    fontWeight: "bold"
                                                                }}>
                                                                    {Number(yuanChargPrice.replace(/,/g, "")) > 0 ? yuanChargExPrice : null}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="user-center">
                                                        <div style={{
                                                            width: "40%",
                                                            backgroundColor: "#FFF9EF",
                                                            height: "40px",
                                                            display: "flex",
                                                            alignItems: "center"
                                                        }}>
                                                            <p style={{
                                                                margin: "0",
                                                                fontSize: "13px",
                                                                marginLeft: "10px",
                                                                color: "#818181"
                                                            }}>{t(`dcTx8`)}ID</p>
                                                        </div>
                                                        <div className="user-input-container"
                                                             style={{width: "60%", height: "35px", marginLeft: "10px"}}>
                                                            <input onChange={handleYuanChargSendId}
                                                                   value={yuanChargSendId}
                                                                   className="ReviewInput user-input"
                                                                   style={{
                                                                       width: "100%",
                                                                       paddingLeft: "10px",
                                                                       paddingRight: "10px",
                                                                       fontSize: "13px",
                                                                   }} maxLength={25} type="text"
                                                                   placeholder="ex) Wechat ID"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="user-center">
                                                        <div style={{
                                                            width: "40%",
                                                            backgroundColor: "#FFF9EF",
                                                            height: "40px",
                                                            display: "flex",
                                                            alignItems: "center"
                                                        }}>
                                                            <p style={{
                                                                margin: "0",
                                                                fontSize: "13px",
                                                                marginLeft: "10px",
                                                                color: "#818181"
                                                            }}>{t(`dcTx9`)}</p>
                                                        </div>
                                                        <div className="user-input-container"
                                                             style={{width: "60%", height: "35px", marginLeft: "10px"}}>
                                                            <input onChange={handleYuanChargSendDate}
                                                                   value={yuanChargSendDate}
                                                                   className="ReviewInput user-input"
                                                                   style={{
                                                                       width: "100%",
                                                                       paddingLeft: "10px",
                                                                       paddingRight: "10px",
                                                                       fontSize: "13px",
                                                                   }} maxLength={8} type="text"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style={{padding: "10px 0"}}>
                                                        <div style={{
                                                            height: "40px",
                                                            display: "flex",
                                                            alignItems: "center"
                                                        }}>
                                                            <img style={{width: "20px", height: "20px"}}
                                                                 src={mainNotice}/>
                                                            <p style={{margin: 0, marginLeft: "8px"}}>{t('dcTx4')}</p>
                                                        </div>

                                                        <div style={{fontSize: "12px"}}>
                                                            <span>- {t(`dcTx10`)}<span style={{
                                                                color: "darkorange",
                                                                fontWeight: "bold",
                                                                cursor: "pointer"
                                                            }}> [{t(`dcTx11`)}]</span>{t(`dcTx3`)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="user-center" style={{marginTop: "20px"}}>
                                                        <button onClick={yuanChargInit}
                                                                className="admin-btn-long admin-bt-color"
                                                                style={{
                                                                    width: "80px",
                                                                    height: "40px",
                                                                    fontSize: "13px",
                                                                    margin: "0",
                                                                    borderRadius: "4px",
                                                                }}>{t(`dcInitBt`)}
                                                        </button>
                                                        <button onClick={saveYuanCharg} disabled={submitBtDisabled} className="orderItemHandleBt"
                                                                style={{
                                                                    width: "80px",
                                                                    height: "40px",
                                                                    fontSize: "13px",
                                                                    margin: "0",
                                                                    borderRadius: "4px",
                                                                    marginLeft: "5px"
                                                                }}>{t(`dcDoneBt`)}
                                                        </button>
                                                    </div>
                                                </div>
                                                :
                                                // 위챗환급탭
                                                yuanTab && yuanRefundTab ?
                                                    <div>
                                                        <div className="user-center">
                                                            <div style={{
                                                                width: "40%",
                                                                backgroundColor: "#FFF9EF",
                                                                height: "40px",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <p style={{
                                                                    margin: "0",
                                                                    fontSize: "13px",
                                                                    marginLeft: "10px",
                                                                    color: "#818181"
                                                                }}>{t(`myBal`)}</p>
                                                            </div>
                                                            <div style={{
                                                                width: "60%",
                                                                height: "40px",
                                                                marginLeft: "10px",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <p style={{
                                                                    margin: "0",
                                                                    fontSize: "13px",
                                                                    marginLeft: "10px",
                                                                    color: "#818181"
                                                                }}>
                                                                    {memberInfo && memberInfo?.deposit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="user-center">
                                                            <div style={{
                                                                width: "40%",
                                                                backgroundColor: "#FFF9EF",
                                                                height: "40px",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <p style={{
                                                                    margin: "0",
                                                                    fontSize: "13px",
                                                                    marginLeft: "10px",
                                                                    color: "#818181"
                                                                }}>{t(`dcRfPr`)}</p>
                                                            </div>
                                                            <div className="user-input-container" style={{
                                                                width: "60%",
                                                                height: "35px",
                                                                marginLeft: "10px"
                                                            }}>
                                                                <input onChange={handleYuanRefundPrice}
                                                                       value={yuanRefundPrice}
                                                                       className="ReviewInput user-input"
                                                                       style={{
                                                                           width: "100%",
                                                                           paddingLeft: "10px",
                                                                           paddingRight: "10px",
                                                                           fontSize: "13px",
                                                                       }} maxLength={25} type="text"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="user-center">
                                                            <div style={{
                                                                width: "40%",
                                                                backgroundColor: "#FFF9EF",
                                                                height: "40px",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <p style={{
                                                                    margin: "0",
                                                                    fontSize: "13px",
                                                                    marginLeft: "10px",
                                                                    color: "#818181"
                                                                }}>{t(`dcTx7`)}(cny)</p>
                                                            </div>
                                                            <div className="user-input-container" style={{
                                                                width: "60%",
                                                                height: "35px",
                                                                marginLeft: "10px"
                                                            }}>
                                                                <div style={{
                                                                    width: "70%",
                                                                    height: "100%",
                                                                    marginLeft: "10px",
                                                                    display: "flex",
                                                                    alignItems: "center"
                                                                }}>
                                                                    <p style={{
                                                                        margin: "0",
                                                                        fontSize: "13px",
                                                                        color: "darkorange",
                                                                        fontWeight: "bold"
                                                                    }}>
                                                                        {Number(yuanRefundPrice.replace(/,/g, "")) > 0 ? yuanRefundExPrice : null}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="user-center">
                                                            <div style={{
                                                                width: "40%",
                                                                backgroundColor: "#FFF9EF",
                                                                height: "40px",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <p style={{
                                                                    margin: "0",
                                                                    fontSize: "13px",
                                                                    marginLeft: "10px",
                                                                    color: "#818181"
                                                                }}>{t(`dcTx12`)}ID</p>
                                                            </div>
                                                            <div className="user-input-container" style={{
                                                                width: "60%",
                                                                height: "35px",
                                                                marginLeft: "10px"
                                                            }}>
                                                                <input onChange={handleYuanRefundId}
                                                                       value={yuanRefundId}
                                                                       className="ReviewInput user-input"
                                                                       style={{
                                                                           width: "100%",
                                                                           paddingLeft: "10px",
                                                                           paddingRight: "10px",
                                                                           fontSize: "13px",
                                                                       }} maxLength={25} type="text"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="user-center" style={{marginTop: "20px"}}>
                                                            <button onClick={yuanRefundInit}
                                                                    className="admin-btn-long admin-bt-color"
                                                                    style={{
                                                                        width: "80px",
                                                                        height: "40px",
                                                                        fontSize: "13px",
                                                                        margin: "0",
                                                                        borderRadius: "4px",
                                                                    }}>{t(`dcInitBt`)}
                                                            </button>
                                                            <button onClick={saveYuanRefund} disabled={submitBtDisabled}
                                                                    className="orderItemHandleBt"
                                                                    style={{
                                                                        width: "80px",
                                                                        height: "40px",
                                                                        fontSize: "13px",
                                                                        margin: "0",
                                                                        borderRadius: "4px",
                                                                        marginLeft: "5px"
                                                                    }}>{t(`dcDoneBt`)}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                paypalPopupSt ?
                    <PaypalModal paypalPopupSt={paypalPopupSt} paypalPopupClose={paypalPopupClose}
                                 currency={selectedDollar}
                                 finalPayExpense={paypalChargPrice} lastForPrice={paypalChargExPrice}
                                 paypalOrderProcess={savePaypalCharg}/>
                    :
                    null
            }
        </div>
    )
}

export default withAuth(UserDepChargPg)
