import React from 'react';
import logo from '../../../assets/img/BtorageLogo.png'
import '../../../css/web/CommonLoading.css';

function CommonLoading() {
    return (
        <div className="loading-container">
            <div className="circle-loader">
                <svg className="progress-ring" width="53" height="53">
                    <circle
                        className="progress-ring__circle-bg"
                        stroke="white"
                        strokeWidth="3"
                        fill="transparent"
                        r="20"
                        cx="26.5"
                        cy="26.5"
                        />
                    <circle
                        className="progress-ring__circle"
                        stroke="#FFAF34"
                        strokeWidth="3"
                        fill="transparent"
                        r="20"
                        cx="26.5"
                        cy="26.5"
                    />
                </svg>
                <img src={logo} alt="Loading" className="center-logo" style={{ width: "20px", opacity:"0.8" }} />
            </div>
        </div>
    );
}

export default CommonLoading;
