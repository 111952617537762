import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import profileImage from "../../assets/img/profileDefaultImg.png";
import DOMPurify from "dompurify";
import reviewComment from "../../assets/img/reviewComment.png";
import defaultImg from "../../assets/img/profileDefaultImg.png";
import warnIcon from "../../assets/img/warnIcon.png";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import AdminReviewCommentModifyPg from "../../components/web/modal/AdminReviewCommentModifyPg";
import maCateImg from "../../assets/img/m-a-cate.png";
import {BsEye} from "@react-icons/all-files/bs/BsEye";
import MAdminReviewCommentModifyPg from "../../components/mobile/MAdminReviewCommentModifyPg";

function MAdminReviewDtPg() {

    const {seqNum} = useParams();
    const navigate = useNavigate();
    const LCAT = localStorage.getItem('adminToken');

    const [adminInfo, setAdminInfo] = useState("");
    const findMember = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data)
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (LCAT) {
            findMember();
        }
    }, [LCAT])


    const [reviewData, setReviewData] = useState([]);
    const [reviewProfileImg, setReviewProfileImg] = useState(profileImage)
    const [reviewList, setReviewList] = useState([]);
    const [reviewCommentList, setReviewCommentList] = useState([]);

    const selectReviewData = () =>
        axios({
            method: 'get',
            url: '/review/selectReviewData',
            headers: {
                Authorization: LCAT
            },
            params: {
                seqNum: seqNum
            }
        })
            .then((response) => {
                setReviewData(response.data)
                const isDefaultImg = response && response.data && response.data.memberDTO && response.data.memberDTO.profileDTO && response.data.memberDTO.profileDTO.filename;
                setReviewProfileImg(
                    isDefaultImg ?
                        "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + response.data.memberDTO.profileDTO.filename
                        :
                        profileImage
                );
                setReviewList(response.data.reviewPhotoDTO)
                setReviewCommentList(response.data.reviewCommentDTO)
            })

    useEffect(() => {
        if (seqNum) {
            selectReviewData();
        }
    }, [seqNum])



    const useSanitizedHTML = ((rawHTML) => {
        if (!rawHTML) return "";
        return DOMPurify.sanitize(rawHTML.replace(/\n/g, '<br>'));
    })
    const sanitizedContent = useSanitizedHTML(reviewData?.content);

    const textareaRef = useRef(null);
    const [reviewCommentText, setReviewCommentText] = useState('');

    const handleCommentTextChange = (e) => {
        setReviewCommentText(e.target.value);
        const textarea = textareaRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = Math.min(textarea.scrollHeight, 200) + 'px';
    };

    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = Math.min(textarea.scrollHeight, 200) + 'px';
        }
    }, [reviewCommentText])

    const saveReviewComment = () =>
        axios({
            method: 'post',
            url: '/review/saveReviewComment',
            params: {
                reviewSeqNum: reviewData.seqNum,
                memberMbNum: adminInfo.mbNum,
                content: reviewCommentText
            }
        })
            .then((response) => {
                const newComment = response.data;
                const updatedComments = [...reviewCommentList, newComment];
                setReviewCommentList(updatedComments);
                setReviewCommentText('');
            })
            .catch((response) => {
                console.log("코멘트 등록실패")
            })

    const sanitizeHTML = (rawHTML) => {
        if (!rawHTML) return "";
        return DOMPurify.sanitize(rawHTML.replace(/\n/g, '<br>'));
    };

    const formatDate = (dateStr) => {
        if (dateStr?.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }

    const formatCutDate = (dateStr) => {
        if (dateStr && dateStr.length === 8) {
            const year = dateStr.slice(2, 4);
            const month = dateStr.slice(4, 6);
            const day = dateStr.slice(6, 8);
            return `${year}-${month}-${day}`;
        }
        return dateStr;
    };

    const moveToModify = (() => {
        navigate(`/adminreview-modify/${seqNum}`);
    })

    const [deleteReviewShow, setDeleteReviewShow] = useState(false);
    const deleteReviewShowMsg1 = "이용후기 게시글을 삭제하시겠습니까?";
    const deleteReviewShowMsg2 = "삭제 후 복구할 수 없습니다"
    const closeDeleteReviewShow = (() => {
        setDeleteReviewShow(false);
    })
    const deleteReview = () =>
        axios({
            method: 'post',
            url: '/review/deleteReview',
            params: {
                reviewSeqNum: seqNum
            }
        })
            .then((response) => {
                moveToReview();
            })
            .catch((response) => {
                console.log("코멘트 등록실패")
            })

    const moveToReview = (() => {
        navigate('/btcorpad/adminreview');
    })

    const [deleteReviewCommentShow, setDeleteReviewCommentShow] = useState(false);
    const [deleteReviewCommentSeqNum, setDeleteReviewCommentSeqNum] = useState('');
    const deleteReviewCommentShowMsg1 = "선택하신 댓글을 삭제하시겠습니까?";
    const deleteReviewCommentShowMsg2 = "삭제 후 복구할 수 없습니다"
    const closeDeleteReviewCommentShow = (() => {
        setDeleteReviewCommentShow(false);
    })
    const openDeleteReviewCommentShow = ((commentSeqNum) => {
        setDeleteReviewCommentSeqNum(commentSeqNum);
        setDeleteReviewCommentShow(true);
    })

    const [modifyCommentShow, setModifyCommentShow] = useState(false);
    const [modifyCommentSeqNum, setModifyCommentSeqNum] = useState('');
    const [modifyCommentContent, setModifyCommentContent] = useState('');

    const openModifyCommentShow = ((seqNum, content) => {
        setModifyCommentSeqNum(seqNum);
        setModifyCommentContent(content);
        setModifyCommentShow(true);
    })

    const closeModifyCommentShow = (() => {
        setModifyCommentShow(false);
    })

    const [blockCommentShow, setBlockCommentShow] = useState(false);
    const blockCommentShowMsg1 = "선택하신 댓글을 block 처리하시겠습니까?";
    const blockCommentShowMsg2 = "진행 후 복구할 수 없습니다"

    const openBlockCommentShow = ((seqNum) => {
        setModifyCommentSeqNum(seqNum);
        setBlockCommentShow(true);
    })

    const closeBlockCommentShow = (() => {
        setBlockCommentShow(false);
    })

    const blockComment = (() => {
        const blockMsg = "관리자에 의해 댓글이 삭제되었습니다"
        modifyReviewComment(blockMsg);
    })

    const modifyReviewComment = ((commentContent) => {
        axios({
            method: 'post',
            url: '/review/modifyReviewComment',
            params: {
                commentSeqNum: modifyCommentSeqNum,
                commentContent: commentContent
            }
        })
            .then((response) => {
                const updatedComment = response.data;
                setReviewCommentList(prevComments =>
                    prevComments.map(comment =>
                        comment.seqNum === updatedComment.seqNum ? updatedComment : comment
                    )
                );
                if (modifyCommentShow) {
                    closeModifyCommentShow();
                }
                if (blockCommentShow) {
                    closeBlockCommentShow();
                }
            })
            .catch((response) => {
                console.log("코멘트 수정실패")
            })
    })

    const deleteReviewComment = () =>
        axios({
            method: 'post',
            url: '/review/deleteReviewComment',
            params: {
                commentSeqNum: deleteReviewCommentSeqNum
            }
        })
            .then((response) => {
                const deletedCommentSeqNum = response.data.seqNum;
                setReviewCommentList(prevComments =>
                    prevComments.filter(comment => comment.seqNum !== deletedCommentSeqNum)
                );
                closeDeleteReviewCommentShow();
            })
            .catch((response) => {
                console.log("코멘트 삭제실패")
            })

    return(
        <div id="m-a-reviewDt-pg-container">
            <div className="m-a-title-frame">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">후기상세</p>
            </div>
            <div className="m-a-card">
                <div className="display-flex flex-column wd-100pc">
                    <div className="display-flex flex-row wd-100pc border-c-b p-b-15 m-b-15">
                        <div className="display-flex wd-20pc">
                            <div className="m-a-header-profile-box-b wd-55 ht-55 m-0-a">
                                <img className="m-a-header-profile-box-img m-t-7" src={reviewProfileImg} alt=""/>
                            </div>
                        </div>
                        <div className="display-flex flex-column wd-80pc">
                            <div className="display-flex flex-column wd-100pc p-l-7 font-c-default font-s-14 font-t-cont">
                                <div className="display-flex flex-row just-cont-sb">
                                    <div className="display-flex flex-row">
                                        <p className="m-r-3"><BsEye /></p>
                                        <p className="font-b m-t-1">{reviewData?.views}</p>
                                    </div>
                                    <div>
                                        <p className="font-b font-c-accent">4.8 ★★★★☆(추후)</p>
                                    </div>
                                </div>
                                <div className="display-flex flex-row just-cont-sb">
                                    <p>{reviewData?.memberDTO?.userName}</p>
                                    <p>{formatCutDate(reviewData?.regDate)}</p>
                                </div>
                                <div className="display-flex flex-row just-cont-sb">
                                    <p>[{reviewData?.country}]</p>
                                    <p>[{reviewData?.shipco}]</p>
                                </div>
                            </div>
                            <div className="display-flex flex-row wd-100pc m-t-7">
                                <div className="display-flex flex-row just-cont-fstart wd-70pc p-l-7 font-s-13 font-t-title">
                                    <p>{reviewData?.title}</p>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-30pc font-s-14 font-t-cont">
                                    <p className="font-b font-c-progress-b" onClick={moveToModify}>수정</p>
                                    <p className="font-b font-c-accent" onClick={()=> setDeleteReviewShow(true)}>삭제</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="display-flex flex-column wd-100pc m-b-30">
                        <div className="display-flex flex-wrap">
                            {reviewList.map((photo, index) => (
                                <div className="wd-100pc m-b-15 ht-400" key={index} style={{boxSizing: "border-box"}}>
                                    <img className="wd-100pc ht-100pc" src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/reviewPhoto/" + photo.fileNm} alt="reviewImg"
                                         style={{borderRadius:"4px"}}/>
                                </div>
                            ))}
                        </div>
                        <div className="display-flex just-cont-fstart m-t-15 font-s-14 font-t-cont font-c-default" style={{minHeight: "auto", maxHeight: "500px"}}>
                            <p dangerouslySetInnerHTML={{ __html: sanitizedContent }}></p>
                        </div>
                    </div>
                    <div className="display-flex flex-column wd-100pc">
                        <div className="display-flex flex-row just-cont-fstart font-t-cont m-b-10 font-s-14">
                            <img className="wd-25 ht-25 m-r-5 m-a-opc-07" alt="CommentImage" src={reviewComment}/>
                            <p>Comment</p>
                            <p className="font-c-btorage font-b m-l-5">{reviewCommentList.length}</p>
                        </div>
                        <div className="wd-100pc">
                            {reviewCommentList.map((comment, i) => (
                                <div key={i} className="border-c-t font-t-cont p-t-5 p-b-5 m-t-10">
                                    <div className="display-flex just-cont-sb align-center wd-100pc">
                                        <div className="display-flex align-center wd-65pc">
                                            <img className="wd-35 ht-35" style={{borderRadius:"100%"}}
                                                 src={comment?.memberDTO?.profileDTO?.filename ? "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/"
                                                     + comment?.memberDTO?.profileDTO?.filename : defaultImg} alt="reviewImg"/>
                                            <p className="font-c-default font-s-14 m-l-5">{comment?.memberDTO?.userName}</p>
                                        </div>
                                        <div className="display-flex flex-column wd-35pc">
                                            <div className="display-flex flex-row just-cont-fend gap8">
                                                { comment.memberDTO.role === '0' ?
                                                    <img className="wd-25 wd-25 m-a-opc-07" onClick={() => {openBlockCommentShow(comment.seqNum)}} alt="blockImg" src={warnIcon}/> : null
                                                }
                                                <p className="font-b font-c-progress-b font-s-14" onClick={()=> openModifyCommentShow(comment.seqNum, comment.content)}>수정</p>
                                                <p className="font-b font-c-accent font-s-14" onClick={()=> openDeleteReviewCommentShow(comment.seqNum)}>삭제</p>
                                            </div>
                                            <div className="display-flex flex-row just-cont-fend font-s-13 font-c-default">
                                                <p>{formatCutDate(comment.regDate)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="display-flex align-center p-l-5 m-t-15">
                                        <p className="font-c-default font-t-cont font-s-15" dangerouslySetInnerHTML={{ __html: sanitizeHTML(comment?.content) }}></p>
                                    </div>
                                </div>
                            ))}
                            {LCAT ?
                                <div className="display-flex just-cont-sb wd-100pc m-t-50 gap5">
                                    <div className="m-a-input-frame wd-70pc font-s-13 font-c-default font-t-cont">
                                        <input ref={textareaRef} spellCheck="false" value={reviewCommentText} maxLength={2000}
                                                  className="m-a-input wd-100pc border-c ht-40"
                                                  style={{borderRadius: "6px", resize: "none", overflow: "auto", outline: "none"}}
                                                  onChange={handleCommentTextChange}
                                                  onKeyDown={(e) => {
                                                      if (e.key === 'Enter' && !e.shiftKey) {
                                                          e.preventDefault();
                                                          saveReviewComment();
                                                      }
                                                  }}
                                        />
                                    </div>
                                    <div className="wd-30pc">
                                        <button onClick={saveReviewComment} className="m-a-colorBt m-a-colorBt-text wd-100pc ht-40">등록</button>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
            { deleteReviewShow ?
                <MsgSelectModal deleteReviewShow={deleteReviewShow} deleteReviewShowMsg1={deleteReviewShowMsg1} deleteReviewShowMsg2={deleteReviewShowMsg2}
                                closeDeleteReviewShow={closeDeleteReviewShow} deleteReview={deleteReview}/>
                :
                deleteReviewCommentShow ?
                    <MsgSelectModal deleteReviewCommentShow={deleteReviewCommentShow} deleteReviewCommentShowMsg1={deleteReviewCommentShowMsg1}
                                    deleteReviewCommentShowMsg2={deleteReviewCommentShowMsg2} closeDeleteReviewCommentShow={closeDeleteReviewCommentShow}
                                    deleteReviewComment={deleteReviewComment} deleteReviewCommentSeqNum={deleteReviewCommentSeqNum}/>
                    :
                    blockCommentShow ?
                        <MsgSelectModal blockCommentShow={blockCommentShow} blockCommentShowMsg1={blockCommentShowMsg1}
                                        blockCommentShowMsg2={blockCommentShowMsg2} blockComment={blockComment} closeBlockCommentShow={closeBlockCommentShow}/>
                        :
                        modifyCommentShow ?
                            <MAdminReviewCommentModifyPg modifyCommentContent={modifyCommentContent}
                                                        closeModifyCommentShow={closeModifyCommentShow} modifyReviewComment={modifyReviewComment}/>
                            :
                            null
            }
        </div>
    )
}

export default MAdminReviewDtPg
