import React, {useCallback, useEffect, useState} from 'react';
import {BsSquareFill} from "react-icons/bs";
import axios from "axios";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import {MdKeyboardArrowDown} from "react-icons/md";
import maCateImg from "../../assets/img/m-a-cate.png";

function AdminCustomsItemPg() {

    /** region DataTable */

    const [data, setData] = useState([]);
    const LCAT = localStorage.getItem('adminToken');

    /** endregion */

    /** region Function */

    const [hsCode, sethsCode] = useState("");
    const [hsCodeNm, sethsCodeNm] = useState("");
    const codeNm = (e) => {sethsCodeNm(e.target.value);};
    const code = (e) => {sethsCode(e.target.value);};

    const [selectedRowData, setSelectedRowData] = useState(true);

    function handleRowClick(cData) {
        setSelectedRowData(cData);
    }

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [data.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
            if (data.length > displayLimit && displayLimit < 30) {
                setDisplayLimit(prevLimit => prevLimit + perPg);
            }
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    // region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e.target.value);
        setperPg(newPerPage);
        // setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    // endregion

    // region Alert

    const [alertShow, setAlertShow] = useState(false);
    const [alertShow2, setAlertShow2] = useState(false);
    const msgConfirmData1 = "저장 하시겠습니까?";
    const msgConfirmData2 = "입력한 값을 확인해주세요.";
    const msgConfirmData3 = "정말 삭제하시겠습니까?";
    const msgConfirmData4 = "삭제된 데이터는 복구가 불가능합니다.";
    const Alert = (() => {
        setAlertShow(true)
    })
    const Alert2 = (() => {
        setAlertShow2(true)
    })
    const modalCancel = useCallback(() => {
        setAlertShow(false);
    })
    const modalCancel2 = useCallback(() => {
        setAlertShow2(false);
    })

    // endregion

    // region CheckBox

    const [checked, setChecked] = useState([]);

    const checkBoxChange = (id) => {
        setChecked((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter((item) => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    const allcheckBoxChange = (e) => {
        let visibleData = data.slice(0, displayLimit);
        if (e.target.checked) {
            const visibleIds = visibleData.map((item) => item.seqNum);
            setChecked(visibleIds);
        } else {
            setChecked([]);
        }
    };

    // endregion

    /** endregion */

    /** region Event */

    useEffect(() => {
        select();
    }, [])

    const select = () => {
        axios.get('/admincustomitem/admincustomitemselect')
            .then((response) => {
                setData(response.data)
                setChecked([]);
                sethsCode("");
                sethsCodeNm("");
            }, [])
            .catch()
    }

    const customItemNewSave = () => {
        if (hsCode === "" || hsCodeNm === "") {
            setAlertShow(false);
            return false;
        } else {
            axios.post(
                '/admincustomitem/admincustomitemsave',
                {
                    hsCode: hsCode,
                    hsCodeNm: hsCodeNm,
                },
                {
                    headers: {
                        Authorization: LCAT,
                        'Content-Type': 'application/json'
                    },
                }
            )
                .then(() => {
                    select();
                    setChecked([]);
                    setAlertShow(false);
                })
                .catch(error => {
                    console.error("Error:", error);
                });
        }
    }

    // region Excel

    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const DateViewValue = year + month + day;

    const excelDown = () => {
        try {
            const selectedRows = data.filter((item) => checked.includes(item.seqNum));

            const excel = require('exceljs');
            const workbook = new excel.Workbook();
            workbook.created = new Date();
            workbook.modified = new Date();

            workbook.addWorksheet('Data');

            const sheetOne = workbook.getWorksheet('Data');

            sheetOne.getRow(1).font = {
                name: '맑은 고딕',
                bold: true,
                size: 11
            };
            sheetOne.getRow(1).alignment = {
                horizontal: 'center',
                vertical: 'middle'
            };
            sheetOne.getRow(1).height = 30;
            sheetOne.columns = [
                {header: '번호', key: 'seqNum', hidden:false, height: 15,  width: 7, style: { alignment: { horizontal: 'center' }}},
                {header: '품목명', key: 'hsCodeNm', width: 25, style: { alignment: { horizontal: 'center' }}},
                {header: 'HSCODE', key: 'hsCode', width: 12, style: { alignment: { horizontal: 'center' }}},
                {header: '등록자', key: 'regNm', width: 12, style: { alignment: { horizontal: 'center' }}},
                {header: '등록일시', key: 'regTime', width: 20, style: { alignment: { horizontal: 'center' }}},
            ];
            selectedRows.map((item, index) => {
                sheetOne.addRow(item);
                for(let loop = 1; loop <= 9; loop++) {
                    const col = sheetOne.getRow(index + 2).getCell(loop);
                    col.font = {name: '맑은 고딕', size: 10};
                }
            });

            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = `통관품목정보_` + DateViewValue + `.xlsx`;
                anchor.click();
                window.URL.revokeObjectURL(url);
            })
        } catch(error) {
            console.error(error);
        }
    }

    // endregion

    /** endregion */

    return (
        <div className="AdminCustomsItemPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">통관품목설정</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card m-b-40 font-t-cont">
                    <div className="admin-event-wrap">
                        <div className="admin-btn-wrap-l">
                            <BsSquareFill className="admin-tb-header-notice"/>
                            <a>내용품명 등록</a>
                        </div>
                        <div className="admin-btn-wrap-r">
                            <button className="admin-btn-long admin-bt-color" onClick={Alert} style={{float: "right", marginBottom: "15px"}}>저장</button>
                        </div>
                    </div>
                    <table className="admin-ver-tb">
                        <tbody className="ver-tb-body">
                        {selectedRowData && (
                        <tr>
                            <th><p>품목명</p></th>
                            <td className="font-b text-center">
                                <input defaultValue={selectedRowData.hsCodeNm || ''} onChange={codeNm} className="admin-input-line wd-300 ht-35 p-l-5 m-b-9"
                                       placeholder={"품목명을 입력하세요."} minLength="1" maxLength="8" type={"text"} />
                            </td>
                            <th><p>HS CODE</p></th>
                            <td className="font-b text-center">
                                <input defaultValue={selectedRowData.hsCode || ''} onChange={code} className="admin-input-line wd-300 ht-35 p-l-5 m-b-9"
                                       placeholder={"중복 HS CODE 등록불가."} minLength="1" maxLength="10" type={"text"} />
                            </td>
                        </tr>
                        )}
                        </tbody>
                    </table>
                </div>
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        <div className="admin-event-wrap-btn">
                            <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                        </div>
                        <div className="admin-event-wrap-btn">
                            <button onClick={excelDown} className="admin-btn-long admin-bt-color">EXCEL</button>
                            {/*<button className="admin-btn-long admin-bt-color">추가</button>*/}
                        </div>
                    </div>
                    <div className="div-tb">
                        <div className="div-tb-head ht-60">
                            <div style={{width: "40px"}}><input className="admin-input-chkBox m-0-a" type="checkbox" checked={checked.length === data.length}
                                                                onChange={allcheckBoxChange}/></div>
                            <div style={{width: "45px"}}><p>번호</p></div>
                            <div style={{width: "100px"}}><p>품목명</p></div>
                            <div style={{width: "120px"}}><p>HS CODE</p></div>
                            <div style={{width: "110px"}}><p>등록자</p></div>
                            <div style={{width: "150px"}}><p>등록일시</p></div>
                            <div style={{width: "150px"}}><p>수정자</p></div>
                            <div style={{width: "150px"}}><p>수정일시</p></div>
                        </div>
                        {data.slice(0, displayLimit).map((cData, i) => (
                            <div key={cData.seqNum} className="div-tb-body ht-70">
                                <div style={{width: "40px"}}>
                                    <input className="admin-input-chkBox m-0-a" type="checkbox" name={cData.seqNum} checked={checked.includes(cData.seqNum)}
                                           onChange={() => checkBoxChange(cData.seqNum)}/>
                                </div>
                                <div style={{width: "45px"}}><p>{i + 1}</p></div>
                                <div style={{width: "100px"}}><p>{cData.hsCodeNm}</p></div>
                                <div className="font-b wd-120"><p>{cData.hsCode}</p></div>
                                <div style={{width: "110px"}}><p>{cData.regNm}</p></div>
                                <div style={{width: "150px"}}><p>{cData.regTime}</p></div>
                                <div style={{width: "150px"}}><p>{cData.modiNm}</p></div>
                                <div style={{width: "150px"}}><p>{cData.modiTime}</p></div>
                            </div>
                        ))}
                    </div>
                    {displayLimit >= 30 && data.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {alertShow === true ? <MsgSelectModal alertShow={alertShow} eventHandle1={modalCancel} eventHandle2={customItemNewSave} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
        </div>
    );
}

export default AdminCustomsItemPg
