import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Tab, Table, Tabs} from "react-bootstrap";
import {BsSquareFill} from "react-icons/bs";
import * as XLSX from "xlsx";
import axios from "axios";
import excel from "exceljs";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import maCateImg from "../../assets/img/m-a-cate.png";

function AdminCurrencyRatePg() {

    /** region DataTable */

    const [emsData, setEmsData] = useState([]);
    const [fedexData, setFedexData] = useState([]);
    const [shipData, setShipData] = useState([]);
    const [domeData, setDomeData] = useState([]);
    const [dhlData, setDhlData] = useState([]);
    const [upsData, setUpsData] = useState([]);
    const [kpackData, setKpackData] = useState([]);
    const [airSData, setAirSData] = useState([]);

    /** endregion */

    /** region Function */

    const LCAT = localStorage.getItem('adminToken');
    const [excelData, setExcelData] = useState(null);
    const [excelDataf, setExcelDataf] = useState(null);
    const [excelDatas, setExcelDatas] = useState(null);
    const [excelDataDhl, setExcelDataDhl] = useState(null);
    const [excelDataEmsp, setExcelDataEmsp] = useState(null);
    const [excelDataKp, setExcelDataKp] = useState(null);
    const [excelDataAs, setExcelDataAs] = useState(null);
    const [selectedSheet, setSelectedSheet] = useState(null);
    const [selectedSheetf, setSelectedSheetf] = useState(null);
    const [selectedSheets, setSelectedSheets] = useState(null);
    const [selectedSheetDhl, setSelectedSheetDhl] = useState(null);
    const [selectedSheetEmsp, setSelectedSheetEmsp] = useState(null);
    const [selectedSheetKp, setSelectedSheetKp] = useState(null);
    const [selectedSheetAs, setSelectedSheetAs] = useState(null);

    /// region Alert

    const [alertShow, setAlertShow] = useState(false);
    const [alertShow2, setAlertShow2] = useState(false);
    const [alertShow3, setAlertShow3] = useState(false);
    const [alertShow4, setAlertShow4] = useState(false);
    const [alertShow5, setAlertShow5] = useState(false);
    const [alertShow6, setAlertShow6] = useState(false);
    const [alertShow7, setAlertShow7] = useState(false);
    const msgConfirmData1 = "기존 데이터에서 덮어 씌우시겠습니까?";
    const msgConfirmData2 = "저장 완료시 기존 요금정보는 복구 불가능합니다.";
    const alert = (() => {
        setAlertShow(true)
    })
    const alert2 = (() => {
        setAlertShow2(true)
    })
    const alert3 = (() => {
        setAlertShow3(true)
    })
    const alert4 = (() => {
        setAlertShow4(true)
    })
    const alert5 = (() => {
        setAlertShow5(true)
    })
    const alert6 = (() => {
        setAlertShow6(true)
    })
    const alert7 = (() => {
        setAlertShow7(true)
    })
    const modalCancel = useCallback(() => {
        setAlertShow(false);
    })
    const modalCancel2 = useCallback(() => {
        setAlertShow2(false);
    })
    const modalCancel3 = useCallback(() => {
        setAlertShow3(false);
    })
    const modalCancel4 = useCallback(() => {
        setAlertShow4(false);
    })
    const modalCancel5 = useCallback(() => {
        setAlertShow5(false);
    })
    const modalCancel6 = useCallback(() => {
        setAlertShow6(false);
    })
    const modalCancel7 = useCallback(() => {
        setAlertShow7(false);
    })

    // endregion Alert

    const exceUploadEMS = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames;
            setSelectedSheet(sheetName[0]);
            setExcelData(workbook);
        };
        reader.readAsArrayBuffer(file);
    };

    const exceUploadFEDEX = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetNamesf = workbook.SheetNames;
            setSelectedSheetf(sheetNamesf[0]);
            setExcelDataf(workbook);
        };
        reader.readAsArrayBuffer(file);
    };

    const exceUploadSHIP = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetNames = workbook.SheetNames;
            setSelectedSheets(sheetNames[0]);
            setExcelDatas(workbook);
        };
        reader.readAsArrayBuffer(file);
    };

    const exceUploadDHL = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetNamesDhl = workbook.SheetNames;
            setSelectedSheetDhl(sheetNamesDhl[0]);
            setExcelDataDhl(workbook);
        };
        reader.readAsArrayBuffer(file);
    };

    const exceUploadUPS = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetNamesEmsp = workbook.SheetNames;
            setSelectedSheetEmsp(sheetNamesEmsp[0]);
            setExcelDataEmsp(workbook);
        };
        reader.readAsArrayBuffer(file);
    };

    const exceUploadKP = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetNamesKp = workbook.SheetNames;
            setSelectedSheetKp(sheetNamesKp[0]);
            setExcelDataKp(workbook);
        };
        reader.readAsArrayBuffer(file);
    };

    const exceUploadAS = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetNamesAs = workbook.SheetNames;
            setSelectedSheetAs(sheetNamesAs[0]);
            setExcelDataAs(workbook);
        };
        reader.readAsArrayBuffer(file);
    };

    const sheetSelectFEDEX = (event) => {
        const sheetNamef = event.target.value;
        setSelectedSheetf(sheetNamef);
    };

    const sheetSelectEMS = (event) => {
        const sheetName = event.target.value;
        setSelectedSheet(sheetName);
    };

    const sheetSelectSHIP = (event) => {
        const sheetNames = event.target.value;
        setSelectedSheets(sheetNames);
    };

    const sheetSelectDHL = (event) => {
        const sheetNameDhl = event.target.value;
        setSelectedSheetDhl(sheetNameDhl);
    };

    const sheetSelectUPS = (event) => {
        const sheetNameEmsp = event.target.value;
        setSelectedSheetEmsp(sheetNameEmsp);
    };

    const sheetSelectKP = (event) => {
        const sheetNameKp = event.target.value;
        setSelectedSheetKp(sheetNameKp);
    };

    const sheetSelectAS = (event) => {
        const sheetNameAs = event.target.value;
        setSelectedSheetAs(sheetNameAs);
    };

    const getSheetDataf = () => {
        // if (!selectedSheetf || !excelDataf) {
        //     return [];
        // }
        // const worksheet = excelDataf.Sheets[selectedSheetf];
        // const rawData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        // rawData.shift();
        // return rawData;
        if (!selectedSheetf || !excelDataf) {
            return [];
        }
        const worksheet = excelDataf.Sheets[selectedSheetf];
        return XLSX.utils.sheet_to_json(worksheet, {header: 1});
    };

    const getSheetData = () => {
        if (!selectedSheet || !excelData) {
            return [];
        }
        const worksheet = excelData.Sheets[selectedSheet];
        return XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    };

    const getSheetDatas = () => {
        if (!selectedSheets || !excelDatas) {
            return [];
        }
        const worksheet = excelDatas.Sheets[selectedSheets];
        return XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    };

    const getSheetDataDhl = () => {
        if (!selectedSheetDhl || !excelDataDhl) {
            return [];
        }
        const worksheet = excelDataDhl.Sheets[selectedSheetDhl];
        return XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    };

    const getSheetDataEmsp = () => {
        if (!selectedSheetEmsp || !excelDataEmsp) {
            return [];
        }
        const worksheet = excelDataEmsp.Sheets[selectedSheetEmsp];
        return XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    };

    const getSheetDataKp = () => {
        if (!selectedSheetKp || !excelDataKp) {
            return [];
        }
        const worksheet = excelDataKp.Sheets[selectedSheetKp];
        return XLSX.utils.sheet_to_json(worksheet, {header: 1});
    };

    const getSheetDataAs = () => {
        if (!selectedSheetAs || !excelDataAs) {
            return [];
        }
        const worksheet = excelDataAs.Sheets[selectedSheetAs];
        return XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    };

    const sheetData = getSheetData();
    const sheetDataf = getSheetDataf();
    const sheetDatas = getSheetDatas();
    const sheetDataDhl = getSheetDataDhl();
    const sheetDataEmsp = getSheetDataEmsp();
    const sheetDataKp = getSheetDataKp();
    const sheetDataAs = getSheetDataAs();

    const fileSelect = useRef(null);
    const fileSelectf = useRef(null);
    const fileSelects = useRef(null);
    const fileSelectDhl = useRef(null);
    const fileSelectEmsp = useRef(null);
    const fileSelectKp = useRef(null);
    const fileSelectAs = useRef(null);

    /** endregion */

    /** region Event */

    useEffect(() => {
        selectEMS();
        selectFEDEX();
        selectSHIP();
        // selectDHL();
        selectUPS();
        selectKP();
        selectAS();
        selectDOME();
    }, [])

    const selectFEDEX = () => {
        axios({
            method: 'get',
            url: '/shipinfo/fedexexrdata',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setFedexData(response.data)
            })
            .catch(() => {});
    };

    const selectEMS = () => {
        axios({
            method: 'get',
            url: '/shipinfo/emsexrdata',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setEmsData(response.data)
            })
            .catch(() => {});
    };

    const selectSHIP= () => {
        axios({
            method: 'get',
            url: '/shipinfo/shipexrdata',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setShipData(response.data)
            })
            .catch(() => {});
    };

    const selectDHL= () => {
        axios({
            method: 'get',
            url: '/shipinfo/shipexrdata',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setDhlData(response.data)
            })
            .catch(() => {});
    };

    const selectUPS= () => {
        axios({
            method: 'get',
            url: '/shipinfo/upsexrdata',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setUpsData(response.data)
            })
            .catch(() => {});
    };

    const selectKP= () => {
        axios({
            method: 'get',
            url: '/shipinfo/kpexrdata',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setKpackData(response.data)
            })
            .catch(() => {});
    };

    const selectAS= () => {
        axios({
            method: 'get',
            url: '/shipinfo/airsexrdata',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setAirSData(response.data)
            })
            .catch(() => {});
    };

    const selectDOME= () => {
        axios({
            method: 'get',
            url: '/shipinfo/domeexrdata',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setDomeData(response.data)
            })
            .catch(() => {});
    };

    const transposeData = (data) => {
        if (data.length === 0) return [];

        const headers = data[0];
        return headers.slice(1).map((_, colIndex) => {
            return data.map(row => row[colIndex + 1]);
        });
    };

    const getCountryKpCode = (countryName) => {
        const countryCodes = {
            '호주': 'AU',
            '브라질': 'BR',
            '캐나다': 'CA',
            '중국': 'CN',
            '프랑스': 'FR',
            '독일': 'DE',
            '홍콩': 'HK',
            '인도네시아': 'ID',
            '일본': 'JP',
            '말레이시아': 'MY',
            '뉴질랜드': 'NZ',
            '필리핀': 'PH',
            '러시아': 'RU',
            '싱가포르': 'SG',
            '스페인': 'ES',
            '대만': 'TW',
            '태국': 'TH',
            '영국': 'GB',
            '미국': 'US',
            '베트남': 'VN',
        };

        return countryCodes[countryName] || 'ZZ';
    };

    const saveExcelFileFEDEX = () => {
        const sheetDataf = getSheetDataf();
        const transposedData = transposeData(sheetDataf);
        const excelDataList = transposedData.map(rowData => {
            return {
                appGroup: rowData[0],
                w500: rowData[1], w1000: rowData[2], w1500: rowData[3], w2000: rowData[4], w2500: rowData[5],
                w3000: rowData[6], w3500: rowData[7], w4000: rowData[8], w4500: rowData[9], w5000: rowData[10],
                w5500: rowData[10], w6000: rowData[11], w6500: rowData[12], w7000: rowData[13], w7500: rowData[14],
                w8000: rowData[15], w8500: rowData[16], w9000: rowData[17], w9500: rowData[18], w10000: rowData[19],
                w10500: rowData[20], w11000: rowData[21], w11500: rowData[22], w12000: rowData[23], w12500: rowData[24],
                w13000: rowData[25], w13500: rowData[26], w14000: rowData[27], w14500: rowData[28], w15000: rowData[29],
                w15500: rowData[30], w16000: rowData[31], w16500: rowData[32], w17000: rowData[33], w17500: rowData[34],
                w18000: rowData[35], w18500: rowData[36], w19000: rowData[37], w19500: rowData[38], w20000: rowData[39],
                w20500: rowData[40], w21000: rowData[41], w22000: rowData[42], w23000: rowData[43], w24000: rowData[44],
                w25000: rowData[45], w26000: rowData[46], w27000: rowData[47], w28000: rowData[48], w29000: rowData[49], w30000: rowData[50]
            };
        });
        axios.post('/shipinfo/foamdatasavefedex', excelDataList, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LCAT
            },
        })
            .then(() => {
                setAlertShow(false);
                setSelectedSheetf([]);
                selectFEDEX()
            })
            .catch((error) => {console.error('Error:', error);});
    };

    const saveExcelFileEMS = () => {
        const tableData = sheetData.slice(1).map((row) => {
            const rowData = {};
            sheetData[0].forEach((header, index) => {
                rowData[header] = row[index];
            });
            return rowData;
        });

        const excelDataList = tableData.map((rowData) => {
            let countryCode;
            if (rowData['국가코드'] === '1지역') {
                countryCode = 'LA';
            } else if (rowData['국가코드'] === '2지역') {
                countryCode = 'BD';
            } else if (rowData['국가코드'] === '3지역') {
                countryCode = 'IL';
            } else if (rowData['국가코드'] === '4지역') {
                countryCode = 'MX';
            } else if (rowData['국가코드'] === '뉴질랜드') {
                countryCode = 'NZ';
            } else if (rowData['국가코드'] === '대만') {
                countryCode = 'TW';
            } else if (rowData['국가코드'] === '독일') {
                countryCode = 'DE';
            } else if (rowData['국가코드'] === '러시아') {
                countryCode = 'RU';
            } else if (rowData['국가코드'] === '말레이시아') {
                countryCode = 'MY';
            } else if (rowData['국가코드'] === '미국') {
                countryCode = 'US';
            } else if (rowData['국가코드'] === '베트남') {
                countryCode = 'VN';
            } else if (rowData['국가코드'] === '브라질') {
                countryCode = 'BR';
            } else if (rowData['국가코드'] === '스페인') {
                countryCode = 'ES';
            } else if (rowData['국가코드'] === '싱가포르') {
                countryCode = 'SG';
            } else if (rowData['국가코드'] === '영국') {
                countryCode = 'GB';
            } else if (rowData['국가코드'] === '인도네시아') {
                countryCode = 'ID';
            } else if (rowData['국가코드'] === '일본') {
                countryCode = 'JP';
            } else if (rowData['국가코드'] === '중국') {
                countryCode = 'CN';
            } else if (rowData['국가코드'] === '캐나다') {
                countryCode = 'CA';
            } else if (rowData['국가코드'] === '태국') {
                countryCode = 'TH';
            } else if (rowData['국가코드'] === '프랑스') {
                countryCode = 'FR';
            } else if (rowData['국가코드'] === '필리핀') {
                countryCode = 'PH';
            } else if (rowData['국가코드'] === '호주') {
                countryCode = 'AU';
            } else if (rowData['국가코드'] === '홍콩') {
                countryCode = 'HK';
            } else {
                countryCode = 'ZZ';
            }
            return {
                countryCode,
                w500: rowData['500g'], w750: rowData['750g'], w1000: rowData['1000g'], w1250: rowData['1250g'], w1500: rowData['1500g'], w1750: rowData['1750g'], w2000: rowData['2000g'],
                w2500: rowData['2500g'], w3000: rowData['3000g'], w3500: rowData['3500g'], w4000: rowData['4000g'], w4500: rowData['4500g'], w5000: rowData['5000g'], w5500: rowData['5500g'],
                w6000: rowData['6000g'], w6500: rowData['6500g'], w7000: rowData['7000g'], w7500: rowData['7500g'], w8000: rowData['8000g'], w8500: rowData['8500g'], w9000: rowData['9000g'],
                w9500: rowData['9500g'], w10000: rowData['10000g'], w10500: rowData['10500g'], w11000: rowData['11000g'], w11500: rowData['11500g'], w12000: rowData['12000g'],
                w12500: rowData['12500g'], w13000: rowData['13000g'], w13500: rowData['13500g'], w14000: rowData['14000g'], w14500: rowData['14500g'], w15000: rowData['15000g'],
                w15500: rowData['15500g'], w16000: rowData['16000g'], w16500: rowData['16500g'], w17000: rowData['17000g'], w17500: rowData['17500g'], w18000: rowData['18000g'],
                w18500: rowData['18500g'], w19000: rowData['19000g'], w19500: rowData['19500g'], w20000: rowData['20000g'], w20500: rowData['20500g'], w21000: rowData['21000g'],
                w21500: rowData['21500g'], w22000: rowData['22000g'], w22500: rowData['22500g'], w23000: rowData['23000g'], w23500: rowData['23500g'], w24000: rowData['24000g'],
                w24500: rowData['24500g'], w25000: rowData['25000g'], w25500: rowData['25500g'], w26000: rowData['26000g'], w26500: rowData['26500g'], w27000: rowData['27000g'],
                w27500: rowData['27500g'], w28000: rowData['28000g'], w28500: rowData['28500g'], w29000: rowData['29000g'], w29500: rowData['29500g'], w30000: rowData['30000g']
            };
        });

        axios.post('/shipinfo/foamdatasave', excelDataList, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LCAT
            },
        })
            .then(() => {
                setAlertShow2(false);
                setSelectedSheet([]);
                selectEMS()
            })
            .catch((error) => {setAlertShow2(false); console.error('Error:', error);});
    };

    const saveExcelFileSHIP = () => {
        const tableData = sheetDatas.slice(1).map((row) => {
            const rowData = {};
            sheetDatas[0].forEach((header, index) => {
                rowData[header] = row[index];
            });
            return rowData;
        });

        const excelDataList = tableData.map((rowData) => {
            let appShipCode;
            if (rowData['지역코드'] === '1지역') {
                appShipCode = 'Z1';
            } else if (rowData['지역코드'] === '2지역') {
                appShipCode = 'Z2';
            } else if (rowData['지역코드'] === '3지역') {
                appShipCode = 'Z3';
            } else {
                appShipCode = 'Z4';
            }
            return {
                appShipCode,
                w1000: rowData['1000g'], w2000: rowData['2000g'], w4000: rowData['4000g'], w6000: rowData['6000g'], w8000: rowData['8000g'], w10000: rowData['10000g'],
                w12000: rowData['12000g'], w14000: rowData['14000g'], w16000: rowData['16000g'], w18000: rowData['18000g'], w20000: rowData['20000g']
            };
        });
        axios.post('/shipinfo/foamdatasaveship', excelDataList, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LCAT
            },
        })
            .then(() => {
                setAlertShow3(false);
                setSelectedSheets([]);
                selectSHIP()
            })
            .catch((error) => {console.error('Error:', error);});
    };

    const saveExcelFileUPS = () => {
        const sheetDataEmsp = getSheetDataEmsp();
        const transposedData = transposeData(sheetDataEmsp);
        const excelDataList = transposedData.map(rowData => {
            return {
                appUpsCode: rowData[0],
                w500: rowData[1], w1000: rowData[2], w1500: rowData[3], w2000: rowData[4], w2500: rowData[5], w3000: rowData[6], w3500: rowData[7], w4000: rowData[8],
                w4500: rowData[9], w5000: rowData[10], w5500: rowData[11], w6000: rowData[12], w6500: rowData[13], w7000: rowData[14], w7500: rowData[15], w8000: rowData[16],
                w8500: rowData[17], w9000: rowData[18], w9500: rowData[19], w10000: rowData[20], w10500: rowData[21], w11000: rowData[22], w11500: rowData[23], w12000: rowData[24],
                w12500: rowData[25], w13000: rowData[26], w13500: rowData[27], w14000: rowData[28], w14500: rowData[29], w15000: rowData[30], w15500: rowData[31], w16000: rowData[32],
                w16500: rowData[33], w17000: rowData[34], w17500: rowData[35], w18000: rowData[36], w18500: rowData[37], w19000: rowData[38], w19500: rowData[39], w20000: rowData[40],
                w21000: rowData[41], w22000: rowData[42], w23000: rowData[43], w24000: rowData[44], w25000: rowData[45], w26000: rowData[46], w27000: rowData[47],
                w28000: rowData[48], w29000: rowData[49], w30000: rowData[50], w31000: rowData[51], w32000: rowData[52], w33000: rowData[53], w34000: rowData[54], w35000: rowData[55],
                w36000: rowData[56], w37000: rowData[57], w38000: rowData[58], w39000: rowData[59], w40000: rowData[60], w41000: rowData[61], w42000: rowData[62], w43000: rowData[63],
                w44000: rowData[64], w45000: rowData[65], w46000: rowData[66], w47000: rowData[67], w48000: rowData[68], w49000: rowData[69], w50000: rowData[70], w51000: rowData[71],
                w52000: rowData[72], w53000: rowData[73], w54000: rowData[74], w55000: rowData[75], w56000: rowData[76], w57000: rowData[77], w58000: rowData[78], w59000: rowData[79],
                w60000: rowData[80], w61000: rowData[81], w62000: rowData[82], w63000: rowData[83], w64000: rowData[84], w65000: rowData[85], w66000: rowData[86], w67000: rowData[87],
                w68000: rowData[88], w69000: rowData[89], w70000: rowData[90],
            };
        });
        axios.post('/shipinfo/foamdatasaveups', excelDataList, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LCAT
            },
        })
            .then(() => {
                setAlertShow5(false);
                setSelectedSheetEmsp([]);
                selectUPS();
            })
            .catch((error) => {console.error('Error:', error);});
    };

    const saveExcelFileKP = () => {
        const sheetDataKp = getSheetDataKp();
        const transposedData = transposeData(sheetDataKp);
        const excelDataList = transposedData.map(rowData => {
            return {
                countryCode: getCountryKpCode(rowData[0]),
                w100: parseInt(rowData[1].replace(/,/g, ''), 10) || 0,
                w200: parseInt(rowData[2].replace(/,/g, ''), 10) || 0,
                w300: parseInt(rowData[3].replace(/,/g, ''), 10) || 0,
                w400: parseInt(rowData[4].replace(/,/g, ''), 10) || 0,
                w500: parseInt(rowData[5].replace(/,/g, ''), 10) || 0,
                w600: parseInt(rowData[6].replace(/,/g, ''), 10) || 0,
                w700: parseInt(rowData[7].replace(/,/g, ''), 10) || 0,
                w800: parseInt(rowData[8].replace(/,/g, ''), 10) || 0,
                w900: parseInt(rowData[9].replace(/,/g, ''), 10) || 0,
                w1000: parseInt(rowData[10].replace(/,/g, ''), 10) || 0,
                w1100: parseInt(rowData[11].replace(/,/g, ''), 10) || 0,
                w1200: parseInt(rowData[12].replace(/,/g, ''), 10) || 0,
                w1300: parseInt(rowData[13].replace(/,/g, ''), 10) || 0,
                w1400: parseInt(rowData[14].replace(/,/g, ''), 10) || 0,
                w1500: parseInt(rowData[15].replace(/,/g, ''), 10) || 0,
                w1600: parseInt(rowData[16].replace(/,/g, ''), 10) || 0,
                w1700: parseInt(rowData[17].replace(/,/g, ''), 10) || 0,
                w1800: parseInt(rowData[18].replace(/,/g, ''), 10) || 0,
                w1900: parseInt(rowData[19].replace(/,/g, ''), 10) || 0,
                w2000: parseInt(rowData[20].replace(/,/g, ''), 10) || 0
            };
        });

        axios.post('/shipinfo/foamdatasavekpack', excelDataList, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LCAT
            },
        })
            .then(() => {
                setAlertShow6(false);
                setSelectedSheetKp([]);
                selectKP()
            })
            .catch((error) => {console.error('Error:', error);});
    };

    const saveExcelFileAS = () => {
        const tableData = sheetDataAs.slice(1).map((row) => {
            const rowData = {};
            sheetDataAs[0].forEach((header, index) => {
                rowData[header] = row[index];
            });
            return rowData;
        });

        const excelDataList = tableData.map((rowData) => {
            let appAsCode;
            if (rowData['지역코드'] === '1지역') {
                appAsCode = 'Z1';
            } else if (rowData['지역코드'] === '2지역') {
                appAsCode = 'Z2';
            } else if (rowData['지역코드'] === '3지역') {
                appAsCode = 'Z3';
            } else {
                appAsCode = 'Z4';
            }
            return {
                appAsCode: appAsCode,
                w100: rowData['100g'],w200: rowData['200g'],w300: rowData['300g'],w400: rowData['400g'],
                w500: rowData['500g'],w600: rowData['600g'],w700: rowData['700g'],w800: rowData['800g'],
                w900: rowData['900g'],w1000: rowData['1000g'],w1100: rowData['1100g'],w1200: rowData['1200g'],
                w1300: rowData['1300g'],w1400: rowData['1400g'],w1500: rowData['1500g'],w1600: rowData['1600g'],
                w1700: rowData['1700g'],w1800: rowData['1800g'],w1900: rowData['1900g'],w2000: rowData['2000g']
            };
        });
        axios.post('/shipinfo/foamdatasaveairs', excelDataList, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LCAT
            },
        })
            .then(() => {
                setAlertShow7(false);
                setSelectedSheetAs([]);
                selectAS()
            })
            .catch((error) => {console.error('Error:', error);});
    };

    //region Excel

    const excelDownFEDEX = () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('FedEx');
        const sheetOne = workbook.getWorksheet('FedEx');
        sheetOne.getRow(1).font = {name: '맑은 고딕', bold: true, size: 11};
        sheetOne.getRow(1).alignment = {horizontal: 'center', vertical: 'middle'};
        sheetOne.getRow(1).height = 20;
        sheetOne.columns = [
            {header: '중량', width: 12, style: { alignment: { horizontal: 'center' }}},
            {header: 'A', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'D', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'F', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'G', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'H', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'I', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'J', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'M', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'N', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'O', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'P', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'Q', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'R', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'S', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'T', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'U', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'V', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'W', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'X', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'Y', width: 10, style: { alignment: { horizontal: 'center' }}},
        ];

        const weights = [
            0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10,
            10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30
        ];

        weights.forEach((weight, index) => {
            sheetOne.getCell(`A${index + 2}`).value = weight;
            sheetOne.getCell(`A${index + 2}`).alignment = { horizontal: 'center' };
        });

        const memoSheet = workbook.addWorksheet('참조');

        const memoText = `A - 마카오
                D - 캄보디아, 라오스 몽골
                F - 캐나다, 미국, 멕시코
                G - 오스트리아, 체코, 덴마크, 핀란드, 그리스, 헝가리, 아일랜드, 노르웨이, 폴란드, 포르투갈, 스웨덴, 스위스
                H - 이스라엘, 튀르키예, 러시아, 우크라이나, 우즈베키스탄
                I - 아르헨티아, 브라질, 칠레, 페루
                J - 방글라데시, 모로코, 카타르, 아랍에미리트, 요르단 ,사우디아라비아
                M - 벨기에, 독일, 이탈리아, 네덜란드, 스페인, 영국, 프랑스
                N - 베트남
                O - 인도
                P - 일본
                Q - 말레이시아
                R - 태국
                S - 필리핀
                T - 인도네시아
                U - 호주, 뉴질랜드
                V - 홍콩
                W - 중국
                X - 대만
                Y - 싱가포르`;

        memoSheet.getCell('A1').value = memoText;
        memoSheet.getCell('A1').alignment = { horizontal: 'left', vertical: 'top' };
        memoSheet.getCell('A1').font = { name: '맑은 고딕', size: 11 };
        memoSheet.getRow(1).height = 400;
        memoSheet.getColumn(1).width = 350;

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `요금표[FedEx].xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const excelDownEMS = () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('EMS');
        const sheetOne = workbook.getWorksheet('EMS');
        sheetOne.getRow(1).font = {name: '맑은 고딕', bold: true, size: 11};
        sheetOne.getRow(1).alignment = {horizontal: 'center', vertical: 'middle'};
        sheetOne.getRow(1).height = 20;
        sheetOne.columns = [
            {header: '국가코드', width: 12, style: { alignment: { horizontal: 'center' }}},
            {header: '500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '750g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '1000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '1250g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '1500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '1750g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '2000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '2500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '3000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '3500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '4000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '4500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '5000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '5500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '6000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '6500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '7000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '7500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '8000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '8500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '9000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '9500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '10000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '10500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '11000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '11500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '12000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '12500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '13000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '13500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '14000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '14500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '15000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '15500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '16000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '16500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '17000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '17500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '18000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '18500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '19000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '19500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '20000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '20500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '21000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '21500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '22000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '22500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '23000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '23500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '24000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '24500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '25000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '25500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '26000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '26500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '27000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '27500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '28000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '28500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '29000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '29500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '30000g', width: 10, style: { alignment: { horizontal: 'center' }}},
        ];

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `요금표[EMS].xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const excelDownSHIP = () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('SHIP');
        const sheetOne = workbook.getWorksheet('SHIP');
        sheetOne.getRow(1).font = {name: '맑은 고딕', bold: true, size: 11};
        sheetOne.getRow(1).alignment = {horizontal: 'center', vertical: 'middle'};
        sheetOne.getRow(1).height = 20;
        sheetOne.columns = [
            {header: '지역코드', width: 12, style: { alignment: { horizontal: 'center' }}},
            {header: '1000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '2000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '4000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '6000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '8000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '10000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '12000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '14000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '16000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '18000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '20000g', width: 10, style: { alignment: { horizontal: 'center' }}},
        ];

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `요금표[SHIP].xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    // const excelDownDHL = () => {}

    const excelDownUPS = () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('UPS');
        const sheetOne = workbook.getWorksheet('UPS');
        sheetOne.getRow(1).font = {name: '맑은 고딕', bold: true, size: 11};
        sheetOne.getRow(1).alignment = {horizontal: 'center', vertical: 'middle'};
        sheetOne.getRow(1).height = 20;
        sheetOne.columns = [
            {header: 'kg', width: 12, style: { alignment: { horizontal: 'center' }}},
            {header: 'Z1', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'Z2', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'Z3', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'Z4', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'ZR', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: 'Z5', width: 10, style: { alignment: { horizontal: 'center' }}},
        ];

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `요금표[UPS].xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const excelDownKP = () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('K-Packet');
        const sheetOne = workbook.getWorksheet('K-Packet');
        sheetOne.getRow(1).font = {name: '맑은 고딕', bold: true, size: 11};
        sheetOne.getRow(1).alignment = {horizontal: 'center', vertical: 'middle'};
        sheetOne.getRow(1).height = 20;
        sheetOne.columns = [
            {header: '중량', width: 12, style: { alignment: { horizontal: 'center' }}},
            {header: '호주', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '브라질', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '캐나다', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '중국', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '프랑스', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '독일', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '홍콩', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '인도네시아', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '일본', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '말레이시아', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '뉴질랜드', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '필리핀', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '러시아', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '싱가포르', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '스페인', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '대만', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '태국', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '영국', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '미국', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '베트남', width: 10, style: { alignment: { horizontal: 'center' }}},
        ];

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `요금표[K-Packet].xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const excelDownAS = () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('항공소형');
        const sheetOne = workbook.getWorksheet('항공소형');
        sheetOne.getRow(1).font = {name: '맑은 고딕', bold: true, size: 11};
        sheetOne.getRow(1).alignment = {horizontal: 'center', vertical: 'middle'};
        sheetOne.getRow(1).height = 20;
        sheetOne.columns = [
            {header: '지역코드', width: 12, style: { alignment: { horizontal: 'center' }}},
            {header: '100g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '200g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '300g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '400g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '600g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '700g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '800g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '900g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '1000g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '1100g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '1200g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '1300g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '1400g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '1500g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '1600g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '1700g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '1800g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '1900g', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '2000g', width: 10, style: { alignment: { horizontal: 'center' }}},
        ];

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `요금표[항공소형].xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    //endregion

    /** endregion */

    return (
        <div className="AdminCurrencyRatePg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">요금표관리</p>
            </div>
            <div className="admin-wrap font-t-cont">
                <div className="admin-wrap-card">
                    <Tabs className="mandm-tab m-b-20 font-s-15" defaultActiveKey="mam-tab-main" style={{marginBottom: "20px", fontSize: "15px"}}>
                        <Tab eventKey="mam-tab-main" title="&nbsp;&nbsp;&nbsp;FedEx&nbsp;&nbsp;&nbsp;">
                            <div className="admin-event-wrap">
                                <div className="admin-event-wrap-btn">
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        <div className="m-l-10" style={{position: "relative"}}>
                                            <input className="font-s-16" type="file" onChange={exceUploadFEDEX} ref={fileSelectf}
                                                   style={{border: "none", paddingLeft: "14px", height: "25px", width: "280px"}}/>
                                            <button id="ex-btn" className="admin-btn-long admin-bt-color" onClick={() => fileSelectf.current.click()}
                                                    style={{position: "absolute", top: -3, right: "187px"}}>파일선택
                                            </button>
                                        </div>
                                        <label style={{fontSize: "14px", marginTop: "5px", marginRight: "5px"}}>Sheet 선택</label>
                                        {selectedSheetf && (
                                            <select className="admin-combo font-s-13" id="sheet-select" value={selectedSheetf.value} onChange={sheetSelectFEDEX}
                                                    style={{width: "120px", height: "25px", marginTop: "2px"}}>
                                                {excelDataf.SheetNames.map((sheetName) => (
                                                    <option key={sheetName} value={sheetName}>
                                                        {sheetName}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                </div>
                                <div className="admin-event-wrap-btn">
                                    <button className="admin-btn-long admin-bt-color" onClick={excelDownFEDEX}>EXCEL</button>
                                    <button className="admin-btn-long admin-bt-color" onClick={alert}>저장</button>
                                </div>
                            </div>
                            <div className="gbn-border-b" style={{width: "100%", marginBottom: "15px"}}></div>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <div className="admin-event-wrap">
                                        <div className="admin-btn-wrap-l">
                                            <BsSquareFill className="admin-tb-header-notice"/>
                                            <a>새로 적용할 요금표[EXCEL]</a>
                                        </div>
                                    </div>
                                    <div style={{width: "100%", maxWidth: "100%",overflowX: "auto"}}>
                                        {selectedSheetf && (
                                            <Table className="admin-tb" striped bordered hover>
                                                <thead className="admin-tb-head">
                                                <tr className="admin-tb-head-line">
                                                    {sheetDataf[0] && sheetDataf[0].map((header) =>
                                                        <th key={header} style={{width: "80px"}}>
                                                            <b>{header}</b>
                                                        </th>)
                                                    }
                                                </tr>
                                                </thead>
                                                <tbody className="admin-tb-body">
                                                {sheetDataf.slice(1).map((row, i) => (
                                                    <tr className="admin-tb-body-line" key={i}>
                                                        {row.map((cell, j) => (
                                                            <td key={`${i}-${j}`}><a>{cell.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                        ))}
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        )}
                                    </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <div className="admin-event-wrap" style={{marginTop: "70px", marginBottom: "-1px"}}>
                                        <div className="admin-btn-wrap-l">
                                            <BsSquareFill className="admin-tb-header-notice"/>
                                            <a>현재 적용된 요금표(단위:kg/&#8361;)</a>
                                        </div>
                                    </div>
                                    <div style={{width: "100%", maxWidth: "100%",overflowX: "auto"}}>
                                        <Table striped bordered hover className="admin-tb">
                                            <thead className="admin-tb-head">
                                                <tr className="admin-tb-head-line">
                                                <th style={{width: "75px"}}><b>그룹코드</b></th>
                                                <th style={{width: "75px"}}><b>0.5kg</b></th>
                                                <th style={{width: "75px"}}><b>1kg</b></th>
                                                <th style={{width: "75px"}}><b>1.5kg</b></th>
                                                <th style={{width: "75px"}}><b>2kg</b></th>
                                                <th style={{width: "75px"}}><b>2.5kg</b></th>
                                                <th style={{width: "75px"}}><b>3.0kg</b></th>
                                                <th style={{width: "75px"}}><b>3.5kg</b></th>
                                                <th style={{width: "75px"}}><b>4.0kg</b></th>
                                                <th style={{width: "75px"}}><b>4.5kg</b></th>
                                                <th style={{width: "75px"}}><b>5.0kg</b></th>
                                                <th style={{width: "75px"}}><b>5.5kg</b></th>
                                                <th style={{width: "75px"}}><b>6.0kg</b></th>
                                                <th style={{width: "75px"}}><b>6.5kg</b></th>
                                                <th style={{width: "75px"}}><b>7.0kg</b></th>
                                                <th style={{width: "75px"}}><b>7.5kg</b></th>
                                                <th style={{width: "75px"}}><b>8.0kg</b></th>
                                                <th style={{width: "75px"}}><b>8.5kg</b></th>
                                                <th style={{width: "75px"}}><b>9.0kg</b></th>
                                                <th style={{width: "75px"}}><b>9.5kg</b></th>
                                                <th style={{width: "75px"}}><b>100kg</b></th>
                                                <th style={{width: "75px"}}><b>10.5kg</b></th>
                                                <th style={{width: "75px"}}><b>11kg</b></th>
                                                <th style={{width: "75px"}}><b>11.5kg</b></th>
                                                <th style={{width: "75px"}}><b>12kg</b></th>
                                                <th style={{width: "75px"}}><b>12.5kg</b></th>
                                                <th style={{width: "75px"}}><b>13kg</b></th>
                                                <th style={{width: "75px"}}><b>13.5kg</b></th>
                                                <th style={{width: "75px"}}><b>14kg</b></th>
                                                <th style={{width: "75px"}}><b>14.5kg</b></th>
                                                <th style={{width: "75px"}}><b>15kg</b></th>
                                                <th style={{width: "75px"}}><b>15.5kg</b></th>
                                                <th style={{width: "75px"}}><b>16kg</b></th>
                                                <th style={{width: "75px"}}><b>16.5kg</b></th>
                                                <th style={{width: "75px"}}><b>17kg</b></th>
                                                <th style={{width: "75px"}}><b>17.5kg</b></th>
                                                <th style={{width: "75px"}}><b>18kg</b></th>
                                                <th style={{width: "75px"}}><b>18.5kg</b></th>
                                                <th style={{width: "75px"}}><b>19kg</b></th>
                                                <th style={{width: "75px"}}><b>19.5kg</b></th>
                                                <th style={{width: "75px"}}><b>20kg</b></th>
                                                <th style={{width: "75px"}}><b>20.5kg</b></th>
                                                <th style={{width: "75px"}}><b>21kg</b></th>
                                                <th style={{width: "75px"}}><b>22kg</b></th>
                                                <th style={{width: "75px"}}><b>23kg</b></th>
                                                <th style={{width: "75px"}}><b>24kg</b></th>
                                                <th style={{width: "75px"}}><b>25kg</b></th>
                                                <th style={{width: "75px"}}><b>26kg</b></th>
                                                <th style={{width: "75px"}}><b>27kg</b></th>
                                                <th style={{width: "75px"}}><b>28kg</b></th>
                                                <th style={{width: "75px"}}><b>29kg</b></th>
                                                <th style={{width: "75px"}}><b>30kg</b></th>
                                            </tr>
                                            </thead>
                                            <tbody className="admin-tb-body">
                                            {fedexData.map((cData, i) => (
                                                <tr className="admin-tb-body-line" key={cData.seqNum}>
                                                    <td><a>{fedexData[i].appGroup}</a></td>
                                                    <td><a>{fedexData[i].w500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w1000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w1500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w2000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w2500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w3000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w3500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w4000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w4500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w5000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w5500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w6000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w6500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w7000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w7500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w8000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w8500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w9000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w9500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w10000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w10500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w11000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w11500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w12000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w12500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w13000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w13500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w14000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w14500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w15000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w15500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w16000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w16500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w17000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w17500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w18000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w18500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w19000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w19500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w20000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w20500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w21000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w22000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w23000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w24000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w25000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w26000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w27000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w28000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w29000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w30000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            <div className="font-t-cont font-s-14">
                                <p className="font-b">A - 마카오</p>
                                <p className="font-b">D - 캄보디아, 라오스 몽골</p>
                                <p className="font-b">F - 캐나다, 미국, 멕시코</p>
                                <p className="font-b">G - 오스트리아, 체코, 덴마크, 핀란드, 그리스, 헝가리, 아일랜드, 노르웨이, 폴란드, 포르투갈, 스웨덴, 스위스</p>
                                <p className="font-b">H - 이스라엘, 튀르키예, 러시아, 우크라이나, 우즈베키스탄</p>
                                <p className="font-b">I - 아르헨티아, 브라질, 칠레, 페루</p>
                                <p className="font-b">J - 방글라데시, 모로코, 카타르, 아랍에미리트, 요르단 ,사우디아라비아</p>
                                <p className="font-b">M - 벨기에, 독일, 이탈리아, 네덜란드, 스페인, 영국, 프랑스</p>
                                <p className="font-b">N - 베트남</p>
                                <p className="font-b">O - 인도</p>
                                <p className="font-b">P - 일본</p>
                                <p className="font-b">Q - 말레이시아</p>
                                <p className="font-b">R - 태국</p>
                                <p className="font-b">S - 필리핀</p>
                                <p className="font-b">T - 인도네시아</p>
                                <p className="font-b">U - 호주, 뉴질랜드</p>
                                <p className="font-b">V - 홍콩</p>
                                <p className="font-b">W - 중국</p>
                                <p className="font-b">X - 대만</p>
                                <p className="font-b">Y - 싱가포르</p>
                            </div>
                        </Tab>
                        <Tab eventKey="mam-tab-sub-ups" title="&nbsp;&nbsp;UPS&nbsp;&nbsp;">
                            <div className="admin-event-wrap">
                                <div className="admin-event-wrap-btn">
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        <div className="m-l-10" style={{position: "relative"}}>
                                            <input className="font-s-16" type="file" onChange={exceUploadUPS} ref={fileSelectEmsp}
                                                   style={{border: "none", paddingLeft: "14px", height: "25px", width: "280px"}}/>
                                            <button id="ex-btn" className="admin-btn-long admin-bt-color" onClick={() => fileSelectEmsp.current.click()}
                                                    style={{position: "absolute", top: -3, right: "187px"}}>파일선택
                                            </button>
                                        </div>
                                        <label style={{fontSize: "14px", marginTop: "5px", marginRight: "5px"}}>Sheet 선택</label>
                                        {selectedSheetEmsp && (
                                            <select className="admin-combo font-s-13" id="sheet-select" value={selectedSheetEmsp.value} onChange={sheetSelectUPS}
                                                    style={{width: "120px", height: "25px", marginTop: "2px"}}>
                                                {excelDataEmsp.SheetNames.map((sheetName) => (
                                                    <option key={sheetName} value={sheetName}>
                                                        {sheetName}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                </div>
                                <div className="admin-event-wrap-btn">
                                    <button className="admin-btn-long admin-bt-color" onClick={excelDownUPS}>EXCEL</button>
                                    <button className="admin-btn-long admin-bt-color" onClick={alert5}>저장</button>
                                </div>
                            </div>
                            <div className="gbn-border-b" style={{width: "100%", marginTop: "18px", marginBottom: "15px"}}></div>
                            <div className="admin-event-wrap" style={{marginTop: "10px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>새로 적용할 요금표[EXCEL]</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                {selectedSheetEmsp && (
                                    <Table className="admin-tb" striped bordered hover>
                                        <thead className="admin-tb-head">
                                        <tr className="admin-tb-head-line">
                                            {sheetDataEmsp[0] && sheetDataEmsp[0].map((header) =>
                                                <th key={header} style={{width: "80px"}}>
                                                    <b>{header}</b>
                                                </th>)
                                            }
                                        </tr>
                                        </thead>
                                        <tbody className="admin-tb-body">
                                        {sheetDataEmsp.slice(1).map((row, i) => (
                                            <tr className="admin-tb-body-line" key={i}>
                                                {row.map((cell, j) => (
                                                    <td key={`${i}-${j}`}><a>{cell.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                ))}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                )}
                            </div>
                            <div className="admin-event-wrap" style={{marginTop: "70px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>현재 적용된 요금표(단위:kg/&#8361;)</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                <Table striped bordered hover className="admin-tb">
                                    <thead className="admin-tb-head">
                                    <tr className="admin-tb-head-line">
                                        <th style={{width: "75px"}}><b>지역코드</b></th>
                                        <th style={{width: "75px"}}><b>0.5kg</b></th>
                                        <th style={{width: "75px"}}><b>1kg</b></th>
                                        <th style={{width: "75px"}}><b>1.5kg</b></th>
                                        <th style={{width: "75px"}}><b>2kg</b></th>
                                        <th style={{width: "75px"}}><b>2.5kg</b></th>
                                        <th style={{width: "75px"}}><b>3.0kg</b></th>
                                        <th style={{width: "75px"}}><b>3.5kg</b></th>
                                        <th style={{width: "75px"}}><b>4.0kg</b></th>
                                        <th style={{width: "75px"}}><b>4.5kg</b></th>
                                        <th style={{width: "75px"}}><b>5.0kg</b></th>
                                        <th style={{width: "75px"}}><b>5.5kg</b></th>
                                        <th style={{width: "75px"}}><b>6.0kg</b></th>
                                        <th style={{width: "75px"}}><b>6.5kg</b></th>
                                        <th style={{width: "75px"}}><b>7.0kg</b></th>
                                        <th style={{width: "75px"}}><b>7.5kg</b></th>
                                        <th style={{width: "75px"}}><b>8.0kg</b></th>
                                        <th style={{width: "75px"}}><b>8.5kg</b></th>
                                        <th style={{width: "75px"}}><b>9.0kg</b></th>
                                        <th style={{width: "75px"}}><b>9.5kg</b></th>
                                        <th style={{width: "75px"}}><b>10.0kg</b></th>
                                        <th style={{width: "75px"}}><b>10.5kg</b></th>
                                        <th style={{width: "75px"}}><b>11kg</b></th>
                                        <th style={{width: "75px"}}><b>11.5kg</b></th>
                                        <th style={{width: "75px"}}><b>12kg</b></th>
                                        <th style={{width: "75px"}}><b>12.5kg</b></th>
                                        <th style={{width: "75px"}}><b>13kg</b></th>
                                        <th style={{width: "75px"}}><b>13.5kg</b></th>
                                        <th style={{width: "75px"}}><b>14kg</b></th>
                                        <th style={{width: "75px"}}><b>14.5kg</b></th>
                                        <th style={{width: "75px"}}><b>15kg</b></th>
                                        <th style={{width: "75px"}}><b>15.5kg</b></th>
                                        <th style={{width: "75px"}}><b>16kg</b></th>
                                        <th style={{width: "75px"}}><b>16.5kg</b></th>
                                        <th style={{width: "75px"}}><b>17kg</b></th>
                                        <th style={{width: "75px"}}><b>17.5kg</b></th>
                                        <th style={{width: "75px"}}><b>18kg</b></th>
                                        <th style={{width: "75px"}}><b>18.5kg</b></th>
                                        <th style={{width: "75px"}}><b>19kg</b></th>
                                        <th style={{width: "75px"}}><b>19.5kg</b></th>
                                        <th style={{width: "75px"}}><b>20kg</b></th>
                                        <th style={{width: "75px"}}><b>21kg</b></th>
                                        <th style={{width: "75px"}}><b>22kg</b></th>
                                        <th style={{width: "75px"}}><b>23kg</b></th>
                                        <th style={{width: "75px"}}><b>24kg</b></th>
                                        <th style={{width: "75px"}}><b>25kg</b></th>
                                        <th style={{width: "75px"}}><b>26kg</b></th>
                                        <th style={{width: "75px"}}><b>27kg</b></th>
                                        <th style={{width: "75px"}}><b>28kg</b></th>
                                        <th style={{width: "75px"}}><b>29kg</b></th>
                                        <th style={{width: "75px"}}><b>30kg</b></th>
                                        <th style={{width: "75px"}}><b>31kg</b></th>
                                        <th style={{width: "75px"}}><b>32kg</b></th>
                                        <th style={{width: "75px"}}><b>33kg</b></th>
                                        <th style={{width: "75px"}}><b>34kg</b></th>
                                        <th style={{width: "75px"}}><b>35kg</b></th>
                                        <th style={{width: "75px"}}><b>36kg</b></th>
                                        <th style={{width: "75px"}}><b>37kg</b></th>
                                        <th style={{width: "75px"}}><b>38kg</b></th>
                                        <th style={{width: "75px"}}><b>39kg</b></th>
                                        <th style={{width: "75px"}}><b>40kg</b></th>
                                        <th style={{width: "75px"}}><b>41kg</b></th>
                                        <th style={{width: "75px"}}><b>42kg</b></th>
                                        <th style={{width: "75px"}}><b>43kg</b></th>
                                        <th style={{width: "75px"}}><b>44kg</b></th>
                                        <th style={{width: "75px"}}><b>45kg</b></th>
                                        <th style={{width: "75px"}}><b>46kg</b></th>
                                        <th style={{width: "75px"}}><b>47kg</b></th>
                                        <th style={{width: "75px"}}><b>48kg</b></th>
                                        <th style={{width: "75px"}}><b>49kg</b></th>
                                        <th style={{width: "75px"}}><b>50kg</b></th>
                                        <th style={{width: "75px"}}><b>51kg</b></th>
                                        <th style={{width: "75px"}}><b>52kg</b></th>
                                        <th style={{width: "75px"}}><b>53kg</b></th>
                                        <th style={{width: "75px"}}><b>54kg</b></th>
                                        <th style={{width: "75px"}}><b>55kg</b></th>
                                        <th style={{width: "75px"}}><b>56kg</b></th>
                                        <th style={{width: "75px"}}><b>57kg</b></th>
                                        <th style={{width: "75px"}}><b>58kg</b></th>
                                        <th style={{width: "75px"}}><b>59kg</b></th>
                                        <th style={{width: "75px"}}><b>60kg</b></th>
                                        <th style={{width: "75px"}}><b>61kg</b></th>
                                        <th style={{width: "75px"}}><b>62kg</b></th>
                                        <th style={{width: "75px"}}><b>63kg</b></th>
                                        <th style={{width: "75px"}}><b>64kg</b></th>
                                        <th style={{width: "75px"}}><b>65kg</b></th>
                                        <th style={{width: "75px"}}><b>66kg</b></th>
                                        <th style={{width: "75px"}}><b>67kg</b></th>
                                        <th style={{width: "75px"}}><b>68kg</b></th>
                                        <th style={{width: "75px"}}><b>69kg</b></th>
                                        <th style={{width: "75px"}}><b>70kg</b></th>
                                    </tr>
                                    </thead>
                                    <tbody className="admin-tb-body">
                                    {upsData.map((cData, i) => (
                                        <tr className="admin-tb-body-line" key={cData.seqNum}>
                                            <td><a>{upsData[i].appUpsCode}</a></td>
                                            <td><a>{upsData[i].w500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w1000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w1500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w2000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w2500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w3000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w3500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w4000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w4500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w5000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w5500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w6000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w6500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w7000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w7500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w8000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w8500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w9000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w9500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w10000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w10500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w11000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w11500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w12000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w12500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w13000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w13500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w14000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w14500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w15000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w15500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w16000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w16500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w17000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w17500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w18000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w18500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w19000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w19500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w20000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w21000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w22000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w23000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w24000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w25000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w26000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w27000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w28000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w29000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w30000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w31000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w32000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w33000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w34000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w35000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w36000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w37000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w38000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w39000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w40000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w41000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w42000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w43000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w44000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w45000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w46000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w47000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w48000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w49000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w50000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w51000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w52000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w53000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w54000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w55000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w56000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w57000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w58000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w59000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w60000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w61000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w62000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w63000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w64000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w65000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w66000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w67000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w68000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w69000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w70000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </div>
                            <div className="font-t-cont font-s-14">
                                {/*<p className="font-b">Z1[1지역] - CN:중국, HK:홍콩, MO:마카오, TW:타이완</p>*/}
                                {/*<p className="font-b">Z2[2지역] - BN:브루나이, KH:캄보디아, JP:일본, LA:라오스, MY:말레이시아, SG:싱가포르, TH:태국, VN:베트남</p>*/}
                                {/*<p className="font-b">Z3[3지역] - </p>*/}
                                {/*<p className="font-b">Z4[4지역] - </p>*/}
                                {/*<p className="font-b">ZA[4-1지역] - RU:러시아</p>*/}
                                {/*<p className="font-b">Z5[5지역] - </p>*/}
                            </div>
                        </Tab>
                        <Tab eventKey="mam-tab-sub" title="&nbsp;&nbsp;&nbsp;&nbsp;EMS&nbsp;&nbsp;&nbsp;&nbsp;">
                            <div>
                                <div className="admin-event-wrap">
                                    <div className="admin-event-wrap-btn">
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <div className="m-l-10" style={{position: "relative"}}>
                                                <input className="font-s-16" type="file" onChange={exceUploadEMS} ref={fileSelect}
                                                       style={{border: "none", paddingLeft: "14px", height: "25px", width: "280px"}}/>
                                                <button id="ex-btn" className="admin-btn-long admin-bt-color" onClick={() => fileSelect.current.click()}
                                                        style={{position: "absolute", top: -3, right: "187px"}}>파일선택
                                                </button>
                                            </div>
                                            <label style={{fontSize: "14px", marginTop: "5px", marginRight: "5px"}}>Sheet 선택</label>
                                            {selectedSheet && (
                                                <select className="admin-combo font-s-13" id="sheet-select" value={selectedSheet.value} onChange={sheetSelectEMS}
                                                        style={{width: "120px", height: "25px", marginTop: "2px"}}>
                                                    {excelData.SheetNames.map((sheetName) => (
                                                        <option key={sheetName} value={sheetName}>
                                                            {sheetName}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </div>
                                    </div>
                                    <div className="admin-event-wrap-btn">
                                        <button className="admin-btn-long admin-bt-color" onClick={excelDownEMS}>EXCEL</button>
                                        <button className="admin-btn-long admin-bt-color" onClick={alert2}>저장</button>
                                    </div>
                                </div>
                            </div>
                            <div className="gbn-border-b" style={{width: "100%", marginBottom: "15px"}}></div>
                            <div className="admin-event-wrap" style={{marginTop: "10px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>새로 적용할 요금표[EXCEL]</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                {selectedSheet && (
                                    <Table className="admin-tb" striped bordered hover>
                                        <thead className="admin-tb-head">
                                        <tr className="admin-tb-head-line">
                                            {sheetData[0] && sheetData[0].map((header) =>
                                                <th key={header} style={{width: "80px"}}>
                                                    <b>{header}</b>
                                                </th>)
                                            }
                                        </tr>
                                        </thead>
                                        <tbody className="admin-tb-body">
                                        {sheetData.slice(1).map((row, i) => (
                                            <tr className="admin-tb-body-line" key={i}>
                                                {row.map((cell, j) => (
                                                    <td key={`${i}-${j}`}><a>{cell.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                ))}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                )}
                            </div>
                            <div className="admin-event-wrap" style={{marginTop: "70px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>현재 적용된 요금표(단위:g/&#8361;)</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                <Table striped bordered hover className="admin-tb">
                                    <thead className="admin-tb-head">
                                    <tr className="admin-tb-head-line">
                                        <th style={{width: "45px"}}><b>번호</b></th>
                                        <th style={{width: "120px"}}><b>국가</b></th>
                                        <th style={{width: "75px"}}><b>500g</b></th>
                                        <th style={{width: "75px"}}><b>750g</b></th>
                                        <th style={{width: "75px"}}><b>1000g</b></th>
                                        <th style={{width: "75px"}}><b>1250g</b></th>
                                        <th style={{width: "75px"}}><b>1500g</b></th>
                                        <th style={{width: "75px"}}><b>1750g</b></th>
                                        <th style={{width: "75px"}}><b>2000g</b></th>
                                        <th style={{width: "75px"}}><b>2500g</b></th>
                                        <th style={{width: "75px"}}><b>3000g</b></th>
                                        <th style={{width: "75px"}}><b>3500g</b></th>
                                        <th style={{width: "75px"}}><b>4000g</b></th>
                                        <th style={{width: "75px"}}><b>4500g</b></th>
                                        <th style={{width: "75px"}}><b>5000g</b></th>
                                        <th style={{width: "75px"}}><b>5500g</b></th>
                                        <th style={{width: "75px"}}><b>6000g</b></th>
                                        <th style={{width: "75px"}}><b>6500g</b></th>
                                        <th style={{width: "75px"}}><b>7000g</b></th>
                                        <th style={{width: "75px"}}><b>7500g</b></th>
                                        <th style={{width: "75px"}}><b>8000g</b></th>
                                        <th style={{width: "75px"}}><b>8500g</b></th>
                                        <th style={{width: "75px"}}><b>9000g</b></th>
                                        <th style={{width: "75px"}}><b>9500g</b></th>
                                        <th style={{width: "75px"}}><b>10000g</b></th>
                                        <th style={{width: "75px"}}><b>10500g</b></th>
                                        <th style={{width: "75px"}}><b>11000g</b></th>
                                        <th style={{width: "75px"}}><b>11500g</b></th>
                                        <th style={{width: "75px"}}><b>12000g</b></th>
                                        <th style={{width: "75px"}}><b>12500g</b></th>
                                        <th style={{width: "75px"}}><b>13000g</b></th>
                                        <th style={{width: "75px"}}><b>13500g</b></th>
                                        <th style={{width: "75px"}}><b>14000g</b></th>
                                        <th style={{width: "75px"}}><b>14500g</b></th>
                                        <th style={{width: "75px"}}><b>15000g</b></th>
                                        <th style={{width: "75px"}}><b>15500g</b></th>
                                        <th style={{width: "75px"}}><b>16000g</b></th>
                                        <th style={{width: "75px"}}><b>16500g</b></th>
                                        <th style={{width: "75px"}}><b>17000g</b></th>
                                        <th style={{width: "75px"}}><b>17500g</b></th>
                                        <th style={{width: "75px"}}><b>18000g</b></th>
                                        <th style={{width: "75px"}}><b>18500g</b></th>
                                        <th style={{width: "75px"}}><b>19000g</b></th>
                                        <th style={{width: "75px"}}><b>19500g</b></th>
                                        <th style={{width: "75px"}}><b>20000g</b></th>
                                        <th style={{width: "75px"}}><b>20500g</b></th>
                                        <th style={{width: "75px"}}><b>21000g</b></th>
                                        <th style={{width: "75px"}}><b>21500g</b></th>
                                        <th style={{width: "75px"}}><b>22000g</b></th>
                                        <th style={{width: "75px"}}><b>22500g</b></th>
                                        <th style={{width: "75px"}}><b>23000g</b></th>
                                        <th style={{width: "75px"}}><b>23500g</b></th>
                                        <th style={{width: "75px"}}><b>24000g</b></th>
                                        <th style={{width: "75px"}}><b>24500g</b></th>
                                        <th style={{width: "75px"}}><b>25000g</b></th>
                                        <th style={{width: "75px"}}><b>25500g</b></th>
                                        <th style={{width: "75px"}}><b>26000g</b></th>
                                        <th style={{width: "75px"}}><b>26500g</b></th>
                                        <th style={{width: "75px"}}><b>27000g</b></th>
                                        <th style={{width: "75px"}}><b>27500g</b></th>
                                        <th style={{width: "75px"}}><b>28000g</b></th>
                                        <th style={{width: "75px"}}><b>28500g</b></th>
                                        <th style={{width: "75px"}}><b>29000g</b></th>
                                        <th style={{width: "75px"}}><b>29500g</b></th>
                                        <th style={{width: "75px"}}><b>30000g</b></th>
                                    </tr>
                                    </thead>
                                    <tbody className="admin-tb-body">
                                        {emsData.map((cData, i) => (
                                            <tr className="admin-tb-body-line" key={cData.seqNum}>
                                                <td><a>{emsData[i].seqNum}</a></td>
                                                <td>
                                                    <a>{emsData[i].countryDTO.countryNm}{emsData[i].countryDTO.countryCode === "LA"
                                                        || emsData[i].countryDTO.countryCode === "BD"
                                                        || emsData[i].countryDTO.countryCode === "IL"
                                                        || emsData[i].countryDTO.countryCode === "MX" ? " 외" : null}
                                                    </a>
                                                </td>
                                                <td><a>{emsData[i].w500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w750.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w1000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w1250.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w1500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w1750.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w2000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w2500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w3000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w3500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w4000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w4500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w5000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w5500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w6000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w6500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w7000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w7500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w8000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w8500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w9000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w9500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w10000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w10500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w11000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w11500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w12000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w12500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w13000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w13500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w14000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w14500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w15000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w15500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w16000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w16500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w17000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w17500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w18000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w18500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w19000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w19500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w20000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w20500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w21000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w21500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w22000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w22500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w23000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w23500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w24000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w24500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w25000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w25500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w26000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w26500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w27000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w27500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w28000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w28500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w29000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w29500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w30000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            <div className="font-s-14 font-t-cont">
                                <p className="font-b">번호21:[1지역] - LA:라오스, MO:마카오, KH:캄보디아, MM:미얀마, MN:몽골</p>
                                <p className="font-b">번호22:[2지역] - BD:방글라데시, BN:브루나이, IN:인도, NP:네팔, LK:스리랑카, MV:몰디브, BT:부탄</p>
                                <p className="font-b">번호23:[3지역] - BE:벨기에, DK:덴마크, FL:핀란드, NO:노르웨이, PT:포르투갈, CH:스위스, SE:스웨덴, AT:오스트리아, RO:루마니아, PL:폴란드, HU:헝가리,
                                    CZ:체코, BH:바레인, IR:이란, IQ:이라크, IL:이스라엘, JO:요르단, TR:튀르키예, KW:쿠웨이트, SA:사우디아라비아, QA:카타르, SY:시리아, PG:파푸아뉴기니, GU:괌, AF:아프가니스탄, AE:아랍에미리트</p>
                                <p className="font-b">번호24:[4지역] - EG:이집트, KE:케냐, LY:리비아, MX:멕시코, PA:파나마, AR:아르헨티나, UY:우루과이, PE:페루, CU:쿠바, HT:아이티, DO:도미니카공화국</p>
                            </div>
                        </Tab>
                        <Tab eventKey="mam-tab-sub-ship" title="&nbsp;&nbsp;&nbsp;&nbsp;SHIP&nbsp;&nbsp;&nbsp;&nbsp;">
                            <div>
                                <div className="admin-event-wrap">
                                    <div className="admin-event-wrap-btn">
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <div className="m-l-10" style={{position: "relative"}}>
                                                <input className="font-s-16" type="file" onChange={exceUploadSHIP} ref={fileSelects}
                                                       style={{border: "none", paddingLeft: "14px", height: "25px", width: "280px"}}/>
                                                <button id="ex-btn" className="admin-btn-long admin-bt-color" onClick={() => fileSelects.current.click()}
                                                        style={{position: "absolute", top: -3, right: "187px"}}>파일선택
                                                </button>
                                            </div>
                                            <label style={{fontSize: "14px", marginTop: "5px", marginRight: "5px"}}>Sheet 선택</label>
                                            {selectedSheets && (
                                                <select className="admin-combo font-s-13" id="sheet-select" value={selectedSheets.value} onChange={sheetSelectSHIP}
                                                        style={{width: "120px", height: "25px", marginTop: "2px"}}>
                                                    {excelDatas.SheetNames.map((sheetName) => (
                                                        <option key={sheetName} value={sheetName}>
                                                            {sheetName}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </div>
                                    </div>
                                    <div className="admin-event-wrap-btn">
                                        <button className="admin-btn-long admin-bt-color" onClick={excelDownSHIP}>EXCEL</button>
                                        <button className="admin-btn-long admin-bt-color" onClick={alert3}>저장</button>
                                    </div>
                                </div>
                            </div>
                            <div className="gbn-border-b" style={{width: "100%", marginTop: "18px", marginBottom: "15px"}}></div>
                            <div className="admin-event-wrap" style={{marginTop: "10px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>새로 적용할 요금표[EXCEL]</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                {selectedSheets && (
                                    <div className="div-tb m-b-10">
                                        <div className="div-tb-head ht-60">
                                            {sheetDatas[0] && sheetDatas[0].map((header) =>
                                                <div key={header} style={{width: "80px"}}>
                                                    <p>{header}</p>
                                                </div>)
                                            }
                                        </div>
                                        {sheetDatas.slice(1).map((row, i) => (
                                            <div className="div-tb-body ht-70" key={i}>
                                                {row.map((cell, j) => (
                                                    <p key={`${i}-${j}`}><a>{cell.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></p>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="admin-event-wrap" style={{marginTop: "70px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>현재 적용된 요금표(단위:g/&#8361;)</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                <div className="div-tb m-b-10">
                                    <div className="div-tb-head ht-60">
                                        <div style={{width: "60px"}}><p>지역코드</p></div>
                                        <div style={{width: "50px"}}><p>1000g</p></div>
                                        <div style={{width: "50px"}}><p>2000g</p></div>
                                        <div style={{width: "50px"}}><p>4000g</p></div>
                                        <div style={{width: "50px"}}><p>6000g</p></div>
                                        <div style={{width: "50px"}}><p>8000g</p></div>
                                        <div style={{width: "50px"}}><p>10000g</p></div>
                                        <div style={{width: "50px"}}><p>12000g</p></div>
                                        <div style={{width: "50px"}}><p>14000g</p></div>
                                        <div style={{width: "50px"}}><p>16000g</p></div>
                                        <div style={{width: "50px"}}><p>18000g</p></div>
                                        <div style={{width: "50px"}}><p>20000g</p></div>
                                    </div>
                                    {shipData.map((cData, i) => (
                                        <div key={cData.seqNum} className="div-tb-body ht-70">
                                            <div style={{width: "60px"}}><p>{shipData[i].appShipCode}</p></div>
                                            <div style={{width: "50px"}}><p>{shipData[i].w1000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{shipData[i].w2000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{shipData[i].w4000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{shipData[i].w6000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{shipData[i].w8000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{shipData[i].w10000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{shipData[i].w12000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{shipData[i].w14000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{shipData[i].w16000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{shipData[i].w18000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{shipData[i].w20000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="font-t-cont font-s-14">
                                <p className="font-b">Z1[1지역] - CN:중국, HK:홍콩, JP:일본, TW:타이완</p>
                                <p className="font-b">Z2[2지역] - BD:방글라데시, ID:인도네시아, MY:말레이시아, PH:필리핀, SG:싱가포르, VN:베트남</p>
                                <p className="font-b">Z3[3지역] - AU:호주, CA:캐나다, DE:독일, ES:스페인, FR:프랑스, GB:영국, IE:아일랜드, NL:네덜란드, NZ:뉴질랜드, PL:폴란드, US:미국</p>
                                <p className="font-b">Z4[4지역] - PE:페루</p>
                            </div>
                        </Tab>
                        <Tab eventKey="mam-tab-sub-kPack" title="&nbsp;&nbsp;&nbsp;K-Packet&nbsp;&nbsp;&nbsp;">
                            <div>
                                <div className="admin-event-wrap">
                                    <div className="admin-event-wrap-btn">
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <div className="m-l-10" style={{position: "relative"}}>
                                                <input className="font-s-16" type="file" onChange={exceUploadKP} ref={fileSelectKp}
                                                       style={{border: "none", paddingLeft: "14px", height: "25px", width: "280px"}}/>
                                                <button id="ex-btn" className="admin-btn-long admin-bt-color" onClick={() => fileSelectKp.current.click()}
                                                        style={{position: "absolute", top: -3, right: "187px"}}>파일선택
                                                </button>
                                            </div>
                                            <label style={{fontSize: "14px", marginTop: "5px", marginRight: "5px"}}>Sheet 선택</label>
                                            {selectedSheetKp && (
                                                <select className="admin-combo font-s-13" id="sheet-select" value={selectedSheetKp.value} onChange={sheetSelectKP}
                                                        style={{width: "120px", height: "25px", marginTop: "2px"}}>
                                                    {excelDataKp.SheetNames.map((sheetName) => (
                                                        <option key={sheetName} value={sheetName}>
                                                            {sheetName}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </div>
                                    </div>
                                    <div className="admin-event-wrap-btn">
                                        <button className="admin-btn-long admin-bt-color" onClick={excelDownKP}>EXCEL</button>
                                        <button className="admin-btn-long admin-bt-color" onClick={alert6}>저장</button>
                                    </div>
                                </div>
                            </div>
                            <div className="gbn-border-b" style={{width: "100%", marginTop: "18px", marginBottom: "15px"}}></div>
                            <div className="admin-event-wrap" style={{marginTop: "10px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>새로 적용할 요금표[EXCEL]</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                {selectedSheetKp && (
                                    <Table className="admin-tb" striped bordered hover>
                                        <thead className="admin-tb-head">
                                        <tr className="admin-tb-head-line">
                                            {sheetDataKp[0] && sheetDataKp[0].map((header) =>
                                                <th key={header} style={{width: "80px"}}>
                                                    <b>{header}</b>
                                                </th>)
                                            }
                                        </tr>
                                        </thead>
                                        <tbody className="admin-tb-body">
                                        {sheetDataKp.slice(1).map((row, i) => (
                                            <tr className="admin-tb-body-line" key={i}>
                                                {row.map((cell, j) => (
                                                    <td key={`${i}-${j}`}><a>{cell.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                ))}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                )}
                            </div>
                            <div className="admin-event-wrap" style={{marginTop: "70px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>현재 적용된 요금표(단위:g/&#8361;)</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                <Table striped bordered hover className="admin-tb">
                                    <thead className="admin-tb-head">
                                    <tr className="admin-tb-head-line">
                                        <th style={{width: "100px"}}><b>국가</b></th>
                                        <th style={{width: "50px"}}><b>100g</b></th>
                                        <th style={{width: "50px"}}><b>200g</b></th>
                                        <th style={{width: "50px"}}><b>300g</b></th>
                                        <th style={{width: "50px"}}><b>400g</b></th>
                                        <th style={{width: "50px"}}><b>500g</b></th>
                                        <th style={{width: "50px"}}><b>600g</b></th>
                                        <th style={{width: "50px"}}><b>700g</b></th>
                                        <th style={{width: "50px"}}><b>800g</b></th>
                                        <th style={{width: "50px"}}><b>900g</b></th>
                                        <th style={{width: "50px"}}><b>1000g</b></th>
                                        <th style={{width: "50px"}}><b>1100g</b></th>
                                        <th style={{width: "50px"}}><b>1200g</b></th>
                                        <th style={{width: "50px"}}><b>1200g</b></th>
                                        <th style={{width: "50px"}}><b>1400g</b></th>
                                        <th style={{width: "50px"}}><b>1500g</b></th>
                                        <th style={{width: "50px"}}><b>1600g</b></th>
                                        <th style={{width: "50px"}}><b>1700g</b></th>
                                        <th style={{width: "50px"}}><b>1800g</b></th>
                                        <th style={{width: "50px"}}><b>1900g</b></th>
                                        <th style={{width: "50px"}}><b>2000g</b></th>
                                    </tr>
                                    </thead>
                                    <tbody className="admin-tb-body">
                                    {kpackData.map((cData, i) => (
                                        <tr className="admin-tb-body-line" key={cData.seqNum}>
                                            <td style={{width: "100px"}}><a>{kpackData[i].countryDTO.countryNm}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w100.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w200.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w300.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w400.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w600.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w700.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w800.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w900.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w1000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w1100.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w1200.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w1300.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w1400.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w1500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w1600.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w1700.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w1800.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w1900.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w2000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </div>
                            <div className="font-s-14 font-t-cont">

                            </div>
                        </Tab>
                        <Tab eventKey="mam-tab-sub-air-s" title="&nbsp;&nbsp;항공소형&nbsp;&nbsp;">
                            <div>
                                <div className="admin-event-wrap">
                                    <div className="admin-event-wrap-btn">
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <div className="m-l-10" style={{position: "relative"}}>
                                                <input className="font-s-16" type="file" onChange={exceUploadAS} ref={fileSelectAs}
                                                       style={{border: "none", paddingLeft: "14px", height: "25px", width: "280px"}}/>
                                                <button id="ex-btn" className="admin-btn-long admin-bt-color" onClick={() => fileSelectAs.current.click()}
                                                        style={{position: "absolute", top: -3, right: "187px"}}>파일선택
                                                </button>
                                            </div>
                                            <label style={{fontSize: "14px", marginTop: "5px", marginRight: "5px"}}>Sheet 선택</label>
                                            {selectedSheetAs && (
                                                <select className="admin-combo font-s-13" id="sheet-select" value={selectedSheetAs.value} onChange={sheetSelectAS}
                                                        style={{width: "120px", height: "25px", marginTop: "2px"}}>
                                                    {excelDataAs.SheetNames.map((sheetName) => (
                                                        <option key={sheetName} value={sheetName}>
                                                            {sheetName}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </div>
                                    </div>
                                    <div className="admin-event-wrap-btn">
                                        <button className="admin-btn-long admin-bt-color" onClick={excelDownAS}>EXCEL</button>
                                        <button className="admin-btn-long admin-bt-color" onClick={alert7}>저장</button>
                                    </div>
                                </div>
                            </div>
                            <div className="gbn-border-b" style={{width: "100%", marginTop: "18px", marginBottom: "15px"}}></div>
                            <div className="admin-event-wrap" style={{marginTop: "10px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>새로 적용할 요금표[EXCEL]</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                {selectedSheetAs && (
                                    <div className="div-tb m-b-10">
                                        <div className="div-tb-head ht-60">
                                            {sheetDataAs[0] && sheetDataAs[0].map((header) =>
                                                <div key={header} style={{width: "80px"}}>
                                                    <p>{header}</p>
                                                </div>)
                                            }
                                        </div>
                                        {sheetDataAs.slice(1).map((row, i) => (
                                            <div className="div-tb-body ht-70" key={i}>
                                                {row.map((cell, j) => (
                                                    <p key={`${i}-${j}`}><a>{cell.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></p>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="admin-event-wrap" style={{marginTop: "70px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>현재 적용된 요금표(단위:g/&#8361;)</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                <div className="div-tb m-b-10">
                                    <div className="div-tb-head ht-60">
                                        <div style={{width: "60px"}}><p>지역코드</p></div>
                                        <div style={{width: "50px"}}><p>100g</p></div>
                                        <div style={{width: "50px"}}><p>200g</p></div>
                                        <div style={{width: "50px"}}><p>300g</p></div>
                                        <div style={{width: "50px"}}><p>400g</p></div>
                                        <div style={{width: "50px"}}><p>500g</p></div>
                                        <div style={{width: "50px"}}><p>600g</p></div>
                                        <div style={{width: "50px"}}><p>700g</p></div>
                                        <div style={{width: "50px"}}><p>800g</p></div>
                                        <div style={{width: "50px"}}><p>900g</p></div>
                                        <div style={{width: "50px"}}><p>1000g</p></div>
                                        <div style={{width: "50px"}}><p>1100g</p></div>
                                        <div style={{width: "50px"}}><p>1200g</p></div>
                                        <div style={{width: "50px"}}><p>1200g</p></div>
                                        <div style={{width: "50px"}}><p>1400g</p></div>
                                        <div style={{width: "50px"}}><p>1500g</p></div>
                                        <div style={{width: "50px"}}><p>1600g</p></div>
                                        <div style={{width: "50px"}}><p>1700g</p></div>
                                        <div style={{width: "50px"}}><p>1800g</p></div>
                                        <div style={{width: "50px"}}><p>1900g</p></div>
                                        <div style={{width: "50px"}}><p>2000g</p></div>
                                    </div>
                                    {airSData.map((cData, i) => (
                                        <div key={cData.seqNum} className="div-tb-body ht-70">
                                            <div style={{width: "60px"}}><p>{airSData[i].appAsCode}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w100.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w200.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w300.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w400.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w600.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w700.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w800.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w900.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w1000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w1100.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w1200.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w1300.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w1400.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w1500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w1600.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w1700.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w1800.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w1900.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w2000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="font-t-cont font-s-14">
                                {/*<p className="font-b">Z1[1지역] - CN:중국, HK:홍콩, JP:일본, TW:타이완</p>*/}
                                {/*<p className="font-b">Z2[2지역] - BD:방글라데시, ID:인도네시아, MY:말레이시아, PH:필리핀, SG:싱가포르, VN:베트남</p>*/}
                                {/*<p className="font-b">Z3[3지역] - AU:호주, CA:캐나다, DE:독일, ES:스페인, FR:프랑스, GB:영국, IE:아일랜드, NL:네덜란드, NZ:뉴질랜드, PL:폴란드, US:미국</p>*/}
                                {/*<p className="font-b">Z4[4지역] - PE:페루</p>*/}
                            </div>
                        </Tab>
                        <Tab eventKey="mam-tab-sub-dome" title="&nbsp;&nbsp;&nbsp;&nbsp;국내&nbsp;&nbsp;&nbsp;&nbsp;">
                            <div className="admin-event-wrap" style={{marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>현재 적용된 요금표[한진택배](단위:kg/&#8361;)</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                <div className="div-tb m-b-10">
                                    <div className="div-tb-head ht-60">
                                        <div style={{width: "40px"}}><p>번호</p></div>
                                        <div style={{width: "80px"}}><p>부피</p></div>
                                        <div style={{width: "80px"}}><p>~3kg</p></div>
                                        <div style={{width: "80px"}}><p>~5kg</p></div>
                                        <div style={{width: "80px"}}><p>~10kg</p></div>
                                        <div style={{width: "80px"}}><p>~20kg</p></div>
                                        <div style={{width: "80px"}}><p>25kg~</p></div>
                                    </div>
                                    {domeData.map((cData, i) => (
                                        <div key={cData.seqNum} className="div-tb-body ht-70">
                                            <div style={{width: "40px"}}><p>{domeData[i].seqNum}</p></div>
                                            <div style={{width: "80px"}}>
                                                <p>{domeData[i].volumes === "V08" ? "~80cm"
                                                    : domeData[i].volumes === "V10" ? "~100cm"
                                                        : domeData[i].volumes === "V12" ? "~120cm"
                                                            : domeData[i].volumes === "V14" ? "~140cm"
                                                                : domeData[i].volumes === "V16" ? "~160cm"
                                                                    : domeData[i].volumes === "V20" ? "~200cm": null}</p>
                                            </div>
                                            <div style={{width: "80px"}}><p>{domeData[i].w3000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "80px"}}><p>{domeData[i].w5000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "80px"}}><p>{domeData[i].w10000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "80px"}}><p>{domeData[i].w20000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "80px"}}><p>{domeData[i].w25000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
            {alertShow === true ? <MsgSelectModal alertShow={alertShow} eventHandle1={modalCancel} eventHandle2={saveExcelFileFEDEX} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {alertShow2 === true ? <MsgSelectModal alertShow={alertShow2} eventHandle1={modalCancel2} eventHandle2={saveExcelFileEMS} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {alertShow3 === true ? <MsgSelectModal alertShow={alertShow3} eventHandle1={modalCancel3} eventHandle2={saveExcelFileSHIP} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {/*{alertShow4 === true ? <MsgSelectModal alertShow={alertShow4} eventHandle1={modalCancel4} eventHandle2={saveExcelFileDHL} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}*/}
            {alertShow5 === true ? <MsgSelectModal alertShow={alertShow5} eventHandle1={modalCancel5} eventHandle2={saveExcelFileUPS} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {alertShow6 === true ? <MsgSelectModal alertShow={alertShow6} eventHandle1={modalCancel6} eventHandle2={saveExcelFileKP} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {alertShow7 === true ? <MsgSelectModal alertShow={alertShow7} eventHandle1={modalCancel7} eventHandle2={saveExcelFileAS} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
        </div>
    );
}

export default AdminCurrencyRatePg
