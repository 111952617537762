import React, {useEffect, useRef, useState} from "react";
import {AiOutlineDoubleRight} from "react-icons/ai";
import '../../../css/Common.css'
import axios from "axios";
import UserAddrBook from "./UserAddrBook";
import {useNavigate} from "react-router-dom";
import MsgSelectModal from "./MsgSelectModal";
import {BiCaretDown} from "react-icons/bi";
import commonClose from '../../../assets/img/commonClose.png'
import FedexLogo from "../../../assets/img/FedexLogo.png";
import dhlLogo from "../../../assets/img/DhlLogo.png";
import EmsLogo from "../../../assets/img/EmsLogo.png";
import upsLogo from "../../../assets/img/UpsLogo.png";
import kPacket from "../../../assets/img/Kpacket.png";
import smallPacket from "../../../assets/img/commonSmallPacket.png";
import SeaShipLogo from "../../../assets/img/SeaShipLogo.png";

import {useTranslation} from "react-i18next";
import CommonLoading from "./CommonLoading";

function DeliveryPriceDetail({closeDetail, paySeqnum, updatePayinfoList, deliveryDetailSt, deliveryDetailFalseFn, updateAppRecinfo, pathComponent, selectedShip}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');
    const adminToken = localStorage.getItem('adminToken');

    const [isLoading, setIsLoading] = useState(false);
    const [isBtDisabled, setIsBtDisabled] = useState(false);
    const [isRecinfoBtLoading, setIsRecinfoBtLoading] = useState(false);
    const [isCustomBtLoading, setIsCustomBtLoading] = useState(false);

    const [contentsNameCount, setContentsNameCount] = useState(0);
    const [contentsSeqnum, setContentsSeqnum] = useState("");
    const [contentsName, setContentsName] = useState("");
    const [totalQuantity, setTotalQuantity] = useState("");
    const [contentsDollar, setContentsDollar] = useState('');
    const [contentsWon, setContentsWon] = useState('');

    const [contentsNameError, setContentsNameError] = useState(false);
    const [totalQuantityError, setTotalQuantityError] = useState(false);
    const [contentsDollarError, setContentsDollarError] = useState(false);
    const [contentsWonError, setContentsWonError] = useState(false);

    const [ctSaveDoneMessage, setCtSaveDoneMessage] = useState(false);
    const [ctSaveFailMessage, setCtSaveFailMessage] = useState(false);

    const contentsNameLengthCheck = (e) => {
        setContentsNameCount(e.target.value.length);
    };
    const contentsNameInputRTCheck = (e) => {
        setContentsNameCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const validContentsName = ((e) => {
        const contentsNmRegex = /^[a-zA-Z ]{2,35}/; /*영문 2~25자*/
        if (!e.target.value || contentsNmRegex.test(e.target.value)) setContentsNameError(false);
        else setContentsNameError(true);
        setContentsName(e.target.value);
    })

    const validTotalQuantity = ((e) => {
        const totalQuantityRegex = /^\d{1,8}$/;
        const inputValue = e.target.value.replace(/,/g, '');

        if (!inputValue) {
            setTotalQuantityError(true);
            setTotalQuantity('');
        } else if (totalQuantityRegex.test(inputValue)) {
            setTotalQuantityError(false);
            setTotalQuantity(inputValue);
        } else {
            setTotalQuantityError(true);
        }
    })

    const validContentsDollar = ((e) => {
        const contentsDollarRegex = /^\d{1,8}$/;
        const inputValue = e.target.value.replace(/,/g, '');

        if (!inputValue) {
            setContentsDollarError(true);
            setContentsDollar('');
        } else if (contentsDollarRegex.test(inputValue)) {
            setContentsDollarError(false);
            setContentsDollar(inputValue);
        } else {
            setContentsDollarError(true);
        }
    })

    const validContentsWon = ((e) => {
        const contentsWonRegex = /^\d{1,8}$/;
        const inputValue = e.target.value.replace(/,/g, '');
        if (!inputValue) {
            setContentsWonError(true);
            setContentsWon('');
        } else if (contentsWonRegex.test(inputValue)) {
            setContentsWonError(false);
            setContentsWon(inputValue);
        } else {
            setContentsWonError(true);
        }
    })

    const insrReset = (() => {
        setContentsWon('');
        setContentsWonError(false);
    })

    const contentsNameValidCheck = (e) => {
        validContentsName(e)
        contentsNameLengthCheck(e);
        contentsNameInputRTCheck(e)
    }

    const [insuranceStatus, setInsuranceStatus] = useState(false);
    const [memberInfo, setMemberInfo] = useState([]);

    const [payList, setPayList] = useState([{}]);
    const [appReqList, setAppReqList] = useState({});
    const [prodList, setProdList] = useState([]);
    const [packList, setPackList] = useState([]);
    const [shipInfoList, setShipInfoList] = useState({});
    const [orderSeqnum, setOrderSeqnum] = useState([]);
    const [appRecSeqnum, setAppRecSeqnum] = useState(0);

    const totalFedexExpense = payList[0]?.ttFedexPrice || 0
    const totalEmsExpense = payList[0]?.ttEmsPrice || 0
    const totalSeaExpense = payList[0]?.ttSeaPrice || 0
    const totalDomExpense = payList[0]?.ttDomPrice || 0
    const totalDhlExpense = payList[0]?.ttDhlPrice || 0
    const totalUpsExpense = payList[0]?.ttUpsPrice || 0
    const totalKpackExpense = payList[0]?.ttKPackPrice || 0
    const totalAirSExpense = payList[0]?.ttAirSPrice || 0

    const totalShipExpense = (
            totalFedexExpense !== 0 ? totalFedexExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
            totalEmsExpense !== 0 ? totalEmsExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
            totalSeaExpense !== 0 ? totalSeaExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
            totalDomExpense !== 0 ? totalDomExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                totalDhlExpense !== 0 ? totalDhlExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                    totalUpsExpense !== 0 ? totalUpsExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                        totalKpackExpense !== 0 ? totalKpackExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                            totalAirSExpense !== 0 ? totalAirSExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                0)

    const [taxid, setTaxid] = useState("");
    const [recname, setRecname] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [address3, setAddress3] = useState("");
    const [city, setCity] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [telnum, setTelnum] = useState("");

    const [deliveryChoiceError, setDeliveryChoiceError] = useState("");
    const [countryError, setCountryError] = useState("");
    const [taxidError, setTaxidError] = useState("");
    const [recnameError, setRecnameError] = useState("");
    const [address1Error, setAddress1Error] = useState("");
    const [address2Error, setAddress2Error] = useState("");
    const [address3Error, setAddress3Error] = useState("");
    const [cityError, setCityError] = useState("");
    const [zipcodeError, setZipcodeError] = useState("");
    const [telnumError, setTelnumError] = useState("");

    const [recSaveDoneMessage, setRecSaveDoneMessage] = useState(false);
    const [recSaveFailMessage, setRecSaveFailMessage] = useState(false);

    const [nameInputCount, setNameInputCount] = useState(0);
    const [telnumInputCount, setTelnumInputCount] = useState(0);
    const [zipcodeInputCount, setZipcodeInputCount] = useState(0);
    const [address1InputCount, setAddress1InputCount] = useState(0);
    const [address2InputCount, setAddress2InputCount] = useState(0);
    const [address3InputCount, setAddress3InputCount] = useState(0);
    const [cityInputCount, setCityInputCount] = useState(0);


    const selectPayinfoDetail = (async () => {
        setIsLoading(true);
        try {
            await axios({
                method: 'get',
                url: '/order/selectTotalPayInfo/',
                headers: {
                    Authorization: LCAT ? LCAT : SSAT
                },
                params: {
                    seqNum: paySeqnum
                },
            })
                .then((response) => {

                    console.log("배송상세토탈페이 : ", response.data)

                    setPayList(response.data)
                    setAppReqList(response.data[0].appReqDTO)
                    setProdList(response.data[0].orderdtDTO.productsDTO)
                    setMemberInfo(response.data[0].orderdtDTO.memberDTO)
                    setPackList(response.data[0].packListDTO?.productsDTO)
                    setShipInfoList(response.data[0].shipInfoDTO)

                    setContentsNameCount(response.data[0].customsDTO.custContent.length)
                    setContentsSeqnum(response.data[0].customsDTO.seqNum)
                    setContentsName(response.data[0].customsDTO.custContent)
                    setTotalQuantity(response.data[0].customsDTO.custQty)
                    setContentsDollar(response.data[0].customsDTO.custPrice)
                    setContentsWon(response.data[0].customsDTO.insrPrice)
                    setInsuranceStatus(response.data[0].customsDTO.insrFl)

                    setAppRecSeqnum(response.data[0].appRecDTO.seqnum)
                    const countryData = {
                        value: response.data[0].appRecDTO.countryDTO.countryCode,
                        label: response.data[0].appRecDTO.countryDTO.countryNm + '(' + response.data[0].appRecDTO.countryDTO.countryEngNm + ')'
                    };
                    setSelectedCountry(countryData)
                    setTaxid(response.data[0].appRecDTO.taxid);
                    setRecname(response.data[0].appRecDTO.recname)
                    setNameInputCount(response.data[0].appRecDTO.recname.length)
                    setTelnum(response.data[0].appRecDTO.telnum)
                    setTelnumInputCount(response.data[0].appRecDTO.telnum.length)
                    setZipcode(response.data[0].appRecDTO.zipcode)
                    setZipcodeInputCount(response.data[0].appRecDTO.zipcode.length)
                    setAddress1(response.data[0].appRecDTO.address1)
                    setAddress1InputCount(response.data[0].appRecDTO.address1.length)
                    setAddress2(response.data[0].appRecDTO.address2)
                    setAddress2InputCount(response.data[0].appRecDTO.address2.length)
                    setAddress3(response.data[0].appRecDTO.address3)
                    setAddress3InputCount(response.data[0].appRecDTO.address3.length)
                    setCity(response.data[0].appRecDTO.city)
                    setCityInputCount(response.data[0].appRecDTO.city.length)

                    setOrderSeqnum(response.data[0].orderdtDTO.seqnum)
                })
        } catch (error) {
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    })

    useEffect(() => {
        selectPayinfoDetail();
        selectAllCountry();
    }, [])

    const validation = () => {
        if (!contentsName) setContentsNameError(true);
        if (!totalQuantity) setTotalQuantityError(true);
        if (!contentsDollar) setContentsDollarError(true);
        if (insuranceStatus && !contentsWon) setContentsWonError(true);
        return !!(contentsName && totalQuantity && contentsDollar && (!insuranceStatus || (insuranceStatus && contentsWon)));
    }

    const modifyCustoms = (async () => {
        if (isBtDisabled) return;
        setIsBtDisabled(true);
        setIsCustomBtLoading(true);

        try {
            await axios({
                method: 'post',
                url: '/order/modifyCustoms/',
                data: {
                    seqNum: contentsSeqnum,
                    custContent: contentsName,
                    custQty: totalQuantity,
                    custPrice: contentsDollar,
                    insrFl: insuranceStatus,
                    insrPrice: contentsWon === '' ? 0 : contentsWon
                },
                headers: {
                    Authorization: LCAT ? LCAT : SSAT
                },
                params: {
                    paySeqnum: paySeqnum,
                }
            })
                .then((response)=> {
                    setCtSaveDoneMessage(true);
                    setTimeout(() => setCtSaveDoneMessage(false), 1500);
                    updatePayinfoList(response.data[0]);
                    setPayList(response.data)
                    setIsBtDisabled(false);
                    setIsCustomBtLoading(false);
                })
                .catch((error)=> {
                    console.error(error)
                    setCtSaveFailMessage(true);
                    setTimeout(() => setCtSaveFailMessage(false), 1500);
                    setIsBtDisabled(false);
                    setIsCustomBtLoading(false);
                })
        } catch (error) {
            console.error(error)
            setCtSaveFailMessage(true);
            setTimeout(() => setCtSaveFailMessage(false), 1500);
            setIsBtDisabled(false);
            setIsCustomBtLoading(false);
        } finally {
            setIsBtDisabled(false);
            setIsCustomBtLoading(false);
        }
    })


    const valiAndCustomSave = async (e)=> {
        validation();
        if (validation()) {
            await modifyCustoms(e);
        }
    }

    // 여기서부터 국가 커스텀셀렉트
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({value: '', label: t(`ordCrLabel`)});
    const [countryShow, setCountryShow] = useState(false);
    const countryRef = useRef(null);

    const toggleCountryShow = () => {
        setCountryShow(!countryShow);
    };

    const handleCountryChange = (country) => {
        setSelectedCountry(country);
        setCountryShow(false);
        setCountryError(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setCountryShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트

    const selectAllCountry = (() => {
        axios({
            method: 'get',
            url: '/order/selectCountry',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                // const countries = response.data.map(country => ({
                //     value: country.countryCode,
                //     label: country.countryNm + '(' + country.countryEngNm + ')'
                // }));
                // setCountryList(countries);
                const countries = response.data
                    .map(country => ({
                        value: country.countryCode,
                        label: `${country.countryNm}(${country.countryEngNm})`,
                        engName: country.countryEngNm
                    }))
                    .sort((a, b) => a.engName.localeCompare(b.engName));

                setCountryList(countries.map(country => ({
                    value: country.value,
                    label: country.label
                })));
            })
            .catch((error) => {
                console.log(error)
            })
    })

    const address1Length = selectedShip === "EMS" ? 200 :
        selectedShip === "UPS" ? 200 :
            selectedShip === "Small-Packet" ? 200 :
                selectedShip === "K-Packet" ? 200 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" ? 35 :
                            selectedShip === "SHIP" ? 200 :
                                selectedShip === "국내배송" ? 100 : 35;
    const address2Length = selectedShip === "EMS" ? 50 :
        selectedShip === "UPS" ? 50 :
            selectedShip === "Small-Packet" ? 50 :
                selectedShip === "K-Packet" ? 50 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" ? 35 :
                            selectedShip === "SHIP" ? 50 :
                                selectedShip === "국내배송" ? 100 : 35;
    const address3Length = selectedShip === "EMS" ? 50 :
        selectedShip === "UPS" ? 50 :
            selectedShip === "Small-Packet" ? 50 :
                selectedShip === "K-Packet" ? 50 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" ? 35 :
                            selectedShip === "SHIP" ? 50 :
                                selectedShip === "국내배송" ? 100 : 35;
    const cityLength = selectedShip === "EMS" ? 50 :
        selectedShip === "UPS" ? 50 :
            selectedShip === "Small-Packet" ? 50 :
                selectedShip === "K-Packet" ? 50 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" ? 35 :
                            selectedShip === "SHIP" ? 50 :
                                selectedShip === "국내배송" ? 100 : 35;

    const validTextTaxid = ((e)=> {
        let taxidRegex;
        taxidRegex = /^[a-zA-Z0-9\s\-.,]{2,50}$/; /*2~50자*/
        if (!e.target.value || taxidRegex.test(e.target.value)) setTaxidError(false);
        else setTaxidError(true);
        setTaxid(e.target.value);
    })
    const validTextRecname = ((e) => {
        const recnameRegex = /^[a-zA-Z ]{2,25}/; /*영문 2~25자*/
        if (!e.target.value || recnameRegex.test(e.target.value)) setRecnameError(false);
        else setRecnameError(true);
        setRecname(e.target.value);
    })
    const validTextAddr1 = ((e) => {
        const Addr1Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address1Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr1Regex.test(e.target.value)) setAddress1Error(false);
        else setAddress1Error(true);
        setAddress1(e.target.value);
    })
    const validTextAddr2 = ((e) => {
        const Addr2Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address2Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr2Regex.test(e.target.value)) setAddress2Error(false);
        else setAddress2Error(true);
        setAddress2(e.target.value);
    })
    const validTextAddr3 = ((e) => {
        const Addr3Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address3Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr3Regex.test(e.target.value)) setAddress3Error(false);
        else setAddress3Error(true);
        setAddress3(e.target.value);
    })
    const validTextCity = ((e) => {
        const cityRegex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${cityLength}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || cityRegex.test(e.target.value)) setCityError(false);
        else setCityError(true);
        setCity(e.target.value);
    })
    const validTextZipcode = ((e) => {
        const zipcodeRegex = /^[a-zA-Z\d- ]{3,10}$/; // 암호화 // 영문 또는 숫자 또는 "-" 로만 가능하게 10자리 이하
        if (!e.target.value || zipcodeRegex.test(e.target.value)) setZipcodeError(false);
        else setZipcodeError(true);
        setZipcode(e.target.value);
    })
    const validTextTelnum = ((e) => {
        const telnumRegex = /^[\d-]{5,15}$/; // 암호화 // 숫자 또는 "-" 로만 가능하게 10자리 이하
        if (!e.target.value || telnumRegex.test(e.target.value)) setTelnumError(false);
        else setTelnumError(true);
        setTelnum(e.target.value);
    })

    const nameInputLengthCheck = (e) => {
        setNameInputCount(e.target.value.length);
    };

    const telnumInputLengthCheck = (e) => {
        setTelnumInputCount(e.target.value.length);
    };

    const zipcodeInputLengthCheck = (e) => {
        setZipcodeInputCount(e.target.value.length);
    };

    const address1InputLengthCheck = (e) => {
        setAddress1InputCount(e.target.value.length);
    };

    const address2InputLengthCheck = (e) => {
        setAddress2InputCount(e.target.value.length);
    };

    const address3InputLengthCheck = (e) => {
        setAddress3InputCount(e.target.value.length);
    };

    const cityInputLengthCheck = (e) => {
        setCityInputCount(e.target.value.length);
    };

    const nameInputRTCheck = (e) => {
        setNameInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const telnumInputRTCheck = (e) => {
        setTelnumInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const zipcodeInputRTCheck = (e) => {
        setZipcodeInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const address1InputRTCheck = (e) => {
        setAddress1InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const address2InputRTCheck = (e) => {
        setAddress2InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const address3InputRTCheck = (e) => {
        setAddress3InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const cityInputRTCheck = (e) => {
        setCityInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const nameValid = (e) => {
        validTextRecname(e)
        nameInputLengthCheck(e);
        nameInputRTCheck(e)
    }

    const telnumValid = (e) => {
        validTextTelnum(e)
        telnumInputLengthCheck(e);
        telnumInputRTCheck(e)
    }

    const zipcodeValid = (e) => {
        validTextZipcode(e)
        zipcodeInputLengthCheck(e);
        zipcodeInputRTCheck(e)
    }

    const address1Valid = (e) => {
        validTextAddr1(e)
        address1InputLengthCheck(e);
        address1InputRTCheck(e)
    }

    const address2Valid = (e) => {
        validTextAddr2(e)
        address2InputLengthCheck(e);
        address2InputRTCheck(e)
    }

    const address3Valid = (e) => {
        validTextAddr3(e)
        address3InputLengthCheck(e);
        address3InputRTCheck(e)
    }

    const cityValid = (e) => {
        validTextCity(e)
        cityInputLengthCheck(e);
        cityInputRTCheck(e)
    }

    const recValidation = () => {
        let isValid = true;

        setTaxidError(false);
        setRecnameError(false);
        setAddress1Error(false);
        setCityError(false);
        setZipcodeError(false);
        setTelnumError(false);
        setCountryError(false);

        if (selectedCountry.value === "ID" && !taxid) {
            setTaxidError(true);
            isValid = false;
        }
        if (selectedCountry.value === t(`ordCrLabel`) || !selectedCountry) {
            setCountryError(true);
            isValid = false;
        }
        if (!recname) {
            setRecnameError(true);
            isValid = false;
        }
        if (!address1) {
            setAddress1Error(true);
            isValid = false;
        }
        if (!city) {
            setCityError(true);
            isValid = false;
        }
        if (!zipcode) {
            setZipcodeError(true);
            isValid = false;
        }
        if (!telnum) {
            setTelnumError(true);
            isValid = false;
        }
        return isValid;
    };

    const valiAndApprecSave = async (e) => {
        const isValid = recValidation();
        if (isValid) {
            await modifyApprec(e);
        }
    };

    const modifyApprec = (async () => {
        if (isBtDisabled) return;
        setIsBtDisabled(true);
        setIsRecinfoBtLoading(true);
        try {
            await axios({
                method: 'post',
                url: '/order/modifyApprec/',
                data: {
                    seqnum: appRecSeqnum,
                    crCode: selectedCountry.value,
                    taxid: taxid,
                    recname: recname,
                    telnum: telnum,
                    zipcode: zipcode,
                    address1: address1,
                    address2: address2,
                    address3: address3,
                    city: city,
                },
                headers: {
                    Authorization: LCAT ? LCAT : SSAT
                },
                params: {
                    mbNum: memberInfo.mbNum
                }
            })
                .then((response)=> {
                    if (pathComponent === "/UserMyPg" || pathComponent === "/btcorpad") {
                        updateAppRecinfo(response.data);
                    }
                    setRecSaveDoneMessage(true);
                    setTimeout(() => setRecSaveDoneMessage(false), 1500);
                    setIsBtDisabled(false);
                    setIsRecinfoBtLoading(false);
                })
                .catch((error)=> {
                    console.log("수취인정보 에러 : ", error)
                    setRecSaveFailMessage(true);
                    setTimeout(() => setRecSaveFailMessage(false), 1500);
                    setIsBtDisabled(false);
                    setIsRecinfoBtLoading(false);
                })
        } catch (error) {
            console.log("수취인정보 에러 : ", error)
            setRecSaveFailMessage(true);
            setTimeout(() => setRecSaveFailMessage(false), 1500);
            setIsBtDisabled(false);
            setIsRecinfoBtLoading(false);
        } finally {
            setIsBtDisabled(false);
            setIsRecinfoBtLoading(false);
        }
    })




    const [deliveryDetailAddrSt, setDeliveryDetailAddrSt] = useState(false);

    const deliveryDetailAddrFalseFn = (() => {
        setDeliveryDetailAddrSt(false);
    })

    const addrChoiceData = ((addrSeqnum) => {
        axios({
            method: 'get',
            url: '/member/choiceAddrInfo',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                seqnum: addrSeqnum
            }
        })
            .then((response) => {
                const countryData = {
                    value: response.data.countryDTO.countryCode,
                    label: response.data.countryDTO.countryNm + '(' + response.data.countryDTO.countryEngNm + ')'
                };
                setSelectedCountry(countryData)
                setTaxid(response.data.taxid);
                setRecname(response.data.recname)
                setAddress1(response.data.address1)
                setAddress2(response.data.address2)
                setAddress3(response.data.address3)
                setCity(response.data.city)
                setZipcode(response.data.zipcode)
                setTelnum(response.data.telnum)
                setNameInputCount(response.data.recname.length)
                setAddress1InputCount(response.data.address1.length)
                setAddress2InputCount(response.data.address2.length)
                setAddress3InputCount(response.data.address3.length)
                setCityInputCount(response.data.city.length)
                setZipcodeInputCount(response.data.zipcode.length)
                setTelnumInputCount(response.data.telnum.length)
                deliveryDetailAddrFalseFn();
            })
    })

    const [deliveryDetailPayMove, setDeliveryDetailPayMove] = useState(false);
    const deliveryDetailPayMsg1 = t(`dpdMdMsg1`);
    const deliveryDetailPayMsg2 = t(`dpdMdMsg2`);

    const moveDeliveryPay = (() => {
        if(payList.length > 0) {
        navigate(`/UserMyOrderPg/${payList[0].orderdtDTO.orderNumber}`);
        }
    })

    return (
        <div className="bookContainer font-t-cont" style={{display: "flex", zIndex:"10"}}>
            <div className="bookFrame" style={{
                width: "600px",
                height: "700px",
                top: "calc(25vh - 120px)",
                left: "calc(50vw - 300px)",
                borderRadius: "5px"
            }}>
                <div style={{width: "100%", height: "100%"}}>
                    {/*상세헤더*/}
                    <div style={{height: "6%"}}>
                        <div style={{
                            height: "100%",
                            fontSize: "23px",
                            border: "solid 1px lightgray",
                            display: "flex"
                        }}>
                            <div style={{width: "92%", textAlign: "center", marginLeft: "55px"}}>
                                <p className="font-t-title font-s-17 m-t-8 wd-100pc">{deliveryDetailSt ? t(`dpdHeader1`) : t(`dpdHeader2`)}</p>
                            </div>
                            <div onClick={deliveryDetailSt ? deliveryDetailFalseFn : closeDetail} style={{width: "8%", textAlign: "center", cursor:"pointer"}}>
                                <img style={{width:"30px", height:"30px", opacity:"0.6"}} src={commonClose} alt=""/>
                            </div>
                        </div>
                    </div>
                    {/*상세바디*/}
                    <div style={{height: "94%", overflowY: "scroll"}}>
                        {/*수취인정보  ㅡㅡㅡㅡ  부터*/}
                        {!payList[0].ttDomPrice > 0 ?
                            <div style={{
                                border: "solid 1px #EAEAEA",
                                margin: "50px 50px 40px 50px",
                                borderRadius: "6px"
                            }}>
                                <div style={{
                                    borderBottom: "solid",
                                    borderWidth: "thin 1px",
                                    borderColor: "#EAEAEA",
                                    width: "100%",
                                    height: "40px",
                                    display: "flex",
                                    backgroundColor: "#F6F6F6"
                                }}>
                                <span style={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0 4px 0 10px",
                                    fontSize: "14px"
                                }}><AiOutlineDoubleRight/></span>
                                    <span style={{
                                        height: "100%",
                                        fontSize: "13px",
                                        display: "flex",
                                        alignItems: "center",
                                        fontWeight: "bold"
                                    }}>{t(`modRefInfo`)}<span style={{color: "#FF7D48"}}>({t(`essential`)})</span></span>
                                </div>
                                <div style={{height: "auto", padding: "20px"}}>
                                    <div>
                                        <div style={{height: "40px", display: "flex"}}>
                                            <span style={{
                                                width: "15%",
                                                height: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                fontSize: "13px"
                                            }}>
                                                <span style={{
                                                    color: "#FF6347",
                                                    fontSize: "13px",
                                                    marginRight: "5px"
                                                }}>*</span>
                                                {t(`modSmallMenu3`)}
                                            </span>
                                            <div className="customContent"
                                                 style={{width: "73%", marginLeft: "6px", display: "flex"}}>
                                                <div style={{width: "75%"}}>
                                                    {/*국가*/}
                                                    <div style={{width: "100%", height: "100%"}}>
                                                        {/*여기서부터 커스텀 국가셀렉트*/}
                                                        <div ref={countryRef} style={{height: "100%", width: "100%", position: "relative"}}>
                                                            <button disabled={!(payList[0].payStatus === "PS0" || adminToken)} className="selectParentBt" style={{width: "100%", height: "100%"}}
                                                                    onClick={toggleCountryShow}>
                                                                <p style={{fontSize:"14px"}}>{selectedCountry.label}</p>
                                                                <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                                                    <i style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "100%"
                                                                    }}><BiCaretDown/></i>

                                                                </span>
                                                            </button>
                                                            {countryShow ?
                                                                <div style={{
                                                                    width:"100%",
                                                                    border: "solid",
                                                                    height: "400px",
                                                                    borderWidth: "thin 1px",
                                                                    borderColor: "#EAEAEA",
                                                                    position: "absolute",
                                                                    top:"100%",
                                                                    bottom:"100%",
                                                                    zIndex:1,
                                                                    marginTop: "5px",
                                                                    overflowY: "scroll",
                                                                }}>
                                                                    {countryList.map((country, index) => (
                                                                        <div className="selectChildBt" key={index}
                                                                             onClick={() => handleCountryChange(country)} style={{
                                                                            display: "flex", padding: "10px", justifyContent: "center",
                                                                            alignItems: "center", cursor: "pointer", fontSize: "14px", borderRadius: "4px", zIndex:99,
                                                                        }}>
                                                                            <p style={{margin:"0", fontSize:"13px"}}>{country.label}</p>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        {/*여기까지 커스텀 국가셀렉트*/}
                                                    </div>
                                                </div>
                                                <div style={{width: "25%", display: "flex"}}>
                                                    <button className="ReviewPgWriteBtn" style={{
                                                        width: "100%",
                                                        fontSize: "14px",
                                                        borderWidth: "thin 1px",
                                                        borderRadius: "4px",
                                                        padding: "0",
                                                        marginLeft: "10px"
                                                    }} onClick={() => setDeliveryDetailAddrSt(true)}
                                                            disabled={!(payList[0].payStatus === "PS0" || adminToken)}>{t(`rmmBookBt`)}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        { countryError ?
                                            <div className="JoinErrorMsg" style={{margin:"5px 0 0 15%"}}>
                                                <p className="JoinErrMsg">{t(`recCrErr`)}</p>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    {selectedCountry.value === "ID" ?
                                        <div style={{marginTop:"10px"}}>
                                            <div style={{height: "40px", display: "flex"}}>
                                            <span style={{
                                                width: "15%",
                                                height: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                fontSize: "13px"
                                            }}>
                                                <span style={{
                                                    color: "#FF6347",
                                                    fontSize: "13px",
                                                    marginRight: "5px"
                                                }}>*</span>
                                                Tax ID</span>
                                                <div className="customContent"
                                                     style={{width: "73%", marginLeft: "6px"}}>
                                                    <input
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            outline: "none",
                                                            border: "solid 1px #EAEAEA",
                                                            borderRadius: "3px",
                                                            fontSize: "14px",
                                                            padding: "5px"
                                                        }} value={taxid} onChange={validTextTaxid} maxLength={50}
                                                        disabled={!(payList[0].payStatus === "PS0" || adminToken)}/>
                                                </div>
                                            </div>
                                            { taxidError ?
                                                <div className="JoinErrorMsg" style={{margin:"5px 0 0 15%"}}>
                                                    <p className="JoinErrMsg">{t(`recTaxIdErr`)}</p>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                        :
                                        null
                                    }
                                    <div style={{marginTop:"10px"}}>
                                        <div style={{height: "40px", display: "flex"}}>
                                            <span style={{
                                                width: "15%",
                                                height: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                fontSize: "13px"
                                            }}>
                                                <span style={{
                                                    color: "#FF6347",
                                                    fontSize: "13px",
                                                    marginRight: "5px"
                                                }}>*</span>
                                                {t(`joinLabel5`)}</span>
                                            <div className="customContent" style={{width: "73%", marginLeft: "6px"}}>
                                                <input
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        outline: "none",
                                                        border: "solid 1px #EAEAEA",
                                                        borderRadius: "3px",
                                                        fontSize: "14px",
                                                        padding: "5px"
                                                    }} value={recname} onChange={nameValid} maxLength={25}
                                                    disabled={!(payList[0].payStatus === "PS0" || adminToken)}/>
                                            </div>
                                            <div style={{
                                                width: "12%",
                                                display: "flex",
                                                fontSize: "13px",
                                                alignItems: "end",
                                                marginRight: "-2px"
                                            }}>
                                        <span style={{
                                            display: "flex",
                                            color: "orangered",
                                            paddingLeft: "3px"
                                        }}>{nameInputCount}</span><span
                                                style={{display: "flex"}}>/25</span>
                                            </div>
                                        </div>
                                        { recnameError ?
                                            <div className="JoinErrorMsg" style={{margin:"5px 0 0 15%"}}>
                                                <p className="JoinErrMsg">{t(`recNmErr`)}</p>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div style={{marginTop:"10px"}}>
                                        <div style={{height: "40px", display: "flex"}}>
                                            <span style={{
                                                width: "15%",
                                                height: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                fontSize: "13px"
                                            }}>
                                                <span style={{
                                                    color: "#FF6347",
                                                    fontSize: "13px",
                                                    marginRight: "5px"
                                                }}>*</span>
                                                {t(`modSmallMenu5`)}</span>
                                            <div className="customContent" style={{width: "73%", marginLeft: "6px"}}>
                                                <input
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        outline: "none",
                                                        border: "solid 1px #EAEAEA",
                                                        borderRadius: "3px",
                                                        fontSize: "14px",
                                                        padding: "5px"
                                                    }} value={telnum} onChange={telnumValid} maxLength={15}
                                                    disabled={!(payList[0].payStatus === "PS0" || adminToken)}/>
                                            </div>
                                            <div style={{
                                                width: "12%",
                                                display: "flex",
                                                fontSize: "13px",
                                                alignItems: "end",
                                                marginRight: "-2px"
                                            }}>
                                        <span style={{
                                            display: "flex",
                                            color: "orangered",
                                            paddingLeft: "3px"
                                        }}>{telnumInputCount}</span><span
                                                style={{display: "flex"}}>/15</span>
                                            </div>
                                        </div>
                                        { telnumError ?
                                            <div className="JoinErrorMsg" style={{margin:"5px 0 0 15%"}}>
                                                <p className="JoinErrMsg">{t(`recTellErr`)}</p>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div style={{marginTop:"10px"}}>
                                        <div style={{height: "40px", display: "flex"}}>
                                            <span style={{
                                                width: "15%",
                                                height: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                fontSize: "13px"
                                            }}>
                                                <span style={{
                                                    color: "#FF6347",
                                                    fontSize: "13px",
                                                    marginRight: "5px"
                                                }}>*</span>
                                                {t(`modSmallMenu6`)}</span>
                                            <div className="customContent" style={{width: "73%", marginLeft: "6px"}}>
                                                <input
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        outline: "none",
                                                        border: "solid 1px #EAEAEA",
                                                        borderRadius: "3px",
                                                        fontSize: "14px",
                                                        padding: "5px"
                                                    }} value={zipcode} onChange={zipcodeValid} maxLength={10}
                                                    disabled={!(payList[0].payStatus === "PS0" || adminToken)}/>
                                            </div>
                                            <div style={{
                                                width: "12%",
                                                display: "flex",
                                                fontSize: "13px",
                                                alignItems: "end",
                                                marginRight: "-2px"
                                            }}>
                                        <span style={{
                                            display: "flex",
                                            color: "orangered",
                                            paddingLeft: "3px"
                                        }}>{zipcodeInputCount}</span><span
                                                style={{display: "flex"}}>/10</span>
                                            </div>
                                        </div>
                                        { zipcodeError ?
                                            <div className="JoinErrorMsg" style={{margin:"5px 0 0 15%"}}>
                                                <p className="JoinErrMsg">{t(`recZipErr`)}</p>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div style={{marginTop:"10px"}}>
                                        <div style={{height: "40px", display: "flex"}}>
                                            <span style={{
                                                width: "15%",
                                                height: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                fontSize: "13px"
                                            }}>
                                                <span style={{
                                                    color: "#FF6347",
                                                    fontSize: "13px",
                                                    marginRight: "5px"
                                                }}>*</span>
                                                {t(`modSmallMenu7`)}</span>
                                            <div className="customContent" style={{width: "73%", marginLeft: "6px"}}>
                                                <input
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        outline: "none",
                                                        border: "solid 1px #EAEAEA",
                                                        borderRadius: "3px",
                                                        fontSize: "14px",
                                                        padding: "5px"
                                                    }} value={address1} onChange={address1Valid} maxLength={address1Length}
                                                    disabled={!(payList[0].payStatus === "PS0" || adminToken)}/>
                                            </div>
                                            <div style={{
                                                width: "12%",
                                                display: "flex",
                                                fontSize: "13px",
                                                alignItems: "end",
                                                marginRight: "-2px"
                                            }}>
                                        <span style={{
                                            display: "flex",
                                            color: "orangered",
                                            paddingLeft: "3px"
                                        }}>{address1InputCount}</span><span
                                                style={{display: "flex"}}>{`/${address1Length}`}</span>
                                            </div>
                                        </div>
                                        { address1Error ?
                                            <div className="JoinErrorMsg" style={{margin:"5px 0 0 15%"}}>
                                                <p className="JoinErrMsg">{`${t("recAddrErr1")} 2~${address1Length}${t("recAddrErr2")}`}</p>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div style={{marginTop:"10px"}}>
                                        <div style={{height: "40px", display: "flex"}}>
                                            <span style={{
                                                width: "15%",
                                                height: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                fontSize:"13px"
                                            }}>{t(`modSmallMenu8`)}</span>
                                            <div className="customContent" style={{width: "73%", marginLeft: "6px"}}>
                                                <input
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        outline: "none",
                                                        border: "solid 1px #EAEAEA",
                                                        borderRadius: "3px",
                                                        fontSize: "14px",
                                                        padding: "5px"
                                                    }} value={address2} onChange={address2Valid} maxLength={address2Length}
                                                    disabled={!(payList[0].payStatus === "PS0" || adminToken)}/>
                                            </div>
                                            <div style={{
                                                width: "12%",
                                                display: "flex",
                                                fontSize: "13px",
                                                alignItems: "end",
                                                marginRight: "-2px"
                                            }}>
                                        <span style={{
                                            display: "flex",
                                            color: "orangered",
                                            paddingLeft: "3px"
                                        }}>{address2InputCount}</span>
                                                <span style={{display: "flex"}}>{`/${address2Length}`}</span>
                                            </div>
                                        </div>
                                        { address2Error ?
                                            <div className="JoinErrorMsg" style={{margin:"5px 0 0 15%"}}>
                                                <p className="JoinErrMsg">{`${t("recAddrErr1")} 2~${address2Length}${t("recAddrErr2")}`}</p>
                                            </div>
                                            :
                                            null
                                        }

                                    </div>
                                    <div style={{marginTop:"10px"}}>
                                        <div style={{height: "40px", display: "flex"}}>
                                            <span style={{
                                                width: "15%",
                                                height: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                fontSize:"13px"
                                            }}>{t(`modSmallMenu9`)}</span>
                                            <div className="customContent" style={{width: "73%", marginLeft: "6px"}}>
                                                <input
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        outline: "none",
                                                        border: "solid 1px #EAEAEA",
                                                        borderRadius: "3px",
                                                        fontSize: "14px",
                                                        padding: "5px"
                                                    }} value={address3} onChange={address3Valid} maxLength={address3Length}
                                                    disabled={!(payList[0].payStatus === "PS0" || adminToken)}/>
                                            </div>
                                            <div style={{
                                                width: "12%",
                                                display: "flex",
                                                fontSize: "13px",
                                                alignItems: "end",
                                                marginRight: "-2px"
                                            }}>
                                        <span style={{
                                            display: "flex",
                                            color: "orangered",
                                            paddingLeft: "3px"
                                        }}>{address3InputCount}</span><span
                                                style={{display: "flex"}}>{`/${address3Length}`}</span>
                                            </div>
                                        </div>
                                        { address3Error ?
                                            <div className="JoinErrorMsg" style={{margin:"5px 0 0 15%"}}>
                                                <p className="JoinErrMsg">{`${t("recAddrErr1")} 2~${address3Length}${t("recAddrErr2")}`}</p>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div style={{marginTop:"10px"}}>
                                        <div style={{height: "40px", display: "flex"}}>
                                            <span style={{
                                                width: "15%",
                                                height: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                fontSize: "13px"
                                            }}>
                                            <span style={{
                                                color: "#FF6347",
                                                fontSize: "13px",
                                                marginRight: "5px"
                                            }}>*</span>
                                                {t(`modSmallMenu10`)}</span>
                                            <div className="customContent" style={{width: "73%", marginLeft: "6px"}}>
                                                <input
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        outline: "none",
                                                        border: "solid 1px #EAEAEA",
                                                        borderRadius: "3px",
                                                        fontSize: "14px",
                                                        padding: "5px"
                                                    }} value={city} onChange={cityValid} maxLength={cityLength}
                                                    disabled={!(payList[0].payStatus === "PS0" || adminToken)}/>
                                            </div>
                                            <div style={{
                                                width: "12%",
                                                display: "flex",
                                                fontSize: "13px",
                                                alignItems: "end",
                                                marginRight: "-2px"
                                            }}>
                                        <span style={{
                                            display: "flex",
                                            color: "orangered",
                                            paddingLeft: "3px"
                                        }}>{cityInputCount}</span><span
                                                style={{display: "flex"}}>{`/${cityLength}`}</span>
                                            </div>
                                        </div>
                                        {cityError ?
                                            <div className="JoinErrorMsg" style={{margin: "5px 0 0 15%"}}>
                                                <p className="JoinErrMsg">{`${t("recAddrErr1")} 2~${cityLength}${t("recAddrErr2")}`}</p>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    {recSaveDoneMessage &&
                                        <div className="JoinErrorMsg" style={{textAlign: "center"}}>
                                            <p className="JoinErrMsg">&nbsp;{t(`dpdSvMsg1`)} &#128521;</p>
                                        </div>
                                    }
                                    {recSaveFailMessage &&
                                        <div className="JoinErrorMsg" style={{textAlign: "center"}}>
                                            <p className="JoinErrMsg">&nbsp;{t(`dpdSvMsg2`)} &#128514;</p>
                                        </div>
                                    }
                                    <div style={{
                                        height: "50px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: "10px"
                                    }}>
                                        <style>
                                            {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
                                        </style>
                                        <button className="orderItemHandleBt user-center" style={{width: "80px"}}
                                                onClick={valiAndApprecSave}
                                                disabled={isBtDisabled || (!adminToken && payList[0].payStatus !== "PS0")}>
                                            {isRecinfoBtLoading ? (
                                                <div className="smallSpinner"></div>
                                            ) : (
                                                <div>{t(`saveBt`)}</div>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        {/*까지*/}
                        {!payList[0].ttDomPrice > 0 ?
                        <div style={{
                            border: "solid 1px #EAEAEA",
                            margin: "60px 50px 40px 50px",
                            borderRadius: "6px"
                        }}>
                            <div style={{
                                borderBottom: "solid 1px #EAEAEA",
                                width: "100%",
                                height: "40px",
                                display: "flex",
                                backgroundColor: "#F6F6F6"
                            }}>
                                <span style={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0 4px 0 10px",
                                    fontSize: "14px"
                                }}><AiOutlineDoubleRight/></span>
                                <span style={{
                                    height: "100%",
                                    fontSize: "13px",
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: "bold"
                                }}>{t(`dpdHeader3`)}<span style={{color: "#FF7D48"}}>({t(`essential`)})</span></span>
                            </div>
                            <div style={{height: "auto", padding: "20px"}}>
                                <div style={{
                                    marginBottom: "15px",
                                    paddingBottom: "20px",
                                    height: "auto",
                                    borderBottom: "solid 1px #EAEAEA",
                                }}>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <span style={{
                                            width: "20%",
                                            height: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize:"13px"
                                        }}>{t(`dpdHeader4`)}</span>
                                        <div className="customContent" style={{width: "68%", marginLeft: "6px"}}><input
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                outline: "none",
                                                border: "solid 1px #EAEAEA",
                                                borderRadius: "3px",
                                                fontSize: "14px",
                                                padding: "5px"
                                            }} value={contentsName} onChange={contentsNameValidCheck} maxLength={50} disabled={!(payList[0].payStatus === "PS0" || adminToken)}/>
                                        </div>
                                        <div style={{
                                            width: "12%",
                                            display: "flex",
                                            fontSize: "13px",
                                            alignItems: "end",
                                            marginRight: "-2px"
                                        }}>
                                        <span style={{
                                            display: "flex",
                                            color: "orangered",
                                            paddingLeft: "3px"
                                        }}>{contentsNameCount}</span><span
                                            style={{display: "flex"}}>/35</span>
                                        </div>
                                    </div>
                                    { contentsNameError ?
                                        <div className="JoinErrorMsg" style={{margin:"5px 0 0 21%"}}>
                                            <p className="JoinErrMsg">{t(`dpdErr1`)}</p>
                                        </div>
                                        :
                                        null
                                    }
                                    <div style={{height: "40px", display: "flex", marginTop:"10px"}}>
                                        <span style={{
                                            width: "20%",
                                            height: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize:"13px"
                                        }}>{t(`dpdHeader5`)}</span>
                                        <div className="customContent" style={{width: "68%", marginLeft: "6px"}}><input
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                outline: "none",
                                                border: "solid 1px #EAEAEA",
                                                borderRadius: "3px",
                                                fontSize: "14px",
                                                padding: "5px"
                                            }} value={totalQuantity} onChange={validTotalQuantity} maxLength={5} disabled={!(payList[0].payStatus === "PS0" || adminToken)}/></div>
                                    </div>
                                    { totalQuantityError ?
                                        <div className="JoinErrorMsg" style={{margin:"5px 0 0 21%"}}>
                                            <p className="JoinErrMsg">{t(`dpdErr2`)}</p>
                                        </div>
                                        :
                                        null
                                    }
                                    <div style={{height: "40px", display: "flex", marginTop:"10px"}}>
                                        <span style={{
                                            width: "20%",
                                            height: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize:"13px"
                                        }}>{t(`dpdHeader6`)}(USD)</span>
                                        <div className="customContent" style={{width: "68%", marginLeft: "6px"}}><input
                                            type="text"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                outline: "none",
                                                border: "solid 1px #EAEAEA",
                                                borderRadius: "3px",
                                                fontSize: "14px",
                                                padding: "5px"
                                            }} value={contentsDollar ? parseInt(contentsDollar).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}
                                                onChange={validContentsDollar} maxLength={4} placeholder={t(`dpdErr3`)}
                                            disabled={!(payList[0].payStatus === "PS0" || adminToken)}/>
                                        </div>
                                    </div>
                                    { contentsDollarError ?
                                        <div className="JoinErrorMsg" style={{margin:"5px 0 0 21%"}}>
                                            <p className="JoinErrMsg">{t(`dpdErr3`)}</p>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                <div style={{height: "40px", display: "flex", marginTop: "10px"}}>
                                    <span style={{
                                        width: "20%",
                                        height: "100%",
                                        display: "flex",
                                        marginTop: "10px",
                                        fontSize:"13px"
                                    }}>{t(`dpdHeader7`)}</span>
                                    <div style={{width: "35%", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <label>
                                                <input className="radioButton" type="radio" name="insurance"
                                                       checked={!insuranceStatus}
                                                       onChange={() => setInsuranceStatus(false)}
                                                       onClick={insrReset}
                                                       style={{height: "15px"}}
                                                       disabled={!(payList[0].payStatus === "PS0" || adminToken)}/>
                                                <span style={{
                                                    verticalAlign: "middle",
                                                    marginLeft: "5px",
                                                    fontSize: "15px"
                                                }}>{t(`dpdLabel1`)}</span>
                                            </label>
                                        </div>
                                        <div style={{
                                            width: "50%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <label>
                                                <input className="radioButton" type="radio" name="insurance"
                                                       checked={insuranceStatus}
                                                       onChange={() => setInsuranceStatus(true)}
                                                       style={{height: "15px"}}
                                                       disabled={!(payList[0].payStatus === "PS0" || adminToken)}/>
                                                <span style={{
                                                    verticalAlign: "middle",
                                                    marginLeft: "5px",
                                                    fontSize: "15px"
                                                }}>{t(`dpdLabel2`)}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div style={{height: "40px", display: "flex", margin:"10px 0"}}>
                                    <span style={{
                                        width: "20%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize:"13px"
                                    }}>{t(`dpdHeader8`)}(KRW)</span>
                                    <div className="customContent" style={{width: "68%", marginLeft: "6px"}}><input
                                        type="text"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            outline: "none",
                                            border: "solid 1px #EAEAEA",
                                            borderRadius: "3px",
                                            fontSize: "13px",
                                            padding: "5px"
                                        }}
                                        value={contentsWon ? parseInt(contentsWon).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}
                                        onChange={validContentsWon} maxLength={8} placeholder={t(`dpdErr4`)}
                                    disabled={!insuranceStatus || !(payList[0].payStatus === "PS0" || adminToken)}/></div>

                                </div>
                                { contentsWonError ?
                                    <div className="JoinErrorMsg" style={{margin:"5px 0 0 21%"}}>
                                        <p className="JoinErrMsg">{t(`dpdErr4`)}</p>
                                    </div>
                                    :
                                    null
                                }
                                { ctSaveDoneMessage &&
                                    <div className="JoinErrorMsg" style={{textAlign:"center"}}>
                                        <p className="JoinErrMsg">&nbsp;{t(`dpdSvMsg1`)} &#128521;</p>
                                    </div>
                                    }
                                { ctSaveFailMessage &&
                                    <div className="JoinErrorMsg" style={{textAlign: "center"}}>
                                        <p className="JoinErrMsg">&nbsp;{t(`dpdSvMsg2`)} &#128514;</p>
                                    </div>
                                }
                                <div style={{
                                    height: "50px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                    <style>
                                        {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
                                    </style>
                                    <button className="orderItemHandleBt user-center" style={{width: "80px"}}
                                            onClick={valiAndCustomSave}
                                            disabled={isBtDisabled || (!adminToken && payList[0].payStatus !== "PS0")}>
                                        {isCustomBtLoading ? (
                                            <div className="smallSpinner"></div>
                                        ) : (
                                            <div>{t(`saveBt`)}</div>
                                        )}
                                    </button>
                                </div>

                            </div>
                        </div>
                            :
                            null
                        }
                        <div style={{
                            border: "solid 1px #EAEAEA",
                            margin: "60px 50px 40px 50px",
                            borderRadius: "6px"
                        }}>
                            <div style={{
                                borderBottom: "solid 1px #EAEAEA",
                                width: "100%",
                                height: "40px",
                                display: "flex",
                                backgroundColor: "#F6F6F6"
                            }}>
                                <span style={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0 4px 0 10px",
                                    fontSize: "14px"
                                }}><AiOutlineDoubleRight/></span>
                                <span style={{
                                    fontSize: "13px",
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: "bold"
                                }}>{t(`dpdHeader9`)}</span>
                            </div>
                            <div>
                                <div style={{
                                    height: "30px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    wordBreak: "break-all",
                                    fontSize: "14px",
                                    margin: "10px"
                                }}><span
                                    style={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}>{t(`realWt`)}</span><span>{shipInfoList.weight}kg</span>
                                </div>
                                <div style={{
                                    height: "30px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    wordBreak: "break-all",
                                    fontSize: "14px",
                                    margin: "10px"
                                }}>
                                    <span>{t(`volWt`)}[{shipInfoList.wid}cm*{shipInfoList.ver}cm*{shipInfoList.hei}cm]</span>
                                    <span>
                                        {payList[0]?.orderdtDTO?.shipco === "FedEx" || payList[0]?.orderdtDTO?.shipco === "DHL" ?
                                            shipInfoList.fedexWeight : shipInfoList.emsWeight}kg
                                    </span>
                                </div>
                                <div style={{
                                    height: "30px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    wordBreak: "break-all",
                                    fontSize: "14px",
                                    margin: "10px 10px 0 10px"
                                }}><span>{t(`appWt`)}</span><span
                                    style={{color: "#FFAF34", fontWeight: "bold"}}>
                                    {payList[0]?.orderdtDTO?.shipco === "FedEx" || payList[0]?.orderdtDTO?.shipco === "DHL" ?
                                        shipInfoList.fedexApyWeight : shipInfoList.emsApyWeight}kg
                                </span>
                                </div>
                            </div>
                        </div>
                        <div style={{
                            border: "solid 1px #EAEAEA",
                            margin: "60px 50px 40px 50px",
                            borderRadius: "6px"
                        }}>
                            <div style={{
                                borderBottom: "solid 1px #EAEAEA",
                                width: "100%",
                                height: "40px",
                                display: "flex",
                                backgroundColor: "#F6F6F6"
                            }}>
                                <span style={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0 4px 0 10px",
                                    fontSize: "14px"
                                }}><AiOutlineDoubleRight/></span>
                                <span style={{
                                    height: "100%",
                                    fontSize: "13px",
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: "bold"
                                }}>{t(`modPlHeader3`)}</span>
                            </div>

                            {/*FedEx*/}
                            {payList[0]?.ttFedexPrice > 0 &&
                                <div style={{margin: "10px"}}>
                                    <div style={{
                                        width: "20%",
                                        height: "35px",
                                        borderBottom: "solid 1px dimgrey",
                                        display: "flex",
                                        marginBottom: "5px",
                                    }}><span style={{
                                        fontSize: "14px",
                                        height: "40px",
                                        paddingTop: "8px",
                                        fontWeight: "bold"
                                    }}>FedEx</span></div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        marginTop: "10px",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}>
                                        <span>{t(`modPlHeader3`)}</span><span>{payList[0]?.fedexPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}>
                                        <span>{t(`fuelScg`)}</span><span>{payList[0]?.fedexFuelSurch?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}>
                                        <span>{t(`deliveryFee`)}</span><span>{payList[0]?.fedexAddFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}>
                                        <span>{t(`etcArea`)}</span><span>{payList[0]?.outOfArea?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}>
                                        <span>{t(`insrFee`)}</span><span>{payList[0]?.fedexInsrFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}><span>{t(`modSHeader7`)}</span><span style={{
                                        color: totalFedexExpense > 0 ? "darkorange" : "black",
                                        fontWeight: totalFedexExpense > 0 ? "bold" : "normal"
                                    }}>{totalFedexExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                        // 추후 택배사와 API 연동예정
                                    }}><span>{t(`prodTrNum`)}</span><span style={{fontSize:"13px"}}>{shipInfoList.outWayNum || ''}</span></div>
                                </div>
                            }

                            {/*UPS*/}
                            {payList[0]?.ttUpsPrice > 0 &&
                                <div style={{margin: "10px"}}>
                                    <div style={{
                                        width: "20%",
                                        height: "35px",
                                        borderBottom: "solid 1px darkgray",
                                        display: "flex",
                                        marginBottom: "5px",
                                    }}><span style={{
                                        fontSize: "14px",
                                        height: "40px",
                                        paddingTop: "8px",
                                        fontWeight: "bold"
                                    }}>UPS</span></div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        marginTop: "10px",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}>
                                        <span>{t(`modPlHeader3`)}</span><span>{payList[0]?.upsPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}>
                                        <span>{t(`deliveryFee`)}</span><span>{payList[0]?.upsAddFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}>
                                        <span>{t(`insrFee`)}</span><span>{payList[0]?.upsInsrFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}><span>{t(`modSHeader7`)}</span>
                                        <span style={{
                                            color: totalUpsExpense > 0 ? "darkorange" : "black",
                                            fontWeight: totalUpsExpense > 0 ? "bold" : "normal"
                                        }}>{totalUpsExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}><span>{t(`prodTrNum`)}</span><span style={{fontSize:"13px"}}>{shipInfoList.outWayNum || ''}</span></div>
                                </div>
                            }

                            {/*/!*DHL*!/*/}
                            {/*{payList[0]?.ttDhlPrice > 0 &&*/}
                            {/*    <div style={{margin: "10px"}}>*/}
                            {/*        <div style={{*/}
                            {/*            width: "20%",*/}
                            {/*            height: "35px",*/}
                            {/*            borderBottom: "solid 1px darkgray",*/}
                            {/*            display: "flex",*/}
                            {/*            marginBottom: "5px",*/}
                            {/*        }}><span style={{*/}
                            {/*            fontSize: "14px",*/}
                            {/*            height: "40px",*/}
                            {/*            paddingTop: "8px",*/}
                            {/*            fontWeight: "bold"*/}
                            {/*        }}>DHL</span></div>*/}
                            {/*        <div style={{*/}
                            {/*            display: "flex",*/}
                            {/*            justifyContent: "space-between",*/}
                            {/*            wordBreak: "break-all",*/}
                            {/*            marginTop: "10px",*/}
                            {/*            fontSize: "13px",*/}
                            {/*            marginBottom: "6px"*/}
                            {/*        }}>*/}
                            {/*            <span>{t(`modPlHeader3`)}</span><span>{payList[0]?.dhlPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>*/}
                            {/*        </div>*/}
                            {/*        <div style={{*/}
                            {/*            display: "flex",*/}
                            {/*            justifyContent: "space-between",*/}
                            {/*            wordBreak: "break-all",*/}
                            {/*            fontSize: "13px",*/}
                            {/*            marginBottom: "6px"*/}
                            {/*        }}>*/}
                            {/*            <span>{t(`fuelScg`)}</span><span>{payList[0]?.dhlFuelSurch?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>*/}
                            {/*        </div>*/}
                            {/*        <div style={{*/}
                            {/*            display: "flex",*/}
                            {/*            justifyContent: "space-between",*/}
                            {/*            wordBreak: "break-all",*/}
                            {/*            fontSize: "13px",*/}
                            {/*            marginBottom: "6px"*/}
                            {/*        }}>*/}
                            {/*            <span>{t(`deliveryFee`)}</span><span>{payList[0]?.dhlAddFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>*/}
                            {/*        </div>*/}
                            {/*        <div style={{*/}
                            {/*            display: "flex",*/}
                            {/*            justifyContent: "space-between",*/}
                            {/*            wordBreak: "break-all",*/}
                            {/*            fontSize: "13px",*/}
                            {/*            marginBottom: "6px"*/}
                            {/*        }}>*/}
                            {/*            <span>{t(`insrFee`)}</span><span>{payList[0]?.dhlInsrFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>*/}
                            {/*        </div>*/}
                            {/*        <div style={{*/}
                            {/*            display: "flex",*/}
                            {/*            justifyContent: "space-between",*/}
                            {/*            wordBreak: "break-all",*/}
                            {/*            fontSize: "13px",*/}
                            {/*            marginBottom: "6px"*/}
                            {/*        }}><span>{t(`modSHeader7`)}</span>*/}
                            {/*            <span style={{*/}
                            {/*                color: totalDhlExpense > 0 ? "darkorange" : "black",*/}
                            {/*                fontWeight: totalDhlExpense > 0 ? "bold" : "normal"*/}
                            {/*            }}>{totalDhlExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>*/}
                            {/*        </div>*/}
                            {/*        <div style={{*/}
                            {/*            display: "flex",*/}
                            {/*            justifyContent: "space-between",*/}
                            {/*            wordBreak: "break-all",*/}
                            {/*            fontSize: "13px",*/}
                            {/*            marginBottom: "6px"*/}
                            {/*        }}><span>{t(`prodTrNum`)}</span><span*/}
                            {/*            style={{fontSize: "13px"}}>{shipInfoList.outWayNum || ''}</span></div>*/}
                            {/*    </div>*/}
                            {/*}*/}

                            {/*EMS*/}
                            {payList[0]?.ttEmsPrice > 0 &&
                                <div style={{margin: "10px"}}>
                                    <div style={{
                                        width: "20%",
                                        height: "35px",
                                        borderBottom: "solid 1px darkgray",
                                        display: "flex",
                                        marginBottom: "5px",
                                    }}><span style={{
                                        fontSize: "14px",
                                        height: "40px",
                                        paddingTop: "8px",
                                        fontWeight: "bold"
                                    }}>EMS</span></div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        marginTop: "10px",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}>
                                        <span>{t(`modPlHeader3`)}</span><span>{payList[0]?.emsPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}>
                                        <span>{t(`deliveryFee`)}</span><span>{payList[0]?.emsAddFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}>
                                        <span>{t(`insrFee`)}</span><span>{payList[0]?.emsInsrFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}><span>{t(`modSHeader7`)}</span>
                                        <span style={{
                                        color: totalEmsExpense > 0 ? "darkorange" : "black",
                                        fontWeight: totalEmsExpense > 0 ? "bold" : "normal"
                                    }}>{totalEmsExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}><span>{t(`prodTrNum`)}</span><span style={{fontSize:"13px"}}>{shipInfoList.outWayNum || ''}</span></div>
                                </div>
                            }
                            {/*K-Packet*/}
                            {payList[0]?.ttKPackPrice > 0 &&
                                <div style={{margin: "10px"}}>
                                    <div style={{
                                        width: "20%",
                                        height: "35px",
                                        borderBottom: "solid 1px darkgray",
                                        display: "flex",
                                        marginBottom: "5px",
                                    }}><span style={{
                                        fontSize: "14px",
                                        height: "40px",
                                        paddingTop: "8px",
                                        fontWeight: "bold"
                                    }}>K-Packet</span></div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        marginTop: "10px",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}>
                                        <span>{t(`modPlHeader3`)}</span><span>{payList[0]?.kPackPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}>
                                        <span>{t(`deliveryFee`)}</span><span>{payList[0]?.kPackAddFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}>
                                        <span>{t(`insrFee`)}</span><span>{payList[0]?.kPackInsrFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}><span>{t(`modSHeader7`)}</span>
                                        <span style={{
                                            color: totalKpackExpense > 0 ? "darkorange" : "black",
                                            fontWeight: totalKpackExpense > 0 ? "bold" : "normal"
                                        }}>{totalKpackExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}><span>{t(`prodTrNum`)}</span><span style={{fontSize:"13px"}}>{shipInfoList.outWayNum || ''}</span></div>
                                </div>
                            }

                            {/*항공소형포장물*/}
                            {payList[0]?.ttAirSPrice > 0 &&
                                <div style={{margin: "10px"}}>
                                    <div style={{
                                        width: "20%",
                                        height: "35px",
                                        borderBottom: "solid 1px darkgray",
                                        display: "flex",
                                        marginBottom: "5px",
                                    }}><span style={{
                                        fontSize: "14px",
                                        height: "40px",
                                        paddingTop: "8px",
                                        fontWeight: "bold"
                                    }}>Small-Packet</span></div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        marginTop: "10px",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}>
                                        <span>{t(`modPlHeader3`)}</span><span>{payList[0]?.airSPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}>
                                        <span>{t(`deliveryFee`)}</span><span>{payList[0]?.airSAddFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}>
                                        <span>{t(`insrFee`)}</span><span>{payList[0]?.airSInsrFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}><span>{t(`modSHeader7`)}</span>
                                        <span style={{
                                            color: totalAirSExpense > 0 ? "darkorange" : "black",
                                            fontWeight: totalAirSExpense > 0 ? "bold" : "normal"
                                        }}>{totalAirSExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}><span>{t(`prodTrNum`)}</span><span style={{fontSize:"13px"}}>{shipInfoList.outWayNum || ''}</span></div>
                                </div>
                            }

                            {/*선편배송*/}
                            {payList[0]?.ttSeaPrice > 0 &&
                                <div style={{margin: "10px"}}>
                                    <div style={{
                                        width: "20%",
                                        height: "35px",
                                        borderBottom: "solid 1px darkgray",
                                        display: "flex",
                                        marginBottom: "5px",
                                    }}><span style={{
                                        fontSize: "14px",
                                        height: "40px",
                                        paddingTop: "8px",
                                        fontWeight: "bold"
                                    }}>Ship(Sea)</span></div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        marginTop: "10px",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}>
                                        <span>{t(`modSHeader6`)}</span>
                                        <span>{payList[0]?.seaPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}>
                                        <span>{t(`deliveryFee`)}</span><span>{payList[0]?.seaAddFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}>
                                        <span>{t(`insrFee`)}</span><span>{payList[0]?.seaInsrFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}><span>{t(`modSHeader7`)}</span><span style={{
                                        color: totalSeaExpense > 0 ? "darkorange" : "black",
                                        fontWeight: totalSeaExpense > 0 ? "bold" : "normal"
                                    }}>{totalSeaExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"

                                    }}><span>{t(`prodTrNum`)}</span><span style={{fontSize:"13px"}}>{shipInfoList.outWayNum || ''}</span></div>
                                </div>
                            }

                            {/*국내배송*/}
                            {totalDomExpense === 0 &&
                                <div style={{margin: "10px"}}>

                                    <div style={{
                                        width: "20%",
                                        height: "35px",
                                        borderBottom: "solid",
                                        borderWidth: "thin 1px",
                                        borderColor: "darkgray",
                                        display: "flex",
                                        marginBottom: "5px",
                                    }}>
                                        <span style={{
                                        fontSize: "14px",
                                        height: "40px",
                                        paddingTop: "8px",
                                        fontWeight: "bold"
                                    }}>{t(`domesticTitle`)}</span>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                    }}><span>{t(`ttDmShipPr`)}</span><span style={{
                                        color: totalDomExpense > 0 ? "darkorange" : "black",
                                        fontWeight: totalDomExpense > 0 ? "bold" : "normal"
                                    }}>{totalDomExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        wordBreak: "break-all",
                                        fontSize: "13px",
                                        marginBottom: "6px"
                                        // 추후 택배사와 API 연동예정
                                    }}><span>{t(`prodTrNum`)}</span><span style={{fontSize:"13px"}}>{shipInfoList.outWayNum || ''}</span></div>
                                </div>
                            }
                        </div>
                        {/*결제정보(결제상태가 완료일 경우에만 보이게 하기*/}
                            <div style={{margin: "60px 50px 0 50px"}}>
                                <div style={{
                                    height: "40px",
                                    borderBottom: "solid 1px #EAEAEA",
                                    display: "flex",
                                    marginBottom: "5px"
                                }}><p style={{fontSize: "16px", height: "100%", paddingTop: "8px"}}>{t(`modHeader10`)}</p></div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    wordBreak: "break-all",
                                    padding: "5px",
                                    fontSize: "13px",
                                    marginTop: "6px"
                                }}><span>{t(`myUseDep`)}</span>
                                    <span>{payList[0]?.depUsePrice !== 0 ?
                                        <span>
                                            - {payList[0]?.depUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </span> : 0}
                                    </span>
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    wordBreak: "break-all",
                                    padding: "5px",
                                    fontSize: "13px"
                                }}><span>{t(`myUsePt`)}</span><span>{payList[0]?.pointUsePrice !== 0 ?
                                    <span>- {payList[0]?.pointUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : 0}</span></div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    wordBreak: "break-all",
                                    padding: "5px",
                                    fontSize: "13px"
                                }}><span>{t(`dpdCpNm`)}</span><span>{payList[0]?.couponUsePrice !== 0 ?
                                    <span>- {payList[0]?.couponUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : 0}</span>
                                </div>
                                <div style={{
                                    height: "50px",
                                    borderBottom: "solid 1px #EAEAEA",
                                    display: "flex",
                                    alignItems: "end",
                                    justifyContent: "space-between",
                                    wordBreak: "break-all",
                                    padding: "5px",
                                    marginBottom: "50px"
                                }}><span style={{fontSize: "16px", fontWeight: "bold"}}>{t(`modFinalPay`)}</span>
                                        <span style={{
                                            color: "#FFAF34",
                                            fontWeight: "bold"
                                        }}>{payList[0]?.payStatus === "PS0" ? 0 : payList[0]?.lastPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </span>

                                </div>
                            </div>
                        <div style={{
                            border: "solid 1px #EAEAEA",
                            margin: "60px 50px 40px 50px",
                            borderRadius: "6px"
                        }}>
                            <div style={{
                                borderBottom: "solid 1px #EAEAEA",
                                width: "100%",
                                height: "40px",
                                display: "flex",
                                backgroundColor: "#F6F6F6"
                            }}>
                                <span style={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0 4px 0 10px",
                                    fontSize: "14px"
                                }}><AiOutlineDoubleRight/></span>
                                <span style={{
                                    height: "100%",
                                    fontSize: "13px",
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: "bold"
                                }}>{t(`appReq`)}</span>
                            </div>
                            <div style={{
                                display: "flex",
                                minHeight: "40px",
                                borderBottom: "solid 1px #EAEAEA",
                            }}>
                                <div style={{
                                    width: "20%",
                                    borderRight: "solid 1px #EAEAEA",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "14px"
                                }}><p style={{margin:"0", fontWeight:"lighter", color:"black", fontSize:"13px"}}>{t(`reqOp`)}</p></div>
                                <div style={{
                                    width: "80%",
                                    paddingLeft: "5px",
                                    display: "flex",
                                    flexDirection: "column",
                                    fontSize: "13px",
                                    margin: "10px"
                                }}>
                                    {appReqList.tagRemove &&
                                        <span style={{display: "flex"}}>- {t(`modReqCont1`)}</span>
                                    }
                                    {appReqList.shoesBoxRemove  &&
                                        <span style={{display: "flex"}}>- {t(`modReqCont2`)}</span>
                                    }
                                    {appReqList.hangerRemove &&
                                        <span style={{display: "flex"}}>- {t(`modReqCont3`)}</span>
                                    }
                                    {appReqList.compressionPack &&
                                        <span style={{display: "flex"}}>- {t(`modReqCont4`)}</span>
                                    }
                                    {appReqList.prodBoxRemove &&
                                        <span style={{display: "flex"}}>- {t(`modReqCont5`)}</span>
                                    }
                                    {appReqList.packingHold &&
                                        <span style={{display: "flex"}}>- {t(`modReqCont6`)}</span>
                                    }
                                    {appReqList.shipPriceDeposipay &&
                                        <span style={{display: "flex"}}>- {t(`modReqCont7`)}</span>
                                    }
                                    {appReqList.shipPriceMessenger  &&
                                        <span style={{display: "flex"}}>- {t(`modReqCont8`)}</span>
                                    }
                                </div>
                            </div>
                            <div style={{display: "flex", minHeight: "40px"}}>
                                <div style={{
                                    width: "20%",
                                    borderRight: "solid 1px #EAEAEA",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "14px"
                                }}><p style={{margin:"0", fontWeight:"lighter", color:"black", fontSize:"13px"}}>{t(`modSmallMenu13`)}</p></div>
                                <div style={{
                                    width: "80%",
                                    paddingLeft: "5px",
                                    display: "flex",
                                    flexDirection: "column",
                                    fontSize: "13px",
                                    margin: "10px",
                                }}>
                                    <span style={{display: "flex"}}>{appReqList.detailReq}</span>
                                </div>
                            </div>
                        </div>
                        <div style={{
                            border: "solid 1px #EAEAEA",
                            margin: "60px 50px 60px 50px",
                            borderRadius: "6px"
                        }}>
                            <div style={{
                                borderBottom: "solid 1px #EAEAEA",
                                width: "100%",
                                height: "40px",
                                display: "flex",
                                backgroundColor: "#F6F6F6"
                            }}>
                                <span style={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0 4px 0 10px",
                                    fontSize: "14px"
                                }}><AiOutlineDoubleRight/></span>
                                <span style={{
                                    height: "100%",
                                    fontSize: "13px",
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: "bold"
                                }}>{t(`packList`)}</span>
                            </div>
                            <div style={{
                                height: "50px",
                                display: "flex",
                                borderBottom: "solid 1px #EAEAEA",
                            }}>
                                <div style={{
                                    width: "10%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}><p style={{marginTop: "12px"}}></p></div>
                                <div style={{width: "35%", display: "flex", justifyContent: "center"}}><p
                                    style={{marginTop: "12px"}}>{t(`prodNm`)}</p></div>
                                <div style={{width: "25%", display: "flex", justifyContent: "center"}}><p
                                    style={{marginTop: "12px"}}>{t(`prodOp`)}</p></div>
                                <div style={{width: "10%", display: "flex", justifyContent: "center"}}><p
                                    style={{marginTop: "12px"}}>{t(`prodQy`)}</p></div>
                                <div style={{width: "20%", display: "flex", justifyContent: "center"}}><p
                                    style={{marginTop: "12px"}}>{t(`prodPr`)}</p></div>
                            </div>
                            {/*추후 여기에 해당 금액 관련한 상품데이터들 .map으로 가져오기*/}
                            {packList && packList.map((pack, index) => (
                                <div key={index} style={{height: "40px", display: "flex"}}>
                                    <div style={{
                                        width: "10%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: "13px"
                                    }}>{index + 1}
                                    </div>
                                    <div style={{width: "35%", display: "flex", alignItems: "center", fontSize: "13px"}}>
                                        <span style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{pack.name}</span>
                                    </div>
                                    <div style={{
                                        width: "25%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontSize: "13px"
                                    }}><span style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{pack.option}</span>
                                    </div>
                                    <div style={{
                                        width: "10%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontSize: "13px"
                                    }}><span>{pack.quantity?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></div>
                                    <div style={{
                                        width: "20%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontSize: "13px"
                                    }}><span>{pack.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></div>
                                </div>
                            ))}
                        </div>
                        { pathComponent === "/UserMyPg" ?
                        <div style={{
                            width: "100%",
                            height: "60px",
                            position: "sticky",
                            bottom: "0",
                            backgroundColor:"white",
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"flex-end",
                            borderTop:"solid 1px #F2F2F2",
                            paddingRight:"10px"
                        }}>
                            <button onClick={()=> setDeliveryDetailPayMove(true)}
                            style={{width:"120px", height:"40px", border:"solid", borderWidth:"thin 1px", borderColor:"lightgray"}}>
                                <p style={{fontSize:"13px"}}>{t(`payment`)}</p>
                            </button>
                        </div>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
            {
                deliveryDetailAddrSt ?
                    <UserAddrBook deliveryDetailAddrSt={deliveryDetailAddrSt} deliveryDetailAddrFalseFn={deliveryDetailAddrFalseFn} addrChoiceData={addrChoiceData}/>
                    :
                    deliveryDetailPayMove ?
                        <MsgSelectModal deliveryDetailPayMove={deliveryDetailPayMove} setDeliveryDetailPayMove={setDeliveryDetailPayMove} moveDeliveryPay={moveDeliveryPay}
                                        deliveryDetailPayMsg1={deliveryDetailPayMsg1} deliveryDetailPayMsg2={deliveryDetailPayMsg2}/>
                        :
                        isLoading ?
                            <CommonLoading/>
                            :
                    null
            }
        </div>
    )
}

export default DeliveryPriceDetail
