import React, {useCallback, useEffect, useState} from 'react';
import axios from "axios";
import MsgSelectModal from "./MsgSelectModal";

function TariffCustomModal() {

    /** region DataTable */

    const [ordTariffData, setOrdTariffData] = useState([]);
    const [ordCustom, setOrdCustom] = useState([]);

    /** endregion */

    /** region Function */

    const handleCustomSelectChange = (selectedValue, index) => {
        const updatedData = ordTariffData.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    selectedCustom: selectedValue,
                    customsDTO: {
                        ...item.customsDTO,
                        customItemDTO: { seqNum: selectedValue }
                    }
                };
            }
            return item;
        });
        setOrdTariffData(updatedData);
    };

    const handleInputChange = (value, index, field) => {
        const updatedData = ordTariffData.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    customsDTO: {
                        ...item.customsDTO,
                        [field]: value
                    }
                };
            }
            return item;
        });
        setOrdTariffData(updatedData);
    };

    // region Alert

    const [alertShow, setAlertShow] = useState(false);
    const msgConfirmData1 = "세관정보를 수정 하시겠습니까?";
    const msgConfirmData2 = "입력한 정보를 다시한번 확인해주세요.";
    const alert = (() => {
        setAlertShow(true)
    })
    const modalCancel1 = useCallback(() => {
        setAlertShow(false);
    })

    // endregion

    /** endregion */

    /** region Event */

    useEffect(() => {
        const receivedData = JSON.parse(localStorage.getItem('ordReceiptData'));
        localStorage.removeItem('ordReceiptData');

        const dataWithSelectedCustom = receivedData.map(item => ({
            ...item,
            selectedCustom: item.customsDTO.customItemDTO?.seqNum
        }));

        setOrdTariffData(dataWithSelectedCustom);
        customItemSelect();
    }, []);

    const customItemSelect = () => {
        axios.get('/admincustomitem/admincustomitemselect')
            .then((response) => {
                setOrdCustom(response.data);
            }, [])
            .catch(() => {})
    }

    const ordTariffUpdate = () => {
        const updatedTariffData = ordTariffData.map(entry => ({
            seqNum: entry.customsDTO.seqNum,
            customItemDTO: entry.customsDTO.customItemDTO,
            custContent: entry.customsDTO.custContent,
            custPrice: entry.customsDTO.custPrice,
        }));
        axios.post('/order/ordCustomsUpdate', updatedTariffData, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(() => {
                setAlertShow(false);
                if (window.opener) {
                    window.opener.location.reload();
                    window.close();
                }
            })
            .catch(error => {
                console.error("Error:", error);
            });
    };

    /** endregion */

    return (
        <div className="TariffCustomModal admin-modal-default">
            <div className="openModal regModal">
                <section>
                    <header className="admin-work-title">
                        <b style={{color: "#ffffff"}}>세관신고금액 및 내용품명 작성</b>
                    </header>
                    <main>
                        <div className="div-tb m-b-10">
                            <div className="div-tb-head ht-50">
                                <div style={{width: "140px"}}><p>주문번호</p></div>
                                <div style={{width: "120px"}}><p>수취인명</p></div>
                                <div style={{width: "270px"}}><p>내용품명</p></div>
                                <div style={{width: "70px"}}><p>금액($)</p></div>
                                <div style={{width: "110px"}}><p>HS CODE</p></div>
                            </div>
                            {ordTariffData.map((tData, i) => (
                                <div key={i} className="div-tb-body ht-70">
                                    <div style={{width: "140px"}}><p className="font-b">…{tData.orderdtDTO.orderNumber.slice(0, 7)} - {tData.shipInfoDTO.boxNum}</p></div>
                                    <div style={{width: "120px"}}><p className="font-b">{tData.appRecDTO.recname}</p></div>
                                    <div style={{width: "270px"}}>
                                        <input className="admin-input-line ht-30 p-l-5 font-s-13 m-l-12" style={{width: "250px"}} type="text" maxLength="130"
                                            placeholder="세관품목 내용을 입력하세요." value={tData.customsDTO.custContent}
                                            onChange={(e) => handleInputChange(e.target.value, i, 'custContent')}/>
                                    </div>
                                    <div style={{width: "70px"}}>
                                        <input className="admin-input-line ht-30 text-center font-s-13 m-l-5 wd-55 font-c-progress font-b" minLength="1" maxLength="4" type="text"
                                            placeholder="0" value={tData.customsDTO.custPrice.toLocaleString()}
                                            onChange={(e) => handleInputChange(e.target.value, i, 'custPrice')}/>
                                    </div>
                                    <div style={{width: "100px"}}>
                                        <select className="admin-combo wd-90 font-s-12 m-l-7 ht-30" value={tData.selectedCustom}
                                                onChange={(e) => handleCustomSelectChange(e.target.value, i)}>s
                                            {ordCustom.map((tcData) => (
                                                <option value={tcData.seqNum} key={tcData.seqNum}>
                                                    {tcData.hsCodeNm}
                                                </option>
                                            ))}s
                                        </select>

                                    </div>
                                </div>
                            ))}
                        </div>
                    </main>
                    <footer>
                        <button className="admin-bt-color admin-btn-long m-r30" onClick={() => window.close()}>취소</button>
                        <button className="admin-bt-color admin-btn-long" onClick={alert}>저장</button>
                    </footer>
                </section>
            </div>
            {alertShow === true ? <MsgSelectModal alertShow={alertShow} eventHandle1={modalCancel1} eventHandle2={ordTariffUpdate} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
        </div>
    );
}

export default TariffCustomModal
