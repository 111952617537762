import React, {useEffect, useRef, useState} from "react";
import commonHeader from "../../assets/img/commonHeader.png";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import {useNavigate} from "react-router-dom";
import {BiCaretDown} from "react-icons/bi";
import {BsTrash} from "react-icons/bs";
import photoUpload from "../../assets/img/reviewUpload.png";
import axios from "axios";
import maCateImg from "../../assets/img/m-a-cate.png";
import MsgCheckModal from "../../components/web/modal/MsgCheckModal";
import commonClose from "../../assets/img/commonClose.png";

function AdminBannerWritePg() {

    const navigate = useNavigate();
    const LCAT = localStorage.getItem('adminToken');

    const [langList, setLangList] = useState(["Korean", "English", "Japanese", "Chinese", "Indonesian"]);
    const [selectedLang, setSelectedLang] = useState('');
    const [langShow, setLangShow] = useState(false);
    const langRef = useRef(null);

    const toggleLangShow = () => {
        setLangShow(!langShow);
    };

    const handleLangChange = (mst) => {
        setSelectedLang(mst);
        setLangShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (langRef.current && !langRef.current.contains(event.target)) {
                setLangShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [titleErrorMsg, setTitleErrorMsg] = useState(false);
    const [langErrorMsg, setLangErrorMsg] = useState(false);

    const [titleText, setTitleText] = useState('');
    const handleChangeTitleText = (e) => {
        setTitleText(e.target.value);
    };

    const [photoContainer, setPhotoContainer] = useState([{ photoImg: photoUpload, photoImgSt: false, photoImgError: false }]);
    const photoImgHandle = (e) => {
        const file = e.target.files[0];

        if (file) {
            if (!file.type.startsWith('image/')) {
                setPhotoContainer({
                    ...photoContainer,
                    photoImgError: true,
                });
            } else {
                setPhotoContainer({
                    ...photoContainer,
                    photoImg: URL.createObjectURL(file),
                    photoImgSt: true,
                    photoImgError: false,
                    file: file
                });
            }
        }
    };

    const photoImgInit = () => {
        setPhotoContainer({ photoImg: photoUpload, photoImgSt: false, photoImgError: false });
    };

    const validation = () => {
        if (titleText === '' || !titleText) setTitleErrorMsg(true);
        if (selectedLang === '' || !selectedLang) setLangErrorMsg(true);
        return !!(titleText && selectedLang)
    }

    useEffect(() => {
        if (titleText.length > 0) {
            setTitleErrorMsg(false);
        }
        if (!selectedLang || selectedLang !== '') {
            setLangErrorMsg(false);
        }
    }, [titleText, selectedLang])

    const [saveFailSt, setSaveFailSt] = useState(false);
    const closeSaveFailSt = (() => {
        setSaveFailSt(false);
    })
    const photoSave = () => {
        const newFormData = new FormData();
        newFormData.append('title', titleText);
        newFormData.append('photoImg', photoContainer.file);

        const lang = selectedLang === "Korean" ? 0 : selectedLang === "English" ? 1
            : selectedLang === "Japanese" ? 2 : selectedLang === "Chinese" ? 3 : 4

        return axios({
            method: 'post',
            url: '/banner/saveBanner',
            data: newFormData,
            params: {
                type: '0',
                lang: lang,
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: LCAT
            },
        })
            .then((response) => {
                navigate("/btcorpad/adminbanner")
            }).catch(() => {
                setSaveFailSt(true);
            })
    }

    const valiAndSave = () => {
        if (validation()) {
            photoSave();
        }
    }

    const moveToReturn = (() => {
        navigate("/btcorpad/adminbanner")
    })

    return(
        <div className="AdminNoticePg" style={{padding:"0 200px"}}>
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">배너추가</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card font-t-cont">
                    <div style={{padding:"20px"}}>
                        <div>
                            <div style={{height: "10%", display: "flex"}}>
                                <div style={{width: "100%"}}>
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                                        <div style={{
                                            height: "40px",
                                            marginBottom: "20px",
                                            display: "flex",
                                            justifyContent: "flex-end"
                                        }}>
                                            <img onClick={moveToReturn} alt="close" style={{
                                                width: "35px",
                                                height: "35px",
                                                opacity: "0.6",
                                                cursor: "pointer"
                                            }} src={commonClose}/>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "justify-between",
                                        width: "100%"
                                    }}>
                                        <div className="ReviewPgSearchFrame acc-icon" style={{width: "80%"}}>
                                            <div className="ReviewPgInputForm" style={{width: "100%"}}>
                                                <input className="ReviewInput user-input"
                                                       style={{width: "99%", height: "100%", padding: "0 10px"}}
                                                       type="text"
                                                       placeholder="" value={titleText} onChange={handleChangeTitleText}
                                                       maxLength={60}/>
                                                <label className="JoinLabel"
                                                       style={{fontSize: "14px", color: "darkgray"}}>제목</label>
                                            </div>
                                        </div>

                                        {/*Lang*/}
                                        <div style={{width:"20%", height: "55px", marginLeft:"10px"}}>
                                            {/*여기서부터 커스텀 Lang셀렉트*/}
                                            <div ref={langRef}
                                                 style={{height: "100%", width: "100%", position: "relative"}}>
                                                <button className="selectParentBt"
                                                        style={{width: "100%", height: "100%"}}
                                                        onClick={toggleLangShow}>
                                                    <p style={{margin: "0"}}>{selectedLang === "" ? "배너언어" : selectedLang}</p>
                                                    <span style={{
                                                        position: "absolute",
                                                        left: "80%",
                                                        top: "0",
                                                        height: "100%",
                                                        zIndex:10,

                                                        width: "20%"
                                                    }}>
                                                        <i style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            height: "100%"
                                                        }}><BiCaretDown/></i>
                                                    </span>
                                                </button>
                                                {langShow ?
                                                    <div style={{
                                                        width:"100%",
                                                        height:"200px",
                                                        overflowY:"scroll",
                                                        border: "solid",
                                                        borderWidth: "thin 1px",
                                                        borderColor: "lightgray",
                                                        position: "absolute",
                                                        top: "100%",
                                                        bottom: "100%",
                                                        zIndex: 1,
                                                        marginTop: "5px",
                                                    }}>
                                                        {langList.map((lang, index) => (
                                                            <div className="selectChildBt" key={index} value={lang}
                                                                 onClick={() => handleLangChange(lang)} style={{
                                                                display: "flex",
                                                                padding: "10px",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                cursor: "pointer",
                                                                fontSize: "14px",
                                                            }}>{lang}</div>
                                                        ))}
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                            {/*여기까지 커스텀 Lang셀렉트*/}
                                        </div>
                                    </div>

                                </div>

                            </div>

                            { titleErrorMsg ?
                                <div className="JoinErrorMsg">
                                    <p className="JoinErrMsg">&nbsp;제목을 입력해주세요</p>
                                </div>
                                :
                                null
                            }

                            { langErrorMsg ?
                                <div className="JoinErrorMsg">
                                    <p className="JoinErrMsg">&nbsp;등록배너의 언어를 선택해주세요</p>
                                </div>
                                :
                                null
                            }

                            <div style={{height: "auto", display: "flex", marginTop: "20px"}}>
                                <div style={{width: "100%"}}>
                                    <div style={{
                                        border: "solid",
                                        height: "500px",
                                        borderWidth: "thin 1px",
                                        borderColor: "#EAEAEA",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        {!photoContainer.photoImgSt ?
                                            <div style={{display: "block", textAlign: "center", cursor: "pointer"}}
                                                 onClick={(e) => {
                                                     const parentDiv = e.target.parentNode;
                                                     const fileInput = parentDiv.querySelector('input[type="file"]');
                                                     if (fileInput) fileInput.click();
                                                 }}>
                                                <input type="file" accept="image/*" style={{display: "none"}}
                                                       onChange={photoImgHandle}/>
                                                <img alt="uploadImg" style={{
                                                    width: "80px",
                                                    height: "80px",
                                                    padding: "10px",
                                                    filter: "opacity(0.2) drop-shadow(0 0 0 gray)",
                                                }} src={photoUpload}/>
                                                <p style={{color: "rgba(112,112,109,0.67)", fontSize: "18px"}}>Click
                                                    me!</p>
                                                <div className="JoinErrorMsg">
                                                    {photoContainer.photoImgError &&
                                                        <p className="JoinErrMsg">&nbsp;사진만 업로드해주세요😅</p>}
                                                </div>
                                            </div>
                                            :
                                            <div className="user-center" style={{
                                                width: "100%",
                                                display: "flex",
                                                height: "100%",
                                                position: "relative",
                                                padding: "10px"
                                            }}>
                                                <img alt="photoImg" style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    maxWidth: "100%",
                                                    maxHeight: "100%",
                                                    borderRadius: "6px"
                                                }} src={photoContainer.photoImg}/>
                                                <button className="orderItemHandleBt" style={{
                                                    position: "absolute",
                                                    width: "40px",
                                                    height: "40px",
                                                    top: "85%",
                                                    left: "90%",
                                                    borderRadius: "4px",
                                                    opacity: "0.6"
                                                }} onClick={photoImgInit}>
                                                    <i style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        fontSize: "26px"
                                                    }}><BsTrash/></i>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                height: "100px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "end"
                            }}>
                                <button className="orderItemHandleBt"
                                        style={{width: "100px", height: "50px", borderRadius: "4px"}}
                                        onClick={valiAndSave}>업로드
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {saveFailSt ?
                <MsgCheckModal saveFailSt={saveFailSt} closeSaveFailSt={closeSaveFailSt}/>
                :
                null
            }

        </div>
    )
}

export default AdminBannerWritePg
