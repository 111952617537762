import React, {createRef, useEffect, useRef, useState} from "react";
import commonClose from "../../../assets/img/commonClose.png";
import {BiCaretDown} from "react-icons/bi";

function AdminDelSetModal({ paypalExModifyShow, closePaypalExModifyShow, paypalExModifychecked, modifyData }) {

    const [curPriceErrorMsg, setCurPriceErrorMsg] = useState(false);
    const [payExrErrorMsg, setPayExrErrorMsg] = useState(false);
    const [payRateErrorMsg, setPayRateErrorMsg] = useState(false);

    const [checkedStates, setCheckedStates] = useState(paypalExModifychecked.map(item => ({
        seqNum: item.seqNum,
        curPrice: item.curPrice || '통화선택',
        payExr: item.payExr || '',
        payRate: item.payRate || '',
        regDate: item.regDate,
        regTime: item.regTime,
        regNm: item.regNm,
    })));


    const handleFieldChange = (index, field, value) => {
        const newCheckedStates = [...checkedStates];
        newCheckedStates[index][field] = value;
        setCheckedStates(newCheckedStates);
        if (field === 'payExr') {
            setPayExrErrorMsg(value.trim() === '');
        } else if (field === 'payRate') {
            setPayRateErrorMsg(value.trim() === '');
        }
    };

    // 여기서부터 커스텀 셀렉트
    const curPriceRefs = useRef([]);
    if (curPriceRefs.current.length !== paypalExModifychecked.length) {
        curPriceRefs.current = Array(paypalExModifychecked.length).fill().map((_, i) => curPriceRefs.current[i] || createRef());
    }

    const [curPriceShow, setCurPriceShow] = useState(null);
    const [curPriceList, setCurPriceList] = useState(["USD", "CAD", "AUD", "JPY", "EUR", "SGD", "CNY", "NZD"]);

    const toggleCurPriceShow = (index) => {
        setCurPriceShow(curPriceShow === index ? null : index);
    };

    const handleCurPriceChange = (index, curPrice) => {

        const newCheckedStates = checkedStates.map((item, idx) =>
            idx === index ? { ...item, curPrice: curPrice } : item
        );

        setCheckedStates(newCheckedStates);
        setCurPriceShow(null); // 드롭다운을 닫음

        setCurPriceErrorMsg(curPrice === '통화선택' || curPrice.trim() === '');
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (curPriceRefs.current.some(ref => ref.current && !ref.current.contains(event.target))) {
                setCurPriceShow(null);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트

    const validation = () => {
        let isValid = true;

        checkedStates.forEach((item) => {
            if (!item.curPrice || item.curPrice === "" || item.curPrice === "통화선택") {
                setCurPriceErrorMsg(true);
                isValid = false;
            }

            if (!item.payExr || item.payExr === "") {
                setPayExrErrorMsg(true);
                isValid = false;
            }

            if (!item.payRate || item.payRate === "") {
                setPayRateErrorMsg(true);
                isValid = false;
            }
        });

        if (isValid) {
            modifyData(checkedStates);
        }
    }
    return(
        <div className="bookContainer font-t-cont display-flex" style={{zIndex:"10"}}>
            <div className="bookFrame" style={{
                minWidth: "800px",
                height: "600px",
                position: "fixed",
                top: "45%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "5px"
            }}>
                <div className="wd-100pc ht-100pc">
                    <div style={{height: "45px"}}>
                        <div className="user-center" style={{
                            height: "100%",
                            fontSize: "23px",
                            border: "solid 1px lightgray",
                        }}>
                            <div className="user-center wd-100pc ht-100pc">
                                <div className="user-center">
                                    <p style={{
                                        height:"100%",
                                        fontSize: "16px",
                                        width: "100%"
                                    }}>ㄱㄱㄱㄱㄱㄱㄱㄱㄱㄱㄱㄱㄱㄱㄱㄱㄱ수정</p>
                                </div>
                            </div>
                            <div style={{width: "40px", position:"absolute", right:"3px", top:"2px", textAlign: "center", cursor:"pointer"}}>
                                <img onClick={closePaypalExModifyShow} style={{width:"30px", height:"30px", opacity:"0.6"}} alt="closeImage" src={commonClose}/>
                            </div>

                        </div>
                    </div>
                    {/*상세바디*/}
                    <div className="user-center" style={{minHeight:"150px", display:"flex", flexDirection:"column"}}>
                        <div style={{width:"100%", height:"100%", padding:"40px"}}>
                            {/*맵처리*/}
                            {checkedStates.map((data, i) => (
                                <div key={i} style={{display:"flex", alignItems:"center", justifyContent:"space-between", marginTop:"3px"}}>
                                    {/*통화*/}
                                    <div style={{width: "33%", height: "40px"}}>
                                        {/*여기서부터 커스텀 통화셀렉트*/}
                                        <div ref={el => curPriceRefs.current[i] = el} style={{height: "100%", width: "100%", position: "relative"}}>
                                            <button className="selectParentBt" style={{width: "100%", height: "100%"}}
                                                    onClick={() => toggleCurPriceShow(i)}>
                                                <p style={{margin: "0", fontSize:"13px"}}>{data.curPrice || "통화선택"}</p>
                                                <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                <i style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%"
                                }}><BiCaretDown/></i>
                            </span>
                                            </button>
                                            {curPriceShow === i &&
                                                <div style={{
                                                    width:"100%",
                                                    border: "solid",
                                                    borderWidth: "thin 1px",
                                                    borderColor: "lightgray",
                                                    position: "absolute",
                                                    zIndex:1,
                                                }}>
                                                    {curPriceList.map((cur, curIndex) => (
                                                        <div className="selectChildBt" key={`${i}-${curIndex}`}
                                                             onClick={() => handleCurPriceChange(i, cur)} style={{
                                                            display: "flex", padding: "10px", justifyContent: "center",
                                                            alignItems: "center", cursor: "pointer", fontSize: "14px",
                                                        }}>{cur}</div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                        {/*여기까지 커스텀 통화셀렉트*/}
                                    </div>
                                    <div className="user-input-container" style={{width:"33%", height:"40px"}}>
                                        <input className="user-input" style={{width:"100%", height:"100%", padding:"0 10px 0 10px", fontSize:"13px"}}
                                               value={data.payExr} onChange={(e) => handleFieldChange(i, 'payExr', e.target.value)}
                                               placeholder="환율" onKeyDown={(e) => {if(e.key === 'Enter') {
                                            modifyData();
                                        }
                                        }}
                                        />
                                    </div>
                                    <div className="user-input-container" style={{width:"33%", height:"40px"}}>
                                        <input className="user-input" style={{width:"100%", height:"100%", padding:"0 10px 0 10px", fontSize:"13px"}}
                                               value={data.payRate} onChange={(e) => handleFieldChange(i, 'payRate', e.target.value)}
                                               placeholder="요율(%)" onKeyDown={(e) => {if(e.key === 'Enter') {
                                            validation();
                                        }
                                        }}
                                        />
                                    </div>
                                </div>
                            ))}
                            { curPriceErrorMsg &&
                                <div className="JoinErrorMsg" style={{margin:"10px 0", padding:"0"}}>
                                    <p className="JoinErrMsg">&nbsp;모든 통화를 선택해주세요</p>
                                </div>
                            }
                            { payExrErrorMsg &&
                                <div className="JoinErrorMsg" style={{margin:"10px 0", padding:"0", height:"20px"}}>
                                    <p className="JoinErrMsg">&nbsp;모든 환율을 입력해주세요</p>
                                </div>
                            }
                            { payRateErrorMsg &&
                                <div className="JoinErrorMsg" style={{margin:"10px 0", padding:"0", height:"20px"}}>
                                    <p className="JoinErrMsg">&nbsp;모든 요율을 입력해주세요</p>
                                </div>
                            }
                            <div className="user-center" style={{marginTop:"20px"}}>
                                <button onClick={validation} className="colorFullBt" style={{
                                    width: "80px",
                                    height: "40px",
                                    textAlign: "center",
                                    fontSize: "15px",
                                    opacity: "0.6",
                                    marginLeft:"5px"
                                }}>수정
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminDelSetModal
