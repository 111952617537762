import React, {useEffect, useState} from 'react';
import '../../css/mobile/MAdmin.css';
import axios from "axios";
import maCateImg from "../../assets/img/m-a-cate.png";

function MAdminInflowStatusPg() {

    /** region DataTable */

    const [data, setData] = useState([]);

    /** endregion */

    /** region Function */

    const LCAT = localStorage.getItem('adminToken');

    /** endregion */

    /** region Event */

    useEffect(() => {
        // select();
    }, [])

    const select = () => {
        axios({
            method: 'get',
            url: '//',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setData(response.data)
            })
            .catch(() => {
            });
    };

    /** endregion */

    return (
        <div id="m-a-traffic-pg-container">
            <div className="m-a-title-frame">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">트래픽현황</p>
            </div>
        </div>
    );
}

export default MAdminInflowStatusPg
