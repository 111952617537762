import React from 'react';

function MAdminYmdEvent({ startDate, endDate, startHandle, endHandle }) {
    return (
        <div className="display-flex flex-row font-s-13 wd-80pc just-cont-sb p-l-5">
            <div className="wd-49pc">
                <input className="m-a-ymd-input wd-100pc ht-45" type={"date"}  min="1930-01-01" max="2099-12-31"
                       value={startDate} onChange={startHandle}/>
            </div>
            {/*<div className="p-t-12">*/}
            {/*    <a className="font-t-cont font-s-11 font-b wd-100pc" style={{color: "#969696"}}>-</a>*/}
            {/*</div>*/}
            <div className="wd-49pc">
                <input className="m-a-ymd-input wd-100pc ht-45" type={"date"}  min="1930-01-01" max="2099-12-31"
                       value={endDate} onChange={endHandle}/>
            </div>
        </div>
    );
}

export default MAdminYmdEvent
