import React, {useEffect} from "react";
import ready from "../../../assets/img/commonReadying.png";
import {useTranslation} from "react-i18next";

function ReadyingPopup({ readyingPopupSt, closeReadyingPopup }) {
    const {t} = useTranslation();

    useEffect(() => {
        const handleEnterKey = (event) => {
            if (event.key === 'Enter') {
                if (readyingPopupSt) {
                    closeReadyingPopup();
                }
            }
        };
        document.addEventListener('keydown', handleEnterKey);

        return () => {
            document.removeEventListener('keydown', handleEnterKey);
        };
    }, []);

    return (
        <div style={{
            position: "fixed", top: "0", left: "0", width: "100%", height: "100%", zIndex: 2000,
            background: "rgba(0, 0, 0, 0.5)", display: "flex", justifyContent: "center", alignItems: "center"
        }}>
            <div style={{
                width: "350px",
                height: "220px",
                background: "white",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                borderRadius: "6px"
            }}>
                <div style={{borderBottom: "solid 1px lightgray", height: "35%", display: "flex", padding: "10px"}}>
                    <div className="wd-20pc ht-100pc display-flex just-cont-center align-center">
                        <img className="wd-40 ht-40" alt='' src={ready}/>
                    </div>
                    <div className="font-t-title font-s-18 ht-100pc wd-80pc display-flex align-center">
                        {readyingPopupSt ?
                            <p>{t(`readyHd`)}</p>
                            :
                            null
                        }
                    </div>
                </div>
                <div className="font-t-cont font-s-17 display-flex align-center"
                     style={{height: "30%", padding: "30px"}}>
                    {readyingPopupSt ?
                        <p>{t(`readyCont`)}</p>
                        :
                        null
                    }
                </div>
                <div style={{
                    height: "35%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    padding: "10px"
                }}>
                    {readyingPopupSt ?
                        <button onClick={closeReadyingPopup} className="orderItemHandleBt" style={{
                            width: "90px",
                            height: "45px",
                            fontSize: "15px",
                            borderRadius: "4px",
                            padding: "0 5px"
                        }}>Check</button>
                        :
                        null
                    }
                </div>
            </div>
        </div>
    )
}

export default ReadyingPopup;
