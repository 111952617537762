import React, {useEffect, useState} from "react";
import commonHeader from "../../assets/img/commonHeader.png";
import commonBack from "../../assets/img/commonBack.png";
import commonClose from "../../assets/img/commonClose.png";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import {useTranslation} from "react-i18next";
import commomHeader from "../../assets/img/commonHeader.png";

function UserDepChargHistoryPg() {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    const [memberInfo, setMemberInfo] = useState("");
    const findMember = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                setMemberInfo(response.data)
            })
            .catch(() => {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (LCAT || SSAT) {
            findMember();
        }
    }, [LCAT, SSAT])

    const [depHistoryList, setDepHistoryList] = useState([]);
    const selectedDepHistory = (() => {
        axios({
            method: 'get',
            url: '/member/selectedDepHistory',
            params: {
                id: memberInfo.id
            }
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.seqNum - a.seqNum);
                setDepHistoryList(sortedData)
            })
            .catch(() => {
                console.log("예치금 신청내역 조회실패")
            })
    })

    useEffect(() => {
        if (memberInfo.id) {
            selectedDepHistory();
        }
    }, [memberInfo])

    const cancelDepHistoryMsg1 = t(`dchMdMsg1`);
    const cancelDepHistoryMsg2 = t(`dchMdMsg2`);

    const [cancelDepHistorySeqNum, setCancelDepHistorySeqNum] = useState(null);
    const [cancelMsgShow, setCancelMsgShow] = useState(false);
    const openCancelMsgShow = ((seqNum) => {
        setCancelDepHistorySeqNum(seqNum);
        setCancelMsgShow(true);
    })
    const closeCancelMsgShow = (() => {
        setCancelMsgShow(false);
    })

    const cancelDepHistory = ((seqNum) => {
        axios({
            method: 'get',
            url: '/member/cancelDepHistory',
            params: {
                seqNum: seqNum
            }
        })
            .then((response) => {
                setDepHistoryList((currentList) => {
                    return currentList.map(item => {
                        if (item.seqNum === seqNum) {
                            return response.data;
                        } else {
                            return item;
                        }
                    });
                });
                closeCancelMsgShow();
            })
            .catch(() => {
                console.log("예치금신청 취소실패")
            })
    })

    const formatDate = (dateStr) => {
        if (dateStr.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }

    return (
        <div className="display-flex just-cont-center align-center">
            <div className="wd-100pc">
                <div className="display-flex ht-60 just-cont-sb">
                    <div style={{display:"flex", alignItems:"flex-start", padding:"10px 0"}}>
                        <img className="wd-25 ht-25" src={commomHeader} alt="header"/>
                        <p className="font-s-20 m-l-10 font-t-title font-c-default">{t(`dchHeader`)}</p>
                    </div>
                    <span className="font-c-progress-b font-s-14 ht-100pc m-t-5 c-point" onClick={() => navigate("/UserDepChargPg")}>
                        <img className="m-a-opc-04 wd-20 ht-20" src={commonBack} alt=""/>
                    </span>
                </div>
                <div style={{minHeight: "450px"}}>
                    <div style={{border: "solid 1px #EEEEEE"}}>
                        <div className="user-center ht-50 font-t-title" style={{backgroundColor: "#F6F6F6"}}>
                            <div className="user-center wd-5pc"></div>
                            <div className="user-center wd-10pc"><p>{t(`dchDate`)}</p></div>
                            <div className="user-center wd-20pc"><p>{t(`dchSmallMenu1`)}</p></div>
                            <div className="user-center wd-30pc"><p>{t(`dchSmallMenu2`)}</p></div>
                            <div className="user-center wd-20pc"><p>{t(`dchSmallMenu3`)}</p></div>
                            <div className="user-center wd-5pc"><p>{t(`dchSmallMenu4`)}</p></div>
                            <div className="user-center wd-5pc"><p>{t(`dchSmallMenu5`)}</p></div>
                            <div className="user-center wd-5pc"></div>
                        </div>
                        <div className="font-t-cont" style={{padding: "10px 0"}}>
                            {depHistoryList.map((dep, i) => (
                                <div key={i} className="user-center ht-40">
                                    <div className="user-center wd-5pc font-s-14"><p>{i + 1}</p></div>
                                    <div className="user-center wd-10pc font-s-14"><p>{formatDate(dep.regDate)}</p></div>
                                    <div className="user-center wd-20pc font-s-14"><p>{dep.name}</p></div>
                                    <div className="user-center wd-30pc font-s-14"><p>{dep.account}</p></div>
                                    <div className="user-center wd-20pc font-s-14">
                                        <p>{dep && dep?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{t(`dchPrice`)}</p>
                                        {dep.currency ?
                                            <div className="user-center">
                                                <p className="m-l-3 font-s-12">-</p>
                                                <p className="font-s-13 m-l-3 font-c-btorage">{dep.dollarPrice}</p>
                                                <p className="font-s-12 m-l-3">{dep.currency}</p>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="user-center wd-5pc font-s-14"><p>{dep.chargFl ? t(`st8`) : t(`st9`)}</p></div>
                                    <div className="user-center wd-5pc">
                                        <div className="font-s-14">
                                            {dep.status === "0" ? <p className="font-c-progress-b">{t(`st1`)}</p>
                                            : dep.status === "1" ?
                                                <p>{t(`st2`)}</p> :
                                                <p className="font-c-accent">{t(`st3`)}</p>}
                                        </div>
                                    </div>
                                    <div className="user-center wd-5pc">
                                        {dep.status === "0" ?
                                            <p className="c-point font-s-15" onClick={() => openCancelMsgShow(dep.seqNum)}>
                                                <img className="wd-22 ht-22 m-a-opc-08" alt="cancel" src={commonClose}/>
                                            </p>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {cancelMsgShow ?
                <MsgSelectModal cancelMsgShow={cancelMsgShow} cancelDepHistoryMsg1={cancelDepHistoryMsg1}
                                cancelDepHistoryMsg2={cancelDepHistoryMsg2}
                                closeCancelMsgShow={closeCancelMsgShow} cancelDepHistory={cancelDepHistory}
                                cancelDepHistorySeqNum={cancelDepHistorySeqNum}/>
                :
                null
            }
        </div>
    )
}

export default UserDepChargHistoryPg
