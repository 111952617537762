import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import commonHeader from '../../assets/img/commonHeader.png'
import commonClose from '../../assets/img/commonClose.png'
import { useLocation } from 'react-router-dom';
import axios from "axios";

function AdminAlarmList() {

    const {type} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const LCAT = localStorage.getItem('adminToken');

    const [prodData, setProdData] = useState([]);
    const [payData, setPayData] = useState([]);
    const [depositData, setDepositData] = useState([]);
    const [reviewData, setReviewData] = useState([]);

    useEffect( () => {
        updateData();
        if (type === "cart") {
            deleteProdData("P00");
        } else if (type === "order") {
            deleteProdData("P01");
        } else if (type === "offline") {
            deleteProdData("P02");
        } else if (type === "transfer") {
            deleteProdData("P03");
        } else if (type === "domestic") {
            deleteProdData("P04");
        } else if (type === "delivery") {
            deleteProdData("P05");
        } else if (type === "pay") {
            deleteAlarmPay();
        } else if (type === "deposit") {
            deleteAlarmDeposit();
        }
    }, [location]);

    const updateData = (() => {
        if (location.state) {
            if (type === "cart" || type === "order" || type === "offline" || type === "transfer" || type === "domestic" || type === "delivery") {
                setProdData(location.state);
            } if (type === "pay") {
                setPayData(location.state);
            } if (type === "deposit") {
                setDepositData(location.state);
            } if (type === "review") {
                setReviewData(location.state);
            }
        }
    })

    const prodStPrint = (prodSt) => {
        const prodStStyles = {
            IS00: { color: "#98B0D4", label: "입고대기" },
            IS01: { color: "#98B0D4", label: "결제대기(상품)" },
            IS02: { color: "#98B0D4", label: "결제중(상품)" },
            IS03: { color: "#FFB378", label: "결제완료(상품)" },
            IS04: { color: "#FF8600", label: "구매완료" },
            IS05: { color: "#6EDA60", label: "센터도착" },
            IS06: { color: "#98B0D4", label: "패킹중" },
            IS07: { color: "#98B0D4", label: "결제대기(배송)" },
            IS08: { color: "#98B0D4", label: "결제중(배송)" },
            IS09: { color: "#FFB378", label: "결제완료(배송)" },
            IS10: { color: "#979696", label: "출고완료" },
            IS11: { color: "#FF8F62", label: "반송중" },
            IS12: { color: "#FF8F62", label: "신청취소" },
            IS13: { color: "#98B0D4", label: "구매중" },
            IS14: { color: "#98B0D4", label: "입고중" }
        };

        const style = prodStStyles[prodSt] || { color: "black", label: null };
        return <p style={{ margin: "0", color: style.color, fontSize: "13px" }}>{style.label}</p>;
    };

    const closeAlarm = (async () => {
        navigate("/btcorpad")
    })

    const deleteProdData = ((prodTy) => {
        deleteAlarmProd(prodTy);
    })

    const deleteAlarmProd = ((prodTy) => {
        axios({
            method: 'post',
            url: '/order/deleteAlarmProd',
            headers: {
                Authorization: LCAT
            },
            params: {
                prodTy: prodTy
            },
        })
            .then((response) => {
            })
            .catch((error) => {
                console.log("삭제실패 : ", error)
            })
    })

    const deleteAlarmPay = (() => {
        axios({
            method: 'post',
            url: '/order/deleteAlarmPay',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
            })
            .catch((error) => {
                console.log("삭제실패 : ", error)
            })
    })

    const deleteAlarmDeposit = (() => {
        axios({
            method: 'post',
            url: '/order/deleteAlarmDeposit',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
            })
            .catch((error) => {
                console.log("삭제실패 : ", error)
            })
    })

    const formatDate = (dateStr) => {
        if (dateStr.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }

    const moveToAdminReviewDtPg = (async (seqNum) => {
        await deleteAlarmReview(seqNum);
        navigate(`/btcorpad/adminreview-detail/${seqNum}`);
    })

    const deleteAlarmReview = ((seqNum) => {
        axios({
            method: 'post',
            url: '/order/deleteAlarmReview',
            headers: {
                Authorization: LCAT
            },
            params: {
                seqNum: seqNum
            }
        })
            .then((response) => {
            })
            .catch((error) => {
                console.log("삭제실패 : ", error)
            })
    })

    return(
        <div style={{padding:"100px"}}>
            <div style={{backgroundColor:"white", width:"1200px", minHeight:"600px", borderRadius:"10px", margin:"auto", boxShadow:"0 0 12px 6px rgba(0,0,0,0.03)"}}>
                <div style={{padding:"30px"}}>
                    <div style={{height:"50px", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                        <div style={{display:"flex", alignItems:"center"}}>
                        <img style={{width:"25px", height:"25px"}} alt="header" src={commonHeader}/>
                        <p className="font-t-title" style={{fontSize:"20px", marginLeft:"10px"}}>Alarm List</p>
                        </div>
                        <div className="user-center" onClick={closeAlarm} style={{cursor:"pointer"}}>
                        <img style={{width:"30px", height:"30px", opacity:"0.6"}} alt="header" src={commonClose}/>
                        </div>
                    </div>
                    {/*type에 따라 조건화/상품*/}
                    { type === 'cart' || type === 'order' || type === 'offline' || type === 'transfer' || type === 'domestic' || type === 'delivery' ?
                    <div className="font-t-cont" style={{border:"solid 1px #EAEAEA"}}>
                        <div className="user-center" style={{height:"40px", borderRadius:"4px", backgroundColor:"#F6F6F6"}}>
                            <div className="user-center" style={{width:"5%"}}><p></p></div>
                            <div className="user-center" style={{width:"15.83%"}}><p style={{fontSize:"14px"}}>성함</p></div>
                            <div className="user-center" style={{width:"15.83%"}}><p style={{fontSize:"14px"}}>타입</p></div>
                            <div className="user-center" style={{width:"15.83%"}}><p style={{fontSize:"14px"}}>상품금액</p></div>
                            <div className="user-center" style={{width:"15.83%"}}><p style={{fontSize:"14px"}}>쇼핑몰배송비</p></div>
                            <div className="user-center" style={{width:"15.83%"}}><p style={{fontSize:"14px"}}>수수료(7%)</p></div>
                            <div className="user-center" style={{width:"15.83%"}}><p style={{fontSize:"14px"}}>상태</p></div>
                        </div>
                        {prodData && prodData.map((prod, i) => (
                            <div key={i} className="user-center" style={{height:"35px"}}>
                            <div className="user-center" style={{width:"5%"}}><p style={{margin:"0"}}>{i+1}</p></div>
                                <div className="user-center" style={{width:"15.83%"}}>
                                    <p style={{margin:"0", fontSize:"13px"}}>{prod.regNm}</p>
                                </div>
                            <div className="user-center" style={{width:"15.83%"}}>
                                <p style={{margin:"0", fontSize:"13px"}}>{prod.prodTy === "P00" ? "장바구니" : prod.prodTy === "P01" ? "구매대행" :
                                prod.prodTy === "P02" ? "오프라인" : prod.prodTy === "P03" ? "이체대행" : prod.prodTy === "P04" ? "국내배송" : prod.prodTy === "P05" ? "배송대행" : null}</p>
                            </div>
                            <div className="user-center" style={{width:"15.83%"}}>
                                <p style={{margin:"0", fontSize:"13px"}}>{prod?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                            </div>
                            <div className="user-center" style={{width:"15.83%"}}>
                                <p style={{margin:"0", fontSize:"13px"}}>{prod?.mallShipPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                            </div>
                            <div className="user-center" style={{width:"15.83%"}}>
                                <p style={{margin:"0", fontSize:"13px"}}>{prod?.fee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                            </div>
                            <div className="user-center" style={{width:"15.83%"}}>
                                {prodStPrint(prod.prodSt)}
                            </div>
                        </div>
                            ))}
                    </div>
                    :
                        type === 'pay' ?
                    // type에 따라 조건화/결제내역
                    <div style={{border:"solid", borderWidth:"thin 1px", borderColor:"#EAEAEA"}}>
                        <div className="user-center" style={{height:"40px", borderRadius:"4px", backgroundColor:"#F6F6F6"}}>
                            <div className="user-center" style={{width:"5%"}}><p style={{margin:"0"}}></p></div>
                            <div className="user-center" style={{width:"19%"}}><p style={{margin:"0", fontSize:"14px"}}>성함</p></div>
                            <div className="user-center" style={{width:"19%"}}><p style={{margin:"0", fontSize:"14px"}}>타입</p></div>
                            <div className="user-center" style={{width:"19%"}}><p style={{margin:"0", fontSize:"14px"}}>최종결제금액</p></div>
                            <div className="user-center" style={{width:"19%"}}><p style={{margin:"0", fontSize:"14px"}}>결제수단</p></div>
                            <div className="user-center" style={{width:"19%"}}><p style={{margin:"0", fontSize:"14px"}}>상태</p></div>
                        </div>
                        {payData && payData.map((pay, i) => (
                            <div key={i} className="user-center" style={{height:"35px"}}>
                            <div className="user-center" style={{width:"5%"}}><p style={{margin:"0"}}>{i+1}</p></div>
                                <div className="user-center" style={{width:"19%"}}>
                                    <p style={{margin:"0", fontSize:"13px"}}>{pay.regNm}</p>
                                </div>
                            <div className="user-center" style={{width:"19%"}}>
                                <p style={{margin:"0", fontSize:"13px"}}>{pay.payType === "PT0" ? "상품금액" : pay.payType === "PT1" ? "해외배송료" :
                                    pay.payType === "PT2" ? "기타비용" : pay.payType === "PT3" ? "통합결제" : null}</p>
                            </div>
                            <div className="user-center" style={{width:"19%"}}>
                                <p style={{margin:"0", fontSize:"13px"}}>{pay?.lastPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                                {pay?.forCurrency ?
                                    <div className="user-center">
                                        <p style={{margin: "0", fontSize: "11px", marginLeft: "3px"}}>-</p>
                                        <p style={{
                                            margin: "0",
                                            fontSize: "13px",
                                            color: "darkorange",
                                            marginLeft: "3px"
                                        }}>{pay?.lastForPrice}</p>
                                        <p style={{margin: "0", fontSize: "11px", marginLeft: "3px"}}>{pay?.forCurrency}</p>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <div className="user-center" style={{width:"19%"}}>
                                <p style={{margin:"0", fontSize:"13px"}}>{pay.payWay === "PM00" ? "무통장입금" : pay.payWay === "PM01" ? "Paypal" : pay.payWay === "PM02" ?
                                    "Wechat" : pay.payWay === "PM03" ? "Alipay" : pay.payWay === "PM04" ? "예치금결제" : pay.payWay === "PM05" ? "적립금결제" : null}</p>
                            </div>
                            <div className="user-center" style={{width:"19%"}}>
                                { pay.payStatus === 'PS0' ?
                                    <p style={{margin:"0", fontSize:"13px", color:"#98B0D4"}}>결제대기</p> :
                                    pay.payStatus === "PS1" ?
                                        <p style={{margin:"0", fontSize:"13px", color:"#98B0D4"}}>결제중</p> :
                                        pay.payStatus === "PS2" ?
                                            <p style={{margin:"0", fontSize:"13px", color:"#FFB378"}}>결제완료</p> :
                                            pay.payStatus === "PS3" ?
                                                <p style={{margin:"0", fontSize:"13px", color:"#979696"}}>출고완료</p> :
                                                pay.payStatus === "PS4" ?
                                                    <p style={{margin:"0", fontSize:"13px", color:"#FF8F62"}}>반송중</p> :
                                                    pay.payStatus === "PS5" ?
                                                        <p style={{margin:"0", fontSize:"13px", color:"#FF8F62"}}>취소</p> : null
                                }
                            </div>
                        </div>
                        ))}
                    </div>
                            :
                            type === 'deposit' ?
                    // type에 따라 조건화/예치금신청
                    <div style={{border:"solid", borderWidth:"thin 1px", borderColor:"#EAEAEA"}}>
                        <div className="user-center" style={{height:"40px", borderRadius:"4px", backgroundColor:"#F6F6F6"}}>
                            <div className="user-center" style={{width:"4%"}}><p style={{margin:"0"}}></p></div>
                            <div className="user-center" style={{width:"20%"}}><p style={{margin:"0", fontSize:"14px"}}>고객명</p></div>
                            <div className="user-center" style={{width:"21%"}}><p style={{margin:"0", fontSize:"14px"}}>예금주(ID)</p></div>
                            <div className="user-center" style={{width:"30%"}}><p style={{margin:"0", fontSize:"14px"}}>계좌</p></div>
                            <div className="user-center" style={{width:"15%"}}><p style={{margin:"0", fontSize:"14px"}}>금액</p></div>
                            <div className="user-center" style={{width:"5%"}}><p style={{margin:"0", fontSize:"14px"}}>구분</p></div>
                            <div className="user-center" style={{width:"5%"}}><p style={{margin:"0", fontSize:"14px"}}>상태</p></div>
                        </div>
                        {depositData && depositData.map((deposit, i) => (
                            <div key={i} className="user-center" style={{height:"35px"}}>
                            <div className="user-center" style={{width:"4%"}}><p style={{margin:"0"}}>{i+1}</p></div>
                                <div className="user-center" style={{width:"20%"}}><p style={{margin:"0", fontSize:"13px"}}>{deposit?.userNm}</p></div>
                                <div className="user-center" style={{width:"21%"}}><p style={{margin:"0", fontSize:"13px"}}>{deposit?.name}</p></div>
                            <div className="user-center" style={{width:"30%"}}><p style={{margin:"0", fontSize:"13px"}}>{deposit?.account}</p></div>
                            <div className="user-center" style={{width:"16%"}}>
                                <p style={{margin:"0", fontSize:"13px"}}>{deposit?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                                {deposit?.currency ?
                                    <div className="user-center">
                                        <p style={{margin:"0", fontSize:"11px", marginLeft:"3px"}}>-</p>
                                        <p style={{margin:"0", fontSize:"13px", color:"darkorange", marginLeft:"3px"}}>{deposit?.dollarPrice}</p>
                                        <p style={{margin:"0", fontSize:"11px", marginLeft:"3px"}}>{deposit?.currency}</p>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <div className="user-center" style={{width:"5%"}}><p style={{margin:"0", fontSize:"13px"}}>{deposit.chargFl ? "충전" : "환급"}</p></div>
                            <div className="user-center" style={{width:"5%"}}>
                                {deposit.status === "0" ? <p style={{margin:"0", fontSize:"13px", color:"#7C9EDC"}}>대기</p> :
                                    deposit.status === "1" ?  <p style={{margin:"0", fontSize:"13px"}}>완료</p> :
                                        <p style={{margin:"0", fontSize:"13px", color:"#EB939D"}}>취소</p>
                                }
                            </div>
                        </div>
                            ))}
                    </div>
                                :
                                type === 'review' ?
                    // type에 따라 조건화/이용후기
                    <div style={{border:"solid", borderWidth:"thin 1px", borderColor:"#EAEAEA"}}>
                        <div className="user-center" style={{height:"40px", borderRadius:"4px", backgroundColor:"#F6F6F6"}}>
                            <div className="user-center" style={{width:"5%"}}><p style={{margin:"0"}}></p></div>
                            <div className="user-center" style={{width:"55%"}}><p style={{margin:"0", fontSize:"14px"}}>제목</p></div>
                            <div className="user-center" style={{width:"20%"}}><p style={{margin:"0", fontSize:"14px"}}>작성자</p></div>
                            <div className="user-center" style={{width:"20%"}}><p style={{margin:"0", fontSize:"14px"}}>일자</p></div>
                        </div>
                        {reviewData && reviewData.map((review, i) => (
                            <div key={i} className="user-center" style={{height:"35px"}}>
                            <div className="user-center" style={{width:"5%"}}><p style={{margin:"0"}}>{i+1}</p></div>
                            <div onClick={()=> moveToAdminReviewDtPg(review.seqNum)} className="user-center" style={{width:"55%", cursor:"pointer"}}><p style={{margin:"0", fontSize:"13px"}}>{review.title}</p></div>
                            <div className="user-center" style={{width:"20%"}}><p style={{margin:"0", fontSize:"13px"}}>{review.regNm}</p></div>
                            <div className="user-center" style={{width:"20%"}}><p style={{margin:"0", fontSize:"13px"}}>{formatDate(review.regDate)}</p></div>
                        </div>
                            ))}
                    </div>
                                    :
                                    null}

                </div>
            </div>
        </div>
    )
}

export default AdminAlarmList
