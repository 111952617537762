import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import reviewUpload from "../../assets/img/reviewUpload.png";
import commonClose from "../../assets/img/commonClose.png";
import {BiCaretDown} from "react-icons/bi";
import {BsTrash} from "react-icons/bs";
import photoUpload from "../../assets/img/reviewUpload.png";

function AdminBannerModifyPg() {

    const navigate = useNavigate();
    const {seqNum} = useParams();
    const LCAT = localStorage.getItem('adminToken');

    const [langList, setLangList] = useState(["Korean", "English", "Japanese", "Chinese", "Indonesian"]);
    const [selectedLang, setSelectedLang] = useState('');
    const [langShow, setLangShow] = useState(false);
    const langRef = useRef(null);
    const [langErrorMsg, setLangErrorMsg] = useState(false);

    const toggleLangShow = () => {
        setLangShow(!langShow);
    };

    const handleLangChange = (mst) => {
        setSelectedLang(mst);
        setLangShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (langRef.current && !langRef.current.contains(event.target)) {
                setLangShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [adminInfo, setAdminInfo] = useState("");
    const findMember = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data)
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (LCAT) {
            findMember();
        }
    }, [LCAT])


    // copy
    const [titleErrorMsg, setTitleErrorMsg] = useState(false);
    const [contentErrorMsg, setContentErrorMsg] = useState(false);

    const [titleText, setTitleText] = useState('');
    const handleChangeTitleText = (e) => {
        setTitleText(e.target.value);
    };

    const [photoContainer, setPhotoContainer] = useState([{ photoImg: reviewUpload, photoImgSt: false, photoImgError: false, photoImgOriNm: '' }]);
    const selectBannerData = () =>
        axios({
            method: 'get',
            url: '/banner/selectBannerData',
            headers: {
                Authorization: LCAT
            },
            params: {
                seqNum: seqNum
            }
        })
            .then((response) => {
                const data = response.data;
                const lang = data.lang === "0" ? "Korean" :data.lang === "1" ? "English" :data.lang === "2" ? "Japanese" :data.lang === "3" ? "Chinese" : "Indonesian"
                    setTitleText(data.title);
                setSelectedLang(lang);
                const bannerPhoto = data.bannerPhotoDTO[0];
                if (bannerPhoto) {
                    setPhotoContainer({ photoImg: "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/bannerImg/" + bannerPhoto.fileNm, photoImgSt: true, photoImgError: false, photoImgOriNm: '' })
                }
            })
            .catch(() => {
                console.log("리뷰조회실패")
            })

    useEffect(() => {
        if (seqNum) {
            selectBannerData();
        }
    }, [seqNum])


    const photoImgHandle = (e) => {
        const file = e.target.files[0];

        if (file) {
            if (!file.type.startsWith('image/')) {
                setPhotoContainer({
                    ...photoContainer,
                    photoImgError: true,
                });
            } else {
                setPhotoContainer({
                    ...photoContainer,
                    photoImg: URL.createObjectURL(file),
                    photoImgSt: true,
                    photoImgError: false,
                    file: file
                });
            }
        }
    };


    const photoImgInit = () => {
        setPhotoContainer({ photoImg: photoUpload, photoImgSt: false, photoImgError: false });
    };

    const validation = () => {
        if (titleText === '' || !titleText) setTitleErrorMsg(true);
        if (selectedLang === '' || !selectedLang) setLangErrorMsg(true);
        return !!(titleText && selectedLang)
    }

    useEffect(() => {
        if (titleText.length > 0) {
            setTitleErrorMsg(false);
        }
        if (!selectedLang || selectedLang !== '') {
            setLangErrorMsg(false);
        }
    }, [titleText, selectedLang])

    const bannerModify = async () => {

        const newFormData = new FormData();
        newFormData.append('title', titleText);
        newFormData.append('photoImg', photoContainer.file);

        if (photoContainer.removeImg) {
            const filename = photoContainer.removeImg.split('/').pop(); // URL에서 파일 이름만 추출
            newFormData.append('removeImg', encodeURIComponent(filename));
        }

        const lang = selectedLang === "Korean" ? 0 : selectedLang === "English" ? 1
            : selectedLang === "Japanese" ? 2 : selectedLang === "Chinese" ? 3 : 4

        return axios({
            method: 'post',
            url: '/banner/bannerModify',
            data: newFormData,
            params: {
                bannerSeqNum: seqNum,
                type: '0',
                lang: lang,
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: LCAT
            },
        })
            .then(() => {
                navigate("/btcorpad/adminbanner")
            });
    }

    const valiAndSave = () => {
        if (validation()) {
            bannerModify();
        }
    }

    const moveToReturn = (() => {
        navigate("/btcorpad/adminbanner")
    })

    return(
        <div className="AdminNoticePg" style={{padding:"0 200px"}}>
            <div className="admin-work-title">
                <h5>배너수정</h5>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        <div style={{width:"100%"}}>
                            <div style={{display:"flex", alignItems:"center", justifyContent:"flex-end"}}>

                                <div style={{height:"40px", marginBottom:"20px", display:"flex", justifyContent:"flex-end"}}>
                                    <img onClick={moveToReturn} alt="close" style={{width:"35px", height:"35px", opacity:"0.6", cursor:"pointer"}} src={commonClose}/>
                                </div>
                            </div>
                            {/*copy*/}
                            <div>
                                <div style={{height: "10%", display: "flex"}}>

                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "justify-between",
                                        width: "100%"
                                    }}>
                                        <div className="ReviewPgSearchFrame acc-icon" style={{width: "80%"}}>
                                            <div className="ReviewPgInputForm" style={{width: "100%"}}>
                                                <input className="ReviewInput user-input"
                                                       style={{width: "99%", height: "100%", padding: "0 10px"}}
                                                       type="text"
                                                       placeholder="" value={titleText} onChange={handleChangeTitleText}
                                                       maxLength={60}/>
                                                <label className="JoinLabel"
                                                       style={{fontSize: "14px", color: "darkgray"}}>제목</label>
                                            </div>
                                        </div>

                                        {/*Lang*/}
                                        <div style={{width:"20%", height: "55px", marginLeft:"10px"}}>
                                            {/*여기서부터 커스텀 Lang셀렉트*/}
                                            <div ref={langRef}
                                                 style={{height: "100%", width: "100%", position: "relative"}}>
                                                <button className="selectParentBt"
                                                        style={{width: "100%", height: "100%"}}
                                                        onClick={toggleLangShow}>
                                                    <p style={{margin: "0"}}>{selectedLang === "" ? "배너언어" : selectedLang}</p>
                                                    <span style={{
                                                        position: "absolute",
                                                        left: "80%",
                                                        top: "0",
                                                        height: "100%",
                                                        zIndex:10,
                                                        width: "20%"
                                                    }}>
                                <i style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%"
                                }}><BiCaretDown/></i>
                            </span>
                                                </button>
                                                {langShow ?
                                                    <div style={{
                                                        width:"100%",
                                                        height:"200px",
                                                        overflowY:"scroll",
                                                        border: "solid",
                                                        borderWidth: "thin 1px",
                                                        borderColor: "lightgray",
                                                        position: "absolute",
                                                        top: "100%",
                                                        bottom: "100%",
                                                        zIndex: 1,
                                                        marginTop: "5px",
                                                    }}>
                                                        {langList.map((lang, index) => (
                                                            <div className="selectChildBt" key={index} value={lang}
                                                                 onClick={() => handleLangChange(lang)} style={{
                                                                display: "flex",
                                                                padding: "10px",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                cursor: "pointer",
                                                                fontSize: "14px",
                                                            }}>{lang}</div>
                                                        ))}
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                            {/*여기까지 커스텀 Lang셀렉트*/}

                                        </div>

                                        { titleErrorMsg ?
                                            <div className="JoinErrorMsg">
                                                <p className="JoinErrMsg">&nbsp;제목을 입력해주세요</p>
                                            </div>
                                            :
                                            null
                                        }

                                        { langErrorMsg ?
                                            <div className="JoinErrorMsg">
                                                <p className="JoinErrMsg">&nbsp;등록배너의 언어를 선택해주세요</p>
                                            </div>
                                            :
                                            null
                                        }

                                    </div>

                                </div>
                                <div style={{height: "auto", display: "flex", marginTop: "20px"}}>
                                    <div style={{width: "100%"}}>
                                        <div style={{
                                            border: "solid",
                                            height: "500px",
                                            borderWidth: "thin 1px",
                                                borderColor: "#EAEAEA",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                {!photoContainer.photoImgSt ?
                                                    <div style={{display: "block", textAlign:"center"}} onClick={(e) => {
                                                        const parentDiv = e.target.parentNode;
                                                        const fileInput = parentDiv.querySelector('input[type="file"]');
                                                        if (fileInput) fileInput.click();
                                                    }}>
                                                        <input type="file" accept="image/*" style={{display: "none"}} onChange={photoImgHandle}/>
                                                        <img alt="uploadImg" style={{
                                                            width: "80px",
                                                            height: "80px",
                                                            padding: "10px",
                                                            filter: "opacity(0.2) drop-shadow(0 0 0 gray)",
                                                        }} src={reviewUpload}/>
                                                        <p style={{color: "rgba(112,112,109,0.67)", fontSize: "18px"}}>Click me!</p>
                                                        <div className="JoinErrorMsg">
                                                            {photoContainer.photoImgError && <p className="JoinErrMsg">&nbsp;사진만 업로드해주세요😅</p>}
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="user-center" style={{width:"100%", display:"flex", height:"100%", position:"relative", padding:"10px"}}>
                                                        <img alt="photoImg" style={{
                                                            display:"flex",
                                                            justifyContent:"center",
                                                            alignItems:"center",
                                                            maxWidth:"100%",
                                                            maxHeight:"100%",
                                                        }} src={photoContainer.photoImg}/>
                                                        <button className="orderItemHandleBt" style={{
                                                            position:"absolute",
                                                            width: "40px",
                                                            height: "40px",
                                                            bottom:"10px",
                                                            right:"10px",
                                                            borderRadius:"4px",
                                                            opacity:"0.6"
                                                        }} onClick={()=> photoImgInit(photoContainer.photoImg)}>
                                                            <i style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                fontSize:"26px"
                                                            }}><BsTrash/></i>
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                    </div>
                                </div>
                                <div style={{
                                    height: "100px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <button className="orderItemHandleBt" style={{width: "100px", height: "50px", borderRadius:"4px"}} onClick={valiAndSave}>수정완료</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminBannerModifyPg
