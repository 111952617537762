import React from 'react';
import {HiOutlineChevronDoubleLeft, HiOutlineChevronDoubleRight, HiOutlineChevronLeft, HiOutlineChevronRight} from "react-icons/hi";
import Pagination from "react-js-pagination";

const AdminPagination = ({perPg, totalData, paginate, pageChange}) => {
    return (
        <div className="AdminPagination">
            <Pagination className="pagination"
                activePage={paginate} //현재페이지(1page)
                itemsCountPerPage={perPg} //데이터의 Row수
                totalItemsCount={totalData ? totalData : 0} //받아온 데이터
                pageRangeDisplayed={10} //보여줄 페이지수 ※ 10으로 고정 ※
                firstPageText={<HiOutlineChevronDoubleLeft className="dark-icon" style={{marginBottom: "2px"}}/>} //이전 끝으로 이동
                prevPageText={<HiOutlineChevronLeft className="dark-icon" style={{marginBottom: "2px"}}/>} //이전으로 이동
                onChange={pageChange}
                nextPageText={<HiOutlineChevronRight className="dark-icon" style={{marginBottom: "2px"}}/>} //다음으로 이동
                lastPageText={<HiOutlineChevronDoubleRight className="dark-icon" style={{marginBottom: "2px"}}/>} //다음 끝으로 이동
            />
        </div>
    );
};

export default AdminPagination
