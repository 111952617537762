import React from "react";
import commonClose from "../../../assets/img/commonClose.png";

function SaveMsgModal({message}) {
    return(
        <div style={{position:"fixed", zIndex:9, top:"0", left:"0", bottom:"0", right:"0", background:"rgba(0, 0, 0, 0.1)"}}>
            <div style={{
                position:"absolute",
                width: "120px",
                height: "60px",
                top: "calc(25vh - -15%)",
                left: "calc(50vw - 0%)",
                alignItems:"center",
                backgroundColor:"dimgrey",
                borderRadius: "15px",
            }}>
                <div className="user-center" style={{height:"100%"}}>
                    <p style={{margin:"0", fontSize:"17px", color:"white"}}>{message}</p>
                </div>
            </div>
        </div>
    )
}

export default SaveMsgModal
