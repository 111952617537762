import React, {useEffect, useState} from "react";
import commonClose from '../../../assets/img/commonClose.png'
import {useTranslation} from "react-i18next";

function KoreaTime({koreaTimeFalseFn}) {

    const { t } = useTranslation();

    //한국기준 시간
    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const week = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const dayWeek = week[date.getDay()];
    const DateViewValue = year + '-' + month + '-' + day + ' ' + dayWeek;

    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const id = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return (() => clearInterval(id))
    }, []);

    return(
        <div className="bookContainer">
            <div className="bookFrame ht-auto" style={{width: "400px", top: "calc(25vh - -15%)", left: "calc(50vw - 12%)", borderRadius: "4px"}}>
                <div className="ht-50 display-flex just-cont-sb align-center font-t-title" style={{borderBottom: "solid 1px lightgray"}}>
                    <p className="font-s-18 font-c-default" style={{margin: "10px 0 0 15px"}}>{t(`ktHeader`)}</p>
                    <div onClick={koreaTimeFalseFn} style={{fontSize: "18px", margin: "10px 10px 10px 0px", marginRight: "20px", cursor: "pointer"}}>
                        <img style={{width:"30px", height:"30px", opacity:"0.6"}} alt="close" src={commonClose}/>
                    </div>
                </div>
                <div className="font-t-cont font-c-default" style={{height: "70%"}}>
                    <div style={{height:"80px", padding:"15px 0px 0px 15px"}}>
                        <p>{DateViewValue}</p>
                        <p>{time.toLocaleTimeString('en-US')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default KoreaTime
