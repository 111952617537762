import React, {useEffect, useState} from 'react';
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import axios from "axios";
import {MdKeyboardArrowDown} from "react-icons/md";
import MsgConfirmModal from "../../components/web/modal/MsgConfirmModal";
import maCateImg from "../../assets/img/m-a-cate.png";
import AdminDelSetModal from "../../components/web/modal/AdminDelSetModal";
import AdminDelAddModal from "../../components/web/modal/AdminDelAddModal";

function AdminDeliveryInfoPg() {

    /**region DataTable */

    const [shipCoData, setShipCoData] = useState([]);

    /** endregion */

    /** region Function */

    const LCAT = localStorage.getItem('adminToken');

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [shipCoData.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
            if (shipCoData.length > displayLimit && displayLimit < 30) {
                setDisplayLimit(prevLimit => prevLimit + perPg);
            }
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(15);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e.target.value);
        setperPg(newPerPage);
        setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    useEffect(() => {
        setDisplayLimit(perPg);
    }, [perPg]);

    // endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);

    const isAllChecked = shipCoData.length > 0 && checked.length === shipCoData.length;

    const checkBoxChange = (id) => {
        setChecked(prevSelectedData => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter(item => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    const allCheckBoxChange = (e) => {
        if (e.target.checked) {
            setChecked(shipCoData.map(item => item.seqnum));
        } else {
            setChecked([]);
        }
    };

    //endregion

    /** endregion */

    /** region Event  */

    useEffect(() => {
        shipCompanySelect();
    }, [])

    const shipCompanySelect = () => {
        axios.get('/shipcompany/shipcoselect')
            .then((response) => {
                setShipCoData(response.data)
            }, [])
            .catch()
    }

    // region Excel

    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const DateViewValue = year + month + day;

    const excelDown = () => {
        try {
            const selectedRows = shipCoData.filter((item) => checked.includes(item.seqnum));

            const excel = require('exceljs');
            const workbook = new excel.Workbook();
            workbook.created = new Date();
            workbook.modified = new Date();

            function mapDataToExcelRow(item, index) {
                return {
                    '번호': index + 1,
                    '배송사명': item.courierCompanyName,
                    '배송사코드': item.shipCoCode,
                    '배송사구분': item.shippingCompanyCode === '0'? '국내배송' : '해외배송',
                    '연동여부': item.connFl === '0' ? 'N' : 'Y',
                    '등록일시': item.regTime,
                };
            }

            const sheetConfigs = {
                '배송사정보': {
                    name: '배송사정보',
                    columns: [
                        {header: '번호', key: '번호', hidden: false, width: 6, style: { alignment: { horizontal: 'center' }}},
                        {header: '배송사명', key: '배송사명', width: 20, style: { alignment: { horizontal: 'center' }}},
                        {header: '배송사코드', key: '배송사코드', width: 12, style: { alignment: { horizontal: 'center' }}},
                        {header: '배송사구분', key: '배송사구분', width: 12, style: { alignment: { horizontal: 'center' }}},
                        {header: '연동여부', key: '연동여부', width: 8, style: { alignment: { horizontal: 'center' }}},
                        {header: '등록일시', key: '등록일시', width: 20, style: { alignment: { horizontal: 'center' }}},
                    ],
                }
            };

            const borderHeaderStyle = {
                top: { style: 'thin', color: { argb: 'FF000000' } },
                left: { style: 'thin', color: { argb: 'FF000000' } },
                bottom: { style: 'thin', color: { argb: 'FF000000' } },
                right: { style: 'thin', color: { argb: 'FF000000' } }
            };

            const applyBordersToRow = (row, border) => {
                row.eachCell({ includeEmpty: true }, (cell) => {
                    cell.border = border;
                });
            };

            const applyStylesToHeader = (sheet, height) => {
                const invisibleRow = sheet.getRow();
                invisibleRow.height = 1;

                const headerRow = sheet.getRow(1);
                headerRow.font = { name: '맑은 고딕', bold: true, size: 10 };
                headerRow.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
                headerRow.height = height;

                headerRow.eachCell(() => {});
                applyBordersToRow(headerRow, borderHeaderStyle);
            };

            const createSheet = (name, columns) => {
                const sheet = workbook.addWorksheet(name);
                sheet.columns = columns;
                applyStylesToHeader(sheet, 23);
                return sheet;
            };

            const addRowsToSheet = (sheet, rows, border) => {
                rows.forEach((item) => {
                    const row = sheet.addRow(item);
                    for (let i = 1; i <= sheet.columns.length; i++) {
                        row.getCell(i).font = { name: '맑은 고딕', size: 10 };
                    }
                    applyBordersToRow(row, border);
                });
            };

            let transformedSelectedRows;
            transformedSelectedRows = selectedRows.map(mapDataToExcelRow);

            if (sheetConfigs) {
                const sheet = createSheet(sheetConfigs['배송사정보'].name, sheetConfigs['배송사정보'].columns);
                applyStylesToHeader(sheet, 23);
                addRowsToSheet(sheet, transformedSelectedRows, borderHeaderStyle);
            }

            let fileName = `비토리지 배송사정보_${DateViewValue}.xlsx`;
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = fileName;
                anchor.click();
                window.URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.error(error);
        }
    };

    // endregion

    /** endregion */


    const [noSelectedPaypalExShow, setNoSelectedPaypalExShow] = useState(false);
    const noSelectedPaypalExMsg = "수정할 항목을 선택해주세요"
    const closeNoSelectedPaypalExShow = (() => {
        setNoSelectedPaypalExShow(false);
    })

    const [paypalExModifyShow, setPaypalExModifyShow] = useState(false);
    const closePaypalExModifyShow = (() => {
        setPaypalExModifyShow(false);
    })
    const openPaypalExModifyShow = (() => {
        if (!checked || checked.length === 0) {
            setNoSelectedPaypalExShow(true);
        } else {
            setPaypalExModifyShow(true);
        }
    })

    const [paypalExAddShow, setPaypalExAddShow] = useState(false);
    const closePaypalExAddShow = (() => {
        setPaypalExAddShow(false);
    })

    const [paypalExDeleteShow, setPaypalExDeleteShow] = useState(false);
    const paypalExDeleteMsg1 = "선택하신 환율항목을 삭제하시겠습니까?"
    const paypalExDeleteMsg2 = "삭제 후 복구가 불가합니다"
    const closePaypalExDeleteShow = (() => {
        setPaypalExDeleteShow(false);
    })

    const saveData = (curPrice, payExr, payRate) => {
        axios({
            method: 'post',
            url: '/paypal/saveExrData',
            data: {
                payRate: payRate,
                payExr: payExr,
                curPrice: curPrice
            },
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setShipCoData([...shipCoData, response.data]);
                closePaypalExAddShow();
            })
            .catch((error) => {
                if (error.response.status === 400 || error.response.status === 401) {
                    window.location.href = '/Error401';
                } else if (error.response.status === 500) {
                    window.location.href = '/Error500';
                } else if (error.response.status === 404) {
                    window.location.href = '/Error404';
                }
            });
    }

    const modifyData = ((modifyData) => {
        axios({
            method: 'post',
            url: '/paypal/modifyExrData',
            data: modifyData,
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                const modifiedData = response.data;
                const updatedData = shipCoData.map(item => {
                    const matchingItem = modifiedData.find(modifiedItem => modifiedItem.seqnum === item.seqNum);
                    if (matchingItem) {
                        return matchingItem;
                    }
                    return item;
                });
                setShipCoData(updatedData);
                closePaypalExModifyShow(false);
            })
            .catch((error) => {

            });
    });

    return (
        <div className="AdminEmployeeInfoPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">배송사관리</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        <div className="admin-event-wrap-btn">
                            <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                        </div>
                        <div className="admin-event-wrap-btn">
                            <button className="admin-btn-long admin-bt-color" onClick={excelDown}>EXCEL</button>
                            <button className="admin-btn-long admin-bt-color" onClick={openPaypalExModifyShow}>편집</button>
                            <button className="admin-btn-long admin-bt-color" onClick={()=> setPaypalExAddShow(true)}>추가</button>
                        </div>
                    </div>
                    <div className="div-tb m-b-20">
                        <div className="div-tb-head ht-60">
                            <div className="wd-40">
                                {/*<input className="admin-input-chkBox m-0-a" type="checkbox" checked={checked.length === shipCoData.length} onChange={allcheckBoxChange}/>*/}
                                <input className="admin-input-chkBox m-0-a" type="checkbox" checked={isAllChecked} onChange={allCheckBoxChange}/>
                            </div>
                            <div className="wd-40"><p>번호</p></div>
                            <div className="wd-150"><p>배송사명</p></div>
                            <div className="wd-110"><p>배송사코드</p></div>
                            <div className="wd-100"><p>배송작업구분</p></div>
                            <div className="wd-100"><p>연동여부</p></div>
                            <div className="wd-150"><p>등록일시</p></div>
                        </div>
                        {shipCoData.slice(0, displayLimit).map((cData, i) => (
                            <div key={i} className="div-tb-body ht-70">
                                <div className="wd-40">
                                    {/*<input className="admin-input-chkBox m-0-a" type="checkbox" name={cData.seqnum} checked={checked.includes(cData.seqnum)}*/}
                                    {/*       onChange={() => checkBoxChange(cData.seqnum)}/>*/}
                                    <input key={cData.seqnum} className="admin-input-chkBox m-0-a" type="checkbox" checked={checked.includes(cData.seqnum)}
                                           onChange={() => checkBoxChange(cData.seqnum)}
                                    />
                                </div>
                                <div className="wd-40"><p>{i + 1}</p></div>
                                <div className="font-b wd-150"><p>{cData.courierCompanyName}</p></div>
                                <div className="wd-110"><p>{cData.shipCoCode}</p></div>
                                <div className="wd-100">
                                    <p style={{color: cData.shippingCompanyCode === '1' ? "#FFAF34" : ""}}>
                                        {cData.shippingCompanyCode === '0' ? '국내' : '해외'}
                                    </p>
                                </div>
                                <div className="font-b wd-100"><p>{cData.connFl === '0' ? 'N' : 'Y'}</p></div>
                                <div className="wd-150"><p>{cData.regTime}</p></div>
                            </div>
                        ))}
                    </div>
                    {displayLimit >= 30 && shipCoData.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
            { noSelectedPaypalExShow ?
                <MsgConfirmModal noSelectedPaypalExShow={noSelectedPaypalExShow} noSelectedPaypalExMsg={noSelectedPaypalExMsg} closeNoSelectedPaypalExShow={closeNoSelectedPaypalExShow}/>
                :
                paypalExModifyShow ?
                    <AdminDelSetModal paypalExModifyShow={paypalExModifyShow} closePaypalExModifyShow={closePaypalExModifyShow} paypalExModifychecked={checked} modifyData={modifyData}/>
                    :
                    paypalExAddShow ?
                        <AdminDelAddModal paypalExAddShow={paypalExAddShow} closePaypalExAddShow={closePaypalExAddShow} saveData={saveData}/>
                        : null
            }
        </div>
    );
}

export default AdminDeliveryInfoPg
