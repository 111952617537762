import React, {createContext, useState, useContext, useEffect} from 'react';

const ChatContext = createContext(null);

export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
    const [refreshChat, setRefreshChat] = useState(false);
    return (
        <ChatContext.Provider value={{ refreshChat, setRefreshChat }}>
            {children}
        </ChatContext.Provider>
    );
};
