import React from 'react';

function MAdminPageSmallEvent({perPg, handleSelectChange, togglePopup}) {
    const options = [10, 15, 20, 50, 100];
    return (
        <div className="m-a-page-popup-container font-c-default" onClick={togglePopup}>
            <div className="m-a-page-popup-frame">
                <div className="m-a-page-popup-header-frame">
                    <div className="font-t-title font-s-18 m-t-4">페이지수</div>
                </div>
                <div className="m-a-page-popup-body-frame font-t-cont">
                    {options.map((option) => (
                        <div className="m-a-card m-t-10 m-b-10" key={option} onClick={() => handleSelectChange(option)}
                             style={{border: option === perPg ? "2px solid #FFAF34" : "none", borderRadius: "10px"}}>
                            <p>{option}</p>
                        </div>
                    ))}
                </div>
                <div className="m-a-page-popup-footer-frame">
                    <button className="m-a-colorBt m-a-colorBt-text ht-40 m-b-5 wd-100" onClick={togglePopup}>Close</button>
                </div>
            </div>
        </div>
    );
}

export default MAdminPageSmallEvent
