import React, {useCallback, useEffect, useRef, useState} from 'react';
import AdminProfileBase from "../../assets/img/AdminProfileBase.jpg";
import {BiEdit, BiTrash} from "react-icons/bi";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import MsgConfirmModal from "../../components/web/modal/MsgConfirmModal";

function AdminModiPg() {

    /** region DataTable */

    const [adminUserdata, setAdminUserData] = useState([]);
    const [userProfileImg, setUserProfileImg] = useState("");
    const [userId, setUserId] = useState("");
    const [userName, setUserName] = useState("");
    const [userNicName, setUserNicName] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [userMbNum, setUserMbNum] = useState("");
    const [userSeqNum, setUserSeqNum] = useState("");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    /** endregion */

    /** region Function */

    const SSAT = sessionStorage.getItem('adminToken');
    const LCAT = localStorage.getItem('adminToken');
    const navigate = useNavigate()
    const [profilePhotoLine, setProfilePhotoLine] = useState(AdminProfileBase);
    const [nicknameError, setNickNameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [samePasswordError, setSamePasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [isCurrentPasswordEntered, setIsCurrentPasswordEntered] = useState(false);
    const [isPasswordChangeAttempted, setIsPasswordChangeAttempted] = useState(false);
    const phoneRef = useRef();

    const formData = new FormData();
    const PhotoChangeHandler = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        formData.append('file', file);
        setProfilePhotoLine(URL.createObjectURL(file));
    };

    const onChangeUserPhone = (e) => {
        const value = phoneRef.current.value.replace(/\D+/g, "");
        const numberLength = 11;

        let result;
        result = "";

        for (let i = 0; i < value.length && i < numberLength; i++) {
            switch (i) {
                case 3:
                    result += "-";
                    break;
                case 7:
                    result += "-";
                    break;

                default:
                    break;
            }
            result += value[i];
        }
        phoneRef.current.value = result;
        setUserPhone(e.target.value);
    };

    const formatPhoneNumber = (phone) => {
        if (!phone) return "";
        const match = phone.match(/^(\d{3})(\d{4})(\d{4})$/);
        if (match) {
            return `${match[1]}-${match[2]}-${match[3]}`;
        }
        return phone;
    };

    const onChangePassword = (e) => {
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()])(?=.*[0-9]).{8,20}$/;
        if ((!e.target.value || (passwordRegex.test(e.target.value)))) {
            setPasswordError(false);
        } else {
            setPasswordError(true);
        }
        setPassword(e.target.value);
        setIsCurrentPasswordEntered(e.target.value.length > 0);
        setIsPasswordChangeAttempted(e.target.value !== '');
    };

    const onChangeNewPassword = (e) => {
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()])(?=.*[0-9]).{8,20}$/;
        const newPasswordValue = e.target.value;

        if ((!newPasswordValue || passwordRegex.test(newPasswordValue))) {
            setNewPasswordError(false);
        } else {
            setNewPasswordError(true);
        }

        if (password && newPasswordValue === password) {
            setSamePasswordError(true);
        } else {
            setSamePasswordError(false);
        }

        if (!confirmPassword || newPasswordValue === confirmPassword) {
            setConfirmPasswordError(false);
        } else {
            setConfirmPasswordError(true);
        }

        setNewPassword(newPasswordValue);
        setIsPasswordChangeAttempted(newPasswordValue !== '');
    };

    const onChangeConfirmPassword = (e) => {
        if (newPassword === e.target.value) setConfirmPasswordError(false);
        else setConfirmPasswordError(true);
        setConfirmPassword(e.target.value);
        setIsPasswordChangeAttempted(e.target.value !== '');
    };

    //region Alert

    const [photoDeleteSelectSt, setPhotoDeleteSelectSt] = useState(false);
    const [photoDeleteConfirmSt, setPhotoDeleteConfirmSt] = useState(false);
    const [confirmShow3, setConfirmShow3] = useState(false);

    const [alertShow, setAlertShow] = useState(false);
    const msgConfirmData1 = "프로필정보를 수정하시겠습니까?";
    const msgConfirmData2 = "입력정보를 확인해주세요.";
    const MsgConfirmData5 = "프로필 이미지가 적용되었습니다.";
    const MsgConfirmData6 = "프로필 이미지를 삭제하시겠습니까?";
    const MsgConfirmData7 = "이미지가 삭제되었습니다.";
    const conFirmAlert = (()=> {setAlertShow(true)})
    const modalCancel = useCallback (() => {setAlertShow(false);})

    const confirm3 = (() => {
        setConfirmShow3(false);
    })

    const photoDeleteCancelFn = (() => {
        setPhotoDeleteSelectSt(false);
    })

    const photoDeleteConfirmFn = (() => {
        setPhotoDeleteConfirmSt(false);
    })

    //endregion

    /** endregion */

    /** region Event */

    const fileSelect = useRef(null);

    const photoChangeAndSave = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        PhotoChangeHandler(e);
        profileSave().then();
    };

    useEffect(() => {
        setTimeout(() => {
            axios({
                method: 'get',
                url: '/member/modiInfo',
                headers: {
                    Authorization: LCAT ? LCAT : SSAT
                }
            })
                .then((response) => {
                    setAdminUserData(response.data);
                    setUserId(response.data[0].id)
                    setUserName(response.data[0].userName)
                    setUserNicName(response.data[0].nicName)
                    const formattedPhone = formatPhoneNumber(response.data[0].phone);
                    setUserPhone(formattedPhone);
                    setUserSeqNum(response.data[0].seqNum)
                    setUserMbNum(response.data[0].mbNum)
                    setUserProfileImg(response.data[0].profileDTO)

                    if (response.data[0].profileDTO === null) {
                        setProfilePhotoLine(AdminProfileBase)
                    } else {
                        setProfilePhotoLine("https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + response.data[0].profileDTO.filename)
                    }
                })
        },);
    }, [])

    const userInfoModify = async () => {
        if (password === newPassword) {
            setSamePasswordError(true);
            return;
        }

        let data = {
            id: userId,
            userName: userName,
            nicName: userNicName,
            phone: userPhone,
            password: newPassword,
            passwordConfirm: confirmPassword,
            lang: "관리자",
            mailRecFl: "Y",
            seqNum: userSeqNum,
            mbNum: userMbNum,
            userOutFl: "N",
            socialJoinFl: false
        };

        const isChangingPassword = password && newPassword && confirmPassword && (newPassword === confirmPassword);

        if (isChangingPassword) {
            data = {
                ...data,
                nowPassword: password,
                password: newPassword,
                passwordConfirm: confirmPassword,
            };
        }

        try {
            await axios.post('/member/adminInfoModify', data, {
                headers: {
                    Authorization: LCAT ? LCAT : SSAT
                },
                params: {
                    nowPassword: password,
                },
            });
            navigate("/btcorpad");
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const profileSave = async () => await axios({
        method: 'post',
        url: '/member/profileSave',
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: LCAT ? LCAT : SSAT
        },
        params: {
            email: userId
        },
        data: formData
    })
        .then(() => {
            setConfirmShow3(true);
        })
        .catch(() => {});

    const photoDeleteButton = (() => {
        setPhotoDeleteSelectSt(true);
    })

    const profileDelete = () => {
        if (!userProfileImg || !userProfileImg.seqnum) {
            return;
        }

        axios({
            method: 'post',
            url: '/member/profileDelete',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                id: userProfileImg.seqnum
            },
        })
            .then(() => {
                setPhotoDeleteSelectSt(false);
                setProfilePhotoLine(AdminProfileBase);
                setPhotoDeleteConfirmSt(true);
            })
            .catch(() => {});
    };

    const onChangeNickName = async (e) => {
        const userNickNameRegex = /^[a-zA-Zㄱ-힣][a-zA-Zㄱ-힣 ]*$/;
        if ((!e.target.value || (userNickNameRegex.test(e.target.value)))) setNickNameError(false);
        else setNickNameError(true);

        const newNickname = e.target.value;
        setUserNicName(newNickname);

        if (newNickname.length < 2 || newNickname.length > 5) {
            setNickNameError('닉네임은 2~5자 입니다.');
            return;
        }

        try {
            const { data } = await axios.get('/member/validateNickname', {
                params: {
                    nickname: newNickname,
                    userId: userId
                }
            });
            if (data.isDuplicate) {
                setNickNameError("'" + newNickname + "'" + "는(은) 이미 사용중입니다.");
            } else {
                setNickNameError('');
            }
        } catch (error) {
            console.error('Error: ', error);
            setNickNameError('닉네임 Err');
        }
    };

    /** endregion */

    return (
        <div className="AdminReviewPg">
            <div className="admin-work-title">
                <h5>프로필 수정</h5>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card" style={{height: "830px"}}>
                    <div style={{display: "flex", flexDirection: "column", marginLeft: "auto", marginRight: "auto", width: "380px", textAlign: "center", alignItems: "center"}}>
                        <div style={{marginTop: "10px", marginBottom: "35px"}}>
                            <div className="admin-profile-modi" style={{display: "block", width: "280px", height: "280px", borderRadius: "50%", verticalAlign: "middle"}}>
                                <img className="admin-profile-modi-img" alt="" src={profilePhotoLine}/>
                                <div className="profile-modi-icon" style={{display: "flex", justifyContent: "space-around", position: "relative", bottom: "165px"}}>
                                    <BiEdit className="c-point font-s-40 pro-edit-icon font-c-btorage" onClick={() => fileSelect.current.click()} />
                                    <input type="file" style={{display: "none"}} ref={fileSelect} accept=".jpg, .png" onChange={photoChangeAndSave}/>
                                    <BiTrash className="c-point font-s-40 pro-edit-icon font-c-btorage" onClick={photoDeleteButton}/>
                                </div>
                            </div>
                        </div>
                        <div  style={{marginTop: "8px", marginBottom: "8px"}}>
                            <label style={{width: "130px"}}>아이디</label>
                            <input className="admin-input-line wd-200  ht-35 p-l-5 font-s-15" type={"text"} disabled={true} value={userId}/>
                        </div>
                        <div  style={{marginTop: "8px", marginBottom: "8px"}}>
                            <label style={{width: "130px"}}>이름</label>
                            <input className="admin-input-line wd-200  ht-35 p-l-5 font-s-15" type={"text"} disabled={true} value={userName}/>
                        </div>
                        <div  style={{marginTop: "8px", marginBottom: "8px"}}>
                            <label style={{width: "130px"}}>닉네임</label>
                            <input className="admin-input-line admin-search-input wd-200 ht-35 p-l-5 font-s-15" minLength="2" maxLength="5" placeholder="닉네임"
                                   onChange={onChangeNickName} type={"text"} value={userNicName}/>
                            {nicknameError && <span className="ErrMsg m-t-4" style={{position: "absolute"}}>{nicknameError}</span>}
                        </div>
                        <div style={{marginTop: "8px", marginBottom: "8px"}}>
                            <label style={{width: "130px"}}>연락처</label>
                            <input className="admin-input-line admin-search-input wd-200 ht-35 p-l-5 font-s-15" type="tel" name={userMbNum} ref={phoneRef} placeholder="연락처(숫자만 입력)"
                                   onChange={onChangeUserPhone} value={userPhone}/>
                        </div>
                        <div style={{marginTop: "30px", marginBottom: "8px"}}>
                            <label style={{width: "130px"}}>현재비밀번호</label>
                            <input className="admin-input-line admin-search-input wd-200 ht-35 p-l-5 font-s-15" type={"password"} minLength="8" maxLength="20"
                                   onChange={onChangePassword} value={password}/>
                            {passwordError && <span className="ErrMsg m-t-4" style={{position: "absolute"}}>비밀번호가 일치하지 않습니다</span>}
                        </div>
                        <div style={{marginTop: "8px", marginBottom: "8px"}}>
                            <label style={{width: "130px"}}>변경비밀번호</label>
                            <input className="admin-input-line wd-200 ht-35 p-l-5 font-s-15" type={"password"} minLength="8" maxLength="20"
                                   onChange={onChangeNewPassword} value={newPassword} disabled={!isCurrentPasswordEntered}/>
                            {newPasswordError && <span className="ErrMsg m-t-4" style={{position: "absolute"}}>8~20자 및 숫자/대,소문자/특수문자를 모두 포함해야 합니다</span>}
                            {samePasswordError && <span className="ErrMsg m-t-4" style={{position: "absolute"}}>현재 비밀번호와 동일합니다</span>}
                        </div>
                        <div style={{marginTop: "8px", marginBottom: "8px"}}>
                            <label style={{width: "130px"}}>비밀번호확인</label>
                            <input className="admin-input-line wd-200 ht-35 p-l-5 font-s-15" type={"password"} minLength="8" maxLength="20"
                                   onChange={onChangeConfirmPassword} value={confirmPassword} disabled={!isCurrentPasswordEntered}/>
                            {confirmPasswordError && <span className="ErrMsg m-t-4" style={{position: "absolute"}}>비밀번호가 일치하지 않습니다</span>}
                        </div>
                        <div><p className="font-s-12" style={{color: "#969696"}}>※닉네임 및 연락처만 변경시 현재비밀번호 입력 필요.</p></div>
                        <div style={{marginTop: "35px"}}>
                            <button className="admin-bt-color btorage-click-event wd-200 ht-40" style={{width: "331px", fontSize: "19px"}}
                                    disabled={!(isPasswordChangeAttempted || '' === nicknameError)} onClick={conFirmAlert}>저장</button>
                        </div>
                    </div>
                </div>
            </div>
            {confirmShow3 === true ?
                <MsgConfirmModal confirm3={confirm3} msgData5={MsgConfirmData5} confirmShow3={confirmShow3}/>
                :
                null
            }
            {photoDeleteSelectSt === true ?
                <MsgSelectModal photoDeleteSelectSt={photoDeleteSelectSt} photoDeleteCancelFn={photoDeleteCancelFn} photoDeleteExecution={profileDelete} msgData7={MsgConfirmData6}
                />
                :
                null
            }
            {photoDeleteConfirmSt === true ?
                <MsgConfirmModal photoDeleteConfirmSt={photoDeleteConfirmSt} photoDeleteConfirmFn={photoDeleteConfirmFn} MsgConfirmData7={MsgConfirmData7}/>
                :
                null
            }

            {alertShow === true ? <MsgSelectModal alertShow={alertShow} eventHandle1={modalCancel} eventHandle2={userInfoModify} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
        </div>
    );
}

export default AdminModiPg
