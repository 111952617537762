import React, {useEffect, useRef} from 'react';
import '../../../css/web/MsgSelectModal.css';
import alert from "../../../assets/img/alert.png"
import {useTranslation} from "react-i18next";


function MsgConfirmModal({confirm, confirm2, confirm3, confirm4, msgData3, msgData4, msgData5, msgData6, confirmShow1, confirmShow2, confirmShow3, confirmShow4
, photoDeleteConfirmSt, photoDeleteConfirmFn, MsgConfirmData7, deleteConfirmSt, deleteConfirmHandleFn, deleteConfirmMsg, regiWaitingFn, regiWaitingSt, regiWaitingMsg
    ,lgWaitingSt, confirmModalCloseFn, lgRegiWaitingMsg, integrationOrderMsgSt, orderTypeFalseFn, orderTypeConfirmMsg, confirmModalShowSt, recmodiModalMsg, confirmModalFalseFn
    ,recmodiMsgSt, recmodiDoneMsg, recmodiFalseFn, payErrorMsg, payErrorMsgClose, payErrorMsgWord, noSelectedPaypalExShow, noSelectedPaypalExMsg, closeNoSelectedPaypalExShow
                             ,allCartProdStNonSeqnumShow, closeAllCartProdStNonSeqNumModalShow, allProdStNonSeqnumMsg, allOrderProdStNonSeqnumShow, closeAllOrderProdStNonSeqNumModalShow,
    allOfflineProdStNonSeqnumShow, closeAllOfflineProdStNonSeqNumModalShow, allTransferProdStNonSeqnumShow, closeAllTransferProdStNonSeqNumModalShow, allDomesticProdStNonSeqnumShow,
    closeAllDomesticProdStNonSeqNumModalShow, allDeliveryProdStNonSeqnumShow, closeAllDeliveryProdStNonSeqNumModalShow
}) {

    const { t } = useTranslation();

    const confirmButtonRef1 = useRef(null);
    const confirmButtonRef2 = useRef(null);
    const confirmButtonRef3 = useRef(null);
    const confirmButtonRef4 = useRef(null);
    const photoDeleteConfirmButtonRef = useRef(null);
    const deleteConfirmButtonRef = useRef(null);
    const regiWaitingButtonRef = useRef(null);
    const lgWaitingButtonRef = useRef(null);
    const integrationOrderMsgButtonRef = useRef(null);
    const confirmModalShowButtonRef = useRef(null);
    const recmodiMsgButtonRef = useRef(null);
    const payErrorMsgButtonRef = useRef(null);
    const noSelectedPaypalExShowButtonRef = useRef(null);
    const allCartProdStNonSeqnumShowButtonRef = useRef(null);
    const allOrderProdStNonSeqnumShowButtonRef = useRef(null);
    const allOfflineProdStNonSeqnumShowButtonRef = useRef(null);
    const allTransferProdStNonSeqnumShowButtonRef = useRef(null);
    const allDomesticProdStNonSeqnumShowButtonRef = useRef(null);
    const allDeliveryProdStNonSeqnumShowButtonRef = useRef(null);


    useEffect(() => {
        const handleEnterKey = (event) => {
            if (event.key === 'Enter') {
                if (confirmShow1 && confirmButtonRef1.current) {
                    confirmButtonRef1.current.click();
                } else if (confirmShow2 && confirmButtonRef2.current) {
                    confirmButtonRef2.current.click();
                } else if (confirmShow3 && confirmButtonRef3.current) {
                    confirmButtonRef3.current.click();
                } else if (confirmShow4 && confirmButtonRef4.current) {
                    confirmButtonRef4.current.click();
                } else if (photoDeleteConfirmSt && photoDeleteConfirmButtonRef.current) {
                    photoDeleteConfirmButtonRef.current.click();
                } else if (deleteConfirmSt && deleteConfirmButtonRef.current) {
                    deleteConfirmButtonRef.current.click();
                } else if (regiWaitingSt && regiWaitingButtonRef.current) {
                    regiWaitingButtonRef.current.click();
                } else if (lgWaitingSt && lgWaitingButtonRef.current) {
                    lgWaitingButtonRef.current.click();
                } else if (integrationOrderMsgSt && integrationOrderMsgButtonRef.current) {
                    integrationOrderMsgButtonRef.current.click();
                } else if (confirmModalShowSt && confirmModalShowButtonRef.current) {
                    confirmModalShowButtonRef.current.click();
                } else if (recmodiMsgSt && recmodiMsgButtonRef.current) {
                    recmodiMsgButtonRef.current.click();
                } else if (payErrorMsg && payErrorMsgButtonRef.current) {
                    payErrorMsgButtonRef.current.click();
                } else if (noSelectedPaypalExShow && noSelectedPaypalExShowButtonRef.current) {
                    noSelectedPaypalExShowButtonRef.current.click();
                } else if (allCartProdStNonSeqnumShow && allCartProdStNonSeqnumShowButtonRef.current) {
                    allCartProdStNonSeqnumShowButtonRef.current.click();
                } else if (allOrderProdStNonSeqnumShow && allOrderProdStNonSeqnumShowButtonRef.current) {
                    allOrderProdStNonSeqnumShowButtonRef.current.click();
                } else if (allOfflineProdStNonSeqnumShow && allOfflineProdStNonSeqnumShowButtonRef.current) {
                    allOfflineProdStNonSeqnumShowButtonRef.current.click();
                } else if (allTransferProdStNonSeqnumShow && allTransferProdStNonSeqnumShowButtonRef.current) {
                    allTransferProdStNonSeqnumShowButtonRef.current.click();
                } else if (allDomesticProdStNonSeqnumShow && allDomesticProdStNonSeqnumShowButtonRef.current) {
                    allDomesticProdStNonSeqnumShowButtonRef.current.click();
                } else if (allDeliveryProdStNonSeqnumShow && allDeliveryProdStNonSeqnumShowButtonRef.current) {
                    allDeliveryProdStNonSeqnumShowButtonRef.current.click();
                }
            }
        };

        // 컴포넌트가 마운트될 때 포커스를 버튼으로 이동
        if (confirmButtonRef1.current) {
            confirmButtonRef1.current.focus();
        }
        if (confirmButtonRef2.current) {
            confirmButtonRef2.current.focus();
        }
        if (confirmButtonRef3.current) {
            confirmButtonRef3.current.focus();
        }
        if (confirmButtonRef4.current) {
            confirmButtonRef4.current.focus();
        }
        if (photoDeleteConfirmButtonRef.current) {
            photoDeleteConfirmButtonRef.current.focus();
        }
        if (deleteConfirmButtonRef.current) {
            deleteConfirmButtonRef.current.focus();
        }
        if (regiWaitingButtonRef.current) {
            regiWaitingButtonRef.current.focus();
        }
        if (lgWaitingButtonRef.current) {
            lgWaitingButtonRef.current.focus();
        }
        if (integrationOrderMsgButtonRef.current) {
            integrationOrderMsgButtonRef.current.focus();
        }
        if (confirmModalShowButtonRef.current) {
            confirmModalShowButtonRef.current.focus();
        }
        if (recmodiMsgButtonRef.current) {
            recmodiMsgButtonRef.current.focus();
        }
        if (payErrorMsgButtonRef.current) {
            payErrorMsgButtonRef.current.focus();
        }
        if (noSelectedPaypalExShowButtonRef.current) {
            noSelectedPaypalExShowButtonRef.current.focus();
        }
        if (allCartProdStNonSeqnumShowButtonRef.current) {
            allCartProdStNonSeqnumShowButtonRef.current.focus();
        }
        if (allOrderProdStNonSeqnumShowButtonRef.current) {
            allOrderProdStNonSeqnumShowButtonRef.current.focus();
        }
        if (allOfflineProdStNonSeqnumShowButtonRef.current) {
            allOfflineProdStNonSeqnumShowButtonRef.current.focus();
        }
        if (allTransferProdStNonSeqnumShowButtonRef.current) {
            allTransferProdStNonSeqnumShowButtonRef.current.focus();
        }
        if (allDomesticProdStNonSeqnumShowButtonRef.current) {
            allDomesticProdStNonSeqnumShowButtonRef.current.focus();
        }
        if (allDeliveryProdStNonSeqnumShowButtonRef.current) {
            allDeliveryProdStNonSeqnumShowButtonRef.current.focus();
        }

        document.addEventListener('keydown', handleEnterKey);

        // Cleanup the event listener
        return () => {
            document.removeEventListener('keydown', handleEnterKey);
        };
    }, [confirmShow1, confirmShow2, confirmShow3, confirmShow4, photoDeleteConfirmSt, deleteConfirmSt, regiWaitingSt, lgWaitingSt,
        integrationOrderMsgSt, confirmModalShowSt, recmodiMsgSt, payErrorMsg, noSelectedPaypalExShow, allCartProdStNonSeqnumShow, allOrderProdStNonSeqnumShow,
    allOfflineProdStNonSeqnumShow, allTransferProdStNonSeqnumShow, allDomesticProdStNonSeqnumShow, allDeliveryProdStNonSeqnumShow]);




    return (
        <div className="blurContainer">
            <div className="alertFrame">
                <div className="alertImgCon">
                    <div className="alertImgBox"><img alt="" src={alert}/></div>
                </div>
                <div className="alertMessage" style={{textAlign: "center", height: "100px"}}>
                    {confirmShow1 ? <p style={{margin:"0", fontSize:"14px"}}>{msgData3}</p> :
                            confirmShow2 ? <p style={{margin:"0", fontSize:"18px"}}>{msgData4}</p> :
                                confirmShow3 ? <p style={{margin:"0", fontSize:"18px"}}>{msgData5}</p> :
                                    confirmShow4 ? <p style={{margin:"0", fontSize:"18px"}}>{msgData6}</p> :
                                        photoDeleteConfirmSt ? <p style={{margin:"0", fontSize:"18px"}}>{MsgConfirmData7}</p> :
                                                deleteConfirmSt ? <p style={{margin:"0", fontSize:"18px"}}>{deleteConfirmMsg}</p> :
                                                    regiWaitingSt ? <p style={{margin:"0", fontSize:"18px"}}>{regiWaitingMsg}</p> :
                                                        lgWaitingSt ? <p style={{margin:"0", fontSize:"18px"}}>{lgRegiWaitingMsg}</p> :
                                                            integrationOrderMsgSt ? <p style={{margin:"0", fontSize:"18px"}}>{orderTypeConfirmMsg}</p> :
                                                                confirmModalShowSt ? <p style={{margin:"0", fontSize:"18px"}}>{recmodiModalMsg}</p> :
                                                                    recmodiMsgSt ? <p style={{margin:"0", fontSize:"18px"}}>{recmodiDoneMsg}</p> :
                                                                        payErrorMsg ? <p style={{margin:"0", fontSize:"18px"}}>{payErrorMsgWord}</p> :
                                                                            noSelectedPaypalExShow ? <p style={{margin:"0", fontSize:"18px"}}>{noSelectedPaypalExMsg}</p> :
                                                                                allCartProdStNonSeqnumShow || allOrderProdStNonSeqnumShow || allOfflineProdStNonSeqnumShow || allTransferProdStNonSeqnumShow ||
                                                                                    allDomesticProdStNonSeqnumShow || allDeliveryProdStNonSeqnumShow?
                                                                                    <p style={{margin:"0", fontSize:"18px"}}>{allProdStNonSeqnumMsg}</p> :
                        null}
                </div>
                <div className="alertBottonFrame" style={{textAlign: "center", height: "80px", padding:"0 10px"}}>
                        {confirmShow1 ? <button ref={confirmButtonRef1} className="MainReviewButton1 btn-click-event" style={{ width: "110px" }} onClick={confirm}>{t('st6')}</button> : null}
                        {confirmShow2 ? <button ref={confirmButtonRef2} className="MainReviewButton1 btn-click-event" style={{ width: "110px" }} onClick={confirm2}>{t('st6')}</button> : null}
                        {confirmShow3 ? <button ref={confirmButtonRef3} className="MainReviewButton1 btn-click-event" style={{ width: "110px" }} onClick={confirm3}>{t('st6')}</button> : null}
                        {confirmShow4 ? <button ref={confirmButtonRef4} className="MainReviewButton1 btn-click-event" style={{ width: "110px" }} onClick={confirm4}>{t('st6')}</button> : null}
                        {photoDeleteConfirmSt ? <button ref={photoDeleteConfirmButtonRef} className="MainReviewButton1 btn-click-event" style={{ width: "110px" }} onClick={photoDeleteConfirmFn}>{t('st6')}</button> : null}
                        {deleteConfirmSt ? <button ref={deleteConfirmButtonRef} className="MainReviewButton1 btn-click-event" style={{ width: "110px" }} onClick={deleteConfirmHandleFn}>{t('st6')}</button> : null}
                        {regiWaitingSt ? <button ref={regiWaitingButtonRef} className="MainReviewButton1 btn-click-event" style={{ width: "110px" }} onClick={regiWaitingFn}>{t('st6')}</button> : null}
                        {lgWaitingSt ? <button ref={lgWaitingButtonRef} className="MainReviewButton1 btn-click-event" style={{ width: "110px" }} onClick={confirmModalCloseFn}>{t('st6')}</button> : null}
                        {integrationOrderMsgSt ? <button ref={integrationOrderMsgButtonRef} className="MainReviewButton1 btn-click-event" style={{ width: "110px" }} onClick={orderTypeFalseFn}>{t('st6')}</button> : null}
                        {confirmModalShowSt ? <button ref={confirmModalShowButtonRef} className="MainReviewButton1 btn-click-event" style={{ width: "110px" }} onClick={confirmModalFalseFn}>{t('st6')}</button> : null}
                        {recmodiMsgSt ? <button ref={recmodiMsgButtonRef} className="MainReviewButton1 btn-click-event" style={{ width: "110px" }} onClick={recmodiFalseFn}>{t('st6')}</button> : null}
                        {payErrorMsg ? <button ref={payErrorMsgButtonRef} className="MainReviewButton1 btn-click-event" style={{ width: "110px" }} onClick={payErrorMsgClose}>{t('st6')}</button> : null}
                    {noSelectedPaypalExShow ? <button ref={noSelectedPaypalExShowButtonRef} className="MainReviewButton1 btn-click-event" style={{ width: "110px" }} onClick={closeNoSelectedPaypalExShow}>{t('st6')}</button> : null}
                    {allCartProdStNonSeqnumShow ? <button ref={allCartProdStNonSeqnumShowButtonRef} className="MainReviewButton1 btn-click-event" style={{ width: "110px" }} onClick={closeAllCartProdStNonSeqNumModalShow}>{t('st6')}</button> : null}
                    {allOrderProdStNonSeqnumShow ? <button ref={allOrderProdStNonSeqnumShowButtonRef} className="MainReviewButton1 btn-click-event" style={{ width: "110px" }} onClick={closeAllOrderProdStNonSeqNumModalShow}>{t('st6')}</button> : null}
                    {allOfflineProdStNonSeqnumShow ? <button ref={allOfflineProdStNonSeqnumShowButtonRef} className="MainReviewButton1 btn-click-event" style={{ width: "110px" }} onClick={closeAllOfflineProdStNonSeqNumModalShow}>{t('st6')}</button> : null}
                    {allTransferProdStNonSeqnumShow ? <button ref={allTransferProdStNonSeqnumShowButtonRef} className="MainReviewButton1 btn-click-event" style={{ width: "110px" }} onClick={closeAllTransferProdStNonSeqNumModalShow}>{t('st6')}</button> : null}
                    {allDomesticProdStNonSeqnumShow ? <button ref={allDomesticProdStNonSeqnumShowButtonRef} className="MainReviewButton1 btn-click-event" style={{ width: "110px" }} onClick={closeAllDomesticProdStNonSeqNumModalShow}>{t('st6')}</button> : null}
                    {allDeliveryProdStNonSeqnumShow ? <button ref={allDeliveryProdStNonSeqnumShowButtonRef} className="MainReviewButton1 btn-click-event" style={{ width: "110px" }} onClick={closeAllDeliveryProdStNonSeqNumModalShow}>{t('st6')}</button> : null}
                </div>
            </div>
        </div>
    );
}

export default MsgConfirmModal
