import "../../css/mobile/MUsageGuidePg.css";
import CommonHeader from "../../assets/img/commonHeader.png";
import CommonGuide from "../../assets/img/commonGuide.png";
import MHeader from "./MHeader";
import React, {useState} from "react";
import GuideModal from "../web/modal/GuideModal";
import {useTranslation} from "react-i18next";
import detailCart from "../../assets/img/detailCart.png";
import detailOrder from "../../assets/img/detailOrder.png";
import detailOffline from "../../assets/img/detailOffline.png";
import detailTransfer from "../../assets/img/detailTransfer.png";
import detailDomestic from "../../assets/img/detailDomestic.png";
import detailDelivery from "../../assets/img/detailDelivery.png";
import guidePc1 from "../../assets/img/guidePc1.png";
import guidePc2 from "../../assets/img/guidePc2.png";
import guidePc3 from "../../assets/img/guidePc3.png";
import guidePc4 from "../../assets/img/guidePc4.png";
import guidePc5 from "../../assets/img/guidePc5.png";
import guidePc6 from "../../assets/img/guidePc6.png";
import guideOne from "../../assets/img/guideOne.png";
import comment from "../../assets/img/reviewComment.png";
import guidePc7 from "../../assets/img/guidePc7.png";
import guidePc8 from "../../assets/img/guidePc8.png";

const MUsageGuidePg = () => {
    const usageGuideOfCart = [
        {title: '장바구니담기', img: guidePc1},
        {title: '장바구니신청', img: guidePc2},
        {title: '해외배송비 청구', img: guidePc3},
        {title: '해외배송비 결제', img: guidePc4},
        {title: '해외로 출발', img: guidePc5},
        {title: '수령', img: guidePc6},
    ];

    const usageGuideOfOrder = [
        {title: '구매대행신청', img: guidePc2},
        {title: '상품금액결제', img: guidePc4},
        {title: '해외배송비 청구', img: guidePc3},
        {title: '해외배송비 결제', img: guidePc4},
        {title: '해외로 출발', img: guidePc5},
        {title: '수령', img: guidePc6},
    ];

    const usageGuideOfDelivery = [
        {title: '물류센터로 주문', img: guidePc8},
        {title: '배송대행신청', img: guidePc2},
        {title: '해외배송비 청구', img: guidePc3},
        {title: '해외배송비 결제', img: guidePc4},
        {title: '해외로 출발', img: guidePc5},
        {title: '수령', img: guidePc6},
    ];

    const usageGuideOfOffline = [
        {title: '오프라인신청', img: guidePc2},
        {title: '상품금액결제', img: guidePc4},
        {title: '해외배송비 청구', img: guidePc3},
        {title: '해외배송비 결제', img: guidePc4},
        {title: '해외로 출발', img: guidePc5},
        {title: '수령', img: guidePc6},
    ];

    const usageGuideOfTransfer = [
        {title: '이체대행신청', img: guidePc2},
        {title: '이체금액결제', img: guidePc4},
        {title: '해외배송도 가능', img: guidePc5},
    ];

    const usageGuideOfDomestic = [
        {title: '국내배송신청', img: guidePc2},
        {title: '상품금액결제', img: guidePc4},
        {title: '한국주소로 출발', img: guidePc7},
    ];

    const {t} = useTranslation();

    const [cartGuide, setCartGuide] = useState(true);
    const [orderGuide, setOrderGuide] = useState(false);
    const [offlineGuide, setOfflineGuide] = useState(false);
    const [transferGuide, setTransferGuide] = useState(false);
    const [domesticGuide, setDomesticGuide] = useState(false);
    const [deliveryGuide, setDeliveryGuide] = useState(false);

    const [cartGuidePopup, setCartGuidePopup] = useState(false);
    const [orderGuidePopup, setOrderGuidePopup] = useState(false);
    const [offlineGuidePopup, setOfflineGuidePopup] = useState(false);
    const [transferGuidePopup, setTransferGuidePopup] = useState(false);
    const [domesticGuidePopup, setDomesticGuidePopup] = useState(false);
    const [deliveryGuidePopup, setDeliveryGuidePopup] = useState(false);

    const cartGuideHeader = "장바구니 서비스 설명";
    const cartGuideContent = "구매하고 싶은 한국 상품들을 고객님의 한국 쇼핑몰 계정의 장바구니에 담은 후 쇼핑몰 계정정보를 입력하여 주문서를 작성하시면 비토리지에서 고객님의 계정으로 로그인하여 장바구니의 상품들을 구매 후 해외로 배송해드리는 서비스입니다(구매하고자 하는 상품의 쇼핑몰 계정이 있어야 하며, 상품정보를 일일이 작성하지 않아도 되기 때문에 상품항목이 많을수록 간편합니다)";
    const orderGuideHeader = "구매대행 서비스 설명";
    const orderGuideContent = "구매하고 싶은 한국 상품들을 상품 URL 주소를 입력하여 작성하시면 비토리지 측의 쇼핑몰 계정으로 주문 후 해외로 배송해드리는 서비스입니다(한국 쇼핑몰 계정이 없어도 원하는 상품들을 모두 구매할 수 있습니다)";
    const offlineGuideHeader = "오프라인 서비스 설명";
    const offlineGuideContent = "온라인에 판매하지 않고 한국 오프라인 매장에 판매하는 상품을 구매 후 해외로 배송해드리는 서비스입니다(수수료 부과)";
    const transferGuideHeader = "이체대행 서비스 설명";
    const transferGuideContent = "카드결제가 불가능한 판매처인 경우나 국내 계좌로 한국 돈(KRW)을 이체하고 싶으실 경우 대신 입금해드리는 서비스입니다(수수료 부과)";
    const domesticGuideHeader = "국내배송 서비스 설명";
    const domesticGuideContent = "구매하고 싶은 상품들을 주문서로 작성해주시면 비토리지 계정으로 구매해드린 후, 해외가 아닌 한국(국내)로 배송해드리는 서비스입니다(수수료 부과)";
    const deliveryGuideHeader = "배송대행 서비스 설명";
    const deliveryGuideContent = "비토리지에서 구매하지 않고 고객님께서 직접 구매한 후 비토리지 물류센터로 주문해주시거나 배송해주시면 물류센터로부터 해외로 '배송만' 대행해드리는 서비스입니다";

    const changeSelectedGuide = ((guide) => {
        if (guide === 'cart') {
            setCartGuide(true);
            setOrderGuide(false);
            setOfflineGuide(false);
            setTransferGuide(false);
            setDomesticGuide(false);
            setDeliveryGuide(false);
        } else if (guide === 'order') {
            setCartGuide(false);
            setOrderGuide(true);
            setOfflineGuide(false);
            setTransferGuide(false);
            setDomesticGuide(false);
            setDeliveryGuide(false);
        } else if (guide === 'offline') {
            setCartGuide(false);
            setOrderGuide(false);
            setOfflineGuide(true);
            setTransferGuide(false);
            setDomesticGuide(false);
            setDeliveryGuide(false);
        } else if (guide === 'transfer') {
            setCartGuide(false);
            setOrderGuide(false);
            setOfflineGuide(false);
            setTransferGuide(true);
            setDomesticGuide(false);
            setDeliveryGuide(false);
        } else if (guide === 'domestic') {
            setCartGuide(false);
            setOrderGuide(false);
            setOfflineGuide(false);
            setTransferGuide(false);
            setDomesticGuide(true);
            setDeliveryGuide(false);
        } else if (guide === 'delivery') {
            setCartGuide(false);
            setOrderGuide(false);
            setOfflineGuide(false);
            setTransferGuide(false);
            setDomesticGuide(false);
            setDeliveryGuide(true);
        }
    })

    const openCartGuidePopup = (() => {
        setCartGuidePopup(true);
    })
    const openOrderGuidePopup = (() => {
        setOrderGuidePopup(true);
    })
    const openOfflineGuidePopup = (() => {
        setOfflineGuidePopup(true);
    })
    const openTransferGuidePopup = (() => {
        setTransferGuidePopup(true);
    })
    const openDomesticGuidePopup = (() => {
        setDomesticGuidePopup(true);
    })
    const openDeliveryGuidePopup = (() => {
        setDeliveryGuidePopup(true);
    })

    const closeCartGuidePopup = (() => {
        setCartGuidePopup(false);
    })
    const closeOrderGuidePopup = (() => {
        setOrderGuidePopup(false);
    })
    const closeOfflineGuidePopup = (() => {
        setOfflineGuidePopup(false);
    })
    const closeTransferGuidePopup = (() => {
        setTransferGuidePopup(false);
    })
    const closeDomesticGuidePopup = (() => {
        setDomesticGuidePopup(false);
    })
    const closeDeliveryGuidePopup = (() => {
        setDeliveryGuidePopup(false);
    })


    return (
        <div className={'m-a-usage-guide-pg-container'}>
            <MHeader title={t(`hdSMenu2`)} url={"/"}/>
            <div className='m-a-usage-guide-sub-title'>
                <img className='m-a-usage-guide-sub-title-icon' src={CommonHeader} alt=''/>
                <div className='m-a-usage-guide-sub-title-text'> {t(`fmSmallMenu5`)}</div>
            </div>
            <div className={'m-a-usage-guide-divider'}/>
            <div className={'m-a-usage-guide-grid-frame'}>
                {/*장바구니가이드*/}
                <div className={`m-a-usage-guide-item-box ${cartGuide && 'selected'}`}
                     onClick={() => changeSelectedGuide('cart')}>
                    <div className={'m-a-usage-guide-item-img-box'}>
                        <img className={'m-a-usage-guide-item-img'} src={detailCart} alt={'cart'}/>
                    </div>
                    <div className={'m-a-usage-guide-item-title'}> 장바구니 가이드</div>
                </div>
                {/*구매대행가이드*/}
                <div className={`m-a-usage-guide-item-box ${orderGuide && 'selected'}`}
                     onClick={() => changeSelectedGuide('order')}>
                    <div className={'m-a-usage-guide-item-img-box'}>
                        <img className={'m-a-usage-guide-item-img'} src={detailOrder} alt={'order'}/>
                    </div>
                    <div className={'m-a-usage-guide-item-title'}> 구매대행 가이드</div>
                </div>
                {/*오프라인가이드*/}
                <div className={`m-a-usage-guide-item-box ${offlineGuide && 'selected'}`}
                     onClick={() => changeSelectedGuide('offline')}>
                    <div className={'m-a-usage-guide-item-img-box'}>
                        <img className={'m-a-usage-guide-item-img'} src={detailOffline} alt={'offline'}/>
                    </div>
                    <div className={'m-a-usage-guide-item-title'}> 오프라인 가이드</div>
                </div>
                {/*이체대행가이드*/}
                <div className={`m-a-usage-guide-item-box ${transferGuide && 'selected'}`}
                     onClick={() => changeSelectedGuide('transfer')}>
                    <div className={'m-a-usage-guide-item-img-box'}>
                        <img className={'m-a-usage-guide-item-img'} src={detailTransfer} alt={'transfer'}/>
                    </div>
                    <div className={'m-a-usage-guide-item-title'}> 이체대행 가이드</div>
                </div>
                {/*국내배송가이드*/}
                <div className={`m-a-usage-guide-item-box ${domesticGuide && 'selected'}`}
                     onClick={() => changeSelectedGuide('domestic')}>
                    <div className={'m-a-usage-guide-item-img-box'}>
                        <img className={'m-a-usage-guide-item-img'} src={detailDomestic} alt={'domestic'}/>
                    </div>
                    <div className={'m-a-usage-guide-item-title'}> 국내배송 가이드</div>
                </div>
                {/*배송대행가이드*/}
                <div className={`m-a-usage-guide-item-box ${deliveryGuide && 'selected'}`}
                     onClick={() => changeSelectedGuide('delivery')}>
                    <div className={'m-a-usage-guide-item-img-box'}>
                        <img className={'m-a-usage-guide-item-img'} src={detailDelivery} alt={'delivery'}/>
                    </div>
                    <div className={'m-a-usage-guide-item-title'}> 배송대행 가이드</div>
                </div>
            </div>
            {cartGuide ? (
                <div className={'m-a-usage-guide-guideline-frame'}>
                    <div className={'m-a-usage-guide-guideline-title-box'}>
                        <div className={'m-a-usage-guide-guideline-title'}> 장바구니 가이드 절차</div>
                        <img className={'m-a-usage-guide-guideline-title-img'} src={CommonGuide} alt={'PopUp'}
                             onClick={openCartGuidePopup}/>
                    </div>
                    <div className={'m-a-usage-guide-description'}>
                        (우측 아이콘을 클릭해주세요)
                    </div>
                    <div className={'m-a-usage-guide-divider'}/>
                    <div className={'m-a-usage-guide-guideline-grid'}>
                        {usageGuideOfCart.map((item, index) => (
                            <div key={index} className={'m-a-usage-guide-guideline-item'}>
                                <div className={'m-a-usage-guide-guideline-item-content'}>
                                    <div className={'m-a-usage-guide-guideline-item-number'}>{index + 1}</div>
                                    <img className={'m-a-usage-guide-guideline-item-img'} src={item.img}
                                         alt={item.title}/>
                                </div>
                                <div className={'m-a-usage-guide-guideline-item-title'}>{item.title}</div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : orderGuide ? (
                <div className={'m-a-usage-guide-guideline-frame'}>
                    <div className={'m-a-usage-guide-guideline-title-box'}>
                        <div className={'m-a-usage-guide-guideline-title'}> 구매대행 가이드 절차</div>
                        <img className={'m-a-usage-guide-guideline-title-img'} src={CommonGuide} alt={'PopUp'}
                             onClick={openOrderGuidePopup}/>
                    </div>
                    <div className={'m-a-usage-guide-description'}>
                        (우측 아이콘을 클릭해주세요)
                    </div>
                    <div className={'m-a-usage-guide-divider'}/>
                    <div className={'m-a-usage-guide-guideline-grid'}>
                        {usageGuideOfOrder.map((item, index) => (
                            <div key={index} className={'m-a-usage-guide-guideline-item'}>
                                <div className={'m-a-usage-guide-guideline-item-content'}>
                                    <div className={'m-a-usage-guide-guideline-item-number'}>{index + 1}</div>
                                    <img className={'m-a-usage-guide-guideline-item-img'} src={item.img}
                                         alt={item.title}/>
                                </div>
                                <div className={'m-a-usage-guide-guideline-item-title'}>{item.title}</div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : offlineGuide ? (
                <div className={'m-a-usage-guide-guideline-frame'}>
                    <div className={'m-a-usage-guide-guideline-title-box'}>
                        <div className={'m-a-usage-guide-guideline-title'}> 구매대행 가이드 절차</div>
                        <img className={'m-a-usage-guide-guideline-title-img'} src={CommonGuide} alt={'PopUp'}
                             onClick={openOfflineGuidePopup}/>
                    </div>
                    <div className={'m-a-usage-guide-description'}>
                        (우측 아이콘을 클릭해주세요)
                    </div>
                    <div className={'m-a-usage-guide-divider'}/>
                    <div className={'m-a-usage-guide-guideline-grid'}>
                        {usageGuideOfOffline.map((item, index) => (
                            <div key={index} className={'m-a-usage-guide-guideline-item'}>
                                <div className={'m-a-usage-guide-guideline-item-content'}>
                                    <div className={'m-a-usage-guide-guideline-item-number'}>{index + 1}</div>
                                    <img className={'m-a-usage-guide-guideline-item-img'} src={item.img}
                                         alt={item.title}/>
                                </div>
                                <div className={'m-a-usage-guide-guideline-item-title'}>{item.title}</div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : transferGuide ? (
                <div className={'m-a-usage-guide-guideline-frame'}>
                    <div className={'m-a-usage-guide-guideline-title-box'}>
                        <div className={'m-a-usage-guide-guideline-title'}> 이체대행 가이드 절차</div>
                        <img className={'m-a-usage-guide-guideline-title-img'} src={CommonGuide} alt={'PopUp'}
                             onClick={openTransferGuidePopup}/>
                    </div>
                    <div className={'m-a-usage-guide-description'}>
                        (우측 아이콘을 클릭해주세요)
                    </div>
                    <div className={'m-a-usage-guide-divider'}/>
                    <div className={'m-a-usage-guide-guideline-grid'}>
                        {usageGuideOfTransfer.map((item, index) => (
                            <div key={index} className={'m-a-usage-guide-guideline-item'}>
                                <div className={'m-a-usage-guide-guideline-item-content'}>
                                    <div className={'m-a-usage-guide-guideline-item-number'}>{index + 1}</div>
                                    <img className={'m-a-usage-guide-guideline-item-img'} src={item.img}
                                         alt={item.title}/>
                                </div>
                                <div className={'m-a-usage-guide-guideline-item-title'}>{item.title}</div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : domesticGuide ? (
                <div className={'m-a-usage-guide-guideline-frame'}>
                    <div className={'m-a-usage-guide-guideline-title-box'}>
                        <div className={'m-a-usage-guide-guideline-title'}> 국내배송 가이드 절차</div>
                        <img className={'m-a-usage-guide-guideline-title-img'} src={CommonGuide} alt={'PopUp'}
                             onClick={openDomesticGuidePopup}/>
                    </div>
                    <div className={'m-a-usage-guide-description'}>
                        (우측 아이콘을 클릭해주세요)
                    </div>
                    <div className={'m-a-usage-guide-divider'}/>
                    <div className={'m-a-usage-guide-guideline-grid'}>
                        {usageGuideOfDomestic.map((item, index) => (
                            <div key={index} className={'m-a-usage-guide-guideline-item'}>
                                <div className={'m-a-usage-guide-guideline-item-content'}>
                                    <div className={'m-a-usage-guide-guideline-item-number'}>{index + 1}</div>
                                    <img className={'m-a-usage-guide-guideline-item-img'} src={item.img}
                                         alt={item.title}/>
                                </div>
                                <div className={'m-a-usage-guide-guideline-item-title'}>{item.title}</div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : deliveryGuide ? (
                <div className={'m-a-usage-guide-guideline-frame'}>
                    <div className={'m-a-usage-guide-guideline-title-box'}>
                        <div className={'m-a-usage-guide-guideline-title'}> 배송대행 가이드 절차</div>
                        <img className={'m-a-usage-guide-guideline-title-img'} src={CommonGuide} alt={'PopUp'}
                             onClick={openDeliveryGuidePopup}/>
                    </div>
                    <div className={'m-a-usage-guide-description'}>
                        (우측 아이콘을 클릭해주세요)
                    </div>
                    <div className={'m-a-usage-guide-divider'}/>
                    <div className={'m-a-usage-guide-guideline-grid'}>
                        {usageGuideOfDelivery.map((item, index) => (
                            <div key={index} className={'m-a-usage-guide-guideline-item'}>
                                <div className={'m-a-usage-guide-guideline-item-content'}>
                                    <div className={'m-a-usage-guide-guideline-item-number'}>{index + 1}</div>
                                    <img className={'m-a-usage-guide-guideline-item-img'} src={item.img}
                                         alt={item.title}/>
                                </div>
                                <div className={'m-a-usage-guide-guideline-item-title'}>{item.title}</div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}
            {cartGuide ? (
                <div className={'m-a-usage-guide-detail-frame'}>
                    <div className={'m-a-usage-guide-detail-title'}>
                        <img className={'m-a-usage-guide-detail-index'} src={guideOne} alt={''}/>
                        <div
                            className={'m-a-usage-guide-detail-title-text'}> 쇼핑 후 장바구니담기
                        </div>
                    </div>
                    <div className={'m-a-usage-guide-detail-content-box'}>
                        <img className={'m-a-usage-guide-detail-content-top'}
                             src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/fixFile/guideTest.PNG"}
                             alt={'photo1'}/>
                        <div className={'m-a-usage-guide-divider bold'}/>
                        <div className={'m-a-usage-guide-detail-content-comment-box'}>
                            <div className={'m-a-usage-guide-detail-comment-top'}>
                                <img className={'m-a-usage-guide-detail-comment-icon'} src={comment}
                                     alt={'Comment'}/>
                                <div className={'m-a-usage-guide-detail-comment-title'}> Comment</div>
                            </div>
                            <div className={'m-a-usage-guide-detail-comment-description'}>
                                ※ 장바구니에 담아주세요
                            </div>
                        </div>
                    </div>
                </div>
            ) : orderGuide ? (
                <div className={'m-a-usage-guide-detail-frame'}>
                    <div className={'m-a-usage-guide-detail-title'}>
                        <img className={'m-a-usage-guide-detail-index'} src={guideOne} alt={''}/>
                        <div
                            className={'m-a-usage-guide-detail-title-text'}> 구매대행신청
                        </div>
                    </div>
                    <div className={'m-a-usage-guide-detail-content-box'}>
                        <img className={'m-a-usage-guide-detail-content-top'}
                             src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/fixFile/guideTest.PNG"}
                             alt={'photo1'}/>
                        <div className={'m-a-usage-guide-divider bold'}/>
                        <div className={'m-a-usage-guide-detail-content-comment-box'}>
                            <div className={'m-a-usage-guide-detail-comment-top'}>
                                <img className={'m-a-usage-guide-detail-comment-icon'} src={comment}
                                     alt={'Comment'}/>
                                <div className={'m-a-usage-guide-detail-comment-title'}> Comment</div>
                            </div>
                            <div className={'m-a-usage-guide-detail-comment-description'}>
                                ※ 신청서를 작성해주세요
                            </div>
                        </div>
                    </div>
                </div>
            ) : offlineGuide ? (
                <div className={'m-a-usage-guide-detail-frame'}>
                    <div className={'m-a-usage-guide-detail-title'}>
                        <img className={'m-a-usage-guide-detail-index'} src={guideOne} alt={''}/>
                        <div
                            className={'m-a-usage-guide-detail-title-text'}> 오프라인신청
                        </div>
                    </div>
                    <div className={'m-a-usage-guide-detail-content-box'}>
                        <img className={'m-a-usage-guide-detail-content-top'}
                             src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/fixFile/guideTest.PNG"}
                             alt={'photo1'}/>
                        <div className={'m-a-usage-guide-divider bold'}/>
                        <div className={'m-a-usage-guide-detail-content-comment-box'}>
                            <div className={'m-a-usage-guide-detail-comment-top'}>
                                <img className={'m-a-usage-guide-detail-comment-icon'} src={comment}
                                     alt={'Comment'}/>
                                <div className={'m-a-usage-guide-detail-comment-title'}> Comment</div>
                            </div>
                            <div className={'m-a-usage-guide-detail-comment-description'}>
                                ※ 신청서를 작성해주세요
                            </div>
                        </div>
                    </div>
                </div>
            ) : transferGuide ? (
                <div className={'m-a-usage-guide-detail-frame'}>
                    <div className={'m-a-usage-guide-detail-title'}>
                        <img className={'m-a-usage-guide-detail-index'} src={guideOne} alt={''}/>
                        <div
                            className={'m-a-usage-guide-detail-title-text'}> 이체대행신청
                        </div>
                    </div>
                    <div className={'m-a-usage-guide-detail-content-box'}>
                        <img className={'m-a-usage-guide-detail-content-top'}
                             src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/fixFile/guideTest.PNG"}
                             alt={'photo1'}/>
                        <div className={'m-a-usage-guide-divider bold'}/>
                        <div className={'m-a-usage-guide-detail-content-comment-box'}>
                            <div className={'m-a-usage-guide-detail-comment-top'}>
                                <img className={'m-a-usage-guide-detail-comment-icon'} src={comment}
                                     alt={'Comment'}/>
                                <div className={'m-a-usage-guide-detail-comment-title'}> Comment</div>
                            </div>
                            <div className={'m-a-usage-guide-detail-comment-description'}>
                                ※ 신청서를 작성해주세요
                            </div>
                        </div>
                    </div>
                </div>
            ) : domesticGuide ? (
                <div className={'m-a-usage-guide-detail-frame'}>
                    <div className={'m-a-usage-guide-detail-title'}>
                        <img className={'m-a-usage-guide-detail-index'} src={guideOne} alt={''}/>
                        <div
                            className={'m-a-usage-guide-detail-title-text'}> 국내배송신청
                        </div>
                    </div>
                    <div className={'m-a-usage-guide-detail-content-box'}>
                        <img className={'m-a-usage-guide-detail-content-top'}
                             src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/fixFile/guideTest.PNG"}
                             alt={'photo1'}/>
                        <div className={'m-a-usage-guide-divider bold'}/>
                        <div className={'m-a-usage-guide-detail-content-comment-box'}>
                            <div className={'m-a-usage-guide-detail-comment-top'}>
                                <img className={'m-a-usage-guide-detail-comment-icon'} src={comment}
                                     alt={'Comment'}/>
                                <div className={'m-a-usage-guide-detail-comment-title'}> Comment</div>
                            </div>
                            <div className={'m-a-usage-guide-detail-comment-description'}>
                                ※ 신청서를 작성해주세요
                            </div>
                        </div>
                    </div>
                </div>
            ) : deliveryGuide ? (
                <div className={'m-a-usage-guide-detail-frame'}>
                    <div className={'m-a-usage-guide-detail-title'}>
                        <img className={'m-a-usage-guide-detail-index'} src={guideOne} alt={''}/>
                        <div
                            className={'m-a-usage-guide-detail-title-text'}> 배송대행신청
                        </div>
                    </div>
                    <div className={'m-a-usage-guide-detail-content-box'}>
                        <img className={'m-a-usage-guide-detail-content-top'}
                             src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/fixFile/guideTest.PNG"}
                             alt={'photo1'}/>
                        <div className={'m-a-usage-guide-divider bold'}/>
                        <div className={'m-a-usage-guide-detail-content-comment-box'}>
                            <div className={'m-a-usage-guide-detail-comment-top'}>
                                <img className={'m-a-usage-guide-detail-comment-icon'} src={comment}
                                     alt={'Comment'}/>
                                <div className={'m-a-usage-guide-detail-comment-title'}> Comment</div>
                            </div>
                            <div className={'m-a-usage-guide-detail-comment-description'}>
                                ※ 신청서를 작성해주세요
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}


            {cartGuidePopup ?
                <GuideModal cartGuidePopup={cartGuidePopup} closeCartGuidePopup={closeCartGuidePopup}
                            cartGuideHeader={cartGuideHeader} cartGuideContent={cartGuideContent}/>
                :
                orderGuidePopup ?
                    <GuideModal orderGuidePopup={orderGuidePopup} closeOrderGuidePopup={closeOrderGuidePopup}
                                orderGuideHeader={orderGuideHeader} orderGuideContent={orderGuideContent}/>
                    :
                    offlineGuidePopup ?
                        <GuideModal offlineGuidePopup={offlineGuidePopup}
                                    closeOfflineGuidePopup={closeOfflineGuidePopup}
                                    offlineGuideHeader={offlineGuideHeader}
                                    offlineGuideContent={offlineGuideContent}/>
                        :
                        transferGuidePopup ?
                            <GuideModal transferGuidePopup={transferGuidePopup}
                                        closeTransferGuidePopup={closeTransferGuidePopup}
                                        transferGuideHeader={transferGuideHeader}
                                        transferGuideContent={transferGuideContent}/>
                            :
                            domesticGuidePopup ?
                                <GuideModal domesticGuidePopup={domesticGuidePopup}
                                            closeDomesticGuidePopup={closeDomesticGuidePopup}
                                            domesticGuideHeader={domesticGuideHeader}
                                            domesticGuideContent={domesticGuideContent}/>
                                :
                                deliveryGuidePopup ?
                                    <GuideModal deliveryGuidePopup={deliveryGuidePopup}
                                                closeDeliveryGuidePopup={closeDeliveryGuidePopup}
                                                deliveryGuideHeader={deliveryGuideHeader}
                                                deliveryGuideContent={deliveryGuideContent}/>
                                    :
                                    null
            }
        </div>
    )
}

export default MUsageGuidePg;
