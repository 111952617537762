import React, {useEffect, useRef, useState} from "react";
import commonClose from '../../assets/img/commonClose.png'

function MAdminReviewCommentModifyPg({ modifyCommentContent, modifyReviewComment, closeModifyCommentShow }) {

    const textareaRef = useRef(null);
    const [reviewCommentText, setReviewCommentText] = useState('');

    const handleCommentTextChange = (e) => {
        setReviewCommentText(e.target.value);
        const textarea = textareaRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = Math.min(textarea.scrollHeight, 150) + 'px';
    };

    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = Math.min(textarea.scrollHeight, 150) + 'px';
        }
    }, [reviewCommentText])

    useEffect(()=> {
        setReviewCommentText(modifyCommentContent);
    }, [])

    return (
        <div className="bookContainer display-flex" style={{zIndex:"10"}}>
            <div className="bookFrame" style={{
                minWidth: "340px",
                width: "auto",
                height: "auto",
                position: "fixed", // 또는 'absolute', 상황에 따라 다를 수 있음
                top: "45%",
                left: "50%",
                transform: "translate(-50%, -50%)", // 중앙 정렬을 위해
                borderRadius: "5px"
            }}>
                <div className="wd-100pc ht-100pc">
                    {/*상세헤더*/}
                    <div className="ht-45">
                        <div style={{
                            height: "100%",
                            fontSize: "23px",
                            borderBottom: "solid 1px lightgray",
                            display: "flex",
                            position:"relative"
                        }}>
                            <div style={{width: "100%", textAlign: "center"}}>
                                <p className="font-t-title font-s-16 wd-100pc m-t-9">댓글수정</p>
                            </div>
                            <div onClick={closeModifyCommentShow} style={{width: "40px", position:"absolute", right:"3px", top:"2px", textAlign: "center", cursor:"pointer"}}>
                                <img style={{width:"30px", height:"30px", opacity:"0.6"}} alt="closeImage" src={commonClose}/>
                            </div>
                        </div>
                    </div>
                    {/*상세바디*/}
                    <div className="user-center font-t-cont" style={{minHeight:"150px"}}>
                        <textarea
                            ref={textareaRef}
                            spellCheck="false"
                            value={reviewCommentText}
                            maxLength={5000}
                            style={{
                                minHeight: "30px",
                                maxHeight: "200px",
                                borderRadius: "6px",
                                width: "70%",
                                borderWidth: "thin 1px",
                                borderColor: "#EEEEEE",
                                resize: "none",
                                overflow: "auto",
                                outline: "none",
                                padding: "10px",
                                fontSize: "14px",
                                ':focus': {
                                    borderColor: "#EEEEEE"
                                }
                            }} onChange={handleCommentTextChange}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    modifyReviewComment();
                                }
                            }}/>
                        <button onClick={()=> modifyReviewComment(reviewCommentText)} className="colorFullBt" style={{
                            width: "20%",
                            height: "60px",
                            textAlign: "center",
                            fontSize: "15px",
                            opacity: "0.6",
                            marginLeft:"5px"
                        }}>완료
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MAdminReviewCommentModifyPg
