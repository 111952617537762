import React from 'react';
import footerCenterLogo from '../../../assets/img/footerCenterLogo.png'
import '../../../css/web/UserFooterMain.css';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {usePopup} from "../../contextApi/FooterOpenChatContext";
import HeaderLogo from "../../../assets/img/HeaderLogo.png";

function UserFooterMain({ openTermsPopup, openPrivacyPopup }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { setIsPopupOpen } = usePopup();

    return (
        <div className="wd-100pc ht-250 display-flex align-center just-cont-sb" style={{backgroundColor:"#F6F6F6", padding:"30px 50px"}}>
            <div className="wd-30pc ht-100pc font-t-cont c-def">
                <div style={{height:"20%"}}>
                    <img alt="mainLogo" style={{width:"141px", height:"32px", opacity: "0.66"}} src={HeaderLogo}/>
                </div>
                <div style={{height:"20%", display:"flex", alignItems:"flex-end"}}>
                    <p style={{fontSize:"14px", color:"#9C9C9C"}}>{t(`fmCompNm`)}</p>
                </div>
                <div style={{height:"20%", display:"flex", alignItems:"center"}}>
                    <span style={{fontSize:"12px", color:"#A5A5A5"}}>경기도 광주시 곤지암읍 연곡길42번길 32-15</span>
                </div>
                <div style={{height:"40%", display:"flex", alignItems:"center"}}>
                    <div onClick={()=> navigate("/UserMailInquiryPg")} className="footerContactBt" tabIndex="0">
                        <p>{t(`fmContBt`)}</p>
                    </div>
                </div>
            </div>
            <div className="wd-45pc ht-100pc display-flex align-center font-t-cont">
                <div style={{width:"37%", height:"100%", display:"flex", flexWrap:"wrap", justifyContent:"center", alignItems:"center"}}>
                    <p className="footerCenterMenu">{t(`fmSmallMenu5`)}</p>
                    <p onClick={openTermsPopup} className="footerCenterMenu">{t(`fmSmallMenu2`)}</p>
                    <p onClick={openPrivacyPopup} className="footerCenterMenu">{t(`fmSmallMenu3`)}</p>
                    <p className="footerCenterMenu">{t(`fmSmallMenu4`)}</p>
                </div>
                <div className="c-def" style={{width:"65%", height:"100%", display:"flex", flexWrap:"wrap", justifyContent:"center", alignItems:"center"}}>
                    <span className="businessInfoMenu">{t(`fmCompCeo`)}</span>
                    <span className="businessInfoMenu">{t(`fmCompInfoAdmin`)}</span>
                    <span className="businessInfoMenu">{t(`fmCompNum`)}</span>
                    <span className="businessInfoMenu">{t(`fmCompMail`)}</span>
                    <span className="businessInfoMenu">{t(`fmCompSalesNum`)}</span>
                </div>
            </div>
            <div className="font-t-cont c-def" style={{width:"22%", height:"100%"}}>
                <div style={{height:"20%"}}>
                    <p style={{fontSize:"14px", color:"#9C9C9C"}}>{t(`fmCt`)}</p>
                </div>
                <div style={{height:"40%", display:"flex"}}>
                    <div style={{width:"30%", display:"flex", alignItems:"center"}}>
                        <img style={{width:"50px", height:"50px", opacity:"0.6"}} src={footerCenterLogo} alt="centerLogo"/>
                    </div>
                    <div style={{width:"70%", display:"flex", flexWrap:"wrap", alignItems:"center"}}>
                        <p style={{fontSize:"12px", color:"#A5A5A5", marginBottom:"-20px"}}>{t(`fmCs`)}</p>
                        <p style={{fontSize:"12px", color:"#A5A5A5"}}>{t(`fmLunch`)}</p>
                    </div>
                </div>
                <div style={{height:"40%", display:"flex", alignItems:"center"}}>
                    {/*// 비동기 특성상 unreadCount가 0으로 리셋이 안 되서 contextApi 사용*/}
                    <div onClick={()=> setIsPopupOpen(true)} className="footerContactBt" tabIndex="0">
                        <p>{t(`fmChatBt`)}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserFooterMain
