import React, {useEffect, useRef, useState} from "react";
import '../../css/web/UserMyPg.css';
import mypgOrder from "../../assets/img/mypgOrder.png";
import mypgDelivery from "../../assets/img/mypgDelivery.png";
import mypgReturn from "../../assets/img/mypgReturn.png";
import mypgReturnIcon from "../../assets/img/mypgReturnIcon.png";
import mypgOrderdt from "../../assets/img/mypgOrderdt.png";
import mypgPayinfo from "../../assets/img/mypgPayinfo.png";
import mypgWarn from "../../assets/img/mypgWarn.png"
import mypgPayCardOrder from "../../assets/img/mypgPayCardOrder.png"
import mypgPayCardDelivery from "../../assets/img/mypgPayCardDelivery.png"
import commonClose from "../../assets/img/commonClose.png"

import noData from "../../assets/img/noData.png";
import {AiOutlineDoubleRight} from "react-icons/ai";
import {useNavigate} from "react-router-dom";
import {BiChevronDown} from "react-icons/bi";
import axios from "axios";
import DeliveryPriceDetail from "../../components/web/modal/DeliveryPriceDetail";
import excel from "exceljs";
import withAuth from '../../auth/UserRouteAuth'
import CommonLoading from "../../components/web/modal/CommonLoading";
import IntegDetailPopup from "../../components/web/modal/IntegDetailPopup";
import {connect} from "../../services/WebsocketService";
import MsgCheckModal from "../../components/web/modal/MsgCheckModal";
import {useTranslation} from "react-i18next";
import commomHeader from "../../assets/img/commonHeader.png";
import {useWebSocket} from "../../services/WebsocketContext";
import TopBtn from "../../components/web/layoutsadmin/TopBtn";

function UserMyPg() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    const [periodSelectShow, setPeriodSelectShow] = useState(false);
    const [periodSelectCommonValue, setPeriodSelectCommonValue] = useState(t(`myPrd1`));
    const periodButtonRef = useRef(null);
    const periodDivRef = useRef(null);

    const periodBtHandle = (newValue) => {
        setPeriodSelectShow(false);
        setPeriodSelectCommonValue(newValue);
    };
    const periodDivHandle = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        const periodClickOutside = (event) => {
            if (
                periodButtonRef.current &&
                periodButtonRef.current.contains(event.target)
            ) {
                setPeriodSelectShow(true);
            } else if (
                periodDivRef.current &&
                !periodDivRef.current.contains(event.target)
            ) {
                setPeriodSelectShow(false);
            }
        };
        document.addEventListener('mousedown', periodClickOutside);
        return () => {
            document.removeEventListener('mousedown', periodClickOutside);
        };
    }, []);

    const [orderStSelectShow, setOrderStSelectShow] = useState(false);
    const [orderStSelectCommonValue, setOrderStSelectCommonValue] = useState(t(`all`));
    const orderStButtonRef = useRef(null);
    const orderStDivRef = useRef(null);

    const myPgContentRef = useRef(null);
    const scrollToMyPgContent = () => {
        if (myPgContentRef.current) {
            myPgContentRef?.current.scrollIntoView({behavior: 'smooth'});
        }
    };

    const orderStBtHandle = (newValue) => {
        setOrderStSelectShow(false);
        setOrderStSelectCommonValue(newValue);
        filterOrderSt(newValue);
        scrollToMyPgContent();
    };
    const orderStDivHandle = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        const orderStClickOutside = (event) => {
            if (
                orderStButtonRef.current &&
                orderStButtonRef.current.contains(event.target)
            ) {
                setOrderStSelectShow(true);
            } else if (
                orderStDivRef.current &&
                !orderStDivRef.current.contains(event.target)
            ) {
                setOrderStSelectShow(false);
            }
        };
        document.addEventListener('mousedown', orderStClickOutside);
        return () => {
            document.removeEventListener('mousedown', orderStClickOutside);
        };
    }, []);

    const [memberInfo, setMemberInfo] = useState("");

    const selectMember = (() => {
        axios({
            method: 'get',
            url: '/member/modiInfo',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response)=> {
                setMemberInfo(response.data[0])
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(()=> {
        if (LCAT || SSAT) {
            selectMember();
        }
    }, [LCAT, SSAT])

    // websocket
    const stompClientRef = useRef(null);
    const { subscribe, unsubscribe, websocketConnect, isConnected, stompClient } = useWebSocket();
    useEffect(() => {
        if (!memberInfo && !memberInfo.mbNum) return;
        const initializeSubscription = async () => {
            if (!isConnected || !stompClient) {
                stompClientRef.current = await websocketConnect(memberInfo.mbNum);
            } else {
                stompClientRef.current = stompClient;
            }
            const subscriptions = [];
            subscriptions.push(subscribe(`/user/${memberInfo.userName}/queue/adminOrderStUpdate`, receivedOrderStChange));
            subscriptions.push(subscribe(`/user/${memberInfo.userName}/queue/adminOrderDivideMerge`, receivedOrderDivideMerge));
            subscriptions.push(subscribe(`/user/${memberInfo.userName}/queue/adminUpdateOrder`, updateOrderList));
            stompClientRef.current = subscriptions;
        };
        initializeSubscription();
        return () => {
            if (stompClientRef.current && stompClientRef.current.length > 0) {
                // 구독이 존재하는 경우에만 해제
                stompClientRef.current.forEach((sub) => {
                    unsubscribe(sub);
                });
            }
            stompClientRef.current = null;
        };
    }, [memberInfo, isConnected, subscribe, unsubscribe, stompClient]);



    const [orderdtList, setOrderdtList] = useState([{}]);
    const [productsList, setProductsList] = useState([]);
    const [payinfoList, setPayinfoList] = useState([{}]);
    const [recinfoList, setRecinfoList] = useState([{}]);
    const [isLoading, setIsLoading] = useState(false);

    const selectTotalOrderList = ((date) => {
        setIsLoading(true);

        let globalDate;
        if (["1개월 전", "1 month ago", "1ヶ月前", "1个月前", "1 Bulan yang Lalu"].includes(date)) {
            globalDate = "1개월 전";
        } else if (["3개월 전", "3 months ago", "3ヶ月前", "3个月前", "3 Bulan yang Lalu"].includes(date)) {
            globalDate = "3개월 전";
        } else if (["6개월 전", "6 months ago", "6ヶ月前", "6个月前", "6 Bulan yang Lalu"].includes(date)) {
            globalDate = "6개월 전";
        } else if (["1년 전", "1 year ago", "1年前", "1年前", "1 Tahun yang Lalu"].includes(date)) {
            globalDate = "1년 전";
        } else if (["2년 전", "2 years ago", "2年前", "2年前", "2 Tahun yang Lalu"].includes(date)) {
            globalDate = "2년 전";
        } else {
            globalDate = null;
        }

        axios({
            method: 'get',
            url: '/order/selectTotalOrderList',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                date: globalDate,
            },
        })
            .then((response) => {
                // let sortedData = response.data.sort((a, b) => b.seqnum - a.seqnum);
                // sortedData = sortedData.slice(0, 10);
                setOrderdtList(response.data);

                const ProdList = response.data.map(order => {
                    const productMap = new Map();
                    order.productsDTO.forEach(product => {
                        if (!productMap.has(product.seqnum)) {
                            productMap.set(product.seqnum, product);
                        }
                    });
                    return Array.from(productMap.values());
                });
                setProductsList(ProdList);

                const payList = response.data.flatMap(order => order.payInfoDTO);
                setPayinfoList(payList);

                const recList = response.data.map(order => order.recinfoDTO);
                setRecinfoList(recList);

            })
            .catch(() => {})
            .finally(()=> {
                setIsLoading(false);
            })
    })

    useEffect(() => {
        selectTotalOrderList(periodSelectCommonValue);
    }, [periodSelectCommonValue]);

    const myOrdPgMove = (ordNum) => () => {
        navigate(`/UserMyOrderPg/${ordNum}`);
    };

    const [paySeqnum, setPaySeqnum] = useState(null);
    const [payShipco, setPayShipco] = useState('');
    const [deliveryDetailSt, setDeliveryDetailSt] = useState(false);
    const deliveryDetailFalseFn = (() => {
        setDeliveryDetailSt(false);
    })
    const deliveryDetailTrueFn = ((seqNum, shipco) => {
        setPaySeqnum(seqNum);
        setPayShipco(shipco);
        setDeliveryDetailSt(true);
    })

    const updateAppRecinfo = (updatedPayinfo) => {
        setFilteredList((prevPayinfoList) => {
            return prevPayinfoList.map((payinfo) => {
                if (payinfo.appRecDTO && payinfo.appRecDTO.seqnum === updatedPayinfo.seqnum) {
                    return {...payinfo, appRecDTO: updatedPayinfo};
                }
                return payinfo;
            });
        });
    };

    const updatePayinfoList = (updatedPayinfo) => {
        setPayinfoList((prevPayinfoList) => {
            return prevPayinfoList.map((payinfo) =>
                payinfo.seqNum === updatedPayinfo.seqNum ? updatedPayinfo : payinfo,
            );
        });
    };

    const receivedOrderStChange = ((payload) => {
        let data = JSON.parse(payload.body);
        setFilteredList(prevList => {
            return prevList.map(item => {
                // 조건에 맞는 항목 찾기
                if (item.type === 'orderdt' && item.seqnum === data.orderSeqNum) {
                    // 해당 항목의 orderStatus를 data.orderSt로 업데이트
                    return {
                        ...item,
                        orderStatus: data.orderSt
                    };
                }
                // 다른 항목들은 그대로 유지
                return item;
            });
        });
    });

    const receivedOrderDivideMerge = ((payload) => {
        let data = JSON.parse(payload.body);
        selectTotalOrderList(periodSelectCommonValue);
        selectedAllProcessCount(memberInfo.mbNum)
    })

    const updateOrderList = ((payload) => {
        let data = JSON.parse(payload.body);
        selectTotalOrderList(periodSelectCommonValue);
        selectedAllProcessCount(memberInfo.mbNum)
    })

    const [searchQuery, setSearchQuery] = useState("");
    const [filteredList, setFilteredList] = useState([]);

    const convertToShipco = ((dvType, defalutShipco) => {
        if (dvType === "D00") {
            return "국내배송"
        } else if (dvType === "D01") {
            return "FedEx"
        } else if (dvType === "D02") {
            return "EMS"
        } else if (dvType === "D03") {
            return "SHIP"
        } else if (dvType === "D04") {
            return "DHL"
        } else if (dvType === "D11") {
            return "UPS"
        } else if (dvType === "D13") {
            return "K-Packet"
        } else if (dvType === "D14") {
            return "Small-Packet"
        } else {
            return defalutShipco;
        }
    })

    useEffect(() => {
        const combined = [
            ...orderdtList.map((item) => ({
                type: 'orderdt',
                date: item.modiTime || item.regTime,
                mostRecentDate: new Date(Math.max(new Date(item.modiTime), new Date(item.regTime))),
                ...item,
            })),
            ...payinfoList.map((item) => ({
                type: 'payinfo',
                date: item?.modiTime || item?.regTime,
                mostRecentDate: new Date(Math.max(new Date(item.modiTime), new Date(item.regTime))),
                ...item,
            })),
        ];

        combined.sort((a, b) => b.mostRecentDate - a.mostRecentDate);
        // combined.sort((a, b) => moment(b.date).isAfter(moment(a.date)) ? 1 : -1);

        const filterStatus = (order) => {
            if (orderStSelectCommonValue === t(`all`)) return true;
            switch (orderStSelectCommonValue) {
                case "PS0":
                    return (order.type === 'orderdt' && order.orderStatus === "OS09") ||
                        (order.type === 'payinfo' && order.payStatus === "PS0");
                case "PS1":
                    return (order.type === 'orderdt' && order.orderStatus === "OS10") ||
                        (order.type === 'payinfo' && order.payStatus === "PS1");
                case "PS2":
                    return (order.type === 'orderdt' && order.orderStatus === "OS11") ||
                        (order.type === 'payinfo' && order.payStatus === "PS2");
                case "PS3":
                    return (order.type === 'orderdt' && order.orderStatus === "OS12") ||
                        (order.type === 'payinfo' && order.payStatus === "PS3");
                case "PS4":
                    return (order.type === 'orderdt' && order.orderStatus === "OS13") ||
                        (order.type === 'payinfo' && order.payStatus === "PS4");
                case "PS5":
                    return (order.type === 'orderdt' && order.orderStatus === "OS14") ||
                        (order.type === 'payinfo' && order.payStatus === "PS5");
                default:
                    return (order.type === 'orderdt' && order.orderStatus === orderStSelectCommonValue) ||
                        (order.type === 'payinfo' && order.payStatus === orderStSelectCommonValue);
            }
        }

        const newList = combined.filter((order) => {
            if (!filterStatus(order)) return false;
            if (searchQuery === "") return true;

            if (order.type === 'orderdt') {
                return (
                    (order.shipco && order.shipco.toLowerCase().includes(searchQuery)) ||
                    (order.recinfoDTO.recname && order.recinfoDTO.recname.toLowerCase().includes(searchQuery)) ||
                    (order.recinfoDTO.countryDTO && order.recinfoDTO.countryDTO.countryEngNm.toLowerCase().includes(searchQuery)) ||
                    (order.productsDTO && order.productsDTO.some((prod) => prod.name && prod.name.toLowerCase().includes(searchQuery))) ||
                    (order.orderNumber && order.orderNumber.toLowerCase().includes(searchQuery))
                );
            }
            if (order.type === 'payinfo') {
                return (
                    (order && convertToShipco(order.dvType, order.orderdtDTO?.shipco).toLowerCase().includes(searchQuery)) ||
                    (order.appRecDTO && order.appRecDTO.countryDTO && order.appRecDTO.countryDTO.countryEngNm.toLowerCase().includes(searchQuery)) ||
                    (order.appRecDTO && order.appRecDTO.recname && order.appRecDTO.recname.toLowerCase().includes(searchQuery)) ||
                    (order.packListDTO && order.packListDTO.productsDTO && order.packListDTO.productsDTO.some((prod) => prod.name && prod.name.toLowerCase().includes(searchQuery))) ||
                    (order.orderdtDTO?.orderNumber && order.orderdtDTO?.orderNumber.toLowerCase().includes(searchQuery))
                );
            }
        });
        setFilteredList(newList);
    }, [orderdtList, payinfoList, searchQuery, orderStSelectCommonValue]);

    useEffect(() => {
        if (memberInfo.mbNum)
            selectedAllProcessCount(memberInfo.mbNum)
    }, [memberInfo])

    const [processData, setProcessData] = useState([]);
    const selectedAllProcessCount = ((mbNum) => {
        axios({
            method: 'get',
            url: '/order/selectedAllProcessCount',
            params: {
                mbNum: mbNum
            }
        })
            .then((response) => {
                setProcessData(response.data);
            })
            .catch((error) => {
                console.log("프로세스수량 조회실패 : ", error)
            })
    })

    const selectNoDateTotalOrderList = ((orderSt) => {
        axios({
            method: 'get',
            url: '/order/selectTotalOrderList',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                // let sortedData = response.data.sort((a, b) => b.seqnum - a.seqnum);
                // sortedData = sortedData.slice(0, 10);
                setOrderdtList(response.data);
                const ProdList = response.data.map(order => {
                    const productMap = new Map();
                    order.productsDTO.forEach(product => {
                        if (!productMap.has(product.seqnum)) {
                            productMap.set(product.seqnum, product);
                        }
                    });
                    return Array.from(productMap.values());
                });
                setProductsList(ProdList);

                const payList = response.data.flatMap(order => order.payInfoDTO);
                setPayinfoList(payList);

                const recList = response.data.map(order => order.recinfoDTO);
                setRecinfoList(recList);

                orderStBtHandle(orderSt);
                setPeriodSelectCommonValue(t('all'))

            })
            .catch((error) => {
                console.log("노데이트 주문조회실패 : ", error)
            })
    })

    const [payPeriodSelectShow, setPayPeriodSelectShow] = useState(false);
    const [payPeriodSelectCommonValue, setPayPeriodSelectCommonValue] = useState(t(`myPrd1`));
    const payPeriodButtonRef = useRef(null);
    const payPeriodDivRef = useRef(null);

    const payPeriodBtHandle = (newValue) => {
        setPayPeriodSelectShow(false);
        setPayPeriodSelectCommonValue(newValue);
    };
    const payPeriodDivHandle = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        const payPeriodClickOutside = (event) => {
            if (
                payPeriodButtonRef.current &&
                payPeriodButtonRef.current.contains(event.target)
            ) {
                // Clicked on the duration button
                setPayPeriodSelectShow(true);
            } else if (
                payPeriodDivRef.current &&
                !payPeriodDivRef.current.contains(event.target)
            ) {
                // Clicked outside the div
                setPayPeriodSelectShow(false);
            }
        };
        document.addEventListener('mousedown', payPeriodClickOutside);
        return () => {
            document.removeEventListener('mousedown', payPeriodClickOutside);
        };
    }, []);

    const filterOrderSt = (status) => {

        let viewStatus;
        switch (status) {
            case t(`all`):
                viewStatus = t(`all`);
                break;
            case "OS00":
                viewStatus = t(`prodStLabel1`);
                break;
            case "OS01":
                viewStatus = t(`prodStLabel2`);
                break;
            case "OS02":
                viewStatus = t(`prodStLabel3`);
                break;
            case "OS03":
                viewStatus = t(`prodStLabel4`);
                break;
            case "OS04":
                viewStatus = t(`prodStLabel14`);
                break;
            case "OS05":
                viewStatus = t(`prodStLabel5`);
                break;
            case "OS06":
                viewStatus = t(`prodStLabel15`);
                break;
            case "OS07":
                viewStatus = t(`prodStLabel6`);
                break;
            case "OS08":
                viewStatus = t(`prodStLabel7`);
                break;
            case "PS0":
                viewStatus = t(`prodStLabel8`);
                break;
            case "PS1":
                viewStatus = t(`prodStLabel9`);
                break;
            case "PS2":
                viewStatus = t(`prodStLabel10`);
                break;
            case "PS3":
                viewStatus = t(`prodStLabel11`);
                break;
            case "PS4":
                viewStatus = t(`prodStLabel12`);
                break;
            case "PS5":
                viewStatus = t(`prodStLabel13`);
                break;
        }
        return viewStatus;
    };

    const [payList, setPayList] = useState([]);
    const payHistoryList = ((dateParam) => {

        let globalDate;
        if (["1개월 전", "1 month ago", "1ヶ月前", "1个月前", "1 Bulan yang Lalu"].includes(dateParam)) {
            globalDate = "1개월 전";
        } else if (["3개월 전", "3 months ago", "3ヶ月前", "3个月前", "3 Bulan yang Lalu"].includes(dateParam)) {
            globalDate = "3개월 전";
        } else if (["6개월 전", "6 months ago", "6ヶ月前", "6个月前", "6 Bulan yang Lalu"].includes(dateParam)) {
            globalDate = "6개월 전";
        } else if (["1년 전", "1 year ago", "1年前", "1年前", "1 Tahun yang Lalu"].includes(dateParam)) {
            globalDate = "1년 전";
        } else if (["2년 전", "2 years ago", "2年前", "2年前", "2 Tahun yang Lalu"].includes(dateParam)) {
            globalDate = "2년 전";
        } else {
            globalDate = null;
        }

        axios({
            method: 'get',
            url: '/order/payHistoryList',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                date: globalDate,
            },
        })
            .then((response) => {
                // payType이 통합결제이거나 자식이 아닌 단독결제건(상품금액/해외배송료/기타비용)만 출력
                const filteredData = response.data.filter(data => data.payType === 'PT3' || !data.childFl);
                setPayList(filteredData);
            })
            .catch(() => {
                console.log("실패")
            })
    })

    const sortedPayList = payList.sort((a, b) => b.seqNum - a.seqNum);

    const [payStSelectShow, setPayStSelectShow] = useState(false);
    const [payStSelectCommonValue, setPayStSelectCommonValue] = useState(t(`all`));
    const payStButtonRef = useRef(null);
    const payStDivRef = useRef(null);

    const payStBtHandle = (newValue) => {
        setPayStSelectShow(false);
        setPayStSelectCommonValue(newValue);
        filterPaySt(newValue);
    };

    useEffect(() => {
        payHistoryList(payPeriodSelectCommonValue);
    }, [payPeriodSelectCommonValue]);

    const payStDivHandle = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        const payStClickOutside = (event) => {
            if (
                payStButtonRef.current &&
                payStButtonRef.current.contains(event.target)
            ) {
                setPayStSelectShow(true);
            } else if (
                payStDivRef.current &&
                !payStDivRef.current.contains(event.target)
            ) {
                setPayStSelectShow(false);
            }
        };
        document.addEventListener('mousedown', payStClickOutside);
        return () => {
            document.removeEventListener('mousedown', payStClickOutside);
        };
    }, []);

    const filterStatus = (pay) => {
        if (payStSelectCommonValue === t(`all`)) return true;
        return pay.payStatus === payStSelectCommonValue;
    }

    const filterPaySt = (status) => {
        let viewStatus;
        switch (status) {
            case t(`all`):
                viewStatus = t(`all`)
                break;
            case "PS0":
                viewStatus = t(`prodStLabel8`)
                break;
            case "PS1":
                viewStatus = t(`prodStLabel9`)
                break;
            case "PS2":
                viewStatus = t(`prodStLabel10`)
                break;
        }
        return viewStatus;
    };

    const [dacPeriodSelectShow, setDacPeriodSelectShow] = useState(false);
    const [dacPeriodSelectCommonValue, setDacPeriodSelectCommonValue] = useState(t(`myPrd1`));
    const dacPeriodButtonRef = useRef(null);
    const dacPeriodDivRef = useRef(null);

    const dacPeriodBtHandle = (newValue) => {
        setDacPeriodSelectShow(false);
        setDacPeriodSelectCommonValue(newValue);
        dacHistoryList(newValue);
    };
    const dacPeriodDivHandle = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        const dacPeriodClickOutside = (event) => {
            if (
                dacPeriodButtonRef.current &&
                dacPeriodButtonRef.current.contains(event.target)
            ) {
                // Clicked on the duration button
                setDacPeriodSelectShow(true);
            } else if (
                dacPeriodDivRef.current &&
                !dacPeriodDivRef.current.contains(event.target)
            ) {
                // Clicked outside the div
                setDacPeriodSelectShow(false);
            }
        };
        document.addEventListener('mousedown', dacPeriodClickOutside);
        return () => {
            document.removeEventListener('mousedown', dacPeriodClickOutside);
        };
    }, []);

    const [dacList, setDacList] = useState([]);
    const dacHistoryList = ((dateParam) => {

        let globalDate;
        if (["1개월 전", "1 month ago", "1ヶ月前", "1个月前", "1 Bulan yang Lalu"].includes(dateParam)) {
            globalDate = "1개월 전";
        } else if (["3개월 전", "3 months ago", "3ヶ月前", "3个月前", "3 Bulan yang Lalu"].includes(dateParam)) {
            globalDate = "3개월 전";
        } else if (["6개월 전", "6 months ago", "6ヶ月前", "6个月前", "6 Bulan yang Lalu"].includes(dateParam)) {
            globalDate = "6개월 전";
        } else if (["1년 전", "1 year ago", "1年前", "1年前", "1 Tahun yang Lalu"].includes(dateParam)) {
            globalDate = "1년 전";
        } else if (["2년 전", "2 years ago", "2年前", "2年前", "2 Tahun yang Lalu"].includes(dateParam)) {
            globalDate = "2년 전";
        } else {
            globalDate = null;
        }

        axios({
            method: 'get',
            url: '/order/dacHistoryList',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                date: globalDate
            },
        })
            .then((response) => {
                setDacList(response.data)
                // console.log("예적쿠 리스트 : ", response.data)
            })
            .catch(() => {
                console.log("실패")
            })
    })

    useEffect(() => {
        dacHistoryList(dacPeriodSelectCommonValue);
    }, [dacPeriodSelectCommonValue]);

    const formatDate = (dateString) => {
        if (!dateString) {
            return "";
        }
        return `${dateString.substring(0, 4)}-${dateString.substring(4, 6)}-${dateString.substring(6, 8)}`;
    };

    const sortedDacList = dacList.sort((a, b) => b.seqNum - a.seqNum);

    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const handleCheckboxChange = (orderId) => {
        setSelectedRows(prevRows => {
            if (prevRows.includes(orderId)) {
                return prevRows.filter(row => row !== orderId);
            } else {
                return [...prevRows, orderId];
            }
        });
    };

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(
                filteredList.map(order => {
                    if (order.type === 'orderdt') {
                        return order.seqnum;
                    } else if (order.type === 'payinfo' && (order.payType === "PT1")) {
                        return order.seqNum;
                    }
                    return null;
                }).filter(seqNum => seqNum !== null)
            );
        }
        setSelectAll(!selectAll);
    };

    const [downNoSelectSt, setDownNoSelectSt] = useState(false);
    const closeDownNoSelect = (() => {
        setDownNoSelectSt(false);
    })

    const downloadInvoice = async () => {
        if (selectedRows.length === 0) {
            setDownNoSelectSt(true);
            return;
        }

        const workbook = new excel.Workbook();

        const combinedRows = [];
        const headers = [
            {header: 'OrderNumber', key: 'seqnum', width: 30, style: { alignment: { horizontal: 'center' }}},
            {header: 'Date', key: 'date', width: 17, style: { alignment: { horizontal: 'center' }}},
            {header: 'Product Name', key: 'productName', width: 43, style: { alignment: { horizontal: 'center' }}},
            {header: 'Price', key: 'price', width: 17, style: { alignment: { horizontal: 'center' }}}
        ];

        // filteredList.forEach(order => {
        //     if (order.type === 'orderdt' && selectedRows.includes(order.seqnum)) {
        //         if (order.productsDTO) {
        //             order.productsDTO.forEach(product => {
        //                 combinedRows.push({
        //                     seqnum: order.orderNumber,
        //                     date: order.regDate,
        //                     productName: product.name,
        //                     price: product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        //                 });
        //             });
        //         } else {
        //             console.error(`err_o ${order.orderNumber}`);
        //         }
        //     } else if (order.type === 'payinfo' && selectedRows.includes(order.seqNum)) {
        //         if (order.packListDTO && order.packListDTO.productsDTO) {
        //             order.packListDTO.productsDTO.forEach(product => {
        //                 combinedRows.push({
        //                     seqnum: order.orderdtDTO.orderNumber,
        //                     date: order.regDate,
        //                     productName: product.name,
        //                     price: product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        //                 });
        //             });
        //         } else {
        //             // console.error(`err_p ${order.orderdtDTO.orderNumber}`);
        //         }
        //     }
        // });

        const worksheet = workbook.addWorksheet("Orders and Payinfo");
        worksheet.columns = headers;

        worksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true };
        });

        worksheet.addRows(combinedRows);

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Btorage_OrderList.xlsx';
        link.click();
    };

    const [guidePopupOrderSt, setGuidePopupOrderSt] = useState({});
    const openGuidePopupOrder = (index) => {
        setGuidePopupOrderSt(prevStates => ({ ...prevStates, [index]: true }));
    }
    const closeGuidePopupOrder = (index) => {
        setGuidePopupOrderSt(prevStates => ({ ...prevStates, [index]: false }));
    }

    const [guidePopupPaySt, setGuidePopupPaySt] = useState({});
    const openGuidePopupPay = ((index) => {
        setGuidePopupPaySt(prevStates => ({ ...prevStates, [index]: true }));
    })
    const closeGuidePopupPay = ((index) => {
        setGuidePopupPaySt(prevStates => ({ ...prevStates, [index]: false }));
    })

    const [integPayDetailPopupSt, setIntegPayDetailPopupSt] = useState(false);
    const [integPaySeqnum, setIntegPaySeqnum] = useState(null);
    const integPayDetail = ((seqnum) => {
        setIntegPaySeqnum(seqnum);
        setIntegPayDetailPopupSt(true)
    })
    const integDetailPopupClose = (() => {
        setIntegPayDetailPopupSt(false);
    })
    useEffect(() => {
        if (integPaySeqnum !== null) {
            setIntegPayDetailPopupSt(true);
        }
    }, [integPaySeqnum]);

    const selectOsTrackNum = ((shipco, trackNum) => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = 1200;
        const height = 800;
        const left = (screenWidth / 2) - (width / 2);
        const top = (screenHeight / 2) - (height / 2);

        if (shipco === "FedEx") {
            const url = `https://www.fedex.com/fedextrack/?trknbr=${trackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (shipco === "UPS") {
            const url = `https://www.ups.com/track?loc=ko_KR&trackNums=${trackNum}&requester=ST/trackdetails`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (shipco === "DHL") {
            const url = `https://www.dhl.com/kr-ko/home/tracking/tracking-ecommerce.html?submit=1&tracking-id=${trackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (shipco === "EMS" || shipco === "SHIP" || shipco === "UPS" || shipco === "K-Packet" || shipco === "Small-Packet") {
            const url = `https://service.epost.go.kr/trace.RetrieveEmsRigiTraceList.comm?POST_CODE=${trackNum}&displayHeader=N`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        }
    })

    const orderStPrint = (orderSt) => {
        const orderStStyles = {
            OS00: { color: "#98B0D4", label: t(`prodStLabel1`) },
            OS01: { color: "#98B0D4", label: t(`prodStLabel2`) },
            OS02: { color: "#98B0D4", label: t(`prodStLabel3`) },
            OS03: { color: "#FFB378", label: t(`prodStLabel4`) },
            OS04: { color: "#98B0D4", label: t(`prodStLabel14`) },
            OS05: { color: "#FFB378", label: t(`prodStLabel5`) },
            OS06: { color: "#98B0D4", label: t(`prodStLabel15`) },
            OS07: { color: "#6EDA60", label: t(`prodStLabel6`) },
            OS08: { color: "#98B0D4", label: t(`prodStLabel7`) },
            OS09: { color: "#98B0D4", label: t(`prodStLabel8`) },
            OS10: { color: "#98B0D4", label: t(`prodStLabel9`) },
            OS11: { color: "#FFB378", label: t(`prodStLabel10`) },
            OS12: { color: "#979696", label: t(`prodStLabel11`) },
            OS13: { color: "#FF8F62", label: t(`prodStLabel12`) },
            OS14: { color: "#FF8F62", label: t(`prodStLabel13`) },
        };

        const style = orderStStyles[orderSt] || { color: "black", label: null };
        return <p style={{ margin: "0", color: style.color, fontSize: "15px" }}>{style.label}</p>;
    };

    // 현재 사용자 브라우저위치
    const pathComponent = window.location.pathname;

    const [activeTab, setActiveTab] = useState(0);
    const tabs = [
        {
            label: t(`myTab1`), content:
                <div>
                    <div style={{height: "80px", display: "flex", alignItems: "center", position: "relative"}}>
                        <button style={{
                            height: "40px",
                            minWidth: "200px",
                            borderColor: "lightgray",
                            border: "none",
                            backgroundColor: "#efeef0",
                            color: "#AAA7A6",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "6px",
                            overflow: "hidden",
                            position: "relative"
                        }} ref={periodButtonRef}
                                onClick={() => setPeriodSelectShow(true)}>{periodSelectCommonValue}<i
                            style={{
                                fontSize: "27px",
                                position: "absolute",
                                right: "3px",
                                bottom: "2px"
                            }}><BiChevronDown/></i></button>
                        {
                            periodSelectShow === true ?
                                <div style={{
                                    width: "200px",
                                    height: "auto",
                                    border: "none",
                                    boxShadow: "0px 0px 10px lightgray",
                                    position: "absolute",
                                    left: "0",
                                    top: "70px",
                                    zIndex: 3,
                                    backgroundColor: "white",
                                    borderRadius: "6px"
                                }} ref={periodDivRef} onClick={periodDivHandle}>
                                    <button className="mypgFilterBt"
                                            onClick={() => periodBtHandle(t('myPrd1'))}>{t(`myPrd1`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => periodBtHandle(t('myPrd2'))}>{t(`myPrd2`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => periodBtHandle(t('myPrd3'))}>{t(`myPrd3`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => periodBtHandle(t('myPrd4'))}>{t(`myPrd4`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => periodBtHandle(t('myPrd5'))}>{t(`myPrd5`)}
                                    </button>
                                </div>
                                :
                                null
                        }
                        <div style={{
                            borderRight: "solid",
                            width: "220px",
                            borderWidth: "thin 1px",
                            borderColor: "lightgray"
                        }}>
                            <button style={{
                                height: "40px",
                                width: "180px",
                                borderColor: "lightgray",
                                border: "none",
                                backgroundColor: "#efeef0",
                                color: "#AAA7A6",
                                display: "flex",
                                marginLeft: "20px",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "6px",
                                overflow: "hidden",
                                position: "relative"
                            }} ref={orderStButtonRef}
                                    onClick={() => setOrderStSelectShow(true)}>{filterOrderSt(orderStSelectCommonValue)}<i
                                style={{
                                    fontSize: "27px",
                                    position: "absolute",
                                    right: "3px",
                                    bottom: "2px"
                                }}><BiChevronDown/></i></button>
                        </div>
                        {
                            orderStSelectShow === true ?
                                <div style={{
                                    width: "300px",
                                    height: "auto",
                                    border: "none",
                                    boxShadow: "0px 0px 10px lightgray",
                                    position: "absolute",
                                    left: "160px",
                                    top: "70px",
                                    zIndex: 3,
                                    backgroundColor: "white",
                                    borderRadius: "6px",
                                }} ref={orderStDivRef} onClick={orderStDivHandle}>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("전체")}>{t(`all`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("OS00")}>{t(`prodStLabel1`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("OS01")}>{t(`prodStLabel2`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("OS02")}>{t(`prodStLabel3`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("OS03")}>{t(`prodStLabel4`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("OS04")}>{t(`prodStLabel14`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("OS05")}>{t(`prodStLabel5`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("OS06")}>{t(`prodStLabel15`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("OS07")}>{t(`prodStLabel6`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("OS08")}>{t(`prodStLabel7`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("PS0")}>{t(`prodStLabel8`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("PS1")}>{t(`prodStLabel9`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("PS2")}>{t(`prodStLabel10`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("PS3")}>{t(`prodStLabel11`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("PS4")}>{t(`prodStLabel12`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("PS5")}>{t(`prodStLabel13`)}
                                    </button>
                                </div>
                                :
                                null
                        }
                        <div style={{width: "250px", height: "40px", marginLeft: "20px", position: "relative"}}>
                            <input className="mypgSerchInput wd-100pc font-s-13 p-l-15" type="text" placeholder={t(`myPlaceHd`)} spellCheck="false"
                                   style={{
                                       width: "100%",
                                       border: "solid 1px #EAEAEA",
                                       height: "100%",
                                       color: "#C1C1C1",
                                       borderRadius: "6px"
                                   }} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}/>
                        </div>
                        <div>
                            <button className="ReviewPgWriteBtn" style={{
                                padding:"0 10px",
                                miWidth: "100px",
                                height: "40px",
                                fontSize: "13px",
                                borderWidth: "thin 1px",
                                borderRadius: "4px",
                                marginLeft: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                paddingTop: "3px"
                            }} onClick={downloadInvoice}>{t(`myExcelDwBt`)}
                            </button>
                        </div>
                    </div>
                    <div style={{height: "auto", marginTop: "30px"}}>
                        <div style={{
                            borderBottom: "solid",
                            height: "80px",
                            display: "flex",
                            borderColor: "#F2F2F2",
                            borderWidth: "thin 1px",
                            backgroundColor: "#FAFAFA",
                            zIndex: "2",
                        }}>
                            <div style={{
                                width: "4%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                                <input id="orderCheckBox" type="checkbox" checked={selectAll} className="myPgCustomCheckBox"
                                       onChange={handleSelectAllChange} style={{width: "20px", height: "20px"}}/>
                            </div>
                            <div style={{
                                width: "6%",
                                height: "100%",
                                display: "inline-block",
                                textAlign: "center",
                                color: "#FAFAFA",
                                marginLeft: "30px"
                            }}>
                                <div style={{marginTop: "15px"}}>
                                    <p style={{margin:"0", color:"dimgray", fontSize:"13px"}}>{t(`modSmallMenu3`)}</p>
                                </div>
                                <div>
                                    <p style={{margin:"0", color:"dimgray", fontSize:"13px"}}>{t(`myHeader1`)}</p>
                                </div>
                            </div>
                            <div style={{
                                width: "25%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#7d7a7a"
                            }}>
                                <div>
                                    <p style={{margin:"0", color:"dimgray", fontSize:"13px"}}>{t(`prodNm`)}</p>
                                </div>
                            </div>
                            <div style={{
                                width: "30%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#7d7a7a",
                                marginLeft: "10px"
                            }}>
                                <div>
                                    <p style={{margin:"0", color:"dimgray", fontSize:"13px"}}>{t(`myHeader2`)}</p>
                                </div>
                            </div>
                            <div style={{
                                width: "25%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#7d7a7a",
                                marginLeft: "10px"
                            }}>
                                <div>
                                    <p style={{margin:"0", color:"dimgray", fontSize:"13px"}}>{t(`dchSmallMenu5`)}</p>
                                </div>
                            </div>
                            <div style={{
                                width: "10%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#7d7a7a",
                                paddingLeft: "20px"
                            }}>
                                <div style={{textAlign:"center"}}>
                                    <p style={{margin:"0", color:"dimgray", fontSize:"13px"}}>{t(`myHeader3`)}</p>
                                    <p style={{margin:"0", color:"dimgray", fontSize:"13px"}}>{t(`prodTrNum`)}</p>
                                </div>
                            </div>
                        </div>
                        {orderdtList.length === 0 || filteredList.length === 0 ?
                            <div style={{
                                height: "500px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                                <div style={{height: "100px", width: "30%"}}>
                                    <span style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "100%"
                                    }}>
                                        <img style={{width: "50px", height: "50px", opacity:"0.3"}} alt="" src={noData}/><span style={{
                                        fontSize: "28px",
                                        marginLeft: "20px",
                                        fontWeight: "bold",
                                        color: "lightgrey"
                                    }}>No Data</span></span>
                                </div>
                            </div>
                            :
                            <div ref={myPgContentRef}>
                                {filteredList.map((order, index) => {
                                    let data1 = 0, data2 = 0, data3 = 0, data4 = 0;

                                    if (order.type === 'orderdt') {
                                        // 상품미결제 + 관리자가 등록한 상품누락금 미결제에 추가
                                        data1 = order.productsDTO?.reduce((sum, item) => {
                                            return (item.prodSt === "IS01" || item.prodSt === "IS02") && item.price > 0 ? sum + item.price + item.mallShipPrice + item.fee : sum;
                                        }, 0) || 0;
                                        data1 += order.payInfoDTO?.reduce((sum, item) => {
                                            return (item.payStatus === "PS0" || item.payStatus === "PS1") && item.payClass === '1'
                                            && item.ttProdExpense > 0 ? sum + item.ttProdExpense : sum; }, 0) || 0;

                                        // 상품총결제 + 관리자가 등록한 상품누락금 결제완료에 추가
                                        data2 = order.productsDTO?.reduce((sum, item) => {
                                            return item.prodSt !== "IS01" && item.prodSt !== "IS02" && item.prodSt !== "IS12" && item.price > 0 ? sum + item.price + item.mallShipPrice + item.fee : sum;
                                        }, 0) || 0;
                                        data2 += order.payInfoDTO?.reduce((sum, item) => {
                                            return item.payStatus !== "PS0" && item.payStatus !== "PS1" && item.payClass === '1'
                                            && item.ttProdExpense > 0 ? sum + item.ttProdExpense : sum; }, 0) || 0;

                                        // 기타미결제
                                        data3 = order.payInfoDTO?.reduce((sum, item) => {
                                            if ((item.payStatus === "PS0" || item.payStatus === "PS1") && item.payClass === '1') {
                                                // payStatus가 PS0 또는 PS1일 때 ttEtcPrice와 ttEtcFee(있을 경우)를 더함
                                                const etcPrice = item.ttEtcPrice > 0 ? item.ttEtcPrice : 0;
                                                const etcFee = item.ttEtcFee > 0 ? item.ttEtcFee : 0;
                                                return sum + etcPrice + etcFee;
                                            }
                                            return sum;
                                        }, 0) || 0;

                                        // 기타총결제
                                        data4 = order.payInfoDTO?.reduce((sum, item) => {
                                            if ((item.payStatus !== "PS0" && item.payStatus !== "PS1") && item.payClass === '1') {
                                                // payStatus가 PS0 또는 PS1일 때 ttEtcPrice와 ttEtcFee(있을 경우)를 더함
                                                const etcPrice = item.ttEtcPrice > 0 ? item.ttEtcPrice : 0;
                                                const etcFee = item.ttEtcFee > 0 ? item.ttEtcFee : 0;
                                                return sum + etcPrice + etcFee;
                                            }
                                            return sum;
                                        }, 0) || 0;
                                    }
                                    return(
                                    <div style={{margin: "auto"}}
                                         key={`order-${order.seqnum}-${index}`}>
                                        {/* orderdt map*/}
                                        {order.type === 'orderdt' ?
                                            <div style={{marginBottom:"30px"}}>
                                                <div style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "19px",
                                                    borderTop: "solid",
                                                    borderWidth: "thin 1px",
                                                    borderColor: "#EDEDED",
                                                    padding: "20px 0",
                                                    // border:"solid"
                                                }}>
                                                    <div style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        height: "50px",
                                                        justifyContent: "space-between"
                                                    }}>
                                                        <div style={{
                                                            fontSize: "22px",
                                                            cursor: "pointer",
                                                            paddingLeft: "10px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            height: "100%",
                                                        }} onClick={myOrdPgMove(order.orderNumber)}>
                                                            <img alt="orderPacking" style={{
                                                                width: "30px",
                                                                height: "30px",
                                                                marginRight: "20px"
                                                            }} src={mypgPayinfo}/>
                                                            {order?.orderNumber ? order.orderNumber : order.orderdtDTO?.orderNumber}
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            height: "100%",
                                                        }}>
                                                            <div style={{
                                                                borderWidth: "thin 1px",
                                                                height: "30px",
                                                            }}>
                                                            </div>
                                                            <div style={{
                                                                width: "130px",
                                                                borderWidth: "thin 1px",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                height: "20px",
                                                                alignItems: "center",
                                                                marginRight: "10px"
                                                            }}>{order.regDate ? `${order.regDate.substring(0, 4)}-${order.regDate.substring(4, 6)}-${order.regDate.substring(6, 8)}` : ''}
                                                            </div>
                                                            <div style={{
                                                                width: "90px",
                                                                borderLeft: "solid",
                                                                borderWidth: "thin 1px",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                height: "20px",
                                                                alignItems: "center"
                                                            }}>{order.shipco}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    alignItems:"center",
                                                    borderColor: "lightgray",
                                                    borderWidth: "thin 1px",
                                                    padding: "30px 0",
                                                }}>
                                                    <div style={{
                                                        width: "4%",
                                                        height: "100%",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                    }}>
                                                        <input id="orderCheckBox" type="checkbox"
                                                               checked={selectedRows.includes(order.seqnum)}
                                                               onChange={() => handleCheckboxChange(order.seqnum)}
                                                               style={{width: "20px", height: "20px"}}/>
                                                    </div>

                                                    <div className="mypgOrderCr" style={{
                                                        width: "10%",
                                                        margin: "auto",
                                                        display: "inline-block",
                                                        color: "#7d7a7a",
                                                    }}>
                                                        <div style={{fontSize:"15px", fontWeight:"bold"}}>{order.recinfoDTO && order.recinfoDTO.countryDTO.countryEngNm}</div>
                                                        <div>{order.recinfoDTO && order.recinfoDTO.recname}</div>
                                                    </div>

                                                    <div style={{
                                                        display: "block",
                                                        width: "22%",
                                                        margin: "auto",
                                                    }}>
                                                        {order.productsDTO && order.productsDTO.slice(0, 10).map((prod, prodIndex) => (
                                                            <div key={`product-${prod.seqnum}-${prodIndex}`}
                                                                 className="mypgOrderCr" style={{
                                                                height: "auto",
                                                                alignItems: "center",
                                                                color: "#7d7a7a",
                                                                fontSize: "15px"
                                                            }}>
                                                                <div style={{display:"flex", alignItems:"center", height:"30px"}}>
                                                                    <span style={{fontSize:"13px", color:"darkgrey"}}>
                                                                        {
                                                                            prod.prodTy === "P00" ? t(`myProdTy1`) :
                                                                                prod.prodTy === "P01" ? t(`myProdTy2`) :
                                                                                    prod.prodTy === "P02" ? t(`myProdTy3`) :
                                                                                        prod.prodTy === "P03" ? t(`myProdTy4`) :
                                                                                            prod.prodTy === "P04" ? t(`myProdTy5`) :
                                                                                                prod.prodTy === "P05" ? t(`myProdTy6`) :
                                                                                                    null
                                                                        }
                                                                    </span>
                                                                    <span style={{margin:"0", fontSize:"13px", marginLeft:"5px",
                                                                    overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap", wordBreak:"break-all"}}>
                                                                        {prod.name}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div style={{width: "28%"}}>
                                                        <div style={{
                                                            display: "flex",
                                                            padding: "20px",
                                                            backgroundColor: "white",
                                                            borderRadius: "10px",
                                                            boxShadow: "0 5px 8px 0 rgba(0,0,0,0.1)"
                                                        }}>
                                                            <div style={{width: "80%"}}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    height: "40px"
                                                                }}>
                                                                    <img style={{
                                                                        width: "22px",
                                                                        height: "22px",
                                                                        marginRight: "5px"
                                                                    }} src={mypgPayCardOrder} alt=""/>
                                                                    <span style={{
                                                                        margin: "0",
                                                                        fontSize: "16px",
                                                                        color: "black",
                                                                        fontWeight: "bold"
                                                                    }}>{t(`myOrddtCardHeader1`)}</span>
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    marginTop: "10px"
                                                                }}>
                                                                    <div style={{
                                                                        width:"85%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        height: "40px",
                                                                        justifyContent:"space-between"
                                                                    }}>
                                                                        <span style={{
                                                                            margin: "0",
                                                                            fontSize: "14px",
                                                                            color: "black"
                                                                        }}>{t(`myOrddtCardHeader2`)}</span>
                                                                        <span style={{
                                                                            margin: "0 0 0 10px",
                                                                            fontSize: "15px",
                                                                            color: data1 > 0 ? "darkorange" : "black",
                                                                            fontWeight: data1 > 0 ? "bold" : null
                                                                        }}>{data1?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                    </div>
                                                                </div>
                                                                <div style={{display: "flex", alignItems: "center"}}>
                                                                    <div style={{
                                                                        width:"85%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        height: "40px",
                                                                        justifyContent:"space-between"
                                                                    }}>
                                                                        <span style={{
                                                                            margin: "0",
                                                                            fontSize: "14px",
                                                                            color: "black"
                                                                        }}>{t(`myOrddtCardHeader3`)}</span>
                                                                        <span style={{
                                                                            margin: "0 0 0 10px",
                                                                            fontSize: "15px",
                                                                            color: "black"
                                                                        }}>{data2?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                    </div>
                                                                </div>
                                                                <div style={{display: "flex", alignItems: "center"}}>
                                                                    <div style={{
                                                                        width:"85%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        height: "40px",
                                                                        justifyContent:"space-between"
                                                                    }}>
                                                                        <span style={{
                                                                            margin: "0",
                                                                            fontSize: "14px",
                                                                            color: "black"
                                                                        }}>{t(`myOrddtCardHeader4`)}</span>
                                                                        <span style={{
                                                                            margin: "0 0 0 10px",
                                                                            fontSize: "15px",
                                                                            color: data3 > 0 ? "darkorange" : "black",
                                                                            fontWeight: data3 > 0 ? "bold" : null
                                                                        }}>{data3?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                    </div>
                                                                </div>
                                                                <div style={{display: "flex", alignItems: "center"}}>
                                                                    <div style={{
                                                                        width:"85%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        height: "40px",
                                                                        justifyContent:"space-between"
                                                                    }}>
                                                                        <span style={{
                                                                            margin: "0",
                                                                            fontSize: "14px",
                                                                            color: "black"
                                                                        }}>{t(`myOrddtCardHeader`)}</span>
                                                                        <span style={{
                                                                            margin: "0 0 0 10px",
                                                                            fontSize: "15px",
                                                                            color: "black",
                                                                        }}>{data4?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                width: "20%",
                                                                position: "relative",
                                                                paddingBottom: "10px"
                                                            }}>
                                                                <div style={{
                                                                    height: "50%",
                                                                    display: "flex",
                                                                    justifyContent: "flex-end"
                                                                }}>
                                                                    <img onClick={() => openGuidePopupOrder(index)}
                                                                         style={{
                                                                             width: "40px",
                                                                             height: "40px",
                                                                             cursor: "pointer"
                                                                         }} src={mypgWarn} alt=""/>
                                                                </div>
                                                                <div style={{
                                                                    height: "50%",
                                                                    display: "flex",
                                                                    justifyContent: "flex-end",
                                                                    alignItems: "flex-end"
                                                                }}>
                                                                    <button onClick={myOrdPgMove(order.orderNumber)}
                                                                            style={{
                                                                                width: "60px",
                                                                                height: "40px",
                                                                                border: "none",
                                                                                background: "orange",
                                                                                marginTop: "auto",
                                                                                borderRadius: "20px"
                                                                            }}>
                                                                        <span style={{
                                                                            margin: "0",
                                                                            fontSize: "13px",
                                                                            color: "white",
                                                                            fontWeight: "bold"
                                                                        }}>{t(`myDt`)}</span>
                                                                    </button>
                                                                </div>
                                                                {guidePopupOrderSt[index] ?
                                                                    <div style={{
                                                                        width: "300px",
                                                                        height: "170px",
                                                                        position: "absolute",
                                                                        backgroundColor: "white",
                                                                        boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
                                                                        zIndex: 3,
                                                                        top: 0,
                                                                        right: 0
                                                                    }}>
                                                                        <div style={{
                                                                            paddingTop: "8px",
                                                                            cursor: "pointer",
                                                                            height: "40px",
                                                                            top: "0",
                                                                            right: "5px",
                                                                            display: "flex",
                                                                            marginRight: "6px",
                                                                            borderBottom: "solid",
                                                                            borderWidth: "thin 1px",
                                                                            borderColor: "#E6E6E6",

                                                                        }} onClick={() => closeGuidePopupOrder(index)}>
                                                                            <div style={{
                                                                                width: "100%",
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center"
                                                                            }}>
                                                                                <p style={{
                                                                                    margin: "0",
                                                                                    fontSize: "15px",
                                                                                    fontWeight: "bold"
                                                                                }}>{t(`myCardHeader`)}</p>
                                                                            </div>
                                                                            <div style={{
                                                                                position: "absolute",
                                                                                right: "6px"
                                                                            }}>
                                                                                <img style={{
                                                                                    width: "25px",
                                                                                    height: "25px"
                                                                                }} src={commonClose} alt=""/>
                                                                            </div>
                                                                        </div>

                                                                        <div style={{
                                                                            width: "100%",
                                                                            height: "110px",
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center"
                                                                        }}>
                                                                            <div>
                                                                                <p style={{
                                                                                    margin: "0",
                                                                                    fontSize: "15px"
                                                                                }}>
                                                                                    {t(`myOrddtCont1`)} <button
                                                                                    onClick={myOrdPgMove(order.orderNumber)}
                                                                                    style={{
                                                                                        width: "50px",
                                                                                        height: "30px",
                                                                                        border: "none",
                                                                                        background: "orange",
                                                                                        margin: "0 2px",
                                                                                        borderRadius: "20px"
                                                                                    }}>
                                                                                    <span style={{
                                                                                        margin: "0",
                                                                                        fontSize: "12px",
                                                                                        color: "white",
                                                                                        fontWeight: "bold"
                                                                                    }}>{t(`myDt`)}</span>
                                                                                </button> {t(`myOrddtCont2`)}
                                                                                </p>
                                                                                <p style={{
                                                                                    margin: "0",
                                                                                    fontSize: "15px",
                                                                                    marginTop: "5px"
                                                                                }}>
                                                                                    {t(`myOrddtCont3`)}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null
                                                                }

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        width: "25%",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        color: "#7d7a7a",
                                                    }}>
                                                        <div>
                                                            {orderStPrint(order?.orderStatus)}
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        width: "10%",
                                                        color: "#7d7a7a",
                                                        display:"flex",
                                                        alignItems:"center",
                                                        justifyContent:"center"
                                                    }}>
                                                        {/*<div>2023-04-20</div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            // payinfo map
                                            order.type === 'payinfo' && (order.payType === "PT1") ?
                                                <div style={{marginBottom: "30px"}} key={`pay-${order.seqNum}-${index}`}>
                                                    <div style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        fontSize: "19px",
                                                        borderTop: "solid",
                                                        borderWidth: "thin 1px",
                                                        borderColor: "#EDEDED",
                                                        padding: "20px 0"
                                                    }}>
                                                        <div style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            height: "50px",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <div style={{
                                                                fontSize: "22px",
                                                                cursor: "pointer",
                                                                paddingLeft: "10px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                height: "100%",
                                                            }} onClick={() => deliveryDetailTrueFn(order.seqNum, convertToShipco(order.dvType, order.orderdtDTO.shipco))}>
                                                                <img alt="order" style={{
                                                                    width: "30px",
                                                                    height: "30px",
                                                                    marginRight: "20px"
                                                                }} src={mypgOrderdt}/>
                                                                {order?.orderNumber ? order.orderNumber : order.orderdtDTO?.orderNumber}
                                                                -{order.shipInfoDTO.boxNum}
                                                            </div>


                                                            <div style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                height: "100%",
                                                            }}>
                                                                <div style={{
                                                                    borderWidth: "thin 1px",
                                                                    height: "30px",
                                                                }}>
                                                                </div>
                                                                <div style={{
                                                                    width: "130px",
                                                                    borderWidth: "thin 1px",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    height: "20px",
                                                                    alignItems: "center",
                                                                    marginRight: "10px"
                                                                }}>{order.regDate ? `${order.regDate.substring(0, 4)}-${order.regDate.substring(4, 6)}-${order.regDate.substring(6, 8)}` : ''}
                                                                </div>
                                                                <div style={{
                                                                    width: "90px",
                                                                    borderLeft: "solid",
                                                                    borderWidth: "thin 1px",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    height: "20px",
                                                                    alignItems: "center"
                                                                }}>{convertToShipco(order.dvType, order.orderdtDTO.shipco)}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        alignItems:"center",
                                                        borderColor: "lightgray",
                                                        borderWidth: "thin 1px",
                                                        padding: "30px 0",
                                                    }}>
                                                        <div style={{
                                                            width: "4%",
                                                            height: "100%",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}>
                                                            <input id="orderCheckBox" type="checkbox"
                                                                   checked={selectedRows.includes(order.seqNum)}
                                                                   onChange={() => handleCheckboxChange(order.seqNum)}
                                                                   style={{width: "20px", height: "20px"}}/>
                                                        </div>

                                                        <div className="mypgOrderCr" style={{
                                                            width: "10%",
                                                            margin: "auto",
                                                            display: "inline-block",
                                                            color: "#7d7a7a",
                                                        }}>
                                                            <div style={{fontWeight:"bold", fontSize:"15px"}}>{order.appRecDTO.countryDTO.countryEngNm}</div>
                                                            <div>{order.appRecDTO.recname}</div>
                                                        </div>

                                                        <div style={{
                                                            display: "block",
                                                            width: "22%",
                                                            margin: "auto",
                                                        }}>
                                                            {order.packListDTO && order.packListDTO.productsDTO && order.packListDTO.productsDTO.slice(0, 10).map((prod, prodIndex) => (
                                                                <div key={`product-${prod.seqnum}-${prodIndex}`}
                                                                     className="mypgOrderCr" style={{
                                                                    height: "auto",
                                                                    alignItems: "center",
                                                                    color: "#7d7a7a",
                                                                    fontSize: "15px"
                                                                }}>
                                                                    <div style={{display:"flex", alignItems:"center", height:"30px"}}>
                                                                    <span style={{fontSize:"13px", color:"darkgrey"}}>
                                                                        {
                                                                            prod.prodTy === "P00" ? t(`myProdTy1`) :
                                                                                prod.prodTy === "P01" ? t(`myProdTy2`) :
                                                                                    prod.prodTy === "P02" ? t(`myProdTy3`) :
                                                                                        prod.prodTy === "P03" ? t(`myProdTy4`) :
                                                                                            prod.prodTy === "P04" ? t(`myProdTy5`) :
                                                                                                prod.prodTy === "P05" ? t(`myProdTy6`) :
                                                                                                    null
                                                                        }
                                                                    </span>
                                                                        <span style={{margin:"0", fontSize:"13px", marginLeft:"5px", color:"dimgrey",
                                                                            overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap", wordBreak:"break-all"}}>
                                                                        {prod.name}
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        {/*해외배송비*/}
                                                        <div style={{width: "28%"}}>
                                                            <div style={{
                                                                display: "flex",
                                                                padding: "20px",
                                                                backgroundColor: "white",
                                                                borderRadius: "10px",
                                                                boxShadow: "0 5px 8px 0 rgba(0,0,0,0.1)"
                                                            }}>
                                                                <div style={{width: "80%"}}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        height: "40px"
                                                                    }}>
                                                                        <img style={{
                                                                            width: "23px",
                                                                            height: "23px",
                                                                            marginRight: "8px"
                                                                        }} src={mypgPayCardDelivery} alt=""/>
                                                                        <span style={{
                                                                            margin: "0",
                                                                            fontSize: "16px",
                                                                            color: "black",
                                                                            fontWeight: "bold"
                                                                        }}>{t(`modSHeader6`)}</span>
                                                                    </div>

                                                                    {/*FedEx*/}
                                                                    <div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
                                                                        <div style={{
                                                                            width:"85%",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            height: "40px",
                                                                            justifyContent:"space-between"
                                                                        }}>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "14px",
                                                                                color: "black"
                                                                            }}>FedEx</span>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "15px",
                                                                                color: order?.ttFedexPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "darkorange" : "black",
                                                                                fontWeight: order?.ttFedexPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "bold" : "normal"
                                                                            }}>
                                                                                {order?.ttFedexPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                        </div>
                                                                    </div>

                                                                     {/*EMS프리미엄*/}
                                                                    <div style={{display: "flex", alignItems: "center"}}>
                                                                        <div style={{
                                                                            width:"85%",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            height: "40px",
                                                                            justifyContent:"space-between"
                                                                        }}>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "14px",
                                                                                color: "black"
                                                                            }}>EMS-Premium</span>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "15px",
                                                                                color: order?.ttUpsPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "darkorange" : "black",
                                                                                fontWeight: order?.ttUpsPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "bold" : "normal"
                                                                            }}>
                                                                                {order?.ttUpsPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                        </div>
                                                                    </div>

                                                                    {/*/!*DHL*!/*/}
                                                                    {/*<div style={{display: "flex", alignItems: "center"}}>*/}
                                                                    {/*    <div style={{*/}
                                                                    {/*        width:"85%",*/}
                                                                    {/*        display: "flex",*/}
                                                                    {/*        alignItems: "center",*/}
                                                                    {/*        height: "40px",*/}
                                                                    {/*        justifyContent:"space-between"*/}
                                                                    {/*    }}>*/}
                                                                    {/*        <span style={{*/}
                                                                    {/*            margin: "0",*/}
                                                                    {/*            fontSize: "14px",*/}
                                                                    {/*            color: "black"*/}
                                                                    {/*        }}>DHL</span>*/}
                                                                    {/*        <span style={{*/}
                                                                    {/*            margin: "0",*/}
                                                                    {/*            fontSize: "15px",*/}
                                                                    {/*            color: order?.ttDhlPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "darkorange" : "black",*/}
                                                                    {/*            fontWeight: order?.ttDhlPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "bold" : "normal"*/}
                                                                    {/*        }}>*/}
                                                                    {/*            {order?.ttDhlPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>*/}
                                                                    {/*    </div>*/}
                                                                    {/*</div>*/}

                                                                    {/*EMS*/}
                                                                    <div style={{display: "flex", alignItems: "center"}}>
                                                                        <div style={{
                                                                            width:"85%",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            height: "40px",
                                                                            justifyContent:"space-between"
                                                                        }}>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "14px",
                                                                                color: "black"
                                                                            }}>EMS</span>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "15px",
                                                                                color: order?.ttUpsPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "darkorange" : "black",
                                                                                fontWeight: order?.ttUpsPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "bold" : "normal"
                                                                            }}>
                                                                                {order?.ttUpsPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                        </div>
                                                                    </div>

                                                                    {/*K-Packet*/}
                                                                    <div style={{display: "flex", alignItems: "center"}}>
                                                                        <div style={{
                                                                            width:"85%",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            height: "40px",
                                                                            justifyContent:"space-between"
                                                                        }}>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "14px",
                                                                                color: "black"
                                                                            }}>K-Packet</span>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "15px",
                                                                                color: order?.ttKPackPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "darkorange" : "black",
                                                                                fontWeight: order?.ttKPackPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "bold" : "normal"
                                                                            }}>
                                                                                {order?.ttKPackPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                        </div>
                                                                    </div>

                                                                    {/*항공소형포장물*/}
                                                                    <div style={{display: "flex", alignItems: "center"}}>
                                                                        <div style={{
                                                                            width:"85%",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            height: "40px",
                                                                            justifyContent:"space-between"
                                                                        }}>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "14px",
                                                                                color: "black"
                                                                            }}>Small-Packet</span>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "15px",
                                                                                color: order?.ttAirSPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "darkorange" : "black",
                                                                                fontWeight: order?.ttAirSPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "bold" : "normal"
                                                                            }}>
                                                                                {order?.ttAirSPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                        </div>
                                                                    </div>

                                                                      {/*선편*/}
                                                                    <div style={{display: "flex", alignItems: "center"}}>
                                                                        <div style={{
                                                                            width:"85%",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            height: "40px",
                                                                            justifyContent:"space-between"
                                                                        }}>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "14px",
                                                                                color: "black"
                                                                            }}>Ship(Sea)</span>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "15px",
                                                                                color: order?.ttSeaPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "darkorange" : "black",
                                                                                fontWeight: order?.ttSeaPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "bold" : "normal"
                                                                            }}>
                                                                                {order?.ttSeaPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                        </div>
                                                                    </div>

                                                                    {/*국내배송*/}
                                                                    {order.ttDomPrice > 0 ?
                                                                        <div style={{
                                                                            display: "flex",
                                                                            alignItems: "center"
                                                                        }}>
                                                                            <div style={{
                                                                                width: "85%",
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                height: "40px",
                                                                                justifyContent: "space-between"
                                                                            }}>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "14px",
                                                                                color: "black"
                                                                            }}>{t(`myPayinfoCardHeader2`)}</span>
                                                                                <span style={{
                                                                                    margin: "0",
                                                                                    fontSize: "15px",
                                                                                    color: order?.ttDomPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "darkorange" : "black",
                                                                                    fontWeight: order?.ttDomPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "bold" : null
                                                                                }}>
                                                                                {order?.ttDomPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                                <div style={{
                                                                    width: "20%",
                                                                    position: "relative",
                                                                    paddingBottom: "10px",
                                                                }}>
                                                                    <div style={{
                                                                        height: "50%",
                                                                        display: "flex",
                                                                        justifyContent: "flex-end"
                                                                    }}>
                                                                        <img onClick={() => openGuidePopupPay(index)}
                                                                             style={{
                                                                                 width: "40px",
                                                                                 height: "40px",
                                                                                 cursor: "pointer"
                                                                             }} src={mypgWarn} alt=""/>
                                                                    </div>
                                                                    <div style={{
                                                                        height: "50%",
                                                                        display: "flex",
                                                                        justifyContent: "flex-end",
                                                                        alignItems: "flex-end"
                                                                    }}>
                                                                        <button
                                                                            onClick={() => deliveryDetailTrueFn(order.seqNum, order.orderdtDTO.shipco)}
                                                                            style={{
                                                                                width: "60px",
                                                                                height: "40px",
                                                                                border: "none",
                                                                                background: "orange",
                                                                                marginTop: "auto",
                                                                                borderRadius: "20px"
                                                                            }}>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "13px",
                                                                                color: "white",
                                                                                fontWeight: "bold"
                                                                            }}>{t(`myDt`)}</span>
                                                                        </button>
                                                                    </div>
                                                                    {guidePopupPaySt[index] ?
                                                                        <div style={{
                                                                            width: "300px",
                                                                            height: "170px",
                                                                            position: "absolute",
                                                                            backgroundColor: "white",
                                                                            boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
                                                                            zIndex: 3,
                                                                            top: 0,
                                                                            right: 0
                                                                        }}>
                                                                            <div style={{
                                                                                paddingTop: "8px",
                                                                                cursor: "pointer",
                                                                                height: "40px",
                                                                                top: "0",
                                                                                right: "5px",
                                                                                display: "flex",
                                                                                marginRight: "6px",
                                                                                borderBottom: "solid",
                                                                                borderWidth: "thin 1px",
                                                                                borderColor: "#E6E6E6",

                                                                            }}
                                                                                 onClick={() => closeGuidePopupPay(index)}>
                                                                                <div style={{
                                                                                    width: "100%",
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center"
                                                                                }}>
                                                                                    <p style={{
                                                                                        margin: "0",
                                                                                        fontSize: "15px",
                                                                                        fontWeight: "bold"
                                                                                    }}>{t(`myCardHeader`)}</p>
                                                                                </div>
                                                                                <div style={{
                                                                                    position: "absolute",
                                                                                    right: "6px"
                                                                                }}>
                                                                                    <img style={{
                                                                                        width: "25px",
                                                                                        height: "25px"
                                                                                    }} src={commonClose} alt=""/>
                                                                                </div>
                                                                            </div>
                                                                            <div style={{
                                                                                width: "100%",
                                                                                height: "110px",
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center"
                                                                            }}>
                                                                                <div>
                                                                                    <div style={{marginBottom:"5px"}}>
                                                                                    <p style={{
                                                                                        margin: "0",
                                                                                        fontSize: "15px"
                                                                                    }}>
                                                                                        {t(`myOrddtCont1`)} <button
                                                                                        onClick={() => deliveryDetailTrueFn(order.seqNum, order.orderdtDTO.shipco)}
                                                                                        style={{
                                                                                            width: "50px",
                                                                                            height: "30px",
                                                                                            border: "none",
                                                                                            background: "orange",
                                                                                            margin: "0 2px",
                                                                                            borderRadius: "20px"
                                                                                        }}>
                                                                                        <span style={{
                                                                                            margin: "0",
                                                                                            fontSize: "12px",
                                                                                            color: "white",
                                                                                            fontWeight: "bold"
                                                                                        }}>{t(`myDt`)}</span>
                                                                                    </button> {t(`myOrddtCont2`)}
                                                                                    </p>
                                                                                    </div>
                                                                                    <p style={{
                                                                                        fontSize: "15px",
                                                                                    }}>
                                                                                        {t(`myPayinfoCont`)}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            width: "25%",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            color: "#7d7a7a",
                                                        }}>
                                                            <div>
                                                                {order.payStatus === "PS0" ?
                                                                    <p style={{
                                                                        margin: "0",
                                                                        color: "#98B0D4",
                                                                        fontSize: "15px"
                                                                    }}>{t(`prodStLabel8`)}</p>
                                                                    :
                                                                    order.payStatus === "PS1" ?
                                                                        <p style={{
                                                                            margin: "0",
                                                                            color: "#98B0D4",
                                                                            fontSize: "15px"
                                                                        }}>{t(`prodStLabel9`)}</p>
                                                                        :
                                                                        order.payStatus === "PS2" ?
                                                                            <p style={{
                                                                                margin: "0",
                                                                                color: "#FFB378",
                                                                                fontSize: "15px"
                                                                            }}>{t(`prodStLabel10`)}</p>
                                                                            :
                                                                            order.payStatus === "PS3" ?
                                                                                <p style={{
                                                                                    margin: "0",
                                                                                    color: "#979696",
                                                                                    fontSize: "15px"
                                                                                }}>{t(`prodStLabel11`)}</p>
                                                                                :
                                                                                order.payStatus === "PS4" ?
                                                                                    <p style={{
                                                                                        margin: "0",
                                                                                        color: "#FF8F62",
                                                                                        fontSize: "15px"
                                                                                    }}>{t(`prodStLabel12`)}</p>
                                                                                    :
                                                                                    order.payStatus === "PS5" ?
                                                                                        <p style={{
                                                                                            margin: "0",
                                                                                            color: "#FF8F62",
                                                                                            fontSize: "15px"
                                                                                        }}>{t(`prodStLabel13`)}</p>
                                                                                        :
                                                                                    null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            width:"10%",
                                                            color: "#7d7a7a",
                                                            margin:"auto"
                                                        }}>
                                                            <div style={{textAlign:"center"}}>2023-04-20</div>
                                                            <div onClick={()=> selectOsTrackNum(convertToShipco(order.dvType), order.shipInfoDTO.outWayNum)} style={{
                                                                cursor:"pointer",
                                                                color:"black",
                                                                marginTop:"5px",
                                                                fontSize:"16px",
                                                                fontWeight:"bold",
                                                                textAlign:"center"
                                                            }}>{order.shipInfoDTO.outWayNum}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                    )
                                })}
                            </div>
                        }
                    </div>
                </div>
        },
        {
            label: t(`myTab2`), content:
                <div>
                    <div style={{height: "80px", display: "flex", alignItems: "center", position: "relative"}}>
                        <button style={{
                            height: "40px",
                            minWidth: "200px",
                            borderColor: "lightgray",
                            border: "none",
                            backgroundColor: "#efeef0",
                            color: "#AAA7A6",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "6px",
                            overflow: "hidden",
                            position: "relative"
                        }} ref={payPeriodButtonRef}
                                onClick={() => setPayPeriodSelectShow(true)}>{payPeriodSelectCommonValue}<i
                            style={{
                                fontSize: "27px",
                                position: "absolute",
                                right: "3px",
                                bottom: "2px"
                            }}><BiChevronDown/></i></button>
                        {
                            payPeriodSelectShow === true ?
                                <div style={{
                                    width: "200px",
                                    height: "auto",
                                    border: "none",
                                    boxShadow: "0px 0px 10px lightgray",
                                    position: "absolute",
                                    left: "0",
                                    top: "70px",
                                    zIndex: 3,
                                    backgroundColor: "white",
                                    borderRadius: "6px"
                                }} ref={payPeriodDivRef} onClick={payPeriodDivHandle}>
                                    <button className="mypgFilterBt"
                                            onClick={() => payPeriodBtHandle(t('myPrd1'))}>{t(`myPrd1`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => payPeriodBtHandle(t('myPrd2'))}>{t(`myPrd2`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => payPeriodBtHandle(t('myPrd3'))}>{t(`myPrd3`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => payPeriodBtHandle(t('myPrd4'))}>{t(`myPrd4`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => payPeriodBtHandle(t('myPrd5'))}>{t(`myPrd5`)}
                                    </button>
                                </div>
                                :
                                null
                        }
                        <button style={{
                            height: "40px",
                            width: "200px",
                            borderColor: "lightgray",
                            border: "none",
                            backgroundColor: "#efeef0",
                            color: "#AAA7A6",
                            display: "flex",
                            marginLeft: "20px",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "6px",
                            overflow: "hidden",
                            position: "relative"
                        }} ref={payStButtonRef}
                                onClick={() => setPayStSelectShow(true)}>{filterPaySt(payStSelectCommonValue)}<i
                            style={{
                                fontSize: "27px",
                                position: "absolute",
                                right: "3px",
                                bottom: "2px"
                            }}><BiChevronDown/></i></button>
                        {
                            payStSelectShow === true ?
                                <div style={{
                                    width: "300px",
                                    height: "auto",
                                    border: "none",
                                    boxShadow: "0px 0px 10px lightgray",
                                    position: "absolute",
                                    left: "170px",
                                    top: "70px",
                                    zIndex: 3,
                                    backgroundColor: "white",
                                    borderRadius: "6px"
                                }} ref={payStDivRef} onClick={payStDivHandle}>
                                    <button className="mypgFilterBt"
                                            onClick={() => payStBtHandle("전체")}>{t(`all`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => payStBtHandle("PS0")}>{t(`prodStLabel8`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => payStBtHandle("PS1")}>{t(`prodStLabel9`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => payStBtHandle("PS2")}>{t(`prodStLabel10`)}
                                    </button>
                                </div>
                                :
                                null
                        }
                    </div>
                    {/*결제내역 컨테이너*/}
                    <div style={{
                        minHeight: "600px",
                        border: "solid",
                        borderWidth: "thin 1px",
                        borderColor: "lightgray",
                        borderRadius: "4px",
                        margin: "10px"
                    }}>
                        <div style={{
                            borderBottom: "solid",
                            borderWidth: "thin 1px",
                            borderColor: "lightgray",
                            display: "flex",
                            height: "60px",
                            fontSize: "15px",
                            color: "dimgrey",
                            marginBottom: "15px",
                            backgroundColor:"#FAFAFA"
                        }}>
                            <div style={{
                                width: "10%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <p style={{fontSize:"14px"}}>{t(`myHtDate`)}</p>
                            </div>
                            <div style={{
                                width: "11%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <p style={{fontSize:"14px"}}>{t(`myUseDep`)}</p>
                            </div>
                            <div style={{
                                width: "11%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <p style={{fontSize:"14px"}}>{t(`myUsePt`)}</p>
                            </div>
                            <div style={{
                                width: "11%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <p style={{margin:"0", fontSize:"14px", color:"dimgrey"}}>{t(`myUseCp`)}</p>
                            </div>
                            <div style={{
                                width: "9%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                            </div>
                            <div style={{
                                width: "18%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <p style={{margin:"0", fontSize:"14px", color:"dimgrey"}}>{t(`myFnPr`)}</p>
                            </div>
                            <div style={{
                                width: "15%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <p style={{margin:"0", fontSize:"14px", color:"dimgrey"}}>{t(`myPt`)}</p>
                            </div>
                            <div style={{
                                width: "15%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <p style={{margin:"0", fontSize:"14px", color:"dimgrey"}}>{t(`modPlHeader5`)}</p>
                            </div>
                        </div>
                        {/*아래에 맵핑*/}
                        {sortedPayList.filter(filterStatus).map((pay, index) => (
                            <div key={index}
                                 style={{height: "50px", display: "flex", color: "dimgrey", fontSize: "14px"}}>
                                <div style={{
                                    width: "10%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{formatDate(pay?.regDate)}
                                </div>
                                <div style={{
                                    width: "11%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{pay?.depUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                </div>
                                <div style={{
                                    width: "11%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{pay?.pointUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                </div>
                                <div style={{
                                    width: "11%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{pay?.couponUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                </div>
                                    <div style={{
                                        width: "9%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                        <div style={{
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}>
                                            {pay.payType === "PT3" ?
                                            <button onClick={()=> integPayDetail(pay.seqNum)} style={{
                                                width: "90px",
                                                height: "40px",
                                                padding:"5px",
                                                border: "solid",
                                                borderWidth:"thin 1px",
                                                borderColor:"lightgray",
                                                borderRadius:"4px",
                                            }}>
                                                <p style={{margin:"0", fontSize:"13px", fontWeight:"lighter"}}>{t(`myHtItBt`)}</p>
                                            </button>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                <div style={{
                                    width: "18%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                    // 추후 페이팔 달러금액은 API 연동 금액으로 대체
                                }}>
                                    {pay?.lastPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    {pay.payWay === "PM01" ?
                                    <p style={{margin:"0", color:"#FF6700"}}>{pay?.lastForPrice}{pay?.forCurrency}</p>
                                    : null}
                                </div>
                                <div style={{
                                    width: "15%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    {pay.payWay === "PM00" ? t(`modNoBank`) :
                                        pay.payWay === "PM01" ? "Paypal" :
                                            pay.payWay === "PM02" ? "Wechat" :
                                                pay.payWay === "PM03" ? "Alipay" :
                                                    pay.payWay === "PM04" ? "Point" :
                                                        pay.payWay === "PM05" ? "Deposit" : null}
                                </div>
                                <div style={{
                                    width: "15%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    { pay.payStatus === "PS1" ?
                                            <p style={{
                                                margin: "0",
                                                color: "#98B0D4",
                                                fontSize: "15px"
                                            }}>{t(`prodStLabel9`)}</p>
                                                :
                                                pay.payStatus === "PS5" ?
                                                    <p style={{
                                                        margin: "0",
                                                        color: "#EB939D",
                                                        fontSize: "15px"
                                                    }}>{t(`prodStLabel13`)}</p>
                                                    :
                                                    <p style={{
                                                        margin: "0",
                                                        color: "#FFB378",
                                                        fontSize: "15px"
                                                    }}>{t(`prodStLabel10`)}</p>}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
        },
        {
            label: t(`myTab3`), content:
                <div>
                    <div style={{height: "80px", display: "flex", alignItems: "center", position: "relative"}}>
                        <button style={{
                            height: "40px",
                            minWidth: "200px",
                            borderColor: "lightgray",
                            border: "none",
                            backgroundColor: "#efeef0",
                            color: "#AAA7A6",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "6px",
                            overflow: "hidden",
                            position: "relative"
                        }} ref={dacPeriodButtonRef}
                                onClick={() => setDacPeriodSelectShow(true)}>{dacPeriodSelectCommonValue}<i style={{
                                fontSize: "27px",
                                position: "absolute",
                                right: "3px",
                                bottom: "2px"
                            }}><BiChevronDown/></i></button>
                        {
                            dacPeriodSelectShow === true ?
                                <div style={{
                                    width: "200px",
                                    height: "auto",
                                    border: "none",
                                    boxShadow: "0px 0px 10px lightgray",
                                    position: "absolute",
                                    left: "0",
                                    top: "70px",
                                    zIndex: 3,
                                    backgroundColor: "white",
                                    borderRadius: "6px"
                                }} ref={dacPeriodDivRef} onClick={dacPeriodDivHandle}>
                                    <button className="mypgFilterBt"
                                            onClick={() => dacPeriodBtHandle(t('myPrd1'))}>{t(`myPrd1`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => dacPeriodBtHandle(t('myPrd2'))}>{t(`myPrd2`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => dacPeriodBtHandle(t('myPrd3'))}>{t(`myPrd3`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => dacPeriodBtHandle(t('myPrd4'))}>{t(`myPrd4`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => dacPeriodBtHandle(t('myPrd5'))}>{t(`myPrd5`)}
                                    </button>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div className="display-flex font-t-cont font-s-16">
                        <div style={{width: "35%", height: "40px", margin: "10px 10px 0 10px"}}>
                            <p style={{paddingTop: "10px"}}>{t(`myDepHt`)}</p>
                        </div>
                        <div style={{width: "35%", height: "40px", margin: "10px 10px 0 10px"}}>
                            <p style={{paddingTop: "10px"}}>{t(`myPtHt`)}</p>
                        </div>
                        <div style={{width: "30%", height: "40px", margin: "10px 10px 0 10px"}}>
                            <p style={{paddingTop: "10px"}}>{t(`myCpHt`)}</p>
                        </div>
                    </div>
                    {/*예적쿠내역 컨테이너*/}
                    <div className="font-t-cont display-flex">
                        {/*예치금*/}
                        <div style={{
                            width: "35%",
                            minHeight: "500px",
                            border: "solid",
                            margin: "10px",
                            borderWidth: "thin 1px",
                            borderColor: "#EAEAEA",
                            borderRadius: "4px"
                        }}>
                            <div style={{
                                borderBottom: "solid",
                                height: "40px",
                                display: "flex",
                                fontSize: "14px",
                                color: "dimgrey",
                                borderWidth: "thin 1px",
                                borderColor: "#EAEAEA",
                                margin: "10px 0 10px 0"
                            }}>
                                <div style={{
                                    width: "20%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{t(`myHtDate`)}
                                </div>
                                <div style={{
                                    width: "40%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{t(`myCont`)}
                                </div>
                                <div style={{
                                    width: "20%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{t(`myPr`)}
                                </div>
                                <div style={{
                                    width: "20%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{t(`myBal`)}
                                </div>
                            </div>
                            {sortedDacList.filter(dac => dac.depPrice > 0).map((dac, index) => (
                                <div key={index} style={{height: "30px", display: "flex", fontSize: "12px"}}>

                                    <div style={{
                                        width: "20%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>{formatDate(dac?.regDate)}
                                    </div>
                                    <div style={{
                                        width: "40%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        wordBreak: "break-all"
                                    }}><span style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        wordBreak: "break-all"
                                    }}>{dac.content}</span>
                                    </div>
                                    <div style={{
                                        width: "20%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>{(dac.type === '0' || dac?.type === '2') ? "+" : "-"}{dac?.depPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                    <div style={{
                                        width: "20%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>{dac?.depBalance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/*적립금*/}
                        <div style={{
                            width: "35%",
                            minHeight: "500px",
                            border: "solid",
                            margin: "10px",
                            borderWidth: "thin 1px",
                            borderColor: "#EAEAEA",
                            borderRadius: "4px"
                        }}>
                            <div style={{
                                borderBottom: "solid",
                                height: "40px",
                                display: "flex",
                                fontSize: "14px",
                                color: "dimgrey",
                                borderWidth: "thin 1px",
                                borderColor: "#EAEAEA",
                                margin: "10px 0 10px 0"
                            }}>
                                <div style={{
                                    width: "20%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{t(`myHtDate`)}
                                </div>
                                <div style={{
                                    width: "40%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{t(`myCont`)}
                                </div>
                                <div style={{
                                    width: "20%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{t(`myPr`)}
                                </div>
                                <div style={{
                                    width: "20%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{t(`myBal`)}
                                </div>
                            </div>
                            {sortedDacList.filter(dac => dac.ptPrice > 0).map((dac, index) => (
                                <div key={index} style={{height: "30px", display: "flex", fontSize: "12px"}}>

                                    <div style={{
                                        width: "20%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>{formatDate(dac?.regDate)}
                                    </div>
                                    <div style={{
                                        width: "40%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        wordBreak: "break-all"
                                    }}><span style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        wordBreak: "break-all"
                                    }}>{dac.content}</span>
                                    </div>
                                    <div style={{
                                        width: "20%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>{(dac.type === '0' || dac?.type === '2') ? "+" : "-"}{dac?.ptPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                    <div style={{
                                        width: "20%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>{dac?.ptBalance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/*쿠폰*/}
                        <div style={{
                            width: "30%",
                            minHeight: "500px",
                            border: "solid",
                            margin: "10px",
                            borderWidth: "thin 1px",
                            borderColor: "#EAEAEA",
                            borderRadius: "4px"
                        }}>
                            <div style={{
                                borderBottom: "solid",
                                height: "40px",
                                display: "flex",
                                fontSize: "14px",
                                color: "dimgrey",
                                borderWidth: "thin 1px",
                                borderColor: "#EAEAEA",
                                margin: "10px 0 10px 0"
                            }}>
                                <div style={{
                                    width: "25%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{t(`myHtDate`)}
                                </div>
                                <div style={{
                                    width: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{t(`myCpNm`)}
                                </div>
                                <div style={{
                                    width: "25%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{t(`myPr`)}
                                </div>
                            </div>
                            {sortedDacList.filter(dac => dac.cpPrice > 0).map((dac, index) => (
                                <div key={index} style={{height: "30px", display: "flex", fontSize: "12px"}}>

                                    <div style={{
                                        width: "25%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>{formatDate(dac?.regDate)}
                                    </div>
                                    <div style={{
                                        width: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        wordBreak: "break-all"
                                    }}><span style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        wordBreak: "break-all"
                                    }}>{dac.cpNm}</span>
                                    </div>
                                    <div style={{
                                        width: "25%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>{(dac.type === '0' || dac?.type === '2') ? "+" : "-"}{dac?.cpPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
        },
    ];

    return (
        <div className="mypgContainer">
            {/*주문현황*/}
            <div style={{height: "auto"}}>
                <div style={{display:"flex", alignItems:"flex-start", padding:"10px 0"}}>
                    <img className="wd-25 ht-25" src={commomHeader} alt="header"/>
                    <p className="font-s-20 m-l-10 font-t-title font-c-default">{t(`myOrdPc`)}</p>
                </div>
                <div className="font-t-cont" style={{
                    backgroundColor:"#FAFAFA",
                    borderRadius:"6px",
                    display: "flex",
                    marginTop: "20px",
                    padding: "70px",
                }}>
                    <div style={{width: "30%", borderRadius:"10px", backgroundColor:"white", boxShadow: "0 0 12px 6px rgba(0,0,0,0.03)"}}>
                        <div style={{
                            height: "120px",
                            display: "flex",
                            overflow: "hidden",
                            justifyContent: "center",
                            alignItems:"center",
                        }}><img alt="" src={mypgOrder} style={{width:"80px", height:"80px"}}/></div>
                        <div style={{
                            height: "50px",
                            fontWeight: "bold",
                            fontSize: "18px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "dimgrey",
                        }}>{t(`myOrdPcHeader1`)}
                        </div>
                        <div onClick={() => selectNoDateTotalOrderList("OS00")} style={{
                            width:"240px",
                            margin:"auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "dimgrey",
                            cursor:"pointer",
                        }}>
                            <p style={{height:"100%", display:"flex", justifyContent:"flex-start",
                                fontWeight:"lighter", alignItems:"center", margin:"0", fontSize:"15px"}}>{t(`myPc1`)}</p>
                            <span style={{
                            color: "darkorange",
                            fontWeight: "bold",
                                fontSize: "17px",
                                height:"100%",
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"center",
                        }}>{processData.OS00 || 0}</span></div>

                        <div onClick={() => selectNoDateTotalOrderList("OS01")} style={{
                            width:"240px",
                            margin:"auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "dimgrey",
                            cursor:"pointer"
                        }}>
                            <p style={{height:"100%", display:"flex", justifyContent:"flex-start",
                                fontWeight:"lighter", alignItems:"center", margin:"0", fontSize:"15px"}}>{t(`myPc2`)}</p>
                            <span style={{
                                color: "darkorange",
                                fontWeight: "bold",
                                fontSize: "17px",
                                height:"100%",
                                display:"flex", justifyContent:"center", alignItems:"center"
                        }}>{processData.OS01 || 0}</span></div>

                        <div onClick={() => selectNoDateTotalOrderList("OS02")} style={{
                            width:"240px",
                            margin:"auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "dimgrey",
                            cursor:"pointer"
                        }}>
                            <p style={{height:"100%", display:"flex", justifyContent:"flex-start",
                                fontWeight:"lighter", alignItems:"center", margin:"0", fontSize:"15px"}}>{t(`myPc3`)}</p>
                            <span style={{
                                color: "darkorange",
                                fontWeight: "bold",
                                fontSize: "17px",
                                height:"100%",
                                display:"flex", justifyContent:"center", alignItems:"center"
                        }}>{processData.OS02 || 0}</span></div>
                        <div onClick={() => selectNoDateTotalOrderList("OS03")} style={{
                            width:"240px",
                            margin:"auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "dimgrey",
                            cursor:"pointer"
                        }}>
                            <p style={{height:"100%", display:"flex", justifyContent:"flex-start",
                                fontWeight:"lighter", alignItems:"center", margin:"0", fontSize:"15px"}}>{t(`myPc4`)}</p>
                            <span style={{
                                color: "darkorange",
                                fontWeight: "bold",
                                fontSize: "17px",
                                height:"100%",
                                display:"flex", justifyContent:"center", alignItems:"center"
                        }}>{processData.OS03 || 0}</span></div>
                        <div onClick={() => selectNoDateTotalOrderList("OS04")} style={{
                            width:"240px",
                            margin:"auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "dimgrey",
                            cursor:"pointer"
                        }}>
                            <p style={{height:"100%", display:"flex", justifyContent:"flex-start",
                                fontWeight:"lighter", alignItems:"center", margin:"0", fontSize:"15px"}}>{t(`myPc5`)}</p>
                            <span style={{
                                color: "darkorange",
                                fontWeight: "bold",
                                fontSize: "17px",
                                height:"100%",
                                display:"flex", justifyContent:"center", alignItems:"center"
                        }}>{processData.OS04 || 0}</span></div>
                        <div onClick={() => selectNoDateTotalOrderList("OS05")} style={{
                            width:"240px",
                            margin:"auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "dimgrey",
                            cursor:"pointer"
                        }}>
                            <p style={{height:"100%", display:"flex", justifyContent:"flex-start",
                                fontWeight:"lighter", alignItems:"center", margin:"0", fontSize:"15px"}}>{t(`myPc6`)}</p>
                            <span style={{
                                color: "darkorange",
                                fontWeight: "bold",
                                fontSize: "17px",
                                height:"100%",
                                display:"flex", justifyContent:"center", alignItems:"center"
                        }}>{processData.OS05 || 0}</span></div>

                    </div>
                    <div style={{
                        width: "5%",
                        color: "dimgrey",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <i style={{
                            fontSize: "25px",
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center"
                        }}><AiOutlineDoubleRight style={{color: "#cbcbcc"}}/></i>
                    </div>
                    <div style={{width: "30%", borderRadius:"10px", backgroundColor:"white", boxShadow: "0 0 12px 6px rgba(0,0,0,0.03)"}}>
                        <div style={{
                            height: "120px",
                            display: "flex",
                            overflow: "hidden",
                            justifyContent: "center",
                            alignItems:"center"
                        }}><img alt="" src={mypgDelivery} style={{width:"80px", height:"80px"}}/></div>
                        <div style={{
                            height:"50px",
                            fontWeight: "bold",
                            fontSize: "18px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "dimgrey"
                        }}>{t(`myOrdPcHeader2`)}
                        </div>
                        <div onClick={() => selectNoDateTotalOrderList("OS06")} style={{
                            width:"240px",
                            margin:"auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "dimgrey",
                            cursor:"pointer"
                        }}>
                            <p style={{height:"100%", display:"flex", justifyContent:"flex-start",
                                fontWeight:"lighter", alignItems:"center", margin:"0", fontSize:"15px"}}>{t(`myPc7`)}</p>
                            <span style={{
                                color: "darkorange",
                                fontWeight: "bold",
                                fontSize: "17px",
                                height:"100%",
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"center"
                        }}>{processData.OS06 || 0}</span></div>
                        <div onClick={() => selectNoDateTotalOrderList("OS07")} style={{
                            width:"240px",
                            margin:"auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "dimgrey",
                            cursor:"pointer"
                        }}>
                            <p style={{height:"100%", display:"flex", justifyContent:"flex-start",
                                fontWeight:"lighter", alignItems:"center", margin:"0", fontSize:"15px"}}>{t(`myPc8`)}</p>
                            <span style={{
                                color: "darkorange",
                                fontWeight: "bold",
                                fontSize: "17px",
                                height:"100%",
                                display:"flex", justifyContent:"center", alignItems:"center"
                        }}>{processData.OS07 || 0}</span></div>
                        <div onClick={() => selectNoDateTotalOrderList("OS08")} style={{
                            width:"240px",
                            margin:"auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "dimgrey",
                            cursor:"pointer"
                        }}>
                            <p style={{height:"100%", display:"flex", justifyContent:"flex-start",
                                fontWeight:"lighter", alignItems:"center", margin:"0", fontSize:"15px"}}>{t(`myPc9`)}</p>
                            <span style={{
                                color: "darkorange",
                                fontWeight: "bold",
                                fontSize: "17px",
                                height:"100%",
                                display:"flex", justifyContent:"center", alignItems:"center"
                        }}>{processData.OS08 || 0}</span></div>
                        <div onClick={() => selectNoDateTotalOrderList("PS0")} style={{
                            width:"240px",
                            margin:"auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "dimgrey",
                            cursor:"pointer"
                        }}>
                            <p style={{height:"100%", display:"flex", justifyContent:"flex-start",
                                fontWeight:"lighter", alignItems:"center", margin:"0", fontSize:"15px"}}>{t(`myPc10`)}</p>
                            <span style={{
                                color: "darkorange",
                                fontWeight: "bold",
                                fontSize: "17px",
                                height:"100%",
                                display:"flex", justifyContent:"center", alignItems:"center"
                        }}>{processData.OS09 + processData.PS0 || 0}</span></div>
                        <div onClick={() => selectNoDateTotalOrderList("PS1")} style={{
                            width:"240px",
                            margin:"auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "dimgrey",
                            cursor:"pointer"
                        }}>
                            <p style={{height:"100%", display:"flex", justifyContent:"flex-start",
                                fontWeight:"lighter", alignItems:"center", margin:"0", fontSize:"15px"}}>{t(`myPc11`)}</p>
                            <span style={{
                                color: "darkorange",
                                fontWeight: "bold",
                                fontSize: "17px",
                                height:"100%",
                                display:"flex", justifyContent:"center", alignItems:"center"
                        }}>{processData.OS10 + processData.PS1 || 0}</span></div>
                        <div onClick={() => selectNoDateTotalOrderList("PS2")} style={{
                            width:"240px",
                            margin:"auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "dimgrey",
                            cursor:"pointer"
                        }}>
                            <p style={{height:"100%", display:"flex", justifyContent:"flex-start",
                                fontWeight:"lighter", alignItems:"center", margin:"0", fontSize:"15px"}}>{t(`myPc12`)}</p>
                            <span style={{
                                color: "darkorange",
                                fontWeight: "bold",
                                fontSize: "17px",
                                height:"100%",
                                display:"flex", justifyContent:"center", alignItems:"center"
                        }}>{processData.OS11 + processData.PS2 || 0}</span></div>
                        <div onClick={() => selectNoDateTotalOrderList("PS3")} style={{
                            width:"240px",
                            margin:"auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "dimgrey",
                            cursor:"pointer",
                            marginBottom:"20px"
                        }}>
                            <p style={{height:"100%", display:"flex", justifyContent:"flex-start",
                                fontWeight:"lighter", alignItems:"center", margin:"0", fontSize:"15px"}}>{t(`myPc13`)}</p>
                            <span style={{
                                color: "darkorange",
                                fontWeight: "bold",
                                fontSize: "17px",
                                height:"100%",
                                display:"flex", justifyContent:"center", alignItems:"center"
                        }}>{processData.OS12 + processData.PS3 || 0}</span></div>
                    </div>
                    <div style={{
                        width: "5%",
                        color: "dimgrey",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <div style={{fontSize:"20px", display:"flex", justifyContent:"center", textAlign:"center"}}>
                        <img style={{width:"20px", height:"20px", opacity:"0.4"}} alt="" src={mypgReturnIcon}/>
                        </div>
                    </div>
                    <div style={{width: "30%", borderRadius:"10px", backgroundColor:"white", boxShadow: "0 0 12px 6px rgba(0,0,0,0.03)"}}>
                        <div style={{
                            height: "120px",
                            display: "flex",
                            overflow: "hidden",
                            justifyContent: "center",
                            alignItems:"center"
                        }}><img alt="" src={mypgReturn} style={{width:"80px", height:"80px"}}/></div>
                        <div style={{
                            height: "50px",
                            fontWeight: "bold",
                            fontSize: "18px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "dimgrey"
                        }}>{t(`myOrdPcHeader3`)}
                        </div>
                        <div onClick={() => selectNoDateTotalOrderList("PS4")} style={{
                            width:"240px",
                            margin:"auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "dimgrey",
                            cursor:"pointer"
                        }}>
                            <p style={{height:"100%", display:"flex", justifyContent:"flex-start",
                                fontWeight:"lighter", alignItems:"center", margin:"0", fontSize:"15px"}}>{t(`myPc14`)}</p>
                            <span style={{
                                color: "darkorange",
                                fontWeight: "bold",
                                fontSize: "17px",
                                height:"100%",
                                display:"flex", justifyContent:"center", alignItems:"center"
                        }}>{processData.OS13 + processData.PS4 || 0}</span></div>
                        <div onClick={() => selectNoDateTotalOrderList("PS5")} style={{
                            width:"240px",
                            margin:"auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "dimgrey",
                            cursor:"pointer"
                        }}>
                            <p style={{height:"100%", display:"flex", justifyContent:"flex-start",
                                fontWeight:"lighter", alignItems:"center", margin:"0", fontSize:"15px"}}>{t(`myPc15`)}</p>
                            <span style={{
                                color: "darkorange",
                                fontWeight: "bold",
                                fontSize: "17px",
                                height:"100%",
                                display:"flex", justifyContent:"center", alignItems:"center"
                        }}>{processData.OS14 + processData.PS5 || 0}</span></div>
                    </div>
                </div>
                <div>
                    <div className="tab-menu font-t-cont">
                        <div className="tabs">
                            {tabs.map((tab, index) => (
                                <button
                                    key={index}
                                    className={`tab ${index === activeTab ? 'active' : ''}`}
                                    onClick={() => {
                                        // 나중에 주문서선택하면 주문서1개월, 결제내역 1개월, 예&적 1개월, 결제내역 선택하면 나머지 1개월, 예&적 선택하면 나머지 1개월 기본세팅 ㄱㄱ
                                        if (tab.label === t(`myTab1`)) {
                                            setPeriodSelectCommonValue(t(`myPrd1`));
                                            setOrderStSelectCommonValue(t(`all`));
                                        }
                                        if (tab.label === t(`myTab2`)) {
                                            setPayPeriodSelectCommonValue(t(`myPrd1`))
                                            setPayStSelectCommonValue(t(`all`));
                                        }
                                        if (tab.label === t(`myTab3`)) {
                                            setDacPeriodSelectCommonValue(t(`myPrd1`))
                                        }

                                        setActiveTab(index);
                                    }}
                                >
                                    {tab.label}
                                </button>
                            ))}
                        </div>
                        <div className="content">{tabs[activeTab].content}</div>
                    </div>
                </div>
            </div>
            {deliveryDetailSt ?
                <DeliveryPriceDetail deliveryDetailSt={deliveryDetailSt} deliveryDetailFalseFn={deliveryDetailFalseFn} selectedShip={payShipco}
                                     paySeqnum={paySeqnum} updateAppRecinfo={updateAppRecinfo} updatePayinfoList={updatePayinfoList} pathComponent={pathComponent}/>
                :
                isLoading ?
                    <CommonLoading/>
                    :
                    integPayDetailPopupSt ?
                        <IntegDetailPopup integDetailPopupClose={integDetailPopupClose} integPaySeqnum={integPaySeqnum}/>
                        :
                        downNoSelectSt ?
                            <MsgCheckModal downNoSelectSt={downNoSelectSt} closeDownNoSelect={closeDownNoSelect}/>
                            :
                null
            }
            <TopBtn/>
        </div>
    )
}

export default withAuth(UserMyPg);
