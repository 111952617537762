import '../../css/mobile/MUserReviewWritePg.css';
import MHeader from "../../components/mobile/MHeader";
import commonHeader from "../../assets/img/commonHeader.png";
import Delete from "../../assets/img/deleteIcon.svg";
import Upload from "../../assets/img/uploadPhotoIcon.svg";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {connect} from "../../services/WebsocketService";
import reviewUpload from "../../assets/img/reviewUpload.png";
import Expand from "../../assets/img/commonBottom.png";
import {useWebSocket} from "../../services/WebsocketContext";

const MUserReviewWritePg = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({value: '', label: t(`ordCrLabel`)});

    const [titleErrorMsg, setTitleErrorMsg] = useState(false);
    const [countryErrorMsg, setCountryErrorMsg] = useState(false);
    const [shipcoErrorMsg, setShipcoErrorMsg] = useState(false);
    const [reviewErrorMsg, setReviewErrorMsg] = useState(false);


    // 여기서부터 커스텀 셀렉트
    const [countryShow, setCountryShow] = useState(false);
    const countryRef = useRef(null);

    const toggleCountryShow = () => {
        setCountryShow(!countryShow);
    };

    const handleCountryChange = (country) => {
        setSelectedCountry(country);
        setCountryShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setCountryShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트

    const [memberInfo, setMemberInfo] = useState("");
    const findMember = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                setMemberInfo(response.data)
            })
            .catch(() => {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (SSAT || LCAT) {
            findMember();
        }
    }, [SSAT, LCAT])

    const stompClientRef = useRef(null);
    const { subscribe, unsubscribe, websocketConnect, isConnected, stompClient } = useWebSocket();
    useEffect(() => {
        if (!memberInfo && !memberInfo.mbNum) return;
        const initializeSubscription = async () => {
            if (!isConnected || !stompClient) {
                stompClientRef.current = await websocketConnect(memberInfo.mbNum);
            } else {
                stompClientRef.current = stompClient;
            }
        };
        initializeSubscription();
        return () => {
            if (stompClientRef.current && stompClientRef.current.length > 0) {
                // 구독이 존재하는 경우에만 해제
                stompClientRef.current.forEach((sub) => {
                    unsubscribe(sub);
                });
            }
            stompClientRef.current = null;
        };
    }, [memberInfo, isConnected, subscribe, unsubscribe, stompClient]);



    const selectCountry = (() => {
        axios({
            method: 'get',
            url: '/order/selectCountry',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                // const countries = response.data.map(country => ({
                //     value: country.countryCode,
                //     label: country.countryNm + '(' + country.countryEngNm + ')'
                // }));
                // setCountryList(countries);
                const countries = response.data
                    .map(country => ({
                        value: country.countryCode,
                        label: `${country.countryNm}(${country.countryEngNm})`,
                        engName: country.countryEngNm
                    }))
                    .sort((a, b) => a.engName.localeCompare(b.engName));

                setCountryList(countries.map(country => ({
                    value: country.value,
                    label: country.label
                })));
            })
            .catch((error) => {
                console.log(error)
            })
    })


    useEffect(() => {
        selectCountry();
        bringToShipCo();
    }, [])

    const [shipcoList, setShipcoList] = useState([]); // DB데이터

    const [selectedShipco, setSelectedShipco] = useState('');

    const shipCode = "1"
    const bringToShipCo = () => axios({
        method: 'get',
        url: '/shipcompany/bringToShipCo/' + shipCode,
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
    })
        .then((response) => {
            setShipcoList(response.data)
        })

    // 여기서부터 커스텀 셀렉트
    const [shipcoShow, setShipcoShow] = useState(false);
    const shipcoRef = useRef(null);

    const toggleShipcoShow = () => {
        setShipcoShow(!shipcoShow);
    };

    const handleShipcoChange = (shipco) => {
        setSelectedShipco(shipco);
        setShipcoShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (shipcoRef.current && !shipcoRef.current.contains(event.target)) {
                setShipcoShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트


    const [titleText, setTitleText] = useState('');
    const handleChangeTitleText = (e) => {
        setTitleText(e.target.value);
    };

    const [reviewText, setReviewText] = useState('');
    const handleChangeReviewText = (e) => {
        setReviewText(e.target.value);
    };


    const [reviewContainers, setReviewContainers] = useState([{
        reviewImg: reviewUpload,
        reviewImgSt: false,
        reviewImgError: false
    }]);
    const reviewImgHandle = (idx) => (e) => {
        const file = e.target.files[0];

        if (file) {
            setReviewContainers((prev) => {
                const newContainers = [...prev];

                if (!file.type.startsWith('image/')) {
                    newContainers[idx] = {
                        ...newContainers[idx],
                        reviewImgError: true,
                    };
                    return newContainers;
                } else {
                    newContainers[idx] = {
                        ...newContainers[idx],
                        reviewImg: URL.createObjectURL(file),
                        reviewImgSt: true,
                        reviewImgError: false,
                        file: file
                    };
                    if (idx === newContainers.length - 1) {
                        addForm(newContainers);
                    }
                    return newContainers;
                }
            });
        }
    };

    const reviewImgInit = (idx) => () => {
        setReviewContainers((prev) => {
            const newContainers = [...prev];

            newContainers.splice(idx, 1);

            const lastContainer = newContainers[newContainers.length - 1];
            if (lastContainer && lastContainer.reviewImgSt) {
                newContainers.push({reviewImg: reviewUpload, reviewImgSt: false, reviewImgError: false});
            }

            return newContainers;
        });
        // formData.delete('reviewImg');
    };


    const addForm = (newContainers) => {
        const lastContainer = newContainers[newContainers.length - 1];
        if (!lastContainer.reviewImgSt) {
            return;
        }

        newContainers.push({reviewImg: reviewUpload, reviewImgSt: false, reviewImgError: false});
    };

    const validation = () => {
        if (titleText === '' || !titleText) setTitleErrorMsg(true);
        if (selectedCountry.value === '' || !selectedCountry) setCountryErrorMsg(true);
        if (selectedShipco === '' || !selectedShipco) setShipcoErrorMsg(true);
        if (reviewText === '' || !reviewText) setReviewErrorMsg(true);
        return !!(titleText && selectedCountry && selectedShipco && reviewText)
    }

    const reviewSave = () => {

        const newFormData = new FormData();
        const crEngNm = selectedCountry.label.match(/\(([^)]+)\)/);

        newFormData.append('title', titleText);
        newFormData.append('country', crEngNm[1]);
        newFormData.append('shipco', selectedShipco);
        newFormData.append('reviewText', reviewText);

        reviewContainers.forEach((container, idx) => {
            if (container.file) {
                newFormData.append('reviewImg', container.file);
            }
        });

        return axios({
            method: 'post',
            url: '/review/reviewSave',
            data: newFormData,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then(async (response) => {
                saveReviewAlarmData(response.data);
                navigate(`/UserReviewDetailPg/${response.data.seqNum}`);
            });
    }

    const saveReviewAlarmData = ((reviewData) => {
        axios({
            method: 'post',
            url: '/order/saveReviewData',
            headers: {
                Authorization: LCAT ? LCAT : SSAT,
                'Content-Type': 'application/json',
            },
            data: reviewData
        })
            .then(async () => {
                await stompClient.send("/app/reviewAlarm", {}, JSON.stringify({sendFl: true}));
            })
            .catch((error) => {
                console.log("알림데이터 저장실패 : ", error)
            })
    })


    const valiAndSave = () => {
        if (validation()) {
            reviewSave();
        }
    }

    return (
        <div className='m-a-user-review-write-pg-container'>
            <MHeader title={t(`rvWtHeader`)} url={"/UserReviewPg"}/>
            <div className='m-a-user-review-write-sub-title'>
                <img className='m-a-user-review-write-sub-title-icon' src={commonHeader} alt=''/>
                <div className='m-a-user-review-write-sub-title-text'>{t(`rvWtHeader`)}</div>
            </div>
            <div className='m-a-user-review-write-divider'/>

            <div className='m-a-user-review-write-input-frame col-2'>
                <div className='m-a-user-review-write-select-frame' ref={countryRef} onClick={toggleCountryShow}>
                    {selectedCountry.label}
                    <img className={'m-a-user-review-write-expand-icon'} src={Expand} alt={'Select'}/>
                    {countryShow && (
                        <div className={'m-a-user-review-write-select-expanded'}>
                            {countryList.map((country, index) => (
                                <div className={'m-a-user-review-write-select-item'}
                                     key={index} onClick={() => handleCountryChange(country)}>
                                    {country.label}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className='m-a-user-review-write-select-frame' ref={shipcoRef}
                     onClick={toggleShipcoShow}>
                    {selectedShipco === "" ? "이용배송사" : selectedShipco}
                    <img className={'m-a-user-review-write-expand-icon'} src={Expand} alt={'Select'}/>
                    {shipcoShow && (
                        <div className={'m-a-user-review-write-select-expanded right'}>
                            {shipcoList.map((shipco, index) => (
                                <div className={'m-a-user-review-write-select-item'}
                                     key={index} onClick={() => handleShipcoChange(shipco)}>
                                    {shipco}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            {countryErrorMsg &&
                <div className={'m-a-user-review-write-error'}>{t(`recCrErr`)}</div>
            }
            {shipcoErrorMsg &&
                <div className={'m-a-user-review-write-error'}>{t(`recNoSelectOsdvErr`)}</div>
            }

            <div className='m-a-user-review-write-input-frame'>
                <input className='m-a-user-review-write-input' placeholder={t(`rvWtTp`)}
                       value={titleText} maxLength={50} type="text"
                       onChange={handleChangeTitleText}/>
                {titleErrorMsg &&
                    <div className={'m-a-user-review-write-error'}>{t(`rvWtErr3`)}</div>
                }
                <textarea className='m-a-user-review-write-input content'
                          placeholder={t(`myCont`)} spellCheck="false"
                          value={reviewText} maxLength={10000}
                          onChange={handleChangeReviewText}/>
                {reviewErrorMsg &&
                    <div className={'m-a-user-review-write-error'}>{t(`rvWtErr1`)}</div>
                }
                {reviewContainers.map((container, index) => (
                    <div className={'m-a-user-review-img-container'} key={index}>
                        {container.reviewImgSt ? (
                            <div className='m-a-user-review-write-img-frame'>
                                <img className='m-a-user-review-write-uploaded-img'
                                     src={container.reviewImg} alt='reviewImg'/>
                                <div className='m-a-user-review-write-uploaded-img-info'>
                                    <div className='m-a-user-review-write-uploaded-img-delete'
                                         onClick={reviewImgInit(index)}>
                                        <img className='m-a-user-review-write-uploaded-img-delete-icon' src={Delete}
                                             alt='Delete'/>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='m-a-user-review-write-img-frame'
                                 onClick={(e) => {
                                     const parentDiv = e.target.parentNode;
                                     const fileInput = parentDiv.querySelector('input[type="file"]');
                                     if (fileInput) fileInput.click();
                                 }}>
                                <input type="file" accept="image/*" style={{display: "none"}}
                                       onChange={reviewImgHandle(index)}/>
                                <div className='m-a-user-review-write-upload'>
                                    <img className='m-a-user-review-write-upload-icon' src={Upload} alt=''/>
                                    <div className='m-a-user-review-write-upload-text'>Touch Me!</div>
                                </div>
                                {container.reviewImgError &&
                                    <div className={'m-a-user-review-write-error'}>{t(`rvWtErr2`)}😅</div>}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div className='m-a-user-review-write-button' onClick={valiAndSave}>
                {t(`rvWtBt`)}
            </div>
        </div>
    )
}

export default MUserReviewWritePg;
