/************************************************************************************************************** */
/************************************************************************************************************** */
/******************************* _____          _                               ******************************* */
/*******************************|  ___|        | |                              ******************************* */
/*******************************| |__  __ _ ___| |_ ___ _ __    ___  __ _  __ _ ******************************* */
/*******************************|  __|/ _` / __| __/ _ \ '__|  / _ \/ _` |/ _` |******************************* */
/*******************************| |__| (_| \__ \ ||  __/ |    |  __/ (_| | (_| |******************************* */
/*******************************\____/\__,_|___/\__\___|_|     \___|\__, |\__, |******************************* */
/*******************************                                     __/ | __/ |******************************* */
/*******************************                                    |___/ |___/ ******************************* */
/************************************************************************************************************** */
/************************************************************************************************************** */









































import React, {useEffect, useRef, useState} from 'react';
import {AiOutlineClose} from "react-icons/ai";

let array = [];
for (let i = 1; i <= 25; i++) {
    array.push(i);
}

function Timer() {
    const [timeElapsed, setTimeElapsed] = useState(0);
    const record = useRef();
    record.current = timeElapsed;
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeElapsed(timeElapsed => timeElapsed + 30);
        }, 30);
        return () => {
            alert("Your Score :" + record.current / 1000);
            clearInterval(timer);
        };
    }, []);

    return (
        <div className="ingame-box-score c-def">
            <div style={{
                display: "block",
                textAlign: "center"
            }}>{Math.floor(timeElapsed / 1000)} : {(timeElapsed % 1000) / 10}</div>
        </div>
    );
}

function Cell({num, handleClick}) {
    return (
        <div onClick={() => handleClick(num)} className="num-cell">
            <p>{num !== 0 ? num : null}</p>
        </div>
    )
}

function Board({numbers, handleClick}) {
    return (
        <div className="num-cellbox">
            {numbers.map((num, index) => (
                <Cell num={num} key={index} handleClick={handleClick}></Cell>
            ))}
        </div>
    )
}

function AdminVerManagementModal({open, close}) {
    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Escape') {
                event.preventDefault();
                close();
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);


    // region 시작

    const [numbers, setNumbers] = useState(array);
    const [gameFlag, setGameFlag] = useState(false);
    const [current, setCurrent] = useState(1);

    const handleClick = num => {
        if (num === current) {
            if (num === 50) {
                endGame();
            }
            const index = numbers.indexOf(num);
            setNumbers(numbers => [
                ...numbers.slice(0, index),
                num < 26 ? num + 25 : 0,
                ...numbers.slice(index + 1)
            ]);
            setCurrent(current => current + 1);
        }
    };

    const shuffleArray = array => {
        for (let i = array.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const startGame = () => {
        setNumbers(shuffleArray(array));
        setCurrent(1);
        setGameFlag(true);
    };
    const endGame = () => {
        setGameFlag(false);
    };

    // endregion

    return (
        <div className="AdminVerManagementModal sleep">
            <div className={open ? 'open-sleep sleep-modal' : 'sleep-modal'}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div className="sleep-header">
                        <AiOutlineClose onClick={close} className="close-x"/>
                    </div>
                    <div className="sleep-main">
                        <div className="ingame-box-score c-def">
                            {gameFlag ? (<Timer/>) : "00 : 00"}
                        </div>
                        <div className="ingame-box">
                            <Board numbers={numbers} handleClick={handleClick}></Board>
                        </div>
                        <div className="ingame-box-btn">
                            <button onClick={startGame} className="start-btn">S T A R T</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminVerManagementModal
