import React from "react";
import {Image} from "react-bootstrap";

import complete from '../../assets/img/complete.png'
import {useLocation, useNavigate} from "react-router-dom";
import withAuth from '../../auth/UserRouteAuth'
import {useTranslation} from "react-i18next";

function UserPayComplPg() {

    const { t } = useTranslation();
    const location = useLocation();
    const data = location.state.data;
    const navigate = useNavigate();

    const orderDetailPgMove = (ordNum) => () => {
        navigate(`/UserMyOrderPg/${ordNum}`);
    };

    return(
        <div className="font-t-cont" style={{height:"100vh", display: "flex", flexDirection: "column", justifyContent: "center", textAlign:"center"}}>
            <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
        <div style={{border:"solid", borderWidth:"thin 1px", borderColor:"lightgray", width:"500px"}}>
            <div style={{height:"200px", padding:"20px"}}>
                <div style={{height:"50%", alignItems:"flex-end", justifyContent:"center"}}>
                    <div style={{width:"100%", height:"100%", textAlign:"center"}}>
                    <Image style={{width:"50px", height:"50px", marginTop:"8%"}} src={complete}/>
                    </div>
                </div>

                <div style={{height:"50%", alignItems:"center", borderBottom:"solid", borderWidth:"thin 1px", borderColor:"lightgray"}}>
                    <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"flex-start", paddingTop:"20px"}}>
                        <p style={{margin:"0", fontSize:"18px"}}>{t(`pcCont`)}</p>
                    </div>
                </div>
            </div>
            <div style={{padding:"20px"}}>
                <div style={{height:"35px", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                    <p style={{fontSize:"14px", display:"flex", margin:"0"}}>{t(`modNum`)}</p>
                    <p style={{fontSize:"14px", color:"black", display:"flex", fontWeight:"bold", margin:"0"}}>{data && data?.orderNumber}</p>
                </div>
                {/*PM01이면 Paypal, PM04면 예치금결제, PM05면 포인트결제*/}
                <div style={{height:"35px",  display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                    <p style={{fontSize:"14px", display:"flex", margin:"0"}}>{t(`modPty`)}</p>
                    {data && data?.payWay === "PM01" ?
                    <p style={{fontSize:"14px", color:"dimgrey", display:"flex", margin:"0"}}>Paypal</p>
                        :
                        data?.payWay === "PM05" ?
                            <p style={{fontSize:"14px", color:"dimgrey", display:"flex", margin:"0"}}>{t(`modDep`)}</p>
                            :
                            data?.payWay === "PM04" ?
                                <p style={{fontSize:"14px", color:"dimgrey", display:"flex", margin:"0"}}>{t(`modPt`)}</p>
                                :
                        null
                    }
                </div>
                <div style={{height:"35px", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                    <p style={{fontSize:"14px", display:"flex", margin:"0"}}>{t(`pcWon`)}</p>
                    <p style={{fontSize:"14px", color:"dimgrey", display:"flex", margin:"0"}}>{data &&
                        data?.lastPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                </div>
                {/*추후 결제수단이 paypal일때만 보이게*/}
                {data && data?.payWay === "PM01" ?
                <div style={{height:"35px", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                    <p style={{fontSize:"14px", display:"flex", margin:"0"}}>{t(`pcFor`)}</p>
                    <div style={{display:"flex", alignItems:"center"}}>
                    <p style={{fontSize:"14px", color:"black", display:"flex", fontWeight:"bold", margin:"0"}}>{data && data?.lastForPrice}</p>
                        <div style={{height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <p style={{fontSize:"14px", margin:"0", marginLeft:"3px"}}>{data && data?.forCurrency}</p>
                        </div>
                    </div>
                </div>
                    :
                    null
                }
            </div>
        </div>
            <div style={{width:"500px",  height:"100px", textAlign:"center", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <button onClick={ ()=> navigate("/UserMyPg") } className="orderDeleteBt" style={{
                    width: "49%",
                    height: "50px",
                    textAlign: "center",
                    fontSize: "16px"
                }}>{t(`hdMypg`)}</button>
                <button onClick={orderDetailPgMove(data.orderNumber)}
                    className="colorFullBt" style={{
                    width: "49%",
                    height: "50px",
                    textAlign: "center",
                    fontSize: "16px",
                }}>{t(`modHeader`)}
                </button>
            </div>
            </div>
        </div>
    )
}

export default withAuth(UserPayComplPg)
