import React, {useEffect, useState} from 'react';
import '../../css/mobile/MAdmin.css';
import {MdKeyboardArrowDown} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import maCateImg from "../../assets/img/m-a-cate.png";
import MAdminPageSmallEvent from "./MAdminPageSmallEvent";
import MAdminYmdSmallEvent from "./MAdminYmdSmallEvent";

function MAdminReviewPg() {

    /** region DataTable */

    const [reviewList, setReviewList] = useState([]);

    /** endregion */

    /** region Function */

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [reviewList.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
        if (reviewList.length > displayLimit && displayLimit < 30) {
            setDisplayLimit(prevLimit => prevLimit + perPg);
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e);
        setperPg(newPerPage);
        setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    const [popupVisible, setPopupVisible] = useState(false);

    const togglePopup = () => {
        setPopupVisible(!popupVisible);
    };

    useEffect(() => {
        setDisplayLimit(perPg);
    }, [perPg]);

    // endregion

    const navigate = useNavigate();

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const startHandle = (e) => {setStartDate(e.target.value);};
    const endHandle = (e) => {setEndDate(e.target.value);};

    useEffect(() => {
        const today = new Date();
        const oneMonthAgo = new Date(today);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const formatDateString = (date) => {
            return date.toISOString().split('T')[0];
        };

        setStartDate(formatDateString(oneMonthAgo));
        setEndDate(formatDateString(today));
    }, []);

    /** endregion */

    /** region Event */

    const selectAllReviewList = (() => {
        const formattedStartDate = startDate.replace(/-/g, '');
        const formattedEndDate = endDate.replace(/-/g, '');
        axios({
            method: 'get',
            url: '/review/selectAllReviewBetDates',
            params: {
                startDate: formattedStartDate,
                endDate: formattedEndDate
            }
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.seqNum - a.seqNum);
                setReviewList(sortedData);
            })
            .catch((response) => {
                console.log("리뷰조회실패")
            })
    });

    useEffect(() => {
        if (startDate && endDate)
            selectAllReviewList();
    }, [startDate, endDate])

    const moveToDetail = ((seqNum) => {
        navigate(`/btcorpad/adminreview-detail/${seqNum}`);
    })

    /** endregion */

    return (
        <div id="m-a-review-pg-container">
            <div className="m-a-title-frame">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">이용후기</p>
            </div>
            <div className="m-a-card display-flex flex-column">
                <div className="m-a-event-frame">
                    <div className="m-a-header-event display-flex flex-row just-cont-sb">
                        <div className="m-a-header-event-combo">
                            <div className="m-a-combo font-t-cont font-c-default wd-55 ht-40 font-s-14 m-a-flex-jc-start m-r-10" onClick={togglePopup}>
                                <div className="font-t-cont">{perPg}</div>
                            </div>
                            {popupVisible && (
                                <MAdminPageSmallEvent perPg={perPg} handleSelectChange={handleSelectChange} togglePopup={togglePopup} />
                            )}
                            <MAdminYmdSmallEvent startDate={startDate} endDate={endDate} startHandle={startHandle} endHandle={endHandle}/>
                        </div>
                    </div>
                </div>
                <div className="m-a-tb-frame">
                    <div className="m-a-div-tb">
                        <div className="m-a-div-tb-head ht-60">
                            <div className="wd-40"><p>번호</p></div>
                            <div className="wd-150"><p>제목</p></div>
                            <div className="wd-200"><p>작성자</p></div>
                            <div className="wd-100"><p>일자</p></div>
                        </div>
                        {reviewList.slice(0, displayLimit).map((rv, i) => (
                            <div key={i} className="m-a-div-tb-body ht-70">
                                <div className="wd-40"><p>{i+1}</p></div>
                                <div className="wd-150 admin-tb-text-cut" onClick={()=> moveToDetail(rv.seqNum)}><p>{rv.title}</p></div>
                                <div className="wd-200"><p>{rv.memberDTO.userName}({rv.memberDTO.id})</p></div>
                                <div className="wd-100"><p>{`${rv.regDate.substring(0, 4)}-${rv.regDate.substring(4, 6)}-${rv.regDate.substring(6, 8)}`}</p></div>
                            </div>
                        ))}
                    </div>
                    {displayLimit >= 30 && reviewList.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default MAdminReviewPg
