import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import MsgSelectModal from "../web/modal/MsgSelectModal";
import {connect} from "../../services/WebsocketService";
import useTheme from "../web/layoutscommon/DarkModeStorage";
import '../../css/mobile/MAdmin.css';
import '../../css/Common.css';
import back from "../../assets/img/commonBack.png";
import {useNavigate} from "react-router-dom";
import orderIMG from "../../assets/img/mypgPayinfo.png";
import {useWebSocket} from "../../services/WebsocketContext";

function MAdminMergeModal() {

    const LCAT = localStorage.getItem('adminToken');

    const [adminInfo, setAdminInfo] = useState([]);
    const selectAdminInfo = (() => {
        axios({
            method: 'get',
            url: '/member/modiInfo',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data[0])
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    //region 다크모드

    const [theme] = useTheme();

    //endregion

    useEffect(() => {
        selectAdminInfo();
    }, [])

    const stompClientRef = useRef(null);
    const { subscribe, unsubscribe, websocketConnect, isConnected, stompClient } = useWebSocket();
    useEffect(() => {
        if (!adminInfo && !adminInfo.mbNum) return;
        const initializeSubscription = async () => {
            if (!isConnected || !stompClient) {
                stompClientRef.current = await websocketConnect(adminInfo.mbNum);
            } else {
                stompClientRef.current = stompClient;
            }
        };
        initializeSubscription();
        return () => {
            if (stompClientRef.current && stompClientRef.current.length > 0) {
                // 구독이 존재하는 경우에만 해제
                stompClientRef.current.forEach((sub) => {
                    unsubscribe(sub);
                });
            }
            stompClientRef.current = null;
        };
    }, [adminInfo, isConnected, subscribe, unsubscribe, stompClient]);


    const cData = JSON.parse(localStorage.getItem('orderData'));
    const closeMergeModal = (() => {
        window.close();
    })

    // 기준주문서 상품총수량
    const totalQuantity = cData.productsDTO.reduce((sum, item) => {
        if (item.prodSt !== "IS12") {
            return sum + item.quantity;
        }
        return sum;
    }, 0);
    // 통합주문서 상품총수량
    const listTotalQuantity = (prod) => {
        return prod.reduce((sum, item) => {
            if (item.prodSt !== "IS12") {
                return sum + item.quantity;
            }
            return sum;
        }, 0);
    }


    const [orderdtList, setOrderdtList] = useState([])
    const [filteredOrderdtList, setFilteredOrderdtList] = useState([]);
    const [mergeOrderList, setMergeOrderList] = useState([]);


    const selectMergeList = (() => {
        axios({
            method: 'get',
            url: '/order/selectMergeList',
            params: {
                mbNum: cData.memberDTO.mbNum
            }
        })
            .then((response) => {
                let sortedOrderList = response.data.sort((a, b) => {
                    let dateA = new Date(a.modiTime);
                    let dateB = new Date(b.modiTime);
                    return dateB - dateA;
                });
                setOrderdtList(sortedOrderList);
            })
            .catch(() => {})
    })


    useEffect(() => {
        const filteredData = orderdtList.filter(order => order.orderNumber !== cData.orderNumber
            && order.recinfoDTO.countryDTO.countryNm === cData.recinfoDTO.countryDTO.countryNm
            && order.orderStatus !== "OS12" && order.orderStatus !== "OS14")
        setFilteredOrderdtList(filteredData);
    }, [orderdtList]);

    useEffect(()=> {
        if (cData) {
            selectMergeList();
        }
    }, [])

    const [isAllChecked, setIsAllChecked] = useState(false);

    const handleAllCheck = (event) => {
        if (event.target.checked) {
            const allSeqNums = filteredOrderdtList.map(order => order.seqnum);
            setMergeOrderList(allSeqNums);
        } else {
            setMergeOrderList([]);
        }
        setIsAllChecked(event.target.checked);
    };

    const toggleOrderSelection = (seqnum) => {
        setMergeOrderList(prev => {
            const newOrderList = prev.includes(seqnum) ? prev.filter(num => num !== seqnum) : [...prev, seqnum];
            setIsAllChecked(newOrderList.length === filteredOrderdtList.length);
            return newOrderList;
        });
    };

    const orderMerge = (() => {
        let mergeProdQueryString = mergeOrderList.map(item => 'mergeOrder=' + encodeURIComponent(item)).join('&');

        axios({
            method: 'post',
            url: `/order/orderMerge?standardOrder=${encodeURIComponent(cData.seqnum)}&${mergeProdQueryString}`,
            headers: {
                Authorization: LCAT
            },
        })
            .then(async () => {
                // 웹소켓 전송하여 사용자 마이페이지도 order 업뎃 ㄱ
                await stompClient.send("/app/orderDivideAndMerge", {}, JSON.stringify({userName:orderdtList[0].memberDTO.userName}));
                // 추후 여기에 AdminDashBoardPg.selectAllData() 조회함수 실행코드 추가

                closeMergeModal();
            });
    });

    const [integModalSt, setIntegModalSt] = useState(false);
    const openIntegModalShow = (() => {
        if (mergeOrderList.length > 0) {
            setIntegModalSt(true);
        }
    })
    const closeIntegModalShow = (() => {
        setIntegModalSt(false);
    })
    const integModalMsg1 = "통합을 진행하시겠습니까?"
    const integModalMsg2 = "진행 후 되돌릴 수 없습니다"

    const orderStPrint = (orderSt) => {
        const orderStStyles = {
            OS00: { color: "#98B0D4", label: "입고대기" },
            OS01: { color: "#98B0D4", label: "결제대기(상품)" },
            OS02: { color: "#98B0D4", label: "결제중(상품)" },
            OS03: { color: "#FFB378", label: "결제완료(상품)" },
            OS04: { color: "#98B0D4", label: "구매중" },
            OS05: { color: "#FFB378", label: "구매완료" },
            OS06: { color: "#98B0D4", label: "입고중" },
            OS07: { color: "#6EDA60", label: "센터도착" },
            OS08: { color: "#98B0D4", label: "패킹중" },
            OS09: { color: "#98B0D4", label: "결제대기(배송)" },
            OS10: { color: "#98B0D4", label: "결제중(배송)" },
            OS11: { color: "#FFB378", label: "결제완료(배송)" },
            OS12: { color: "#979696", label: "출고완료" },
            OS13: { color: "#FF8F62", label: "반송중" },
            OS14: { color: "#FF8F62", label: "신청취소" },
        };

        const style = orderStStyles[orderSt] || { color: "black", label: null };
        return <p style={{ margin: "0", color: style.color, fontSize: "12px"}}>{style.label}</p>;
    };

    const navigate = useNavigate();
    const pageBack = (() => {
        navigate(-1);
    })

    return (
        <div id="m-a-merge-modal-container" data-theme={theme}>
            <div className="m-a-common-header">
                <img onClick={pageBack} className="m-a-backIcon" src={back} alt="back"/>
                <div className="m-a-pg-header-text font-t-head">주문서 통합</div>
            </div>
            <div className="m-a-fixed-container">
                <div className="m-t-15">
                    <div className="m-a-card">
                        <div className="display-flex flex-column just-cont-center dark-font font-s-14">
                            <p className="font-b font-s-17"><img className="wd-17 ht-17 m-b-4 m-r-3" alt="" src={orderIMG}/>{cData.orderNumber}</p>
                            <div className="display-flex flex-row">
                                <p className="wd-35pc">고객명:</p>
                                <p className="wd-65pc">{cData.memberDTO.userName}</p>
                            </div>
                            <div className="display-flex flex-row">
                                <p className=" wd-35pc">수취인명:</p>
                                <p className="font-b wd-65pc">{cData.recinfoDTO.recname}</p>
                            </div>
                            <div className="display-flex flex-row font-s-14">
                                <p className="wd-35pc">국가:</p>
                                <p className="wd-65pc">{cData.recinfoDTO.countryDTO.countryNm + "[" + cData.recinfoDTO.countryDTO.countryCode + "]"}</p>
                            </div>
                            {/*<div className="display-flex flex-row font-s-14">*/}
                            {/*    <p className="wd-35pc">총수량:</p>*/}
                            {/*    <p className="wd-65pc">{totalQuantity}</p>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="m-a-card m-t-15">
                        <div className="m-a-tb-frame-b">
                            <div className="m-a-div-tb">
                                <div className="m-a-div-tb-head-b ht-60 font-s-12">
                                    <div className="wd-10pc">
                                        <input className="m-a-admin-input-chkBox m-0-a" type="checkbox" checked={isAllChecked} onChange={handleAllCheck}/>
                                    </div>
                                    <div className="wd-45pc">
                                        <p>주문번호</p>
                                        <p>국가</p>
                                    </div>
                                    <div className="wd-45pc">
                                        <p>고객명</p>
                                        <p>수취인명</p>
                                        <p>상태구분</p>
                                    </div>
                                    {/*<div className="wd-10pc"><p>수량</p></div>*/}
                                </div>
                                {filteredOrderdtList && filteredOrderdtList.map((order, i) => (
                                    <div key={i} className="m-a-div-tb-body-b ht-70">
                                        <div className="wd-10pc">
                                            <input className="m-a-admin-input-chkBox m-0-a" onChange={() => toggleOrderSelection(order.seqnum)} type="checkbox"
                                                   checked={mergeOrderList.includes(order.seqnum)}/>
                                        </div>
                                        <div className="wd-45pc">
                                            <p className="font-b font-s-12">{order.orderNumber}</p>
                                            <p className="font-b font-s-12">{order.recinfoDTO.countryDTO.countryNm + "[" + cData.recinfoDTO.countryDTO.countryCode + "]"}</p>
                                        </div>
                                        <div className="wd-45pc font-s-12">
                                            <p className="font-b">{order.memberDTO.userName}</p>
                                            <p className="font-b">{order.recinfoDTO.recname}</p>
                                            <p className="font-b">{orderStPrint(order.orderStatus)}</p>
                                        </div>
                                        {/*<div className="wd-10pc font-s-12"><p>{listTotalQuantity(order && order.productsDTO)}</p></div>*/}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="m-a-m-fixed-bt-frame">
                <button className="m-a-colorBt-radius-n m-a-colorBt-text wd-100pc ht-50" onClick={openIntegModalShow}>확인</button>
            </div>
            { integModalSt ?
                <MsgSelectModal integModalSt={integModalSt} orderMerge={orderMerge} closeIntegModalShow={closeIntegModalShow}
                                integModalMsg1={integModalMsg1} integModalMsg2={integModalMsg2}/>
                :
                null
            }
        </div>
    );
}

export default MAdminMergeModal
