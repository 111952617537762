import React, {createContext, useContext, useState} from 'react';

const BoxSaveContext = createContext();

export const useBoxSave = () => useContext(BoxSaveContext);

export const BoxSaveProvider = ({ children }) => {
    const [tempSaves, setTempSaves] = useState([]);

    const saveTempData = (data) => {
        setTempSaves(prevData => [...prevData, data]);
    };

    const value = {
        tempSaves,
        saveTempData,
    };

    return <BoxSaveContext.Provider value={value}>{children}</BoxSaveContext.Provider>;
};
