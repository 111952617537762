import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const isAdminPg = window.location.pathname.startsWith('/btcorpad');

i18n
    .use(HttpBackend) // 서버에서 언어파일 로드
    .use(LanguageDetector) // 브라우저 언어감지
    .use(initReactI18next) // react-i18next와 i18next를 연결
    .init({
        backend: {
            loadPath: isAdminPg ? '/locales/ko/translation.json' : '/locales/{{lng}}/{{ns}}.json',
        },
        fallbackLng: 'ko', // 기본언어
        debug: false,
        interpolation: {
            escapeValue: false, // React에서는 기본적으로 안전함
        },
    });

export default i18n;
