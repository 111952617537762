import React, {useEffect, useState} from "react";
import {AiOutlineClose, AiOutlineDoubleRight} from "react-icons/ai";
import axios from "axios";
import {useTranslation} from "react-i18next";
import commonClose from "../../../assets/img/commonClose.png";

function EtcPriceDetail({closeDetail, paySeqnum}) {
    const { t } = useTranslation();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    const [payList, setPayList] = useState([{}]);


    const selectPayinfoDetail = () => axios({
        method: 'get',
        url: '/order/selectTotalPayInfo/',
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
        params: {
            seqNum: paySeqnum
        },
    })
        .then((response) => {
            setPayList(response.data)
        })
    useEffect(() => {
        const timer = setTimeout(() => {
            selectPayinfoDetail();
        })
        return () => {
            clearTimeout(timer);
        }
    }, [])


    return (
        <div className="bookContainer" style={{display: "flex", zIndex:"10"}}>


            <div className="bookFrame" style={{
                width: "600px",
                height: "700px",
                top: "calc(25vh - 120px)",
                left: "calc(50vw - 260px)",
                borderRadius: "5px"
            }}>

                <div style={{width: "100%", height: "100%"}}>
                    {/*상세헤더*/}
                    <div style={{height: "6%"}}>
                        <div style={{
                            height: "100%",
                            fontSize: "23px",
                            border: "solid",
                            borderWidth: "thin 1px",
                            color: "lightgray",
                            display: "flex"
                        }}>

                            <div style={{width: "92%", textAlign: "center", marginLeft: "55px"}}>
                                <p style={{
                                    color: "dimgrey",
                                    fontSize: "16px",
                                    width: "100%",
                                    marginTop: "7px"
                                }}>{t(`epdHeader1`)}</p>
                            </div>
                            <div onClick={closeDetail} style={{width: "8%", textAlign: "center", cursor:"pointer"}}>
                                <img style={{width:"30px", height:"30px", opacity:"0.6"}} src={commonClose}/>
                            </div>

                        </div>
                    </div>

                    {/*상세바디*/}
                    <div style={{height: "94%", overflowY: "scroll"}}>

                        <div style={{
                            border: "solid",
                            margin: "60px 50px 40px 50px",
                            borderWidth: "thin 1px",
                            borderColor: "lightgray",
                            borderRadius: "6px"
                        }}>
                            <div style={{
                                borderBottom: "solid",
                                borderWidth: "thin 1px",
                                borderColor: "lightgray",
                                width: "100%",
                                height: "40px",
                                display: "flex",
                                backgroundColor: "rgba(198,193,193,0.22)"
                            }}>
                                <span style={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0 4px 0 10px",
                                    fontSize: "14px"
                                }}><AiOutlineDoubleRight/></span>
                                <span style={{
                                    height: "100%",
                                    fontSize: "13px",
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: "bold"
                                }}>{t(`epdHeader2`)}</span>
                            </div>
                            <div style={{margin: "10px", color: "dimgrey"}}>


                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    wordBreak: "break-all",
                                    marginTop: "10px",
                                    fontSize: "13px",
                                    marginBottom: "6px"
                                }}><span>{t(`epdHeader3`)}</span><span>{payList[0]?.courierPay?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    wordBreak: "break-all",
                                    fontSize: "13px",
                                    marginBottom: "6px"
                                }}><span>{t(`epdHeader4`)}</span><span>{payList[0]?.returnFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    wordBreak: "break-all",
                                    fontSize: "13px",
                                    borderBottom:"solid",
                                    borderWidth:"thin 1px",
                                    borderColor:"#EEEEEE",
                                    height:"35px"
                                }}>
                                    <span>{t(`epdHeader5`)}({payList[0]?.etcPriceNm || ''})</span><span>{payList[0]?.etcPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    wordBreak: "break-all",
                                    fontSize: "13px",
                                    margin: "15px 0 6px 0"
                                }}><span>{t(`epdHeader6`)}</span><span>{payList[0]?.repackFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    wordBreak: "break-all",
                                    fontSize: "13px",
                                    marginBottom: "6px"
                                }}><span>{t(`epdHeader7`)}</span><span>{payList[0]?.comprFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    wordBreak: "break-all",
                                    fontSize: "13px",
                                    marginBottom: "6px"
                                }}><span>{t(`epdHeader8`)}</span><span>{payList[0]?.agencyFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    wordBreak: "break-all",
                                    fontSize: "13px",
                                    marginBottom: "6px"
                                }}>
                                    <span>{t(`epdHeader9`)}({payList[0]?.etcFeeNm || ''})</span><span>{payList[0]?.etcFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                </div>


                            </div>
                        </div>


                        <div style={{margin: "60px 50px 0 50px", color: "dimgrey"}}>
                            <div style={{
                                height: "40px",
                                borderBottom: "solid",
                                borderWidth: "thin 1px",
                                borderColor: "lightgray",
                                display: "flex",
                                marginBottom: "5px"
                            }}><p style={{fontSize: "16px", height: "100%", paddingTop: "8px"}}>{t(`modHeader10`)}</p></div>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                wordBreak: "break-all",
                                padding: "5px",
                                fontSize: "13px"
                            }}><span>{t(`modSHeader9`)}</span>
                                <span>{(payList[0]?.ttEtcFee + payList[0]?.ttEtcPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                wordBreak: "break-all",
                                padding: "5px",
                                fontSize: "13px",
                                marginTop: "6px"
                            }}><span>{t(`myUseDep`)}</span><span>{payList[0]?.depUsePrice !== 0 ?
                                <span>- {payList[0]?.depUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : 0}</span></div>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                wordBreak: "break-all",
                                padding: "5px",
                                fontSize: "13px"
                            }}><span>{t(`myUsePt`)}</span><span>{payList[0]?.pointUsePrice !== 0 ?
                                <span>- {payList[0]?.pointUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : 0}</span></div>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                wordBreak: "break-all",
                                padding: "5px",
                                fontSize: "13px"
                            }}><span>{t(`modSHeader13`)}({payList[0].couponNm})</span><span>{payList[0]?.couponUsePrice !== 0 ?
                                <span>- {payList[0]?.couponUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : 0}</span>
                            </div>

                            <div style={{
                                height: "50px",
                                borderBottom: "solid",
                                borderWidth: "thin 1px",
                                borderColor: "lightgray",
                                display: "flex",
                                alignItems: "end",
                                justifyContent: "space-between",
                                wordBreak: "break-all",
                                padding: "5px",
                                marginBottom: "50px"
                            }}><span style={{fontSize: "16px", fontWeight: "bold"}}>{t(`modFinalPay`)}</span>

                                <span style={{
                                color: "#FFAF34",
                                fontWeight: "bold"
                            }}>{payList[0]?.payStatus === "PS0" ? 0 : payList[0]?.lastPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </span>

                            </div>

                        </div>


                    </div>


                </div>


            </div>


        </div>
    )
}

export default EtcPriceDetail
