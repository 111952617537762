import React, {useEffect, useState} from "react";
import {AiOutlineClose, AiOutlineDoubleRight} from "react-icons/ai";
import axios from "axios";
import commonClose from '../../../assets/img/commonClose.png'
import {useTranslation} from "react-i18next";

function IntegDetailPopup({integDetailPopupClose, integPaySeqnum}) {

    // const SSAT = sessionStorage.getItem('AToken');
    // const LCAT = localStorage.getItem('AToken');
    const { t } = useTranslation();
    const [prodPay, setProdPay] = useState([]);
    const [shipPay, setShipPay] = useState([]);
    const [etcPay, setEtcPay] = useState([]);

    const [useDepPrice, setUseDepPrice] = useState(0);
    const [usePointPrice, setUsePointPrice] = useState(0);
    const [useCpPrice, setUseCpPrice] = useState(0);
    const [integLastPrice, setIntegLastPrice] = useState([])

    const selectPayinfoDetail = () => axios({
        method: 'get',
        url: '/order/selectIntegPayHistory/',
        params: {
            paySeqnum: integPaySeqnum
        },
    })
        .then((response) => {
            let prodPayArr = [];
            let shipPayArr = [];
            let etcPayArr = [];

            response.data.forEach(item => {
                switch(item.payType) {
                    case "PT0":
                        prodPayArr.push(item);
                        break;
                    case "PT1":
                        shipPayArr.push(item);
                        break;
                    case "PT2":
                        etcPayArr.push(item);
                        break;
                    case "PT3":
                        setUseDepPrice(item.depUsePrice);
                        setUsePointPrice(item.pointUsePrice);
                        setUseCpPrice(item.couponUsePrice);
                        setIntegLastPrice(item.lastPrice);
                        break;
                }
            });

            setProdPay(prodPayArr);
            setShipPay(shipPayArr);
            setEtcPay(etcPayArr);
        })

    useEffect(() => {
        if (integPaySeqnum) {
            selectPayinfoDetail();
        }
    }, [integPaySeqnum])

    return (
        <div className="bookContainer" style={{display: "flex", zIndex:"10"}}>


            <div className="bookFrame" style={{
                width: "600px",
                height: "700px",
                top: "calc(25vh - 120px)",
                left: "calc(50vw - 260px)",
                borderRadius: "5px"
            }}>

                <div style={{width: "100%", height: "100%"}}>
                    {/*상세헤더*/}
                    <div style={{height: "6%"}}>
                        <div style={{
                            height: "100%",
                            fontSize: "23px",
                            border: "solid",
                            borderWidth: "thin 1px",
                            color: "lightgray",
                            display: "flex"
                        }}>

                            <div style={{width: "92%", textAlign: "center", marginLeft: "55px"}}>
                                <p style={{
                                    color: "dimgrey",
                                    fontSize: "16px",
                                    width: "100%",
                                    marginTop: "7px"
                                }}>{t(`idpHeader`)}</p>
                            </div>
                            <div onClick={integDetailPopupClose} style={{width: "8%", textAlign: "center", cursor:"pointer"}}>
                                <img style={{width:"30px", height:"30px", opacity:"0.6"}} src={commonClose}/>
                            </div>

                        </div>
                    </div>

                    {/*상세바디*/}
                    <div style={{height: "94%", overflowY: "scroll"}}>


                        {prodPay.length > 0 ?
                            <div style={{
                                border: "solid",
                                margin: "30px",
                                borderWidth: "thin 1px",
                                borderColor: "lightgray",
                            }}>

                                <div style={{
                                    borderBottom: "solid",
                                    borderWidth: "thin 1px",
                                    borderColor: "lightgray",
                                    width: "100%",
                                    height: "40px",
                                    display: "flex",
                                    backgroundColor: "#FAFAFA"
                                }}>
                                    <div style={{
                                        width: "10%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <p style={{margin: "0", fontSize: "12px"}}>{t(`num`)}</p>
                                    </div>
                                    <div style={{
                                        width: "20%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <p style={{margin: "0", fontSize: "12px"}}>{t(`prodPr`)}</p>
                                    </div>
                                    <div style={{
                                        width: "20%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <p style={{margin: "0", fontSize: "12px"}}>{t(`prodMallPr`)}</p>
                                    </div>
                                    <div style={{
                                        width: "20%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <p style={{margin: "0", fontSize: "12px"}}>{t(`prodFee`)}</p>
                                    </div>
                                    <div style={{
                                        width: "30%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <p style={{margin: "0", fontSize: "12px"}}>{t(`ttPr`)}</p>
                                    </div>
                                </div>

                                <div style={{padding: "5px 0 5px 0"}}>

                                    {prodPay.map((prod, i) => (
                                        <div key={i} style={{
                                            height: "40px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <div style={{
                                                width: "10%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "12px",
                                                    fontWeight: "lighter",
                                                    color: "black"
                                                }}>{i + 1}</p>
                                            </div>
                                            <div style={{
                                                width: "20%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "12px",
                                                    fontWeight: "lighter",
                                                    color: "black"
                                                }}>{prod?.ttProdPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                                            </div>
                                            <div style={{
                                                width: "20%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "12px",
                                                    fontWeight: "lighter",
                                                    color: "black"
                                                }}>{prod?.ttMallShipPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                                            </div>
                                            <div style={{
                                                width: "20%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "12px",
                                                    fontWeight: "lighter",
                                                    color: "black"
                                                }}>{prod?.ttEtcFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                                            </div>
                                            <div style={{
                                                width: "30%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "12px",
                                                    fontWeight: "lighter",
                                                    color: "black"
                                                }}>{(prod.ttProdExpense + prod.ttEtcFee).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                            :
                            null
                        }


                        {shipPay.length > 0 ?
                            <div style={{
                                border: "solid",
                                margin: "30px",
                                borderWidth: "thin 1px",
                                borderColor: "lightgray",
                            }}>

                                <div style={{
                                    borderBottom: "solid",
                                    borderWidth: "thin 1px",
                                    borderColor: "lightgray",
                                    width: "100%",
                                    height: "40px",
                                    display: "flex",
                                    backgroundColor: "#FAFAFA"
                                }}>
                                    <div style={{
                                        width: "10%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <p style={{margin: "0", fontSize: "12px"}}>{t(`num`)}</p>
                                    </div>

                                    <div style={{
                                        width: "25%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <p style={{margin: "0", fontSize: "12px"}}>{t(`dv`)}</p>
                                    </div>

                                    <div style={{
                                        width: "30%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <p style={{margin: "0", fontSize: "12px"}}>{t(`appWt`)}</p>
                                    </div>

                                    <div style={{
                                        width: "35%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <p style={{margin: "0", fontSize: "12px"}}>{t(`dvPr`)}</p>
                                    </div>

                                </div>

                                <div style={{padding: "5px 0 5px 0"}}>

                                    {shipPay.map((ship, i) => (
                                        <div key={i} style={{
                                            height: "40px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <div style={{
                                                width: "10%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "12px",
                                                    fontWeight: "lighter",
                                                    color: "black"
                                                }}>{i + 1}</p>
                                            </div>

                                            <div style={{
                                                width: "25%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "12px",
                                                    fontWeight: "lighter",
                                                    color: "black"
                                                }}>
                                                    {ship.ttFedexPrice > 0 ? "FedEx" :
                                                        ship.ttUpsPrice > 0 ? "UPS" :
                                                            ship.ttDhlPrice > 0 ? "DHL" :
                                                                ship.ttEmsPrice > 0 ? "EMS" :
                                                                    ship.ttKPackPrice > 0 ? "K-Packet" :
                                                                        ship.ttAirSPrice > 0 ? "Small-Packet" :
                                                                            ship.ttSeaPrice > 0 ? "Sea Parcel" :
                                                                                ship.ttDomPrice > 0 ? "Domestic Delivery"
                                                                        :
                                                                        null}
                                                </p>
                                            </div>

                                            <div style={{
                                                width: "30%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "12px",
                                                    fontWeight: "lighter",
                                                    color: "black"
                                                }}>
                                                    {ship.ttFedexPrice > 0 ? ship.shipInfoDTO.fedexApyWeight :
                                                        ship.ttDhlPrice > 0 ? ship.shipInfoDTO.dhlApyWeight :
                                                            ship.ttEmsPrice > 0 ? ship.shipInfoDTO.emsApyWeight :
                                                                ship.ttUpsPrice > 0 ? ship.shipInfoDTO.upsApyWeight :
                                                                    ship.ttKPackPrice > 0 ? ship.shipInfoDTO.kPackApyWeight :
                                                                        ship.ttAirSPrice > 0 ? ship.shipInfoDTO.airSApyWeight :
                                                                            ship.ttSeaPrice > 0 ? ship.shipInfoDTO.seaApyWeight :
                                                                                ship.ttDomPrice > 0 ? ship.shipInfoDTO.weight
                                                                :
                                                                0}kg
                                                </p>
                                            </div>

                                            <div style={{
                                                width: "35%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "12px",
                                                    fontWeight: "lighter",
                                                    color: "black"
                                                }}>
                                                    {ship.ttFedexPrice > 0 ? ship.ttFedexPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                                        ship.ttDhlPrice > 0 ? ship.ttDhlPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                                            ship.ttEmsPrice > 0 ? ship.ttEmsPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                                                ship.ttUpsPrice > 0 ? ship.ttUpsPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                                                    ship.ttKPackPrice > 0 ? ship.ttKPackPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                                                        ship.ttAirSPrice > 0 ? ship.ttAirSPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                                                            ship.ttSeaPrice > 0 ? ship.ttSeaPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                                                                ship.ttDomPrice > 0 ? ship.ttDomPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                                                0}
                                                </p>
                                                {ship.payStatus === "PS5" ? <p style={{margin:"0", fontSize:"12px", color:"#EB939D", marginLeft:"3px"}}> (취소)</p> : null}
                                            </div>

                                        </div>
                                    ))}

                                </div>
                            </div>
                            :
                            null
                        }


                        {etcPay.length > 0 ?
                            <div style={{
                                border: "solid",
                                margin: "30px",
                                borderWidth: "thin 1px",
                                borderColor: "lightgray",
                            }}>

                                <div style={{
                                    borderBottom: "solid",
                                    borderWidth: "thin 1px",
                                    borderColor: "lightgray",
                                    width: "100%",
                                    height: "40px",
                                    display: "flex",
                                    backgroundColor: "#FAFAFA"
                                }}>
                                    <div style={{
                                        width: "10%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <p style={{margin: "0", fontSize: "12px"}}>{t(`num`)}</p>
                                    </div>
                                    <div style={{
                                        width: "18%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <p style={{margin: "0", fontSize: "12px"}}>{t(`epdHeader6`)}</p>
                                    </div>
                                    <div style={{
                                        width: "18%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <p style={{margin: "0", fontSize: "12px"}}>{t(`epdHeader8`)}</p>
                                    </div>
                                    <div style={{
                                        width: "18%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <p style={{margin: "0", fontSize: "12px"}}>{t(`epdHeader7`)}</p>
                                    </div>
                                    <div style={{
                                        width: "18%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <p style={{margin: "0", fontSize: "12px"}}>{t(`epdHeader5`)}</p>
                                    </div>
                                    <div style={{
                                        width: "18%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <p style={{margin: "0", fontSize: "12px"}}>{t(`epdHeader9`)}</p>
                                    </div>
                                </div>

                                <div style={{padding: "5px 0 5px 0"}}>

                                    {etcPay.map((etc, i) => (
                                        <div key={i} style={{
                                            height: "40px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <div style={{
                                                width: "10%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "12px",
                                                    fontWeight: "lighter",
                                                    color: "black"
                                                }}>{i + 1}</p>
                                            </div>
                                            <div style={{
                                                width: "18%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "12px",
                                                    fontWeight: "lighter",
                                                    color: "black"
                                                }}>{etc?.repackFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                                            </div>
                                            <div style={{
                                                width: "18%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "12px",
                                                    fontWeight: "lighter",
                                                    color: "black"
                                                }}>{etc?.agencyFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                                            </div>
                                            <div style={{
                                                width: "18%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "12px",
                                                    fontWeight: "lighter",
                                                    color: "black"
                                                }}>{etc?.comprFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                                            </div>
                                            <div style={{
                                                width: "18%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "12px",
                                                    fontWeight: "lighter",
                                                    color: "black"
                                                }}>{etc?.etcPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                                            </div>
                                            <div style={{
                                                width: "18%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "12px",
                                                    fontWeight: "lighter",
                                                    color: "black"
                                                }}>{etc?.etcFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                            :
                            null
                        }

                        {/*추후 통합결제 건의 lastPrice 맵핑*/}
                        <div style={{padding:"30px"}}>
                        <div style={{borderTop:"1px solid lightgray", borderBottom:"1px solid lightgray", borderWidth:"thin 1px", padding:"10px 0"}}>

                            <div style={{height:"80px"}}>
                                <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                    <p style={{margin:"0", fontSize:"13px", height:"25px", fontWeight:"lighter"}}>{t('myUseDep')}</p>
                                    <p style={{margin:"0", fontSize:"13px", height:"25px", fontWeight:"lighter"}}>
                                        { useDepPrice > 0 ? <span>-</span> : null}
                                        {useDepPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </p>
                                </div>
                                <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                    <p style={{margin:"0", fontSize:"13px", height:"25px", fontWeight:"lighter"}}>{t('myUsePt')}</p>
                                    <p style={{margin:"0", fontSize:"13px", height:"25px", fontWeight:"lighter"}}>
                                        { usePointPrice > 0 ? <span>-</span> : null}
                                        {usePointPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </p>
                                </div>
                                <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                    <p style={{margin:"0", fontSize:"13px", height:"25px", fontWeight:"lighter"}}>{t('myUseCp')}</p>
                                    <p style={{margin:"0", fontSize:"13px", height:"25px", fontWeight:"lighter"}}>
                                        { useCpPrice > 0 ? <span>-</span> : null}
                                        {useCpPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </p>
                                </div>
                            </div>

                            <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                            <div style={{display:"flex", alignItems:"center"}}>
                                <p style={{margin:"0", fontSize:"15px"}}>{t(`idpItgPr`)}</p>
                            </div>
                            <div style={{display:"flex", alignItems:"center"}}>
                                <p style={{margin:"0", fontSize:"15px", color: integLastPrice > 0 ? "darkorange" : "dimgray"}}>
                                    {integLastPrice > 0 ? integLastPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0"}
                                </p>
                            </div>
                            </div>

                        </div>
                        </div>








                    </div>


                </div>


            </div>


        </div>
    )
}

export default IntegDetailPopup
