import React, {useEffect, useState} from 'react';
import {BsSquareFill} from "react-icons/bs";
import axios from "axios";
import AdminYmdEvent from "../../components/web/layoutsadmin/AdminYmdEvent";
import maCateImg from "../../assets/img/m-a-cate.png";
import ApexCharts from "react-apexcharts";

function AdminSalesStatusPg() {

    /** region DataTable */

    const [salesData, setSalesData] = useState([]);
    const [salesChartData, setSalesChartData] = useState([]);

    /** endregion */

    /** region Function */

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const startHandle = (e) => {
        setStartDate(e.target.value);
    };
    const endHandle = (e) => {
        setEndDate(e.target.value);
    };

    const purchaseSeries = salesChartData.purchaseData || [];
    const deliverySeries = salesChartData.deliveryData || [];

    console.log("salesChartData.deliveryData : ", salesChartData.deliveryData)

    const stateProd = {
        series: [{
            name: "구매",
            data: purchaseSeries
        }],
        options: {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                }
            },
            colors: ['#FF9924'],
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    borderRadiusApplication: 'end',
                    horizontal: true,
                    barHeight: '70%',
                }
            },
            dataLabels: {
                enabled: true,
                style: {
                    colors: ['#fff'],
                    fontSize: '12px',
                    fontFamily: 'Pretendard-Regular, sans-serif'
                },
                formatter: function (value) {
                    return "\u20A9 " + value.toLocaleString();
                }
            },
            xaxis: {
                categories: ['장바구니', '구매대행', '오프라인', '이체대행', '국내배송', '기타비용', '총수수료'],
                labels: {
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Pretendard-Regular, sans-serif',
                        colors: '#333333',
                    }
                },
                title: {
                    text: '단위(\u20A9)',
                    style: {
                        color: '#bebebe',
                        fontSize: '11px',
                        fontFamily: 'Pretendard-Regular, sans-serif'
                    }
                }
            },
            tooltip: {
                enabled: true,
                theme: 'dark',
                y: {
                    formatter: function (value) {
                        return "\u20A9 " + value.toLocaleString();
                    }
                }
            },
            title: {
                text: '구매',
                align: 'left',
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#333333',
                    fontFamily: 'Pretendard-Regular, sans-serif'
                }
            }
        },
    };

    const stateDel = {
        series: [{
            name: "배송",
            data: deliverySeries
        }],
        options: {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                }
            },
            colors: ['#ffc376'],
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    borderRadiusApplication: 'end',
                    horizontal: true,
                    barHeight: '70%',
                }
            },
            dataLabels: {
                enabled: true,
                style: {
                    colors: ['#fff'],
                    fontSize: '12px',
                    fontFamily: 'Pretendard-Regular, sans-serif'
                },
                formatter: function (value) {
                    return "\u20A9 " + value.toLocaleString();
                }
            },
            xaxis: {
                categories: ['Fedex', 'UPS', 'EMS', 'K-Packet', '항공소형', 'Sea', '국내택배', '예치금사용', '적립금사용', '쿠폰사용'],
                title: {
                    text: '단위(\u20A9)',
                    style: {
                        color: '#bebebe',
                        fontSize: '11px',
                        fontFamily: 'Pretendard-Regular, sans-serif'
                    }
                }
            },
            tooltip: {
                enabled: true,
                theme: 'dark',
                y: {
                    formatter: function (value) {
                        return "\u20A9 " + value.toLocaleString();
                    }
                }
            },
            title: {
                text: '배송',
                align: 'left',
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#333333',
                    fontFamily: 'Pretendard-Regular, sans-serif'
                }
            }
        },
    };

    useEffect(() => {
        const today = new Date();
        // const oneMonthAgo = new Date(today);
        // oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        const oneYearAgo = new Date(today);
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

        const formatDateString = (date) => {
            return date.toISOString().split('T')[0];
        };

        // setStartDate(formatDateString(oneMonthAgo));
        setStartDate(formatDateString(oneYearAgo));
        setEndDate(formatDateString(today));
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            selectOutsales();
        }
    }, [startDate, endDate])

    useEffect(() => {
        if (Object.keys(salesData).length) {
            const purchaseTotal = salesData.cartPrice + salesData.domPrice + salesData.offPrice + salesData.ordPrice + salesData.trPrice + salesData.ttFee;
            const deliveryTotal = salesData.ttFedexPrice + salesData.ttEmsPrice + salesData.ttUpsPrice + salesData.ttKPackPrice
                + salesData.ttAirSPrice + salesData.ttSeaPrice + salesData.ttDomPrice + salesData.ttEtcPrice + salesData.dpcPrice;

            const chartData = [
                { sDate: "1월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "2월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "3월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "4월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "5월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "6월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "7월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "8월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "9월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "10월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "11월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "12월", prod: purchaseTotal, del: deliveryTotal }
            ];

            setSalesChartData(chartData);
        }
    }, [salesData]);

    /** endregion */

    /** region Event */

    const selectOutsales = (() => {
        const formattedStartDate = startDate.replace(/-/g, '');
        const formattedEndDate = endDate.replace(/-/g, '');

        axios({
            method: 'get',
            url: '/outsales/selectOutsales',
            params: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            },
        })
            .then((response) => {
                setSalesData(response.data)
                setSalesChartData(response.data)
            })
    })

    useEffect(() => {
        if (salesData) {
            const purchaseData = [
                salesData.cartPrice || 0,
                salesData.ordPrice || 0,
                salesData.offPrice || 0,
                salesData.trPrice || 0,
                salesData.domPrice || 0,
                // salesData.dhlPrice || 0,
                salesData.upsPrice || 0,
                salesData.kPackPrice || 0,
                salesData.airSPrice || 0,
                salesData.ttEtcPrice || 0,
                salesData.ttFee || 0
            ];

            const deliveryData = [
                salesData.ttFedexPrice || 0,
                salesData.ttEmsPrice || 0,
                salesData.ttSeaPrice || 0,
                salesData.ttDomPrice || 0,
                // salesData.ttDhlPrice || 0,
                salesData.ttUpsPrice || 0,
                salesData.ttKPackPrice || 0,
                salesData.ttAirSPrice || 0,
                salesData.useDeposit || 0,
                salesData.usePoint || 0,
                salesData.useCoupon || 0
            ];

            setSalesChartData({ purchaseData, deliveryData });
        }
    }, [salesData]);

    /** endregion */

    return (
        <div className="AdminSalesStatusPg font-t-cont">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">매출현황</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card m-b-40">
                    <div className="display-flex flex-row ht-350">
                        <div className="wd-50pc ht-100pc">
                            <ApexCharts options={stateProd.options} series={stateProd.series} type="bar" width={"100%"} height={"100%"} />
                        </div>
                        <div className="wd-50pc ht-100pc">
                            <ApexCharts options={stateDel.options} series={stateDel.series} type="bar" width={"100%"} height={"100%"} />
                        </div>
                    </div>
                </div>
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        <div className="admin-event-wrap-btn">
                            <AdminYmdEvent startDate={startDate} endDate={endDate} startHandle={startHandle} endHandle={endHandle}/>
                        </div>
                    </div>
                    <div className="display-flex">
                        <div className="wd-50pc">
                            <div className="table-title dark-font">
                                <BsSquareFill className="admin-tb-header-notice"/>
                                <a>구매</a>
                            </div>
                            <div className="wd-100pc p-r-20">
                                <div style={{width: "100%", border: "solid 1px", color: "#F2F2F2", borderRadius: "4px"}}>
                                    <div className="display-flex ht-40">
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">장바구니</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.cartPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">구매대행</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ordPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">오프라인</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.offPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p style={{fontSize: "13px"}}>이체대행</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.trPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">국내배송</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.domPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">기타비용</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ttEtcPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">총수수료</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ttEtcFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wd-50pc">
                            <div className="table-title dark-font">
                                <BsSquareFill className="admin-tb-header-notice"/>
                                <a>배송</a>
                            </div>
                            <div style={{width: "100%", paddingRight: "20px"}}>
                                <div style={{
                                    width: "100%",
                                    border: "solid 1px",
                                    color: "#F2F2F2",
                                    borderRadius: "4px"
                                }}>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p style={{fontSize: "13px"}}>Fedex</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ttFedexPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">UPS</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ttUpsPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">EMS</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ttEmsPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">K-Packet</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ttKPackPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">항공소형</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ttAirSPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">Sea</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ttSeaPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    {/*<div style={{height: "40px", display: "flex"}}>*/}
                                    {/*    <div style={{*/}
                                    {/*        width: "50%",*/}
                                    {/*        height: "100%",*/}
                                    {/*        display: "flex",*/}
                                    {/*        justifyContent: "center",*/}
                                    {/*        alignItems: "center",*/}
                                    {/*        backgroundColor: "#F6F6F6"*/}
                                    {/*    }}>*/}
                                    {/*        <p className="font-s-13">DHL</p>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">*/}
                                    {/*        <p>{salesData && salesData?.ttDhlPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">국내택배</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ttDomPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">예치금사용</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.useDeposit?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p style={{fontSize: "13px"}}>적립금사용</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.usePoint?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">쿠폰사용</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.useCoupon?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminSalesStatusPg
