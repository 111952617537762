import '../../css/mobile/MHeader.css';
import Back from "../../assets/img/commonBack.png";
import { useNavigate } from "react-router-dom";

const MHeader = (props) => {
	const { title, url } = props;
	const navigate= useNavigate();

	return (
		<div className='m-a-header'>
			{url && (
				<div className="m-u-header-frame wd-40">
					<img className='m-a-header-back-icon' src={Back} alt='Back' onClick={() => {navigate(url);}}/>
				</div>
			)}
			<div className='m-a-header-title font-t-head'>
				{title}
			</div>
		</div>
	)
}


export default MHeader;
