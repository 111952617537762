import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {BsTrash} from "react-icons/bs";
import photoUpload from "../../assets/img/reviewUpload.png";
import axios from "axios";
import MsgCheckModal from "../../components/web/modal/MsgCheckModal";
import useTheme from "../../components/web/layoutscommon/DarkModeStorage";
import back from "../../assets/img/commonBack.png";

function MAdminNoticeWritePg() {

    const navigate = useNavigate();
    const LCAT = localStorage.getItem('adminToken');

    const [langList, setLangList] = useState(["한국어", "English", "日本語", "中文語", "Indonesian"]);
    const [selectedLang, setSelectedLang] = useState("언어선택");
    const [langShow, setLangShow] = useState(false);
    const langRef = useRef(null);

    const handleLangChange = (lang) => {
        setSelectedLang(lang);
        setLangShow(false);
    };

    const [titleErrorMsg, setTitleErrorMsg] = useState(false);
    const [contentErrorMsg, setContentErrorMsg] = useState(false);
    const [langErrorMsg, setLangErrorMsg] = useState(false);

    const [titleText, setTitleText] = useState('');
    const handleChangeTitleText = (e) => {
        setTitleText(e.target.value);
    };

    const [contentText, setContentText] = useState('');
    const handleChangeContentText = (e) => {
        setContentText(e.target.value);
    };

    const [photoContainers, setPhotoContainers] = useState([{ photoImg: photoUpload, photoImgSt: false, photoImgError: false }]);
    const photoImgHandle = (idx) => (e) => {
        const file = e.target.files[0];

        if (file) {
            setPhotoContainers((prev) => {
                const newContainers = [...prev];

                if (!file.type.startsWith('image/')) {
                    newContainers[idx] = {
                        ...newContainers[idx],
                        photoImgError: true,
                    };
                    return newContainers;
                } else {
                    newContainers[idx] = {
                        ...newContainers[idx],
                        photoImg: URL.createObjectURL(file),
                        photoImgSt: true,
                        photoImgError: false,
                        file: file
                    };
                    if (idx === newContainers.length - 1) {
                        addForm(newContainers);
                    }
                    return newContainers;
                }
            });
        }
    };

    const photoImgInit = (idx) => () => {
        setPhotoContainers((prev) => {
            const newContainers = [...prev];

            newContainers.splice(idx, 1);

            const lastContainer = newContainers[newContainers.length - 1];
            if (lastContainer && lastContainer.photoImgSt) {
                newContainers.push({ photoImg: photoUpload, photoImgSt: false, photoImgError: false });
            }

            return newContainers;
        });
    };

    const addForm = (newContainers) => {
        const lastContainer = newContainers[newContainers.length - 1];
        if (!lastContainer.photoImgSt) {
            return;
        }

        newContainers.push({ photoImg: photoUpload, photoImgSt: false, photoImgError: false });
    };

    const validation = () => {
        if (titleText === '' || !titleText) setTitleErrorMsg(true);
        if (contentText === '' || !contentText) setContentErrorMsg(true);
        if (selectedLang === '' || !selectedLang) setLangErrorMsg(true);
        return !!(titleText && contentText && selectedLang)
    }

    const [saveFailSt, setSaveFailSt] = useState(false);
    const closeSaveFailSt = (() => {
        setSaveFailSt(false);
    })
    const photoSave = () => {

        const lang = selectedLang === "Korean" ? 0 : selectedLang === "English" ? 1
            : selectedLang === "Japanese" ? 2 : selectedLang === "Chinese" ? 3 : 4

        const newFormData = new FormData();

        newFormData.append('title', titleText);
        newFormData.append('contentText', contentText);

        photoContainers.forEach((container, idx) => {
            if (container.file) {
                newFormData.append('photoImg', container.file);
            }
        });

        return axios({
            method: 'post',
            url: '/notice/photoSave',
            data: newFormData,
            params: {
                fixFl: fixCheck,
                lang: lang
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: LCAT
            },
        })
            .then((response) => {
                navigate(`/btcorpad/adminnotice-detail/${response.data}`);
            });
    }

    const valiAndSave = () => {
        if (validation()) {
            photoSave();
        }
    }

    useEffect(() => {
        if (titleText.length > 0) {
            setTitleErrorMsg(false);
        } if (contentText.length > 0) {
            setContentErrorMsg(false);
        } if (!selectedLang || selectedLang !== '') {
            setLangErrorMsg(false);
        }
    }, [titleText, contentText, selectedLang])

    const [fixCheck, setFixCheck] = useState(false);
    const selectedFixCheckBox = (() => {
        setFixCheck(!fixCheck);
    })

    const pageBack = (() => {
        navigate(-1);
    })

    //region 다크모드

    const [theme] = useTheme();

    //endregion

    return(
        <div id="m-a-notice-write-pg-container" data-theme={theme}>
            <div className="m-a-common-header">
                <img onClick={pageBack} className="m-a-backIcon" src={back} alt="back"/>
                <div className="m-a-pg-header-text font-t-head">글작성[공지사항]</div>
            </div>
            <div className="m-a-fixed-container font-t-cont">
                <div className="m-t-15 display-flex flex-column">
                    <div className="m-a-input-frame ht-45 m-b-15">
                        <input className="m-a-input wd-100pc font-t-title font-s-14" type="text" placeholder="제목" value={titleText} onChange={handleChangeTitleText}/>
                    </div>
                </div>
                <div className="display-flex flex-row just-cont-sb wd-100pc m-b-15 font-t-cont">
                    <div className="m-a-combo wd-50pc ht-45 font-s-15 m-a-flex-jc-start" onClick={()=> setLangShow(true)}>
                        {selectedLang}
                        {langShow && (
                            <div className="m-a-page-popup-container" onClick={(e) => { e.stopPropagation(); setLangShow(false); }}>
                                <div className="m-a-page-popup-frame" onClick={(e) => e.stopPropagation()}>
                                    <div className="m-a-page-popup-header-frame">
                                        <div className="font-t-title font-s-18 m-t-4">언어선택</div>
                                    </div>
                                    <div className="m-a-page-popup-body-frame">
                                        {langList.map((lang, index) => (
                                            <div key={index} onClick={() => handleLangChange(lang)}
                                                 className={`m-a-card m-t-10 m-b-10 ${selectedLang === lang ? "m-a-selected-option" : ""}`}>
                                                <p>{lang}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="m-a-page-popup-footer-frame">
                                        <div className="m-a-file-bt-container">
                                            <button className="m-a-colorBt ht-45 m-b-5 wd-80" onClick={(e) => { e.stopPropagation(); setLangShow(false); }}>
                                                <div className="m-a-colorBt-text font-t-cont">Close</div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="display-flex flex-row wd-50pc ht-45 just-cont-fend p-t-10">
                        <p className="font-s-15 m-r-5">공지 상단고정</p>
                        <input className="m-a-admin-input-chkBox m-t-1" id="AgreeCheckBox" type="checkbox" checked={fixCheck} onChange={selectedFixCheckBox}/>
                    </div>
                </div>
                <div>
                    <div className="m-a-textarea-frame m-b-15 font-s-14 font-t-cont ht-150" style={{minHeight: "100px", maxHeight: "500px"}}>
                        <textarea className="m-a-textarea-b" placeholder="내용입력" spellCheck="false" value={contentText} onChange={handleChangeContentText}/>
                    </div>
                </div>
                <div>
                    <div>
                        {photoContainers.map((container, idx) => (
                            <div key={idx} className="display-flex just-cont-center align-center border-c ht-400">
                                {!container.photoImgSt ?
                                    <div className="display-block text-center" onClick={(e) => {
                                        const parentDiv = e.target.parentNode;
                                        const fileInput = parentDiv.querySelector('input[type="file"]');
                                        if (fileInput) fileInput.click();
                                    }}>
                                        <input type="file" accept="image/*" style={{display: "none"}} onChange={photoImgHandle(idx)}/>
                                        <img className="wd-70 ht-70" alt="uploadImg" style={{filter: "opacity(0.3) drop-shadow(0 0 0 gray)",}} src={container.photoImg}/>
                                        <p className="font-s-18 font-c-default">Click here!</p>
                                    </div>
                                    :
                                    <div className="display-flex wd-90pc ht-90pc position-relative" style={{maxHeight: "400px"}}>
                                        <img className="display-flex just-cont-center align-center wd-100pc ht-100pc" alt="uploadImg" src={container.photoImg}/>
                                        <button className="orderItemHandleBt position-absolute wd-40 ht-40 m-a-opc-06" style={{top:"85%", left:"86%", borderRadius:"4px"}}
                                                onClick={photoImgInit(idx, container.photoImg)}>
                                            <i style={{display: "flex", justifyContent: "center", alignItems: "center", fontSize:"26px"}}>
                                                <BsTrash className="display-flex just-cont-center align-center font-s-26"/>
                                            </i>
                                        </button>
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="m-a-m-fixed-bt-frame">
                <button className="m-a-colorBt-radius-n m-a-colorBt-text wd-100pc ht-45" onClick={valiAndSave}>저장</button>
            </div>
            { saveFailSt ?
                <MsgCheckModal saveFailSt={saveFailSt} closeSaveFailSt={closeSaveFailSt}/>
                :
                null
            }
        </div>
    )
}

export default MAdminNoticeWritePg
