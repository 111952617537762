import "../../css/mobile/MUserChatAlarmPg.css";
import MHeader from "../../components/mobile/MHeader";
import ordChatAlarm from "../../assets/img/orderChatAlarm.png";
import profileDefaultImg from "../../assets/img/profileDefaultImg.png";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useNavigation, useParams} from "react-router-dom";
import {usePopup} from "../../components/contextApi/FooterOpenChatContext";
import React, {createRef, useCallback, useEffect, useMemo, useRef, useState} from "react";
import axios from "axios";
import {connect} from "../../services/WebsocketService";
import * as XLSX from "xlsx";
import excel from "exceljs";
import {orderStatusUpdate} from "../../services/AutoChangeOrderSt";
import boxLogo from "../../assets/img/boxLogo.png";
import HeaderLogo from "../../assets/img/HeaderLogo.png";
import {useWebSocket} from "../../services/WebsocketContext";

const MUserChatAlarmPg = () => {

    const {t} = useTranslation();

    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');


    const [memberDto, setMemberDto] = useState([]);
    const memberDtoRef = useRef(memberDto);

    useEffect(() => {
        if (memberDto.mbNum) {
            getChatData(memberDto.mbNum);
        }
    }, [memberDto]);

    useEffect(() => {
        if (SSAT || LCAT) {
            axios({
                method: 'get',
                url: '/member/modiInfo',
                headers: {
                    Authorization: LCAT ? LCAT : SSAT
                }
            })
                .then((response) => {
                    setMemberDto(response.data[0]);
                    memberDtoRef.current = response.data[0];
                })
                .catch(() => {
                    console.log("멤버Dto 조회 실패")
                })
        }
    }, [SSAT, LCAT]);


    const stompClientRef = useRef(null);
    const { subscribe, unsubscribe, websocketConnect, isConnected, stompClient } = useWebSocket();
    useEffect(() => {
        if (!memberDto && !memberDto.mbNum) return;
        const initializeSubscription = async () => {
            if (!isConnected || !stompClient) {
                stompClientRef.current = await websocketConnect(memberDto.mbNum);
            } else {
                stompClientRef.current = stompClient;
            }
            const subscriptions = [];
            subscriptions.push(subscribe(`/user/${memberDto.userName}/queue/adminMessage`, onMessageReceived));
            subscriptions.push(subscribe(`/user/${memberDto.userName}/queue/adminRead`, onReadMessageReceived));
            stompClientRef.current = subscriptions;
        };
        initializeSubscription();
        return () => {
            if (stompClientRef.current && stompClientRef.current.length > 0) {
                // 구독이 존재하는 경우에만 해제
                stompClientRef.current.forEach((sub) => {
                    unsubscribe(sub);
                });
            }
            stompClientRef.current = null;
        };
    }, [memberDto, isConnected, subscribe, unsubscribe, stompClient]);


    const [chatData, setChatData] = useState([]);

    const getChatData = (mbNum) => {
        axios.get(`/order/chatAlarmList/${mbNum}`, {
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            }
        })
            .then(response => {
                let data = response.data;
                data.sort((a, b) => b.seqnum - a.seqnum);
                setChatData(data)
            })
            .catch(error => {
                console.log("알림데이터 조회실패 : ", error);
            })
    }

    const onMessageReceived = async (payload) => {
        let userReadData = JSON.parse(payload.body);
        if (userReadData.mstMbNum === memberDtoRef.current.mbNum && userReadData.memberDTO.role === '1') {
            getChatData(memberDtoRef.current.mbNum);
        }
    }

    const onReadMessageReceived = async (payload) => {
        let userReadData = JSON.parse(payload.body);
        if (userReadData.mstUserNm === memberDtoRef.current.userName && userReadData.readerRole === "USER") {
            getChatData(memberDtoRef.current.mbNum);
        }
    }

    const convertToLastChatDate = (regDate) => {
        // regDate를 로컬 시간대를 반영하도록 수정
        const regDateParts = regDate.split(" "); // 날짜와 시간을 분리
        const datePart = regDateParts[0]; // "2024-05-02"
        const timePart = regDateParts[1]; // "11:48:57"
        const formattedRegDate = `${datePart}T${timePart}.000+09:00`; // 한국 시간대 적용

        const regTime = new Date(formattedRegDate);
        const currentTime = new Date();

        const timeDifference = currentTime - regTime;
        const minutesDiff = Math.floor(timeDifference / 60000);
        const hoursDiff = Math.floor(minutesDiff / 60);
        const daysDiff = Math.floor(hoursDiff / 24);

        if (daysDiff < 1) {
            if (minutesDiff < 1) return t('now');
            if (minutesDiff < 60) return `${minutesDiff}${t('min')}`;
            return `${hoursDiff}${t('hour')}`;
        } else if (daysDiff === 1) {
            return t('yesterday');
        } else if (daysDiff === 2) {
            return t('2daysAgo');
        } else if (daysDiff < 7) {
            return `${daysDiff}${t('daysAgo')}`;
        } else {
            return `${(regTime.getMonth() + 1).toString().padStart(2, '0')}-${regTime.getDate().toString().padStart(2, '0')}`;
        }
    };

    const [unreadCount, setUnreadCount] = useState(null);
    const [chatShow, setChatShow] = useState(false);
    const navigate = useNavigate();

    const chatFrameHandle = (() => {
        resetUnreadChat(chatData[0]);
        if (chatData && chatData[0]) {
            navigate(`/UserMyOrderPg/${chatData[0].chatOrdNum}`);
        }
    })


    const resetUnreadChat = ((newMessage) => {
        axios({
            method: 'post',
            url: `/order/chat/unread/`,
            params: {
                chatOrdNum: newMessage.chatOrdNum,
                userFl: true
            },
            headers: {
                Authorization: LCAT
            }
        })
            .then(response => {
                if (response.status !== 200) {
                    throw new Error("HTTP status " + response.status);
                } else {
                    setUnreadCount(0);
                    stompClientRead(newMessage.chatOrdNum, newMessage.mstUserNm);
                }
            })
            .catch(error => console.error(error));
    })

    const stompClientRead = ((chatOrdNum, mstUserNm) => {
        if (!stompClientRef.current || !stompClientRef.current.connected) {
            console.log("읽음전송실패")
            return;
        }
        const readChat = {
            chatOrdNum: chatOrdNum,
            mstUserNm: mstUserNm,
            readerRole: "USER"
        };
        stompClient.send("/app/chat/read", {}, JSON.stringify(readChat));
    })

    const totalUnreadCount = chatData.reduce((total, data) => total + (data.unreadCount || 0), 0);

    return (
        <div className='m-a-user-chat-alarm-pg-container'>
            <MHeader title={`${t(`hdChat`)}`} url={"/UserMyPg"}/>
            <div className='m-a-chat-alarm-sub-title'>
                <img className='m-a-chat-alarm-sub-title-icon' src={ordChatAlarm} alt=''/>
                <div className='m-a-chat-alarm-sub-title-text'>{t(`hdChat`)}</div>
            </div>
            <div className='m-a-chat-alarm-divider'/>
            {chatData && chatData.length > 0 && chatData.map((data, index) => (
                <div className='m-a-chat-alarm-frame' onClick={chatFrameHandle} key={index}>
                    <img className='m-a-chat-alarm-frame-img' src={
                        data.memberDTO && data.memberDTO.profileDTO && data.memberDTO.profileDTO.filename ? "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + data.memberDTO.profileDTO.filename : HeaderLogo
                    } alt=''/>
                    {(totalUnreadCount > 0 && chatData[0].memberDTO.role === '1') ?
                        <div className='m-a-chat-alarm-frame-length'> {totalUnreadCount}</div>
                        :
                        null
                    }
                    <div className='m-a-chat-alarm-frame-info'>
                        <div
                            className='m-a-chat-alarm-frame-info-title'>{chatData && chatData[0].chatOrdNum}</div>
                        <div className='m-a-chat-alarm-frame-info-content'>
                            {data.chatKey.startsWith("chatFile") ? data.filenm : data.chatKey}
                            <div
                                className='m-a-chat-alarm-frame-date'>{convertToLastChatDate(chatData[0].regTime)}</div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )

}

export default MUserChatAlarmPg;
