import '../../css/mobile/MCompareShipPg.css';
import weight from "../../assets/img/weight.png";
import MHeader from "../../components/mobile/MHeader";
import DeliveryFee from "../../assets/img/DeliveryFee.png";
import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import Expand from "../../assets/img/commonBottom.png";

const MCompareShipPg = () => {
    const {t} = useTranslation();

    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({value: '', label: t('modSmallMenu3')});

    const [countryShow, setCountryShow] = useState(false);
    const countryRef = useRef(null);

    const toggleCountryShow = () => {
        setCountryShow(!countryShow);
    };

    const handleCountryChange = (country) => {
        console.log(country)
        setSelectedCountry(country);
        setCountryShow(false);
    };


    useEffect(() => {
        function handleClickOutside(event) {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setCountryShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const selectCountry = (() => {
        axios({
            method: 'get',
            url: '/order/selectCountry',
            // headers: {
            //     Authorization: LCAT ? LCAT : SSAT
            // },
        })
            .then((response) => {
                // const countries = response.data.map(country => ({
                //     value: country.countryCode,
                //     label: country.countryNm + '(' + country.countryEngNm + ')'
                // }));
                // setCountryList(countries);
                const countries = response.data
                    .map(country => ({
                        value: country.countryCode,
                        label: `${country.countryNm}(${country.countryEngNm})`,
                        engName: country.countryEngNm
                    }))
                    .sort((a, b) => a.engName.localeCompare(b.engName));

                setCountryList(countries.map(country => ({
                    value: country.value,
                    label: country.label
                })));
            })
            .catch((error) => {
                console.log(error)
            })
    })

    useEffect(() => {
        selectCountry();
    }, [])

    const [realWeight, setRealWeight] = useState('');
    const [wid, setWid] = useState('');
    const [len, setLen] = useState('');
    const [hei, setHei] = useState('');

    const [fedexAppWeight, setFedExAppWeight] = useState('');
    const [dhlAppWeight, setDhlAppWeight] = useState('');
    const [emsAppWeight, setEmsAppWeight] = useState('');
    const [upsAppWeight, setUpsAppWeight] = useState('');
    const [kpackAppWeight, setKpackAppWeight] = useState('');

    const [fedexPrice, setFedexPrice] = useState('');
    const [dhlPrice, setDhlPrice] = useState('');
    const [emsPrice, setEmsPrice] = useState('');
    const [upsPrice, setUpsPrice] = useState('');
    const [kpackPrice, setKpackPrice] = useState('');
    const [smallPackPrice, setSmallPackPrice] = useState('');
    const [seaPrice, setSeaPrice] = useState('');

    const initWeight = (() => {
        setRealWeight('');
        setWid('');
        setLen('');
        setHei('');
    })

    const findPrice = (weight, priceList) => {
        const weightInGrams = Math.round(weight * 1000); // kg를 그램으로 변환, 반올림 처리
        const weightKeys = Object.keys(priceList).map(key => parseInt(key.substring(1))); // "w500" -> 500
        const sortedWeights = weightKeys.sort((a, b) => a - b);

        for (let i = 0; i < sortedWeights.length; i++) {
            if (weightInGrams <= sortedWeights[i]) {
                return priceList[`w${sortedWeights[i]}`]; // 해당 무게에 맞는 가격 반환
            }
        }
        return t('cspWtNoSurch'); // 무게구간에 해당하지 않을 경우 '별도문의' 반환
    };

    // 실무게&가로&세로&높이 모두 입력될때만 DB에 조회쿼리
    useEffect(() => {
        if (selectedCountry.value !== '' && realWeight !== '' && wid !== '' && len !== '' && hei !== '') {
            selectedShipFee(selectedCountry.value);
        }
    }, [selectedCountry, realWeight, wid, len, hei])

    const selectedShipFee = ((crCd) => {
        axios({
            method: 'get',
            url: '/shipinfo/selectedShipFee',
            params: {
                crCd: crCd
            }
        })
            .then((response) => {
                const data = response.data;

                const divideBy6000 = wid * len * hei / 6000;
                const divideBy5000 = wid * len * hei / 5000;
                const fedexAppWeight = Math.max(realWeight, divideBy5000);
                const dhlAppWeight = Math.max(realWeight, divideBy5000);
                const emsAppWeight = Math.max(realWeight, divideBy6000);
                const upsAppWeight = Math.max(realWeight, divideBy6000);
                const kpackAppWeight = Math.max(realWeight, divideBy6000);
                const smallPackAppWeight = Math.max(realWeight);
                const seaAppWeight = Math.max(realWeight);
                // 위 두 계산값 모두 소숫점 2자리까지만 출력
                const formattedFedexAppWeight = parseFloat(fedexAppWeight.toFixed(2));
                const formattedDhlAppWeight = parseFloat(dhlAppWeight.toFixed(2));
                const formattedEmsAppWeight = parseFloat(emsAppWeight.toFixed(2));
                const formattedUpsAppWeight = parseFloat(upsAppWeight.toFixed(2));
                const formattedKpackAppWeight = parseFloat(kpackAppWeight.toFixed(2));
                const formattedSmallPackAppWeight = parseFloat(smallPackAppWeight.toFixed(2));
                const formattedSeaAppWeight = parseFloat(seaAppWeight.toFixed(2));

                setFedExAppWeight(formattedFedexAppWeight);
                setDhlAppWeight(formattedDhlAppWeight);
                setEmsAppWeight(formattedEmsAppWeight);
                setUpsAppWeight(formattedUpsAppWeight);
                setKpackAppWeight(formattedKpackAppWeight);

                // 가격찾고 서비스하지 않는 국가로 인해 null이면 '별도문의 반환'
                let fedexPrice = data.exrInfoFEDEXDTO ? findPrice(formattedFedexAppWeight, data.exrInfoFEDEXDTO) : t('cspWtNoSurch');
                let dhlPrice = data.exrInfoDHLDTO ? findPrice(formattedDhlAppWeight, data.exrInfoDHLDTO) : t('cspWtNoSurch');
                let emsPrice = data.exrInfoEMSDTO ? findPrice(formattedEmsAppWeight, data.exrInfoEMSDTO) : t('cspWtNoSurch');
                let upsPrice = data.exrInfoEMSDTO ? findPrice(formattedUpsAppWeight, data.exrInfoEMSDTO) : t('cspWtNoSurch');
                let kpackPrice = data.exrInfoEMSDTO ? findPrice(formattedKpackAppWeight, data.exrInfoEMSDTO) : t('cspWtNoSurch');
                let smallPackPrice = data.exrInfoEMSDTO ? findPrice(realWeight, data.exrInfoEMSDTO) : t('cspWtNoSurch');
                let seaPrice = data.exrInfoSHIPDTO ? findPrice(realWeight, data.exrInfoSHIPDTO) : t('cspWtNoSurch');

                // fedex 유류할증료 합산
                if (typeof fedexPrice === "number" && data.countryDTO && data.countryDTO.fedexFuelSurcharge > 0) {
                    // 백분율을 소수점으로 변환하여 fedexPrice에서 fedexFuelSurcharge값만큼 fedexPrice에 +=
                    const surchargeRate = data.countryDTO.fedexFuelSurcharge / 100;
                    const fuelSurcharge = fedexPrice * surchargeRate;
                    const roundedFuelSurcharge = Math.round(fuelSurcharge);
                    // 소숫점 제외한 전체 결과값인 fuelSurcharge값을 산출
                    fedexPrice += roundedFuelSurcharge;
                    // 전체 결과값에서 10원 단위로 반올림
                    fedexPrice = Math.round(fedexPrice / 10) * 10;
                }
                // fedex 운송수수료 합산
                if (typeof fedexPrice === "number" && data.countryDTO.fedexAddFee > 0) {
                    const additionalFedexFee = formattedFedexAppWeight * 10 * data.countryDTO.fedexAddFee;
                    fedexPrice += additionalFedexFee;
                }

                // dhl 유류할증료 합산
                if (typeof dhlPrice === "number" && data.countryDTO && data.countryDTO.dhlFuelSurcharge > 0) {
                    // 백분율을 소수점으로 변환하여 dhlPrice에서 dhlFuelSurcharge값만큼 dhlPrice에 +=
                    const surchargeRate = data.countryDTO.dhlFuelSurcharge / 100;
                    const fuelSurcharge = dhlPrice * surchargeRate;
                    const roundedFuelSurcharge = Math.round(fuelSurcharge);
                    // 소숫점 제외한 전체 결과값인 fuelSurcharge값을 산출
                    dhlPrice += roundedFuelSurcharge;
                    // 전체 결과값에서 10원 단위로 반올림
                    dhlPrice = Math.round(dhlPrice / 10) * 10;
                }
                // dhl 운송수수료 합산
                if (typeof dhlPrice === "number" && data.countryDTO.dhlAddFee > 0) {
                    const additionalDhlFee = formattedFedexAppWeight * 10 * data.countryDTO.dhlAddFee;
                    dhlPrice += additionalDhlFee;
                }

                // ems 운송수수료 합산
                if (typeof emsPrice === "number" && data.countryDTO.emsAddFee > 0) {
                    const additionalEmsFee = formattedEmsAppWeight * 10 * data.countryDTO.emsAddFee;
                    emsPrice += additionalEmsFee;
                }

                // ems프리미엄 운송수수료 합산
                if (typeof upsPrice === "number" && data.countryDTO.upsAddFee > 0) {
                    const additionalEmsPFee = formattedUpsAppWeight * 10 * data.countryDTO.upsAddFee;
                    upsPrice += additionalEmsPFee;
                }

                // K-Packet 운송수수료 합산
                if (typeof kpackPrice === "number" && data.countryDTO.kPackAddFee > 0) {
                    const additionalKpackFee = formattedKpackAppWeight * 10 * data.countryDTO.kPackAddFee;
                    kpackPrice += additionalKpackFee;
                }

                // Small-Packet 운송수수료 합산
                if (typeof smallPackPrice === "number" && data.countryDTO.airSAddFee > 0) {
                    const additionalSmallPackFee = formattedSmallPackAppWeight * 10 * data.countryDTO.airSAddFee;
                    smallPackPrice += additionalSmallPackFee;
                }

                // sea 운송수수료 합산
                if (typeof seaPrice === "number" && (data.countryDTO.countryCode === "US" || data.countryDTO.countryCode === "CA")) {
                    const additionalSeaFee = formattedSeaAppWeight * 10 * data.countryDTO.seaFee;
                    seaPrice += additionalSeaFee;
                }

                setFedexPrice(fedexPrice);
                setDhlPrice(dhlPrice);
                setEmsPrice(emsPrice);
                setUpsPrice(upsPrice);
                setKpackPrice(kpackPrice);
                setSmallPackPrice(smallPackPrice);
                setSeaPrice(seaPrice);
            })
            .catch((error) => {
                console.log("배송사별 요금조회 실패 : ", error)
            })
    })

    return (
        <div className='m-a-compare-ship-pg-container'>
            <MHeader title={t(`cspSmallHeader1`)} url={"/"}/>
            <div className='m-a-compare-ship-sub-title'>
                <img className='m-a-compare-ship-sub-title-icon' src={weight} alt='header'/>
                <div className='m-a-compare-ship-sub-title-text'>{t(`cspSmallHeader1`)}</div>
            </div>
            <div className='m-a-compare-ship-divider'/>
            <div className='m-a-compare-ship-weight-frame'>
                <div className='m-a-compare-ship-weight-frame-grid'>
                    <div className='m-a-compare-ship-weight-frame-grid-label'>{t(`modSmallMenu3`)}</div>
                    <div className='m-a-compare-ship-weight-frame-grid-value country'
                         ref={countryRef} onClick={toggleCountryShow}>
                        {selectedCountry.label}
                        <img className={'m-a-compare-ship-weight-frame-grid-expand-icon'} src={Expand} alt={''}/>
                        {countryShow && (
                            <div
                                className={`m-a-compare-ship-weight-frame-country-list`}>
                                {countryList.map((country, index) => (
                                    <div
                                        className={`m-a-compare-ship-weight-frame-country-item ${country.label === selectedCountry.label && 'selected'}`}
                                        key={index} onClick={() => handleCountryChange(country)}>
                                        {country.label}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                {/*실무게*/}
                <div className='m-a-compare-ship-weight-frame-grid'>
                    <div className='m-a-compare-ship-weight-frame-grid-label'>{t(`cspRw`)}(kg)</div>
                    <input className='m-a-compare-ship-weight-frame-grid-value country' type="text"
                           value={realWeight} onChange={(e) => setRealWeight(e.target.value)}/>
                </div>
                {/*부피무게*/}
                <div className='m-a-compare-ship-weight-frame-grid volume'>
                    <div className='m-a-compare-ship-weight-frame-grid-label'>{t(`cspV`)}(cm)</div>
                    <input className='m-a-compare-ship-weight-frame-grid-value tripple'
                           type="text" value={wid} onChange={(e) => setWid(e.target.value)}/>
                    *
                    <input className='m-a-compare-ship-weight-frame-grid-value tripple'
                           type="text" value={len} onChange={(e) => setLen(e.target.value)}/>
                    *
                    <input className='m-a-compare-ship-weight-frame-grid-value tripple'
                           type="text" value={hei} onChange={(e) => setHei(e.target.value)}/>
                </div>
                {/*적용무게*/}
                <div className='m-a-compare-ship-weight-frame-grid'>
                    <div className='m-a-compare-ship-weight-frame-grid-label'>{t(`cspVw`)}</div>
                    {emsAppWeight && fedexAppWeight && (
                        <div className={'m-a-compare-ship-weight-frame-grid-value country'}>
                            {emsAppWeight}kg ~ {fedexAppWeight}kg
                        </div>
                    )}
                </div>
                <div className='m-a-compare-ship-button' onClick={initWeight}> {t(`cspBt`)}</div>
            </div>
            <div className='m-a-compare-ship-sub-title'>
                <img className='m-a-compare-ship-sub-title-icon' src={DeliveryFee} alt=''/>
                <div className='m-a-compare-ship-sub-title-text'>{t(`cspSmallHeader2`)}</div>
            </div>
            <div className='m-a-compare-ship-divider'/>
            <div className='m-a-compare-ship-delivery-fee-frame'>

                {/*페덱스*/}
                <div className='m-a-compare-ship-delivery-fee-row'>
                    <div className='m-a-compare-ship-delivery-fee-row-left'>
                        <div className='m-a-compare-ship-delivery-fee-agency'> FedEx</div>
                        {fedexAppWeight &&
                            <div className='m-a-compare-ship-delivery-fee-weight'>
                                (${t(`cspAw`)}
                                <div className='strong'>{fedexAppWeight}</div>
                                kg)
                            </div>
                        }
                    </div>
                    <div className={`m-a-compare-ship-delivery-fee-row-right ${!fedexPrice && 'normal'}`}>
                        {fedexPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                    </div>
                </div>

                {/*DHL*/}
                <div className='m-a-compare-ship-delivery-fee-row'>
                    <div className='m-a-compare-ship-delivery-fee-row-left'>
                        <div className='m-a-compare-ship-delivery-fee-agency'> DHL</div>
                        {dhlAppWeight &&
                            <div className='m-a-compare-ship-delivery-fee-weight'>
                                ({t(`cspAw`)}
                                <div className='strong'>{dhlAppWeight}</div>
                                kg)
                            </div>
                        }
                    </div>
                    <div className={`m-a-compare-ship-delivery-fee-row-right ${!dhlPrice && 'normal'}`}>
                        {dhlPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                    </div>
                </div>

                {/*UPS*/}
                <div className='m-a-compare-ship-delivery-fee-row'>
                    <div className='m-a-compare-ship-delivery-fee-row-left'>
                        <div className='m-a-compare-ship-delivery-fee-agency'> UPS</div>
                        {upsAppWeight &&
                            <div className='m-a-compare-ship-delivery-fee-weight'>
                                ({t(`cspAw`)}
                                <div className='strong'>{upsAppWeight}</div>
                                kg)
                            </div>
                        }
                    </div>
                    <div className={`m-a-compare-ship-delivery-fee-row-right ${!upsPrice && 'normal'}`}>
                        {upsPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                    </div>
                </div>

                {/*EMS*/}
                <div className='m-a-compare-ship-delivery-fee-row'>
                    <div className='m-a-compare-ship-delivery-fee-row-left'>
                        <div className='m-a-compare-ship-delivery-fee-agency'> EMS</div>
                        {emsAppWeight &&
                            <div className='m-a-compare-ship-delivery-fee-weight'>
                                ({t(`cspAw`)}
                                <div className='strong'>{emsAppWeight}</div>
                                kg)
                            </div>
                        }
                    </div>
                    <div className={`m-a-compare-ship-delivery-fee-row-right ${!emsPrice && 'normal'}`}>
                        {emsPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                    </div>
                </div>

                {/*K-Packet*/}
                <div className='m-a-compare-ship-delivery-fee-row'>
                    <div className='m-a-compare-ship-delivery-fee-row-left'>
                        <div className='m-a-compare-ship-delivery-fee-agency'> K-Packet</div>
                        {kpackAppWeight &&
                            <div className='m-a-compare-ship-delivery-fee-weight'>
                                ({t(`cspAw`)}
                                <div className='strong'>{kpackAppWeight}</div>
                                kg)
                            </div>
                        }
                    </div>
                    <div className={`m-a-compare-ship-delivery-fee-row-right ${!kpackPrice && 'normal'}`}>
                        {kpackPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                    </div>
                </div>

                {/*Small-Packet*/}
                <div className='m-a-compare-ship-delivery-fee-row'>
                    <div className='m-a-compare-ship-delivery-fee-row-left'>
                        <div className='m-a-compare-ship-delivery-fee-agency'> Small-Packet</div>
                        {realWeight &&
                            <div className='m-a-compare-ship-delivery-fee-weight'>
                                ({t(`cspAw`)}
                                <div className='strong'>{realWeight}</div>
                                kg)
                            </div>
                        }
                    </div>
                    <div className={`m-a-compare-ship-delivery-fee-row-right ${!smallPackPrice && 'normal'}`}>
                        {smallPackPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                    </div>
                </div>

                {/*선편*/}
                <div className='m-a-compare-ship-delivery-fee-row'>
                    <div className='m-a-compare-ship-delivery-fee-row-left'>
                        <div className='m-a-compare-ship-delivery-fee-agency'> Sea Parcel</div>
                        {realWeight &&
                            <div className='m-a-compare-ship-delivery-fee-weight'>
                                ({t(`cspAw`)}
                                <div className='strong'>{realWeight}</div>
                                kg)
                            </div>
                        }
                    </div>
                    <div className={`m-a-compare-ship-delivery-fee-row-right ${!seaPrice && 'normal'}`}>
                        {seaPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                    </div>
                </div>

            </div>
            <div className='m-a-compare-ship-description'>
                ※ {t('cspNt1')} <br/>
                ※ {t('cspNt2')}
            </div>
        </div>
    )
}

export default MCompareShipPg;
