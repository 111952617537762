import React from "react";
import commonClose from '../../../assets/img/commonClose.png'
import HeaderLangKr from '../../../assets/img/HeaderLangKr.png'
import HeaderLangUs from '../../../assets/img/HeaderLangUs.png'
import HeaderLangCn from '../../../assets/img/HeaderLangCn.png'
import HeaderLangJp from '../../../assets/img/HeaderLangJp.png'
import HeaderLangId from '../../../assets/img/HeaderLangId.png'

function SelectLangModal({selectLangFalseFn, handleSelectLangChange}) {
    return (
        <div className="bookContainer">
            <div className="bookFrame" style={{
                width: "300px",
                height: "auto",
                top: "calc(25vh - 0%)",
                left: '50%',
                transform: 'translateX(-50%)',
                borderRadius: "4px",
            }}>
                <div style={{
                    borderBottom: "solid 1px #EAEAEA",
                    height: "60px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "0 20px"
                    }}>
                        <div className="user-center" style={{height: "100%"}}>
                            <p style={{fontSize: "18px"}} className="font-t-title">Language</p>
                        </div>
                        <div onClick={selectLangFalseFn} style={{fontSize: "18px", cursor: "pointer"}}>
                            <img style={{width: "30px", height: "30px", opacity: "0.6"}} alt="close" src={commonClose}/>
                        </div>
                    </div>
                </div>
                <div className="font-t-cont" style={{
                    height: "70%",
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    margin: "10px",
                    padding: "10px"
                }}>
                    <div onClick={() => handleSelectLangChange("ko")} className="user-center" style={{
                        width: "100%",
                        height: "50px",
                        border: "solid 1px #EAEAEA",
                        borderRadius: "6px",
                        cursor: "pointer"
                    }}>
                        <div className="user-center" style={{width: "40%"}}>
                            <img style={{width: "45px", height: "45px", opacity: "0.8", borderRadius: "8px"}} alt="korea" src={HeaderLangKr}/>
                        </div>
                        <div style={{width: "60%"}}>
                            <p style={{fontSize: "14px"}}>한국어</p>
                        </div>
                    </div>
                    <div onClick={() => handleSelectLangChange("en")} className="user-center" style={{
                        width: "100%",
                        height: "50px",
                        border: "solid 1px #EAEAEA",
                        borderRadius: "6px",
                        marginTop: "10px",
                        cursor: "pointer"
                    }}>
                        <div className="user-center" style={{width: "40%"}}>
                            <img style={{width: "40px", height: "40px", opacity: "0.8"}} alt="english" src={HeaderLangUs}/>
                        </div>
                        <div style={{width: "60%"}}>
                            <p style={{fontSize: "14px"}}>English</p>
                        </div>
                    </div>
                    <div onClick={() => handleSelectLangChange("zh")} className="user-center" style={{
                        width: "100%",
                        height: "50px",
                        border: "solid 1px #EAEAEA",
                        borderRadius: "6px",
                        marginTop: "10px",
                        cursor: "pointer"
                    }}>
                        <div className="user-center" style={{width: "40%"}}>
                            <img style={{width: "40px", height: "40px", opacity: "0.8"}} alt="china" src={HeaderLangCn}/>
                        </div>
                        <div style={{width: "60%"}}>
                            <p style={{fontSize: "14px"}}>中国人</p>
                        </div>
                    </div>
                    <div onClick={() => handleSelectLangChange("ja")} className="user-center" style={{
                        width: "100%",
                        height: "50px",
                        border: "solid 1px #EAEAEA",
                        borderRadius: "6px",
                        marginTop: "10px",
                        cursor: "pointer"
                    }}>
                        <div className="user-center" style={{width: "40%"}}>
                            <img style={{width: "45px", height: "45px", opacity: "0.8", borderRadius: "8px"}} alt="japan"
                                 src={HeaderLangJp}/>
                        </div>
                        <div style={{width: "60%"}}>
                            <p style={{fontSize: "14px"}}>日本語</p>
                        </div>
                    </div>
                    <div onClick={() => handleSelectLangChange("id")} className="user-center" style={{
                        width: "100%",
                        height: "50px",
                        border: "solid 1px #EAEAEA",
                        borderRadius: "6px",
                        marginTop: "10px",
                        cursor: "pointer"
                    }}>
                        <div className="user-center" style={{width: "40%"}}>
                            <img style={{width: "38px", height: "38px", opacity: "0.8", borderRadius: "8px"}} alt="japan"
                                 src={HeaderLangId}/>
                        </div>
                        <div style={{width: "60%"}}>
                            <p style={{fontSize: "14px"}}>bahasaIndo</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectLangModal
