import React, {useEffect, useState} from 'react';
import '../../css/mobile/MAdmin.css';
import axios from "axios";
import {MdKeyboardArrowDown} from "react-icons/md";
import maCateImg from "../../assets/img/m-a-cate.png";
import MAdminPageSmallEvent from "./MAdminPageSmallEvent";
import MAdminYmdSmallEvent from "./MAdminYmdSmallEvent";

function MAdminUserStatusPg() {

    /** region DataTable */

    const [data, setData] = useState([]);

    /** endregion */

    /** region Function */

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [data.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
        if (data.length > displayLimit && displayLimit < 30) {
            setDisplayLimit(prevLimit => prevLimit + perPg);
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e);
        setperPg(newPerPage);
        setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    const [popupVisible, setPopupVisible] = useState(false);

    const togglePopup = () => {
        setPopupVisible(!popupVisible);
    };

    useEffect(() => {
        setDisplayLimit(perPg);
    }, [perPg]);

    // endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);

    const checkBoxChange = (id) => {
        setChecked((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter((item) => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    const allcheckBoxChange = (e) => {
        let visibleData = data.slice(0, displayLimit);
        if (e.target.checked) {
            const visibleIds = visibleData.map((item) => item.seqNum);
            setChecked(visibleIds);
        } else {
            setChecked([]);
        }
    };

    //endregion

    /** endregion */

    /** region Event */

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const startHandle = (e) => {
        setStartDate(e.target.value);
    };
    const endHandle = (e) => {
        setEndDate(e.target.value);
    };

    useEffect(() => {
        const today = new Date();
        // const oneMonthAgo = new Date(today);
        // oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        const oneYearAgo = new Date(today);
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

        const formatDateString = (date) => {
            return date.toISOString().split('T')[0];
        };
        setStartDate(formatDateString(oneYearAgo));
        setEndDate(formatDateString(today));
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            selectOutsalesMember();
        }
    }, [startDate, endDate])

    const selectOutsalesMember = (() => {
        const formattedStartDate = startDate.replace(/-/g, '');
        const formattedEndDate = endDate.replace(/-/g, '');

        axios({
            method: 'get',
            url: '/outsales/selectOutsalesMember',
            params: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.finalPrice - a.finalPrice);
                setData(sortedData);
            })
            .catch((error) => {
                console.log("고객별 매출데이터 조회실패 : ", error)
            })
    })

    /** endregion */

    return (
        <div id="m-a-user-service-pg-container">
            <div className="m-a-title-frame">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">고객별서비스현황</p>
            </div>
            <div className="m-a-card display-flex flex-column">
                <div className="m-a-event-frame">
                    {/*<UserState/>*/}
                    <div className="m-a-header-event display-flex flex-row just-cont-sb">
                        <div className="m-a-header-event-combo">
                            <div className="m-a-combo font-t-cont font-c-default wd-55 ht-40 font-s-14 m-a-flex-jc-start m-r-10" onClick={togglePopup}>
                                <div className="font-t-cont">{perPg}</div>
                            </div>
                            {popupVisible && (
                                <MAdminPageSmallEvent perPg={perPg} handleSelectChange={handleSelectChange} togglePopup={togglePopup} />
                            )}
                            <MAdminYmdSmallEvent startDate={startDate} endDate={endDate} startHandle={startHandle} endHandle={endHandle}/>
                        </div>
                    </div>
                </div>
                <div className="m-a-tb-frame">
                    <p className="font-s-13 font-t-cont font-c-default">(단위:만원)</p>
                    <div className="m-a-div-tb">
                        <div className="m-a-div-tb-head ht-60">
                            {/*<div className="wd-40">*/}
                            {/*    <input className="m-a-admin-input-chkBox m-0-a" type="checkbox" checked={checked.length === data.length} onChange={allcheckBoxChange}/>*/}
                            {/*</div>*/}
                            <div className="wd-120"><p>고객명</p></div>
                            <div className="wd-100"><p>장바구니</p></div>
                            <div className="wd-100"><p>구매대행</p></div>
                            <div className="wd-100"><p>오프라인</p></div>
                            <div className="wd-100"><p>이체대행</p></div>
                            <div className="wd-100"><p>국내(구매)</p></div>
                            <div className="wd-100"><p>수수료</p></div>
                            <div className="wd-100"><p>기타</p></div>
                            <div className="wd-100"><p>EMS</p></div>
                            <div className="wd-100"><p>FedEx</p></div>
                            <div className="wd-100"><p>선편</p></div>
                            <div className="wd-100"><p>국내</p></div>
                            <div className="wd-100"><p>예치금</p></div>
                            <div className="wd-100"><p>적립금</p></div>
                            <div className="wd-100"><p>쿠폰</p></div>
                            <div className="wd-120"><p>전체(예적쿠제외)</p></div>
                        </div>
                        {data.slice(0, displayLimit).map((cData, i) => (
                            <div key={i} className="m-a-div-tb-body ht-70">
                                {/*<div className="wd-40">*/}
                                {/*    <input className="m-a-admin-input-chkBox m-0-a" type="checkbox" name={cData.seqNum} checked={checked.includes(cData.seqNum)}*/}
                                {/*           onChange={() => checkBoxChange(cData.seqNum)}/>*/}
                                {/*</div>*/}
                                <div className="wd-120"><p className="font-b">{cData.memberDTO.userName}</p></div>
                                <div className="wd-120"><p className="font-b">{(cData.cartPrice / 10000).toFixed(1)}</p></div>
                                <div className="wd-100"><p className="font-b">{(cData.ordPrice / 10000).toFixed(1)}</p></div>
                                <div className="wd-100"><p className="font-b">{(cData.offPrice / 10000).toFixed(1)}</p></div>
                                <div className="wd-100"><p className="font-b">{(cData.trPrice / 10000).toFixed(1)}</p></div>
                                <div className="wd-100"><p className="font-b">{(cData.domPrice / 10000).toFixed(1)}</p></div>
                                <div className="wd-100"><p className="font-b">{(cData.ttEtcFee / 10000).toFixed(1)}</p></div>
                                <div className="wd-100"><p className="font-b">{(cData.ttEtcPrice / 10000).toFixed(1)}</p></div>
                                <div className="wd-100"><p className="font-b">{(cData.ttEmsPrice / 10000).toFixed(1)}</p></div>
                                <div className="wd-100"><p className="font-b">{(cData.ttFedexPrice / 10000).toFixed(1)}</p></div>
                                <div className="wd-100"><p className="font-b">{(cData.ttSeaPrice / 10000).toFixed(1)}</p></div>
                                <div className="wd-100"><p className="font-b">{(cData.ttDomPrice / 10000).toFixed(1)}</p></div>
                                <div className="wd-100"><p className="font-b">{(cData.useDeposit / 10000).toFixed(1)}</p></div>
                                <div className="wd-100"><p className="font-b">{(cData.usePoint / 10000).toFixed(1)}</p></div>
                                <div className="wd-100"><p className="font-b">{(cData.useCoupon / 10000).toFixed(1)}</p></div>
                                <div className="wd-120"><p className="font-b">{(cData.finalPrice / 10000).toFixed(1)}</p></div>
                            </div>
                        ))}
                    </div>
                    {displayLimit >= 30 && data.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default MAdminUserStatusPg
