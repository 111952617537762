import React, {useRef, useState} from "react";
import '../../css/mobile/MAdminJoinPg.css';
import back from "../../assets/img/commonBack.png";
import btorageLogo from "../../assets/img/HeaderLogo.png";
import pwHide from "../../assets/img/pwHide.png"
import pwView from "../../assets/img/pwView.png"
import {useNavigate} from "react-router-dom";
import axios from "axios";
import MMsgCheckModal from "../../components/mobile/MMsgCheckModal";


function MAdminJoinPg() {

    const navigate = useNavigate()

    const [num, setNum] = useState('');
    const phoneRef = useRef();

    const [missingFields, setMissingFields] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [birthday, setBirthDay] = useState("");
    const [name, setName] = useState("");
    const [nickname, setNickName] = useState("");
    const [id, setId] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [birthdayError, setBirthDayError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [nicknameError, setNickNameError] = useState(false);
    const [idError, setIdError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);

    const onChangeBirthDay = (e) => {
        const userBirthDayRegex = /^[0-9]{6}$/;
        if ((!e.target.value || (userBirthDayRegex.test(e.target.value)))) setBirthDayError(false);
        else setBirthDayError(true);
        setBirthDay(e.target.value);
    };
    const onChangeName = (e) => {
        const userNameRegex = /[ㄱ-힣]{2,5}/;
        if ((!e.target.value || (userNameRegex.test(e.target.value)))) setNameError(false);
        else setNameError(true);
        setName(e.target.value);
    };
    const onChangeNickName = async (e) => {
        const userNickNameRegex = /^[a-zA-Zㄱ-힣][a-zA-Zㄱ-힣 ]*$/;
        if ((!e.target.value || (userNickNameRegex.test(e.target.value)))) setNickNameError(false);
        else setNickNameError(true);

        const newNickname = e.target.value;
        setNickName(newNickname);

        if (newNickname.length < 2 || newNickname.length > 5) {
            setNickNameError('닉네임은 2~5자 입니다.');
            return;
        }

        try {
            const { data } = await axios.get('/member/validateNickname', {
                params: {
                    nickname: newNickname,
                    id: id
                }
            });
            if (data.isDuplicate) {
                setNickNameError("'" + newNickname + "'" + "는(은) 이미 사용중입니다.");
            } else {
                setNickNameError('');
            }
        } catch (error) {
            console.error('Error: ', error);
            setNickNameError('닉네임 Err');
        }
    };
    const onChangeId = (e) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const value = e.target.value;

        if (!value || (emailRegex.test(value) && value.length >= 11 && value.length <= 25)) {
            setIdError(false);
        } else {
            setIdError(true);
        }
        setId(value);
    };
    const onChangePassword = (e) => {
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()])(?=.*[0-9]).{8,20}$/;
        if ((!e.target.value || (passwordRegex.test(e.target.value)))) setPasswordError(false);
        else setPasswordError(true);

        if (!confirmPassword || e.target.value === confirmPassword) setConfirmPasswordError(false);
        else setConfirmPasswordError(true);
        setPassword(e.target.value);
    };
    const onChangeConfirmPassword = (e) => {
        if (password === e.target.value) setConfirmPasswordError(false);
        else setConfirmPasswordError(true);
        setConfirmPassword(e.target.value);
    };

    const validation = () => {
        if (!name) setIdError(true);
        if (!birthday) setIdError(true);
        if (!id) setIdError(true);
        if (!password) setPasswordError(true);
        if (!confirmPassword) setConfirmPasswordError(true);
        return !!(birthday && name && id && password && confirmPassword);
    }

    const phone = (e) => {
        const value = phoneRef.current.value.replace(/\D+/g, "");
        const numberLength = 11;
        let result;
        result = "";

        for (let i = 0; i < value.length && i < numberLength; i++) {
            switch (i) {
                case 3:
                    result += "-";
                    break;
                case 7:
                    result += "-";
                    break;

                default:
                    break;
            }
            result += value[i];
        }
        phoneRef.current.value = result;
        setNum(e.target.value);
    };

    const [passwordEncryption, setPasswordEncryption] = useState(true);
    const togglePassword = (() => {
        setPasswordEncryption(!passwordEncryption);
    });

    const [confirmPasswordEncryption, setConfirmPasswordEncryption] = useState(true);
    const toggleConfirmPassword = (() => {
        setConfirmPasswordEncryption(!confirmPasswordEncryption);
    });

    const validAndSave = () => {
        validation();
        joinDbSave();
    }

    /** endregion */

    /** region Event */

    const regiWaitingFn = (() => {
        navigate("/btcorpad-login")
    })

    const [regiWaitingSt, setRegiWaitingSt] = useState(false);
    const regiWaitingMsg = "회원가입 신청완료. 관리자 승인 후에 로그인이 가능합니다."

    const lang = "관리자"
    const userType = "1"
    const mailRecFl = "Y"
    const joinVerFl = "N"
    const userOutFl = "N"
    const role = "1"
    const socialJoinFl = false;

    const joinDbSave = () => {
        setIsLoading(true);
        try {
            const missing = [];
            if (!id) missing.push("계정");
            if (!password) missing.push("비밀번호");
            if (!confirmPassword) missing.push("비밀번호확인");
            if (!name) missing.push("이름");
            if (!nickname) missing.push("닉네임");
            if (!birthday) missing.push("생년월일");
            if (!num) missing.push("연락처");

            if (missing.length > 0) {
                setMissingFields(missing);
                return;
            }

            axios.post("/member/join", {
                id: id,
                password: password,
                passwordConfirm: confirmPassword,
                userName: name,
                lang: lang,
                userType: userType,
                mailRecFl: mailRecFl,
                joinVerFl: joinVerFl,
                userOutFl: userOutFl,
                role: role,
                phone: num,
                nicName: nickname,
                byMd: birthday,
                socialJoinFl: socialJoinFl
            })
                .then(() => {
                    setRegiWaitingSt(true);
                })
                .catch((error) => {
                    console.error(error);
                    setIsLoading(false);
                });
        } catch {
            setIsLoading(false);
        }
    }

    /** endregion */

    return(
        <div id="m-a-login-pg-container">
            <div className="m-a-common-header">
                <img onClick={()=> navigate("/btcorpad-login")} className="m-a-backIcon" src={back} alt="back"/>
                <div className="m-a-pg-header-text font-t-head">회원가입</div>
            </div>
            <div className="m-a-fixed-container">
                <div className="m-a-login-container">
                    <div className="m-a-idpw-container m-t-40">
                        <div className="m-a-input-frame ht-45 m-a-input-frame-mgb5 wd-100pc">
                            <input type="text"
                                   className="m-a-input font-t-cont"
                                   placeholder="이메일"
                                   value={id}
                                   onChange={onChangeId}
                                   minLength={11} maxLength={25}
                            />
                        </div>
                        <div className="m-a-input-frame ht-45 m-a-input-frame-mgb5 wd-100pc">
                            <input type={passwordEncryption ? "password" : "text"}
                                   className="m-a-input font-t-cont"
                                   placeholder="비밀번호"
                                   value={password}
                                   onChange={onChangePassword}
                                   minLength={8} maxLength={20}
                            />
                            { passwordEncryption ?
                                <img onClick={togglePassword} className="m-a-pw-HideFl-icon" src={pwHide} alt="pwHide"/>
                                :
                                <img onClick={togglePassword} className="m-a-pw-HideFl-icon" src={pwView} alt="pwView"/>
                            }
                        </div>
                        <div className="m-a-input-frame ht-45 m-a-input-frame-mgb5 wd-100pc">
                            <input type={confirmPasswordEncryption ? "password" : "text"}
                                   className="m-a-input font-t-cont"
                                   placeholder="비밀번호확인"
                                   value={confirmPassword}
                                   onChange={onChangeConfirmPassword}
                                   minLength={8} maxLength={20}
                            />
                            { confirmPasswordEncryption ?
                                <img onClick={toggleConfirmPassword} className="m-a-pw-HideFl-icon" src={pwHide} alt="pwHide"/>
                                :
                                <img onClick={toggleConfirmPassword} className="m-a-pw-HideFl-icon" src={pwView} alt="pwView"/>
                            }
                        </div>
                        <div className="m-a-input-frame ht-45 m-a-input-frame-mgb5 wd-100pc">
                            <input type="text"
                                   className="m-a-input font-t-cont"
                                   placeholder="이름(실명)"
                                   value={name} onChange={onChangeName}
                                   minLength={2} maxLength={5}
                            />
                        </div>
                        <div className="m-a-input-frame ht-45 m-a-input-frame-mgb5 wd-100pc">
                            <input type="text"
                                   className="m-a-input font-t-cont"
                                   placeholder="닉네임"
                                   value={nickname}
                                   onChange={onChangeNickName}
                                   minLength={2} maxLength={5}
                            />
                        </div>
                        <div className="m-a-input-frame ht-45 m-a-input-frame-mgb5 wd-100pc">
                            <input type="text"
                                   className="m-a-input font-t-cont"
                                   placeholder="생년월일(6자리숫자)"
                                   value={birthday} onChange={onChangeBirthDay}
                                   minLength={6} maxLength={6}
                            />
                        </div>
                        <div className="m-a-input-frame ht-45 wd-100pc m-b-5">
                            <input type="tel"
                                   className="m-a-input font-t-cont"
                                   placeholder="연락처(숫자만입력)"
                                   name={num} ref={phoneRef} onChange={phone}
                            />
                        </div>
                    </div>
                    {nameError ?
                        <div className="m-a-error-msg-container">
                            <div className="m-a-error-msg font-t-cont font-s-14"><p className="font-c-accent">이름을 확인해주세요</p></div>
                        </div>
                        :
                        null
                    }
                    {nicknameError ?
                        <div className="m-a-error-msg-container">
                            <div className="m-a-error-msg font-t-cont font-s-14"><p className="font-c-accent">닉네임을 확인해주세요</p></div>
                        </div>
                        :
                        null
                    }
                    {birthdayError ?
                        <div className="m-a-error-msg-container">
                            <div className="m-a-error-msg font-t-cont font-s-14"><p className="font-c-accent">생년월일을 확인해주세요</p></div>
                        </div>
                        :
                        null
                    }
                    {passwordError || confirmPasswordError ?
                        <div className="m-a-error-msg-container">
                            <div className="m-a-error-msg font-t-cont font-s-14"><p className="font-c-accent">아이디 또는 패스워드를 확인해주세요</p></div>
                        </div>
                        :
                        null
                    }
                    {missingFields.length > 0 ?
                        <div className="m-a-error-msg-container">
                            <div className="m-a-error-msg font-t-cont font-s-14"><p className="font-c-accent">모든 필드의 입력란은 필수입니다</p></div>
                        </div>
                        :
                        null
                    }
                    <div className="m-a-colorBt-container m-t-10">
                        <button onClick={validAndSave} className="m-a-colorBt ht-45 m-b-5 wd-100pc">
                            <style>
                                {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
                            </style>
                            {isLoading ? (
                                <div className="spinner"></div>
                            ) : (
                                <div className="m-a-colorBt-text font-t-cont">회원가입</div>
                            )}
                        </button>
                    </div>
                    {regiWaitingSt ? <MMsgCheckModal regiWaitingFn={regiWaitingFn} regiWaitingSt={regiWaitingSt} regiWaitingMsg={regiWaitingMsg}/> : null}
                </div>
            </div>
        </div>
    )
}

export default MAdminJoinPg
