import React from "react";
import commonClose from "../../../assets/img/commonClose.png";
import commonGuide from "../../../assets/img/commonGuide.png";

function GuideModal({cartGuidePopup, closeCartGuidePopup, cartGuideHeader, cartGuideContent , orderGuidePopup, closeOrderGuidePopup, orderGuideHeader, orderGuideContent,
                    offlineGuidePopup, closeOfflineGuidePopup, offlineGuideHeader, offlineGuideContent, transferGuidePopup, closeTransferGuidePopup, transferGuideHeader, transferGuideContent,
                    domesticGuidePopup, closeDomesticGuidePopup, domesticGuideHeader, domesticGuideContent, deliveryGuidePopup, closeDeliveryGuidePopup, deliveryGuideHeader, deliveryGuideContent}) {
    return(
        <div className="display-flex just-cont-center align-center position-fixed wd-100pc ht-100pc" style={{top:"0", left:"0", background:"rgba(0, 0, 0, 0.3)"}}>
            <div style={{width:"500px", height:"350px", background:"white", boxShadow:"0 0 10px rgba(0, 0, 0, 0.5)", borderRadius:"6px"}}>
                <div style={{borderBottom:"solid 1px #EAEAEA", height:"20%", display:"flex", padding:"10px"}}>
                    <div className="ht-100pc wd-10pc display-flex just-cont-center align-center">
                        <img className="wd-30 ht-30" alt='' src={commonGuide}/>
                    </div>
                    <div style={{height:"100%", width:"80%", display:"flex", alignItems:"center"}}>
                        <p className="font-t-title font-s-17">
                            {cartGuidePopup ? cartGuideHeader : orderGuidePopup ? orderGuideHeader : offlineGuidePopup ? offlineGuideHeader : transferGuidePopup ? transferGuideHeader :
                            domesticGuidePopup ? domesticGuideHeader : deliveryGuidePopup ? deliveryGuideHeader : null}
                        </p>
                    </div>
                    <div className="user-center" style={{width:"10%", height:"100%"}}>
                        <img onClick={cartGuidePopup ? closeCartGuidePopup : orderGuidePopup ? closeOrderGuidePopup : offlineGuidePopup ? closeOfflineGuidePopup :
                            transferGuidePopup ? closeTransferGuidePopup : domesticGuidePopup ? closeDomesticGuidePopup : deliveryGuidePopup ? closeDeliveryGuidePopup : null} style={{
                            width:"30px", height:"30px", opacity:"0.7", cursor:"pointer"}} src={commonClose} alt="close"/>
                    </div>
                </div>
                <div className="font-t-cont font-s-16" style={{height:"80%", padding:"20px"}}>
                    <p>{cartGuidePopup ? "비토리지는 고객님의 한국 쇼핑몰 계정으로 로그인하여"
                        : orderGuidePopup ? "비토리지는 고객님이 입력한 상품 URL을 통해"
                            : domesticGuidePopup ? "구매하고 싶은 상품들을 주문서로 작성해주시면"
                                : null}</p>
                    <p className="m-b-15">{cartGuidePopup ? "장바구니의 상품을 구매하고 해외로 배송해드리는 서비스입니다."
                                            : orderGuidePopup ? "한국 쇼핑몰에서 주문하고 해외로 배송해드리는 서비스입니다."
                                                : domesticGuidePopup ? "비토리지 계정으로 구매 후, 한국(국내)로 배송해드리는 서비스입니다."
                                                    : null}</p>
                    <p>{cartGuidePopup ? "1. 구매하고 싶은 상품을 한국 쇼핑몰 계정의 장바구니에 담아주세요."
                        : orderGuidePopup ? "1. 구매하고 싶은 한국 상품의 URL을 입력하세요."
                            : null}</p>
                    <p className="m-b-15">{cartGuidePopup ? "2. 쇼핑몰 계정 정보를 입력하여 주문서를 작성하세요."
                                            : orderGuidePopup ? "2. 비토리지 측의 쇼핑몰 계정으로 주문을 진행합니다."
                                                : null}</p>
                    <p>{cartGuidePopup ? "※ 반드시 구매하려는 쇼핑몰 계정이 있어야 하며, 상품 정보를 일일이 작성할 필요가 없어 상품 항목이 많을수록 편리합니다."
                        : orderGuidePopup ? "※ 한국 쇼핑몰 계정이 없어도 원하는 상품을 모두 구매할 수 있습니다."
                            : domesticGuidePopup ? "※ 해외로는 발송하지 않으며, 수수료과 부과됩니다."
                                : null}</p>
                    <p className="font-t-cont font-s-16">
                        {offlineGuidePopup ? offlineGuideContent
                                    : transferGuidePopup ? transferGuideContent
                                            : deliveryGuidePopup ? deliveryGuideContent : null}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default GuideModal
