import "../../css/mobile/MSideQuickMenu.css"
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import MainChat from '../../assets/img/mainChat.png';
import MainChatCs from "../../assets/img/mainChatCs.png";
import MainChatLine from "../../assets/img/mainChatLine.png";
import MainChatInstagram from "../../assets/img/mainChatInstagram.png";
import MainChatKakao from "../../assets/img/mainChatKakao.png";
import MainChatWechat from "../../assets/img/MainChatWechat.png";
import MainChatMail from "../../assets/img/mainChatMail.png";
import {useState} from "react";

const MSideQuickMenu = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [openNonUserChat, setOpenNonUserChat] = useState(false);

    const openLink = (url) => {
        window.open(url, "_target");
        setIsOpen(false);
    }

    const moveToMailPg = () => {
        navigate("/UserMailInquiryPg");
    };

    return (
        <div className={'m-a-side-quick-menu-container'}>
            <div className={'m-a-side-quick-menu-default'}
                 onClick={(e) => {
                     setIsOpen(!isOpen);
                     e.stopPropagation();
                 }}>
                <img className={'m-a-side-quick-menu-img'} src={MainChat} alt={''}/>
            </div>
            {isOpen && (
                <div className={'m-a-side-quick-menu-open'}>
                    <img className={'m-a-side-quick-menu-item-img'} src={MainChatCs} alt={''}
                         onClick={() => {
                             navigate("/NonUserChatPg");
                         }}/>
                    <img className={'m-a-side-quick-menu-item-img'} src={MainChatLine} alt={''}
                         onClick={() => openLink('https://line.me/ko/')}/>
                    <img className={'m-a-side-quick-menu-item-img'} src={MainChatInstagram} alt={''}
                         onClick={() => openLink('https://www.instagram.com/lfamily0212/')}/>
                    <img className={'m-a-side-quick-menu-item-img'} src={MainChatKakao} alt={''}
                         onClick={() => openLink('https://pf.kakao.com/_zTxllC/chat')}/>
                    <img className={'m-a-side-quick-menu-item-img'} src={MainChatWechat} alt={''}
                         onClick={() => openLink('https://www.wechat.com/ko/')}/>
                    <img className={'m-a-side-quick-menu-item-img'} src={MainChatMail} alt={''}
                         onClick={moveToMailPg}/>
                </div>
            )}
        </div>
    )
}

export default MSideQuickMenu;