import React, {useEffect, useRef, useState} from 'react';
import {BsSquareFill} from "react-icons/bs";
import {Table} from "react-bootstrap";
import MsgSelectModal from "./MsgSelectModal";
import axios from "axios";
import {connect} from "../../../services/WebsocketService";
import {useWebSocket} from "../../../services/WebsocketContext";

function AdminDivideModal() {

    const LCAT = localStorage.getItem('adminToken');

    const [adminInfo, setAdminInfo] = useState([]);

    const selectAdminInfo = (() => {
        axios({
            method: 'get',
            url: '/member/modiInfo',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data[0])
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        selectAdminInfo();
    }, [])


    const stompClientRef = useRef(null);
    const { subscribe, unsubscribe, websocketConnect, isConnected, stompClient } = useWebSocket();
    useEffect(() => {
        if (!adminInfo && !adminInfo.mbNum) return;
        const initializeSubscription = async () => {
            if (!isConnected || !stompClient) {
                stompClientRef.current = await websocketConnect(adminInfo.mbNum);
            } else {
                stompClientRef.current = stompClient;
            }
        };
        initializeSubscription();
        return () => {
            if (stompClientRef.current && stompClientRef.current.length > 0) {
                // 구독이 존재하는 경우에만 해제
                stompClientRef.current.forEach((sub) => {
                    unsubscribe(sub);
                });
            }
            stompClientRef.current = null;
        };
    }, [adminInfo, isConnected, subscribe, unsubscribe, stompClient]);


    const cData = JSON.parse(localStorage.getItem('orderData'));
    const closeDivideModal = (() => {
        window.close();
    })

    // 기준주문서 상품총수량
    const totalQuantity = cData.productsDTO.reduce((sum, item) => {
        if (item.prodSt !== "IS12") {
            return sum + item.quantity;
        }
        return sum;
    }, 0);

    const [totalProdList, setTotalProdList] = useState([])
    const [divideProdList, setDivideProdList] = useState([]);
    const [userName, setUserName] = useState('');
    const selectProdList = (() => {
        axios({
            method: 'get',
            url: '/order/selectProdList',
            params: {
                orderNumber: cData.orderNumber
            }
        })
            .then((response) => {
                let sortedData = response.data.sort((a, b) => b.seqnum - a.seqnum);
                setTotalProdList(sortedData);
                setUserName(response.data[0].regNm);
            })
            .catch(() => {})
    })

    useEffect(() => {
        selectProdList();
    }, [])

    const [isAllChecked, setIsAllChecked] = useState(false);

    const handleAllCheck = (event) => {
        if (event.target.checked) {
            const allSeqNums = totalProdList.map(prod => prod.seqnum);
            setDivideProdList(allSeqNums);
        } else {
            setDivideProdList([]);
        }
        setIsAllChecked(event.target.checked);
    };

    const toggleProdSelection = (seqnum) => {
        setDivideProdList(prev => {
            const newDivideProdList = prev.includes(seqnum) ? prev.filter(num => num !== seqnum) : [...prev, seqnum];
            setIsAllChecked(newDivideProdList.length === totalProdList.length);
            return newDivideProdList;
        });
    };

    const orderDivide = (() => {
        let divideProdQueryString = divideProdList.map(item => 'divideProd=' + encodeURIComponent(item)).join('&');

        axios({
            method: 'post',
            url: `/order/orderDivide?standardOrder=${encodeURIComponent(cData.seqnum)}&${divideProdQueryString}`,
            headers: {
                Authorization: LCAT
            },
        })
            .then(async () => {
                // 웹소켓 전송하여 사용자 마이페이지도 order 업뎃 ㄱ
                await stompClient.send("/app/orderDivideAndMerge", {}, JSON.stringify({userName:userName}));
                // 추후 여기에 AdminDashBoardPg.selectAllData() 조회함수 실행코드 추가

                closeDivideModal();
            });
    });

    const [divideModalSt, setDivideModalSt] = useState(false);
    const openDivideModalShow = (() => {
        if (divideProdList.length > 0) {
        setDivideModalSt(true);
        }
    })
    const closeDivideModalShow = (() => {
        setDivideModalSt(false);
    })
    const divideModalMsg1 = "분리작업을 시작하시겠습니까?"
    const divideModalMsg2 = "진행 후 되돌릴 수 없습니다"

    return (
        <div className="AdminDivideModal admin-modal-default">
            <div className="openModal regModal">
                    <section>
                        <header className="admin-work-title">
                            <b style={{color: "#ffffff"}}>주문서 분리</b>
                        </header>
                        <main>
                            <div className="admin-btn-wrap-l"><BsSquareFill className="admin-tb-header-notice"/><a className="font-t-cont font-s-15">분리 주문서</a></div>
                            <div className="div-tb m-b-30">
                                <div className="div-tb-head ht-60">
                                    <div className="wd-200"><p>주문번호</p></div>
                                    <div className="wd-150"><p>계정명</p></div>
                                    <div className="wd-150"><p>수취인명</p></div>
                                    <div className="wd-130"><p>국가</p></div>
                                    <div className="wd-70"><p>신고금액</p></div>
                                    <div className="wd-40"><p>수량</p></div>
                                </div>
                                <div className="div-tb-body ht-70">
                                    <div className="wd-200"><p className="font-b">{cData.orderNumber}</p></div>
                                    <div className="wd-150"><p className="font-b">{cData.memberDTO.userName}</p></div>
                                    <div className="wd-150"><p>{cData.recinfoDTO.recname}</p></div>
                                    <div className="wd-130"><p>{cData.recinfoDTO.countryDTO.countryNm}</p></div>
                                    <div className="wd-70"><p>null</p></div>
                                    <div className="wd-40"><p>{totalQuantity}</p></div>
                                </div>
                            </div>
                            <div className="admin-btn-wrap-l"><BsSquareFill className="admin-tb-header-notice"/><a className="font-t-cont font-s-15">분리 상품목록</a></div>
                            <div className="div-tb m-b-30">
                                <div className="div-tb-head ht-60">
                                    <div className="wd-40">
                                        <input className="admin-input-chkBox-s m-0-a" type="checkbox" checked={isAllChecked} onChange={handleAllCheck}/>
                                    </div>
                                    <div className="wd-40"><p>품번</p></div>
                                    <div className="wd-70"><p>사진</p></div>
                                    <div className="wd-200"><p>상품명</p></div>
                                    <div className="wd-100"><p>옵션</p></div>
                                    <div className="wd-40"><p>수량</p></div>
                                    <div className="wd-80"><p>상태구분</p></div>
                                </div>
                                {totalProdList.map((prod, i) => (
                                    <div key={i} className="div-tb-body ht-70">
                                        <div className="wd-40">
                                            <input className="admin-input-chkBox-s m-0-a" type="checkbox" onChange={() => toggleProdSelection(prod.seqnum)}
                                                   checked={divideProdList.includes(prod.seqnum)}/>
                                        </div>
                                        <div className="wd-40"><p>{i+1}</p></div>
                                        <div className="wd-70"><p>이미지</p></div>
                                        <div className="wd-200"><p>{prod.name}</p></div>
                                        <div className="wd-100"><p>{prod.option}</p></div>
                                        <div className="wd-40"><p>{prod.quantity}</p></div>
                                        <div className="wd-80">
                                            <p>
                                                {prod?.prodSt === "IS00" ? (
                                                    <p style={{margin:"0", color: "#98B0D4", fontSize:"13px"}}>입고대기</p>
                                                ) : prod?.prodSt === "IS01" ? (
                                                    <p style={{margin:"0", color: "#98B0D4", fontSize:"13px"}}>결제대기(상품)</p>
                                                ) : prod?.prodSt === "IS02" ? (
                                                    <p style={{margin:"0", color: "#98B0D4", fontSize:"13px"}}>결제중(상품)</p>
                                                ) : prod?.prodSt === "IS03" ? (
                                                    <p style={{margin:"0", color: "#FFB378", fontSize:"13px"}}>결제완료(상품)</p>
                                                ) : prod?.prodSt === "IS04" ? (
                                                    <p style={{margin:"0", color: "#FF8600", fontSize:"13px"}}>구매완료</p>
                                                ) : prod?.prodSt === "IS05" ? (
                                                    <p style={{margin:"0", color: "#6EDA60", fontSize:"13px"}}>센터도착</p>
                                                ) : prod?.prodSt === "IS06" ? (
                                                    <p style={{margin:"0", color: "#98B0D4", fontSize:"13px"}}>패킹중</p>
                                                ) : prod?.prodSt === "IS07" ? (
                                                    <p style={{margin:"0", color: "#98B0D4", fontSize:"13px"}}>결제대기(배송)</p>
                                                ) : prod?.prodSt === "IS08" ? (
                                                    <p style={{margin:"0", color: "#98B0D4", fontSize:"13px"}}>결제중(배송)</p>
                                                ) : prod?.prodSt === "IS09" ? (
                                                    <p style={{margin:"0", color: "#FFB378", fontSize:"13px"}}>결제완료(배송)</p>
                                                ) : prod?.prodSt === "IS10" ? (
                                                    <p style={{margin:"0", color: "#979696", fontSize:"13px"}}>출고완료</p>
                                                ) : prod?.prodSt === "IS11" ? (
                                                    <p style={{margin:"0", color: "#FF8F62", fontSize:"13px"}}>반송중</p>
                                                ) : prod?.prodSt === "IS12" ? (
                                                    <p style={{margin:"0", color: "#FF8F62", fontSize:"13px"}}>신청취소</p>
                                                ) : null}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </main>
                        <footer>
                            <button onClick={closeDivideModal} className="admin-bt-color admin-btn-long" style={{marginRight: "30px"}}>취소</button>
                            <button onClick={openDivideModalShow} className="admin-bt-color admin-btn-long">분리</button>
                        </footer>
                    </section>
            </div>
            { divideModalSt ?
                <MsgSelectModal divideModalSt={divideModalSt} orderDivide={orderDivide} closeDivideModalShow={closeDivideModalShow}
                                divideModalMsg1={divideModalMsg1} divideModalMsg2={divideModalMsg2}/>
                :
                null
            }
        </div>
    );
}

export default AdminDivideModal
