import React from 'react';
import {IoSearchOutline} from "react-icons/io5";

function MAdminSearchEvent({ searchInput, handleSearchInputChange }) {
    return (
        <div className="display-flex">
            <input className="m-a-search" type="text" placeholder="Search" value={searchInput} onChange={handleSearchInputChange} maxLength="20"/>
            <IoSearchOutline className="font-c-default font-s-24 m-t-7" style={{position: "absolute", right: "35px"}}/>
        </div>
    );
}

export default MAdminSearchEvent
