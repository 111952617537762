import React, {useCallback, useEffect, useState} from 'react';
import axios from "axios";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import {MdKeyboardArrowDown} from "react-icons/md";
import TopBtn from "../../components/web/layoutsadmin/TopBtn";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import AdminSearchEvent from "../../components/web/layoutsadmin/AdminSearchEvent";
import maCateImg from "../../assets/img/m-a-cate.png";

function AdminCountryInfoPg() {

    /** region DataTable */

    const [data, setData] = useState([]);
    const [fedexFuelValue, setFedexFuelValue] = useState("");
    const LCAT = localStorage.getItem('adminToken');

    /** endregion */

    /** region Function */

    //region Search

    const [searchInput, setSearchInput] = useState('');
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const searchData = data.filter((cData) => {
        const searchLower = searchInput.toLowerCase();
        return (
            cData.countryNm.toLowerCase().includes(searchLower) ||
            // cData.countryEngNm.toLowerCase().includes(searchLower) ||
            cData.countryCode.toLowerCase().includes(searchLower)
        );
    });

    //endregion

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(20);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [searchData.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
            if (searchData.length > displayLimit && displayLimit < 40) {
                setDisplayLimit(prevLimit => prevLimit + perPg);
            }
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(20);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e.target.value);
        setperPg(newPerPage);
        // setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    useEffect(() => {
        setDisplayLimit(perPg);
    }, [perPg]);

    // endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);

    // region 체크박스 기존 UI에 보여지는것만 체크

    // const checkBoxChange = (id) => {
    //     setChecked(prevSelectedData => {
    //         if (prevSelectedData.includes(id)) {
    //             return prevSelectedData.filter(item => item !== id);
    //         } else {
    //             return [...prevSelectedData, id];
    //         }
    //     });
    // };
    //
    // const allCheckBoxChange = (e) => {
    //     let visibleData = data.slice(0, displayLimit);
    //     if (e.target.checked) {
    //         const visibleIds = visibleData.map(item => item.seqNum);
    //         setChecked(visibleIds);
    //     } else {
    //         setChecked([]);
    //     }
    // };

    //endregion

    const isAllChecked = data.length > 0 && checked.length === data.length;

    const checkBoxChange = (id) => {
        setChecked(prevSelectedData => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter(item => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    const allCheckBoxChange = (e) => {
        if (e.target.checked) {
            setChecked(data.map(item => item.seqNum));
        } else {
            setChecked([]);
        }
    };

    //endregion

    // region Alert

    const [alertShow, setAlertShow] = useState(false);
    const msgConfirmData1 = "※ FedEx 배송료가 변경됩니다. 변경하시겠습니까? ※";
    const msgConfirmData2 = "입력한 정보를 한번 더 확인해주세요.";
    const showAlert = (() => {
        setAlertShow(true)
    })

    const modalCancel = useCallback(() => {
        setAlertShow(false);
    })

    // endregion

    const [editMode, setEditMode] = useState(null);
    const [editedFees, setEditedFees] = useState({});

    const handleEditClick = (cData) => {
        setEditMode(cData.seqNum);
        setEditedFees({
            emsFee: cData.emsFee,
            fedexFee: cData.fedexFee,
            seaFee: cData.seaFee,
            emsPFee: cData.emsPFee,
            kpackFee: cData.kpackFee,
            airSFee: cData.airSFee,
        });
    };

    const handleFeeChange = (e, feeType) => {
        setEditedFees(prevFees => ({
            ...prevFees,
            [feeType]: e.target.value
        }));
    };

    /** endregion */

    /** region Event */

    useEffect(() => {
        select();
    }, [])

    const select = () => {
        axios.get('/country/countryselect')
            .then((response) => {
                setData(response.data)
                setFedexFuelValue();
                const fedExFuel = response.data.find(item => item.fedexFuelSurcharge !== undefined);
                if (fedExFuel) {
                    setFedexFuelValue(fedExFuel.fedexFuelSurcharge.toString());
                }
            }, [])
            .catch()
    }

    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            showAlert();
        }
    };

    const countryFedexFuelModi = () => {
        const fuelModifyValue = {
            fedexFuelSurcharge: parseFloat(fedexFuelValue)
        };

        axios.post('/country/fedexFuelModi', fuelModifyValue, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LCAT,
            }
        })
            .then(() => {
                setAlertShow(false);
                setFedexFuelValue();
            })
            .catch(error => {
                console.error("Err:", error);
            });
    };

    const handleSaveClick = (seqNum) => {
        const countryData = data.find(item => item.seqNum === seqNum);

        const { fedexFee, emsFee, emsPFee, seaFee, kpackFee, airSFee } = editedFees;

        const feesToSubmit = {
            seqNum,
            countryCode: countryData.countryCode,
            countryNm: countryData.countryNm,
            countryEngNm: countryData.countryEngNm,
            fedexFee: editedFees.fedexFee || 0,
            emsFee: editedFees.emsFee || 0,
            emsPFee: editedFees.emsPFee || 0,
            seaFee: editedFees.seaFee || 0,
            kpackFee: editedFees.kpackFee || 0,
            airSFee: editedFees.airSFee || 0,
        };

        axios.post('/country/updateFees', feesToSubmit, {
            headers: {
                'Authorization': LCAT
            }
        })
            .then(() => {
                setData(prevData => prevData.map(item =>
                    item.seqNum === seqNum ? { ...item, ...editedFees } : item
                ));
                setEditMode(null);
            })
            .catch(error => console.error("err", error));
    };

    // region Excel

    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const DateViewValue = year + month + day;

    const excelDown = () => {
        try {
            const selectedRows = data.filter((item) => checked.includes(item.seqNum));

            const excel = require('exceljs');
            const workbook = new excel.Workbook();
            workbook.created = new Date();
            workbook.modified = new Date();

            workbook.addWorksheet('Data');
            const sheetOne = workbook.getWorksheet('Data');
            sheetOne.getRow(1).font = {
                name: '맑은 고딕',
                bold: true,
                size: 11
            };
            sheetOne.getRow(1).alignment = {
                horizontal: 'center',
                vertical: 'middle'
            };
            sheetOne.getRow(1).height = 30;
            sheetOne.columns = [
                {header: '번호', key: 'seqNum', hidden:false, height: 15,  width: 7, style: { alignment: { horizontal: 'center' }}},
                {header: '국가명', key: 'countryNm', width: 20, style: { alignment: { horizontal: 'center' }}},
                {header: '국가명(Eng)', key: 'countryEngNm', width: 35, style: { alignment: { horizontal: 'center' }}},
                {header: '국가코드', key: 'countryCode', width: 12, style: { alignment: { horizontal: 'center' }}},
                {header: 'EMS수수료', key: 'emsFee', width: 13, style: { alignment: { horizontal: 'center' }}},
                {header: 'FedEx수수료', key: 'fedexFee', width: 13, style: { alignment: { horizontal: 'center' }}},
                {header: '선편수수료', key: 'seaFee', width: 13, style: { alignment: { horizontal: 'center' }}}
            ];

            selectedRows.map((item, index) => {
                sheetOne.addRow(item);

                for(let loop = 1; loop <= 8; loop++) {
                    const col = sheetOne.getRow(index + 2).getCell(loop);
                    col.font = {name: '맑은 고딕', size: 10};
                }
            });

            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = `국가정보_` + DateViewValue + `.xlsx`;
                anchor.click();
                window.URL.revokeObjectURL(url);
            })
        } catch(error) {
            console.error(error);
        }
    }

    // endregion

    /** endregion */

    return (
        <div className="AdminCountryInfoSetPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">국가정보</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        <div className="admin-event-wrap-btn">
                            <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                            <AdminSearchEvent searchInput={searchInput} handleSearchInputChange={handleSearchInputChange} />
                            <div className="m-t-3 m-l-5">
                                <a className="dark-font font-s-14">페덱스요율</a>
                                <input className="admin-input-line text-center wd-70 ht-27 m-l-5 font-b font-c-progress" type="text" maxLength="5" placeholder="%"
                                       value={fedexFuelValue} onChange={(e) => setFedexFuelValue(e.target.value)}
                                       onKeyUp={onKeyPress}/>
                                <label className="font-s-13 m-l-5" style={{color: "#c5c5c5"}}>변경 후 'Enter'</label>
                            </div>
                        </div>
                        <div className="admin-event-wrap-btn">
                            <button onClick={excelDown} className="admin-btn-long admin-bt-color">EXCEL</button>
                        </div>
                    </div>
                    <div className="text-right"><p className="font-s-12">수수료(100g/&#8361;)</p></div>
                    <div className="div-tb m-b-10">
                        <div className="div-tb-head ht-60">
                            <div style={{width: "40px"}}>
                                {/*<input className="admin-input-chkBox m-0-a" type="checkbox" checked={checked.length === data.length} onChange={allCheckBoxChange}/>*/}
                                <input className="admin-input-chkBox m-0-a" type="checkbox" checked={isAllChecked} onChange={allCheckBoxChange}/>
                            </div>
                            <div style={{width: "40px"}}><p>번호</p></div>
                            <div style={{width: "150px"}}><p>국가명</p></div>
                            <div style={{width: "150px"}}><p>국가명(EN)</p></div>
                            <div style={{width: "70px"}}><p>국가코드</p></div>
                            <div style={{width: "150px"}}><p>FedEx수수료</p></div>
                            <div style={{width: "150px"}}><p>UPS수수료</p></div>
                            <div style={{width: "150px"}}><p>EMS수수료</p></div>
                            <div style={{width: "150px"}}><p>선편수수료</p></div>
                            <div style={{width: "150px"}}><p>Kpack수수료</p></div>
                            <div style={{width: "150px"}}><p>항공소형수수료</p></div>
                            <div style={{width: "80px"}}><p></p></div>
                        </div>
                        {searchData.slice(0, displayLimit).map((cData, i) => (
                            <div key={i} className="div-tb-body ht-70">
                                <div style={{width: "40px"}}>
                                    {/*<input className="admin-input-chkBox m-0-a" type="checkbox" name={cData.seqNum} checked={checked.includes(cData.seqNum)}*/}
                                    {/*       onChange={() => checkBoxChange(cData.seqNum)}/>*/}
                                    <input key={cData.seqNum} className="admin-input-chkBox m-0-a" type="checkbox" checked={checked.includes(cData.seqNum)}
                                        onChange={() => checkBoxChange(cData.seqNum)}
                                    />
                                </div>
                                <div style={{width: "40px"}}><p>{i + 1}</p></div>
                                <div style={{width: "150px"}}><p className="font-b">{cData.countryNm}</p></div>
                                <div style={{width: "150px"}}><p>{cData.countryEngNm}</p></div>
                                <div style={{width: "70px"}}><p className="font-b">{cData.countryCode}</p></div>
                                <div style={{width: "150px"}}>
                                    {editMode === cData.seqNum ? (
                                        <input className="admin-input-line m-0-a font-b font-c-cancel wd-60pc ht-30 text-center font-s-13 com-input"
                                            type="text" value={editedFees.fedexFee} onChange={(e) => handleFeeChange(e, 'fedexFee')}/>
                                    ) : (
                                        <p style={{ color: cData.fedexFee !== 0 ? "#FFAF34" : "" }}>
                                            {cData.fedexFee.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                        </p>
                                    )}
                                </div>
                                <div style={{width: "150px"}}>
                                    {editMode === cData.seqNum ? (
                                        <input className="admin-input-line m-0-a font-b font-c-cancel wd-60pc ht-30 text-center font-s-13 com-input"
                                               type="text" value={editedFees.upsFee} onChange={(e) => handleFeeChange(e, 'upsFee')}/>
                                    ) : (
                                        <p style={{ color: cData.upsFee !== 0 ? "#FFAF34" : "" }}>
                                            {cData.upsFee.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                        </p>
                                    )}
                                </div>
                                <div style={{width: "150px"}}>
                                    {editMode === cData.seqNum ? (
                                        <input className="admin-input-line m-0-a font-b font-c-cancel wd-60pc ht-30 text-center font-s-13 com-input"
                                               type="text" value={editedFees.emsFee} onChange={(e) => handleFeeChange(e, 'emsFee')}/>
                                    ) : (
                                        <p style={{ color: cData.emsFee !== 0 ? "#FFAF34" : "" }}>
                                            {cData.emsFee.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                        </p>
                                    )}
                                </div>
                                <div style={{width: "150px"}}>
                                    {editMode === cData.seqNum ? (
                                        <input className="admin-input-line m-0-a font-b font-c-cancel wd-60pc ht-30 text-center font-s-13 com-input"
                                               type="text" value={editedFees.seaFee} onChange={(e) => handleFeeChange(e, 'seaFee')}/>
                                    ) : (
                                        <p style={{ color: cData.seaFee !== 0 ? "#FFAF34" : "" }}>
                                            {cData.seaFee.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                        </p>
                                    )}
                                </div>
                                <div style={{width: "150px"}}>
                                    {editMode === cData.seqNum ? (
                                        <input className="admin-input-line m-0-a font-b font-c-cancel wd-60pc ht-30 text-center font-s-13 com-input"
                                               type="text" value={editedFees.kpackFee} onChange={(e) => handleFeeChange(e, 'kpackFee')}/>
                                    ) : (
                                        <p style={{ color: cData.kpackFee !== 0 ? "#FFAF34" : "" }}>
                                            {cData.kpackFee.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                        </p>
                                    )}
                                </div>
                                <div style={{width: "150px"}}>
                                    {editMode === cData.seqNum ? (
                                        <input className="admin-input-line m-0-a font-b font-c-cancel wd-60pc ht-30 text-center font-s-13 com-input"
                                               type="text" value={editedFees.airSFee} onChange={(e) => handleFeeChange(e, 'airSFee')}/>
                                    ) : (
                                        <p style={{ color: cData.airSFee !== 0 ? "#FFAF34" : "" }}>
                                            {cData.airSFee.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                        </p>
                                    )}
                                </div>
                                <div style={{ width: "80px" }}>
                                    {editMode === cData.seqNum ? (
                                        <button className="admin-btn-basic admin-bt-color ht-30" onClick={() => handleSaveClick(cData.seqNum)}>저장</button>
                                    ) : (
                                        <button className="admin-btn-basic ht-30" onClick={() => handleEditClick(cData)}>수정</button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    {displayLimit >= 40 && data.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <TopBtn/>
            {alertShow === true ? <MsgSelectModal alertShow={alertShow} eventHandle1={modalCancel} eventHandle2={countryFedexFuelModi} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
        </div>
    );
}

export default AdminCountryInfoPg
