import axios from "axios";

export function loadPaypalScript(currency) {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: '/credentials/secretManager/paypalSandId', // 페이팔 Sandbox or live
            headers: {
                Authorization: `Bearer ${localStorage.getItem('AToken') || sessionStorage.getItem('AToken')}`
            }
        }).then((response) => {
            const clientId = response.data;
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=${currency}&locale=en_US`; // 클라이언트 ID를 사용하여 PayPal SDK 스크립트 URL 설정
            script.onload = () => resolve();
            document.body.appendChild(script);
        }).catch((error) => {
            console.error("Failed to load PayPal client ID:", error);
            reject(error); // 스크립트 로딩 실패 처리
        });
    });
}
