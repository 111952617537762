import React, {useEffect, useState} from "react";
import commonHeader from "../../assets/img/commonHeader.png";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import {useNavigate} from "react-router-dom";
import {BiCaretDown} from "react-icons/bi";
import {BsTrash} from "react-icons/bs";
import photoUpload from "../../assets/img/reviewUpload.png";
import axios from "axios";
import maCateImg from "../../assets/img/m-a-cate.png";
import MsgCheckModal from "../../components/web/modal/MsgCheckModal";
import commonClose from "../../assets/img/commonClose.png";

function AdminPopupWritePg() {

    const navigate = useNavigate();
    const LCAT = localStorage.getItem('adminToken');


    const [titleErrorMsg, setTitleErrorMsg] = useState(false);
    const [contentErrorMsg, setContentErrorMsg] = useState(false);

    const [titleText, setTitleText] = useState('');
    const handleChangeTitleText = (e) => {
        setTitleText(e.target.value);
    };


    const [photoContainer, setPhotoContainer] = useState([{ photoImg: photoUpload, photoImgSt: false, photoImgError: false }]);
    const photoImgHandle = (e) => {
        const file = e.target.files[0];

        if (file) {
            if (!file.type.startsWith('image/')) {
                setPhotoContainer({
                    ...photoContainer,
                    photoImgError: true,
                });
            } else {
                setPhotoContainer({
                    ...photoContainer,
                    photoImg: URL.createObjectURL(file),
                    photoImgSt: true,
                    photoImgError: false,
                    file: file
                });
            }
        }
    };

    const photoImgInit = () => {
        setPhotoContainer({ photoImg: photoUpload, photoImgSt: false, photoImgError: false });
    };

    const validation = () => {
        if (titleText === '' || !titleText) setTitleErrorMsg(true);
        return !!(titleText)
    }

    const [saveFailSt, setSaveFailSt] = useState(false);
    const closeSaveFailSt = (() => {
        setSaveFailSt(false);
    })
    const photoSave = () => {
        const newFormData = new FormData();
        newFormData.append('title', titleText);
        newFormData.append('photoImg', photoContainer.file);

        return axios({
            method: 'post',
            url: '/banner/saveBanner',
            data: newFormData,
            params: {
                type: '1'
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: LCAT
            },
        })
            .then((response) => {
                navigate("/btcorpad/adminPopup")
            }).catch(() => {
                setSaveFailSt(true);
            })
    }

    const valiAndSave = () => {
        if (validation()) {
            photoSave();
        }
    }

    useEffect(() => {
        if (titleText.length > 0) {
            setTitleErrorMsg(false);
        }
    }, [titleText])

    const moveToReturn = (() => {
        navigate("/btcorpad/adminPopup")
    })

    return(
        <div className="AdminNoticePg" style={{padding:"0 200px"}}>
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">팝업추가</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card font-t-cont">
                    <div style={{padding:"20px"}}>
                        <div>
                            <div style={{height: "10%", display: "flex"}}>
                                <div style={{width: "100%"}}>
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>

                                        <div style={{
                                            height: "40px",
                                            marginBottom: "20px",
                                            display: "flex",
                                            justifyContent: "flex-end"
                                        }}>
                                            <img onClick={moveToReturn} alt="close" style={{
                                                width: "35px",
                                                height: "35px",
                                                opacity: "0.6",
                                                cursor: "pointer"
                                            }} src={commonClose}/>
                                        </div>
                                    </div>
                                    <div className="ReviewPgSearchFrame acc-icon">
                                        <div className="ReviewPgInputForm" style={{width: "100%"}}>
                                            <input className="ReviewInput user-input"
                                                   style={{width: "100%", height: "100%", padding: "0 10px"}}
                                                   type="text"
                                                   placeholder="" value={titleText} onChange={handleChangeTitleText}
                                                   maxLength={60}/>
                                            <label className="JoinLabel"
                                                   style={{fontSize: "14px", color: "darkgray"}}>제목</label>
                                        </div>
                                    </div>
                                    <div className="JoinErrorMsg">
                                        {titleErrorMsg && <p className="JoinErrMsg">&nbsp;제목을 입력해주세요</p>}
                                    </div>
                                </div>
                            </div>
                            <div style={{height: "auto", display: "flex", marginTop: "20px"}}>
                                <div style={{width: "100%"}}>
                                    <div style={{
                                        border: "solid",
                                        height: "500px",
                                        borderWidth: "thin 1px",
                                        borderColor: "#EAEAEA",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        {!photoContainer.photoImgSt ?
                                            <div style={{display: "block", textAlign:"center", cursor:"pointer"}} onClick={(e) => {
                                                const parentDiv = e.target.parentNode;
                                                const fileInput = parentDiv.querySelector('input[type="file"]');
                                                if (fileInput) fileInput.click();
                                            }}>
                                                <input type="file" accept="image/*" style={{display: "none"}} onChange={photoImgHandle}/>
                                                <img alt="uploadImg" style={{
                                                    width: "80px",
                                                    height: "80px",
                                                    padding: "10px",
                                                    filter: "opacity(0.2) drop-shadow(0 0 0 gray)",
                                                }} src={photoUpload}/>
                                                <p style={{color: "rgba(112,112,109,0.67)", fontSize: "18px"}}>Click me!</p>
                                                <div className="JoinErrorMsg">
                                                    {photoContainer.photoImgError && <p className="JoinErrMsg">&nbsp;사진만 업로드해주세요😅</p>}
                                                </div>
                                            </div>
                                            :
                                            <div className="user-center" style={{width:"100%", display:"flex", height:"100%", position:"relative", padding:"10px"}}>
                                                <img alt="photoImg" style={{
                                                    display:"flex",
                                                    justifyContent:"center",
                                                    alignItems:"center",
                                                    maxWidth:"100%",
                                                    maxHeight:"100%",
                                                    borderRadius:"6px"
                                                }} src={photoContainer.photoImg}/>
                                                <button className="orderItemHandleBt" style={{
                                                    position:"absolute",
                                                    width: "40px",
                                                    height: "40px",
                                                    top:"85%",
                                                    left:"90%",
                                                    borderRadius:"4px",
                                                    opacity:"0.6"
                                                }} onClick={photoImgInit}>
                                                    <i style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        fontSize:"26px"
                                                    }}><BsTrash/></i>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                height: "100px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "end"
                            }}>
                                <button className="orderItemHandleBt" style={{width: "100px", height: "50px", borderRadius:"4px"}} onClick={valiAndSave}>업로드</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            { saveFailSt ?
                <MsgCheckModal saveFailSt={saveFailSt} closeSaveFailSt={closeSaveFailSt}/>
                :
                null
            }

        </div>
    )
}

export default AdminPopupWritePg
