import React, {useCallback, useEffect, useState} from 'react';
import AdminSearchEvent from "../../components/web/layoutsadmin/AdminSearchEvent";
import axios from "axios";
import {BsSquareFill} from "react-icons/bs";
import {MdKeyboardArrowDown} from "react-icons/md";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import TopBtn from "../../components/web/layoutsadmin/TopBtn";
import maCateImg from "../../assets/img/m-a-cate.png";
import profileImage from "../../assets/img/profileDefaultImg.png";
import AdminProfileBase from "../../assets/img/AdminProfileBase.jpg";

function AdminUserInfoPg() {

    /**region DataTable */

    const [userData, setUserData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    /** endregion */

    /** region Function */

    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('adminToken');

    //region Search

    const [searchInput, setSearchInput] = useState('');
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const searchData = userData.filter((cData) => {
        return cData.userName.toLowerCase().includes(searchInput.toLowerCase());
    });

    //endregion

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [searchData.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
            if (searchData.length > displayLimit && displayLimit < 30) {
                setDisplayLimit(prevLimit => prevLimit + perPg);
            }
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    // region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e.target.value);
        setperPg(newPerPage);
        // setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    //endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);

    const checkBoxChange = (id, event) => {
        event.stopPropagation(); // Stop event propagation
        setChecked((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter((item) => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    const allcheckBoxChange = (e) => {
        let visibleData = searchData.slice(0, displayLimit);
        if (e.target.checked) {
            const visibleIds = visibleData.map((item) => item.mbNum);
            setChecked(visibleIds);
        } else {
            setChecked([]);
        }
    };

    //endregion

    //region Alert

    const [pendingUserOutFl, setPendingUserOutFl] = useState(null);
    const [alertShow, setAlertShow] = useState(false);
    const [alertShow2, setAlertShow2] = useState(false);
    const msgConfirmData1 = "선택된 회원이 탈퇴됩니다.";
    const msgConfirmData2 = "정말 탈퇴하시겠습니까?";
    const msgConfirmData3 = "고객의 프로필 이미지가 삭제됩니다.?";
    const msgConfirmData4 = "정말 삭제하시겠습니까?";
    const outFlAlert = (newValue) => {
        setPendingUserOutFl(newValue);
        setAlertShow(true);
    };
    const profileDeleteAlert = (newValue) => {
        setPendingUserOutFl(newValue);
        setAlertShow2(true);
    };

    const modalCancel = useCallback (() => {setAlertShow(false);})
    const modalCancel2 = useCallback (() => {setAlertShow2(false);})

    //endregion

    const handleRowClick = (user) => {
        setSelectedUser(user);
    };

    const [profilePhotoLine, setProfilePhotoLine] = useState(profileImage);

    const [openImagePop, setOpenImagePop] = useState(false);
    const [imageSrc, setImageSrc] = useState('');

    const openImageP = (imageSrc) => {
        setImageSrc(imageSrc);
        setOpenImagePop(true);
    };

    const closeImageP = () => {
        setOpenImagePop(false);
        setImageSrc('');
    };

    /** endregion */

    /** region Event  */

    const profileDelete = (id) => {
        axios({
            method: 'post',
            url: '/member/profileDelete',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                id: id
            },
        })
            .then(() => {
                setUserData(prevData => prevData.map(user =>
                    user.profileDTO && user.profileDTO.seqnum === id
                        ? { ...user, profilePhoto: profileImage, profileDTO: null }
                        : user
                ));
                setAlertShow2(false);
            })
            .catch(() => {});
    };

    useEffect(() => {
        select();
    }, [])

    useEffect(() => {
        if (userData.length > 0) {
            setSelectedUser(userData[0]);
        }
    }, [userData]);

    const select = () => {
        axios({
            method: 'get',
            url: '/member/adminuserselect',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                const filteredData = response.data.filter(item => item.role === "0");
                const sortedData = filteredData.sort((a, b) => a.userName.localeCompare(b.userName));
                setUserData(sortedData);

                const updatedData = sortedData.map(user => ({
                    ...user,
                    profilePhoto: user.profileDTO ? "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + user.profileDTO.filename : profileImage
                }));

                setUserData(updatedData);
            })
            .catch(() => {})
    };

    const outFlUpdate = (newValue) => {
        axios.post('/member/userOutFlupdate', {
            mbNum: selectedUser.mbNum,
            userOutFl: newValue
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LCAT
            }
        })
            .then(() => {
                setAlertShow(false);
                setPendingUserOutFl(null);
                setSelectedUser(prevUser => ({ ...prevUser, userOutFl: newValue }));
            })
            .catch((error) => {
                console.error("에러:", error);
            });
    };

    // region Excel

    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const DateViewValue = year + month + day;

    const excelDown = () => {
        try {
            const selectedRows = searchData.filter((item) => checked.includes(item.mbNum));

            const excel = require('exceljs');
            const workbook = new excel.Workbook();
            workbook.created = new Date();
            workbook.modified = new Date();

            function mapDataToExcelRow(item, index) {
                return {
                    '번호': index + 1,
                    '아이디': item.id,
                    '회원명': item.userName,
                    '사서함번호': 'BT' + item.mbNum,
                    '문의언어국가': item.lang,
                    '예치금': item.deposit,
                    '적립금': item.point,
                    '보유쿠폰개수': '1',
                    '메일수신여부': item.mailRecFl,
                    '가입일시': item.regTime,
                    '회원탈퇴여부': item.userOutFl,
                    '탈퇴일시': '',
                    '마지막접속일시': '',
                    '수정일시': ''
                };
            }

            const sheetConfigs = {
                '회원정보': {
                    name: '회원정보',
                    columns: [
                        {header: '번호', key: '번호', hidden: false, width: 6, style: { alignment: { horizontal: 'center' }}},
                        {header: '아이디', key: '아이디', width: 25},
                        {header: '회원명', key: '회원명', width: 21, style: { alignment: { horizontal: 'center'}}},
                        {header: '사서함번호', key: '사서함번호', width: 11, style: { alignment: { horizontal: 'center'}}},
                        {header: '문의언어국가', key: '문의언어국가', width: 12, style: { alignment: { horizontal: 'center' }}},
                        {header: '예치금', key: '예치금', width: 10},
                        {header: '적립금', key: '적립금', width: 10},
                        {header: '보유쿠폰개수', key: '보유쿠폰개수', width: 12, style: { alignment: { horizontal: 'right' }}},
                        {header: '메일수신여부', key: '메일수신여부', width: 12, style: { alignment: { horizontal: 'center' }}},
                        {header: '가입일시', key: '가입일시', width: 20, style: { alignment: { horizontal: 'center' }}},
                        {header: '회원탈퇴여부', key: '회원탈퇴여부', width: 12, style: { alignment: { horizontal: 'center' }}},
                        {header: '탈퇴일시', key: '탈퇴일시', width: 20, style: { alignment: { horizontal: 'center' }}},
                        {header: '마지막접속일시', key: '마지막접속일시', width: 20, style: { alignment: { horizontal: 'center' }}},
                        {header: '수정일시', key: '수정일시', width: 20, style: { alignment: { horizontal: 'center' }}},
                    ],
                }
            };

            const borderHeaderStyle = {
                top: { style: 'thin', color: { argb: 'FF000000' } },
                left: { style: 'thin', color: { argb: 'FF000000' } },
                bottom: { style: 'thin', color: { argb: 'FF000000' } },
                right: { style: 'thin', color: { argb: 'FF000000' } }
            };

            const applyBordersToRow = (row, border) => {
                row.eachCell({ includeEmpty: true }, (cell) => {
                    cell.border = border;
                });
            };

            const applyStylesToHeader = (sheet, height) => {
                const invisibleRow = sheet.getRow();
                invisibleRow.height = 1;

                const headerRow = sheet.getRow(1);
                headerRow.font = { name: '맑은 고딕', bold: true, size: 10 };
                headerRow.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
                headerRow.height = height;

                headerRow.eachCell(() => {});
                applyBordersToRow(headerRow, borderHeaderStyle);
            };

            const createSheet = (name, columns) => {
                const sheet = workbook.addWorksheet(name);
                sheet.columns = columns;
                applyStylesToHeader(sheet, 23);
                return sheet;
            };

            const addRowsToSheet = (sheet, rows, border) => {
                rows.forEach((item) => {
                    const row = sheet.addRow(item);
                    for (let i = 1; i <= sheet.columns.length; i++) {
                        row.getCell(i).font = { name: '맑은 고딕', size: 10 };
                    }
                    applyBordersToRow(row, border);
                });
            };

            let transformedSelectedRows;
            transformedSelectedRows = selectedRows.map(mapDataToExcelRow);

            if (sheetConfigs) {
                const sheet = createSheet(sheetConfigs['회원정보'].name, sheetConfigs['회원정보'].columns);
                applyStylesToHeader(sheet, 23);
                addRowsToSheet(sheet, transformedSelectedRows, borderHeaderStyle);
            }

            let fileName = `비토리지 회원정보_${DateViewValue}.xlsx`;
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = fileName;
                anchor.click();
                window.URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.error(error);
        }
    };

    // endregion

    /** endregion */

    return (
        <div className="AdminUserInfoPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">회원정보</p>
            </div>
            <div className="admin-wrap font-t-cont">
                <div className="admin-wrap-card">
                    <div className="user-set-config">
                        <div className="admin-btn-wrap-l m-b-5"><BsSquareFill className="admin-tb-header-notice"/><a>회원정보상세</a></div>
                        {selectedUser && (
                            <table className="admin-ver-tb">
                                <tbody className="ver-tb-body">
                                    <tr>
                                        <th><p>아이디</p></th>
                                        <td><p>{selectedUser.id}</p></td>
                                        <th><p>회원명</p></th>
                                        <td><p className="font-b">{selectedUser.userName}</p></td>
                                    </tr>
                                    <tr>
                                        <th><p>사서함번호</p></th>
                                        <td><p className="font-b">BT{selectedUser.mbNum}</p></td>
                                        <th><p>CS국가</p></th>
                                        <td><p>{selectedUser.lang}</p></td>
                                    </tr>
                                    <tr>
                                        <th><p>예치금</p></th>
                                        <td><p>\ {selectedUser && selectedUser.deposit != null ? selectedUser.deposit.toLocaleString() : 0}</p></td>
                                        <th><p>적립금</p></th>
                                        <td><p>\ {selectedUser && selectedUser.point != null ? selectedUser.point.toLocaleString() : 0}</p></td>
                                    </tr>
                                    <tr>
                                        <th><p>보유쿠폰</p></th>
                                        <td><p>개</p></td>
                                        <th><p>메일수신여부</p></th>
                                        <td><p>
                                            {selectedUser.mailRecFl === 'Y' ? 'Y:수신' : 'N' ? 'N:거부' : null}
                                        </p></td>
                                    </tr>
                                    <tr>
                                        <th><p>가입일시</p></th>
                                        <td><p>{selectedUser.regTime}</p></td>
                                        <th><p>탈퇴여부</p></th>
                                        <td>
                                            <select className="admin-combo wd-70 ht-25 m-b-8" value={selectedUser.userOutFl}
                                                    onChange={(e) => {const newValue = e.target.value;outFlAlert(newValue);}}>
                                                <option value="Y">Y</option>
                                                <option value="N">N</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><p>정보수정일시</p></th>
                                        <td><p>{selectedUser.modiTime}</p></td>
                                        <th><p>탈퇴일시</p></th>
                                        <td><p>{selectedUser.secTime}</p></td>
                                    </tr>
                                    <tr>
                                        <th><p>마지막 접속일시</p></th>
                                        <td><p>-</p></td>
                                        <th><p>프로필사진</p></th>
                                        <td className="display-flex flex-row">
                                            <div className="m-a-header-profile-box m-r-10 c-point" onClick={() => openImageP(selectedUser.profilePhoto)}>
                                                <img className="m-a-header-profile-box-img" src={selectedUser.profilePhoto} alt=""/>
                                            </div>
                                            <button className="admin-btn-basic admin-btn-long" onClick={profileDeleteAlert}>이미지삭제</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
            {openImagePop && (
                <div className="pop-dt-img-container" onClick={closeImageP}>
                    <div className="pop-dt-img-frame" onClick={(e) => e.stopPropagation()}>
                        <img className="pop-dt-img" src={imageSrc} alt="profileDelete" />
                        <button className="pop-dt-close font-t-head" onClick={closeImageP}>X</button>
                    </div>
                </div>
            )}
            <div className="admin-wrap">
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        <div className="admin-event-wrap-btn">
                            <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                            <AdminSearchEvent searchInput={searchInput} handleSearchInputChange={handleSearchInputChange} />
                        </div>
                        <div className="admin-event-wrap-btn">
                            <button onClick={excelDown} className="admin-btn-long admin-bt-color">EXCEL</button>
                        </div>
                    </div>
                    <div className="div-tb">
                        <div className="div-tb-head ht-60">
                            <div style={{width: "40px"}}>
                                <input className="admin-input-chkBox m-0-a" type="checkbox" checked={checked.length === userData.length} onChange={allcheckBoxChange}/>
                            </div>
                            <div style={{width: "40px"}}><p>번호</p></div>
                            <div style={{width: "120px"}}><p>회원명</p></div>
                            <div style={{width: "190px"}}><p>아이디</p></div>
                            <div style={{width: "80px"}}><p>CS국가</p></div>
                            <div style={{width: "100px"}}><p>예치금(&#8361;)</p></div>
                            <div style={{width: "100px"}}><p>적립금(&#8361;)</p></div>
                            <div style={{width: "100px"}}><p>가입일자</p></div>
                            <div style={{width: "80px"}}><p>메일수신</p></div>
                            <div style={{width: "80px"}}><p>탈퇴여부</p></div>
                        </div>
                        {searchData.slice(0, displayLimit).map((cData, i) => (
                            <div key={i} className="div-tb-body ht-70" onClick={() => handleRowClick(cData)}>
                                <div style={{width: "40px"}}>
                                    <input className="admin-input-chkBox m-0-a" type="checkbox" name={cData.mbNum} checked={checked.includes(cData.mbNum)} onChange={(e) => checkBoxChange(cData.mbNum, e)}/>
                                </div>
                                <div style={{width: "40px"}}><p>{indexOfFirst + i + 1}</p></div>
                                <div style={{width: "120px"}}><p className="font-b">{cData.userName}</p></div>
                                <div style={{width: "190px"}}><p>{cData.id}</p></div>
                                <div style={{width: "80px"}}><p>{cData.lang}</p></div>
                                <div style={{width: "100px"}}><p className="font-b">{cData.deposit.toLocaleString()}</p></div>
                                <div style={{width: "100px"}}><p className="font-b">{cData.point.toLocaleString()}</p></div>
                                <div style={{width: "100px"}}><p>{`${cData.regDate.substring(0, 4)}-${cData.regDate.substring(4, 6)}-${cData.regDate.substring(6, 8)}`}</p></div>
                                <div style={{width: "80px"}}><p>{cData.mailRecFl}</p></div>
                                <div style={{width: "80px"}}><p>{cData.userOutFl}</p></div>
                            </div>
                        ))}
                    </div>
                    {displayLimit >= 30 && searchData.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {alertShow === true ?
                <MsgSelectModal alertShow={alertShow} eventHandle1={modalCancel} eventHandle2={() => outFlUpdate(pendingUserOutFl)} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/>
                : null}
            {alertShow2 === true ? <MsgSelectModal alertShow={alertShow2} eventHandle1={modalCancel2} eventHandle2={() => profileDelete(selectedUser.profileDTO.seqnum)} msgData1={msgConfirmData3} msgData2={msgConfirmData4}/> : null}
            <TopBtn/>
        </div>
    );
}

export default AdminUserInfoPg
