import React from "react";
import dontPg from '../../assets/img/dontExistPgImg.png'
import {useNavigate} from "react-router-dom";

function ErrorPathPg() {

    const navigate = useNavigate();

    const pathName = window.location.pathname;

    // const moveToMainPg = (() => {
    //     if (pathName.startsWith("/btcorpad")) {
    //         navigate("/btcorpad")
    //     } else {
    //     navigate("/")
    //     }
    // })

    const moveToMainPg = (() => {
            navigate("/")
    })

    return(
        <div className="user-center" style={{height:"80vh"}}>
            <div className="user-center" style={{width:"300px", height:"300px"}}>
                <div style={{textAlign:"center"}}>
                    <img style={{width:"100px", height:"100px"}} src={dontPg} alt="dontPage"/>
                    <p style={{margin:"0", fontSize:"18px", marginTop:"10px"}}>잘못된 경로입니다</p>
                    <button className="colorFullBt user-center" style={{
                        width: "100%",
                        height: "50px",
                        textAlign: "center",
                        fontSize: "19px",
                        marginTop:"20px"
                    }} onClick={moveToMainPg}>
                        <p style={{margin:"0", fontSize:"16px", color:"white"}}>HOME</p>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ErrorPathPg
