import React, {useRef, useState} from 'react';
import '../../css/web/AdminJoinPg.css';
import '../../css/Common.css';
import {FiUser} from "react-icons/fi";
import {AiFillEye, AiFillEyeInvisible, AiOutlineEye, AiOutlineEyeInvisible, AiOutlineIdcard, AiOutlinePhone} from "react-icons/ai";
import {HiKey, HiOutlineCake, HiOutlineKey} from "react-icons/hi";
import {BsEmojiSunglasses} from "react-icons/bs";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import MsgConfirmModal from "../../components/web/modal/MsgConfirmModal";
import useTheme from "../../components/web/layoutscommon/DarkModeStorage";
import imgLogo from "../../assets/img/btorage_only_img_logo.png";

function AdminJoinPg() {

    /** region Function */

    //region 다크모드

    const [theme] = useTheme();

    //endregion

    const navigate = useNavigate()

    const [pwView, setPwView] = useState(false)
    const [pwViewConfirm, setPwViewConfirm] = useState(false)

    const [num, setNum] = useState('');
    const phoneRef = useRef();

    const [missingFields, setMissingFields] = useState([]);

    const [birthday, setBirthDay] = useState("");
    const [name, setName] = useState("");
    const [nickname, setNickName] = useState("");
    const [id, setId] = useState("");
    const [idErrorCheck, setIdErrorCheck] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [birthdayError, setBirthDayError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [nicknameError, setNickNameError] = useState(false);
    const [idError, setIdError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);

    const onChangeBirthDay = (e) => {
        const userBirthDayRegex = /^[0-9]{6}$/;
        if ((!e.target.value || (userBirthDayRegex.test(e.target.value)))) setBirthDayError(false);
        else setBirthDayError(true);
        setBirthDay(e.target.value);
    };
    const onChangeName = (e) => {
        const userNameRegex = /[ㄱ-힣]{2,5}/;
        if ((!e.target.value || (userNameRegex.test(e.target.value)))) setNameError(false);
        else setNameError(true);
        setName(e.target.value);
    };
    const onChangeNickName = async (e) => {
        const userNickNameRegex = /^[a-zA-Zㄱ-힣][a-zA-Zㄱ-힣 ]*$/;
        if ((!e.target.value || (userNickNameRegex.test(e.target.value)))) setNickNameError(false);
        else setNickNameError(true);

        const newNickname = e.target.value;
        setNickName(newNickname);

        if (newNickname.length < 2 || newNickname.length > 5) {
            setNickNameError('닉네임은 2~5자 입니다.');
            return;
        }

        try {
            const { data } = await axios.get('/member/validateNickname', {
                params: {
                    nickname: newNickname,
                    id: id
                }
            });
            if (data.isDuplicate) {
                setNickNameError("'" + newNickname + "'" + "는(은) 이미 사용중입니다.");
            } else {
                setNickNameError('');
            }
        } catch (error) {
            console.error('Error: ', error);
            setNickNameError('닉네임 Err');
        }
    };

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const onChangeId = async (e) => {
        const value = e.target.value;

        if (!value || (emailRegex.test(value) && value.length >= 11 && value.length <= 35)) {
            setIdError(false);
            if (emailRegex.test(value) && value.length >= 11 && value.length <= 35) {
                await IdCheck(value);
            } else {
                setIdErrorCheck(false);
            }
        } else {
            setIdError(true);
            setIdErrorCheck(false);
        }
        setId(value);
    };

    const IdCheck = async (id) => {
        if (id === undefined) return;
        await axios.get("/member/" + id).then((response) => {
            if (response.data === true) setIdErrorCheck(true);
            else setIdErrorCheck(false);
        }).catch((error) => {
            console.error("Error checking ID:", error);
            setIdErrorCheck(false);
        });
    }

    const onChangePassword = (e) => {
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()])(?=.*[0-9]).{8,20}$/;
        if ((!e.target.value || (passwordRegex.test(e.target.value)))) setPasswordError(false);
        else setPasswordError(true);

        if (!confirmPassword || e.target.value === confirmPassword) setConfirmPasswordError(false);
        else setConfirmPasswordError(true);
        setPassword(e.target.value);
    };
    const onChangeConfirmPassword = (e) => {
        if (password === e.target.value) setConfirmPasswordError(false);
        else setConfirmPasswordError(true);
        setConfirmPassword(e.target.value);
    };

    const validation = () => {
        if (!name) setIdError(true);
        if (!birthday) setIdError(true);
        if (!id) setIdError(true);
        if (!password) setPasswordError(true);
        if (!confirmPassword) setConfirmPasswordError(true);
        return !!(birthday && name && id && password && confirmPassword);
    }

    const phone = (e) => {
        const value = phoneRef.current.value.replace(/\D+/g, "");
        const numberLength = 11;
        let result;
        result = "";

        for (let i = 0; i < value.length && i < numberLength; i++) {
            switch (i) {
                case 3:
                    result += "-";
                    break;
                case 7:
                    result += "-";
                    break;

                default:
                    break;
            }
            result += value[i];
        }
        phoneRef.current.value = result;
        setNum(e.target.value);
    };

    const clickPwView = (e) => {
        e.preventDefault()
        setPwView(!pwView)
    }
    const clickPwViewConfirm = (e) => {
        e.preventDefault()
        setPwViewConfirm(!pwViewConfirm)
    }
    const validAndSave = () => {
        validation();
        joinDbSave();
    }

    /** endregion */

    /** region Event */

    const regiWaitingFn = (() => {
        navigate("/btcorpad-login")
    })

    const [regiWaitingSt, setRegiWaitingSt] = useState(false);
    const regiWaitingMsg = "회원가입 신청완료. 관리자 승인 후에 로그인이 가능합니다."

    const lang = "관리자"
    const userType = "1"
    const mailRecFl = "Y"
    const joinVerFl = "N"
    const userOutFl = "N"
    const role = "1"
    const socialJoinFl = false;

    const joinDbSave = () => {
        const missing = [];

        if (!id) missing.push("계정");
        if (!password) missing.push("비밀번호");
        if (!confirmPassword) missing.push("비밀번호확인");
        if (!name) missing.push("이름");
        if (!nickname) missing.push("닉네임");
        if (!birthday) missing.push("생년월일");
        if (!num) missing.push("연락처");

        if (missing.length > 0) {
            setMissingFields(missing);
            return;
        }

        axios.post("/member/join", {
            id: id,
            password: password,
            passwordConfirm: confirmPassword,
            userName: name,
            lang: lang,
            userType: userType,
            mailRecFl: mailRecFl,
            joinVerFl: joinVerFl,
            userOutFl: userOutFl,
            role: role,
            phone: num,
            nicName: nickname,
            byMd: birthday,
            socialJoinFl: socialJoinFl
        })
            .then(() => {
                setRegiWaitingSt(true);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** endregion */

    return (
        <div className="AdminJoinPg font-t-cont" data-theme={theme}>
            <div className="join-main">
                <div className="join-sub">
                    <div className="wrap-join p-l-55 p-r-55 p-t-65 p-b-54">
                        <form className="join-form validate-form">
                            <div className="join-form-title c-def">
                                <img alt="mainLogo" style={{width:"28px", height:"45px", marginRight: "10px", marginBottom: "5px"}} src={imgLogo}/>
                                <strong className="font-s-35">Administrator Join</strong>
                            </div>
                            <div className="wrap-input" data-validate="Username is reauired">
                                <input className="input100 user-input" type="text" name="username"
                                       value={id}
                                       onChange={onChangeId}
                                       minLength="11" maxLength="35"
                                       placeholder="이메일 형식(ex@btorage.com)" />
                                <span className="focus-input">
                                    <FiUser className="ad-join-i-con"/>
                                </span>
                            </div>
                            <div className="wrap-input" data-validate="Password is required">
                                <input className="input100 user-input" type={pwView ? 'text' : 'password'} name="pass"
                                       value={password}
                                       onChange={onChangePassword}
                                       minLength="8" maxLength="20"
                                       placeholder="영문, 숫자, 특수문자 조합 8~20자"/>
                                <span className="focus-input">
                                    <HiOutlineKey className="ad-join-i-con"/>
                                </span>
                                <span className="focus-input-view">
                                    <a onClick={clickPwView}>
                                        {pwView ? <AiOutlineEye className="i-con-view"/> :
                                            <AiOutlineEyeInvisible className="i-con-view"/>}
                                    </a>
                                </span>
                            </div>
                            <div className="wrap-input" data-validate="Password is required">
                                <input className="input100 user-input" type={pwViewConfirm ? 'text' : 'password'}
                                       name="pass"
                                       value={confirmPassword}
                                       onChange={onChangeConfirmPassword}
                                       minLength="8" maxLength="20"
                                       placeholder="비밀번호 재입력"/>
                                <span className="focus-input">
                                    <HiKey className="ad-join-i-con"/>
                                </span>
                                <span className="focus-input-view">
                                    <a onClick={clickPwViewConfirm}>
                                        {pwViewConfirm ? <AiFillEye className="i-con-view"/> :
                                            <AiFillEyeInvisible className="i-con-view"/>}
                                    </a>
                                </span>
                            </div>
                            <div className="ErrView">
                                {[
                                    { condition: passwordError || confirmPasswordError, message: "아이디 또는 패스워드를 확인해주세요.", icon: <FiUser /> },
                                    { condition: idErrorCheck, message: "이미 가입된 계정입니다.", icon: <FiUser /> },
                                ].map(({ condition, message, icon }, index) => (
                                    condition && (
                                        <p key={index} className="ErrMsg">
                                            {icon}&nbsp;{message}
                                        </p>
                                    )
                                ))}
                            </div>
                            <div className="wrap-input m-t-45">
                                <input className="input100 user-input" type="text"
                                       value={name} onChange={onChangeName}
                                       minLength="2" maxLength="5"
                                       placeholder="이름(실명)"/>
                                <span className="focus-input">
                                    <AiOutlineIdcard className="ad-join-i-con"/>
                                </span>
                            </div>
                            <div className="wrap-input">
                                <input className="input100 user-input" type="text"
                                       value={nickname} onChange={onChangeNickName}
                                       minLength="2" maxLength="5"
                                       placeholder="닉네임"/>
                                <span className="focus-input">
                                    <BsEmojiSunglasses className="ad-join-i-con"/>
                                </span>
                            </div>
                            <div className="wrap-input">
                                <input className="input100 user-input" type="text"
                                       value={birthday} onChange={onChangeBirthDay}
                                       minLength="6"
                                       maxLength="6"
                                       placeholder="생년월일(6자리 숫자)"/>
                                <span className="focus-input">
                                    <HiOutlineCake className="ad-join-i-con"/>
                                </span>
                            </div>
                            <div className="wrap-input">
                                <input className="input100 user-input" type="tel" name={num} ref={phoneRef}
                                       onChange={phone} placeholder="연락처(숫자만 입력)"/>
                                <span className="focus-input"><AiOutlinePhone className="ad-join-i-con"/>
                                </span>
                            </div>
                            <div className="ErrView">
                                {[
                                    { condition: nameError, message: "이름을 확인해주세요.", icon: <AiOutlineIdcard /> },
                                    { condition: nicknameError, message: "닉네임을 확인해주세요.", icon: <BsEmojiSunglasses /> },
                                    { condition: birthdayError, message: "생년월일을 확인해주세요.", icon: <HiOutlineCake /> }
                                ].map(({ condition, message, icon }, index) => (
                                    condition && (
                                        <p key={index} className="ErrMsg">
                                            {icon}&nbsp;{message}
                                        </p>
                                    )
                                ))}
                            </div>
                            {missingFields.length > 0 && (
                                <div style={{width: "100%", textAlign: "center", marginBottom: "-25px"}}>
                                    <a className="ErrMsg c-def">모든 필드의 입력란은 필수입니다.</a>
                                </div>
                            )}
                            <div className="join-sub-form-btn">
                                {theme === "light" ?
                                    <div className="JoinButton acc-submit" style={{width: "100%"}}>
                                        <Button variant="primary" id="accSubmitBt" onClick={validAndSave} style={{fontWeight: "bold"}}>SIGN UP</Button>
                                    </div>
                                    :
                                    <div className="wrap-login100-form-btn dark">
                                        <Button className="ad-login-btn" onClick={validAndSave}>SIGN UP</Button>
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
                {regiWaitingSt === true ? <MsgConfirmModal regiWaitingFn={regiWaitingFn} regiWaitingSt={regiWaitingSt} regiWaitingMsg={regiWaitingMsg}/> : null}
            </div>
        </div>
    );
}

export default AdminJoinPg
