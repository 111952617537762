import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import commonHeader from '../../assets/img/commonHeader.png'
import commonClose from '../../assets/img/commonClose.png'
import { useLocation } from 'react-router-dom';
import axios from "axios";
import maCateImg from "../../assets/img/m-a-cate.png";

function MAdminAlarmListPg() {
    const {type} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const LCAT = localStorage.getItem('adminToken');

    const [prodData, setProdData] = useState([]);
    const [payData, setPayData] = useState([]);
    const [depositData, setDepositData] = useState([]);
    const [reviewData, setReviewData] = useState([]);

    useEffect( () => {
        updateData();
        if (type === "cart") {
            deleteProdData("P00");
        } else if (type === "order") {
            deleteProdData("P01");
        } else if (type === "offline") {
            deleteProdData("P02");
        } else if (type === "transfer") {
            deleteProdData("P03");
        } else if (type === "domestic") {
            deleteProdData("P04");
        } else if (type === "delivery") {
            deleteProdData("P05");
        } else if (type === "pay") {
            deleteAlarmPay();
        } else if (type === "deposit") {
            deleteAlarmDeposit();
        }
    }, [location]);

    const updateData = (() => {
        if (location.state) {
            if (type === "cart" || type === "order" || type === "offline" || type === "transfer" || type === "domestic" || type === "delivery") {
                setProdData(location.state);
            } if (type === "pay") {
                setPayData(location.state);
            } if (type === "deposit") {
                setDepositData(location.state);
            } if (type === "review") {
                setReviewData(location.state);
            }
        }
    })

    const prodStPrint = (prodSt) => {
        const prodStStyles = {
            IS00: { color: "#98B0D4", label: "입고대기" },
            IS01: { color: "#98B0D4", label: "결제대기(상품)" },
            IS02: { color: "#98B0D4", label: "결제중(상품)" },
            IS03: { color: "#FFB378", label: "결제완료(상품)" },
            IS04: { color: "#FF8600", label: "구매완료" },
            IS05: { color: "#6EDA60", label: "센터도착" },
            IS06: { color: "#98B0D4", label: "패킹중" },
            IS07: { color: "#98B0D4", label: "결제대기(배송)" },
            IS08: { color: "#98B0D4", label: "결제중(배송)" },
            IS09: { color: "#FFB378", label: "결제완료(배송)" },
            IS10: { color: "#979696", label: "출고완료" },
            IS11: { color: "#FF8F62", label: "반송중" },
            IS12: { color: "#FF8F62", label: "신청취소" },
            IS13: { color: "#98B0D4", label: "구매중" },
            IS14: { color: "#98B0D4", label: "입고중" }
        };

        const style = prodStStyles[prodSt] || { color: "black", label: null };
        return <p style={{ margin: "0", color: style.color, fontSize: "13px" }}>{style.label}</p>;
    };

    const closeAlarm = (async () => {
        navigate("/btcorpad")
    })

    const deleteProdData = ((prodTy) => {
        deleteAlarmProd(prodTy);
    })

    const deleteAlarmProd = ((prodTy) => {
        axios({
            method: 'post',
            url: '/order/deleteAlarmProd',
            headers: {
                Authorization: LCAT
            },
            params: {
                prodTy: prodTy
            },
        })
            .then((response) => {
            })
            .catch((error) => {
                console.log("삭제실패 : ", error)
            })
    })

    const deleteAlarmPay = (() => {
        axios({
            method: 'post',
            url: '/order/deleteAlarmPay',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
            })
            .catch((error) => {
                console.log("삭제실패 : ", error)
            })
    })

    const deleteAlarmDeposit = (() => {
        axios({
            method: 'post',
            url: '/order/deleteAlarmDeposit',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
            })
            .catch((error) => {
                console.log("삭제실패 : ", error)
            })
    })

    const formatDate = (dateStr) => {
        if (dateStr.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }

    const moveToAdminReviewDtPg = (async (seqNum) => {
        await deleteAlarmReview(seqNum);
        navigate(`/btcorpad/adminreview-detail/${seqNum}`);
    })

    const deleteAlarmReview = ((seqNum) => {
        axios({
            method: 'post',
            url: '/order/deleteAlarmReview',
            headers: {
                Authorization: LCAT
            },
            params: {
                seqNum: seqNum
            }
        })
            .then((response) => {
            })
            .catch((error) => {
                console.log("삭제실패 : ", error)
            })
    })

    return(
        <div id="m-a-alarm-pg-container">
            <div className="m-a-title-frame">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">Alarm List</p>
            </div>
            <div className="m-a-card display-flex flex-column">
                <div className="m-a-event-frame">

                </div>
                <div className="m-a-tb-frame">
                    { type === 'deposit' ?
                        <div className="m-a-div-tb">
                            <div className="m-a-div-tb-head ht-60">
                                <div className="wd-20pc"><p>고객명</p></div>
                                <div className="wd-20pc"><p>예금주(ID)</p></div>
                                <div className="wd-15pc"><p>계좌</p></div>
                                <div className="wd-15pc"><p>금액</p></div>
                                <div className="wd-15pc"><p>구분</p></div>
                                <div className="wd-15pc"><p>상태</p></div>
                            </div>
                            {depositData && depositData.map((deposit, i) => (
                                <div key={i} className="m-a-div-tb-body ht-70">
                                    <div className="wd-20pc"><p>{i + 1}</p></div>
                                    <div className="wd-20pc"><p>{deposit?.userNm}</p></div>
                                    <div className="wd-15pc"><p>{deposit?.name}</p></div>
                                    <div className="wd-15pc"><p>{deposit?.account}</p></div>
                                    <div className="wd-15pc">
                                        <p>{deposit?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                                        {deposit?.currency ?
                                            <div>
                                                <p style={{fontSize:"11px", marginLeft:"3px"}}>-</p>
                                                <p style={{fontSize:"13px", color:"darkorange", marginLeft:"3px"}}>{deposit?.dollarPrice}</p>
                                                <p style={{fontSize:"11px", marginLeft:"3px"}}>{deposit?.currency}</p>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="wd-15pc"><p>{deposit.chargFl ? "충전" : "환급"}</p></div>
                                    <div className="wd-15pc">
                                        {deposit.status === "0" ? <p style={{fontSize:"13px", color:"#7C9EDC"}}>대기</p> :
                                            deposit.status === "1" ?  <p style={{fontSize:"13px"}}>완료</p> :
                                                <p style={{fontSize:"13px", color:"#EB939D"}}>취소</p>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                        :
                        type === 'review' ?
                            <div className="m-a-div-tb">
                                <div className="m-a-div-tb-head ht-60">
                                    <div className="wd-10pc"><p></p></div>
                                    <div className="wd-45pc"><p>제목</p></div>
                                    <div className="wd-25pc"><p>작성자</p></div>
                                    <div className="wd-20pc"><p>일자</p></div>
                                </div>
                                {reviewData && reviewData.map((review, i) => (
                                    <div key={i} className="m-a-div-tb-body ht-70">
                                        <div className="wd-10pc"><p>{i + 1}</p></div>
                                        <div className="wd-45pc" onClick={()=> moveToAdminReviewDtPg(review.seqNum)}><p>{review.title}</p></div>
                                        <div className="wd-25pc"><p>{review.regNm}</p></div>
                                        <div className="wd-20pc"><p>{formatDate(review.regDate)}</p></div>
                                    </div>
                                ))}
                            </div>
                            :
                        null
                    }
                </div>
            </div>
        </div>
    )
}

export default MAdminAlarmListPg
