import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import {MdKeyboardArrowDown} from "react-icons/md";
import maCateImg from "../../assets/img/m-a-cate.png";
import noImage from "../../assets/img/NoImage.png";
import commonClose from "../../assets/img/commonClose.png";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";

function AdminBannerPg() {

    /** region DataTable */

    const [data, setData] = useState([]);

    /** endregion */

    /** region Function */

    const navigate = useNavigate();

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [data.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
        if (data.length > displayLimit && displayLimit < 30) {
            setDisplayLimit(prevLimit => prevLimit + perPg);
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e.target.value);
        setperPg(newPerPage);
        // setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    useEffect(() => {
        setDisplayLimit(perPg);
    }, [perPg]);

    // endregion

    //region CheckBox

    const [checkItems, setCheckItems] = useState([]);
    useEffect(() => {}, [checkItems]);
    const handleSingleCheck = (checked, seqNum) => {
        if (checked) {
            setCheckItems(prev => [...prev, seqNum]);
        } else {
            setCheckItems(checkItems.filter((el) => el !== seqNum));
        }
    };
    const handleAllCheck = (checked) => {
        if(checked) {
            const idArray = [];
            data.forEach((el) => idArray.push(el.seqNum));
            setCheckItems(idArray);
        } else {
            setCheckItems([]);
        }
    }

    //endregion

    /** endregion */

    /** region Event */

    const moveToModifyPg = ((seqNum) => {
        navigate(`/btcorpad/adminbanner-modify/${seqNum}`)
    })

    const formatDate = (dateStr) => {
        if (dateStr.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }

    const selectFixFirstCondition = (perPg) => axios({
        method: 'get',
        url: '/banner/selectFixFirstCondition/',
        params: {
            limit: perPg,
            type: '0'
        }
    })
        .then((response) => {
            setData(response.data);
        })

    useEffect(() => {
        if (perPg) {
        selectFixFirstCondition(perPg);
        }
    }, [perPg])

    const [seqNum, setSeqNum] = useState(0);
    const deleteBannerMsg1 = "선택한 배너를 삭제하시겠습니까?"
    const deleteBannerMsg2 = "삭제 후 복구가 불가합니다"
    const [deleteBannerMsgShow, setDeleteBannerMsgShow] = useState(false);
    const openDeleteBannerMsgShow = ((seqNum) => {
        setSeqNum(seqNum);
        setDeleteBannerMsgShow(true);
    })
    const closeDeleteBannerMsgShow = (() => {
        setDeleteBannerMsgShow(false);
    })

    const deleteBanner = ((seqNum) => {
        axios({
            method: 'post',
            url: '/banner/deleteBanner',
            params: {
                bannerSeqNum: seqNum,
            },
        })
            .then(() => {
                setData(prevData => prevData.filter(banner => banner.seqNum !== seqNum));
                closeDeleteBannerMsgShow();
                navigate("/btcorpad/adminbanner");
            })
            .catch(() => {
                console.log("banner 삭제실패")
            })
    })

    const convertToLang = ((lang) => {
        if (lang === "0") return "Korean"
        if (lang === "1") return "English"
        if (lang === "2") return "Japanese"
        if (lang === "3") return "Chinese"
        if (lang === "4") return "Indonesian"
    })

    /** endregion */

    return (
        <div className="AdminNoticePg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">배너설정</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        <div className="admin-event-wrap-btn">
                            <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                        </div>
                        <div className="admin-event-wrap-btn">
                            <button onClick={()=> navigate("/btcorpad/adminbanner-write")} className="admin-btn-long admin-bt-color">배너추가</button>
                        </div>
                    </div>
                    <div className="div-tb m-b-10">
                        <div className="div-tb-head ht-60">
                            <div style={{width: "30px"}}>
                                <input className="admin-input-chkBox m-0-a" type='checkbox'
                                       onChange={(e) => handleAllCheck(e.target.checked)}
                                       checked={checkItems.length === data.length}/>
                            </div>
                            <div style={{width: "30px"}}><p>번호</p></div>
                            <div style={{width: "300px"}}><p>이미지</p></div>
                            <div style={{width: "200px"}}><p>제목</p></div>
                            <div style={{width: "40px"}}><p>언어</p></div>
                            <div style={{width: "150px"}}><p>작성자</p></div>
                            <div style={{width: "90px"}}><p>작성일자</p></div>
                            <div style={{width: "40px"}}></div>
                        </div>
                        {data.filter(data => data.type === '0').map((data, i) => (
                            <div key={i} className="div-tb-body ht-150">
                                <div style={{width: "30px"}}>
                                    <input className="admin-input-chkBox m-0-a" type="checkbox" name={data.seqNum}
                                           checked={checkItems.includes(data.seqNum)}
                                           onChange={(e) => handleSingleCheck(e.target.checked, data.seqNum)}/>
                                </div>
                                <div style={{width: "30px"}}><p>{i + 1}</p></div>
                                <div onClick={() => moveToModifyPg(data?.seqNum)} style={{
                                    width: "300px",
                                    height: "100%",
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "20px"
                                }}>
                                    {data?.bannerPhotoDTO?.[0]?.fileNm ?
                                        <img style={{
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            objectFit: "contain",
                                            textAlign: "center",
                                        }}
                                             src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/bannerImg/" + data?.bannerPhotoDTO?.[0]?.fileNm}
                                             alt="bannerImg"/>
                                        :
                                        <img style={{
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            objectFit: "contain",
                                            textAlign: "center",
                                            opacity: "0.5"
                                        }}
                                             src={noImage}
                                             alt="noImage"/>
                                    }
                                </div>
                                <div style={{width: "200px"}}><p>{data?.title}</p></div>
                                <div style={{width: "40px"}}><p>{convertToLang(data?.lang)}</p></div>
                                <div style={{width: "150px"}}><p>{data?.regNm}</p></div>
                                <div style={{width: "90px"}}><p>{formatDate(data?.regDate)}</p></div>
                                <div style={{width: "40px"}}><img onClick={() => openDeleteBannerMsgShow(data?.seqNum)}
                                                                  style={{
                                                                      width: "30px",
                                                                      height: "30px",
                                                                      opacity: "0.6",
                                                                      cursor: "pointer"
                                                                  }} src={commonClose} alt="close"/></div>
                            </div>
                        ))}
                    </div>
                    {displayLimit >= 30 && data.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
            { deleteBannerMsgShow ?
                <MsgSelectModal deleteBannerMsgShow={deleteBannerMsgShow} deleteBannerMsg1={deleteBannerMsg1} deleteBannerMsg2={deleteBannerMsg2}
                                closeDeleteBannerMsgShow={closeDeleteBannerMsgShow} deleteBanner={deleteBanner} deleteBannerSeqNum={seqNum}/>
                :
                null
            }
        </div>
    );
}

export default AdminBannerPg
