import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {BiArrowBack} from "react-icons/bi";
import {Link, useParams} from "react-router-dom";
import commonHeader from "../../assets/img/commonHeader.png";
import profileImage from "../../assets/img/profileDefaultImg.png";
import reviewComment from "../../assets/img/reviewComment.png";
import defaultImg from "../../assets/img/profileDefaultImg.png";
import DOMPurify from "dompurify";
import {useTranslation} from "react-i18next";
import commomHeader from "../../assets/img/commonHeader.png";

function UserReviewDetailPg() {

    const { t } = useTranslation();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');
    const {seqNum} = useParams();

    const [memberInfo, setMemberInfo] = useState("");
    const findMember = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response)=> {
                setMemberInfo(response.data)
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (SSAT || LCAT) {
            findMember();
        }
    }, [SSAT, LCAT])

    const [reviewData, setReviewData] = useState([]);
    const [reviewProfileImg, setReviewProfileImg] = useState(profileImage)
    const [reviewList, setReviewList] = useState([]);
    const [reviewCommentList, setReviewCommentList] = useState([]);

    const selectReviewData = () =>
        axios({
            method: 'get',
            url: '/review/selectReviewData',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                seqNum: seqNum
            }
        })
            .then((response) => {
                setReviewData(response.data);
                const isDefaultImg = response && response.data && response.data.memberDTO && response.data.memberDTO.profileDTO && response.data.memberDTO.profileDTO.filename;
                setReviewProfileImg(
                    isDefaultImg ?
                        "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + response.data.memberDTO.profileDTO.filename
                        :
                        profileImage
                );
                setReviewList(response.data.reviewPhotoDTO)
                setReviewCommentList(response.data.reviewCommentDTO)
            })

    useEffect(() => {
        if (seqNum) {
            selectReviewData();
        }
    }, [seqNum])



    const useSanitizedHTML = ((rawHTML) => {
        if (!rawHTML) return "";
        return DOMPurify.sanitize(rawHTML.replace(/\n/g, '<br>'));
    })
    const sanitizedContent = useSanitizedHTML(reviewData?.content);

    const textareaRef = useRef(null);
    const [reviewCommentText, setReviewCommentText] = useState('');

    const handleCommentTextChange = (e) => {
        setReviewCommentText(e.target.value);
        const textarea = textareaRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = Math.min(textarea.scrollHeight, 200) + 'px';
    };

    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = Math.min(textarea.scrollHeight, 200) + 'px';
        }
    }, [reviewCommentText])

    const saveReviewComment = () =>
        axios({
            method: 'post',
            url: '/review/saveReviewComment',
            params: {
                reviewSeqNum: reviewData.seqNum,
                memberMbNum: memberInfo.mbNum,
                content: reviewCommentText
            }
        })
            .then((response) => {
                const newComment = response.data;
                const updatedComments = [...reviewCommentList, newComment];
                setReviewCommentList(updatedComments);
                setReviewCommentText('');
            })
            .catch((response) => {
                console.log("코멘트 등록실패")
            })

    const sanitizeHTML = (rawHTML) => {
        if (!rawHTML) return "";
        return DOMPurify.sanitize(rawHTML.replace(/\n/g, '<br>'));
    };

    const formatDate = (dateStr) => {
        if (dateStr?.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }

    return (
        <div>
            <div style={{
                height: "30px",
                margin: "0 0 30px 130px",
                display:"flex",
                alignItems:"center"
            }}>
                <img className="wd-25 ht-25" src={commomHeader} alt="header"/>
                <p className="font-s-20 m-l-10 font-t-title font-c-default">{t(`rvdtHeader`)}</p>
            </div>
            <div style={{
                border: "solid",
                width: "80%",
                margin: "auto",
                borderWidth: "thin 1px",
                borderColor: "#EAEAEA",
                borderRadius: "4px",
                padding: "20px"
            }}>
                {/*헤더 컨테이너*/}
                <div className="font-t-cont ht-80 display-flex m-b-20">
                    <div style={{width: "10%", padding:"5px"}}>
                        <div style={{borderRadius: "100%", height: "100%", width: "90%"}}><img alt="profileImg" style={{
                            width: "90%",
                            height: "90%",
                            borderRadius: "100%"
                        }} src={reviewProfileImg}/></div>
                    </div>

                    <div style={{width: "90%", height: "100%"}}>
                        <div style={{height: "40%", display: "flex", color: "dimgrey", alignItems:"center"}}><span
                            style={{paddingRight: "10px", fontSize: "15px"}}>{reviewData?.memberDTO?.userName}</span>
                            {/*<span style={{*/}
                            {/*    paddingRight: "10px",*/}
                            {/*    color: "dimgrey",*/}
                            {/*    fontSize: "14px",*/}
                            {/*    marginLeft:"10px",*/}
                            {/*}}>{t(`rvdtView`)} {reviewData?.views}</span> */}
                            <span style={{marginLeft: "auto"}}>
                                <Link to={"/UserReviewPg"}>
                                <i style={{display:"flex", justifyContent:"center", alignItems:"center", fontSize:"25px", cursor:"pointer"}}><BiArrowBack/></i>
                                </Link>
                            </span>
                        </div>

                        <div style={{height: "60%", display: "flex", justifyContent: "space-between", paddingTop:"5px"}}>
                            <p style={{fontSize: "16px"}}>[{reviewData?.country}-{reviewData?.shipco}]<span style={{marginLeft:"5px"}}>{reviewData?.title}</span></p>
                        </div>

                    </div>
                </div>
                {/*컨텐츠 컨테이너*/}
                <div style={{borderTop: "solid", marginTop:"30px", borderWidth:"thin 1px", borderColor:"#EAEAEA"}}>
                    <div style={{minHeight:"80px", display:"flex", alignItems:"center", padding:"20px 0"}}>
                        <p style={{margin:"0", fontSize:"14px", height:"100%", fontWeight:"lighter"}} dangerouslySetInnerHTML={{ __html: sanitizedContent }}></p>
                    </div>
                    <div style={{display:"flex", flexWrap:"wrap"}}>
                            {reviewList.map((photo, index) => (
                                <div key={index} style={{width: "calc(52% - 20px)", height:"380px", boxSizing: "border-box", padding:"5px"}}>
                                <img src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/reviewPhoto/" + photo.fileNm} alt="reviewImg"
                                style={{width:"100%", height:"100%", borderRadius:"4px"}}/>
                                </div>
                            ))}
                    </div>
                </div>
                <div style={{marginTop:"50px"}}>
                    <div style={{height:"60px", display:"flex", alignItems:"center"}}>
                        <img style={{width:"25px", height:"25px", marginRight:"5px", opacity:"0.7"}} alt="CommentImage" src={reviewComment}/>
                        <p style={{margin:"0", fontSize:"16px", fontWeight:"bold"}}>{t(`rvdtComt`)}</p>
                        <p style={{margin:"0", color:"darkorange", fontSize:"18px", fontWeight:"bold", marginLeft:"5px"}}>{reviewCommentList.length}</p>
                    </div>
                    {reviewCommentList.map((comment, i) => (
                    <div key={i} style={{borderTop:"solid", borderWidth:"thin 1px", borderColor:"#EEEEEE"}}>
                        <div style={{width:"100%", height:"70px", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                            <div style={{display:"flex", alignItems:"center"}}>
                            <img style={{width:"35px",height:"35px", borderRadius:"100%"}}
                                 src={comment?.memberDTO?.profileDTO?.filename ? "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/"
                                + comment?.memberDTO?.profileDTO?.filename : defaultImg} alt="reviewImg"/>
                            <p style={{margin:"0", marginLeft:"10px"}}>{comment?.memberDTO?.userName}</p>
                            </div>
                            <p style={{margin:"0", fontSize:"12px"}}>{formatDate(comment.regDate)}</p>
                        </div>
                        <div style={{width:"100%", minHeight:"50px", display:"flex", padding:"5px", marginBottom:"5px"}}>
                            <p style={{margin:"0", fontSize:"13px"}} dangerouslySetInnerHTML={{ __html: sanitizeHTML(comment?.content) }}></p>
                        </div>
                    </div>
                        ))}
                    {(LCAT || SSAT) ?
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                        <textarea
                            ref={textareaRef}
                            spellCheck="false"
                            value={reviewCommentText}
                            maxLength={5000}
                            style={{
                                minHeight: "30px",
                                maxHeight: "200px",
                                borderRadius: "6px",
                                width: "89%",
                                borderWidth: "thin 1px",
                                borderColor: "#EEEEEE",
                                resize: "none",
                                overflow: "auto",
                                outline: "none",
                                color: "dimgrey",
                                padding: "10px",
                                fontSize: "14px",
                                ':focus': {
                                    borderColor: "#EEEEEE"
                                }
                            }} onChange={handleCommentTextChange}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault(); // To prevent the default action of the Enter key
                                    saveReviewComment();
                                }
                            }}/>
                            <div style={{width: "10%", display: "flex", alignItems: "flex-start"}}>
                                <button onClick={saveReviewComment} className="colorFullBt" style={{
                                    width: "100%",
                                    height: "60px",
                                    textAlign: "center",
                                    fontSize: "15px",
                                    opacity: "0.6"
                                }}>{t(`rvdtBt`)}
                                </button>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        </div>
    )
}

export default UserReviewDetailPg
