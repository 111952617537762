import React, {useState} from "react";
import warnIcon from '../../../assets/img/warnIcon.png'
import adminMasterPlus from '../../../assets/img/adminMsterPlus.png'
import commonClose2 from '../../../assets/img/commonClose2.png'

import {BsFillPersonCheckFill} from "react-icons/bs";
import axios from "axios";

function AdminMasterAdd({masterAddClose, selectAutoResList, selectMacroList, masterAddMacroPopup}) {

    const LCAT = localStorage.getItem('adminToken');

    const [title, setTitle] = useState('');
    const handleTitle = ((e) => {
        setTitle(e.target.value);
    })

    const saveMaster = () => axios({
        method: 'post',
        url: '/member/saveMaster/',
        headers: {
            Authorization: LCAT
        },
        params: {
            title: title
        }
    })
        .then(() => {
            masterAddClose(false);
            selectAutoResList();
        })

    const saveMacroMaster = () => axios({
        method: 'post',
        url: '/member/saveMacroMaster/',
        headers: {
            Authorization: LCAT
        },
        params: {
            title: title
        }
    })
        .then(() => {
            masterAddClose(false);
            selectMacroList();
        })

    return(
        <div style={{position:"fixed", top:"0", left:"0", width:"100%", height:"100%",
            background:"rgba(0, 0, 0, 0.5)", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <div style={{width:"350px", height:"250px", background:"white", boxShadow:"0 0 10px rgba(0, 0, 0, 0.5)", borderRadius:"6px"}}>

                <div style={{borderBottom:"solid", height:"35%", borderColor:"lightgray", borderWidth:"thin 1px", display:"flex", padding:"10px"}}>
                    <div style={{height:"100%", width:"20%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <img alt='' src={adminMasterPlus} style={{width:"30px", height:"30px"}}/>
                    </div>
                    <div style={{height:"100%", width:"70%", display:"flex", alignItems:"center"}}>
                            <p style={{margin:"0", fontSize:"16px"}}>Master Add</p>
                    </div>
                    <div onClick={()=> masterAddClose(false)} style={{height:"100%", width:"10%", display:"flex", alignItems:"center", cursor:"pointer"}}>
                        <img alt='' src={commonClose2} style={{width:"15px", height:"15px", opacity:"0.6"}}/>
                    </div>
                </div>
                <div style={{height:"30%", display:"flex", alignItems:"flex-end",  justifyContent:"center", width:"100%", padding:"0 30px 0 30px"}}>
                    <input
                        style={{
                            width:"100%",
                            height:"50px",
                            outline: "none",
                            border:"solid",
                            borderWidth: "thin 1px",
                            borderColor: "lightgrey",
                            padding: "10px",
                            borderRadius: "4px",
                            fontSize:"14px"
                        }}
                        maxLength={19}
                        placeholder='Title'
                        onFocus={(e) => e.target.style.border = "2px solid ##FFAF34"}
                        onBlur={(e) => e.target.style.border = "1px solid lightgrey"}
                        value={title}
                        onChange={handleTitle}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                if (masterAddMacroPopup) {
                                    saveMacroMaster();
                                } else {
                                    saveMaster();
                                }
                            }
                        }}
                    />
                </div>
                <div style={{height:"30%", display:"flex", alignItems:"center", padding:"10px", justifyContent:"center"}}>
                    { masterAddMacroPopup ?
                    <button onClick={saveMacroMaster} className="orderItemHandleBt"
                            style={{
                                width: "80px",
                                height: "40px",
                                fontSize: "14px",
                                margin: "0",
                                borderRadius: "4px",
                                marginLeft: "8px"
                            }}>Complete
                    </button>
                        :
                        <button onClick={saveMaster} className="orderItemHandleBt"
                                style={{
                                    width: "80px",
                                    height: "40px",
                                    fontSize: "14px",
                                    margin: "0",
                                    borderRadius: "4px",
                                    marginLeft: "8px"
                                }}>Complete
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}

export default AdminMasterAdd
