import React from 'react';

function MAdminYmdSmallEvent({ startDate, endDate, startHandle, endHandle }) {
    return (
        <div className=" font-s-11 wd-79pc">
            <input className="m-a-ymd-input wd-45pc ht-40" type={"date"}  min="1930-01-01" max="2099-12-31"
                   value={startDate} onChange={startHandle}/>
            <a className="font-t-cont font-b wd-10pc font-s-10" style={{color: "#969696"}}> - </a>
            <input className="m-a-ymd-input wd-45pc ht-40" type={"date"}  min="1930-01-01" max="2099-12-31"
                   value={endDate} onChange={endHandle}/>
        </div>
    );
}

export default MAdminYmdSmallEvent
