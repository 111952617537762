import React, {useEffect, useRef, useState} from "react";
import commonHeader from "../../assets/img/commonHeader.png";
import {useNavigate} from "react-router-dom";

import testze from '../../assets/img/mainFaq.png'
import axios from "axios";
import {BiCaretDown} from "react-icons/bi";
import {useTranslation} from "react-i18next";

function UserFaqPg() {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const moveToDetail = ((seqNum) => {
        navigate(`/UserFaqDtPg/${seqNum}`)
    })

    const [faqMstList, setFaqMstList] = useState([]);
    const [selectedMstTab, setSelectedMstTab] = useState('');

    const selectMstTab = () => axios({
        method: 'get',
        url: '/faq/selectMstTab/',
    })
        .then((response) => {
            const titles = response.data.map(item => item.title);
            setFaqMstList(titles);
        })

    useEffect(() => {
        setSelectedMstTab("전체");
        selectMstTab();
        selectDtList();
    }, [])

    // 여기서부터 커스텀 셀렉트

    const [mstShow, setMstShow] = useState(false);
    const faqMstRef = useRef(null);

    const toggleMstShow = () => {
        setMstShow(!mstShow);
    };

    const handleMstChange = (title) => {
        selectDtList(title);
        setSelectedMstTab(title === null ? "전체" : title);
        setMstShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (faqMstRef.current && !faqMstRef.current.contains(event.target)) {
                setMstShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트

    const [faqDtList, setFaqDtList] = useState([]);
    const selectDtList = (title) => axios({
        method: 'get',
        url: '/faq/selectDtList/',
        params: {
            title: title
        }
    })
        .then((response) => {
            setFaqDtList(response.data);
        })

    const formatDate = (dateStr) => {
        if (dateStr?.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }





    return(
        <div>
            <div style={{
                height: "50px",
                display:"flex",
                alignItems:"center",
                marginBottom:"10px",
                justifyContent:"space-between"
            }}>
                <div style={{display:"flex", alignItems:"center"}}>
                <img style={{width:"25px", height:"25px"}} alt="HeatherImage" src={commonHeader}/>
                <p style={{margin:"0", fontSize:"18px", marginLeft:"5px"}}>{t(`fHeader`)}</p>
                </div>
                <div style={{display:"flex", alignItems:"center", width:"200px", height:"40px"}}>
                    {/*여기서부터 커스텀 배송사셀렉트*/}
                    <div ref={faqMstRef} style={{height: "100%", width: "100%", position: "relative"}}>
                        <button className="selectParentBt" style={{width: "100%", height: "100%"}}
                                onClick={toggleMstShow}>
                            <p style={{margin: "0"}}>{selectedMstTab}</p>
                            <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                <i style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    opacity:"0.5"
                                }}><BiCaretDown/></i>
                            </span>
                        </button>
                        {mstShow ?
                            <div style={{
                                width:"100%",
                                border: "solid",
                                minHeight: "40px",
                                borderWidth: "thin 1px",
                                borderColor: "lightgray",
                                // backgroundColor:"white",
                                zIndex:1,
                                marginTop: "5px",
                            }}>
                                <div className="selectChildBt" value="전체"
                                     onClick={()=> handleMstChange(null)} style={{
                                    display: "flex", padding: "10px", justifyContent: "center",
                                    alignItems: "center", cursor: "pointer", fontSize: "14px"
                                }}>{t(`all`)}</div>
                                {faqMstList.map((mst, index) => (
                                    <div key={index} className="selectChildBt" value={selectedMstTab}
                                         onClick={() => handleMstChange(mst)} style={{
                                        display: "flex", padding: "10px", justifyContent: "center",
                                        alignItems: "center", cursor: "pointer", fontSize: "13px"
                                    }}>{mst}</div>
                                ))}
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
            </div>

            <div style={{minHeight:"500px", border:"solid", borderWidth:"thin 1px", borderColor:"#EAEAEA"}}>
                <div className="user-center" style={{borderBottom:"solid", borderWidth:"thin 2px", borderColor:"lightgray", height:"50px", display:"flex"}}>
                    <div className="user-center" style={{width:"10%"}}><p style={{margin:"0", fontSize:"15px"}}>{t(`fSmallMenu1`)}</p></div>
                    <div className="user-center" style={{width:"10%"}}><p style={{margin:"0", fontSize:"15px"}}>{t(`fSmallMenu5`)}</p></div>
                    <div className="user-center" style={{width:"20%"}}><p style={{margin:"0", fontSize:"15px"}}>{t(`fSmallMenu2`)}</p></div>
                    <div className="user-center" style={{width:"45%"}}><p style={{margin:"0", fontSize:"15px"}}>{t(`fSmallMenu3`)}</p></div>
                    <div className="user-center" style={{width:"15%"}}><p style={{margin:"0", fontSize:"15px"}}>{t(`fSmallMenu4`)}</p></div>
                </div>

                <div style={{padding:"10px 0"}}>
                    <div>
                        {/*고정*/}
                        {faqDtList.filter(data => data.fixFl === true).map((dt, i) => (
                            <div key={i} onClick={()=> moveToDetail(dt.seqNum)} className="user-center" style={{height:"40px", display:"flex", cursor:"pointer"}}>
                                <div className="user-center" style={{width:"10%"}}><p style={{margin:"0", fontSize:"14px", color:"darkorange"}}>{t(`fdFix`)}</p></div>
                                <div className="user-center" style={{width:"10%"}}><p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black"}}>{formatDate(dt?.regDate)}</p></div>
                                <div className="user-center" style={{width:"20%"}}><p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"dimgray"}}>[{dt?.faqMstDTO?.title}]</p></div>
                            <div className="user-center" style={{width:"45%"}}><p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black"}}>{dt?.title}</p></div>
                            <div className="user-center" style={{width:"15%"}}><p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black"}}>{dt?.regNm}</p></div>
                        </div>
                            ))}

                    </div>

                    <div>
                        {faqDtList.filter(data => data.fixFl === false).map((dt, i) => (
                            <div key={i} onClick={()=> moveToDetail(dt.seqNum)} className="user-center" style={{height:"40px", display:"flex", cursor:"pointer"}}>
                            <div className="user-center" style={{width:"10%"}}><p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black"}}>{i+1}</p></div>
                                <div className="user-center" style={{width:"10%"}}><p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black"}}>{formatDate(dt?.regDate)}</p></div>
                                <div className="user-center" style={{width:"20%"}}><p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"dimgray"}}>[{dt?.faqMstDTO?.title}]</p></div>
                            <div className="user-center" style={{width:"45%"}}><p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black"}}>{dt?.title}</p></div>
                            <div className="user-center" style={{width:"15%"}}><p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black"}}>{dt?.regNm}</p></div>
                        </div>
                            ))}

                    </div>

                </div>

            </div>

        </div>
    )
}

export default UserFaqPg
