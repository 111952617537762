import React from 'react';
import { Navigate } from 'react-router-dom';

const withAuth = (Component) => {
    return (props) => {
        // LocalStorage에서 토큰을 조회
        const localToken = localStorage.getItem('AToken');
        const sesstionToken = sessionStorage.getItem('AToken');


        if (!localToken && !sesstionToken) {
            return <Navigate to="/UserLoginPg" replace />;
        }

        // 토큰이 있다면 원래의 컴포넌트를 그대로 렌더링
        return <Component {...props} />;
    };
};

export default withAuth;
