import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../../css/web/AdminDashBoardPg.css';
import {Link, useNavigate} from "react-router-dom";
import TopBtn from "../../components/web/layoutsadmin/TopBtn";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import AdminYmdEvent from "../../components/web/layoutsadmin/AdminYmdEvent";
import AdminSearchEvent from "../../components/web/layoutsadmin/AdminSearchEvent";
import {Tab, Table, Tabs} from "react-bootstrap";
import {FiMinus, FiPlus} from "react-icons/fi";
import BtorageWriteLogo from "../../assets/img/BtorageLogo_black.png";
import {BiCaretDown} from "react-icons/bi";
import {TbArrowBarToUp} from "react-icons/tb";
import DeliveryPriceDetail from "../../components/web/modal/DeliveryPriceDetail";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import MsgConfirmModal from "../../components/web/modal/MsgConfirmModal";
import MsgCheckModal from "../../components/web/modal/MsgCheckModal";
import axios from "axios";
import {orderStatusUpdate} from "../../services/AutoChangeOrderSt";
import mypgOrder from "../../assets/img/mypgOrder.png";
import mypgDelivery from "../../assets/img/mypgDelivery.png";
import mypgReturn from "../../assets/img/mypgReturn.png";
import {PiAirplaneTakeoff, PiPackageLight} from "react-icons/pi";
import '../../css/web/AdminOrderList.css'
import deposit from '../../assets/img/depositPay.png'
import point from '../../assets/img/pointPay.png'
import SaveMsgModal from "../../components/web/modal/SaveMsgModal";
import {RxCheck, RxCopy} from "react-icons/rx";
import {useWebSocket} from "../../services/WebsocketContext";

function AdminDashBoardPg() {

    /** region DataTable */

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [ordReceiptCountData, setOrdReceiptCountData] = useState(0);

    /** endregion DataTable*/

    /** region Function */

    const [coopCont, setcoopCont] = useState("");
    const Cont = (e) => {setcoopCont(e.target.value);};
    const [ordData, setOrdData] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [payinfoList, setPayinfoList] = useState([{}]);
    const [recinfoList, setRecinfoList] = useState([{}]);
    const [ordermemoList, setOrderMemoList] = useState([{}]);
    const [memberInfo, setMemberInfo] = useState("");
    const [adminInfo, setAdminInfo] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredList, setFilteredList] = useState([]);
    const LCAT = localStorage.getItem('adminToken');

    const [isOpen, setMenu] = useState(false);
    const [isOpenDTord, setIsOpenDTord] = useState(false);
    const [isOpenDToffline, setIsOpenDToffline] = useState(false);
    const [isOpenDTtransfer, setIsOpenDTtransfer] = useState(false);
    const [isOpenDTdomestic, setIsOpenDTdomestic] = useState(false);
    const [isOpenDTdelivery, setIsOpenDTdelivery] = useState(false);
    const [selectedShipCompany, setSelectedShipCompany] = useState("배송사선택");
    const [userInfoShow, setUserInfoShow] = useState(false);

    const [memoModiData, setMemoModiData] = useState({});

    const handleInputMemoChange = (e, seqnum, memoType) => {
        const updatedMemoModiData = { ...memoModiData, [`${seqnum}-${memoType}`]: e.target.value };
        setMemoModiData(updatedMemoModiData);

        const updatedData = ordData.map(data => {
            if (data.seqnum === seqnum) {
                return { ...data, [memoType]: { ...data[memoType], ordDtCont: e.target.value }};
            }
            return data;
        });
        setOrdData(updatedData);
    };

    const onKeyPress = (e, seqnum, memoType) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const value = memoModiData[`${seqnum}-${memoType}`] || '';
            ordDtMemoSave(seqnum, value, memoType);
        }
    };

    const inputRefs = useRef({});

    // websocket
    const stompClientRef = useRef(null);
    const { subscribe, unsubscribe, websocketConnect, isConnected, stompClient } = useWebSocket();
    useEffect(() => {
        if (!adminInfo && !adminInfo.mbNum) return;
        const initializeSubscription = async () => {
            if (!isConnected || !stompClient) {
                stompClientRef.current = await websocketConnect(adminInfo.mbNum);
            } else {
                stompClientRef.current = stompClient;
            }
            const subscriptions = [];
            subscriptions.push(subscribe(`/topic/adminOrderStUpdate`, orderStReceived));
            subscriptions.push(subscribe(`/topic/userOrderData`, orderDataReceived));
            subscriptions.push(subscribe(`/topic/depConfirmAlarm`, depositDataReceived));
            subscriptions.push(subscribe(`/topic/depReviewAlarm`, reviewDataReceived));
            subscriptions.push(subscribe(`/topic/addTotalCount`, chatDataReceived));
            stompClientRef.current = subscriptions;
        };
        initializeSubscription();
        return () => {
            if (stompClientRef.current && stompClientRef.current.length > 0) {
                // 구독이 존재하는 경우에만 해제
                stompClientRef.current.forEach((sub) => {
                    unsubscribe(sub);
                });
            }
            stompClientRef.current = null;
        };
    }, [adminInfo, isConnected, subscribe, unsubscribe, stompClient]);




    const handleClick = () => {
        setUserInfoShow(prevUserInfoShow => !prevUserInfoShow);
    };

    const toggleMenu = (id) => {
        setMenu(isOpen => !isOpen);
        setOrdData((prevData) => {
            return prevData.map((cData) => {
                if (cData.seqnum === id) {
                    return { ...cData, isOpen: !cData.isOpen };
                }
                return cData;
            });
        });
    };

    const [adminProdMemos, setAdminProdMemos] = useState({});
    const handleProdMemoChange = (seqnum, value) => {
        setAdminProdMemos(prevMemos => ({
            ...prevMemos,
            [seqnum]: value
        }));
    };

    const [saveMsgSt, setSaveMsgSt] = useState(false);
    const [savePopupMsg, setSavePopupMsg] = useState('');
    const modifyAdminProdMemo = ((seqnum, memo) => {
        axios({
            method: 'post',
            url: '/order/modifyAdminProdMemo',
            headers: {
                Authorization: LCAT
            },
            data: {
                seqNum: seqnum,
                memo: memo
            }
        })
            .then((response) => {
                setSavePopupMsg("저장 완료");
                setSaveMsgSt(true);
                setTimeout(() => setSaveMsgSt(false), 1000);
            })
            .catch((error) => {
                setSavePopupMsg("저장 실패");
                setSaveMsgSt(true);
                setTimeout(() => setSaveMsgSt(false), 1000);
            })
    })

    const saveProdMemo = (e, seqnum) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            const memo = adminProdMemos[seqnum] || '';
            modifyAdminProdMemo(seqnum, memo);
        }
    };

    useEffect(() => {
        const initialMemos = {};
        // 'orderdt' 타입의 항목만 필터링
        const orderDetails = filteredList.filter(item => item.type === 'orderdt');
        orderDetails.forEach(order => {
            order.productsDTO.forEach(prod => {
                // 각 상품seqnum마다 adminMemo 맵핑
                initialMemos[prod.seqnum] = prod.adminMemo;
            });
        });
        // 초기 메모 상태 설정
        setAdminProdMemos(initialMemos);
    }, [filteredList]);

    function formatDateTime(inputDateTime) {
        const date = new Date(inputDateTime);
        const year = date.getFullYear().toString().slice(-2);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }

    //region Alert

    const [alertShow, setAlertShow] = useState(false);
    const [alertShow2, setAlertShow2] = useState(false);
    const [alertShow3, setAlertShow3] = useState(false);
    const msgConfirmData1 = "저장 하시겠습니까?";
    const msgConfirmData2 = "입력한 값을 확인해주세요.";
    const msgConfirmData3 = "정말 삭제하시겠습니까?";
    const msgConfirmData4 = "복구가 불가능합니다.";
    const msgConfirmData5 = "등록된 정보를 취소 하시겠습니까?";
    const msgConfirmData6 = "취소된 정보는 복구가 불가능합니다.";
    const alert = (() => {
        setAlertShow(true)
    })
    const alert2 = (() => {
        setAlertShow2(true)
    })
    const alert3 = ((cData) => {
        setSelectedData(cData);
        setAlertShow3(true)
    })
    const modalCancel = useCallback(() => {
        setAlertShow(false);
    })
    const modalCancel2 = useCallback(() => {
        setAlertShow2(false);
    })
    const modalCancel3 = useCallback(() => {
        setAlertShow3(false);
    })

    //endregion

    //region CheckBox

    const [noticeChecked, setNoticeChecked] = useState([]);

    const noticeCheckBoxChange = (id) => {
        setNoticeChecked((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter((item) => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    //endregion

    //region Search

    const [searchInput, setSearchInput] = useState('');
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    //endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);
    const [orderCheckedCount, setOrderCheckedCount] = useState(0);

    const checkBoxChange = (id, type) => {
        setChecked((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                // 이미 체크된 경우 해제 로직
                const newChecked = prevSelectedData.filter((item) => item !== id);
                // orderdt 타입만 orderCheckedCount 조정
                if (type === 'orderdt') {
                    setOrderCheckedCount(prev => Math.max(0, prev - 1));
                }
                return newChecked;
            } else {
                const newChecked = [...prevSelectedData, id];
                // orderdt 타입만 orderCheckedCount 조정 = 프로세스 결제대기(배송/PS0)에서 예적금결제 제한을 위함
                if (type === 'orderdt') {
                    setOrderCheckedCount(prev => prev + 1);
                }
                return newChecked;
            }
        });
    };

    const allcheckBoxChange = (e) => {
        if (e.target.checked) {
            // 전체 체크하는 경우
            const allIds = filteredList.map(item => item.seqNum || item.seqnum);
            setChecked(allIds);
            // orderdt 타입 개수만큼 orderCheckedCount 설정
            const orderdtCount = filteredList.filter(item => item.type === 'orderdt').length;
            setOrderCheckedCount(orderdtCount);
        } else {
            // 전체 해제하는 경우
            setChecked([]);
            setOrderCheckedCount(0);
        }
    };

    //endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(15);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const pageChange = (currentPage) => {
        setCurrentPage(currentPage);
    };
    const handleSelectChange = (e) => {
        setperPg(parseInt(e.target.value));
        setCurrentPage(1);
    };

    //endregion

    //region DateSelect

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const startHandle = (e) => {setStartDate(e.target.value);};
    const endHandle = (e) => {setEndDate(e.target.value);};
    const stringStartDate = startDate.replace(/-/g, '');
    const stringEndDate = endDate.replace(/-/g, '');

    const currentDate = new Date();
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(currentDate.getDate() - 90); //3개월

    const defaultMonth = () => {
        const stringStartDate = formatDate(twoWeeksAgo);
        const stringEndDate = formatDate(currentDate);
        setStartDate(stringStartDate);
        setEndDate(stringEndDate);
    }

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    //endregion DateSelect

    const orderStPrint = (orderSt) => {
        const orderStStyles = {
            OS00: { color: "#98B0D4", label: "입고대기" },
            OS01: { color: "#98B0D4", label: "결제대기(상품)" },
            OS02: { color: "#98B0D4", label: "결제중(상품)" },
            OS03: { color: "#FFB378", label: "결제완료(상품)" },
            OS04: { color: "#98B0D4", label: "구매중" },
            OS05: { color: "#FFB378", label: "구매완료" },
            OS06: { color: "#98B0D4", label: "입고중" },
            OS07: { color: "#6EDA60", label: "센터도착" },
            OS08: { color: "#98B0D4", label: "패킹중" },
            OS09: { color: "#98B0D4", label: "결제대기(배송)" },
            OS10: { color: "#98B0D4", label: "결제중(배송)" },
            OS11: { color: "#FFB378", label: "결제완료(배송)" },
            OS12: { color: "#979696", label: "출고완료" },
            OS13: { color: "#FF8F62", label: "반송중" },
            OS14: { color: "#FF8F62", label: "신청취소" },
        };

        const style = orderStStyles[orderSt] || { color: "black", label: null };
        return <p style={{ margin: "0", color: style.color, fontSize: "12px"}}>{style.label}</p>;
    };

    const prodStPrint = (prodSt) => {
        const prodStStyles = {
            IS00: { color: "#98B0D4", label: "입고대기" },
            IS01: { color: "#98B0D4", label: "결제대기(상품)" },
            IS02: { color: "#98B0D4", label: "결제중(상품)" },
            IS03: { color: "#FFB378", label: "결제완료(상품)" },
            IS04: { color: "#FF8600", label: "구매완료" },
            IS05: { color: "#6EDA60", label: "센터도착" },
            IS06: { color: "#98B0D4", label: "패킹중" },
            IS07: { color: "#98B0D4", label: "결제대기(배송)" },
            IS08: { color: "#98B0D4", label: "결제중(배송)" },
            IS09: { color: "#FFB378", label: "결제완료(배송)" },
            IS10: { color: "#979696", label: "출고완료" },
            IS11: { color: "#FF8F62", label: "반송중" },
            IS12: { color: "#FF8F62", label: "신청취소" },
            IS13: { color: "#98B0D4", label: "구매중" },
            IS14: { color: "#98B0D4", label: "입고중" }
        };

        const style = prodStStyles[prodSt] || { color: "black", label: null };
        return <p style={{ margin: "0", color: style.color, fontSize: "14px" }}>{style.label}</p>;
    };

    // 메인알림로직
    const [lcCartProd, setLcCartProd] = useState([]);
    const [lcOrderProd, setLcOrderProd] = useState([]);
    const [lcOfflineProd, setLcOfflineProd] = useState([]);
    const [lcTransferProd, setLcTransferProd] = useState([]);
    const [lcDomesticProd, setLcDomesticProd] = useState([]);
    const [lcDeliveryProd, setLcDeliveryProd] = useState([]);
    const [lcPayData, setLcPayData] = useState([]);
    const [lcDepositData, setLcDepositData] = useState([]);
    const [lcReviewData, setLcReviewData] = useState([]);
    const [lcChatData, setLcChatData] = useState(0);

    const selectAlarmData = (() => {
        axios({
            method: 'get',
            url: '/order/selectAlarmData',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                const data = response.data;
                // 새 상태값을 저장할 임시 객체 생성
                const newState = {
                    lcCartProd: [],
                    lcOrderProd: [],
                    lcOfflineProd: [],
                    lcTransferProd: [],
                    lcDomesticProd: [],
                    lcDeliveryProd: [],
                };

                if (data.prodList.length > 0) {
                    // 순회하여 각 상태값에 새로운 배열을 할당
                    data.prodList.forEach((prod) => {
                        switch (prod.prodTy) {
                            case 'P00':
                                newState.lcCartProd.push(prod);
                                break;
                            case 'P01':
                                newState.lcOrderProd.push(prod);
                                break;
                            case 'P02':
                                newState.lcOfflineProd.push(prod);
                                break;
                            case 'P03':
                                newState.lcTransferProd.push(prod);
                                break;
                            case 'P04':
                                newState.lcDomesticProd.push(prod);
                                break;
                            case 'P05':
                                newState.lcDeliveryProd.push(prod);
                                break;
                            default:
                                break;
                        }
                    });

                    // 새로운 상태값으로 각 상태 업데이트
                    setLcCartProd(newState.lcCartProd);
                    setLcOrderProd(newState.lcOrderProd);
                    setLcOfflineProd(newState.lcOfflineProd);
                    setLcTransferProd(newState.lcTransferProd);
                    setLcDomesticProd(newState.lcDomesticProd);
                    setLcDeliveryProd(newState.lcDeliveryProd);
                }

                if (data.payList.length > 0) {
                    setLcPayData(data.payList)
                }

                if (data.depositList.length > 0) {
                    setLcDepositData(data.depositList)
                }

                if (data.reviewList.length > 0) {
                    setLcReviewData(data.reviewList)
                }

            })
            .catch((error) => {
                console.log("알림데이터 조회실패 : ", error)
            })
    });

    const selectChatCount = (() => {
        axios({
            method: 'get',
            url: '/order/selectChatCount',
        })
            .then((response) => {
                const totalChats = response.data.ordChat + response.data.nonUserChat;
                setLcChatData(totalChats);
                // 총채팅개수 각각 산출
                // setLcUserChatCount(response.data.userChat);
                // setLcNUserChatCount(response.data.nonUserChat);
            })
            .catch((error) => console.error(error))
    })

    useEffect(() => {
        selectAlarmData();
        selectChatCount()
    }, [])

    const moveToAlarmListPg = ((type, count) => {
        if (count > 0) {
            const stateData = {
                cart: lcCartProd,
                order: lcOrderProd,
                offline: lcOfflineProd,
                transfer: lcTransferProd,
                domestic: lcDomesticProd,
                delivery: lcDeliveryProd,
                pay: lcPayData,
                deposit: lcDepositData,
                review: lcReviewData
            };

            navigate(`/btcorpad/alarmlist/${type}`, { state: stateData[type] });
        }
    })

    const orderDataReceived = ((payload) => {
        let orderData = JSON.parse(payload.body);
        selectAlarmData();
        selectedAllProcessCount();
    })

    const depositDataReceived = ((payload) => {
        let depositData = JSON.parse(payload.body);
        selectAlarmData();
        selectedAllProcessCount();
    })

    const reviewDataReceived = ((payload) => {
        let reviewData = JSON.parse(payload.body);
        selectAlarmData();
        selectedAllProcessCount();
    })

    const chatDataReceived = ((payload) => {
        let chatData = JSON.parse(payload.body);
        selectChatCount();
    })

    const [copiedIndexes, setCopiedIndexes] = useState([]);

    const handleCopy = (text, index) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setCopiedIndexes([...copiedIndexes, index]);
                setTimeout(() => {
                    setCopiedIndexes(copiedIndexes.filter(i => i !== index));
                }, 1000);
            })
            .catch((error) => {console.error("실패: ", error);});
    };

    /** endregion Function */

    /** region Event */

    useEffect(() => {
        coopSelect();
        ordReceiptCount();
    }, [])

    const coopSelect = () => {
        axios.get('/admincoop/admincoopselect')
            .then((response) => {
                setData(response.data)
                setcoopCont("");
            }, [])
            .catch()
    }

    const coopSave = () => {
        if (coopCont === "") {
            return false;
        } else {
            axios.post('/admincoop/admincoopsave', {
                coopCont: coopCont
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: LCAT
                }})
                .then(() => {
                    coopSelect();
                })
                .catch(() => {})
        }
    }

    const coopDataDelete = () => {
        setChecked([]);
        axios
            .delete('/admincoop/admincoopdelete', { data: noticeChecked })
            .then(() => {
                coopSelect();
                setNoticeChecked([]);
                setAlertShow2(false);
            })
            .catch(() => {})
    };

    const ordReceiptCount = (() => {
        axios({
            method: 'get',
            url: '/order/receiptCount',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setOrdReceiptCountData(response.data);
            })
            .catch(() => {})
    })

    const selectedAdminInfo = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setAdminInfo(response.data)
            })
            .catch((error) => {
                console.log("Admin 조회실패 : ", error)
            })
    })

    useEffect(() => {
        selectedAdminInfo();
        selectAllData();
    }, [])


    useEffect(() => {
        const timer = setTimeout(() => {
            defaultMonth();
        })
        return () => {
            clearTimeout(timer);
        }
    }, [endDate])

    const selectAllData = (() => {
        axios({
            method: 'get',
            url: '/order/selectAllMainData',
            headers: {
                Authorization: LCAT
            },
            params: {
                startDate: stringStartDate,
                endDate: stringEndDate,
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => {
                    return new Date(a.modiTime) - new Date(b.modiTime);
                });
                setOrdData(sortedData);
                // setOrdData(response.data);

                const ProdList = response.data.map(order => {
                    const productMap = new Map();
                    order.productsDTO.forEach(product => {
                        if (!productMap.has(product.seqnum)) {
                            productMap.set(product.seqnum, product);
                        }
                    });
                    return Array.from(productMap.values());
                });
                setProductsList(ProdList);

                const payList = response.data.flatMap(order => order.payInfoDTO);
                setPayinfoList(payList);

                const recList = response.data.map(order => order.recinfoDTO);
                setRecinfoList(recList);

                setMemberInfo(response.data[0].memberDTO)
            })
            .catch(() => {})
    })

    // axios.interceptors.request.use(request => {
    //     console.log('관리자메인 요청 API 리스트', request);
    //     return request;
    // });

    const [orderStSelectCommonValue, setOrderStSelectCommonValue] = useState("상태(전체)");

    const convertToShipco = ((dvType, defalutShipco) => {
        if (dvType === "D00") {
            return "국내배송"
        } else if (dvType === "D01") {
            return "FedEx"
        } else if (dvType === "D02") {
            return "EMS"
        } else if (dvType === "D03") {
            return "SHIP"
        } else if (dvType === "D11") {
            return "UPS"
        } else if (dvType === "D13") {
            return "K-Packet"
        } else if (dvType === "D14") {
            return "Small-Packet"
        } else {
            return defalutShipco;
        }
    })

    useEffect(() => {
        const combined = [
            ...ordData.map((item) => ({
                type: 'orderdt',
                date: item.modiTime || item.regTime,
                mostRecentDate: new Date(Math.max(new Date(item.modiTime), new Date(item.regTime))),
                ...item,
            })),
            ...payinfoList.map((item) => ({
                type: 'payinfo',
                date: item?.modiTime || item?.regTime,
                mostRecentDate: new Date(Math.max(new Date(item.modiTime), new Date(item.regTime))),
                ...item,
            })),
        ];

        combined.sort((a, b) => b.mostRecentDate - a.mostRecentDate);
        // combined.sort((a, b) => moment(b.date).isAfter(moment(a.date)) ? 1 : 0);

        const filterStatus = (order) => {
            if (orderStSelectCommonValue === "상태(전체)") return true;
            switch (orderStSelectCommonValue) {
                case "PS0":
                    return (order.type === 'orderdt' && order.orderStatus === "OS09") ||
                        (order.type === 'payinfo' && order.payStatus === "PS0");
                case "PS1":
                    return (order.type === 'orderdt' && order.orderStatus === "OS10") ||
                        (order.type === 'payinfo' && order.payStatus === "PS1");
                case "PS2":
                    return (order.type === 'orderdt' && order.orderStatus === "OS11") ||
                        (order.type === 'payinfo' && order.payStatus === "PS2");
                case "PS3":
                    return (order.type === 'orderdt' && order.orderStatus === "OS12") ||
                        (order.type === 'payinfo' && order.payStatus === "PS3");
                case "PS4":
                    return (order.type === 'orderdt' && order.orderStatus === "OS13") ||
                        (order.type === 'payinfo' && order.payStatus === "PS4");
                case "PS5":
                    return (order.type === 'orderdt' && order.orderStatus === "OS14") ||
                        (order.type === 'payinfo' && order.payStatus === "PS5");
                default:
                    return (order.type === 'orderdt' && order.orderStatus === orderStSelectCommonValue) ||
                        (order.type === 'payinfo' && order.payStatus === orderStSelectCommonValue);
            }
        }

        const filterShipCo = (order) => {
            if (selectedShipCompany === "배송사선택") return true;
            switch (selectedShipCompany) {
                case "FedEx":
                    return (order.type === 'orderdt' && order.shipco === "FedEx") ||
                        (order.type === 'payinfo' && order.dvType === "D01");
                case "EMS":
                    return (order.type === 'orderdt' && order.shipco === "EMS") ||
                        (order.type === 'payinfo' && order.dvType === "D02");
                case "SHIP":
                    return (order.type === 'orderdt' && order.shipco === "SHIP") ||
                        (order.type === 'payinfo' && order.dvType === "D03");
                case "국내배송":
                    return (order.type === 'orderdt' && order.shipco === "국내배송") ||
                        (order.type === 'payinfo' && order.dvType === "D00");
                // case "DHL":
                //     return (order.type === 'orderdt' && order.shipco === "DHL") ||
                //         (order.type === 'payinfo' && order.dvType === "D04");
                case "UPS":
                    return (order.type === 'orderdt' && order.shipco === "UPS") ||
                        (order.type === 'payinfo' && order.dvType === "D11");
                case "K-Packet":
                    return (order.type === 'orderdt' && order.shipco === "K-Packet") ||
                        (order.type === 'payinfo' && order.dvType === "D013");
                case "Small-Packet":
                    return (order.type === 'orderdt' && order.shipco === "Small-Packet") ||
                        (order.type === 'payinfo' && order.dvType === "D14");
                default:
                    return (order.type === 'orderdt' && order.shipco === selectedShipCompany) ||
                        (order.type === 'payinfo' && convertToShipco(order.dvType, order.orderdtDTO?.shipco) === selectedShipCompany);
            }
        }

        const matchesSearch = (value) => {
            const searchString = searchInput.toLowerCase().trim();
            if (typeof value === 'number') {
                const prefixedValue = `BT${value}`;
                return searchString === prefixedValue.toLowerCase();
            }
            return value?.toString().toLowerCase().includes(searchString);
        };

        const newList = combined.filter((order) => {
            if (!filterStatus(order)) return false;
            if (!filterShipCo(order)) return false;
            if (!searchInput.trim()) return true;

            if (order.type === 'orderdt') {
                return (
                    matchesSearch(order.orderNumber) ||
                    matchesSearch(order.shipco) ||
                    matchesSearch(order.memberDTO?.mbNum) ||
                    matchesSearch(order.memberDTO?.userName) ||
                    matchesSearch(order.recinfoDTO?.recname) ||
                    matchesSearch(order.recinfoDTO?.countryDTO?.countryEngNm) ||
                    matchesSearch(order.recinfoDTO?.countryDTO?.countryNm) ||
                    matchesSearch(order.recinfoDTO?.country) ||
                    matchesSearch('주문서') ||
                    order.productsDTO?.some((prod) => matchesSearch(prod?.option)) ||
                    order.productsDTO?.some((prod) => matchesSearch(prod?.name))
                );
            } else if (order.type === 'payinfo') {
                return (
                    matchesSearch(order.orderdtDTO?.orderNumber) ||
                    matchesSearch(convertToShipco(order.dvType, order.orderdtDTO.shipco)) ||
                    matchesSearch(order.orderdtDTO?.memberDTO?.mbNum) ||
                    matchesSearch(order.orderdtDTO?.memberDTO?.userName) ||
                    matchesSearch(order.appRecDTO?.recname) ||
                    matchesSearch(order.appRecDTO?.countryDTO?.countryEngNm) ||
                    matchesSearch(order.appRecDTO?.countryDTO?.countryNm) ||
                    matchesSearch(order.appRecDTO?.country) ||
                    matchesSearch(order.appOrderMemoDTO?.ordDtCont) ||
                    matchesSearch('배송료') ||
                    order.packListDTO?.productsDTO?.some((prod) => matchesSearch(prod?.option)) ||
                    order.packListDTO?.productsDTO?.some((prod) => matchesSearch(prod?.name))
                );
            }
        });

        setFilteredList(newList);
    }, [ordData, payinfoList, searchInput, orderStSelectCommonValue, selectedShipCompany]);


    const orderContentRef = useRef(null);
    const scrollToOrderContent = () => {
        if (orderContentRef.current) {
            orderContentRef?.current.scrollIntoView({behavior: 'smooth'});
        }
    };

    const [selectedProcessSt, setSelectedProcessSt] = useState('');
    const selectNoDateTotalOrderList = ((orderSt) => {
        if (orderSt === "OS01" || orderSt === "PS0" || orderSt === "PS3") {
            setChecked([]);
        }
        setSelectedProcessSt(orderSt);
        axios({
            method: 'get',
            url: '/order/selectAllMainData',
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => {
                    return new Date(a.modiTime) - new Date(b.modiTime);
                });
                setOrdData(sortedData);

                const ProdList = response.data.map(order => {
                    const productMap = new Map();
                    order.productsDTO.forEach(product => {
                        if (!productMap.has(product.seqnum)) {
                            productMap.set(product.seqnum, product);
                        }
                    });
                    return Array.from(productMap.values());
                });
                setProductsList(ProdList);

                const payList = response.data.flatMap(order => order.payInfoDTO);
                setPayinfoList(payList);

                const recList = response.data.map(order => order.recinfoDTO);
                setRecinfoList(recList);

                scrollToOrderContent();
                setOrderStSelectCommonValue(orderSt);
                // 추후 개월수 로직 완료되면 개월값을 default로 변경예정
                // setPeriodSelectCommonValue("전체")

            })
            .catch((error) => {
                console.log("노데이트 주문조회실패 : ", error)
            })
    })

    //region Modal-blank

    let WayModal = null;
    const width = 850;
    const height = 900;
    const left = (window.innerWidth / 2) - (width / 2);
    const top = (window.innerHeight / 2) - (height / 2);

    const wayModalRef = useRef(null);

    const openPayModal = (data, type) => {
        const combinedData = {
            ...data,
            type,
        };
        const url = `/btcorpad/btad-Payreg2`;
        const width = 660;
        const height = 840;
        const left = (window.innerWidth / 2) - (width / 2);
        const top = (window.innerHeight / 2) - (height / 2);

        const popup = window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top},scrollbars=yes`);

        if (popup) {
            localStorage.setItem('ordData', JSON.stringify(combinedData));
        } else {
            console.error('Popup open error');
        }
    };

    const openModal = (rowData, dataType) => {
        const combinedData = {
            ...rowData,
            dataType,
        };

        localStorage.setItem('orderData', JSON.stringify(combinedData));

        const url = 'btad-Payreg';

        if (wayModalRef.current && !wayModalRef.current.closed) {
            wayModalRef.current.location.href = `/btcorpad/${url}`;
            wayModalRef.current.focus();
        } else {
            wayModalRef.current = window.open(`/btcorpad/${url}`, '_blank', `width=${width},height=${height},left=${left},top=${top},scrollbars=yes`);
        }
    };

    const openModalMerge = (rowData) => {
        localStorage.setItem('orderData', JSON.stringify(rowData));
        if (WayModal && !WayModal.closed) {
            WayModal.location.href = '/btcorpad/btad-Merge';
            WayModal.focus();
        } else {
            WayModal = window.open('/btcorpad/btad-Merge', '_blank', `width=${width},height=${height},left=${left},top= ${top}, scrollbars=yes`);
        }
        // 주기적으로 팝업 창이 닫혔는지 확인후 닫혔으면 order 재조회
        const checkPopupClosed = setInterval(async () => {
            if (!WayModal || WayModal.closed) {
                clearInterval(checkPopupClosed);
                // 분리/통합 여부 상관없이 팝업창만 닫아도 재조회하고 있어서 나중에
                selectAllData();
            }
        }, 2000);
    };

    const openModalDivide = (rowData) => {
        localStorage.setItem('orderData', JSON.stringify(rowData));
        if (WayModal && !WayModal.closed) {
            WayModal.location.href = '/btcorpad/btad-Divide';
            WayModal.focus();
        } else {
            WayModal = window.open('/btcorpad/btad-Divide', '_blank', `width=${width},height=${height},left=${left},top= ${top}, scrollbars=yes`);
        }
        // 주기적으로 팝업 창이 닫혔는지 확인후 닫혔으면 order 재조회
        const checkPopupClosed = setInterval(async () => {
            if (!WayModal || WayModal.closed) {
                clearInterval(checkPopupClosed);
                selectAllData();
            }
        }, 2000);
    };

    //endregion

    // region Excel

    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const DateViewValue = year + month + day;

    //region 기존 엑셀(전체)

    const excelDownOrder = () => {
        try {
            const allData = [...filteredList];
            const selectedRows = allData.filter((item) => checked.includes(item.seqnum || item.seqNum));
            const excel = require('exceljs');
            const workbook = new excel.Workbook();
            workbook.created = new Date();
            workbook.modified = new Date();

            function sumProductQuantities(products) {
                return products ? products.reduce((total, product) => total + (product.quantity || 0), 0) : 0;
            }

            function sumProductPrices(products) {
                return products ? products.reduce((total, product) => total + (product.price || 0), 0) : 0;
            }

            function displayProductNames(products) {
                if (!products || products.length === 0) return '';
                const firstProductName = products[0].name;
                const additionalProductCount = products.length - 1;
                return additionalProductCount === 0 ? firstProductName : `${firstProductName} 외 ${additionalProductCount}개···`;
            }

            function interpretOrderStatus(status) {
                const statusMap = {
                    'OS01': '입고대기',
                    'OS02': '구매완료',
                    'OS03': '결제대기(상품)',
                    'OS07': '센터도착',
                    'OS09': '결제대기(배송)',
                    'OS12': '출고완료',
                };
                return statusMap[status] || 'N/A';
            }

            function interpretPayStatus(status) {
                const statusMap = {
                    'PS0': '결제대기',
                    'PS1': '결제중',
                    'PS2': '결제완료',
                    'PS3': '출고완료',
                };
                return statusMap[status] || 'N/A';
            }

            function getSafeValue(obj, key, defaultValue = '') {
                return obj && obj[key] !== undefined ? obj[key] : defaultValue;
            }

            function getSafeArray(obj, key) {
                return obj && Array.isArray(obj[key]) ? obj[key] : [];
            }

            function mapDataToExcelRowOrder(item) {
                const recName = item.type === 'orderdt'
                    ? getSafeValue(item.recinfoDTO, 'recname', 'N/A')
                    : getSafeValue(item.appRecDTO, 'recname', 'N/A');
                // const recName = item.type === 'orderdt' ? item.recinfoDTO.recname : item.appRecDTO.recname;
                const productsDTO = item.type === 'orderdt'
                    ? getSafeArray(item, 'productsDTO')
                    : getSafeArray(item.packListDTO, 'productsDTO');
                // const productsDTO = item.type === 'orderdt' ? item.productsDTO : item.packListDTO.productsDTO;
                const totalProductQuantity = sumProductQuantities(productsDTO);
                const totalProducPrice = sumProductPrices(productsDTO);
                const totalProductName = displayProductNames(productsDTO);
                const orderNumber = item.type === 'orderdt' ? getSafeValue(item, 'orderNumber', 'N/A')
                    : `${getSafeValue(item.orderdtDTO, 'orderNumber', 'N/A')}-${getSafeValue(item.shipInfoDTO, 'boxNum', 'N/A')}`;

                const countryDTO = item.type === 'orderdt'
                    ? getSafeValue(item.recinfoDTO, 'countryDTO', {})
                    : getSafeValue(item.appRecDTO, 'countryDTO', {});

                return {
                    '여백': '',
                    '주문번호': orderNumber,
                    '주문서유형': item.type === 'orderdt' ? '주문서' : '배송료',
                    '사서함번호': item.type === 'orderdt' ? 'BT' + getSafeValue(item.memberDTO, 'mbNum', 'N/A') : 'BT' + getSafeValue(item.orderdtDTO.memberDTO, 'mbNum', 'N/A'),
                    '고객명': item.type === 'orderdt' ? getSafeValue(item.memberDTO, 'userName', 'N/A') : getSafeValue(item.orderdtDTO.memberDTO, 'userName', 'N/A'),
                    '수취인명': recName,
                    '국가': `${getSafeValue(countryDTO, 'countryNm', 'N/A')}[${getSafeValue(countryDTO, 'countryCode', 'N/A')}]`,
                    '상품명': totalProductName,
                    '상태구분': item.type === 'orderdt' ? interpretOrderStatus(item.orderStatus) : interpretPayStatus(item.payStatus),
                    '배송사': item.type === 'orderdt' ? getSafeValue(item, 'shipco', 'N/A') : getSafeValue(item.orderdtDTO, 'shipco', 'N/A'),
                    '운송장번호': item.type === 'orderdt' ? 'N/A' : getSafeValue(item.shipInfoDTO, 'outWayNum', 'N/A'),
                    '세관내용품명': item.type === 'orderdt' ? 'N/A' : getSafeValue(item.customsDTO, 'custContent', 'N/A'),
                    '세관신고수량': item.type === 'orderdt' ? 'N/A' : getSafeValue(item.customsDTO, 'custQty', 'N/A'),
                    '세관신고금액': item.type === 'orderdt' ? 'N/A' : getSafeValue(item.customsDTO, 'custPrice', 'N/A'),
                    '미결제(상품)': totalProducPrice,
                    '총결제(상품)': 0,
                    '미결제(기타)': 0,
                    '총결제(기타)': 0,
                    '상품수량': totalProductQuantity,
                    'FedEx': item.type === 'orderdt' ? 0 : getSafeValue(item, 'ttFedexPrice', 0),
                    'EMS': item.type === 'orderdt' ? 0 : getSafeValue(item, 'ttEmsPrice', 0),
                    'Sea': item.type === 'orderdt' ? 0 : getSafeValue(item, 'ttSeaPrice', 0),
                    '패킹일시': item.type === 'orderdt' ? 'N/A' : getSafeValue(item.shipInfoDTO, 'regTime', 'N/A'),
                    '출고일시': item.type === 'orderdt' ? 'N/A' : getSafeValue(item.shipInfoDTO, 'outRegTime', 'N/A'),
                    '요청옵션': item.type === 'orderdt'
                        ? `${getSafeValue(item.reqmnDTO, 'compressionPack', false) ? '압축팩 /' : ''} ${getSafeValue(item.reqmnDTO, 'hangerRemove', false) ? '행거제거 /' : ''} ${getSafeValue(item.reqmnDTO, 'packingHold', false) ? '패킹보류 /' : ''} ${getSafeValue(item.reqmnDTO, 'prodBoxRemove', false) ? '박스제거 /' : ''} ${getSafeValue(item.reqmnDTO, 'shipPriceDepositpay', false) ? '예치금결제 /' : ''} ${getSafeValue(item.reqmnDTO, 'shipPriceMessenger', false) ? '카톡안내 /' : ''} ${getSafeValue(item.reqmnDTO, 'shoesBoxRemove', false) ? '신발박스제거 /' : ''} ${getSafeValue(item.reqmnDTO, 'tagRemove', false) ? '택제거 /' : ''}`
                        : `${getSafeValue(item.appReqDTO, 'compressionPack', false) ? '압축팩 /' : ''} ${getSafeValue(item.appReqDTO, 'hangerRemove', false) ? '행거제거 /' : ''} ${getSafeValue(item.appReqDTO, 'packingHold', false) ? '패킹보류 /' : ''} ${getSafeValue(item.appReqDTO, 'prodBoxRemove', false) ? '박스제거 /' : ''} ${getSafeValue(item.appReqDTO, 'shipPriceDepositpay', false) ? '예치금결제 /' : ''} ${getSafeValue(item.appReqDTO, 'shipPriceMessenger', false) ? '카톡안내 /' : ''} ${getSafeValue(item.appReqDTO, 'shoesBoxRemove', false) ? '신발박스제거 /' : ''} ${getSafeValue(item.appReqDTO, 'tagRemove', false) ? '택제거 /' : ''}`,
                    '요청사항': item.type === 'orderdt' ? getSafeValue(item.reqmnDTO, 'detailReq', 'N/A') : getSafeValue(item.appReqDTO, 'detailReq', 'N/A'),
                };
            }

            const sheetConfigs = {
                'Order': {
                    name: 'Order',
                    columns: [
                        { header: '', key: '여백', width: 0.5 },
                        { header: '주문번호', key: '주문번호', width: 26, style: { alignment: { horizontal: 'center' } } },
                        { header: '주문서유형', key: '주문서유형', width: 13, style: { alignment: { horizontal: 'center' } } },
                        { header: '사서함번호', key: '사서함번호', width: 13, style: { alignment: { horizontal: 'center' } } },
                        { header: '고객명', key: '고객명', width: 18, style: { alignment: { horizontal: 'center' } } },
                        { header: '수취인명', key: '수취인명', width: 23, style: { alignment: { horizontal: 'center' } } },
                        { header: '국가', key: '국가', width: 17, style: { alignment: { horizontal: 'center' } } },
                        { header: '상품명', key: '상품명', width: 30, style: { alignment: { horizontal: 'center' } } },
                        { header: '상태구분', key: '상태구분', width: 15, style: { alignment: { horizontal: 'center' } } },
                        { header: '배송사', key: '배송사', width: 12, style: { alignment: { horizontal: 'center' } } },
                        { header: '운송장번호', key: '운송장번호', width: 20, style: { alignment: { horizontal: 'center' } } },
                        { header: '세관신고수량', key: '세관신고수량', width: 15, style: { alignment: { horizontal: 'center' } } },
                        { header: '세관신고금액', key: '세관신고금액', width: 15, style: { alignment: { horizontal: 'center' } } },
                        { header: '세관내용품명', key: '세관내용품명', width: 30 },
                        { header: '미결제(상품)', key: '미결제(상품)', width: 15, style: { alignment: { horizontal: 'center' } } },
                        { header: '총결제(상품)', key: '총결제(상품)', width: 15, style: { alignment: { horizontal: 'center' } } },
                        { header: '미결제(기타)', key: '미결제(기타)', width: 15, style: { alignment: { horizontal: 'center' } } },
                        { header: '총결제(기타)', key: '총결제(기타)', width: 15, style: { alignment: { horizontal: 'center' } } },
                        { header: '상품수량', key: '상품수량', width: 10, style: { alignment: { horizontal: 'center' } } },
                        { header: 'FedEx', key: 'FedEx', width: 13, style: { alignment: { horizontal: 'center' } } },
                        { header: 'EMS', key: 'EMS', width: 13, style: { alignment: { horizontal: 'center' } } },
                        { header: 'Sea', key: 'Sea', width: 13, style: { alignment: { horizontal: 'center' } } },
                        { header: '패킹일시', key: '패킹일시', width: 20, style: { alignment: { horizontal: 'center' } } },
                        { header: '출고일시', key: '출고일시', width: 20, style: { alignment: { horizontal: 'center' } } },
                        { header: '요청옵션', key: '요청옵션', width: 30, style: { alignment: { horizontal: 'center' } } },
                        { header: '요청사항', key: '요청사항', width: 70 },
                    ],
                }
            };

            const borderHeaderStyle = {
                top: { style: 'thin', color: { argb: 'FF000000' } },
                left: { style: 'thin', color: { argb: 'FF000000' } },
                bottom: { style: 'thin', color: { argb: 'FF000000' } },
                right: { style: 'thin', color: { argb: 'FF000000' } }
            };

            const applyBordersToRow = (row, border) => {
                row.eachCell({ includeEmpty: true }, (cell) => {
                    cell.border = border;
                });
            };

            const createSheet = (name, columns) => {
                const sheet = workbook.addWorksheet(name);
                sheet.columns = columns;
                return sheet;
            };

            const addRowsToSheet = (sheet, rows, border) => {
                rows.forEach((item) => {
                    const row = sheet.addRow(item);
                    const orderTypeCell = row.getCell('주문서유형');

                    orderTypeCell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: orderTypeCell.value === '주문서' ? 'F7BD36' : 'C3E9FA' },
                    };

                    row.eachCell({ includeEmpty: true }, (cell) => {
                        cell.font = { name: '맑은 고딕', size: 10 };
                    });

                    applyBordersToRow(row, border);
                });
            };

            const transformedSelectedRows = selectedRows.map(mapDataToExcelRowOrder);

            if (sheetConfigs) {
                const sheet = createSheet(sheetConfigs['Order'].name, sheetConfigs['Order'].columns);
                sheet.getRow(1).eachCell((cell) => {
                    cell.alignment = { horizontal: 'center' };
                });
                addRowsToSheet(sheet, transformedSelectedRows, borderHeaderStyle);
            }

            const fileName = `주문리스트[주문단위]_${DateViewValue}.xlsx`;
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = fileName;
                anchor.click();
                window.URL.revokeObjectURL(url);
            });

        } catch (error) {
            console.error(error);
        }
    }

    //endregion 기존 엑셀(전체)

    const excelDown = () => {
        try {
            const allData = [...filteredList];
            const selectedRows = allData.filter((item) => checked.includes(item.seqnum || item.seqNum));
            const excel = require('exceljs');
            const workbook = new excel.Workbook();
            workbook.created = new Date();
            workbook.modified = new Date();

            function sumProductQuantities(products) {
                return products ? products.reduce((total, product) => total + (product.quantity || 0), 0) : 0;
            }

            function sumProductPrices(products) {
                return products ? products.reduce((total, product) => total + (product.price || 0), 0) : 0;
            }

            function displayProductNames(products) {
                if (!products || products.length === 0) return '';
                const firstProductName = products[0].name;
                const additionalProductCount = products.length - 1;
                return additionalProductCount === 0 ? firstProductName : `${firstProductName} 외 ${additionalProductCount}개···`;
            }

            function interpretOrderStatus(status) {
                const statusMap = {
                    'OS01': '입고대기',
                    'OS02': '구매완료',
                    'OS03': '결제대기(상품)',
                    'OS07': '센터도착',
                    'OS09': '결제대기(배송)',
                    'OS12': '출고완료',
                };
                return statusMap[status] || 'N/A';
            }

            function interpretPayStatus(status) {
                const statusMap = {
                    'PS0': '결제대기',
                    'PS1': '결제중',
                    'PS2': '결제완료',
                    'PS3': '출고완료',
                };
                return statusMap[status] || 'N/A';
            }

            function interpretProdType(status) {
                const statusMap = {
                    'P00': '장바구니',
                    'P01': '구매대행',
                    'P02': '오프라인',
                    'P03': '이체대행',
                    'P04': '국내배송',
                    'P05': '배송대행',
                };
                return statusMap[status] || 'N/A';
            }

            function interpretProdStatus(status) {
                const statusMap = {
                    'IS00': '입고대기',
                    'IS01': '결제대기(P)',
                    'IS02': '결제중(P)',
                    'IS03': '결제완료(P)',
                    'IS04': '구매완료',
                    'IS05': '센터도착',
                    'IS06': '패킹중',
                    'IS07': '결제대기(D)',
                    'IS08': '결제중(D)',
                    'IS09': '결제완료(D)',
                    'IS10': '출고완료',
                    'IS11': '반송중',
                    'IS12': '신청취소',
                    'IS13': '구매중',
                    'IS14': '입고중',

                };
                return statusMap[status] || 'N/A';
            }

            function getSafeValue(obj, key, defaultValue = '') {
                return obj && obj[key] !== undefined ? obj[key] : defaultValue;
            }

            function getSafeArray(obj, key) {
                return obj && Array.isArray(obj[key]) ? obj[key] : [];
            }

            function mapDataToExcelRows(item) {
                const productsDTO = item.type === 'orderdt'
                    ? getSafeArray(item, 'productsDTO')
                    : getSafeArray(item.packListDTO, 'productsDTO');

                return productsDTO.map(product => ({
                    '여백': '',
                    '주문번호': item.type === 'orderdt' ? getSafeValue(item, 'orderNumber', '')
                        : `${getSafeValue(item.orderdtDTO, 'orderNumber', '')}-${getSafeValue(item.shipInfoDTO, 'boxNum', '')}`,
                    '주문서유형': item.type === 'orderdt' ? '주문서' : '배송료',
                    '사서함번호': item.type === 'orderdt' ? 'BT' + getSafeValue(item.memberDTO, 'mbNum', '') : 'BT' + getSafeValue(item.orderdtDTO.memberDTO, 'mbNum', ''),
                    '고객명': item.type === 'orderdt' ? getSafeValue(item.memberDTO, 'userName', '') : getSafeValue(item.orderdtDTO.memberDTO, 'userName', ''),
                    '수취인명': getSafeValue(item.type === 'orderdt' ? item.recinfoDTO : item.appRecDTO, 'recname', ''),
                    '국가': `${getSafeValue(item.type === 'orderdt' ? item.recinfoDTO : item.appRecDTO, 'countryDTO', {}).countryNm || ''}[${getSafeValue(item.type === 'orderdt' ? item.recinfoDTO : item.appRecDTO, 'countryDTO', {}).countryCode || ''}]`,
                    '주문상태': item.type === 'orderdt' ? interpretOrderStatus(item.orderStatus) : interpretPayStatus(item.payStatus),
                    '개별상태': interpretProdStatus(product.prodSt),
                    '배송사': item.type === 'orderdt' ? getSafeValue(item, 'shipco', '') : getSafeValue(item.orderdtDTO, 'shipco', ''),
                    '운송장번호': item.type === 'orderdt' ? '' : getSafeValue(item.shipInfoDTO, 'outWayNum', ''),
                    '세관내용품명': item.type === 'orderdt' ? '' : getSafeValue(item.customsDTO, 'custContent', ''),
                    '세관신고수량': product.quantity || 0,
                    '세관신고금액': item.type === 'orderdt' ? '' : item.customsDTO.custPrice || 0,
                    '서비스구분': interpretProdType(product.prodTy),
                    '상품명': product.name,
                    '상품옵션': product.option,
                    '상품수량': product.quantity || 0,
                    '구매비용': product.price || 0,
                    '상품메모': product.adminMemo || '',
                    '기타비용': item.type === 'orderdt' ? item.payInfoDTO.etcFee || 0 : item.etcFee || 0,
                    '기타비용내용': item.type === 'orderdt' ? item.payInfoDTO.etcFeeNm || '' : item.etcFeeNm || '',
                    '재포장수수료': item.type === 'orderdt' ? item.payInfoDTO.repackFee || 0 : item.repackFee || 0,
                    '착불비': item.type === 'orderdt' ? item.payInfoDTO.agencyFee || 0 : item.agencyFee || 0,
                    '기타수수료': item.type === 'orderdt' ? item.payInfoDTO.etcPrice || 0 : item.etcPrice || 0,
                    '기타수수료내용': item.type === 'orderdt' ? item.payInfoDTO.etcPriceNm || '' : item.etcPriceNm || '',
                    'FedEx': item.type === 'orderdt' ? 0 : getSafeValue(item, 'ttFedexPrice', 0),
                    'EMS': item.type === 'orderdt' ? 0 : getSafeValue(item, 'ttEmsPrice', 0),
                    'Sea': item.type === 'orderdt' ? 0 : getSafeValue(item, 'ttSeaPrice', 0),
                    '패킹일시': item.type === 'orderdt' ? '' : getSafeValue(item.shipInfoDTO, 'regTime', ''),
                    '출고일시': item.type === 'orderdt' ? '' : getSafeValue(item.shipInfoDTO, 'outRegTime', ''),
                    '요청옵션': item.type === 'orderdt'
                        ? `${getSafeValue(item.reqmnDTO, 'compressionPack', false) ? '압축팩 /' : ''} ${getSafeValue(item.reqmnDTO, 'hangerRemove', false) ? '행거제거 /' : ''} ${getSafeValue(item.reqmnDTO, 'packingHold', false) ? '패킹보류 /' : ''} ${getSafeValue(item.reqmnDTO, 'prodBoxRemove', false) ? '박스제거 /' : ''} ${getSafeValue(item.reqmnDTO, 'shipPriceDepositpay', false) ? '예치금결제 /' : ''} ${getSafeValue(item.reqmnDTO, 'shipPriceMessenger', false) ? '카톡안내 /' : ''} ${getSafeValue(item.reqmnDTO, 'shoesBoxRemove', false) ? '신발박스제거 /' : ''} ${getSafeValue(item.reqmnDTO, 'tagRemove', false) ? '택제거 /' : ''}`
                        : `${getSafeValue(item.appReqDTO, 'compressionPack', false) ? '압축팩 /' : ''} ${getSafeValue(item.appReqDTO, 'hangerRemove', false) ? '행거제거 /' : ''} ${getSafeValue(item.appReqDTO, 'packingHold', false) ? '패킹보류 /' : ''} ${getSafeValue(item.appReqDTO, 'prodBoxRemove', false) ? '박스제거 /' : ''} ${getSafeValue(item.appReqDTO, 'shipPriceDepositpay', false) ? '예치금결제 /' : ''} ${getSafeValue(item.appReqDTO, 'shipPriceMessenger', false) ? '카톡안내 /' : ''} ${getSafeValue(item.appReqDTO, 'shoesBoxRemove', false) ? '신발박스제거 /' : ''} ${getSafeValue(item.appReqDTO, 'tagRemove', false) ? '택제거 /' : ''}`,
                    '요청사항': item.type === 'orderdt' ? getSafeValue(item.reqmnDTO, 'detailReq', '') : getSafeValue(item.appReqDTO, 'detailReq', 'N/A'),
                }));
            }

            const sheetConfigs = {
                'Order': {
                    name: 'Order',
                    columns: [
                        { header: '', key: '여백', width: 0.5 },
                        { header: '주문번호', key: '주문번호', width: 26, style: { alignment: { horizontal: 'center' } } },
                        { header: '주문서유형', key: '주문서유형', width: 13, style: { alignment: { horizontal: 'center' } } },
                        { header: '사서함번호', key: '사서함번호', width: 13, style: { alignment: { horizontal: 'center' } } },
                        { header: '고객명', key: '고객명', width: 18, style: { alignment: { horizontal: 'center' } } },
                        { header: '수취인명', key: '수취인명', width: 23, style: { alignment: { horizontal: 'center' } } },
                        { header: '국가', key: '국가', width: 17, style: { alignment: { horizontal: 'center' } } },
                        { header: '주문상태', key: '주문상태', width: 15, style: { alignment: { horizontal: 'center' } } },
                        { header: '개별상태', key: '개별상태', width: 15, style: { alignment: { horizontal: 'center' } } },
                        { header: '배송사', key: '배송사', width: 12, style: { alignment: { horizontal: 'center' } } },
                        { header: '운송장번호', key: '운송장번호', width: 20, style: { alignment: { horizontal: 'center' } } },
                        { header: '세관신고수량', key: '세관신고수량', width: 15, style: { alignment: { horizontal: 'center' } } },
                        { header: '세관신고금액($)', key: '세관신고금액', width: 16, style: { alignment: { horizontal: 'center' } } },
                        { header: '세관내용품명', key: '세관내용품명', width: 33 },
                        { header: '서비스구분', key: '서비스구분', width: 13, style: { alignment: { horizontal: 'center' } } },
                        { header: '상품명', key: '상품명', width: 30},
                        { header: '상품옵션', key: '상품옵션', width: 30},
                        { header: '상품수량', key: '상품수량', width: 10, style: { alignment: { horizontal: 'center' } } },
                        { header: '구매비용', key: '구매비용', width: 10, style: { alignment: { horizontal: 'center' } } },
                        { header: '상품메모', key: '상품메모', width: 30},
                        { header: '기타비용', key: '기타비용', width: 15, style: { alignment: { horizontal: 'center' } } },
                        { header: '기타비용내용', key: '기타비용내용', width: 40},
                        { header: '재포장수수료', key: '재포장수수료', width: 15, style: { alignment: { horizontal: 'center' } } },
                        { header: '착불비', key: '착불비', width: 15, style: { alignment: { horizontal: 'center' } } },
                        { header: '기타수수료', key: '기타수수료', width: 15, style: { alignment: { horizontal: 'center' } } },
                        { header: '기타수수료내용', key: '기타수수료내용', width: 40},
                        { header: 'FedEx', key: 'FedEx', width: 13, style: { alignment: { horizontal: 'center' } } },
                        { header: 'EMS', key: 'EMS', width: 13, style: { alignment: { horizontal: 'center' } } },
                        { header: 'Sea', key: 'Sea', width: 13, style: { alignment: { horizontal: 'center' } } },
                        { header: '패킹일시', key: '패킹일시', width: 20, style: { alignment: { horizontal: 'center' } } },
                        { header: '출고일시', key: '출고일시', width: 20, style: { alignment: { horizontal: 'center' } } },
                        { header: '요청옵션', key: '요청옵션', width: 30, style: { alignment: { horizontal: 'center' } } },
                        { header: '요청사항', key: '요청사항', width: 70 },
                    ],
                }
            };

            const borderHeaderStyle = {
                top: { style: 'thin', color: { argb: 'FF000000' } },
                left: { style: 'thin', color: { argb: 'FF000000' } },
                bottom: { style: 'thin', color: { argb: 'FF000000' } },
                right: { style: 'thin', color: { argb: 'FF000000' } }
            };

            const titleRowStyle = {
                font: { bold: true },
                alignment: { horizontal: 'center' }
            };

            const contentRowStyle = {
                fill: {
                    type: 'gradient',
                    gradient: 'angle',
                    degree: 45,
                    stops: [
                        { position: 0, color: { argb: 'FFFFFF' } },
                        { position: 1, color: { argb: 'FFFFFF' } }
                    ]
                }
            };

            const applyBordersToRow = (row, border) => {
                row.eachCell({ includeEmpty: true }, (cell) => {
                    cell.border = border;
                });
            };

            const createSheet = (name, columns) => {
                const sheet = workbook.addWorksheet(name);
                sheet.columns = columns;
                return sheet;
            };

            const addRowsToSheet = (sheet, rows, border) => {
                rows.forEach((item) => {
                    const row = sheet.addRow(item);
                    const orderTypeCell = row.getCell('주문서유형');

                    orderTypeCell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: orderTypeCell.value === '주문서' ? 'ffe9a0' : 'e0f4ff' },
                    };

                    row.eachCell({ includeEmpty: true }, (cell) => {
                        cell.font = { name: '맑은 고딕', size: 10 };
                    });

                    applyBordersToRow(row, border);
                });
            };

            const transformedRows = selectedRows.flatMap(mapDataToExcelRows);

            if (sheetConfigs) {
                const sheet = createSheet(sheetConfigs['Order'].name, sheetConfigs['Order'].columns);

                const headerRow = sheet.getRow(1);
                headerRow.eachCell((cell) => {
                    cell.alignment = { horizontal: 'center' };
                    cell.font = { bold: true, name: '맑은 고딕', size: 11 };
                });

                sheet.getRow(1).eachCell((cell) => {
                    cell.alignment = { horizontal: 'center' };
                });
                addRowsToSheet(sheet, transformedRows, borderHeaderStyle);
            }

            const fileName = `주문리스트[세무작업]_${DateViewValue}.xlsx`;
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = fileName;
                anchor.click();
                window.URL.revokeObjectURL(url);
            });

        } catch (error) {
            console.error(error);
        }
    }

    //endregion

    const [paySeqnum, setPaySeqnum] = useState(null);
    const [deliveryDetailSt, setDeliveryDetailSt] = useState(false);
    const deliveryDetailFalseFn = (() => {
        setDeliveryDetailSt(false);
    })
    const deliveryDetailTrueFn = ((e) => {
        setPaySeqnum(e);
        setDeliveryDetailSt(true);
    })

    // 현재 관리자 브라우저위치
    const pathComponent = window.location.pathname;

    const updateAppRecinfo = (updatedPayinfo) => {
        setPayinfoList((prevPayinfoList) => {
            return prevPayinfoList.map((payinfo) => {
                if (payinfo.appRecDTO && payinfo.appRecDTO.seqnum === updatedPayinfo.seqnum) {
                    return {...payinfo, appRecDTO: updatedPayinfo};
                }
                return payinfo;
            });
        });
    };

    const updatePayinfoList = (updatedPayinfo) => {
        setPayinfoList((prevPayinfoList) => {
            return prevPayinfoList.map((payinfo) =>
                payinfo.seqNum === updatedPayinfo.seqNum ? updatedPayinfo : payinfo,
            );
        });
    };

    // 장바구니 상품상태변경 시작
    const [isOpenCartItemDt, setIsOpenCartItemDt] = useState({});

    // const toggleMenuDTcartFn = (cDataIndex, prodQy) => {
    //     if (prodQy > 0) {
    //         setIsOpenCartItemDt(prevState => ({
    //             ...prevState,
    //             [cDataIndex]: !prevState[cDataIndex]
    //         }));
    //     }
    // };

    const toggleMenuDTcartFn = (cDataIndex, prodQy) => {
        setIsOpenCartItemDt(prevState => ({
            ...prevState,
            [cDataIndex]: prodQy > 0 ? !prevState[cDataIndex] : false
        }));
    };

    const [allCartProdStChangeShow, setAllCartProdStChangeShow] = useState(false);
    const closeAllCartProdStChangeShow = (() => {
        setAllCartProdStChangeShow(false);
    })
    const [allCartProdStNonSeqnumShow, setAllCartProdStNonSeqnumShow] = useState(false);
    const closeAllCartProdStNonSeqNumModalShow = (() => {
        setAllCartProdStNonSeqnumShow(false);
    })

    const [cartProdStShow, setCartProdStShow] = useState({});
    const cartProdStRef = useRef([]);

    const toggleCartProdStShow = (cDataIndex, productIndex) => {
        const key = `${cDataIndex}-${productIndex}`;
        setCartProdStShow(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            // Find the key of the currently open dropdown
            const openDropdownKey = Object.keys(cartProdStShow).find(key => cartProdStShow[key]);
            if (openDropdownKey !== undefined) {
                // Retrieve the ref of the open dropdown
                const openDropdownRef = cartProdStRef.current[openDropdownKey];

                // If the click was outside the open dropdown, close it
                if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                    setCartProdStShow(prevState => ({
                        ...prevState,
                        [openDropdownKey]: false
                    }));
                }
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [cartProdStShow]);

    const [selectedAllCartProdSt, setSelectedAllCartProdSt] = useState({});
    const [allCartProdStShow, setAllCartProdStShow] = useState({});
    const allCartProdStRefs = useRef({});

    const toggleAllCartProdStShow = (cDataIndex) => {
        setAllCartProdStShow(prevState => ({
            ...prevState,
            [cDataIndex]: !prevState[cDataIndex]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            const openDropdownKey = Object.keys(allCartProdStShow).find(key => allCartProdStShow[key]);
            const openDropdownRef = allCartProdStRefs.current[openDropdownKey];

            if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                setAllCartProdStShow(prevState => ({
                    ...prevState,
                    [openDropdownKey]: false
                }));
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [allCartProdStShow, allCartProdStRefs]);
    // 장바구니 상품상태변경 종료


    // 구매대행 상품상태변경 시작
    const [isOpenOrderItemDt, setIsOpenOrderItemDt] = useState({});

    const toggleMenuDTorderFn = (cDataIndex, prodQy) => {
        setIsOpenOrderItemDt(prevState => ({
            ...prevState,
            [cDataIndex]: prodQy > 0 ? !prevState[cDataIndex] : false
        }));
    };

    const [allOrderProdStChangeShow, setAllOrderProdStChangeShow] = useState(false);
    const closeAllOrderProdStChangeShow = (() => {
        setAllOrderProdStChangeShow(false);
    })
    const [allOrderProdStNonSeqnumShow, setAllOrderProdStNonSeqnumShow] = useState(false);
    const closeAllOrderProdStNonSeqNumModalShow = (() => {
        setAllOrderProdStNonSeqnumShow(false);
    })

    const [orderProdStShow, setOrderProdStShow] = useState({});
    const orderProdStRef = useRef([]);

    const toggleOrderProdStShow = (cDataIndex, productIndex) => {
        const key = `${cDataIndex}-${productIndex}`;
        setOrderProdStShow(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            // Find the key of the currently open dropdown
            const openDropdownKey = Object.keys(orderProdStShow).find(key => orderProdStShow[key]);
            if (openDropdownKey !== undefined) {
                // Retrieve the ref of the open dropdown
                const openDropdownRef = orderProdStRef.current[openDropdownKey];

                // If the click was outside the open dropdown, close it
                if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                    setOrderProdStShow(prevState => ({
                        ...prevState,
                        [openDropdownKey]: false
                    }));
                }
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [orderProdStShow]);

    const [selectedAllOrderProdSt, setSelectedAllOrderProdSt] = useState({});
    const [allOrderProdStShow, setAllOrderProdStShow] = useState({});
    const allOrderProdStRefs = useRef({});

    const toggleAllOrderProdStShow = (cDataIndex) => {
        setAllOrderProdStShow(prevState => ({
            ...prevState,
            [cDataIndex]: !prevState[cDataIndex]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            const openDropdownKey = Object.keys(allOrderProdStShow).find(key => allOrderProdStShow[key]);
            const openDropdownRef = allOrderProdStRefs.current[openDropdownKey];

            if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                setAllOrderProdStShow(prevState => ({
                    ...prevState,
                    [openDropdownKey]: false
                }));
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [allOrderProdStShow, allOrderProdStRefs]);
    // 구매대행 상품상태변경 종료


    // 오프라인 상품상태변경 시작
    const [isOpenOfflineItemDt, setIsOpenOfflineItemDt] = useState({});

    // const toggleMenuDTofflineFn = (cDataIndex, prodQy) => {
    //     if (prodQy > 0) {
    //         setIsOpenOfflineItemDt(prevState => ({
    //             ...prevState,
    //             [cDataIndex]: !prevState[cDataIndex]
    //         }));
    //     }
    // };

    const toggleMenuDTofflineFn = (cDataIndex, prodQy) => {
        setIsOpenOfflineItemDt(prevState => ({
            ...prevState,
            [cDataIndex]: prodQy > 0 ? !prevState[cDataIndex] : false
        }));
    };

    const [allOfflineProdStChangeShow, setAllOfflineProdStChangeShow] = useState(false);
    const closeAllOfflineProdStChangeShow = (() => {
        setAllOfflineProdStChangeShow(false);
    })
    const [allOfflineProdStNonSeqnumShow, setAllOfflineProdStNonSeqnumShow] = useState(false);
    const closeAllOfflineProdStNonSeqNumModalShow = (() => {
        setAllOfflineProdStNonSeqnumShow(false);
    })

    const [offlineProdStShow, setOfflineProdStShow] = useState({});
    const offlineProdStRef = useRef([]);

    const toggleOfflineProdStShow = (cDataIndex, productIndex) => {
        const key = `${cDataIndex}-${productIndex}`;
        setOfflineProdStShow(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            // Find the key of the currently open dropdown
            const openDropdownKey = Object.keys(offlineProdStShow).find(key => offlineProdStShow[key]);
            if (openDropdownKey !== undefined) {
                // Retrieve the ref of the open dropdown
                const openDropdownRef = offlineProdStRef.current[openDropdownKey];

                // If the click was outside the open dropdown, close it
                if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                    setOfflineProdStShow(prevState => ({
                        ...prevState,
                        [openDropdownKey]: false
                    }));
                }
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [offlineProdStShow]);

    const [selectedAllOfflineProdSt, setSelectedAllOfflineProdSt] = useState({});
    const [allOfflineProdStShow, setAllOfflineProdStShow] = useState({});
    const allOfflineProdStRefs = useRef({});

    const toggleAllOfflineProdStShow = (cDataIndex) => {
        setAllOfflineProdStShow(prevState => ({
            ...prevState,
            [cDataIndex]: !prevState[cDataIndex]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            const openDropdownKey = Object.keys(allOfflineProdStShow).find(key => allOfflineProdStShow[key]);
            const openDropdownRef = allOfflineProdStRefs.current[openDropdownKey];

            if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                setAllOfflineProdStShow(prevState => ({
                    ...prevState,
                    [openDropdownKey]: false
                }));
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [allOfflineProdStShow, allOfflineProdStRefs]);
    // 오프라인 상품상태변경 종료


    // 이체대행 상품상태변경 시작
    const [isOpenTransferItemDt, setIsOpenTransferItemDt] = useState({});

    // const toggleMenuDTtransferFn = (cDataIndex, prodQy) => {
    //     if (prodQy > 0) {
    //         setIsOpenTransferItemDt(prevState => ({
    //             ...prevState,
    //             [cDataIndex]: !prevState[cDataIndex]
    //         }));
    //     }
    // };

    const toggleMenuDTtransferFn = (cDataIndex, prodQy) => {
        setIsOpenTransferItemDt(prevState => ({
            ...prevState,
            [cDataIndex]: prodQy > 0 ? !prevState[cDataIndex] : false
        }));
    };

    const [allTransferProdStChangeShow, setAllTransferProdStChangeShow] = useState(false);
    const closeAllTransferProdStChangeShow = (() => {
        setAllTransferProdStChangeShow(false);
    })
    const [allTransferProdStNonSeqnumShow, setAllTransferProdStNonSeqnumShow] = useState(false);
    const closeAllTransferProdStNonSeqNumModalShow = (() => {
        setAllTransferProdStNonSeqnumShow(false);
    })

    const [transferProdStShow, setTransferProdStShow] = useState({});
    const transferProdStRef = useRef([]);

    const toggleTransferProdStShow = (cDataIndex, productIndex) => {
        const key = `${cDataIndex}-${productIndex}`;
        setTransferProdStShow(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            // Find the key of the currently open dropdown
            const openDropdownKey = Object.keys(transferProdStShow).find(key => transferProdStShow[key]);
            if (openDropdownKey !== undefined) {
                // Retrieve the ref of the open dropdown
                const openDropdownRef = transferProdStRef.current[openDropdownKey];

                // If the click was outside the open dropdown, close it
                if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                    setTransferProdStShow(prevState => ({
                        ...prevState,
                        [openDropdownKey]: false
                    }));
                }
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [transferProdStShow]);

    const [selectedAllTransferProdSt, setSelectedAllTransferProdSt] = useState({});
    const [allTransferProdStShow, setAllTransferProdStShow] = useState({});
    const allTransferProdStRefs = useRef({});

    const toggleAllTransferProdStShow = (cDataIndex) => {
        setAllTransferProdStShow(prevState => ({
            ...prevState,
            [cDataIndex]: !prevState[cDataIndex]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            const openDropdownKey = Object.keys(allTransferProdStShow).find(key => allTransferProdStShow[key]);
            const openDropdownRef = allTransferProdStRefs.current[openDropdownKey];

            if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                setAllTransferProdStShow(prevState => ({
                    ...prevState,
                    [openDropdownKey]: false
                }));
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [allTransferProdStShow, allTransferProdStRefs]);
    // 이체대행 상품상태변경 종료


    // 국내구매 상품상태변경 시작
    const [isOpenDomesticItemDt, setIsOpenDomesticItemDt] = useState({});

    // const toggleMenuDTdomesticFn = (cDataIndex, prodQy) => {
    //     if (prodQy > 0) {
    //         setIsOpenDomesticItemDt(prevState => ({
    //             ...prevState,
    //             [cDataIndex]: !prevState[cDataIndex]
    //         }));
    //     }
    // };

    const toggleMenuDTdomesticFn = (cDataIndex, prodQy) => {
        setIsOpenDomesticItemDt(prevState => ({
            ...prevState,
            [cDataIndex]: prodQy > 0 ? !prevState[cDataIndex] : false
        }));
    };

    const [allDomesticProdStChangeShow, setAllDomesticProdStChangeShow] = useState(false);
    const closeAllDomesticProdStChangeShow = (() => {
        setAllDomesticProdStChangeShow(false);
    })
    const [allDomesticProdStNonSeqnumShow, setAllDomesticProdStNonSeqnumShow] = useState(false);
    const closeAllDomesticProdStNonSeqNumModalShow = (() => {
        setAllDomesticProdStNonSeqnumShow(false);
    })

    const [domesticProdStShow, setDomesticProdStShow] = useState({});
    const domesticProdStRef = useRef([]);

    const toggleDomesticProdStShow = (cDataIndex, productIndex) => {
        const key = `${cDataIndex}-${productIndex}`;
        setDomesticProdStShow(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            // Find the key of the currently open dropdown
            const openDropdownKey = Object.keys(domesticProdStShow).find(key => domesticProdStShow[key]);
            if (openDropdownKey !== undefined) {
                // Retrieve the ref of the open dropdown
                const openDropdownRef = domesticProdStRef.current[openDropdownKey];

                // If the click was outside the open dropdown, close it
                if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                    setDomesticProdStShow(prevState => ({
                        ...prevState,
                        [openDropdownKey]: false
                    }));
                }
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [domesticProdStShow]);

    const [selectedAllDomesticProdSt, setSelectedAllDomesticProdSt] = useState({});
    const [allDomesticProdStShow, setAllDomesticProdStShow] = useState({});
    const allDomesticProdStRefs = useRef({});

    const toggleAllDomesticProdStShow = (cDataIndex) => {
        setAllDomesticProdStShow(prevState => ({
            ...prevState,
            [cDataIndex]: !prevState[cDataIndex]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            const openDropdownKey = Object.keys(allDomesticProdStShow).find(key => allDomesticProdStShow[key]);
            const openDropdownRef = allDomesticProdStRefs.current[openDropdownKey];

            if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                setAllDomesticProdStShow(prevState => ({
                    ...prevState,
                    [openDropdownKey]: false
                }));
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [allDomesticProdStShow, allDomesticProdStRefs]);
    // 국내구매 상품상태변경 종료

    // 배송대행 상품상태변경 시작
    const [isOpenDeliveryItemDt, setIsOpenDeliveryItemDt] = useState({});

    // const toggleMenuDTdeliveryFn = (cDataIndex, prodQy) => {
    //     if (prodQy > 0) {
    //         setIsOpenDeliveryItemDt(prevState => ({
    //             ...prevState,
    //             [cDataIndex]: !prevState[cDataIndex]
    //         }));
    //     }
    // };

    const toggleMenuDTdeliveryFn = (cDataIndex, prodQy) => {
        setIsOpenDeliveryItemDt(prevState => ({
            ...prevState,
            [cDataIndex]: prodQy > 0 ? !prevState[cDataIndex] : false
        }));
    };

    const [allDeliveryProdStChangeShow, setAllDeliveryProdStChangeShow] = useState(false);
    const closeAllDeliveryProdStChangeShow = (() => {
        setAllDeliveryProdStChangeShow(false);
    })
    const [allDeliveryProdStNonSeqnumShow, setAllDeliveryProdStNonSeqnumShow] = useState(false);
    const closeAllDeliveryProdStNonSeqNumModalShow = (() => {
        setAllDeliveryProdStNonSeqnumShow(false);
    })

    const [deliveryProdStShow, setDeliveryProdStShow] = useState({});
    const deliveryProdStRef = useRef([]);

    const toggleDeliveryProdStShow = (cDataIndex, productIndex) => {
        const key = `${cDataIndex}-${productIndex}`;
        setDeliveryProdStShow(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            const openDropdownKey = Object.keys(deliveryProdStShow).find(key => deliveryProdStShow[key]);
            if (openDropdownKey !== undefined) {
                const openDropdownRef = deliveryProdStRef.current[openDropdownKey];

                if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                    setDeliveryProdStShow(prevState => ({
                        ...prevState,
                        [openDropdownKey]: false
                    }));
                }
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [deliveryProdStShow]);

    const [selectedAllDeliveryProdSt, setSelectedAllDeliveryProdSt] = useState({});
    const [allDeliveryProdStShow, setAllDeliveryProdStShow] = useState({});
    const allDeliveryProdStRefs = useRef({});

    const toggleAllDeliveryProdStShow = (cDataIndex) => {
        setAllDeliveryProdStShow(prevState => ({
            ...prevState,
            [cDataIndex]: !prevState[cDataIndex]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            const openDropdownKey = Object.keys(allDeliveryProdStShow).find(key => allDeliveryProdStShow[key]);
            const openDropdownRef = allDeliveryProdStRefs.current[openDropdownKey];

            if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                setAllDeliveryProdStShow(prevState => ({
                    ...prevState,
                    [openDropdownKey]: false
                }));
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [allDeliveryProdStShow, allDeliveryProdStRefs]);
    // 배송대행 상품상태변경 종료

    // common function
    const allProdStChangeMsg1 = "선택하신 상품의 상태들을 변경하시겠습니까?";
    const allProdStChangeMsg2 = "변경하실 상품상태를 한 번더 확인해주세요";
    const allProdStNonSeqnumMsg = "전체변경하실 상품을 선택해주세요";

    const [selectedFormTy, setSelectedFormTy] = useState('');
    const [selectedCdataIndex, setSelectedCdataIndex] = useState(0);
    const [selectedProdSt, setSelectedProdSt] = useState({});
    const [selectedItems, setSelectedItems] = useState({
        cart: {},
        order: {},
        offline: {},
        transfer: {},
        domestic: {},
        delivery: {}
    });

    const handleCheckAll = (formType, cDataIndex, isChecked, productsDTO) => {
        let prodStCode = null;
        if (formType === 'cart') {
            prodStCode = "P00";
        } else if (formType === 'order') {
            prodStCode = "P01";
        } else if (formType === 'offline') {
            prodStCode = "P02";
        } else if (formType === 'transfer') {
            prodStCode = "P03";
        } else if (formType === 'domestic') {
            prodStCode = "P04";
        } else if (formType === 'delivery') {
            prodStCode = "P05";
        }

        setSelectedItems(prevState => {
            const updatedState = { ...prevState };
            updatedState[formType][cDataIndex] = isChecked ? productsDTO.filter(prod => prod.prodTy === prodStCode).map(prod => prod.seqnum) : [];
            return updatedState;
        });
    };

    // 개별 아이템 선택 처리
    const handleCheckItem = (formType, cDataIndex, seqNum, isChecked) => {
        setSelectedItems(prevState => {
            const updatedState = { ...prevState };
            if (isChecked) {
                updatedState[formType][cDataIndex] = [...(updatedState[formType][cDataIndex] || []), seqNum];
            } else {
                updatedState[formType][cDataIndex] = (updatedState[formType][cDataIndex] || []).filter(num => num !== seqNum);
            }
            return updatedState;
        });
    };

    const handleProdStChange = (formType, prodSt, seqNum, cDataIndex, productIndex) => {
        // 선택된 상품 업데이트 (단일 항목)
        // setSelectedItems(prevState => ({
        //     ...prevState,
        //     [formType]: {
        //         ...prevState[formType],
        //         [cDataIndex]: [seqNum]  // 단일 항목만 저장
        //     }
        // }));

        // 셀렉트 label 업뎃
        const newSelectedProdSt = {
            ...selectedProdSt,
            [`${formType}-${cDataIndex}-${productIndex}`]: prodSt
        };
        setSelectedProdSt(newSelectedProdSt);

        // 신청서 index 및 prod index에 따라 드랍다운 닫기
        if (formType === "cart") {
            setCartProdStShow({});
        } else if (formType === "order") {
            setOrderProdStShow({});
        } else if (formType === "offline") {
            setOfflineProdStShow({});
        } else if (formType === "transfer") {
            setTransferProdStShow({});
        } else if (formType === "domestic") {
            setDomesticProdStShow({});
        } else if (formType === "delivery") {
            setDeliveryProdStShow({});
        }

        modifyProdSt(prodSt, seqNum);
    };

    const handleAllProdStChange = (formType, cDataIndex, allProdSt) => {
        setSelectedFormTy(formType);
        setSelectedCdataIndex(cDataIndex);
        let selectedItemsDataFl = selectedItems[formType][cDataIndex] && selectedItems[formType][cDataIndex].length > 0;
        let selectedItemsNoDataFl = !selectedItems[formType][cDataIndex] || selectedItems[formType][cDataIndex].length === 0;

        if (formType === 'cart') {
            setSelectedAllCartProdSt(prevState => ({
                ...prevState,
                [cDataIndex]: allProdSt
            }));
            setAllCartProdStShow(prevState => ({
                ...prevState,
                [cDataIndex]: false
            }));
            setAllCartProdStChangeShow(selectedItemsDataFl);
            setAllCartProdStNonSeqnumShow(selectedItemsNoDataFl);
        } else if (formType === 'order') {
            setSelectedAllOrderProdSt(prevState => ({
                ...prevState,
                [cDataIndex]: allProdSt
            }));
            setAllOrderProdStShow(prevState => ({
                ...prevState,
                [cDataIndex]: false
            }));
            setAllOrderProdStChangeShow(selectedItemsDataFl);
            setAllOrderProdStNonSeqnumShow(selectedItemsNoDataFl);
        } else if (formType === 'offline') {
            setSelectedAllOfflineProdSt(prevState => ({
                ...prevState,
                [cDataIndex]: allProdSt
            }));
            setAllOfflineProdStShow(prevState => ({
                ...prevState,
                [cDataIndex]: false
            }));
            setAllOfflineProdStChangeShow(selectedItemsDataFl);
            setAllOfflineProdStNonSeqnumShow(selectedItemsNoDataFl);
        } else if (formType === 'transfer') {
            setSelectedAllTransferProdSt(prevState => ({
                ...prevState,
                [cDataIndex]: allProdSt
            }));
            setAllTransferProdStShow(prevState => ({
                ...prevState,
                [cDataIndex]: false
            }));
            setAllTransferProdStChangeShow(selectedItemsDataFl);
            setAllTransferProdStNonSeqnumShow(selectedItemsNoDataFl);
        } else if (formType === 'domestic') {
            setSelectedAllDomesticProdSt(prevState => ({
                ...prevState,
                [cDataIndex]: allProdSt
            }));
            setAllDomesticProdStShow(prevState => ({
                ...prevState,
                [cDataIndex]: false
            }));
            setAllDomesticProdStChangeShow(selectedItemsDataFl);
            setAllDomesticProdStNonSeqnumShow(selectedItemsNoDataFl);
        } else if (formType === 'delivery') {
            setSelectedAllDeliveryProdSt(prevState => ({
                ...prevState,
                [cDataIndex]: allProdSt
            }));
            setAllDeliveryProdStShow(prevState => ({
                ...prevState,
                [cDataIndex]: false
            }));
            setAllDeliveryProdStChangeShow(selectedItemsDataFl);
            setAllDeliveryProdStNonSeqnumShow(selectedItemsNoDataFl);
        }
    };

    const [dontTrackingShipcoSt, setDontTrackingShipcoSt] = useState(false);
    const closeDontTrackingShipco = (() => {
        setDontTrackingShipcoSt(false);
    });

    const dbSelectedDeliveryTracking = ((selectedDomestic, dsTrackNum) => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = 1200;
        const height = 800;
        const left = (screenWidth / 2) - (width / 2);
        const top = (screenHeight / 2) - (height / 2);

        if (selectedDomestic === "CJ대한통운") {
            const url = `https://trace.cjlogistics.com/next/tracking.html?wblNo=${dsTrackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "로젠택배") {
            const url = `https://www.ilogen.com/web/personal/trace/${dsTrackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "우체국") {
            const url = `https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?sid1=${dsTrackNum}&displayHeader=N`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "한진택배") {
            const url = `https://www.hanjin.com/kor/CMS/DeliveryMgr/WaybillResult.do?mCode=MN038&schLang=KR&wblnumText2=${dsTrackNum}`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "롯데택배") {
            const url = `https://www.lotteglogis.com/open/tracking?invno=${dsTrackNum}`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "경동택배") {
            const url = `https://kdexp.com/newDeliverySearch.kd?barcode=${dsTrackNum}`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else {
            setDontTrackingShipcoSt(true);
        }
    })

    const modifyProdSt = (prodSt, seqNums) => {

        let params = new URLSearchParams();
        params.append('prodValue', prodSt.value);
        params.append('adminInfo', adminInfo.mbNum);

        if (!Array.isArray(seqNums)) {
            seqNums = [seqNums];
        }
        seqNums.forEach(num => params.append('prodSeqNum', num));

        axios({
            method: 'post',
            url: '/order/modifyProdSt',
            params: params,
        })
            .then((response) => {

                const updatedOrder = response.data;

                setFilteredList(currentList => {
                    return currentList.map(cData => {
                        if (cData.type === 'orderdt' && cData.seqnum === updatedOrder.seqnum) {
                            const updatedProductsDTO = cData.productsDTO.map(prod => {
                                const updatedProduct = updatedOrder.productsDTO.find(upProd => upProd.seqnum === prod.seqnum);
                                return updatedProduct ? updatedProduct : prod;
                            });

                            // 전체 orderdt와 업데이트된 product데이터만 업뎃
                            Object.assign(cData, updatedOrder);
                            // 업데이트된 productsDTO로 설정
                            cData.productsDTO = updatedProductsDTO;
                            // 반환데이터.type을 orderdt로 분류(안그럼 메인에 출력안됨)
                            cData.type = 'orderdt';
                        }
                        return cData;
                    });
                });

                realTimeChangeProdSt(updatedOrder.productsDTO);

                setSelectedItems({
                    cart: {},
                    order: {},
                    offline: {},
                    transfer: {},
                    domestic: {},
                    delivery: {}
                });

                seletecdProdWithOrderSeqNum(response.data.seqnum, response.data.orderStatus);

            })
            .catch((error) => {
                console.log("상품상태 변경실패 : ", error);
            });
    };

    const realTimeChangeProdSt = async (prodList) => {
        if (stompClient) {
            await stompClient.send("/app/prodSt/adminProdStUpdate", {}, JSON.stringify(prodList));
        } else {
            console.error("Stomp client is not connected.");
        }
    }

    const seletecdProdWithOrderSeqNum = ((orderSeqNum, orderSt) => {
        axios({
            method: 'post',
            url: '/order/seletecdProdWithOrderSeqNum',
            headers: {
                Authorization: LCAT
            },
            params: {
                orderSeqNum: orderSeqNum
            }
        })
            .then((response) => {
                // 중앙에 stompClient 연결데이터, product List, orderSeqNum, orderSt 전송
                orderStatusUpdate(stompClientRef.current, response.data, orderSeqNum);
            })
            .catch((error) => {
                console.log("orderdt 반환실패 : ", error)
            })
    })

    const orderStReceived = ((payload) => {
        let data = JSON.parse(payload.body);
        setFilteredList(currentList => {
            return currentList.map(cData => {
                if (cData.type === 'orderdt' && cData.seqnum === data.orderSeqNum) {
                    cData.orderStatus = data.orderSt; // OS 실시간변경
                }
                return cData;
            });
        });
        selectAlarmData();
        selectedAllProcessCount();
    })

    const [prodStList, setProdStList] = useState([]);
    const selectedProdStList = (() => {
        axios({
            method: 'get',
            url: '/order/selectedProdStList',
            data: {
                selectPayinfo: checked
            },
        })
            .then((response) => {
                const newProdStList = response.data.map(item => ({
                    value: item.stValue,
                    label: item.stLabel
                }));
                setProdStList(newProdStList);
            })
            .catch((error) => {
                console.log("상품상태 리스트 조회실패 : ", error)
            })
    })


    useEffect(() => {
        selectedProdStList();
        selectedAllProcessCount();
    }, [])

    const [processData, setProcessData] = useState([]);
    const selectedAllProcessCount = (() => {
        axios({
            method: 'get',
            url: '/order/selectedAllProcessCount',
        })
            .then((response) => {
                setProcessData(response.data);
            })
            .catch((error) => {
                console.log("프로세스수량 조회실패 : ", error)
            })
    })

    const ordDtMemoSave = (seqnum, ordDtCont, memoType) => {
        axios.post('/order/ordMemoDtSave', { seqnum, ordDtCont, memoType }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LCAT
            }
        })
            .then(() => {
                // if (inputRefs.current[seqnum]) {
                //     inputRefs.current[seqnum].blur();
                // }
                setSavePopupMsg("저장 완료");
                setSaveMsgSt(true);
                setTimeout(() => setSaveMsgSt(false), 1000);
            })
            .catch(error => {
                setSavePopupMsg("저장 실패");
                setSaveMsgSt(true);
                setTimeout(() => setSaveMsgSt(false), 1000);
            });
    };

    /** Event endregion */

    /** 관리자 예치금/적립금결제 시작 */
    // orderdt 관리자 예치금/적립금결제
    const [orderdtDepositPaySt, setOrderdtDepositPaySt] = useState(false);
    const [orderdtPointPaySt, setOrderdtPointPaySt] = useState(false);
    const orderdtDepositPayMsg1 = "주문서의 미결제금액을 예치금으로 결제하시겠습니까?";
    const orderdtDepositPayMsg2 = "결제 전 체크해주시기 바랍니다";
    const orderdtPointPayMsg1 = "주문서의 미결제금액을 적립금으로 결제하시겠습니까?";
    const orderdtPointPayMsg2 = "결제 전 체크해주시기 바랍니다";

    // 선택된 데이터가 null 일 경우
    const [dpPayNullSt, setDpPayNullSt] = useState(false);
    const closeDpPayNullSt = (() => {
        setDpPayNullSt(false);
    })

    const openOrderdtDepositPaySt = (() => {
        // 선택된 체크박스 데이터 없으면 orderdtDepositPayNullSt true
        if (!checked || checked.length === 0) {
            setDpPayNullSt(true);
        } else {
            setOrderdtDepositPaySt(true);
        }
    })
    const closeOrderdtDepositPaySt = (() => {
        setOrderdtDepositPaySt(false);
    })

    const openOrderdtPointPaySt = (() => {
        // 선택된 체크박스 데이터 없으면 setOrderdtPointPaySt true
        if (!checked || checked.length === 0) {
            setDpPayNullSt(true);
        } else {
            setOrderdtPointPaySt(true);
        }
    })
    const closeOrderdtPointPaySt = (() => {
        setOrderdtPointPaySt(false);
    })

    const orderdtAdminDpPay = ((type) => {
        axios({
            method: 'post',
            url: '/order/orderdtAdminDpPay',
            headers: {
                'Content-Type': 'application/json',
            },
            data : {
                seqNum: checked,
                type: type
            }
        })
            .then(async (response) => {
                for (const item of response.data) {
                    saveOrderAlarmData(response.data);
                }
                if (type === "deposit") {
                    closeOrderdtDepositPaySt();
                } else {
                    closeOrderdtPointPaySt();
                }
                selectedAllProcessCount();
                selectNoDateTotalOrderList(selectedProcessSt);
            })
            .catch((error) => {
                console.log("예치금결제 실패 : ", error)
            })
    })

    // payinfo 관리자 예치금/적립금결제
    const [payinfoDepositPaySt, setPayinfoDepositPaySt] = useState(false);
    const [payinfoPointPaySt, setPayinfoPointPaySt] = useState(false);
    const payinfoDepositPayMsg1 = "선택된 해외배송비를 예치금으로 결제하시겠습니까?";
    const payinfoDepositPayMsg2 = "결제 전 체크해주시기 바랍니다";
    const payinfoPointPayMsg1 = "선택된 해외배송비를 적립금으로 결제하시겠습니까?";
    const payinfoPointPayMsg2 = "결제 전 체크해주시기 바랍니다";
    const [payinfoSelectOrderErrSt, setPayinfoSelectOrderErrSt] = useState(false);
    const payinfoSelectOrderErrMsg = "배송료타입만 결제가 가능합니다";

    const openPayinfoDepositPaySt = (() => {
        // orderdt 주문서 유형이 선택되었다면 결제제한
        if (orderCheckedCount === 0) {
            // 선택된 체크박스 데이터 없으면 payinfoDepositPayNullSt true
            if (!checked || checked.length === 0) {
                setDpPayNullSt(true);
            } else {
                setPayinfoDepositPaySt(true);
            }
        } else {
            setPayinfoSelectOrderErrSt(true);
        }
    })
    const closePayinfoDepositPaySt = (() => {
        setPayinfoDepositPaySt(false);
    })

    const closePayinfoSelectOrderErr = (() => {
        setPayinfoSelectOrderErrSt(false);
    })

    const openPayinfoPointPaySt = (() => {
        // orderdt 주문서 유형이 선택되었다면 결제제한
        if (orderCheckedCount === 0) {
            // 선택된 체크박스 데이터 없으면 setPayinfoPointPaySt true
            if (!checked || checked.length === 0) {
                setDpPayNullSt(true);
            } else {
                setPayinfoPointPaySt(true);
            }
        } else {
            setPayinfoSelectOrderErrSt(true);
        }
    })
    const closePayinfoPointPaySt = (() => {
        setPayinfoPointPaySt(false);
    })

    const payinfoAdminDpPay = ((type) => {
        axios({
            method: 'post',
            url: '/order/payinfoAdminDpPay',
            headers: {
                'Content-Type': 'application/json',
            },
            data : {
                seqNum: checked,
                type: type
            }
        })
            .then(async (response) => {
                for (const item of response.data) {
                    saveOrderAlarmData(response.data);
                }
                if (type === "deposit") {
                    closePayinfoDepositPaySt();
                } else {
                    closePayinfoPointPaySt();
                }
                selectedAllProcessCount();
                selectNoDateTotalOrderList(selectedProcessSt);
            })
            .catch((error) => {
                console.log("예치금결제 실패 : ", error)
            })
    })

    const saveOrderAlarmData = ((orderData) => {
        axios({
            method: 'post',
            url: '/order/saveOrderData',
            headers: {
                Authorization: LCAT,
                'Content-Type': 'application/json',
            },
            data: orderData
        })
            .then(async () => {
                await stompClient.send("/app/order", {}, JSON.stringify({sendFl:true}));
            })
            .catch((error) => {
                console.log("알림데이터 저장실패 : ", error)
            })
    })


    /** 관리자 예치금/적립금결제 end */

    /** 국내반송/해외반송 시작 */
    const [adminOsReturnSt, setAdminOsReturnSt] = useState(false);
    const [adminDsReturnSt, setAdminDsReturnSt] = useState(false);
    const osReturnMsg1 = "해외반송으로 처리하시겠습니까?";
    const osReturnMsg2 = "진행 후 복구가 불가합니다";
    const dsReturnMsg1 = "국내반송으로 처리하시겠습니까?";
    const dsReturnMsg2 = "진행 후 복구가 불가합니다";

    // 선택된 데이터가 null 일 경우
    const [returnNullSt, setReturnNullSt] = useState(false);
    const closeReturnNullSt = (() => {
        setReturnNullSt(false);
    })

    const openOsReturnSt = (() => {
        if (orderCheckedCount === 0) {
            // 선택된 체크박스 데이터 없으면 orderdtDepositPayNullSt true
            if (!checked || checked.length === 0) {
                setReturnNullSt(true);
            } else {
                setAdminOsReturnSt(true);
            }
        } else {
            setPayinfoSelectOrderErrSt(true);
        }
    })
    const closeOsReturnSt = (() => {
        setAdminOsReturnSt(false);
    })

    const openDsReturnSt = (() => {
        if (orderCheckedCount === 0) {
        // 선택된 체크박스 데이터 없으면 setOrderdtPointPaySt true
        if (!checked || checked.length === 0) {
            setDpPayNullSt(true);
        } else {
            setAdminDsReturnSt(true);
        }
        } else {
            setPayinfoSelectOrderErrSt(true);
        }
    })
    const closeDsReturnSt = (() => {
        setAdminDsReturnSt(false);
    })

    const deliveryReturn = ((type) => {
        axios({
            method: 'post',
            url: '/order/deliveryReturn',
            headers: {
                'Content-Type': 'application/json',
                Authorization: LCAT
            },
            data : {
                seqNum: checked,
                type: type
            }
        })
            .then(async (response) => {
                for (const item of response.data) {
                    await stompClient.send("/app/updateOrderList", {}, JSON.stringify({userName: item}));
                }
                if (type === "overseas") {
                    closeOsReturnSt();
                } else {
                    closeDsReturnSt();
                }
                selectedAllProcessCount();
                selectNoDateTotalOrderList(selectedProcessSt);
            })
            .catch((error) => {
                console.log("리턴처리실패 : ", error)
            })
    })

    /** 국내반송/해외반송 종료 */

    const [selectedData, setSelectedData] = useState(null);

    const expRegDelete = async (cData) => {
        if (!cData || cData.type !== 'payinfo') {
            console.log('dataType 확인요망:', cData);
            return;
        }
        if (!cData) return;
        await axios({
            method: 'post',
            url: '/order/deletePayinfoList/',
            data: {
                customsSeqnum: cData?.customsDTO?.seqNum,
                appReqSeqnum: cData?.appReqDTO?.seqNum,
                appRecSeqnum: cData?.appRecDTO?.seqnum,
                shipInfoSeqnum: cData?.shipInfoDTO?.seqNum,
                payConSeqnum: cData?.payConDTOList?.map(item => item.seqNum),
                packListSeqnum: cData?.packListDTO?.seqnum,
                payinfoSeqnum: cData?.seqNum,
                memoSeqnum: cData?.appOrderMemoDTO ? cData.appOrderMemoDTO.amSeqNum : null
            },
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                // 삭제된 결제리스트 payinfo 필터
                const updatedSortedPayinfoList = payinfoList.filter((item) => item.seqNum !== cData.seqNum);
                setPayinfoList(updatedSortedPayinfoList);
                modalCancel3();
                selectedAllProcessCount();
            })
            .catch((error) => {
                console.log("결제리스트 삭제실패 : ", error)
            })
    };

    const totalProdQyDv = ((cData) => {
        // 상품총수량(payinfo)
        return cData?.packListDTO?.productsDTO?.reduce((totalSum, item) => {
            if (item) {
                return totalSum + item.quantity;
            }
            return totalSum;
        }, 0);
    })

    return (
        <div className="AdminDashBoardPg">
            <div className="admin-wrap">
                <div className="AdminDashBoardSub">
                    <div className="admin-wrap-card dash-header">
                        <div className="display-flex align-center just-cont-sb">
                            <div className="display-flex just-cont-center">
                                <div className="status-card">
                                    <div className="card-img-warp">
                                        <div style={{display: "inline-block", width: "65px", height: "65px"}}>
                                            <img className="default-img" alt="" src={mypgOrder}/>
                                        </div>
                                    </div>
                                    <div className="m-b-15"><b className="c-def font-s-17 font-t-title" style={{textDecoration:"underline", textUnderlinePosition:"under"}}>주문</b></div>
                                    <div className="admin-status-ord font-t-cont">
                                        <div className="status-cont" style={{border: selectedProcessSt === "OS00" ? "solid" : null,
                                            borderColor: selectedProcessSt === "OS00" ? "#FFAF34" : null}} onClick={()=> selectNoDateTotalOrderList("OS00")}>
                                            <a style={{cursor:"pointer"}}>입고대기</a>
                                            <div>
                                                <b style={{cursor:"pointer"}}>{processData.OS00 || 0}</b>
                                            </div>
                                        </div>
                                        <div className="status-cont" style={{border: selectedProcessSt === "OS01" ? "solid" : null,
                                            borderColor: selectedProcessSt === "OS01" ? "#FFAF34" : null}} onClick={()=> selectNoDateTotalOrderList("OS01")}>
                                            <a style={{cursor:"pointer"}}>결제대기</a>
                                            <div>
                                                <b style={{cursor:"pointer"}}>{processData.OS01 || 0}</b>
                                            </div>
                                        </div>
                                        <div className="status-cont" style={{border: selectedProcessSt === "OS02" ? "solid" : null,
                                            borderColor: selectedProcessSt === "OS02" ? "#FFAF34" : null}} onClick={()=> selectNoDateTotalOrderList("OS02")}>
                                            <a style={{cursor:"pointer"}}>결제중</a>
                                            <div>
                                                <b style={{cursor:"pointer"}}>{processData.OS02 || 0}</b>
                                            </div>
                                        </div>
                                        <div className="status-cont" style={{border: selectedProcessSt === "OS03" ? "solid" : null,
                                            borderColor: selectedProcessSt === "OS03" ? "#FFAF34" : null}} onClick={()=> selectNoDateTotalOrderList("OS03")}>
                                            <a style={{cursor:"pointer"}}>결제완료</a>
                                            <div>
                                                <b style={{cursor:"pointer"}}>{processData.OS03 || 0}</b>
                                            </div>
                                        </div>
                                        <div className="status-cont" style={{border: selectedProcessSt === "OS04" ? "solid" : null,
                                            borderColor: selectedProcessSt === "OS04" ? "#FFAF34" : null}} onClick={()=> selectNoDateTotalOrderList("OS04")}>
                                            <a style={{cursor:"pointer"}}>구매중</a>
                                            <div>
                                                <b style={{cursor:"pointer"}}>{processData.OS04 || 0}</b>
                                            </div>
                                        </div>
                                        <div className="status-cont" style={{border: selectedProcessSt === "OS05" ? "solid" : null,
                                            borderColor: selectedProcessSt === "OS05" ? "#FFAF34" : null}} onClick={()=> selectNoDateTotalOrderList("OS05")}>
                                            <a style={{cursor:"pointer"}}>구매완료</a>
                                            <div>
                                                <b style={{cursor:"pointer"}}>{processData.OS05 || 0}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="gbn-border"></div>
                                <div className="status-card">
                                    <div className="card-img-warp">
                                        <div style={{display: "inline-block", width: "65px", height: "65px"}}>
                                            <img className="default-img" alt="" src={mypgDelivery}/>
                                        </div>
                                    </div>
                                    <div className="m-b-15"><b className="c-def font-s-17 font-t-title" style={{textDecoration:"underline", textUnderlinePosition:"under"}}>배송</b></div>
                                    <div className="admin-status-ship font-t-cont">
                                        <div className="status-cont" style={{border: selectedProcessSt === "OS06" ? "solid" : null,
                                            borderColor: selectedProcessSt === "OS06" ? "#FFAF34" : null}} onClick={()=> selectNoDateTotalOrderList("OS06")}>
                                            <a style={{cursor:"pointer"}}>입고중</a>
                                            <div>
                                                <b style={{cursor:"pointer"}}>{processData.OS06 || 0}</b>
                                            </div>
                                        </div>
                                        <div className="status-cont" style={{border: selectedProcessSt === "OS07" ? "solid" : null,
                                            borderColor: selectedProcessSt === "OS07" ? "#FFAF34" : null}} onClick={()=> selectNoDateTotalOrderList("OS07")}>
                                            <a style={{cursor:"pointer"}}>센터도착</a>
                                            <div>
                                                <b style={{cursor:"pointer"}}>{processData.OS07 || 0}</b>
                                            </div>
                                        </div>
                                        <div className="status-cont" style={{border: selectedProcessSt === "OS08" ? "solid" : null,
                                            borderColor: selectedProcessSt === "OS08" ? "#FFAF34" : null}} onClick={()=> selectNoDateTotalOrderList("OS08")}>
                                            <a style={{cursor:"pointer"}}>패킹중</a>
                                            <div>
                                                <b style={{cursor:"pointer"}}>{processData.OS08 || 0}</b>
                                            </div>
                                        </div>
                                        <div className="status-cont" style={{border: selectedProcessSt === "PS0" ? "solid" : null,
                                            borderColor: selectedProcessSt === "PS0" ? "#FFAF34" : null}} onClick={()=> selectNoDateTotalOrderList("PS0")}>
                                            <a style={{cursor:"pointer"}}>결제대기</a>
                                            <div>
                                                <b style={{cursor:"pointer"}}>{processData.OS09 + processData.PS0 || 0}</b>
                                            </div>
                                        </div>
                                        <div className="status-cont" style={{border: selectedProcessSt === "PS1" ? "solid" : null,
                                            borderColor: selectedProcessSt === "PS1" ? "#FFAF34" : null}} onClick={()=> selectNoDateTotalOrderList("PS1")}>
                                            <a style={{cursor:"pointer"}}>결제중</a>
                                            <div>
                                                <b style={{cursor:"pointer"}}>{processData.OS10 + processData.PS1 || 0}</b>
                                            </div>
                                        </div>
                                        <div className="status-cont" style={{border: selectedProcessSt === "PS2" ? "solid" : null,
                                            borderColor: selectedProcessSt === "PS2" ? "#FFAF34" : null}} onClick={()=> selectNoDateTotalOrderList("PS2")}>
                                            <a style={{cursor:"pointer"}}>결제완료</a>
                                            <div>
                                                <b style={{cursor:"pointer"}}>{processData.OS11 + processData.PS2 || 0}</b>
                                            </div>
                                        </div>
                                        <div className="status-cont" style={{border: selectedProcessSt === "PS3" ? "solid" : null,
                                            borderColor: selectedProcessSt === "PS3" ? "#FFAF34" : null}} onClick={()=> selectNoDateTotalOrderList("PS3")}>
                                            <a style={{cursor:"pointer"}}>출고완료</a>
                                            <div>
                                                <b style={{cursor:"pointer"}}>{processData.OS12 + processData.PS3 || 0}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="gbn-border"></div>
                                <div className="status-card">
                                    <div className="card-img-warp">
                                        <div style={{display: "inline-block", width: "65px", height: "65px"}}>
                                            <img className="default-img" alt="" src={mypgReturn}/>
                                        </div>
                                    </div>
                                    <div className="m-b-15"><b className="c-def font-s-17 font-t-title" style={{textDecoration:"underline", textUnderlinePosition:"under"}}>반송</b></div>
                                    <div className="admin-status-return font-t-cont">
                                        <div className="status-cont" style={{border: selectedProcessSt === "PS4" ? "solid" : null,
                                            borderColor: selectedProcessSt === "PS4" ? "#FFAF34" : null}} onClick={()=> selectNoDateTotalOrderList("PS4")}>
                                            <a style={{cursor:"pointer"}}>반송중</a>
                                            <div>
                                                <b style={{cursor:"pointer"}}>{processData.OS13 + processData.PS4 || 0}</b>
                                            </div>
                                        </div>
                                        <div className="status-cont" style={{border: selectedProcessSt === "PS5" ? "solid" : null,
                                            borderColor: selectedProcessSt === "PS5" ? "#FFAF34" : null}} onClick={()=> selectNoDateTotalOrderList("PS5")}>
                                            <a style={{cursor:"pointer"}}>취소</a>
                                            <div>
                                                <b style={{cursor:"pointer"}}>{processData.OS14 + processData.PS5 || 0}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{width:"700px"}}>
                                <div className="board-lr">
                                    <div className="card-wrap-r" style={{borderTopLeftRadius: "10px", borderTopRightRadius: "10px"}}>
                                        <div>
                                            <div className="cooper-title text-center ht-30 p-t-3" style={{borderTopLeftRadius: "10px", borderTopRightRadius: "10px",}}>
                                                <b className="font-c-white c-def font-t-title">공지사항</b>
                                            </div>
                                        </div>
                                        <div className="cooper-card-memo font-t-cont" style={{display: "block", position: "relative", overflowX: "hidden", overflowY: "auto", height: "270px", padding:"10px"}}>
                                            <table className="admin-tb">
                                                <tbody className="admin-tb-body">
                                                {data.map((cData, i) => {
                                                    return (
                                                        <tr className="admin-tb-body-line main-td-hover" key={cData.coSeqNum} style={{height: "35px"}}>
                                                            <td style={{width: "40px"}}>
                                                                <input className="admin-input-chkBox-s" type="checkbox" name={cData.coSeqNum} checked={noticeChecked.includes(cData.coSeqNum)}
                                                                       onChange={() => noticeCheckBoxChange(cData.coSeqNum)}
                                                                />
                                                            </td>
                                                            <td style={{width: "40px"}}><a style={{fontSize: "14px"}}>{i + 1}</a></td>
                                                            <td><a style={{fontWeight: "bold", fontSize: "13px"}}>{data[i].coopCont}</a></td>
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="dark-br-top font-t-cont text-center" style={{padding: "1px"}}>
                                            <input value={coopCont} onChange={Cont} className="admin-input-line p-l-5 m-r-3 font-s-13"
                                                   placeholder={"내용을 입력하세요.(50자 이내)"} type={"text"} maxLength={50}
                                                   style={{width: "85%"}} />
                                            <button className="admin-bt-color btn-click-event wd-40" onClick={coopSave}>등록</button>
                                            <button className="admin-bt-color btn-click-event wd-40" onClick={alert2}>삭제</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="admin-wrap-card dash-cont">
                        <div className="status-board-work">
                            <div style={{width:"65.5%", marginBottom:"15px"}}>
                                <div className="service-card c-def" style={{width:"100%"}}>
                                    <div style={{textAlign: "center", marginTop: "7px", marginBottom: "27px"}}>
                                        <h5 style={{paddingTop:"10px"}} className="c-def font-t-title font-c-default">Order List</h5>
                                    </div>
                                    <div className="font-t-cont" style={{display:"flex", alignItems:"center", justifyContent:"space-between", padding:"30px", flexDirection: "row"}}>
                                        <div onClick={()=> moveToAlarmListPg('cart', lcCartProd.length)} style={{display:"flex", alignItems:"center", cursor:"pointer"}}>
                                            {/*<div className="font-s-18 btorage-hover dark-font m-r-5">장바구니</div>*/}
                                            {/*<div className="font-s-16 font-b dark-font">{lcCartProd.length || 0}</div>*/}
                                            <p className="font-s-18 btorage-hover" style={{margin:"0"}}>장바구니</p>
                                            <span className="badge-dash" style={{marginTop:"7px"}}>
                                                <p className="font-s-18 font-b">{lcCartProd.length || 0}</p>
                                            </span>
                                        </div>
                                        <div onClick={()=> moveToAlarmListPg('order', lcOrderProd.length)} style={{display:"flex", alignItems:"center", cursor:"pointer"}}>
                                            {/*<div className="font-s-18 btorage-hover dark-font m-r-5">구매대행</div>*/}
                                            {/*<div className="font-s-16 font-b dark-font">{lcOrderProd.length || 0}</div>*/}
                                            <p className="font-s-18 btorage-hover" style={{margin:"0"}}>구매대행</p>
                                            <span className="badge-dash dark-font" style={{marginTop:"7px"}}>
                                                <p className="font-c-white">{lcOrderProd.length || 0}</p>
                                            </span>
                                        </div>
                                        <div onClick={()=> moveToAlarmListPg('delivery', lcDeliveryProd.length)} style={{display:"flex", alignItems:"center", cursor:"pointer"}}>
                                            {/*<div className="font-s-18 btorage-hover dark-font m-r-5">배송대행</div>*/}
                                            {/*<div className="font-s-16 font-b dark-font">{lcDeliveryProd.length || 0}</div>*/}
                                            <p className="font-s-18 btorage-hover" style={{margin:"0"}}>배송대행</p>
                                            <span className="badge-dash dark-font" style={{marginTop:"7px"}}>
                                                <p className="font-c-white">{lcDeliveryProd.length || 0}</p>
                                            </span>
                                        </div>
                                        <div onClick={()=> moveToAlarmListPg('transfer', lcTransferProd.length)} style={{display:"flex", alignItems:"center", cursor:"pointer"}}>
                                            {/*<div className="font-s-18 btorage-hover dark-font m-r-5">이체대행</div>*/}
                                            {/*<div className="font-s-16 font-b dark-font">{lcTransferProd.length || 0}</div>*/}
                                            <p className="font-s-18 btorage-hover" style={{margin:"0"}}>이체대행</p>
                                            <span className="badge-dash dark-font" style={{marginTop:"7px"}}>
                                                <p className="font-c-white">{lcTransferProd.length || 0}</p>
                                            </span>
                                        </div>
                                        <div onClick={()=> moveToAlarmListPg('offline', lcOfflineProd.length)} style={{display:"flex", alignItems:"center", cursor:"pointer"}}>
                                            {/*<div className="font-s-18 btorage-hover dark-font m-r-5">오프라인</div>*/}
                                            {/*<div className="font-s-16 font-b dark-font">{lcOfflineProd.length || 0}</div>*/}
                                            <p className="font-s-18 btorage-hover" style={{margin:"0"}}>오프라인</p>
                                            <span className="badge-dash dark-font" style={{marginTop:"7px"}}>
                                                <p className="font-c-white">{lcOfflineProd.length || 0}</p>
                                            </span>
                                        </div>
                                        <div onClick={()=> moveToAlarmListPg('domestic', lcDomesticProd.length)} style={{display:"flex", alignItems:"center", cursor:"pointer"}}>
                                            {/*<div className="font-s-18 btorage-hover dark-font m-r-5">국내배송</div>*/}
                                            {/*<div className="font-s-16 font-b dark-font">{lcDomesticProd.length || 0}</div>*/}
                                            <p className="font-s-18 btorage-hover" style={{margin:"0"}}>국내배송</p>
                                            <span className="badge-dash dark-font" style={{marginTop:"7px"}}>
                                                <p className="font-c-white">{lcDomesticProd.length || 0}</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{width:"33%"}}>
                                <div className="service-card-state c-def">
                                    <div style={{textAlign: "center", marginTop: "7px"}}>
                                        <h5 style={{paddingTop:"10px"}} className="c-def font-t-title font-c-default">Packing</h5>
                                    </div>
                                    <div style={{textAlign: "center", marginTop: "22px"}}>
                                        <div className="dfj-frs">
                                            <div style={{width: "100px", display: "flex", justifyContent: "center"}}>
                                                <PiPackageLight className="dark-icon" style={{fontSize: "45px", margin: "auto"}}/>
                                            </div>
                                            <div style={{width: "100px", display: "flex", justifyContent: "center"}}>
                                                <b className={processData.OS07 === 0 ? "font-s-40 c-point" : "real-text font-s-40 c-point"}
                                                   // style={{color: "#276ab7"}}
                                                   onClick={() => selectNoDateTotalOrderList("OS07")}>{processData.OS07 || 0}
                                                </b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{width:"33%"}}>
                                <div className="service-card c-def">
                                    <div style={{textAlign: "center", marginTop: "7px", marginBottom: "42px"}}>
                                        <h5 style={{paddingTop:"10px"}} className="c-def font-t-title font-c-default">Payment</h5>
                                    </div>
                                    <div style={{textAlign: "center", marginTop: "10px"}}>
                                        <div className="dfj-frs font-t-cont">
                                            <div onClick={()=> moveToAlarmListPg('pay', lcPayData.length)} style={{display:"flex", alignItems:"center", cursor:"pointer"}}>
                                                {/*<div className="font-s-18 btorage-hover dark-font m-r-5">Pay</div>*/}
                                                {/*<div className="font-s-16 font-b font-c-btorage">{lcPayData.length || 0}</div>*/}
                                                <p className="font-s-18 btorage-hover" style={{margin:"0"}}>Pay</p>
                                                <span className="badge-dash dark-font" style={{marginTop:"7px"}}>
                                                <p className="font-c-white">{lcPayData.length || 0}</p>
                                                </span>
                                            </div>
                                            <div onClick={()=> moveToAlarmListPg('deposit', lcDepositData.length)} style={{display:"flex", alignItems:"center", cursor:"pointer"}}>
                                                {/*<div className="font-s-18 btorage-hover dark-font m-r-5">예치금신청</div>*/}
                                                {/*<div className="font-s-16 font-b font-c-btorage">{lcDepositData.length || 0}</div>*/}
                                                <p className="font-s-18 btorage-hover">예치금신청</p>
                                                <span className="badge-dash dark-font" style={{marginTop:"7px"}}>
                                                <p className="font-c-white">{lcDepositData.length || 0}</p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{width:"33%"}}>
                                <div className="service-card c-def">
                                    <div style={{textAlign: "center", marginTop: "7px", marginBottom: "42px"}}>
                                        <h5 style={{paddingTop:"10px"}} className="c-def font-t-title font-c-default">Customer Service</h5>
                                    </div>
                                    <div style={{textAlign: "center", marginTop: "10px"}}>
                                        <div className="dfj-frs font-t-cont">
                                            <div onClick={()=> moveToAlarmListPg('review', lcReviewData.length)} style={{display:"flex", alignItems:"center", cursor:"pointer"}}>
                                                {/*<div className="font-s-18 btorage-hover dark-font m-r-5">이용후기</div>*/}
                                                {/*<div className="font-s-16 font-b font-c-btorage">{lcReviewData.length || 0}</div>*/}
                                                <p className="font-s-18 btorage-hover" style={{margin:"0"}}>이용후기</p>
                                                <span className="badge-dash dark-font" style={{marginTop:"7px"}}>
                                                <p className="font-c-white">{lcReviewData.length || 0}</p>
                                                </span>
                                            </div>
                                            <div onClick={()=> navigate("/btcorpad/adminorderchat")} style={{display:"flex", alignItems:"center", cursor:"pointer"}}>
                                                {/*<div className="font-s-18 btorage-hover dark-font m-r-5">주문서댓글</div>*/}
                                                {/*<div className="font-s-12 font-b dark-font">준비중</div>*/}
                                                <p className="font-s-18 btorage-hover" style={{margin:"0"}}>실시간채팅</p>
                                                <span className="badge-dash dark-font" style={{marginTop:"7px"}}>
                                                <p className="font-c-white">{lcChatData || 0}</p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{width:"33%"}}>
                                <div className="service-card-state c-def">
                                    <div style={{textAlign: "center", marginTop: "7px"}}>
                                        <h5 style={{paddingTop:"10px"}} className="c-def font-t-title font-c-default">Receipt</h5>
                                    </div>
                                    <div style={{textAlign: "center", marginTop: "22px"}}>
                                        <div className="dfj-frs">
                                            <div style={{width: "100px", display: "flex", justifyContent: "center"}}>
                                                <PiAirplaneTakeoff className="dark-icon" style={{fontSize: "45px", margin: "auto"}}/>
                                            </div>
                                            <div style={{width: "100px", display: "flex", justifyContent: "center"}}>
                                                <Link className="font-b  font-s-40" to="/btcorpad/receiptlist" style={{color: "#FFAF34"}}>
                                                    {ordReceiptCountData}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {alertShow === true ? <MsgSelectModal alertShow={alertShow} eventHandle1={modalCancel} eventHandle2={coopSave} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
                    {alertShow2 === true ? <MsgSelectModal alertShow={alertShow2} eventHandle1={modalCancel2} eventHandle2={coopDataDelete} msgData1={msgConfirmData3} msgData2={msgConfirmData4}/> : null}
                </div>
                <div>
                    <div className="admin-wrap-card">
                        <section className="dash-cont">
                            <div className="status-board-order">
                                <Tabs className="ord-tab" defaultActiveKey="tab-task">
                                    <Tab className="tab-task" eventKey="tab-task" title="진행주문서">
                                        <div className="AdminOrdList">
                                            <div>
                                                <div className="admin-event-wrap" style={{padding: "17px 10px 0 10px", marginBottom: "33px"}}>
                                                    <div className="admin-event-wrap-btn font-t-cont m-t-3">
                                                        <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                                                        <AdminYmdEvent startDate={startDate} endDate={endDate} startHandle={startHandle} endHandle={endHandle}/>
                                                        <div style={{marginRight: "15px"}}>
                                                            <select className="admin-combo wd-110 font-s-14 p-l-7 ht-30">
                                                                <option>1개월</option>
                                                                <option>3개월</option>
                                                                <option>6개월</option>
                                                                <option>1년</option>
                                                            </select>
                                                        </div>
                                                        <div style={{marginRight: "15px"}}>
                                                            <select  className="admin-combo wd-110 ht-30 p-l-7 font-s-14"
                                                                     onChange={(e) => setSelectedShipCompany(e.target.value)} value={selectedShipCompany}>
                                                                <option value="배송사선택">배송사선택</option>
                                                                <option value="FedEx">FedEx</option>
                                                                <option value="UPS">UPS</option>
                                                                <option value="EMS">EMS</option>
                                                                <option value="SHIP">SHIP</option>
                                                                <option value="국내배송">국내배송</option>
                                                                {/*<option value="DHL">DHL</option>*/}
                                                                <option value="K-Packet">K-Packet</option>
                                                                <option value="Small-Packet">Small-Packet</option>
                                                            </select>
                                                        </div>
                                                        <div style={{marginRight: "15px"}}>
                                                            <select className="admin-combo wd-110 ht-30 p-l-7 font-s-14"
                                                                    onChange={(e)=> setOrderStSelectCommonValue(e.target.value)} value={orderStSelectCommonValue}>
                                                                <option value="상태(전체)">상태(전체)</option>
                                                                <option value="OS00">입고대기</option>
                                                                <option value="OS01">결제대기(상품)</option>
                                                                <option value="OS02">결제중(상품)</option>
                                                                <option value="OS03">결제완료(상품)</option>
                                                                <option value="OS04">구매중</option>
                                                                <option value="OS05">구매완료</option>
                                                                <option value="OS06">입고중</option>
                                                                <option value="OS07">센터도착</option>
                                                                <option value="OS08">패킹중</option>
                                                                <option value="PS0">결제대기(배송)</option>
                                                                <option value="PS1">결제중(배송)</option>
                                                                <option value="PS2">결제완료(배송)ㄴ</option>
                                                                <option value="PS3">출고완료</option>
                                                                <option value="PS4">반송중</option>
                                                                <option value="PS5">취소</option>
                                                            </select>
                                                        </div>
                                                        <AdminSearchEvent searchInput={searchInput} handleSearchInputChange={handleSearchInputChange}/>
                                                    </div>
                                                    <div className="admin-event-wrap-btn">
                                                        <button onClick={excelDownOrder} className="admin-btn-long admin-bt-color">EXCEL[주문]</button>
                                                        <button onClick={excelDown} className="admin-btn-long admin-bt-color">EXCEL[세무]</button>
                                                    </div>
                                                </div>
                                                <Table className="ord-main-tb admin-tb font-t-cont" bordered>
                                                    <thead ref={orderContentRef} className="ord-main-tb-head">
                                                    <tr className="ord-main-tb-head-line">
                                                        <th style={{width: "30px"}}>
                                                            <li><input className="admin-input-chkBox-s" type="checkbox"
                                                                       checked={checked.length > 0 && checked.length === filteredList.length}
                                                                       onChange={allcheckBoxChange}/></li>
                                                        </th>
                                                        <th style={{width: "140px"}}>
                                                            <li>
                                                                <b>주문번호</b>
                                                            </li>
                                                        </th>
                                                        <th style={{width: "90px"}}>
                                                            <li><b>주문서유형</b></li>
                                                        </th>
                                                        <th style={{width: "150px"}}>
                                                            <li>
                                                                <b>사서함번호</b>
                                                                <b>고객명</b>
                                                                <b>수취인성함</b>
                                                            </li>
                                                        </th>
                                                        <th style={{width: "115px"}}>
                                                            <li>
                                                                <b>국가</b>
                                                                <b>CS언어</b>
                                                            </li>
                                                        </th>
                                                        <th style={{width: "190px"}}>
                                                            <li><b>상품명</b></li>
                                                        </th>
                                                        <th style={{width: "100px"}}>
                                                            <li>
                                                                <b>상태</b>
                                                            </li>
                                                        </th>
                                                        <th style={{width: "140px"}}>
                                                            <li>
                                                                <b>배송사</b>
                                                                <b>운송장번호</b>
                                                            </li>
                                                        </th>
                                                        <th style={{width: "110px"}}>
                                                            <li>
                                                                <b>세관신고수량</b>
                                                                <b>세관신고금액</b>
                                                                <b>보험여부</b>
                                                                <b>내용품명</b>
                                                            </li>
                                                        </th>
                                                        <th style={{width: "100px"}}>
                                                            <li>
                                                                <b>상품미결제</b>
                                                                <b>상품총결제</b>
                                                                <b>기타미결제</b>
                                                                <b>기타총결제</b>
                                                            </li>
                                                        </th>
                                                        <th style={{width: "100px"}}>
                                                            <li><b>상품수량</b></li>
                                                            <li><b>예치금</b></li>
                                                            <li><b>적립금</b></li>
                                                        </th>
                                                        <th style={{width: "110px"}}>
                                                            <li>
                                                                <b>작성일시</b>
                                                                <b>수정일시</b>
                                                                <b>출고일시</b>
                                                                <b>패킹일시</b>
                                                            </li>
                                                        </th>
                                                        <th style={{width: "110px"}}>
                                                            <li><b>System</b></li>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    {filteredList.slice(indexOfFirst, indexOfLast).map((cData, i) => {
                                                        // const key = cData.seqnum || i; // 2개 다 쓸 경우 중복오류 발생가능있음
                                                        const key = i;
                                                        let data1 = 0, data2 = 0, data3 = 0, data4 = 0;
                                                        let totalProdQy = 0

                                                        if (cData.type === 'orderdt') {
                                                            // 상품미결제 + 관리자가 등록한 상품누락금 미결제에 추가
                                                            data1 = cData.productsDTO?.reduce((sum, item) => {
                                                                return (item.prodSt === "IS01" || item.prodSt === "IS02") && item.price > 0 ? sum + item.price + item.mallShipPrice + item.fee : sum;
                                                            }, 0) || 0;
                                                            data1 += cData.payInfoDTO?.reduce((sum, item) => {
                                                                return (item.payStatus === "PS0" || item.payStatus === "PS1") && item.payClass === '1'
                                                                && item.ttProdExpense > 0 ? sum + item.ttProdExpense : sum;
                                                            }, 0) || 0;
                                                            // 상품총결제 + 관리자가 등록한 상품누락금 결제완료에 추가
                                                            data2 = cData.productsDTO?.reduce((sum, item) => {
                                                                return item.prodSt !== "IS01" && item.prodSt !== "IS02" && item.prodSt !== "IS12" && item.price > 0 ? sum + item.price + item.mallShipPrice + item.fee : sum;
                                                            }, 0) || 0;
                                                            data2 += cData.payInfoDTO?.reduce((sum, item) => {
                                                                return item.payStatus !== "PS0" && item.payStatus !== "PS1" && item.payClass === '1'
                                                                && item.ttProdExpense > 0 ? sum + item.ttProdExpense : sum;
                                                            }, 0) || 0;
                                                            // 기타미결제
                                                            data3 = cData.payInfoDTO?.reduce((sum, item) => {
                                                                if ((item.payStatus === "PS0" || item.payStatus === "PS1") && item.payClass === '1') {
                                                                    // payStatus가 PS0 또는 PS1일 때 ttEtcPrice와 ttEtcFee(있을 경우)를 더함
                                                                    const etcPrice = item.ttEtcPrice > 0 ? item.ttEtcPrice : 0;
                                                                    const etcFee = item.ttEtcFee > 0 ? item.ttEtcFee : 0;
                                                                    return sum + etcPrice + etcFee;
                                                                }
                                                                return sum;
                                                            }, 0) || 0;

                                                            // 기타총결제
                                                            data4 = cData?.payInfoDTO?.reduce((sum, item) => {
                                                                if ((item.payStatus !== "PS0" && item.payStatus !== "PS1") && item.payClass === '1') {
                                                                    // payStatus가 PS0 또는 PS1일 때 ttEtcPrice와 ttEtcFee(있을 경우)를 더함
                                                                    const etcPrice = item.ttEtcPrice > 0 ? item.ttEtcPrice : 0;
                                                                    const etcFee = item.ttEtcFee > 0 ? item.ttEtcFee : 0;
                                                                    return sum + etcPrice + etcFee;
                                                                }
                                                                return sum;
                                                            }, 0) || 0;
                                                            // 상품총수량(orderdt)
                                                            totalProdQy = cData?.productsDTO?.reduce((sum, item) => {
                                                                return sum + item.quantity;
                                                            }, 0) || 0;
                                                        }
                                                        return cData.type === 'orderdt' ? (
                                                                <tbody className="ord-main-tb-body" key={key}>
                                                                <tr className="ord-main-tb-body-line">
                                                                    <td>
                                                                        <li><input className="admin-input-chkBox-s" type="checkbox" name={cData.seqnum}
                                                                                   checked={checked.includes(cData.seqnum)}
                                                                                   onChange={() => checkBoxChange(cData.seqnum, 'orderdt')}/></li>
                                                                    </td>
                                                                    <td>
                                                                        <li style={{alignItems: "center"}}>
                                                                            <a className="font-b">{cData.orderNumber}</a>
                                                                            <span style={{display: "flex", flexDirection: "row", marginTop: "15px"}}>
                                                                                <a href={`/btcorpad/orderdtmodi/${cData.orderNumber}`} target="_blank">
                                                                                    <button className="admin-btn-basic wd-40 m-t-3 m-r-5">상세</button>
                                                                                </a>
                                                                                {/*<button onClick={() => toggleMenu(cData.seqnum)} className="admin-bt-color wd-40">*/}
                                                                                <button onClick={() => toggleMenu(cData.seqnum)} className="admin-btn-basic wd-40 m-t-3">
                                                                                    {cData.isOpen ?
                                                                                        <FiMinus className="dt-click"/> : <FiPlus className="dt-click"/>
                                                                                    }
                                                                                </button>
                                                                            </span>
                                                                        </li>
                                                                    </td>
                                                                    <td>
                                                                        <li><a className="font-b">주문서</a></li>
                                                                    </td>
                                                                    <td>
                                                                        <li>
                                                                            <a>BT{cData.memberDTO.mbNum}</a>
                                                                            <a className="font-b font-c-white c-point" onClick={handleClick}>{cData.memberDTO.userName}</a>
                                                                            {/*{userInfoShow && (*/}
                                                                            {/*    <div style={{position: "absolute", width: "150px", height: "100px", border: "solid 2px red", right: "60px"}}>*/}
                                                                            {/*        okay*/}
                                                                            {/*    </div>*/}
                                                                            {/*)}*/}
                                                                            <a style={{textAlign: "center"}}>{cData.recinfoDTO.recname}</a>
                                                                        </li>
                                                                    </td>
                                                                    <td>
                                                                        <li>
                                                                            <a className="font-b">{cData.recinfoDTO.countryDTO.countryNm + "[" + cData.recinfoDTO.countryDTO.countryCode + "]"}</a>
                                                                            <a>{cData.memberDTO.lang}</a>
                                                                        </li>
                                                                    </td>
                                                                    <td>
                                                                        <li className="admin-tb-text-cut">
                                                                            {cData && cData.productsDTO && cData.productsDTO.length > 0 && (
                                                                                <div>
                                                                                    {cData.productsDTO.sort((a,b) => a.seqnum - b.seqnum).slice(0, 6).map((product, index) => (
                                                                                        <div key={index}>
                                                                                            <a>{product.name}</a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )}
                                                                        </li>
                                                                    </td>
                                                                    <td>
                                                                        <li>
                                                                            <a className="font-b">
                                                                                {orderStPrint(cData.orderStatus)}
                                                                            </a>
                                                                        </li>
                                                                    </td>
                                                                    <td>
                                                                        <li style={{alignItems: "center"}}>
                                                                            <a>{cData.shipco}</a>
                                                                        </li>
                                                                    </td>
                                                                    <td>
                                                                        <li className="admin-tb-text-cut">
                                                                            {totalProdQy > 0 ?
                                                                                <a>{totalProdQy}개</a>
                                                                                :
                                                                                <a>0</a>
                                                                            }
                                                                            {/*<a>150$</a>*/}
                                                                            {/*<a className="font-b font-c-cancel">Y</a>*/}
                                                                            {/*<a>cotton pouch and women and foam pouch ddf1344ggrgrd3 plastic scotton*/}
                                                                            {/*    cpasldqwd123123123</a>*/}
                                                                        </li>
                                                                    </td>
                                                                    <td>
                                                                        <li>
                                                                            <a style={{lineHeight: "24px", color:data1 > 0 ? "darkorange" : "black", fontWeight: data1 > 0 ? "bold" : "normal"}}>{data1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</a>
                                                                            <a style={{lineHeight: "24px"}}>{data2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</a>
                                                                            <a style={{lineHeight: "24px", color:data3 > 0 ? "darkorange" : "black", fontWeight: data3 > 0 ? "bold" : "normal"}}>{data3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</a>
                                                                            <a style={{lineHeight: "24px"}}>{data4.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</a>
                                                                        </li>
                                                                    </td>
                                                                    <td>
                                                                        { totalProdQy > 0 ?
                                                                            <a style={{display:"flex", alignItems:"center", justifyContent:"center"}}>{totalProdQy}개</a>
                                                                            :
                                                                            <a style={{display:"flex", alignItems:"center", justifyContent:"center"}}>0개</a>
                                                                        }
                                                                        <div style={{display:"flex", alignItems:"center", margin:"5px 0 0 2px"}}>
                                                                            <img style={{width:"18px", height:"18px"}} alt="deposit" src={deposit}/>
                                                                            <a style={{color: cData.memberDTO.deposit < 0 ? "#FF6628" : "black", marginLeft:"4px"}}>
                                                                                {cData.memberDTO.deposit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                            </a>
                                                                        </div>
                                                                        <div style={{display:"flex", alignItems:"center", marginLeft:"3px"}}>
                                                                            <img style={{width:"16px", height:"16px"}} alt="point" src={point}/>
                                                                            <a style={{color: cData.memberDTO.point < 0 ? "#FF6628" : "black", marginLeft:"5px"}}>
                                                                                {cData.memberDTO.point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                            </a>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <li>
                                                                            <a style={{lineHeight: "28px"}}>{formatDateTime(cData.regTime)}</a>
                                                                            <a className="font-b" style={{lineHeight: "28px"}}>{cData.shipInfoDTO?.modiTime ? formatDateTime(cData.modiTime) : "-"}</a>
                                                                            <a style={{lineHeight: "28px"}}>{cData.shipInfoDTO?.outRegTime ? formatDateTime(cData.shipInfoDTO.outRegTime) : "-"}</a>
                                                                            <a style={{lineHeight: "28px"}}>{cData.shipInfoDTO?.regTime ? formatDateTime(cData.shipInfoDTO.regTime) : "-"}</a>
                                                                        </li>
                                                                    </td>
                                                                    <td>
                                                                        <li style={{alignItems: "center"}}>
                                                                            <button onClick={() => openModalMerge(cData)}
                                                                                    className="admin-bt-color btn-click-event"
                                                                                    style={{width: "75px"}}>통합
                                                                            </button>
                                                                            <button onClick={() => openModalDivide(cData)}
                                                                                    className="admin-bt-color btn-click-event"
                                                                                    style={{width: "75px"}}>분리
                                                                            </button>
                                                                            {/*{cData.type === "orderdt" && cData.productsDTO.filter(product => product.prodSt === "IS05").length > 0 &&*/}
                                                                            {cData.type === "orderdt" &&
                                                                                <button onClick={() => openModal(cData, 'orderdt')} className="admin-bt-color btn-click-event" style={{width: "75px"}}>
                                                                                    비용등록
                                                                                </button>
                                                                            }
                                                                            {/*{cData.type === "orderdt" &&*/}
                                                                            {/*    <button onClick={() => openPayModal(cData, 'orderdt')} className="admin-bt-color btn-click-event" style={{width: "75px"}}>*/}
                                                                            {/*        tt*/}
                                                                            {/*    </button>*/}
                                                                            {/*}*/}

                                                                            {/*<a href={`/btcorpad/btad-Payreg2/${cData.orderNumber}`} target="_blank">*/}
                                                                            {/*    <button className="admin-btn-basic wd-40 m-t-3 m-r-5">상세</button>*/}
                                                                            {/*</a>*/}
                                                                        </li>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="13">
                                                                        <div className="cont-dt">
                                                                            <span className="dt-option">
                                                                                <a className="c-def dark-font">요청옵션 : </a>
                                                                                {cData.reqmnDTO.tagRemove && <a className="font-c-progress">&nbsp;택제거&nbsp;</a>}
                                                                                {cData.reqmnDTO.shoesBoxRemove &&
                                                                                    <a className="font-c-progress">{cData.reqmnDTO.tagRemove &&
                                                                                        <span>/</span>}&nbsp;신발박스제거&nbsp;</a>}
                                                                                {cData.reqmnDTO.hangerRemove &&
                                                                                    <a className="font-c-progress">{cData.reqmnDTO.tagRemove || cData.reqmnDTO.shoesBoxRemove ?
                                                                                        <span>/</span> : ""}&nbsp;옷걸이제거&nbsp;</a>}
                                                                                {cData.reqmnDTO.compressionPack &&
                                                                                    <a className="font-c-progress">{cData.reqmnDTO.tagRemove || cData.reqmnDTO.shoesBoxRemove || cData.reqmnDTO.hangerRemove ?
                                                                                        <span>/</span> : ""}&nbsp;압축팩서비스&nbsp;</a>}
                                                                                {cData.reqmnDTO.prodBoxRemove &&
                                                                                    <a className="font-c-progress">{cData.reqmnDTO.tagRemove || cData.reqmnDTO.shoesBoxRemove || cData.reqmnDTO.hangerRemove || cData.reqmnDTO.compressionPack ?
                                                                                        <span>/</span> : ""}&nbsp;제품박스제거&nbsp;</a>}
                                                                                {cData.reqmnDTO.packingHold &&
                                                                                    <a className="font-c-progress">{cData.reqmnDTO.tagRemove || cData.reqmnDTO.shoesBoxRemove || cData.reqmnDTO.hangerRemove || cData.reqmnDTO.compressionPack || cData.reqmnDTO.prodBoxRemove ?
                                                                                        <span>/</span> : ""}&nbsp;패킹보류&nbsp;</a>}
                                                                                {cData.reqmnDTO.shipPriceDepositpay &&
                                                                                    <a className="font-c-progress">{cData.reqmnDTO.tagRemove || cData.reqmnDTO.shoesBoxRemove || cData.reqmnDTO.hangerRemove || cData.reqmnDTO.compressionPack || cData.reqmnDTO.prodBoxRemove || cData.reqmnDTO.packingHold ?
                                                                                        <span>/</span> : ""}&nbsp;배송료예치금결제&nbsp;</a>}
                                                                                {cData.reqmnDTO.shipPriceMessenger &&
                                                                                    <a className="font-c-progress">{cData.reqmnDTO.tagRemove || cData.reqmnDTO.shoesBoxRemove || cData.reqmnDTO.hangerRemove || cData.reqmnDTO.compressionPack || cData.reqmnDTO.prodBoxRemove || cData.reqmnDTO.packingHold || cData.reqmnDTO.shipPriceDepositpay ?
                                                                                        <span>/</span> : ""}&nbsp;배송료메신저안내&nbsp;</a>}
                                                                            </span>
                                                                            <span className="dt-req">
                                                                                <div className="wd-55">
                                                                                    <a className="c-def dark-font">요청사항 :</a>
                                                                                </div>
                                                                                <div className="wd-100pc">
                                                                                    <a className="font-c-cancel">{cData.reqmnDTO.detailReq}</a>
                                                                                </div>
                                                                            </span>
                                                                            <span className="dt-memo">
                                                                                <a className="c-def dark-font">주문서메모 :&nbsp;</a>
                                                                                <input className="admin-input-line order-memo-input p-l-5" type="text" maxLength="300"
                                                                                       onKeyDown={(e) => onKeyPress(e, cData.seqnum, 'orderMemoDTO')}
                                                                                       value={memoModiData[`${cData.seqnum}-orderMemoDTO`] || cData?.orderMemoDTO?.ordDtCont || ''}
                                                                                       onChange={(e) => handleInputMemoChange(e, cData.seqnum, 'orderMemoDTO')}
                                                                                       ref={(el) => (inputRefs.current[cData.seqnum] = el)}
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                {cData.isOpen && (
                                                                    <tr>
                                                                        <td colSpan="13">
                                                                            <div id="ord-dt-tb-wrap" style={{marginBottom: "15px"}}>
                                                                                <div className="admin-ord-ctg">
                                                                                    <div className="admin-ord-ctg-wrap c-point"
                                                                                         onClick={()=> toggleMenuDTcartFn(i, cData.productsDTO.filter(prod => prod.prodTy === "P00").length)}>
                                                                                        {isOpenCartItemDt[i] ?
                                                                                            <i className="ord-accordion-icon"
                                                                                               style={{transform: "rotate(90deg)"}}/> :
                                                                                            <i className="ord-accordion-icon"></i>
                                                                                        }
                                                                                        <a className="dark-font font-s-14">장바구니</a>
                                                                                        <a style={{color: cData.productsDTO.filter(prod => prod.prodTy === "P00").length === 0 ? "dimgrey" : "darkorange",
                                                                                            fontWeight:"bold", marginLeft:"5px"}}>{cData.productsDTO.filter(prod => prod.prodTy === "P00").length}
                                                                                        </a>
                                                                                    </div>
                                                                                    <div
                                                                                        className={isOpenCartItemDt[i] ? "order-dt-close" : "order-dt-open"}>
                                                                                        <Table striped bordered hover
                                                                                               style={{tableLayout: "fixed"}}>
                                                                                            <thead className="tb-head-td-title">
                                                                                            <tr className="tb-head-title-line">
                                                                                                <th className="tb-head-cont-line-l"
                                                                                                    style={{width: "30px"}}>
                                                                                                    <li><input className="admin-input-chkBox-s" type="checkbox"
                                                                                                               onChange={e => handleCheckAll('cart', i, e.target.checked, cData.productsDTO)}
                                                                                                               checked={selectedItems.cart[i] ? selectedItems.cart[i].length === cData.productsDTO.filter(prod => prod.prodTy === "P00").length : false}
                                                                                                    /></li>
                                                                                                </th>
                                                                                                <th style={{width: "40px"}}>
                                                                                                    <li><b>품번</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "90px"}}>
                                                                                                    <li><b>사진</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "180px"}}>
                                                                                                    <li><b>ID / URL</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "340px"}}>
                                                                                                    <li><b>상품메모</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "260px"}}>
                                                                                                    <li><b>패스워드</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "50px"}}>
                                                                                                    <li><b>수량</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "100px"}}>
                                                                                                    <li><b>장바구니금액</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "80px"}}>
                                                                                                    <li><b>쇼핑몰배송비</b></li>
                                                                                                </th>
                                                                                                <th className="tb-head-cont-line-r"
                                                                                                    style={{width: "150px"}}>
                                                                                                    <li><b>개별현황</b></li>
                                                                                                </th>
                                                                                            </tr>
                                                                                            </thead>
                                                                                            {/*장바구니상품*/}
                                                                                            { cData.productsDTO.filter(prod => prod.prodTy === "P00").sort((a,b) => a.seqnum - b.seqnum).map((cart, index) => (
                                                                                                <tbody key={index} className="tb-head-cont-dt">
                                                                                                <tr className="tb-head-cont-line">
                                                                                                    <td>
                                                                                                        <li>
                                                                                                            <input onChange={e => handleCheckItem('cart', i, cart.seqnum, e.target.checked)}
                                                                                                                   checked={selectedItems.cart[i] ? selectedItems.cart[i].includes(cart.seqnum) : false}
                                                                                                                   className="admin-input-chkBox-s"
                                                                                                                   type="checkbox"/>
                                                                                                        </li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b">1</a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                                                            <span className="ord-prod-img">
                                                                                                                <img className="ord-prod-img-file" src={BtorageWriteLogo} alt="admin-logo"/>
                                                                                                            </span>
                                                                                                        </li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b" href={cart.url} target="_blank">{cart.name}</a></li>
                                                                                                        <li style={{width:"180px"}}><a className="font-b" href={cart.url} target="_blank" style={{
                                                                                                            width:"95%",
                                                                                                            overflow: "hidden",
                                                                                                            textOverflow: "ellipsis",
                                                                                                            whiteSpace:"nowrap",
                                                                                                            wordBreak:"break-all",
                                                                                                            color:"#98B0D4"
                                                                                                        }}>{cart.url}</a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                                                            <textarea
                                                                                                                className="admin-input-line prod-textarea-memo"
                                                                                                                cols="52"
                                                                                                                rows="4"
                                                                                                                value={adminProdMemos[cart.seqnum] || ''}
                                                                                                                onChange={(e) => handleProdMemoChange(cart.seqnum, e.target.value)}
                                                                                                                onKeyDown={(e) => saveProdMemo(e, cart.seqnum)}
                                                                                                            ></textarea>
                                                                                                        </li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                                                            <a className="dark-font" style={{fontSize: "11px"}}>{cart.option}</a>
                                                                                                        </li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b">{cart.quantity}</a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b">{cart.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b">0</a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                                                            <a>{prodStPrint(cart?.prodSt)}</a>
                                                                                                            <a style={{width:"100%"}}>
                                                                                                                {/*여기서부터 커스텀 상품상태 셀렉트*/}
                                                                                                                <div ref={el => cartProdStRef.current[`${i}-${index}`] = el} style={{height: "30px", width: "100%", position: "relative", padding:"3px"}}>
                                                                                                                    <button className="selectParentBt" style={{width: "100%", height: "100%", backgroundColor:"white", border:"none"}}
                                                                                                                            onClick={() => toggleCartProdStShow(i, index)}>
                                                                                                                        <p style={{margin: "0", fontSize:"12px", fontWeight:"lighter"}}>{selectedProdSt[`${'cart'}-${i}-${index}`]?.label || '개별변경'}</p>
                                                                                                                        <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                                                                                                            <i style={{
                                                                                                                                display: "flex",
                                                                                                                                justifyContent: "center",
                                                                                                                                alignItems: "center",
                                                                                                                                height: "100%",
                                                                                                                                color:"dimgray"
                                                                                                                            }}><BiCaretDown/></i>
                                                                                                                        </span>
                                                                                                                    </button>
                                                                                                                    {cartProdStShow[`${i}-${index}`] ?
                                                                                                                        <div style={{
                                                                                                                            width: "100%",
                                                                                                                            border: "solid",
                                                                                                                            maxHeight: "250px",
                                                                                                                            borderWidth: "thin 1px",
                                                                                                                            borderColor: "lightgray",
                                                                                                                            position: "absolute",
                                                                                                                            top: "100%",
                                                                                                                            zIndex: 1000,
                                                                                                                            marginTop: "5px",
                                                                                                                            overflowY: "auto",
                                                                                                                        }}>
                                                                                                                            {prodStList.map((ps, idx) => (
                                                                                                                                <div className="selectChildBt ht-30" key={idx}
                                                                                                                                     onClick={() => handleProdStChange('cart', ps, cart.seqnum, i, index)} style={{
                                                                                                                                    display: "flex", padding: "10px", justifyContent: "center",
                                                                                                                                    alignItems: "center", cursor: "pointer", fontSize: "12px", borderRadius: "4px", zIndex:99,
                                                                                                                                }}>
                                                                                                                                    <p style={{margin:"0", fontSize:"12px"}}>{ps.label}</p>
                                                                                                                                </div>
                                                                                                                            ))}
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        null
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </a>
                                                                                                            { cart?.modiTime && <a className="dark-font">{formatDateTime(cart.modiTime)}(수정)</a>}
                                                                                                            { cart?.orderDate && <a className="dark-font">{formatDateTime(cart.orderDate)}((구매)</a>}
                                                                                                            { cart?.arrDate && <a className="dark-font">{formatDateTime(cart.arrDate)}(도착)</a>}
                                                                                                        </li>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                </tbody>
                                                                                            ))}
                                                                                        </Table>
                                                                                        <div className="cont-dt-bottom">
                                                                                            <span className="dt-bottom-sp-l">
                                                                                                <button className="admin-btn-basic wd-40 ht-25 m-l-7" onClick={()=> toggleMenuDTcartFn(i, cData.productsDTO.filter(prod => prod.prodTy === "P00").length)}><TbArrowBarToUp className="up-icon"/></button>
                                                                                            </span>
                                                                                            <span className="dt-bottom-sp-r" style={{width:"130px"}}>
                                                                                                <div style={{display:"flex", alignItems:"center", width:"100%", height:"100%"}}>
                                                                                                {/*여기서부터 커스텀 전체상태변경 셀렉트*/}
                                                                                                    <div ref={el => allCartProdStRefs.current[`${i}`] = el} style={{height: "100%", width: "100%", position: "relative", marginRight:"10px"}}>
                                                                                                        <button className="selectParentBt" style={{width: "100%", height: "100%"}}
                                                                                                                onClick={() => toggleAllCartProdStShow(i)}>
                                                                                                            <p style={{margin: "0", fontSize:"13px"}}>{selectedAllCartProdSt[i]?.label || '전체변경'}</p>
                                                                                                            <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                                                                                                <i style={{
                                                                                                                    display: "flex",
                                                                                                                    justifyContent: "center",
                                                                                                                    alignItems: "center",
                                                                                                                    height: "100%",
                                                                                                                    color:"dimgray"
                                                                                                                }}><BiCaretDown/></i>
                                                                                                            </span>
                                                                                                        </button>
                                                                                                        {allCartProdStShow[i] ?
                                                                                                            <div style={{
                                                                                                                width: "100%",
                                                                                                                border: "solid",
                                                                                                                maxHeight: "250px",
                                                                                                                borderWidth: "thin 1px",
                                                                                                                borderColor: "lightgray",
                                                                                                                position: "absolute",
                                                                                                                top: "100%",
                                                                                                                zIndex: 1000,
                                                                                                                marginTop: "5px",
                                                                                                                overflowY: "auto",
                                                                                                            }}>
                                                                                                                {prodStList.map((aps, idx) => (
                                                                                                                    <div className="selectChildBt ht-30" key={idx}
                                                                                                                         onClick={() => handleAllProdStChange('cart', i, aps)} style={{
                                                                                                                        display: "flex", padding: "10px", justifyContent: "center",
                                                                                                                        alignItems: "center", cursor: "pointer", fontSize: "12px", borderRadius: "4px", zIndex:99,
                                                                                                                    }}>{aps.label}</div>
                                                                                                                ))}
                                                                                                            </div>
                                                                                                            :
                                                                                                            null
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="admin-ord-ctg-wrap c-point"
                                                                                         onClick={()=> toggleMenuDTorderFn(i, cData.productsDTO.filter(prod => prod.prodTy === "P01").length)}>
                                                                                        {isOpenOrderItemDt[i] ?
                                                                                            <i className="ord-accordion-icon"
                                                                                               style={{transform: "rotate(90deg)"}}/> :
                                                                                            <i className="ord-accordion-icon"></i>
                                                                                        }
                                                                                        <a className="dark-font font-s-14">구매대행</a>
                                                                                        <a style={{color: cData.productsDTO.filter(prod => prod.prodTy === "P01").length === 0 ? "dimgrey" : "darkorange",
                                                                                            fontWeight:"bold", marginLeft:"5px"}}>{cData.productsDTO.filter(prod => prod.prodTy === "P01").length}
                                                                                        </a>
                                                                                    </div>
                                                                                    <div
                                                                                        className={isOpenOrderItemDt[i] ? "order-dt-close" : "order-dt-open"}>
                                                                                        <Table striped bordered hover
                                                                                               style={{tableLayout: "fixed"}}>
                                                                                            <thead className="tb-head-td-title">
                                                                                            <tr className="tb-head-title-line">
                                                                                                <th className="tb-head-cont-line-l"
                                                                                                    style={{width: "30px"}}>
                                                                                                    <li><input className="admin-input-chkBox-s" type="checkbox"
                                                                                                               onChange={e => handleCheckAll('order', i, e.target.checked, cData.productsDTO)}
                                                                                                               checked={selectedItems.order[i] ? selectedItems.order[i].length === cData.productsDTO.filter(prod => prod.prodTy === "P01").length : false}
                                                                                                    /></li>
                                                                                                </th>
                                                                                                <th style={{width: "40px"}}>
                                                                                                    <li><b>품번</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "90px"}}>
                                                                                                    <li><b>사진</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "180px"}}>
                                                                                                    <li><b>상품명 / URL / 운송장번호</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "340px"}}>
                                                                                                    <li><b>상품메모</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "260px"}}>
                                                                                                    <li><b>상품옵션</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "50px"}}>
                                                                                                    <li><b>수량</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "100px"}}>
                                                                                                    <li><b>구매금액</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "85px"}}>
                                                                                                    <li><b>쇼핑몰배송비</b></li>
                                                                                                </th>
                                                                                                <th className="tb-head-cont-line-r"
                                                                                                    style={{width: "150px"}}>
                                                                                                    <li><b>개별현황</b></li>
                                                                                                </th>
                                                                                            </tr>
                                                                                            </thead>
                                                                                            {/*구매대행상품*/}
                                                                                            { cData.productsDTO.filter(prod => prod.prodTy === "P01").sort((a,b) => a.seqnum - b.seqnum).map((order, index) => (
                                                                                                <tbody key={index} className="tb-head-cont-dt">
                                                                                                <tr className="tb-head-cont-line">
                                                                                                    <td>
                                                                                                        <li><input
                                                                                                            onChange={e => handleCheckItem('order', i, order.seqnum, e.target.checked)}
                                                                                                            checked={selectedItems.order[i] ? selectedItems.order[i].includes(order.seqnum) : false}
                                                                                                            className="admin-input-chkBox-s"
                                                                                                            type="checkbox"/></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b">1</a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                                                            <span className="ord-prod-img">
                                                                                                                <img className="ord-prod-img-file"
                                                                                                                     src={BtorageWriteLogo} alt="admin-logo"/>
                                                                                                            </span>
                                                                                                        </li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b"
                                                                                                               href={order.url}
                                                                                                               target="_blank">
                                                                                                            {order.name}
                                                                                                        </a></li>
                                                                                                        <li style={{width: "180px"}}>
                                                                                                            <a className="font-b"
                                                                                                               href={order.url}
                                                                                                               target="_blank"
                                                                                                               style={{
                                                                                                                   width: "95%",
                                                                                                                   overflow: "hidden",
                                                                                                                   textOverflow: "ellipsis",
                                                                                                                   whiteSpace: "nowrap",
                                                                                                                   wordBreak: "break-all",
                                                                                                                   color:"#98B0D4"
                                                                                                               }}>{order.url}</a>
                                                                                                        </li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                                                            <textarea
                                                                                                                className="admin-input-line prod-textarea-memo"
                                                                                                                cols="52"
                                                                                                                rows="4"
                                                                                                                value={adminProdMemos[order.seqnum] || ''} // 초기값 설정
                                                                                                                onChange={(e) => handleProdMemoChange(order.seqnum, e.target.value)}
                                                                                                                onKeyDown={(e) => saveProdMemo(e, order.seqnum)}
                                                                                                            ></textarea>
                                                                                                        </li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                                                            <a className="dark-font"
                                                                                                               style={{fontSize: "11px"}}>
                                                                                                                {order?.option}
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b">{order?.quantity}</a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b">{order?.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b">{order?.mallShipPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                                                            <a>{prodStPrint(order?.prodSt)}</a>
                                                                                                            <a style={{width:"100%"}}>
                                                                                                                <div ref={el => orderProdStRef.current[`${i}-${index}`] = el} style={{height: "30px", width: "100%", position: "relative", padding:"3px"}}>
                                                                                                                    <button className="selectParentBt" style={{width: "100%", height: "100%", backgroundColor:"white", border:"none"}}
                                                                                                                            onClick={() => toggleOrderProdStShow(i, index)}>
                                                                                                                        <p style={{margin: "0", fontSize:"12px", fontWeight:"lighter"}}>{selectedProdSt[`${'order'}-${i}-${index}`]?.label || '개별변경'}</p>
                                                                                                                        <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                                                                                                            <i style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%", color:"dimgray"}}><BiCaretDown/></i>
                                                                                                                        </span>
                                                                                                                    </button>
                                                                                                                    {orderProdStShow[`${i}-${index}`] ?
                                                                                                                        <div style={{
                                                                                                                            width: "100%",
                                                                                                                            border: "solid",
                                                                                                                            maxHeight: "250px",
                                                                                                                            borderWidth: "thin 1px",
                                                                                                                            borderColor: "lightgray",
                                                                                                                            position: "absolute",
                                                                                                                            top: "100%",
                                                                                                                            zIndex: 1000,
                                                                                                                            marginTop: "5px",
                                                                                                                            overflowY: "auto",
                                                                                                                        }}>
                                                                                                                            {prodStList.map((ps, idx) => (
                                                                                                                                <div className="selectChildBt ht-30" key={idx}
                                                                                                                                     onClick={() => handleProdStChange('order', ps, order.seqnum, i, index)} style={{
                                                                                                                                    display: "flex", padding: "10px", justifyContent: "center",
                                                                                                                                    alignItems: "center", cursor: "pointer", fontSize: "12px", borderRadius: "4px", zIndex:99,
                                                                                                                                }}>
                                                                                                                                    <p style={{margin:"0", fontSize:"12px"}}>{ps.label}</p>
                                                                                                                                </div>
                                                                                                                            ))}
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        null
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </a>
                                                                                                            { order?.modiTime && <a className="dark-font">{formatDateTime(order.modiTime)}(수정)</a>}
                                                                                                            { order?.orderDate && <a className="dark-font">{formatDateTime(order.orderDate)}(구매)</a>}
                                                                                                            { order?.arrDate && <a className="dark-font">{formatDateTime(order.arrDate)}(도착)</a>}
                                                                                                        </li>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                </tbody>
                                                                                            ))}
                                                                                        </Table>
                                                                                        <div className="cont-dt-bottom">
                                                                <span className="dt-bottom-sp-l">
                                                                    <button className="admin-btn-basic wd-40 ht-25 m-l-7"
                                                                            onClick={() => toggleMenuDTorderFn(i, cData.productsDTO.filter(prod => prod.prodTy === "P01").length)}><TbArrowBarToUp className="up-icon"/>
                                                                    </button>
                                                                </span>
                                                                                            <span className="dt-bottom-sp-r" style={{width:"130px"}}>
                                                                    <div style={{display:"flex", alignItems:"center", width:"100%", height: "25px"}}>
                                                                        <div ref={el => allOrderProdStRefs.current[`${i}`] = el} style={{height: "100%", width: "100%", position: "relative", marginRight:"10px"}}>
                                                                            <button className="selectParentBt" style={{width: "100%", height: "100%"}}
                                                                                    onClick={() => toggleAllOrderProdStShow(i)}>
                                                                                <p style={{margin: "0", fontSize:"13px"}}>{selectedAllOrderProdSt[i]?.label || '전체변경'}</p>
                                                                                <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                                                                    <i style={{
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        height: "100%",
                                                                                        color:"dimgray"
                                                                                    }}><BiCaretDown/></i>
                                                                                </span>
                                                                            </button>
                                                                            {allOrderProdStShow[i] ?
                                                                                <div style={{
                                                                                    width: "100%",
                                                                                    border: "solid",
                                                                                    maxHeight: "250px",
                                                                                    borderWidth: "thin 1px",
                                                                                    borderColor: "lightgray",
                                                                                    position: "absolute",
                                                                                    top: "100%",
                                                                                    zIndex: 1000,
                                                                                    marginTop: "5px",
                                                                                    overflowY: "auto",
                                                                                }}>
                                                                                    {prodStList.map((aps, idx) => (
                                                                                        <div className="selectChildBt ht-30" key={idx}
                                                                                             onClick={() => handleAllProdStChange('order', i, aps)} style={{
                                                                                            display: "flex", padding: "10px", justifyContent: "center",
                                                                                            alignItems: "center", cursor: "pointer", fontSize: "12px", borderRadius: "4px", zIndex:99,
                                                                                        }}>{aps.label}</div>
                                                                                    ))}
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>
                                                                        </div>
                                                                </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="admin-ord-ctg-wrap c-point"
                                                                                         onClick={()=> toggleMenuDTofflineFn(i, cData.productsDTO.filter(prod => prod.prodTy === "P02").length)}>
                                                                                        {isOpenOfflineItemDt[i] ?
                                                                                            <i className="ord-accordion-icon"
                                                                                               style={{transform: "rotate(90deg)"}}/> :
                                                                                            <i className="ord-accordion-icon"></i>
                                                                                        }
                                                                                        <a className="dark-font font-s-14">오프라인</a>
                                                                                        <a style={{color: cData.productsDTO.filter(prod => prod.prodTy === "P02").length === 0 ? "dimgrey" : "darkorange",
                                                                                            fontWeight:"bold", marginLeft:"5px"}}>{cData.productsDTO.filter(prod => prod.prodTy === "P02").length}
                                                                                        </a>
                                                                                    </div>
                                                                                    <div
                                                                                        className={isOpenOfflineItemDt[i] ? "order-dt-close" : "order-dt-open"}>
                                                                                        <Table striped bordered hover
                                                                                               style={{tableLayout: "fixed"}}>
                                                                                            <thead className="tb-head-td-title">
                                                                                            <tr className="tb-head-title-line">
                                                                                                <th className="tb-head-cont-line-l"
                                                                                                    style={{width: "30px"}}>
                                                                                                    <li><input className="admin-input-chkBox-s" type="checkbox"
                                                                                                               onChange={e => handleCheckAll('offline', i, e.target.checked, cData.productsDTO)}
                                                                                                               checked={selectedItems.offline[i] ? selectedItems.offline[i].length === cData.productsDTO.filter(prod => prod.prodTy === "P02").length : false}
                                                                                                    /></li>
                                                                                                </th>
                                                                                                <th style={{width: "40px"}}>
                                                                                                    <li><b>품번</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "90px"}}>
                                                                                                    <li><b>사진</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "180px"}}>
                                                                                                    <li><b>상품명 / URL / 운송장번호</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "340px"}}>
                                                                                                    <li><b>상품메모</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "260px"}}>
                                                                                                    <li><b>상품옵션</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "50px"}}>
                                                                                                    <li><b>수량</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "100px"}}>
                                                                                                    <li><b>구매금액</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "80px"}}>
                                                                                                    <li><b>수수료</b></li>
                                                                                                </th>
                                                                                                <th className="tb-head-cont-line-r"
                                                                                                    style={{width: "150px"}}>
                                                                                                    <li><b>개별현황</b></li>
                                                                                                </th>
                                                                                            </tr>
                                                                                            </thead>
                                                                                            {/*오프라인상품*/}
                                                                                            { cData.productsDTO.filter(prod => prod.prodTy === "P02").sort((a,b) => a.seqnum - b.seqnum).map((offline, index) => (
                                                                                                <tbody key={index} className="tb-head-cont-dt">
                                                                                                <tr className="tb-head-cont-line">
                                                                                                    <td>
                                                                                                        <li><input
                                                                                                            onChange={e => handleCheckItem('offline', i, offline.seqnum, e.target.checked)}
                                                                                                            checked={selectedItems.offline[i] ? selectedItems.offline[i].includes(offline.seqnum) : false}
                                                                                                            className="admin-input-chkBox-s"
                                                                                                            type="checkbox"/></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b">1</a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                            <span className="ord-prod-img">
                                                                <img className="ord-prod-img-file"
                                                                     src={BtorageWriteLogo} alt="admin-logo"/>
                                                            </span>
                                                                                                        </li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b"
                                                                                                               href="https://www.naver.com"
                                                                                                               target="_blank">
                                                                                                            {offline.name}
                                                                                                        </a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                            <textarea
                                                                                className="admin-input-line prod-textarea-memo"
                                                                                cols="52"
                                                                                rows="4"
                                                                                value={adminProdMemos[offline.seqnum] || ''} // 초기값 설정
                                                                                onChange={(e) => handleProdMemoChange(offline.seqnum, e.target.value)}
                                                                                onKeyDown={(e) => saveProdMemo(e, offline.seqnum)}
                                                                            ></textarea>
                                                                                                        </li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                                                            <a className="dark-font"
                                                                                                               style={{fontSize: "11px"}}>
                                                                                                                {offline.option}
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b">{offline.quantity}</a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b">{offline.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b">{offline.fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>

                                                                                                            <a>{prodStPrint(offline?.prodSt)}</a>

                                                                                                            <a style={{width:"100%"}}>
                                                                                                                {/*여기서부터 커스텀 상품상태 셀렉트*/}
                                                                                                                <div ref={el => offlineProdStRef.current[`${i}-${index}`] = el} style={{height: "30px", width: "100%", position: "relative", padding:"3px"}}>
                                                                                                                    <button className="selectParentBt" style={{width: "100%", height: "100%", backgroundColor:"white", border:"none"}}
                                                                                                                            onClick={() => toggleOfflineProdStShow(i, index)}>
                                                                                                                        <p style={{margin: "0", fontSize:"12px", fontWeight:"lighter"}}>{selectedProdSt[`${'offline'}-${i}-${index}`]?.label || '개별변경'}</p>
                                                                                                                        <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>

                                                                    <i style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "100%",
                                                                        color:"dimgray"
                                                                    }}><BiCaretDown/></i>

                                                                </span>
                                                                                                                    </button>
                                                                                                                    {offlineProdStShow[`${i}-${index}`] ?
                                                                                                                        <div style={{
                                                                                                                            width: "100%",
                                                                                                                            border: "solid",
                                                                                                                            maxHeight: "250px",
                                                                                                                            borderWidth: "thin 1px",
                                                                                                                            borderColor: "lightgray",
                                                                                                                            position: "absolute",
                                                                                                                            top: "100%",
                                                                                                                            zIndex: 1000,
                                                                                                                            marginTop: "5px",
                                                                                                                            overflowY: "auto",
                                                                                                                        }}>
                                                                                                                            {prodStList.map((ps, idx) => (
                                                                                                                                <div className="selectChildBt ht-30" key={idx}
                                                                                                                                     onClick={() => handleProdStChange('offline', ps, offline.seqnum, i, index)} style={{
                                                                                                                                    display: "flex", padding: "10px", justifyContent: "center",
                                                                                                                                    alignItems: "center", cursor: "pointer", fontSize: "12px", borderRadius: "4px", zIndex:99,
                                                                                                                                }}>
                                                                                                                                    <p style={{margin:"0", fontSize:"12px"}}>{ps.label}</p>
                                                                                                                                </div>
                                                                                                                            ))}
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        null
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </a>
                                                                                                            { offline?.modiTime && <a className="dark-font">{formatDateTime(offline.modiTime)}(수정)</a>}
                                                                                                            { offline?.orderDate && <a className="dark-font">{formatDateTime(offline.orderDate)}(구매)</a>}
                                                                                                            { offline?.arrDate && <a className="dark-font">{formatDateTime(offline.arrDate)}(도착)</a>}
                                                                                                        </li>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                </tbody>
                                                                                            ))}
                                                                                        </Table>
                                                                                        <div className="cont-dt-bottom">
                                                                <span className="dt-bottom-sp-l">
                                                                    <button className="admin-btn-basic wd-40 ht-25 m-l-7" onClick={()=> toggleMenuDTofflineFn(i, cData.productsDTO.filter(prod => prod.prodTy === "P02").length)}>
                                                                        <TbArrowBarToUp className="up-icon"/>
                                                                    </button>
                                                                </span>
                                                                                            <span className="dt-bottom-sp-r" style={{width:"130px"}}>
                                                                    <div style={{display:"flex", alignItems:"center", width:"100%", height:"100%"}}>
                                                                        <div ref={el => allOfflineProdStRefs.current[`${i}`] = el} style={{height: "100%", width: "100%", position: "relative", marginRight:"10px"}}>
                                                                        <button className="selectParentBt" style={{width: "100%", height: "100%"}}
                                                                                onClick={() => toggleAllOfflineProdStShow(i)}>
                                                                            <p style={{margin: "0", fontSize:"13px"}}>{selectedAllOfflineProdSt[i]?.label || '전체변경'}</p>
                                                                            <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                                                                <i style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    height: "100%",
                                                                                    color:"dimgray"
                                                                                }}><BiCaretDown/></i>
                                                                            </span>
                                                                        </button>
                                                                            {allOfflineProdStShow[i] ?
                                                                                <div style={{
                                                                                    width: "100%",
                                                                                    border: "solid",
                                                                                    maxHeight: "250px",
                                                                                    borderWidth: "thin 1px",
                                                                                    borderColor: "lightgray",
                                                                                    position: "absolute",
                                                                                    top: "100%",
                                                                                    zIndex: 1000,
                                                                                    marginTop: "5px",
                                                                                    overflowY: "auto",
                                                                                }}>
                                                                                    {prodStList.map((aps, idx) => (
                                                                                        <div className="selectChildBt ht-30" key={idx}
                                                                                             onClick={() => handleAllProdStChange('offline', i, aps)} style={{
                                                                                            display: "flex", padding: "10px", justifyContent: "center",
                                                                                            alignItems: "center", cursor: "pointer", fontSize: "12px", borderRadius: "4px", zIndex:99,
                                                                                        }}>{aps.label}</div>
                                                                                    ))}
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>
                                                                        {/*여기까지 커스텀 전체상태변경 셀렉트*/}
                                                                        </div>
                                                                </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="admin-ord-ctg-wrap c-point"
                                                                                         onClick={()=> toggleMenuDTtransferFn(i, cData.productsDTO.filter(prod => prod.prodTy === "P03").length)}>
                                                                                        {isOpenTransferItemDt[i] ?
                                                                                            <i className="ord-accordion-icon"
                                                                                               style={{transform: "rotate(90deg)"}}/> :
                                                                                            <i className="ord-accordion-icon"></i>
                                                                                        }
                                                                                        <a className="dark-font font-s-14">이체대행</a>
                                                                                        <a style={{color: cData.productsDTO.filter(prod => prod.prodTy === "P03").length === 0 ? "dimgrey" : "darkorange",
                                                                                            fontWeight:"bold", marginLeft:"5px"}}>{cData.productsDTO.filter(prod => prod.prodTy === "P03").length}
                                                                                        </a>
                                                                                    </div>
                                                                                    <div
                                                                                        className={isOpenTransferItemDt[i] ? "order-dt-close" : "order-dt-open"}>
                                                                                        <Table striped bordered hover
                                                                                               style={{tableLayout: "fixed"}}>
                                                                                            <thead className="tb-head-td-title">
                                                                                            <tr className="tb-head-title-line">
                                                                                                <th className="tb-head-cont-line-l"
                                                                                                    style={{width: "30px"}}>
                                                                                                    <li><input className="admin-input-chkBox-s" type="checkbox"
                                                                                                               onChange={e => handleCheckAll('transfer', i, e.target.checked, cData.productsDTO)}
                                                                                                               checked={selectedItems.transfer[i] ? selectedItems.transfer[i].length === cData.productsDTO.filter(prod => prod.prodTy === "P03").length : false}
                                                                                                    /></li>
                                                                                                </th>
                                                                                                <th style={{width: "40px"}}>
                                                                                                    <li><b>품번</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "90px"}}>
                                                                                                    <li><b>사진</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "180px"}}>
                                                                                                    <li><b>상품명 / URL / 운송장번호</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "340px"}}>
                                                                                                    <li><b>상품메모</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "260px"}}>
                                                                                                    <li><b>상품옵션</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "50px"}}>
                                                                                                    <li><b>수량</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "100px"}}>
                                                                                                    <li><b>이체금액</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "80px"}}>
                                                                                                    <li><b>수수료</b></li>
                                                                                                </th>
                                                                                                <th className="tb-head-cont-line-r"
                                                                                                    style={{width: "150px"}}>
                                                                                                    <li><b>개별현황</b></li>
                                                                                                </th>
                                                                                            </tr>
                                                                                            </thead>

                                                                                            {/*이체대행상품*/}
                                                                                            { cData.productsDTO.filter(prod => prod.prodTy === "P03").sort((a,b) => a.seqnum - b.seqnum).map((transfer, index) => (
                                                                                                <tbody key={index} className="tb-head-cont-dt">
                                                                                                <tr className="tb-head-cont-line">
                                                                                                    <td>
                                                                                                        <li><input
                                                                                                            onChange={e => handleCheckItem('transfer', i, transfer.seqnum, e.target.checked)}
                                                                                                            checked={selectedItems.transfer[i] ? selectedItems.transfer[i].includes(transfer.seqnum) : false}
                                                                                                            className="admin-input-chkBox-s"
                                                                                                            type="checkbox"/></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b">1</a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                            <span className="ord-prod-img">
                                                                                <img className="ord-prod-img-file"
                                                                                     src={BtorageWriteLogo} alt="admin-logo"/>
                                                                            </span>
                                                                                                        </li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b"
                                                                                                               href="https://www.naver.com"
                                                                                                               target="_blank">
                                                                                                            {transfer.name}
                                                                                                        </a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                            <textarea
                                                                                className="admin-input-line prod-textarea-memo"
                                                                                cols="52"
                                                                                rows="4"
                                                                                value={adminProdMemos[transfer.seqnum] || ''} // 초기값 설정
                                                                                onChange={(e) => handleProdMemoChange(transfer.seqnum, e.target.value)}
                                                                                onKeyDown={(e) => saveProdMemo(e, transfer.seqnum)}
                                                                            ></textarea>

                                                                                                        </li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                                                            <a className="dark-font"
                                                                                                               style={{fontSize: "11px"}}>

                                                                                                            </a>
                                                                                                        </li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b"></a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b">{transfer.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b">{transfer.fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                                                            <a>{prodStPrint(transfer?.prodSt)}</a>
                                                                                                            <a style={{width:"100%"}}>
                                                                                                                {/*여기서부터 커스텀 상품상태 셀렉트*/}
                                                                                                                <div ref={el => transferProdStRef.current[`${i}-${index}`] = el} style={{height: "30px", width: "100%", position: "relative", padding:"3px"}}>
                                                                                                                    <button className="selectParentBt" style={{width: "100%", height: "100%", backgroundColor:"white", border:"none"}}
                                                                                                                            onClick={() => toggleTransferProdStShow(i, index)}>
                                                                                                                        <p style={{margin: "0", fontSize:"12px", fontWeight:"lighter"}}>{selectedProdSt[`${'transfer'}-${i}-${index}`]?.label || '개별변경'}</p>
                                                                                                                        <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                                                                                                            <i style={{
                                                                                                                                display: "flex",
                                                                                                                                justifyContent: "center",
                                                                                                                                alignItems: "center",
                                                                                                                                height: "100%",
                                                                                                                                color:"dimgray"
                                                                                                                            }}><BiCaretDown/></i>
                                                                                                                        </span>
                                                                                                                    </button>
                                                                                                                    {transferProdStShow[`${i}-${index}`] ?
                                                                                                                        <div style={{
                                                                                                                            width: "100%",
                                                                                                                            border: "solid",
                                                                                                                            maxHeight: "250px",
                                                                                                                            borderWidth: "thin 1px",
                                                                                                                            borderColor: "lightgray",
                                                                                                                            position: "absolute",
                                                                                                                            top: "100%",
                                                                                                                            zIndex: 1000,
                                                                                                                            marginTop: "5px",
                                                                                                                            overflowY: "auto",
                                                                                                                        }}>
                                                                                                                            {prodStList.map((ps, idx) => (
                                                                                                                                <div className="selectChildBt ht-30" key={idx}
                                                                                                                                     onClick={() => handleProdStChange('transfer', ps, transfer.seqnum, i, index)} style={{
                                                                                                                                    display: "flex", padding: "10px", justifyContent: "center",
                                                                                                                                    alignItems: "center", cursor: "pointer", fontSize: "12px", borderRadius: "4px", zIndex:99,
                                                                                                                                }}>
                                                                                                                                    <p style={{margin:"0", fontSize:"12px"}}>{ps.label}</p>
                                                                                                                                </div>
                                                                                                                            ))}
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        null
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </a>
                                                                                                            { transfer?.modiTime && <a className="dark-font">{formatDateTime(transfer.modiTime)}(수정)</a>}
                                                                                                            { transfer?.orderDate && <a className="dark-font">{formatDateTime(transfer.orderDate)}(구매)</a>}
                                                                                                            { transfer?.arrDate && <a className="dark-font">{formatDateTime(transfer.arrDate)}(도착)</a>}
                                                                                                        </li>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                </tbody>
                                                                                            ))}
                                                                                        </Table>
                                                                                        <div className="cont-dt-bottom">
                                                                                                <span className="dt-bottom-sp-l">
                                                                                                    <button className="admin-btn-basic wd-40 ht-25 m-l-7" onClick={()=> toggleMenuDTtransferFn(i, cData.productsDTO.filter(prod => prod.prodTy === "P03").length)}>
                                                                                                        <TbArrowBarToUp className="up-icon"/>
                                                                                                    </button>
                                                                                                </span>
                                                                                            <span className="dt-bottom-sp-r" style={{width:"130px"}}>
                                                                    <div style={{display:"flex", alignItems:"center", width:"100%", height:"100%"}}>
                                                                    {/*여기서부터 커스텀 전체상태변경 셀렉트*/}
                                                                        <div ref={el => allTransferProdStRefs.current[`${i}`] = el} style={{height: "100%", width: "100%", position: "relative", marginRight:"10px"}}>
                                                                            <button className="selectParentBt" style={{width: "100%", height: "100%"}}
                                                                                    onClick={() => toggleAllTransferProdStShow(i)}>
                                                                                <p style={{margin: "0", fontSize:"13px"}}>{selectedAllTransferProdSt[i]?.label || '전체변경'}</p>
                                                                                <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                                                                    <i style={{
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        height: "100%",
                                                                                        color:"dimgray"
                                                                                    }}><BiCaretDown/></i>
                                                                                </span>
                                                                            </button>
                                                                            {allTransferProdStShow[i] ?
                                                                                <div style={{
                                                                                    width: "100%",
                                                                                    border: "solid",
                                                                                    maxHeight: "250px",
                                                                                    borderWidth: "thin 1px",
                                                                                    borderColor: "lightgray",
                                                                                    position: "absolute",
                                                                                    top: "100%",
                                                                                    zIndex: 1000,
                                                                                    marginTop: "5px",
                                                                                    overflowY: "auto",
                                                                                }}>
                                                                                    {prodStList.map((aps, idx) => (
                                                                                        <div className="selectChildBt ht-30" key={idx}
                                                                                             onClick={() => handleAllProdStChange('transfer', i, aps)} style={{
                                                                                            display: "flex", padding: "10px", justifyContent: "center",
                                                                                            alignItems: "center", cursor: "pointer", fontSize: "12px", borderRadius: "4px", zIndex:99,
                                                                                        }}>{aps.label}</div>
                                                                                    ))}
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>
                                                                        {/*여기까지 커스텀 전체상태변경 셀렉트*/}
                                                                        </div>
                                                                </span>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="admin-ord-ctg-wrap c-point"
                                                                                         onClick={()=> toggleMenuDTdomesticFn(i, cData.productsDTO.filter(prod => prod.prodTy === "P04").length)}>
                                                                                        {isOpenCartItemDt[i] ?
                                                                                            <i className="ord-accordion-icon"
                                                                                               style={{transform: "rotate(90deg)"}}/> :
                                                                                            <i className="ord-accordion-icon"></i>
                                                                                        }
                                                                                        <a className="dark-font font-s-14">국내배송</a>
                                                                                        <a style={{color: cData.productsDTO.filter(prod => prod.prodTy === "P04").length === 0 ? "dimgrey" : "darkorange",
                                                                                            fontWeight:"bold", marginLeft:"5px"}}>{cData.productsDTO.filter(prod => prod.prodTy === "P04").length}
                                                                                        </a>
                                                                                    </div>
                                                                                    <div
                                                                                        className={isOpenDomesticItemDt[i] ? "order-dt-close" : "order-dt-open"}>
                                                                                        <Table striped bordered hover
                                                                                               style={{tableLayout: "fixed"}}>
                                                                                            <thead className="tb-head-td-title">
                                                                                            <tr className="tb-head-title-line">
                                                                                                <th className="tb-head-cont-line-l"
                                                                                                    style={{width: "30px"}}>
                                                                                                    <li><input className="admin-input-chkBox-s" type="checkbox"
                                                                                                               onChange={e => handleCheckAll('domestic', i, e.target.checked, cData.productsDTO)}
                                                                                                               checked={selectedItems.domestic[i] ? selectedItems.domestic[i].length === cData.productsDTO.filter(prod => prod.prodTy === "P04").length : false}
                                                                                                    /></li>
                                                                                                </th>
                                                                                                <th style={{width: "40px"}}>
                                                                                                    <li><b>품번</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "90px"}}>
                                                                                                    <li><b>사진</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "180px"}}>
                                                                                                    <li><b>상품명 / URL / 운송장번호</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "340px"}}>
                                                                                                    <li><b>상품메모</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "260px"}}>
                                                                                                    <li><b>상품옵션</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "50px"}}>
                                                                                                    <li><b>수량</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "100px"}}>
                                                                                                    <li><b>구매금액</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "80px"}}>
                                                                                                    <li><b>쇼핑몰배송비/수수료</b></li>
                                                                                                </th>
                                                                                                <th className="tb-head-cont-line-r"
                                                                                                    style={{width: "150px"}}>
                                                                                                    <li><b>개별현황</b></li>
                                                                                                </th>
                                                                                            </tr>
                                                                                            </thead>
                                                                                            {/*국내배송상품*/}
                                                                                            { cData.productsDTO.filter(prod => prod.prodTy === "P04").sort((a,b) => a.seqnum - b.seqnum).map((domestic, index) => (
                                                                                                <tbody key={index} className="tb-head-cont-dt">
                                                                                                <tr className="tb-head-cont-line">
                                                                                                    <td>
                                                                                                        <li><input
                                                                                                            onChange={e => handleCheckItem('domestic', i, domestic.seqnum, e.target.checked)}
                                                                                                            checked={selectedItems.domestic[i] ? selectedItems.domestic[i].includes(domestic.seqnum) : false}
                                                                                                            className="admin-input-chkBox-s"
                                                                                                            type="checkbox"/></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b">1</a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                                                            <span className="ord-prod-img">
                                                                                                                <img className="ord-prod-img-file"
                                                                                                                     src={BtorageWriteLogo} alt="admin-logo"/>
                                                                                                            </span>
                                                                                                        </li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a
                                                                                                            className="font-b"
                                                                                                            href={domestic.url}
                                                                                                            target="_blank">
                                                                                                            {domestic.name}
                                                                                                        </a></li>
                                                                                                        <li style={{width: "180px"}}>
                                                                                                            <a className="font-b"
                                                                                                               href={domestic.url}
                                                                                                               target="_blank"
                                                                                                               style={{
                                                                                                                   width: "95%",
                                                                                                                   overflow: "hidden",
                                                                                                                   textOverflow: "ellipsis",
                                                                                                                   whiteSpace: "nowrap",
                                                                                                                   wordBreak: "break-all",
                                                                                                                   color:"#98B0D4"
                                                                                                               }}>{domestic.url}</a>
                                                                                                        </li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                                                            <textarea
                                                                                                                className="admin-input-line prod-textarea-memo"
                                                                                                                cols="52"
                                                                                                                rows="4"
                                                                                                                value={adminProdMemos[domestic.seqnum] || ''} // 초기값 설정
                                                                                                                onChange={(e) => handleProdMemoChange(domestic.seqnum, e.target.value)}
                                                                                                                onKeyDown={(e) => saveProdMemo(e, domestic.seqnum)}
                                                                                                            ></textarea>
                                                                                                        </li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                                                            <a className="dark-font"
                                                                                                               style={{fontSize: "11px"}}>
                                                                                                                {domestic.option}
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b">{domestic.quantity}</a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b">{domestic.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b">{domestic?.mallShipPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</a></li>
                                                                                                        <li><a className="font-b">{domestic.fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                                                            <a>{prodStPrint(domestic?.prodSt)}</a>
                                                                                                            <a style={{width:"100%"}}>
                                                                                                                {/*여기서부터 커스텀 상품상태 셀렉트*/}
                                                                                                                <div ref={el => domesticProdStRef.current[`${i}-${index}`] = el} style={{height: "30px", width: "100%", position: "relative", padding:"3px"}}>
                                                                                                                    <button className="selectParentBt" style={{width: "100%", height: "100%", backgroundColor:"white", border:"none"}}
                                                                                                                            onClick={() => toggleDomesticProdStShow(i, index)}>
                                                                                                                        <p style={{margin: "0", fontSize:"12px", fontWeight:"lighter"}}>{selectedProdSt[`${'domestic'}-${i}-${index}`]?.label || '개별변경'}</p>
                                                                                                                        <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                                                                                                                    <i style={{
                                                                                                                                        display: "flex",
                                                                                                                                        justifyContent: "center",
                                                                                                                                        alignItems: "center",
                                                                                                                                        height: "100%",
                                                                                                                                        color:"dimgray"
                                                                                                                                    }}><BiCaretDown/></i>

                                                                                                                                </span>
                                                                                                                    </button>
                                                                                                                    {domesticProdStShow[`${i}-${index}`] ?
                                                                                                                        <div style={{
                                                                                                                            width: "100%",
                                                                                                                            border: "solid",
                                                                                                                            maxHeight: "250px",
                                                                                                                            borderWidth: "thin 1px",
                                                                                                                            borderColor: "lightgray",
                                                                                                                            position: "absolute",
                                                                                                                            top: "100%",
                                                                                                                            zIndex: 1000,
                                                                                                                            marginTop: "5px",
                                                                                                                            overflowY: "auto",
                                                                                                                        }}>
                                                                                                                            {prodStList.map((ps, idx) => (
                                                                                                                                <div className="selectChildBt ht-30" key={idx}
                                                                                                                                     onClick={() => handleProdStChange('domestic', ps, domestic.seqnum, i, index)} style={{
                                                                                                                                    display: "flex", padding: "10px", justifyContent: "center",
                                                                                                                                    alignItems: "center", cursor: "pointer", fontSize: "12px", borderRadius: "4px", zIndex:99,
                                                                                                                                }}>
                                                                                                                                    <p style={{margin:"0", fontSize:"12px"}}>{ps.label}</p>
                                                                                                                                </div>
                                                                                                                            ))}
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        null
                                                                                                                    }
                                                                                                                </div>
                                                                                                                {/*여기까지 커스텀 상품상태 셀렉트*/}
                                                                                                            </a>
                                                                                                            { domestic?.modiTime && <a className="dark-font">{formatDateTime(domestic.modiTime)}(수정)</a>}
                                                                                                            { domestic?.orderDate && <a className="dark-font">{formatDateTime(domestic.orderDate)}(구매)</a>}
                                                                                                            { domestic?.arrDate && <a className="dark-font">{formatDateTime(domestic.arrDate)}(도착)</a>}
                                                                                                        </li>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                </tbody>
                                                                                            ))}
                                                                                        </Table>
                                                                                        <div className="cont-dt-bottom">
                                                                                            <span className="dt-bottom-sp-l">
                                                                                                <button className="admin-btn-basic wd-40 ht-25 m-l-7"
                                                                                                        onClick={()=> toggleMenuDTdomesticFn(i, cData.productsDTO.filter(prod => prod.prodTy === "P04").length)}>
                                                                                                    <TbArrowBarToUp className="up-icon"/>
                                                                                                </button>
                                                                                            </span>
                                                                                            <span className="dt-bottom-sp-r" style={{width:"130px"}}>
                                                                    <div style={{display:"flex", alignItems:"center", width:"100%", height:"100%"}}>
                                                                    {/*여기서부터 커스텀 전체상태변경 셀렉트*/}
                                                                        <div ref={el => allDomesticProdStRefs.current[`${i}`] = el} style={{height: "100%", width: "100%", position: "relative", marginRight:"10px"}}>
                                                                            <button className="selectParentBt" style={{width: "100%", height: "100%"}}
                                                                                    onClick={() => toggleAllDomesticProdStShow(i)}>
                                                                                <p style={{margin: "0", fontSize:"13px"}}>{selectedAllDomesticProdSt[i]?.label || '전체변경'}</p>
                                                                                <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                                                                    <i style={{
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        height: "100%",
                                                                                        color:"dimgray"
                                                                                    }}><BiCaretDown/></i>
                                                                                </span>
                                                                            </button>
                                                                            {allDomesticProdStShow[i] ?
                                                                                <div style={{
                                                                                    width: "100%",
                                                                                    border: "solid",
                                                                                    maxHeight: "250px",
                                                                                    borderWidth: "thin 1px",
                                                                                    borderColor: "lightgray",
                                                                                    position: "absolute",
                                                                                    top: "100%",
                                                                                    zIndex: 1000,
                                                                                    marginTop: "5px",
                                                                                    overflowY: "auto",
                                                                                }}>
                                                                                    {prodStList.map((aps, idx) => (
                                                                                        <div className="selectChildBt ht-30" key={idx}
                                                                                             onClick={() => handleAllProdStChange('domestic', i, aps)} style={{
                                                                                            display: "flex", padding: "10px", justifyContent: "center",
                                                                                            alignItems: "center", cursor: "pointer", fontSize: "12px", borderRadius: "4px", zIndex:99,
                                                                                        }}>{aps.label}</div>
                                                                                    ))}
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>
                                                                        {/*여기까지 커스텀 전체상태변경 셀렉트*/}
                                                                        </div>
                                                                </span>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="admin-ord-ctg-wrap c-point"
                                                                                         onClick={()=> toggleMenuDTdeliveryFn(i, cData.productsDTO.filter(prod => prod.prodTy === "P05").length)}>
                                                                                        {isOpenDeliveryItemDt[i] ?
                                                                                            <i className="ord-accordion-icon"
                                                                                               style={{transform: "rotate(90deg)"}}/> :
                                                                                            <i className="ord-accordion-icon"></i>
                                                                                        }
                                                                                        <a className="dark-font font-s-14">배송대행</a>
                                                                                        <a style={{color: cData.productsDTO.filter(prod => prod.prodTy === "P05").length === 0 ? "dimgrey" : "darkorange",
                                                                                            fontWeight:"bold", marginLeft:"5px"}}>{cData.productsDTO.filter(prod => prod.prodTy === "P05").length}
                                                                                        </a>
                                                                                    </div>
                                                                                    <div
                                                                                        className={isOpenDeliveryItemDt[i] ? "order-dt-close" : "order-dt-open"}>
                                                                                        <Table striped bordered hover
                                                                                               style={{tableLayout: "fixed"}}>
                                                                                            <thead className="tb-head-td-title">
                                                                                            <tr className="tb-head-title-line">
                                                                                                <th className="tb-head-cont-line-l"
                                                                                                    style={{width: "30px"}}>
                                                                                                    <li><input className="admin-input-chkBox-s" type="checkbox"
                                                                                                               onChange={e => handleCheckAll('delivery', i, e.target.checked, cData.productsDTO)}
                                                                                                               checked={selectedItems.delivery[i] ? selectedItems.delivery[i].length === cData.productsDTO.filter(prod => prod.prodTy === "P05").length : false}
                                                                                                    /></li>
                                                                                                </th>
                                                                                                <th style={{width: "40px"}}>
                                                                                                    <li><b>품번</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "90px"}}>
                                                                                                    <li><b>사진</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "180px"}}>
                                                                                                    <li><b>TR / 물품명</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "340px"}}>
                                                                                                    <li><b>상품메모</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "260px"}}>
                                                                                                    <li><b>상품옵션</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "50px"}}>
                                                                                                    <li><b>수량</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "100px"}}>
                                                                                                    <li><b>구매금액</b></li>
                                                                                                </th>
                                                                                                <th style={{width: "80px"}}>
                                                                                                    <li><b></b></li>
                                                                                                </th>
                                                                                                <th className="tb-head-cont-line-r"
                                                                                                    style={{width: "150px"}}>
                                                                                                    <li><b>개별현황</b></li>
                                                                                                </th>
                                                                                            </tr>
                                                                                            </thead>
                                                                                            {/*배송대행상품*/}
                                                                                            { cData.productsDTO.filter(prod => prod.prodTy === "P05").sort((a,b) => a.seqnum - b.seqnum).map((delivery, index) => (
                                                                                                <tbody key={index} className="tb-head-cont-dt">
                                                                                                <tr className="tb-head-cont-line">
                                                                                                    <td>
                                                                                                        <li><input
                                                                                                            onChange={e => handleCheckItem('delivery', i, delivery.seqnum, e.target.checked)}
                                                                                                            checked={selectedItems.delivery[i] ? selectedItems.delivery[i].includes(delivery.seqnum) : false}
                                                                                                            className="admin-input-chkBox-s"
                                                                                                            type="checkbox"/></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b">1</a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                                                            <span className="ord-prod-img">
                                                                                                                <img className="ord-prod-img-file"
                                                                                                                     src={BtorageWriteLogo} alt="admin-logo"/>
                                                                                                            </span>
                                                                                                        </li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                                                            <a>{delivery.domShipco}</a>
                                                                                                        </li>
                                                                                                        <li style={{display: "flex", justifyContent: "center", marginTop: "5px", marginBottom: "5px", flexDirection: "row"}}>
                                                                                                            <a onClick={() => dbSelectedDeliveryTracking(delivery.domShipco, delivery.inWaynum)}
                                                                                                               className="m-r-3 font-b c-point font-c-progress text-underline">{delivery?.inWaynum || ""}
                                                                                                            </a>
                                                                                                            {!copiedIndexes.includes(index) && (
                                                                                                                <RxCopy className="m-l-3 font-s-18 c-point btorage-hover btn-click-event" style={{opacity: "0.65"}}
                                                                                                                        onClick={() => handleCopy(delivery.inWaynum.trim(), index)}/>
                                                                                                            )}
                                                                                                            {copiedIndexes.includes(index) && <RxCheck className="m-l-3 font-s-18 font-c-btorage" style={{opacity: "0.65"}} />}
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <a className="font-b">{delivery.name}</a>
                                                                                                        </li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                                                            <textarea
                                                                                                                className="admin-input-line prod-textarea-memo"
                                                                                                                cols="52"
                                                                                                                rows="4"
                                                                                                                value={adminProdMemos[delivery.seqnum] || ''} // 초기값 설정
                                                                                                                onChange={(e) => handleProdMemoChange(delivery.seqnum, e.target.value)}
                                                                                                                onKeyDown={(e) => saveProdMemo(e, delivery.seqnum)}
                                                                                                            ></textarea>
                                                                                                        </li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                                                            <a className="dark-font"
                                                                                                               style={{fontSize: "11px"}}>
                                                                                                                {delivery.option}
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b">{delivery.quantity}</a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b"></a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li><a className="font-b"></a></li>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <li>
                                                                                                            <a>{prodStPrint(delivery?.prodSt)}</a>
                                                                                                            <a style={{width:"100%"}}>
                                                                                                                {/*여기서부터 커스텀 상품상태 셀렉트*/}
                                                                                                                <div ref={el => deliveryProdStRef.current[`${i}-${index}`] = el} style={{height: "30px", width: "100%", position: "relative", padding:"3px"}}>
                                                                                                                    <button className="selectParentBt" style={{width: "100%", height: "100%", backgroundColor:"white", border:"none"}}
                                                                                                                            onClick={() => toggleDeliveryProdStShow(i, index)}>
                                                                                                                        <p style={{margin: "0", fontSize:"12px", fontWeight:"lighter"}}>{selectedProdSt[`${'delivery'}-${i}-${index}`]?.label || '개별변경'}</p>
                                                                                                                        <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                                                                                                            <i style={{
                                                                                                                                display: "flex",
                                                                                                                                justifyContent: "center",
                                                                                                                                alignItems: "center",
                                                                                                                                height: "100%",
                                                                                                                                color:"dimgray"
                                                                                                                            }}><BiCaretDown/></i>
                                                                                                                        </span>
                                                                                                                    </button>
                                                                                                                    {deliveryProdStShow[`${i}-${index}`] ?
                                                                                                                        <div style={{
                                                                                                                            width: "100%",
                                                                                                                            border: "solid",
                                                                                                                            maxHeight: "250px",
                                                                                                                            borderWidth: "thin 1px",
                                                                                                                            borderColor: "lightgray",
                                                                                                                            position: "absolute",
                                                                                                                            top: "100%",
                                                                                                                            zIndex: 1000,
                                                                                                                            marginTop: "5px",
                                                                                                                            overflowY: "auto",
                                                                                                                        }}>
                                                                                                                            {prodStList.map((ps, idx) => (
                                                                                                                                <div className="selectChildBt ht-30" key={idx}
                                                                                                                                     onClick={() => handleProdStChange('delivery', ps, delivery.seqnum, i, index)} style={{
                                                                                                                                    display: "flex", padding: "10px", justifyContent: "center",
                                                                                                                                    alignItems: "center", cursor: "pointer", fontSize: "12px", borderRadius: "4px", zIndex:99,
                                                                                                                                }}>
                                                                                                                                    <p style={{margin:"0", fontSize:"12px"}}>{ps.label}</p>
                                                                                                                                </div>
                                                                                                                            ))}
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        null
                                                                                                                    }
                                                                                                                </div>
                                                                                                                {/*여기까지 커스텀 상품상태 셀렉트*/}
                                                                                                            </a>
                                                                                                            { delivery?.modiTime && <a className="dark-font">{formatDateTime(delivery.modiTime)}(수정)</a>}
                                                                                                            { delivery?.arrDate && <a className="dark-font">{formatDateTime(delivery.arrDate)}(도착)</a>}
                                                                                                        </li>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                </tbody>
                                                                                            ))}
                                                                                        </Table>
                                                                                        <div className="cont-dt-bottom">
                                                                                            <span className="dt-bottom-sp-l">
                                                                                                <button className="admin-btn-basic wd-40 ht-25 m-l-7"
                                                                                                        onClick={()=> toggleMenuDTdeliveryFn(i, cData.productsDTO.filter(prod => prod.prodTy === "P05").length)}>
                                                                                                    <TbArrowBarToUp className="up-icon"/>
                                                                                                </button>
                                                                                            </span>
                                                                                            <span className="dt-bottom-sp-r" style={{width:"130px"}}>
                                                                                                <div style={{display:"flex", alignItems:"center", width:"100%", height:"100%"}}>
                                                                                                {/*여기서부터 커스텀 전체상태변경 셀렉트*/}
                                                                                                    <div ref={el => allDeliveryProdStRefs.current[`${i}`] = el} style={{height: "100%", width: "100%", position: "relative", marginRight:"10px"}}>
                                                                                                        <button className="selectParentBt" style={{width: "100%", height: "100%"}}
                                                                                                                onClick={() => toggleAllDeliveryProdStShow(i)}>
                                                                                                            <p style={{margin: "0", fontSize:"13px"}}>{selectedAllDeliveryProdSt[i]?.label || '전체변경'}</p>
                                                                                                            <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                                                                                                <i style={{
                                                                                                                    display: "flex",
                                                                                                                    justifyContent: "center",
                                                                                                                    alignItems: "center",
                                                                                                                    height: "100%",
                                                                                                                    color:"dimgray"
                                                                                                                }}><BiCaretDown/></i>
                                                                                                            </span>
                                                                                                        </button>
                                                                                                        {allDeliveryProdStShow[i] ?
                                                                                                            <div style={{
                                                                                                                width: "100%",
                                                                                                                border: "solid",
                                                                                                                maxHeight: "250px",
                                                                                                                borderWidth: "thin 1px",
                                                                                                                borderColor: "lightgray",
                                                                                                                position: "absolute",
                                                                                                                top: "100%",
                                                                                                                zIndex: 1000,
                                                                                                                marginTop: "5px",
                                                                                                                overflowY: "auto",
                                                                                                            }}>
                                                                                                                {prodStList.map((aps, idx) => (
                                                                                                                    <div className="selectChildBt ht-30" key={idx}
                                                                                                                         onClick={() => handleAllProdStChange('delivery', i, aps)} style={{
                                                                                                                        display: "flex", padding: "10px", justifyContent: "center",
                                                                                                                        alignItems: "center", cursor: "pointer", fontSize: "12px", borderRadius: "4px", zIndex:99,
                                                                                                                    }}>{aps.label}</div>
                                                                                                                ))}
                                                                                                            </div>
                                                                                                            :
                                                                                                            null
                                                                                                        }
                                                                                                    </div>
                                                                                                    {/*여기까지 커스텀 전체상태변경 셀렉트*/}
                                                                                                    </div>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                                </tbody>
                                                            ) :
                                                            // payinfo map
                                                            cData.type === 'payinfo' && (cData.payType === "PT1") ? (
                                                                    <tbody className="ord-main-tb-body" style={{zIndex:1}} key={key}>
                                                                    <tr className="ord-main-tb-body-line">
                                                                        <td>
                                                                            <li><input className="admin-input-chkBox-s" type="checkbox" name={cData.seqNum}
                                                                                       checked={checked.includes(cData.seqNum)}
                                                                                       onChange={() => checkBoxChange(cData.seqNum, 'payinfo')}/></li>
                                                                        </td>
                                                                        <td>
                                                                            <li style={{alignItems: "center"}}>
                                                                                <a className="font-b">{cData.orderdtDTO.orderNumber}-{cData.shipInfoDTO.boxNum}</a>
                                                                                {/*<a>A1234</a>*/} {/*랙&위치번호*/}
                                                                                <a>
                                                                                    <button className="admin-btn-basic wd-40 m-t-3"
                                                                                            onClick={() => deliveryDetailTrueFn(cData.seqNum)}>상세</button>
                                                                                </a>
                                                                            </li>
                                                                        </td>
                                                                        <td>
                                                                            <li><a className="font-b">배송료</a>
                                                                            </li>
                                                                        </td>
                                                                        <td>
                                                                            <li>
                                                                                <a>BT{cData.orderdtDTO.memberDTO.mbNum}</a>
                                                                                <a className="font-b font-c-white">{cData.orderdtDTO.memberDTO.userName}</a>
                                                                                <a style={{textAlign: "center"}}>{cData.appRecDTO.recname}</a>
                                                                            </li>
                                                                        </td>
                                                                        <td>
                                                                            <li>
                                                                                <a className="font-b">{cData.appRecDTO.countryDTO.countryNm + "[" + cData.appRecDTO.countryDTO.countryCode + "]"}</a>
                                                                                <a>{cData.orderdtDTO.memberDTO.lang}</a>
                                                                            </li>
                                                                        </td>
                                                                        <td>
                                                                            <li className="admin-tb-text-cut">
                                                                                {cData && cData.packListDTO && cData.packListDTO.productsDTO && cData.packListDTO.productsDTO.length > 0 && (
                                                                                    <div>
                                                                                        {cData.packListDTO.productsDTO.sort((a,b) => a.seqNum - b.seqNum).slice(0, 6).map((product, index) => (
                                                                                            <div key={index}>
                                                                                                <a>{product.name}</a>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                )}
                                                                            </li>
                                                                        </td>
                                                                        <td>
                                                                            <li>
                                                                                <a className="font-b">
                                                                                    {orderStPrint(
                                                                                        cData.payStatus === "PS0" ? "OS09" :
                                                                                            cData.payStatus === "PS1" ? "OS10" :
                                                                                                cData.payStatus === "PS2" ? "OS11" :
                                                                                                    cData.payStatus === "PS3" ? "OS12" :
                                                                                                        cData.payStatus === "PS4" ? "OS13" :
                                                                                                            cData.payStatus === "PS5" ? "OS14" : null)}
                                                                                </a>
                                                                            </li>
                                                                        </td>
                                                                        <td>
                                                                            <li style={{alignItems: "center"}}>
                                                                                { cData.ttFedexPrice > 0 || cData.ttEmsPrice > 0 || cData.ttSeaPrice > 0 ?
                                                                                    <a>{convertToShipco(cData.dvType, cData.orderdtDTO.shipco)}</a>
                                                                                    : cData.ttDomPrice > 0 ?
                                                                                    <a>국내배송</a>
                                                                                    : null
                                                                                }
                                                                                <span>
                                                                                    <a className="m-r-7 font-b c-point font-c-progress text-underline">{cData.shipInfoDTO?.outWayNum || ""}</a>
                                                                                    {cData.shipInfoDTO?.outWayNum !== "" && cData.shipInfoDTO?.outWayNum !== 0 && cData.shipInfoDTO?.outWayNum !== null &&
                                                                                        <RxCopy className="font-s-18 c-point btorage-hover btn-click-event" style={{opacity: "0.65"}}
                                                                                                onClick={() => handleCopy(cData.shipInfoDTO?.outWayNum)}/>
                                                                                    }
                                                                                </span>
                                                                                <button className="admin-bt-color" style={{marginTop: "10px", width: "40px"}}>R</button>
                                                                            </li>
                                                                        </td>
                                                                        <td>
                                                                            <li className="admin-tb-text-cut">
                                                                                <a>{cData.customsDTO.custQty}개</a>
                                                                                <a>{cData.customsDTO.custPrice}$</a>
                                                                                { cData.customsDTO.insrFl ?
                                                                                    <div style={{display:"flex", justifyContent:"center"}}>
                                                                                        <a className="font-b font-c-cancel">Y</a>
                                                                                        <a style={{marginLeft:"5px"}}>({cData.customsDTO.insrPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</a>
                                                                                    </div>
                                                                                    :
                                                                                    <a>N</a>
                                                                                }
                                                                                <a>{cData.customsDTO.custContent}</a>
                                                                            </li>
                                                                        </td>
                                                                        <td className="wd-100pc">
                                                                            { cData.ttFedexPrice > 0 || cData.ttEmsPrice > 0 || cData.ttSeaPrice > 0 ?
                                                                            <li className="wd-100pc" style={{gap: "8px"}}>
                                                                                {/*<a style={{lineHeight: "24px"}}>FedEx</a>*/}
                                                                                {/*<a style={{lineHeight: "26px", color:cData?.ttFedexPrice > 0 && cData?.payStatus === "PS0" ? "darkorange" : "black", fontWeight:cData?.ttFedexPrice > 0 ? "bold" : "normal"}}>*/}
                                                                                {/*    {cData?.ttFedexPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}*/}
                                                                                {/*</a>*/}
                                                                                {/*<a style={{lineHeight: "24px"}}>DHL</a>*/}
                                                                                {/*<a style={{lineHeight: "26px", color:cData?.ttSeaPrice > 0 && cData?.payStatus === "PS0" ? "darkorange" : "black", fontWeight:cData?.ttSeaPrice > 0 ? "bold" : "normal"}}>*/}
                                                                                {/*    {cData?.ttSeaPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}*/}
                                                                                {/*</a>*/}
                                                                                {/*<a style={{lineHeight: "24px"}}>EMS</a>*/}
                                                                                {/*<a style={{lineHeight: "26px", color:cData?.ttEmsPrice > 0 && cData?.payStatus === "PS0" ? "darkorange" : "black", fontWeight:cData?.ttEmsPrice > 0 ? "bold" : "normal"}}>*/}
                                                                                {/*    {cData?.ttEmsPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}*/}
                                                                                {/*</a>*/}
                                                                                {/*<a style={{lineHeight: "24px"}}>EMSP</a>*/}
                                                                                {/*<a style={{lineHeight: "26px", color:cData?.ttSeaPrice > 0 && cData?.payStatus === "PS0" ? "darkorange" : "black", fontWeight:cData?.ttSeaPrice > 0 ? "bold" : "normal"}}>*/}
                                                                                {/*    {cData?.ttSeaPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}*/}
                                                                                {/*</a>*/}
                                                                                {/*<a style={{lineHeight: "24px"}}>Sea</a>*/}
                                                                                {/*<a style={{lineHeight: "26px", color:cData?.ttSeaPrice > 0 && cData?.payStatus === "PS0" ? "darkorange" : "black", fontWeight:cData?.ttSeaPrice > 0 ? "bold" : "normal"}}>*/}
                                                                                {/*    {cData?.ttSeaPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}*/}
                                                                                {/*</a>*/}
                                                                                {/*<a style={{lineHeight: "24px"}}>K-Packet</a>*/}
                                                                                {/*<a style={{lineHeight: "26px", color:cData?.ttSeaPrice > 0 && cData?.payStatus === "PS0" ? "darkorange" : "black", fontWeight:cData?.ttSeaPrice > 0 ? "bold" : "normal"}}>*/}
                                                                                {/*    {cData?.ttSeaPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}*/}
                                                                                {/*</a>*/}
                                                                                {/*<a style={{lineHeight: "24px"}}>항공소형</a>*/}
                                                                                {/*<a style={{lineHeight: "26px", color:cData?.ttSeaPrice > 0 && cData?.payStatus === "PS0" ? "darkorange" : "black", fontWeight:cData?.ttSeaPrice > 0 ? "bold" : "normal"}}>*/}
                                                                                {/*    {cData?.ttSeaPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}*/}
                                                                                {/*</a>*/}
                                                                                <div className="display-flex flex-row just-cont-center wd-100pc">
                                                                                    <a className="font-b wd-20 text-center">F:&nbsp;</a>
                                                                                    <a className="wd-70" style={{color:cData?.ttFedexPrice > 0 && cData?.payStatus === "PS0" ? "darkorange" : "black", fontWeight:cData?.ttFedexPrice > 0 ? "bold" : "normal"}}>
                                                                                        {cData?.ttFedexPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                                    </a>
                                                                                </div>
                                                                                <div className="display-flex flex-row just-cont-center">
                                                                                    <a className="font-b wd-20">P:&nbsp;</a>
                                                                                    <a className="wd-70" style={{color:cData?.ttUpsPrice > 0 && cData?.payStatus === "PS0" ? "darkorange" : "black", fontWeight:cData?.ttUpsPrice > 0 ? "bold" : "normal"}}>
                                                                                        {cData?.ttUpsPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                                    </a>
                                                                                </div>
                                                                                {/*<div className="display-flex flex-row just-cont-center">*/}
                                                                                {/*    <a className="font-b wd-20">D:&nbsp;</a>*/}
                                                                                {/*    <a className="wd-70" style={{color:cData?.ttDhlPrice > 0 && cData?.payStatus === "PS0" ? "darkorange" : "black", fontWeight:cData?.ttFedexPrice > 0 ? "bold" : "normal"}}>*/}
                                                                                {/*        {cData?.ttDhlPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}*/}
                                                                                {/*    </a>*/}
                                                                                {/*</div>*/}
                                                                                <div className="display-flex flex-row just-cont-center">
                                                                                    <a className="font-b wd-20">E:&nbsp;</a>
                                                                                    <a className="wd-70" style={{color:cData?.ttEmsPrice > 0 && cData?.payStatus === "PS0" ? "darkorange" : "black", fontWeight:cData?.ttEmsPrice > 0 ? "bold" : "normal"}}>
                                                                                        {cData?.ttEmsPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                                    </a>
                                                                                </div>
                                                                                <div className="display-flex flex-row just-cont-center">
                                                                                    <a className="font-b wd-20">S:&nbsp;</a>
                                                                                    <a className="wd-70" style={{color:cData?.ttSeaPrice > 0 && cData?.payStatus === "PS0" ? "darkorange" : "black", fontWeight:cData?.ttSeaPrice > 0 ? "bold" : "normal"}}>
                                                                                        {cData?.ttSeaPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                                    </a>
                                                                                </div>
                                                                                <div className="display-flex flex-row just-cont-center">
                                                                                    <a className="font-b wd-20">K:&nbsp;</a>
                                                                                    <a className="wd-70" style={{color:cData?.ttKPackPrice > 0 && cData?.payStatus === "PS0" ? "darkorange" : "black", fontWeight:cData?.ttKPackPrice > 0 ? "bold" : "normal"}}>
                                                                                        {cData?.ttKPackPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                                    </a>
                                                                                </div>
                                                                                <div className="display-flex flex-row just-cont-center">
                                                                                    <a className="font-b wd-20">A:&nbsp;</a>
                                                                                    <a className="wd-70" style={{color:cData?.ttAirSPrice > 0 && cData?.payStatus === "PS0" ? "darkorange" : "black", fontWeight:cData?.ttAirSPrice > 0 ? "bold" : "normal"}}>
                                                                                        {cData?.ttAirSPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                                    </a>
                                                                                </div>
                                                                            </li>
                                                                                :
                                                                                cData.ttDomPrice > 0 ?
                                                                                    <li>
                                                                                        <a style={{lineHeight: "24px"}}>국내배송</a>
                                                                                        <a style={{lineHeight: "26px", color:cData?.ttDomPrice > 0 && cData?.payStatus === "PS0" ? "darkorange" : "black", fontWeight:"bold"}}>
                                                                                            {cData?.ttDomPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                                        </a>
                                                                                    </li>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </td>

                                                                        <td>
                                                                            { totalProdQyDv(cData) > 0 ?
                                                                                <a style={{display:"flex", alignItems:"center", justifyContent:"center"}}>{totalProdQyDv(cData)}개</a>
                                                                                :
                                                                                <a style={{display:"flex", alignItems:"center", justifyContent:"center"}}>0개</a>
                                                                            }
                                                                            {/*<div style={{display:"flex", alignItems:"center", margin:"5px 0 0 2px"}}>*/}
                                                                            {/*    <img style={{width:"18px", height:"18px"}} alt="deposit" src={deposit}/>*/}
                                                                            {/*    <a style={{color: cData.orderdtDTO.memberDTO.deposit < 0 ? "#FF6628" : "black", marginLeft:"4px"}}>*/}
                                                                            {/*        {cData.orderdtDTO.memberDTO.deposit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}*/}
                                                                            {/*    </a>*/}
                                                                            {/*</div>*/}
                                                                            {/*<div style={{display:"flex", alignItems:"center", marginLeft:"3px"}}>*/}
                                                                            {/*    <img style={{width:"16px", height:"16px"}} alt="point" src={point}/>*/}
                                                                            {/*    <a style={{color: cData.orderdtDTO.memberDTO.point < 0 ? "#FF6628" : "black", marginLeft:"5px"}}>*/}
                                                                            {/*        {cData.orderdtDTO.memberDTO.point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}*/}
                                                                            {/*    </a>*/}
                                                                            {/*</div>*/}
                                                                        </td>
                                                                        <td>
                                                                            <li>
                                                                                <a style={{lineHeight: "28px"}}>{formatDateTime(cData.regTime)}</a>
                                                                                <a className="font-b" style={{lineHeight: "28px"}}>{formatDateTime(cData.orderdtDTO?.modiTime)}</a>
                                                                                <a style={{lineHeight: "28px"}}>{cData.shipInfoDTO?.outRegTime ? formatDateTime(cData.shipInfoDTO.outRegTime) : "-"}</a>
                                                                                <a style={{lineHeight: "28px"}}>{cData.shipInfoDTO?.regTime ? formatDateTime(cData.shipInfoDTO.regTime) : "-"}</a>
                                                                            </li>
                                                                        </td>
                                                                        <td>
                                                                            <li style={{alignItems: "center"}}>
                                                                                {cData.type === "payinfo" && cData.payStatus !== "PS3" &&
                                                                                    <button className="admin-bt-color btn-click-event" style={{width: "75px"}}
                                                                                            onClick={() => openModal(cData, 'payinfo')}>
                                                                                            비용수정
                                                                                    </button>
                                                                                }
                                                                                {/*{cData.type === "payinfo" && cData.payStatus !== "PS3" &&*/}
                                                                                {/*    <button className="admin-bt-color btn-click-event" style={{width: "75px"}}*/}
                                                                                {/*            onClick={() => openPayModal(cData, 'payinfo')}>*/}
                                                                                {/*        tt*/}
                                                                                {/*    </button>*/}
                                                                                {/*}*/}
                                                                            </li>
                                                                            <li style={{alignItems: "center"}}>
                                                                                {cData.type === "payinfo" && cData.payStatus !== "PS3" &&
                                                                                    <button className="admin-bt-color btn-click-event" style={{width: "75px"}}
                                                                                            onClick={() => alert3(cData)}>
                                                                                            등록취소
                                                                                    </button>
                                                                                }
                                                                            </li>
                                                                            <li style={{alignItems: "center"}}>
                                                                                {cData.type === "payinfo" && cData.payStatus === "PS3" &&
                                                                                    <button className="admin-bt-color btn-click-event" style={{width: "75px"}}>
                                                                                            출고취소
                                                                                    </button>
                                                                                }
                                                                            </li>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan="13">
                                                                            <div className="cont-dt">
                                                                                <span className="dt-option">
                                                                                    <a className="c-def dark-font">요청옵션 : </a>
                                                                                    {cData.orderdtDTO.reqmnDTO.tagRemove && <a className="font-c-progress">&nbsp;택제거&nbsp;</a>}
                                                                                    {cData.orderdtDTO.reqmnDTO.shoesBoxRemove &&
                                                                                        <a className="font-c-progress">{cData.orderdtDTO.reqmnDTO.tagRemove &&
                                                                                            <span>/</span>}&nbsp;신발박스제거&nbsp;</a>}
                                                                                    {cData.orderdtDTO.reqmnDTO.hangerRemove &&
                                                                                        <a className="font-c-progress">{cData.orderdtDTO.reqmnDTO.tagRemove || cData.orderdtDTO.reqmnDTO.shoesBoxRemove ?
                                                                                            <span>/</span> : ""}&nbsp;옷걸이제거&nbsp;</a>}
                                                                                    {cData.orderdtDTO.reqmnDTO.compressionPack &&
                                                                                        <a className="font-c-progress">{cData.orderdtDTO.reqmnDTO.tagRemove || cData.orderdtDTO.reqmnDTO.shoesBoxRemove || cData.orderdtDTO.reqmnDTO.hangerRemove ?
                                                                                            <span>/</span> : ""}&nbsp;압축팩서비스&nbsp;</a>}
                                                                                    {cData.orderdtDTO.reqmnDTO.prodBoxRemove &&
                                                                                        <a className="font-c-progress">{cData.orderdtDTO.reqmnDTO.tagRemove || cData.orderdtDTO.reqmnDTO.shoesBoxRemove || cData.orderdtDTO.reqmnDTO.hangerRemove || cData.orderdtDTO.reqmnDTO.compressionPack ?
                                                                                            <span>/</span> : ""}&nbsp;제품박스제거&nbsp;</a>}
                                                                                    {cData.orderdtDTO.reqmnDTO.packingHold &&
                                                                                        <a className="font-c-progress">{cData.orderdtDTO.reqmnDTO.tagRemove || cData.orderdtDTO.reqmnDTO.shoesBoxRemove || cData.orderdtDTO.reqmnDTO.hangerRemove || cData.orderdtDTO.reqmnDTO.compressionPack || cData.orderdtDTO.reqmnDTO.prodBoxRemove ?
                                                                                            <span>/</span> : ""}&nbsp;패킹보류&nbsp;</a>}
                                                                                    {cData.orderdtDTO.reqmnDTO.shipPriceDepositpay &&
                                                                                        <a className="font-c-progress">{cData.orderdtDTO.reqmnDTO.tagRemove || cData.orderdtDTO.reqmnDTO.shoesBoxRemove || cData.orderdtDTO.reqmnDTO.hangerRemove || cData.orderdtDTO.reqmnDTO.compressionPack || cData.orderdtDTO.reqmnDTO.prodBoxRemove || cData.orderdtDTO.reqmnDTO.packingHold ?
                                                                                            <span>/</span> : ""}&nbsp;배송료예치금결제&nbsp;</a>}
                                                                                    {cData.orderdtDTO.reqmnDTO.shipPriceMessenger &&
                                                                                        <a className="font-c-progress">{cData.orderdtDTO.reqmnDTO.tagRemove || cData.orderdtDTO.reqmnDTO.shoesBoxRemove || cData.orderdtDTO.reqmnDTO.hangerRemove || cData.orderdtDTO.reqmnDTO.compressionPack || cData.orderdtDTO.reqmnDTO.prodBoxRemove || cData.orderdtDTO.reqmnDTO.packingHold || cData.orderdtDTO.reqmnDTO.shipPriceDepositpay ?
                                                                                            <span>/</span> : ""}&nbsp;배송료메신저안내&nbsp;</a>}
                                                                                </span>
                                                                                <span className="dt-req">
                                                                                    <a className="c-def dark-font">요청사항 : </a>
                                                                                    <a className="font-c-cancel">&nbsp;{cData.orderdtDTO.reqmnDTO.detailReq}</a>
                                                                                </span>
                                                                                <span className="dt-memo">
                                                                                    <a className="c-def dark-font">주문서메모:&nbsp;</a>
                                                                                    <input className="admin-input-line order-memo-input p-l-5" type="text" maxLength="300"
                                                                                           onKeyDown={(e) => onKeyPress(e, cData.seqNum, 'appOrderMemoDTO')}
                                                                                           value={memoModiData[`${cData.seqNum}-appOrderMemoDTO`] || cData?.appOrderMemoDTO?.ordDtCont || ''}
                                                                                           onChange={(e) => handleInputMemoChange(e, cData.seqNum, 'appOrderMemoDTO')}
                                                                                           ref={(el) => (inputRefs.current[cData.seqNum] = el)}/>
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                )
                                                                :
                                                                null
                                                    })}
                                                </Table>
                                            </div>
                                            <div style={{minHeight:"50px", display:"flex", alignItems:"center", marginLeft:"10px"}}>
                                            {selectedProcessSt === "PS3" ?
                                                <div style={{display: "flex", alignItems: "center"}}>
                                                    <button onClick={openDsReturnSt} className="orderSaveBt user-center" style={{
                                                        width: "80px",
                                                        height: "30px",
                                                        textAlign: "center",
                                                        fontSize: "18px",
                                                        fontWeight:"lighter",
                                                        borderColor:"lightgray"
                                                    }}><span style={{fontSize:"12px"}}>국내반송</span>
                                                    </button>
                                                    <button onClick={openOsReturnSt} className="orderSaveBt user-center" style={{
                                                        width: "80px",
                                                        height: "30px",
                                                        textAlign: "center",
                                                        fontSize: "18px",
                                                        fontWeight:"lighter",
                                                        marginLeft:"5px",
                                                        borderColor:"lightgray"
                                                    }}><span style={{fontSize:"12px"}}>해외반송</span>
                                                    </button>
                                                </div>
                                                :
                                                null
                                            }
                                                { selectedProcessSt === "PS0" ?
                                                    <div style={{display:"flex", alignItems:"center"}}>
                                                        <button onClick={openPayinfoDepositPaySt} className="orderSaveBt user-center" style={{
                                                            width: "80px",
                                                            height: "30px",
                                                            textAlign: "center",
                                                            fontWeight:"lighter",
                                                            borderColor:"lightgray"
                                                        }}><span style={{fontSize:"12px"}}>예치금결제</span>
                                                        </button>
                                                        <button onClick={openPayinfoPointPaySt} className="orderSaveBt user-center" style={{
                                                            width: "80px",
                                                            height: "30px",
                                                            textAlign: "center",
                                                            fontWeight:"lighter",
                                                            marginLeft:"5px",
                                                            borderColor:"lightgray"
                                                        }}><span style={{fontSize:"12px"}}>적립금결제</span>
                                                        </button>
                                                    </div>
                                                    :
                                                    selectedProcessSt === "OS01" || selectedProcessSt === "OS02" || selectedProcessSt === "OS03" ||
                                                    selectedProcessSt === "OS04" || selectedProcessSt === "OS05" || selectedProcessSt === "OS06" ?
                                                        <div style={{display:"flex", alignItems:"center"}}>
                                                            <button onClick={openOrderdtDepositPaySt} className="orderSaveBt user-center" style={{
                                                                width: "80px",
                                                                height: "30px",
                                                                textAlign: "center",
                                                                fontWeight:"lighter",
                                                                borderColor:"lightgray"
                                                            }}><span style={{fontSize:"12px"}}>예치금결제</span>
                                                            </button>
                                                            <button onClick={openOrderdtPointPaySt} className="orderSaveBt user-center" style={{
                                                                width: "80px",
                                                                height: "30px",
                                                                textAlign: "center",
                                                                fontWeight:"lighter",
                                                                marginLeft:"5px",
                                                                borderColor:"lightgray"
                                                            }}><span style={{fontSize:"12px"}}>적립금결제</span>
                                                            </button>
                                                        </div>
                                                        :
                                                    null
                                                }
                                            </div>
                                        </div>

                                    </Tab>
                                    <Tab className="tab-temp" eventKey="tab-temp" title="예비Tab">

                                    </Tab>
                                </Tabs>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <TopBtn/>
            {deliveryDetailSt ?
                <DeliveryPriceDetail deliveryDetailSt={deliveryDetailSt} deliveryDetailFalseFn={deliveryDetailFalseFn}
                                     paySeqnum={paySeqnum} updateAppRecinfo={updateAppRecinfo} updatePayinfoList={updatePayinfoList} pathComponent={pathComponent}/>
                :
                allCartProdStChangeShow ?
                    <MsgSelectModal allCartProdStChangeShow={allCartProdStChangeShow} closeAllCartProdStChangeShow={closeAllCartProdStChangeShow}
                                    allProdStChangeMsg1={allProdStChangeMsg1} allProdStChangeMsg2={allProdStChangeMsg2} modifyProdSt={modifyProdSt}
                                    selectedAllCartProdSt={selectedAllCartProdSt} selectedItems={selectedItems} selectedCdataIndex={selectedCdataIndex} selectedFormTy={selectedFormTy}/>
                    :
                    allCartProdStNonSeqnumShow ?
                        <MsgConfirmModal allCartProdStNonSeqnumShow={allCartProdStNonSeqnumShow} closeAllCartProdStNonSeqNumModalShow={closeAllCartProdStNonSeqNumModalShow}
                                         allProdStNonSeqnumMsg={allProdStNonSeqnumMsg}/>
                        :
                        allOrderProdStChangeShow ?
                            <MsgSelectModal allOrderProdStChangeShow={allOrderProdStChangeShow} closeAllOrderProdStChangeShow={closeAllOrderProdStChangeShow}
                                            allProdStChangeMsg1={allProdStChangeMsg1} allProdStChangeMsg2={allProdStChangeMsg2} modifyProdSt={modifyProdSt}
                                            selectedAllOrderProdSt={selectedAllOrderProdSt} selectedItems={selectedItems} selectedCdataIndex={selectedCdataIndex} selectedFormTy={selectedFormTy}/>
                            :
                            allOrderProdStNonSeqnumShow ?
                                <MsgConfirmModal allOrderProdStNonSeqnumShow={allOrderProdStNonSeqnumShow} closeAllOrderProdStNonSeqNumModalShow={closeAllOrderProdStNonSeqNumModalShow}
                                                 allProdStNonSeqnumMsg={allProdStNonSeqnumMsg}/>
                                :
                                allOfflineProdStChangeShow ?
                                    <MsgSelectModal allOfflineProdStChangeShow={allOfflineProdStChangeShow} closeAllOfflineProdStChangeShow={closeAllOfflineProdStChangeShow}
                                                    allProdStChangeMsg1={allProdStChangeMsg1} allProdStChangeMsg2={allProdStChangeMsg2} modifyProdSt={modifyProdSt}
                                                    selectedAllOfflineProdSt={selectedAllOfflineProdSt} selectedItems={selectedItems} selectedCdataIndex={selectedCdataIndex} selectedFormTy={selectedFormTy}/>
                                    :
                                    allOfflineProdStNonSeqnumShow ?
                                        <MsgConfirmModal allOfflineProdStNonSeqnumShow={allOfflineProdStNonSeqnumShow} closeAllOfflineProdStNonSeqNumModalShow={closeAllOfflineProdStNonSeqNumModalShow}
                                                         allProdStNonSeqnumMsg={allProdStNonSeqnumMsg}/>
                                        :
                                        allTransferProdStChangeShow ?
                                            <MsgSelectModal allTransferProdStChangeShow={allTransferProdStChangeShow} closeAllTransferProdStChangeShow={closeAllTransferProdStChangeShow}
                                                            allProdStChangeMsg1={allProdStChangeMsg1} allProdStChangeMsg2={allProdStChangeMsg2} modifyProdSt={modifyProdSt}
                                                            selectedAllTransferProdSt={selectedAllTransferProdSt} selectedItems={selectedItems} selectedCdataIndex={selectedCdataIndex} selectedFormTy={selectedFormTy}/>
                                            :
                                            allTransferProdStNonSeqnumShow ?
                                                <MsgConfirmModal allTransferProdStNonSeqnumShow={allTransferProdStNonSeqnumShow} closeAllTransferProdStNonSeqNumModalShow={closeAllTransferProdStNonSeqNumModalShow}
                                                                 allProdStNonSeqnumMsg={allProdStNonSeqnumMsg}/>
                                                :
                                                allDomesticProdStChangeShow ?
                                                    <MsgSelectModal allDomesticProdStChangeShow={allDomesticProdStChangeShow} closeAllDomesticProdStChangeShow={closeAllDomesticProdStChangeShow}
                                                                    allProdStChangeMsg1={allProdStChangeMsg1} allProdStChangeMsg2={allProdStChangeMsg2} modifyProdSt={modifyProdSt}
                                                                    selectedAllDomesticProdSt={selectedAllDomesticProdSt} selectedItems={selectedItems} selectedCdataIndex={selectedCdataIndex} selectedFormTy={selectedFormTy}/>
                                                    :
                                                    allDomesticProdStNonSeqnumShow ?
                                                        <MsgConfirmModal allDomesticProdStNonSeqnumShow={allDomesticProdStNonSeqnumShow} closeAllDomesticProdStNonSeqNumModalShow={closeAllDomesticProdStNonSeqNumModalShow}
                                                                         allProdStNonSeqnumMsg={allProdStNonSeqnumMsg}/>
                                                        :
                                                        allDeliveryProdStChangeShow ?
                                                            <MsgSelectModal allDeliveryProdStChangeShow={allDeliveryProdStChangeShow} closeAllDeliveryProdStChangeShow={closeAllDeliveryProdStChangeShow}
                                                                            allProdStChangeMsg1={allProdStChangeMsg1} allProdStChangeMsg2={allProdStChangeMsg2} modifyProdSt={modifyProdSt}
                                                                            selectedAllDeliveryProdSt={selectedAllDeliveryProdSt} selectedItems={selectedItems} selectedCdataIndex={selectedCdataIndex} selectedFormTy={selectedFormTy}/>
                                                            :
                                                            allDeliveryProdStNonSeqnumShow ?
                                                                <MsgConfirmModal allDeliveryProdStNonSeqnumShow={allDeliveryProdStNonSeqnumShow} closeAllDeliveryProdStNonSeqNumModalShow={closeAllDeliveryProdStNonSeqNumModalShow}
                                                                                 allProdStNonSeqnumMsg={allProdStNonSeqnumMsg}/>
                                                                :
                                                                dontTrackingShipcoSt ?
                                                                    <MsgCheckModal dontTrackingShipcoSt={dontTrackingShipcoSt} closeDontTrackingShipco={closeDontTrackingShipco}/>
                                                                    :
                                                                    // 관리자 예치금or포인트 결제팝업
                                                                    orderdtDepositPaySt ?
                                                                        <MsgSelectModal orderdtDepositPaySt={orderdtDepositPaySt} closeOrderdtDepositPaySt={closeOrderdtDepositPaySt}
                                                                                        orderdtDepositPayMsg1={orderdtDepositPayMsg1} orderdtDepositPayMsg2={orderdtDepositPayMsg2}
                                                                                        orderdtAdminDpPay={orderdtAdminDpPay}/>
                                                                        :
                                                                        orderdtPointPaySt ?
                                                                            <MsgSelectModal orderdtPointPaySt={orderdtPointPaySt} closeOrderdtPointPaySt={closeOrderdtPointPaySt}
                                                                                            orderdtPointPayMsg1={orderdtPointPayMsg1} orderdtPointPayMsg2={orderdtPointPayMsg2}
                                                                                            orderdtAdminDpPay={orderdtAdminDpPay}
                                                                            />
                                                                            :
                                                                        payinfoDepositPaySt ?
                                                                            <MsgSelectModal payinfoDepositPaySt={payinfoDepositPaySt} closePayinfoDepositPaySt={closePayinfoDepositPaySt}
                                                                                            payinfoDepositPayMsg1={payinfoDepositPayMsg1} payinfoDepositPayMsg2={payinfoDepositPayMsg2}
                                                                                            payinfoAdminDpPay={payinfoAdminDpPay}
                                                                            />
                                                                            :
                                                                            payinfoPointPaySt ?
                                                                                <MsgSelectModal payinfoPointPaySt={payinfoPointPaySt} closePayinfoPointPaySt={closePayinfoPointPaySt}
                                                                                                payinfoPointPayMsg1={payinfoPointPayMsg1} payinfoPointPayMsg2={payinfoPointPayMsg2}
                                                                                                payinfoAdminDpPay={payinfoAdminDpPay}
                                                                                />
                                                                                :
                                                                                dpPayNullSt ?
                                                                                    <MsgCheckModal dpPayNullSt={dpPayNullSt} closeDpPayNullSt={closeDpPayNullSt}/>
                                                                                    :
                                                                                    // 관리자 해외/국내반송 팝업
                                                                                    adminOsReturnSt ?
                                                                                        <MsgSelectModal adminOsReturnSt={adminOsReturnSt} closeOsReturnSt={closeOsReturnSt}
                                                                                                        osReturnMsg1={osReturnMsg1} osReturnMsg2={osReturnMsg2}
                                                                                                        deliveryReturn={deliveryReturn}
                                                                                        />
                                                                                        :
                                                                                        adminDsReturnSt ?
                                                                                            <MsgSelectModal adminDsReturnSt={adminDsReturnSt} closeDsReturnSt={closeDsReturnSt}
                                                                                                            dsReturnMsg1={dsReturnMsg1} dsReturnMsg2={dsReturnMsg2}
                                                                                                            deliveryReturn={deliveryReturn}
                                                                                            />
                                                                                            :
                                                                                            returnNullSt ?
                                                                                                <MsgCheckModal returnNullSt={returnNullSt} closeReturnNullSt={closeReturnNullSt}/>
                                                                                                :
                                                                                                saveMsgSt ?
                                                                                                    <SaveMsgModal message={savePopupMsg} />
                                                                                                    :
                                                                                                    payinfoSelectOrderErrSt ?
                                                                                                        <MsgCheckModal payinfoSelectOrderErrSt={payinfoSelectOrderErrSt} payinfoSelectOrderErrMsg={payinfoSelectOrderErrMsg} closePayinfoSelectOrderErr={closePayinfoSelectOrderErr}/>
                                                                                                        :
                                                                    null
            }
            {alertShow3 === true ? <MsgSelectModal alertShow={alertShow3} eventHandle1={modalCancel3} eventHandle2={() => expRegDelete(selectedData)} msgData1={msgConfirmData5} msgData2={msgConfirmData6}/> : null}
        </div>
    );
}

export default AdminDashBoardPg
