import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {ChatProvider} from "./components/contextApi/ChatContext";
import {BoxSaveProvider} from "./components/contextApi/PackingContext";
import { store } from './store/store';
import {Provider} from "react-redux";

// region 콘솔창 꾸미기

function scriptFixed(bt, context) {
    let result;
    return function () {
        if (bt) {
            result = bt.apply(context || this, arguments);
            bt = null;
        }
        return result;
    };
}
let Shapeog = scriptFixed(function () {
    console.log("" +
        "%c\n" +
        " /$$$$$$$   /$$                                                     \n" +
        "| $$__  $$ | $$                                                     \n" +
        "| $$  \\ $$/$$$$$$    /$$$$$$   /$$$$$$  /$$$$$$   /$$$$$$   /$$$$$$ \n" +
        "| $$$$$$$|_  $$_/   /$$__  $$ /$$__  $$|____  $$ /$$__  $$ /$$__  $$\n" +
        "| $$__  $$ | $$    | $$  \\ $$| $$  \\__/ /$$$$$$$| $$  \\ $$| $$$$$$$$\n" +
        "| $$  \\ $$ | $$ /$$| $$  | $$| $$      /$$__  $$| $$  | $$| $$_____/\n" +
        "| $$$$$$$/ |  $$$$/|  $$$$$$/| $$     |  $$$$$$$|  $$$$$$$|  $$$$$$$\n" +
        "|_______/   \\___/   \\______/ |__/      \\_______/ \\____  $$ \\_______/\n" +
        "                                                 /$$  \\ $$          \n" +
        "                                                |  $$$$$$/          \n" +
        "                                                 \\______/           \n" +
        "\n" +
        "                                              %c해외배송은 비토리지!!!!!\n" +
        "\n"
        , "color: #FFAF34", "color: #7E7E7EFF");
    // console.log('%cTicket Box :: ' + BtorageCareer, `color: ${"#548ddc"}`);
});
Shapeog();

// endregion

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <ChatProvider>
            <BrowserRouter>
                <BoxSaveProvider>
                    <App/>
                </BoxSaveProvider>
            </BrowserRouter>
        </ChatProvider>
    </Provider>
);
