import axios from "axios";

export const orderStatusUpdate = (stompClient, prod, orderSeqNum) => {

    const prodStPriority = {
        "IS12": 14, "IS11": 12, "IS10": 13, "IS09": 8, "IS08": 7, "IS07": 6,
        "IS06": 5, "IS05": 11, "IS04": 4, "IS14": 9, "IS03": 0, "IS02": 3,
        "IS01": 2, "IS13": 1, "IS00": 10
    };

    const filterByPriority = (minPriority) => {
        return prod.filter(p => prodStPriority[p.prodSt] <= minPriority);
    };

    let highestPriorityState = Object.keys(prodStPriority).reduce((highest, current) => {
        if (filterByPriority(prodStPriority[current]).length > 0) {
            return prodStPriority[current] < prodStPriority[highest] ? current : highest;
        }
        return highest;
    }, "IS12");

    const modifyOrderSt = (orderSt, orderSeqNum) => {
        if (!orderSeqNum) {
            console.warn("전달된 orderSeqNum이 없습니다.");
            return;
        }
        axios({
            method: 'post',
            url: '/order/modifyOrderSt/',
            params: {
                orderSt: orderSt,
                orderSeqnum: orderSeqNum
            }
        })
            .then((response) => {
                sendOrderStUpdate(response.data.orderSt, response.data.orderSeqNum, response.data.memberDTO.userName);
            })
    }

    switch (highestPriorityState) {
        case "IS03": modifyOrderSt("OS03", orderSeqNum); break;
        case "IS13": modifyOrderSt("OS04", orderSeqNum); break;
        case "IS01": modifyOrderSt("OS01", orderSeqNum); break;
        case "IS02": modifyOrderSt("OS02", orderSeqNum); break;
        case "IS04": modifyOrderSt("OS05", orderSeqNum); break;
        case "IS14": modifyOrderSt("OS06", orderSeqNum); break;
        case "IS05": modifyOrderSt("OS07", orderSeqNum); break;
        case "IS06": modifyOrderSt("OS08", orderSeqNum); break;
        case "IS07": modifyOrderSt("OS09", orderSeqNum); break;
        case "IS08": modifyOrderSt("OS10", orderSeqNum); break;
        case "IS09": modifyOrderSt("OS11", orderSeqNum); break;
        case "IS10": modifyOrderSt("OS12", orderSeqNum); break;
        case "IS11": modifyOrderSt("OS13", orderSeqNum); break;
        case "IS00": modifyOrderSt("OS00", orderSeqNum); break;
        case "IS12": modifyOrderSt("OS14", orderSeqNum); break;
        default: console.log("함수실행 조건을 충족하는 상태값이 없습니다"); break;
    }

    const sendOrderStUpdate = async (orderSt, orderSeqNum, userName) => {
        const orderStInfo = {
            orderSt: orderSt,
            orderSeqNum: orderSeqNum,
            userName: userName
        };
        if (stompClient && stompClient.connected) {
            await stompClient.send("/app/orderSt/adminOrderStUpdate", {}, JSON.stringify(orderStInfo));
        } else {
            console.error("Stomp client is not connected.");
        }
    };
};
