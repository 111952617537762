import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import uploadImg from "../../assets/img/reviewUpload.png";
import {BsTrash} from "react-icons/bs";
import commonClose from "../../assets/img/commonClose.png";
import {BiCaretDown} from "react-icons/bi";
import commonPlus from "../../assets/img/commonPlus.png";
import mstEdit from "../../assets/img/mstEdit.png";
import mstClose from "../../assets/img/mstClose.png";
import AdminMstEdit from "../../components/web/modal/AdminMstEdit";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import back from "../../assets/img/commonBack.png";
import useTheme from "../../components/web/layoutscommon/DarkModeStorage";
import '../../css/mobile/MAdmin.css';
import '../../css/Common.css';

function MAdminFaqModifyPg() {

    const navigate = useNavigate();
    const {seqNum} = useParams();
    const LCAT = localStorage.getItem('adminToken');

    const [adminInfo, setAdminInfo] = useState("");
    const findMember = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data)
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (LCAT) {
            findMember();
        }
    }, [LCAT])

    const [fixCheck, setFixCheck] = useState(false);
    const selectedFixCheckBox = (() => {
        setFixCheck(!fixCheck);
    })
    const moveToReturn = (() => {
        navigate(`/btcorpad/adminfaq-detail/${seqNum}`);
    })
    const [mstAddShow, setMstAddShow] = useState(false);
    const closeMstAddShow = (() => {
        setMstAddShow(false);
    })

    const saveFaqMst = (content) => axios({
        method: 'post',
        url: '/faq/saveFaqMst/',
        data: {
            title: content
        },
        headers: {
            Authorization: LCAT
        },
    })
        .then((response) => {
            const returnData = response.data;
            setFaqMstList(prevFaqMstList => [...prevFaqMstList, returnData]);
            closeMstAddShow();
        })


    // 여기서부터 커스텀 셀렉트
    const [faqMstList, setFaqMstList] = useState([]);
    const [selectedFaqMst, setSelectedFaqMst] = useState('');
    const [selectedFaqMstSeqNum, setSelectedFaqMstSeqNum] = useState('');
    const [faqMstShow, setFaqMstShow] = useState(false);
    const faqMstRef = useRef(null);

    const toggleFaqMstShow = () => {
        setFaqMstShow(!faqMstShow);
    };

    const handleFaqMstChange = (mst) => {
        setSelectedFaqMst(mst);
        setFaqMstShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (faqMstRef.current && !faqMstRef.current.contains(event.target)) {
                setFaqMstShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const selectMstTab = () => axios({
        method: 'get',
        url: '/faq/selectMstTab/',
    })
        .then((response) => {
            const faqData = response.data.map(item => ({
                seqNum: item.seqNum,
                title: item.title
            }));
            setFaqMstList(faqData);
        })

    useEffect(() => {
        selectMstTab()
    }, [])


    // 여기까지 커스텀 셀렉트

    const [mstModifyShow, setMstModifyShow] = useState(false);
    const [mstContent, setMstContent] = useState('');
    const [mstModifySeqNum, setMstModifySeqNum] = useState('');
    const openMsgModifyShow = ((mst) => {
        setMstContent(mst.title);
        setMstModifySeqNum(mst.seqNum)
        setMstModifyShow(true);
    })
    const closeMsgModifyShow = (() => {
        setMstModifyShow(false);
    })

    const modifyFaqMst = (content) => axios({
        method: 'post',
        url: '/faq/modifyFaqMst/',
        params: {
            mstSeqNum: mstModifySeqNum,
            title: content
        }
    })
        .then((response) => {
            const returnData = response.data;
            const updatedFaqMstList = faqMstList.map(item =>
                item.seqNum === returnData.seqNum ? {...item, ...returnData} : item
            );
            setFaqMstList(updatedFaqMstList);
            setSelectedFaqMst("구분선택")
            closeMsgModifyShow();
        })

    const [mstDeleteMsgShow, setMstDeleteMsgShow] = useState(false);
    const [mstDeleteSeqNum, setMstDeleteSeqNum] = useState('');
    const [mstDeleteContent, setMstDeleteContent] = useState('');

    const mstDeleteMsg1 = "구분 목록을 삭제하시겠습니까?"
    const mstDeleteMsg2 = "삭제 시 하위 FAQ 게시물들도 함께 삭제됩니다"
    const closeMstDeleteMsg = (() => {
        setMstDeleteMsgShow(false);
    })
    const openMstDeleteMsg = ((mst) => {
        setMstDeleteSeqNum(mst.seqNum);
        setMstDeleteContent(mst.title);
        setMstDeleteMsgShow(true);
    })

    const deleteFaqMst = (seqNum, content) => axios({
        method: 'post',
        url: '/faq/deleteFaqMst/',
        params: {
            mstSeqNum: seqNum,
            mstContent: content
        }
    })
        .then((response) => {
            const deletedSeqNum = response.data;
            const updatedFaqMstList = faqMstList.filter(item => item.seqNum !== deletedSeqNum);
            setFaqMstList(updatedFaqMstList);
            setSelectedFaqMst("구분선택")
            closeMstDeleteMsg();
        })

    // copy
    const [titleErrorMsg, setTitleErrorMsg] = useState(false);
    const [contentErrorMsg, setContentErrorMsg] = useState(false);
    const [faqMstErrorMsg, setFaqMstErrorMsg] = useState(false);

    const [titleText, setTitleText] = useState('');
    const handleChangeTitleText = (e) => {
        setTitleText(e.target.value);
    };

    const [contentText, setContentText] = useState('');
    const handleChangeContentText = (e) => {
        setContentText(e.target.value);
    };

    const [photoContainers, setPhotoContainers] = useState([{ photoImg: uploadImg, photoImgSt: false, photoImgError: false, photoImgOriNm: '' }]);
    const [photoRemoveContainers, setPhotoRemoveContainers] = useState([{ removeImg: ''}]);
    const selectFaqDtData = () =>
        axios({
            method: 'get',
            url: '/faq/selectFaqDtData',
            headers: {
                Authorization: LCAT
            },
            params: {
                seqNum: seqNum
            }
        })
            .then((response) => {
                setTitleText(response.data.title);
                setContentText(response.data.content);
                setFixCheck(response.data.fixFl);
                setSelectedFaqMst(response.data.faqMstDTO)

                const faqDtPhotos = response.data.faqDtPhotoDTO;
                if (faqDtPhotos && faqDtPhotos.length > 0) {
                    setPhotoContainers(prev => {
                        // 기존 사진 데이터를 포함한 배열 생성
                        const updatedContainers = faqDtPhotos.map(photo => ({
                            photoImg: "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/writePhoto/" + photo.fileNm,
                            photoImgSt: true,
                            photoImgError: false,
                            photoImgFileOriNm: photo.fileOriNm
                        }));

                        // 마지막에 빈 폼 추가
                        updatedContainers.push({ photoImg: uploadImg, photoImgSt: false, photoImgError: false });

                        return updatedContainers;
                    });
                }
            })
            .catch(() => {
                console.log("리뷰조회실패")
            })

    useEffect(() => {
        if (seqNum) {
            selectFaqDtData();
        }
    }, [seqNum])


    const photoImgHandle = (idx) => (e) => {
        const file = e.target.files[0];

        if (file) {
            setPhotoContainers((prev) => {
                const newContainers = [...prev];

                if (!file.type.startsWith('image/')) {
                    newContainers[idx] = {
                        ...newContainers[idx],
                        photoImgError: true,
                    };
                    return newContainers;
                } else {
                    newContainers[idx] = {
                        ...newContainers[idx],
                        photoImg: URL.createObjectURL(file),
                        photoImgSt: true,
                        photoImgError: false,
                        file: file
                    };
                    if (idx === newContainers.length - 1) {
                        addForm(newContainers);
                    }
                    return newContainers;
                }
            });
        }
    };

    const formData = new FormData();

    const photoImgInit = (idx, photoImg) => () => {
        setPhotoRemoveContainers(prev => [...prev, { removeImg: photoImg }]);
        setPhotoContainers((prev) => {
            const newContainers = [...prev];

            newContainers.splice(idx, 1);

            const lastContainer = newContainers[newContainers.length - 1];
            if (lastContainer && lastContainer.photoImgSt) {
                newContainers.push({ photoImg: uploadImg, photoImgSt: false, photoImgError: false });
            }

            return newContainers;
        });
        formData.delete('photoImg');
    };


    const addForm = (newContainers) => {
        const lastContainer = newContainers[newContainers.length - 1];
        if (!lastContainer.photoImgSt) {
            return;
        }

        newContainers.push({ photoImg: uploadImg, photoImgSt: false, photoImgError: false });
    };

    const validation = () => {
        if (titleText === '' || !titleText) setTitleErrorMsg(true);
        if (contentText === '' || !contentText) setContentErrorMsg(true);
        if (!selectedFaqMst) setFaqMstErrorMsg(true);
        return !!(titleText && contentText && selectedFaqMst)
    }

    useEffect(() => {
        if (titleText.length > 0) {
            setTitleErrorMsg(false);
        } if (selectedFaqMst) {
            setFaqMstErrorMsg(false);
        } if (contentText.length > 0) {
            setContentErrorMsg(false);
        }
    }, [titleText, selectedFaqMst, contentText])

    const faqDtModify = async () => {

        const newFormData = new FormData();

        newFormData.append('title', titleText);
        newFormData.append('contentText', contentText);

        photoContainers.forEach((container, i) => {
            if (container.file) {
                newFormData.append('photoImg', container.file);
            } else {
                newFormData.append('photoImg', container.photoImg);
            }
        });

        photoRemoveContainers.forEach((remove, i) => {
            if (remove.removeImg) {
                const filename = remove.removeImg.split('/').pop();
                newFormData.append('removeImg', encodeURIComponent(filename));
            }
        });

        return axios({
            method: 'post',
            url: '/faq/faqDtModify',
            data: newFormData,
            params: {
                faqMstSeqNum: selectedFaqMst && selectedFaqMst.seqNum ? selectedFaqMst.seqNum : null,
                faqDtSeqNum: seqNum,
                fixFl: fixCheck,
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: LCAT
            },
        })
            .then(() => {
                navigate(`/btcorpad/adminfaq-detail/${seqNum}`);
            });
    }

    const valiAndSave = () => {
        if (validation()) {
            faqDtModify();
        }
    }

    const pageBack = (() => {
        navigate(-1);
    })

    //region 다크모드

    const [theme] = useTheme();

    //endregion

    return(
        <div id="m-a-faq-modi-pg-container" data-theme={theme}>
            <div className="m-a-common-header">
                <img onClick={pageBack} className="m-a-backIcon" src={back} alt="back"/>
                <div className="m-a-pg-header-text font-t-head">FAQ수정</div>
            </div>
            <div className="m-a-fixed-container">
                <div className="m-t-15 display-flex flex-column">
                    <div className="m-t-15 display-flex flex-column">
                        <div className="m-a-input-frame ht-40 m-b-15">
                            <input className="m-a-input wd-100pc font-t-title font-s-14" type="text" placeholder="제목" value={titleText} onChange={handleChangeTitleText}/>
                        </div>
                    </div>
                    <div className="display-flex flex-row just-cont-sb wd-100pc m-b-15 font-t-cont">
                        <div className="display-flex wd-70pc">
                            <div className="ht-40" ref={faqMstRef} style={{width: "100%", position: "relative"}}>
                                <button className="selectParentBt" style={{width: "100%", height: "100%"}}
                                        onClick={toggleFaqMstShow}>
                                    <p style={{margin: "0"}}>{selectedFaqMst.title || '구분선택'}</p>
                                    <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                                <i style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}><BiCaretDown/></i>
                                            </span>
                                </button>
                                {faqMstShow ?
                                    <div style={{width:"100%", border: "solid", borderWidth: "thin 1px", borderColor: "lightgray",
                                        zIndex:1000, marginTop: "5px", position: "relative"}}>
                                        <div onClick={()=> setMstAddShow(true)} className="selectChildBt" style={{
                                            display: "flex", padding: "10px", justifyContent: "center",
                                            alignItems: "center", cursor: "pointer", fontSize: "14px", height:"40px"
                                        }}>
                                            <p style={{margin:"0", fontSize:"14px"}}>구분추가</p>
                                            <div style={{position:"absolute", right:"10px", display:"flex"}}>
                                                <div className="user-center">
                                                    <img style={{width:"15px", height:"15px"}} alt="add" src={commonPlus}/>
                                                </div>
                                            </div>
                                        </div>
                                        {faqMstList.map((mst, index) => (
                                            <div className="selectChildBt" key={index} value={mst.title}
                                                 onClick={() => handleFaqMstChange(mst)} style={{
                                                display: "flex", padding: "10px", justifyContent: "center",
                                                alignItems: "center", cursor: "pointer", fontSize: "14px"
                                            }}>
                                                <p style={{margin:"0", fontSize:"14px"}}>{mst.title}</p>
                                                <div style={{position:"absolute", right:"5px", display:"flex", zIndex:1000}}>
                                                    <div onClick={()=> openMsgModifyShow(mst)} className="user-center">
                                                        <img style={{width:"22px", height:"22px"}} alt="edit" src={mstEdit}/>
                                                    </div>
                                                    <div onClick={()=> openMstDeleteMsg(mst)} className="user-center">
                                                        <img style={{width:"24px", height:"24px", marginLeft:"3px", zIndex:2}} alt="close" src={mstClose}/>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="display-flex flex-row just-cont-fend wd-30pc ht-40 align-center">
                            <p className="font-s-15 m-r-5">글 상단고정</p>
                            <input className="m-a-admin-input-chkBox m-t-1" id="AgreeCheckBox" type="checkbox" checked={fixCheck} onChange={selectedFixCheckBox}/>
                        </div>
                    </div>
                    <div>
                        <div className="m-a-textarea-frame m-b-15 font-s-14 font-t-cont ht-150" style={{minHeight: "auto", maxHeight: "300px"}}>
                            <textarea className="m-a-textarea-b" placeholder="내용입력" spellCheck="false" value={contentText} onChange={handleChangeContentText}/>
                        </div>
                    </div>
                    <div>
                        <div>
                            {photoContainers.map((container, idx) => (
                                <div key={idx} className="display-flex just-cont-center align-center border-c ht-400">
                                    {!container.photoImgSt ?
                                        <div className="display-block text-center" onClick={(e) => {
                                            const parentDiv = e.target.parentNode;
                                            const fileInput = parentDiv.querySelector('input[type="file"]');
                                            if (fileInput) fileInput.click();
                                        }}>
                                            <input type="file" accept="image/*" style={{display: "none"}} onChange={photoImgHandle(idx)}/>
                                            <img className="wd-70 ht-70" alt="uploadImg" style={{filter: "opacity(0.3) drop-shadow(0 0 0 gray)",}} src={container.photoImg}/>
                                            <p className="font-s-18 font-c-default">Click here!</p>
                                        </div>
                                        :
                                        <div className="display-flex wd-90pc ht-90pc position-relative" style={{maxHeight: "400px"}}>
                                            <img className="display-flex just-cont-center align-center wd-100pc ht-100pc" alt="uploadImg" src={container.photoImg}/>
                                            <button className="orderItemHandleBt position-absolute wd-40 ht-40 m-a-opc-06" style={{top:"85%", left:"86%", borderRadius:"4px"}}
                                                    onClick={photoImgInit(idx, container.photoImg)}>
                                                <i style={{display: "flex", justifyContent: "center", alignItems: "center", fontSize:"26px"}}>
                                                    <BsTrash className="display-flex just-cont-center align-center font-s-26"/>
                                                </i>
                                            </button>
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="m-a-m-fixed-bt-frame">
                <button className="m-a-colorBt-radius-n m-a-colorBt-text wd-100pc ht-45" onClick={valiAndSave}>저장</button>
            </div>
            { mstModifyShow ?
                <AdminMstEdit mstContent={mstContent} mstModifySeqNum={mstModifySeqNum} modifyFaqMst={modifyFaqMst} closeMsgModifyShow={closeMsgModifyShow}/>
                :
                mstDeleteMsgShow ?
                    <MsgSelectModal mstDeleteMsgShow={mstDeleteMsgShow} mstDeleteMsg1={mstDeleteMsg1} mstDeleteMsg2={mstDeleteMsg2}
                                    closeMstDeleteMsg={closeMstDeleteMsg} mstDeleteSeqNum={mstDeleteSeqNum} deleteFaqMst={deleteFaqMst} mstDeleteContent={mstDeleteContent}/>
                    :
                    mstAddShow ?
                        <AdminMstEdit mstAddShow={mstAddShow} saveFaqMst={saveFaqMst} closeMstAddShow={closeMstAddShow}/>
                        :
                        null
            }
        </div>
    )
}

export default MAdminFaqModifyPg
