import React from 'react';

function AdminPageEvent({perPg, handleSelectChange}) {
    return (
        <div className="font-t-cont m-r-15">
            <select value={perPg} onChange={handleSelectChange} className="admin-combo wd-60 ht-30 p-l-7 font-s-14">
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
        </div>
    );
}

export default AdminPageEvent
