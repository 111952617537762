import { Stomp } from "@stomp/stompjs";
import SockJS from 'sockjs-client';

export const socketUrl = 'https://btorage.com/api/websocket';

export const createClient = () => {
    const socketFactory = () => new SockJS(socketUrl);
    const stompClient = Stomp.over(socketFactory);

    // Nginx의 클라이언트/서버간 유지시간을 위해 웹소켓 연결상태 유지
    stompClient.heartbeat.outgoing = 50000; // 50초마다 ping 전송
    stompClient.heartbeat.incoming = 50000; // 50초마다 pong 수신

    return stompClient;
};

export const connect = (key, onConnected, onError = () => {}) => {
    const stompClient = createClient();

    stompClient.connect({ key }, () => {
        onConnected(stompClient);
    }, onError);

    return stompClient;
};
