import React from "react";
import commonClose from "../../../assets/img/commonClose.png";
import commonTerms from "../../../assets/img/commonTerms.png";
import {useTranslation} from "react-i18next";

function CommonTermsPopup({ termsOfUsePopup, closeTermsOfUsePopup, privacyPolicyPopup, closePrivacyPolicyPopup, cartTermsPopup, closeCartTermsPopup, orderTermsPopup, closeOrderTermsPopup,
                              offlineTermsPopup, closeOfflineTermsPopup, transferTermsPopup, closeTransferTermsPopup, domesticTermsPopup, closeDomesticTermsPopup,
                              deliveryTermsPopup, closeDeliveryTermsPopup,
                          }) {

    const { t } = useTranslation();

    return(
        <div className="position-fixed wd-100pc ht-100pc display-flex just-cont-center align-center"
             style={{top:"0", left:"0", zIndex:1000, background:"rgba(0, 0, 0, 0.3)"}}>
            <div style={{width:"800px", background:"white", boxShadow:"0 0 10px rgba(0, 0, 0, 0.5)", borderRadius:"6px"}}>
                <div style={{borderBottom:"solid 1px #EAEAEA", height:"70px", display:"flex", padding:"10px"}}>
                    <div className="ht-100pc display-flex just-cont-center align-center" style={{width:"6%"}}>
                        <img className="wd-30 ht-30" alt='' src={commonTerms}/>
                    </div>
                    <div style={{height:"100%", width:"84%", display:"flex", alignItems:"center"}}>
                        <p className="font-t-title font-s-18 m-t-2">
                            {
                                termsOfUsePopup ? t('ctpTuHd') :
                                    privacyPolicyPopup ? t('ctpPpHd') :
                                        cartTermsPopup ? t('ctpCartHd') :
                                            orderTermsPopup ? t('ctpOrdHd') :
                                                offlineTermsPopup ? t('ctpOffHd') :
                                                    transferTermsPopup ? t('ctpTrHd') :
                                                        domesticTermsPopup ? t('ctpDsHd') :
                                                            deliveryTermsPopup ? t('ctpDvHd') :
                                                                null
                            }
                        </p>
                    </div>
                    <div className="user-center wd-10pc ht-100pc">
                        <img className="wd-30 ht-30 m-a-opc-07 c-point" onClick={termsOfUsePopup ? closeTermsOfUsePopup : privacyPolicyPopup ? closePrivacyPolicyPopup : cartTermsPopup ? closeCartTermsPopup :
                        orderTermsPopup ? closeOrderTermsPopup : offlineTermsPopup ? closeOfflineTermsPopup : transferTermsPopup ? closeTransferTermsPopup :
                        domesticTermsPopup ? closeDomesticTermsPopup : deliveryTermsPopup ? closeDeliveryTermsPopup : null} src={commonClose} alt="close"/>
                    </div>
                </div>
                {/*내용*/}
                <div className="font-t-cont font-s-13" style={{height:"600px", padding:"20px"}}>
                    <div style={{overflowY:"scroll", height:"100%"}}>
                        { termsOfUsePopup ?
                            <div>
                                <div>
                                    <p className="font-b">{t('ctpTuHd1')}</p>
                                    <p style={{whiteSpace: "pre-line"}}>{t('ctpTuCont1')}</p>
                                </div>
                                <div style={{marginTop:"20px"}}>
                                    <p className="font-b">{t('ctpTuHd2')}</p>
                                    <p style={{whiteSpace: "pre-line"}}>{t('ctpTuCont2')}</p>
                                </div>
                                <div style={{marginTop:"20px"}}>
                                    <p className="font-b">{t('ctpTuHd3')}</p>
                                    <p style={{whiteSpace: "pre-line"}}>{t('ctpTuCont3')}</p>
                                </div>
                                <div style={{marginTop:"20px"}}>
                                    <p className="font-b">{t('ctpTuHd4')}</p>
                                    <p style={{whiteSpace: "pre-line"}}>{t('ctpTuCont4')}</p>
                                </div>
                                <div style={{marginTop:"20px"}}>
                                    <p className="font-b">{t('ctpTuHd5')}</p>
                                    <p style={{whiteSpace: "pre-line"}}>{t('ctpTuCont5')}</p>
                                </div>
                                <div style={{marginTop:"20px"}}>
                                    <p className="font-b">{t('ctpTuHd6')}</p>
                                    <p style={{whiteSpace: "pre-line"}}>{t('ctpTuCont6')}</p>
                                </div>
                                <div style={{marginTop:"20px"}}>
                                    <p className="font-b">{t('ctpTuHd7')}</p>
                                    <p style={{whiteSpace: "pre-line"}}>{t('ctpTuCont7')}</p>
                                </div>
                                <div style={{marginTop:"20px"}}>
                                    <p className="font-b">{t('ctpTuHd8')}</p>
                                    <p style={{whiteSpace: "pre-line"}}>{t('ctpTuCont8')}</p>
                                </div>
                                <div style={{marginTop:"20px"}}>
                                    <p className="font-b">{t('ctpTuHd9')}</p>
                                    <p style={{whiteSpace: "pre-line"}}>{t('ctpTuCont9')}</p>
                                </div>
                                <div style={{marginTop:"20px"}}>
                                    <p className="font-b">{t('ctpTuHd10')}</p>
                                    <p style={{whiteSpace: "pre-line"}}>{t('ctpTuCont10')}</p>
                                </div>
                                <div style={{marginTop:"20px"}}>
                                    <p className="font-b">{t('ctpTuHd11')}</p>
                                    <p style={{whiteSpace: "pre-line"}}>{t('ctpTuCont11')}</p>
                                </div>
                                <div style={{marginTop:"20px"}}>
                                    <p className="font-b">{t('ctpTuHd12')}</p>
                                    <p style={{whiteSpace: "pre-line"}}>{t('ctpTuCont12')}</p>
                                </div>
                                <div style={{marginTop:"20px"}}>
                                    <p className="font-b">{t('ctpTuHd13')}</p>
                                    <p style={{whiteSpace: "pre-line"}}>{t('ctpTuCont13')}</p>
                                </div>
                                <div style={{marginTop:"20px"}}>
                                    <p className="font-b">{t('ctpTuHd14')}</p>
                                    <p style={{whiteSpace: "pre-line"}}>{t('ctpTuCont14')}</p>
                                </div>
                                <div style={{marginTop:"20px"}}>
                                    <p className="font-b">{t('ctpTuHd15')}</p>
                                    <p style={{whiteSpace: "pre-line"}}>{t('ctpTuCont15')}</p>
                                </div>
                                <div style={{marginTop:"20px"}}>
                                    <p className="font-b">{t('ctpTuHd16')}</p>
                                    <p style={{whiteSpace: "pre-line"}}>{t('ctpTuCont16')}</p>
                                </div>
                                <div style={{marginTop:"20px"}}>
                                    <p className="font-b">{t('ctpTuHd17')}</p>
                                    <p style={{whiteSpace: "pre-line"}}>{t('ctpTuCont17')}</p>
                                </div>
                                <div style={{marginTop:"20px"}}>
                                    <p className="font-b">{t('ctpTuHd18')}</p>
                                    <p style={{whiteSpace: "pre-line"}}>{t('ctpTuCont18')}</p>
                                </div>
                                <div style={{marginTop:"20px"}}>
                                    <p className="font-b">{t('ctpTuHd19')}</p>
                                    <p style={{whiteSpace: "pre-line"}}>{t('ctpTuCont19')}</p>
                                </div>
                                <div style={{marginTop:"20px"}}>
                                    <p className="font-b">{t('ctpTuHd20')}</p>
                                    <p style={{whiteSpace: "pre-line"}}>{t('ctpTuCont20')}</p>
                                </div>
                                <div style={{marginTop:"20px"}}>
                                    <p className="font-b">{t('ctpTuHd21')}</p>
                                    <p style={{whiteSpace: "pre-line"}}>{t('ctpTuCont21')}</p>
                                </div>
                            </div>
                            :
                            privacyPolicyPopup ?
                                <div>
                                    <p>{t('ctpPpcont')}</p>
                                    <div style={{marginTop:"20px"}}>
                                    <p className="font-b">{t('ctpPpHd1')}</p>
                                    <p style={{whiteSpace: "pre-line"}}>{t('ctpPpCont1')}</p>
                                    </div>
                                    <div style={{marginTop:"20px"}}>
                                        <p className="font-b">{t('ctpPpHd2')}</p>
                                        <p style={{whiteSpace: "pre-line"}}>{t('ctpPpCont2')}</p>
                                    </div>
                                    <div style={{marginTop:"20px"}}>
                                        <p className="font-b">{t('ctpPpHd3')}</p>
                                        <p style={{whiteSpace: "pre-line"}}>{t('ctpPpCont3')}</p>
                                    </div>
                                    <div style={{marginTop:"20px"}}>
                                        <p className="font-b">{t('ctpPpHd4')}</p>
                                        <p style={{whiteSpace: "pre-line"}}>{t('ctpPpCont4')}</p>
                                    </div>
                                    <div style={{marginTop:"20px"}}>
                                        <p className="font-b">{t('ctpPpHd5')}</p>
                                        <p style={{whiteSpace: "pre-line"}}>{t('ctpPpCont5')}</p>
                                    </div>
                                </div>
                                :
                                <p style={{whiteSpace: "pre-line"}}>
                                    { cartTermsPopup ? t('ctpCartCont') :
                                        orderTermsPopup ? t('ctpOrdCont') :
                                            offlineTermsPopup ? t('ctpOffCont') :
                                                transferTermsPopup ? t('ctpTrCont') :
                                                    domesticTermsPopup ? t('ctpDsCont') :
                                                        deliveryTermsPopup ? t('ctpDvCont') : null
                                    }
                                </p>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommonTermsPopup
