import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import '../../css/mobile/MAdminLoginPg.css';
import btorageLogo from '../../assets/img/HeaderLogo.png'
import {useDispatch} from "react-redux";
import {setUserInfo} from "../../store/UserSlice";
import MMsgCheckModal from "../../components/mobile/MMsgCheckModal";
import imgLogo from "../../assets/img/btorage_only_img_logo.png";

function MAdminLoginPg() {

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [id, setId] = useState("");
    const [password, setPassword] = useState("");
    const [loginErrorMsg, setLoginErrorMsg] = useState(false);
    const [lgWaitingSt, setLgWaitingSt] = useState(false);
    const lgRegiWaitingMsg = "가입승인이 완료되지 않은 계정입니다."
    const [isLoading, setIsLoading] = useState(false);

    const handleChange_email = (e) => {
        setId(e.target.value);
    }
    const handleChange_password = (e) => {
        setPassword(e.target.value);
    }

    const confirmModalCloseFn = (() => {
        setLgWaitingSt(false);
        setIsLoading(false);
    })

    const loginCheck = async () => {
        setIsLoading(true);
        if (!id || !password) {
            setLoginErrorMsg(true);
            setIsLoading(false);
        }
        try {
            const joinVerResponse = await axios.get("/member/joinVerFl/" + id);
            if (joinVerResponse.data === "N") {
                setLgWaitingSt(true);
            } else if (joinVerResponse.data === "Y") {
                const loginResponse = await axios.post("/member/login", {
                    id: id,
                    password: password,
                }, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                const userInformation = {
                    ...loginResponse.data,
                    token: loginResponse.headers['authorization'],
                    id: loginResponse.data.id,
                    userType: loginResponse.data.userType,
                };

                localStorage.setItem('adminToken', loginResponse.headers.get('authorization'));
                localStorage.setItem('adminLastLoginTime', new Date().toISOString());

                dispatch(setUserInfo(userInformation));
                navigate("/btcorpad");
            }
        } catch (error) {
            setLoginErrorMsg(true);
        }
    };

    return(
        <div id="m-a-login-pg-container">
            <div className="m-a-fixed-container">
                <div className="m-a-login-container">
                    <div className="m-a-logo-frame m-b-45">
                        <img alt="mainLogo" style={{width:"24px", height:"35px", marginRight: "10px"}} src={imgLogo}/>
                        <strong className="font-t-head font-s-24 m-t-8">Administrator Login</strong>
                    </div>
                    <div className="m-a-idpw-container">
                        <div className="m-a-input-frame ht-45 m-a-input-frame-mgb5 wd-100pc">
                            <input onChange={handleChange_email} className="m-a-input font-t-cont" placeholder="이메일"/>
                        </div>
                        <div className="m-a-input-frame ht-45 wd-100pc">
                            <input type="password" onChange={handleChange_password} className="m-a-input font-t-cont" placeholder="비밀번호"/>
                        </div>
                    </div>
                    {loginErrorMsg ?
                        <div className="m-a-error-msg-container m-t-7">
                            <div className="m-a-error-msg font-t-cont font-s-14 p-l-7"><p className="font-c-accent">아이디 및 비밀번호를 확인해주세요</p></div>
                        </div>
                        :
                        null
                    }
                    <div className="m-a-join-findPw-container m-t-10">
                        <div onClick={()=> navigate("/btcorpad-join")} className="m-a-join-text font-t-cont text-active">회원가입</div>
                        <div className="m-a-vertical-solid"></div>
                        <div onClick={()=> navigate("/btcorpad-pwFind")} className="m-a-findPw-text font-t-cont text-active">비밀번호찾기</div>
                    </div>
                    <div className="m-a-colorBt-container">
                        <button className="m-a-colorBt ht-45 m-b-5 wd-100pc">
                            <style>
                                {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
                            </style>
                            {isLoading ? (
                                <div className="user-center">
                                <div className="spinner"></div>
                                </div>
                            ) : (
                                <div onClick={loginCheck} className="m-a-colorBt-text font-t-cont">로그인</div>
                            )}
                        </button>
                    </div>
                    {lgWaitingSt ? <MMsgCheckModal lgWaitingSt={lgWaitingSt} confirmModalCloseFn={confirmModalCloseFn} lgRegiWaitingMsg={lgRegiWaitingMsg}/> : null}
                </div>
            </div>
        </div>
    )
}

export default MAdminLoginPg
