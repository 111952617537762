import React, {useEffect, useRef, useState} from "react";
import commonClose from "../../../assets/img/commonClose.png";
import {BiCaretDown} from "react-icons/bi";

function AdminDelAddModal({ paypalExAddShow, closePaypalExAddShow, saveData }) {

    const [payExr, setPayExr] = useState('');
    const [payRate, setPayRate] = useState('');

    const [curPriceErrorMsg, setCurPriceErrorMsg] = useState(false);
    const [payExrErrorMsg, setPayExrErrorMsg] = useState(false);
    const [payRateErrorMsg, setPayRateErrorMsg] = useState(false);

    const handlePayRate = ((e) => {
        setPayRate(e);
        if (e.length > 0) {
            setPayRateErrorMsg(false);
        } else {
            setPayRateErrorMsg(true);
        }
    })

    const handlePayExr = ((e) => {
        setPayExr(e);
        if (e.length > 0) {
            setPayExrErrorMsg(false);
        } else {
            setPayExrErrorMsg(true);
        }
    })


    // 여기서부터 커스텀 셀렉트
    const [curPriceShow, setCurPriceShow] = useState(false);
    const [curPriceList, setCurPriceList] = useState(["USD", "CAD", "AUD", "JPY", "EUR", "SGD", "CNY", "NZD"]);
    const [selectedCurPrice, setSelectedCurPrice] = useState('');
    const curPriceRef = useRef(null);

    const toggleCurPriceShow = () => {
        setCurPriceShow(!curPriceShow);
    };

    const handleCurPriceChange = (curPrice) => {
        setSelectedCurPrice(curPrice);
        setCurPriceShow(false);
        setCurPriceErrorMsg(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (curPriceRef.current && !curPriceRef.current.contains(event.target)) {
                setCurPriceShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트

    const validation = (selectedCurPrice, payExr, payRate) => {
        let isValid = true;

        if (!selectedCurPrice || selectedCurPrice.trim() === "" || selectedCurPrice === "통화선택") {
            setCurPriceErrorMsg(true);
            isValid = false;
        } else {
            setCurPriceErrorMsg(false);
        }

        if (!payExr || payExr.trim() === "") {
            setPayExrErrorMsg(true);
            isValid = false;
        } else {
            setPayExrErrorMsg(false);
        }

        if (!payRate || payRate.trim() === "") {
            setPayRateErrorMsg(true);
            isValid = false;
        } else {
            setPayRateErrorMsg(false);
        }

        if (isValid) {
            saveData(selectedCurPrice, payExr, payRate);
        }
    }



    return(
        <div className="bookContainer font-t-cont" style={{display: "flex", zIndex:"10"}}>
            <div className="bookFrame" style={{
                minWidth: "400px",
                height: "500px",
                position: "fixed",
                top: "45%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "5px"
            }}>
                <div style={{width: "100%", height: "100%"}}>
                    {/*상세헤더*/}
                    <div style={{height: "45px"}}>
                        <div className="user-center" style={{
                            height: "100%",
                            fontSize: "23px",
                            border: "solid",
                            borderWidth: "thin 1px",
                            color: "lightgray",
                        }}>

                            <div className="user-center" style={{width: "100%", height:"100%"}}>
                                <div className="user-center">
                                    <p style={{
                                        margin:"0",
                                        height:"100%",
                                        color: "dimgrey",
                                        fontSize: "16px",
                                        width: "100%"
                                    }}>ㄱㄱㄱㄱㄱㄱ등록</p>
                                </div>
                            </div>
                            <div style={{width: "40px", position:"absolute", right:"3px", top:"2px", textAlign: "center", cursor:"pointer"}}>
                                <img onClick={closePaypalExAddShow} style={{width:"30px", height:"30px", opacity:"0.6"}} alt="closeImage" src={commonClose}/>
                            </div>

                        </div>
                    </div>
                    {/*상세바디*/}
                    <div className="user-center" style={{minHeight:"150px", display:"flex", flexDirection:"column"}}>
                        <div style={{width:"100%", height:"100%", padding:"50px"}}>
                            {/*통화*/}
                            <div style={{width: "100%", height: "60px"}}>
                                {/*여기서부터 커스텀 통화셀렉트*/}
                                <div ref={curPriceRef} style={{height: "100%", width: "100%", position: "relative"}}>
                                    <button className="selectParentBt" style={{width: "100%", height: "100%"}}
                                            onClick={toggleCurPriceShow}>
                                        <p style={{margin: "0"}}>{selectedCurPrice || "통화선택"}</p>
                                        <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                <i style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%"
                                }}><BiCaretDown/></i>
                            </span>
                                    </button>
                                    {curPriceShow ?
                                        <div style={{
                                            width:"100%",
                                            border: "solid",
                                            borderWidth: "thin 1px",
                                            borderColor: "lightgray",
                                            position: "absolute",
                                            zIndex:1,
                                            marginTop: "5px",
                                        }}>
                                            {curPriceList.map((cur, index) => (
                                                <div className="selectChildBt" key={index} value={cur}
                                                     onClick={() => handleCurPriceChange(cur)} style={{
                                                    display: "flex", padding: "10px", justifyContent: "center",
                                                    alignItems: "center", cursor: "pointer", fontSize: "14px",
                                                }}>{cur}</div>
                                            ))}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                {/*여기까지 커스텀 통화셀렉트*/}
                            </div>
                            { curPriceErrorMsg &&
                                <div className="JoinErrorMsg">
                                    <p className="JoinErrMsg">&nbsp;통화를 선택해주세요</p>
                                </div>
                            }
                            <div className="user-input-container" style={{width:"100%", height:"50px", marginTop:"5px"}}>
                                <input className="user-input" style={{width:"100%", height:"100%", padding:"0 10px 0 10px", fontSize:"14px"}}
                                       value={payExr} onChange={(e)=> handlePayExr(e.target.value)} placeholder="환율" onKeyDown={(e) => {if(e.key === 'Enter') {
                                    saveData();
                                }
                                }}
                                />
                            </div>
                            { payExrErrorMsg &&
                                <div className="JoinErrorMsg">
                                    <p className="JoinErrMsg">&nbsp;환율을 입력해주세요</p>
                                </div>
                            }
                            <div className="user-input-container" style={{width:"100%", height:"50px", marginTop:"5px"}}>
                                <input className="user-input" style={{width:"100%", height:"100%", padding:"0 10px 0 10px", fontSize:"14px"}}
                                       value={payRate} onChange={(e)=> handlePayRate(e.target.value)} placeholder="요율(%)" onKeyDown={(e) => {if(e.key === 'Enter') {
                                    validation(selectedCurPrice, payExr, payRate);
                                }
                                }}
                                />
                            </div>

                            { payRateErrorMsg &&
                                <div className="JoinErrorMsg">
                                    <p className="JoinErrMsg">&nbsp;요율을 입력해주세요</p>
                                </div>
                            }
                            <div className="user-center" style={{marginTop:"20px"}}>
                                <button onClick={()=> validation(selectedCurPrice, payExr, payRate)} className="colorFullBt" style={{
                                    width: "80px",
                                    height: "40px",
                                    textAlign: "center",
                                    fontSize: "15px",
                                    opacity: "0.6",
                                    marginLeft:"5px"
                                }}>등록
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminDelAddModal
