import React, {useEffect, useRef, useState} from "react";
import commonHeader from "../../assets/img/commonHeader.png";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import reviewUpload from "../../assets/img/reviewUpload.png";
import commonClose from "../../assets/img/commonClose.png";
import {BiCaretDown} from "react-icons/bi";
import {BsTrash} from "react-icons/bs";

function AdminNoticeModifyPg() {

    const navigate = useNavigate();
    const {seqNum} = useParams();
    const LCAT = localStorage.getItem('adminToken');

    const [adminInfo, setAdminInfo] = useState("");
    const findMember = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data)
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (LCAT) {
            findMember();
        }
    }, [LCAT])

    const [langList, setLangList] = useState(["Korean", "English", "Japanese", "Chinese", "Indonesian"]);
    const [selectedLang, setSelectedLang] = useState('');
    const [langShow, setLangShow] = useState(false);
    const langRef = useRef(null);

    const toggleLangShow = () => {
        setLangShow(!langShow);
    };

    const handleLangChange = (mst) => {
        setSelectedLang(mst);
        setLangShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (langRef.current && !langRef.current.contains(event.target)) {
                setLangShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // copy
    const [titleErrorMsg, setTitleErrorMsg] = useState(false);
    const [contentErrorMsg, setContentErrorMsg] = useState(false);
    const [langErrorMsg, setLangErrorMsg] = useState(false);


    const [titleText, setTitleText] = useState('');
    const handleChangeTitleText = (e) => {
        setTitleText(e.target.value);
    };

    const [contentText, setContentText] = useState('');
    const handleChangeContentText = (e) => {
        setContentText(e.target.value);
    };

    const [photoContainers, setPhotoContainers] = useState([{ photoImg: reviewUpload, photoImgSt: false, photoImgError: false, photoImgOriNm: '' }]);
    const [photoRemoveContainers, setPhotoRemoveContainers] = useState([{ removeImg: ''}]);
    const selectNoticeData = () =>
        axios({
            method: 'get',
            url: '/notice/selectNoticeData',
            headers: {
                Authorization: LCAT
            },
            params: {
                seqNum: seqNum
            }
        })
            .then((response) => {
                const data = response.data;
                setTitleText(data.title);
                setContentText(data.content);
                setFixCheck(data.fixFl);
                setSelectedLang(convertToLang(data.lang))

                const noticePhotos = data.noticePhotoDTO;
                if (noticePhotos && noticePhotos.length > 0) {
                    setPhotoContainers(prev => {
                        // 기존 사진 데이터를 포함한 배열 생성
                        const updatedContainers = noticePhotos.map(photo => ({
                            photoImg: "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/writePhoto/" + photo.fileNm,
                            photoImgSt: true,
                            photoImgError: false,
                            photoImgFileOriNm: photo.fileOriNm
                        }));

                        // 마지막에 빈 폼 추가
                        updatedContainers.push({ photoImg: reviewUpload, photoImgSt: false, photoImgError: false });

                        return updatedContainers;
                    });
                }
            })
            .catch(() => {
                console.log("리뷰조회실패")
            })

    useEffect(() => {
        if (seqNum) {
            selectNoticeData();
        }
    }, [seqNum])


    const photoImgHandle = (idx) => (e) => {
        const file = e.target.files[0];

        if (file) {
            setPhotoContainers((prev) => {
                const newContainers = [...prev];

                if (!file.type.startsWith('image/')) {
                    newContainers[idx] = {
                        ...newContainers[idx],
                        photoImgError: true,
                    };
                    return newContainers;
                } else {
                    newContainers[idx] = {
                        ...newContainers[idx],
                        photoImg: URL.createObjectURL(file),
                        photoImgSt: true,
                        photoImgError: false,
                        file: file
                    };
                    if (idx === newContainers.length - 1) {
                        addForm(newContainers);
                    }
                    return newContainers;
                }
            });
        }
    };

    const formData = new FormData();

    const photoImgInit = (idx, photoImg) => () => {
        setPhotoRemoveContainers(prev => [...prev, { removeImg: photoImg }]);
        setPhotoContainers((prev) => {
            const newContainers = [...prev];

            newContainers.splice(idx, 1);

            const lastContainer = newContainers[newContainers.length - 1];
            if (lastContainer && lastContainer.photoImgSt) {
                newContainers.push({ photoImg: reviewUpload, photoImgSt: false, photoImgError: false });
            }

            return newContainers;
        });
        formData.delete('photoImg');
    };


    const addForm = (newContainers) => {
        const lastContainer = newContainers[newContainers.length - 1];
        if (!lastContainer.photoImgSt) {
            return;
        }

        newContainers.push({ photoImg: reviewUpload, photoImgSt: false, photoImgError: false });
    };

    const validation = () => {
        if (titleText === '' || !titleText) setTitleErrorMsg(true);
        if (contentText === '' || !contentText) setContentErrorMsg(true);
        if (selectedLang === '' || !selectedLang) setLangErrorMsg(true);
        return !!(titleText && contentText && selectedLang)
    }

    useEffect(() => {
        if (titleText.length > 0) {
            setTitleErrorMsg(false);
        } if (contentText.length > 0) {
            setContentErrorMsg(false);
        } if (!selectedLang || selectedLang !== '') {
            setLangErrorMsg(false);
        }
    }, [titleText, contentText, selectedLang])

    const convertToLang = ((lang) => {
        if (lang === "0") return "Korean"
        if (lang === "1") return "English"
        if (lang === "2") return "Japanese"
        if (lang === "3") return "Chinese"
        if (lang === "4") return "Indonesian"
    })

    const noticeModify = async () => {

        const lang = selectedLang === "Korean" ? 0 : selectedLang === "English" ? 1
            : selectedLang === "Japanese" ? 2 : selectedLang === "Chinese" ? 3 : 4

        const newFormData = new FormData();
        newFormData.append('title', titleText);
        newFormData.append('contentText', contentText);

        photoContainers.forEach((container, i) => {
            if (container.file) {
                newFormData.append('photoImg', container.file);
            } else {
                newFormData.append('photoImg', container.photoImg);
            }
        });

        photoRemoveContainers.forEach((remove, i) => {
            if (remove.removeImg) {
                const filename = remove.removeImg.split('/').pop(); // URL에서 파일 이름만 추출
                newFormData.append('removeImg', encodeURIComponent(filename));
            }
        });

        return axios({
            method: 'post',
            url: '/notice/noticeModify',
            data: newFormData,
            params: {
                noticeSeqNum: seqNum,
                fixFl: fixCheck,
                lang: lang
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: LCAT
            },
        })
            .then(() => {
                navigate(`/btcorpad/adminnotice-detail/${seqNum}`);
            });
    }

    const valiAndSave = () => {
        if (validation()) {
            noticeModify();
        }
    }

    const [fixCheck, setFixCheck] = useState(false);
    const selectedFixCheckBox = (() => {
        setFixCheck(!fixCheck);
    })
    const moveToReturn = (() => {
        navigate(`/btcorpad/adminnotice-detail/${seqNum}`);
    })

    return(
        <div className="AdminNoticePg">
            <div className="admin-work-title">
                <h5>공지사항수정</h5>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        <div style={{width:"100%"}}>
                            <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                            <div style={{height:"50px", display:"flex"}}>
                                <input id="AgreeCheckBox" type="checkbox" style={{width:"20px", height:"20px", marginTop:"4px", cursor:"pointer"}}
                                       checked={fixCheck} onChange={selectedFixCheckBox}
                                />
                                <label style={{display:"flex", marginLeft:"10px", cursor:"pointer"}} htmlFor="AgreeCheckBox">
                                    <span style={{fontSize:"17px"}}>공지고정</span>
                                </label>
                            </div>

                                <div style={{height:"40px", marginBottom:"20px", display:"flex", justifyContent:"flex-end"}}>
                                    <img onClick={moveToReturn} alt="close" style={{width:"35px", height:"35px", opacity:"0.6", cursor:"pointer"}} src={commonClose}/>
                                </div>
                            </div>
                                {/*copy*/}
                            <div>
                                <div style={{height: "10%", display: "flex"}}>
                                    <div style={{width: "100%", paddingRight: "5px"}}>
                                        <div className="ReviewPgSearchFrame acc-icon">
                                            <div className="ReviewPgInputForm" style={{width: "97%"}}>
                                                <input className="ReviewInput user-input"
                                                       style={{width: "100%", marginLeft: "10px", height: "100%"}}
                                                       type="text"
                                                       placeholder="" value={titleText}
                                                       onChange={handleChangeTitleText}/>
                                                <label className="JoinLabel" style={{fontSize: "14px"}}>제목</label>
                                            </div>
                                        </div>
                                    </div>

                                    {/*Lang*/}
                                    <div style={{width: "25%", height: "55px", marginLeft: "5px"}}>
                                        {/*여기서부터 커스텀 Lang셀렉트*/}
                                        <div ref={langRef}
                                             style={{height: "100%", width: "100%", position: "relative"}}>
                                            <button className="selectParentBt"
                                                    style={{width: "100%", height: "100%"}}
                                                    onClick={toggleLangShow}>
                                                <p style={{margin: "0"}}>{selectedLang === "" ? "배너언어" : selectedLang}</p>
                                                <span style={{
                                                    position: "absolute",
                                                    left: "80%",
                                                    top: "0",
                                                    height: "100%",
                                                    zIndex: 10,

                                                    width: "20%"
                                                }}>
                                <i style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%"
                                }}><BiCaretDown/></i>
                            </span>
                                            </button>
                                            {langShow ?
                                                <div style={{
                                                    width: "100%",
                                                    height: "200px",
                                                    overflowY: "scroll",
                                                    border: "solid",
                                                    borderWidth: "thin 1px",
                                                    borderColor: "lightgray",
                                                    position: "absolute",
                                                    top: "100%",
                                                    bottom: "100%",
                                                    zIndex: 1,
                                                    marginTop: "5px",
                                                }}>
                                                    {langList.map((lang, index) => (
                                                        <div className="selectChildBt" key={index} value={lang}
                                                             onClick={() => handleLangChange(lang)} style={{
                                                            display: "flex",
                                                            padding: "10px",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            cursor: "pointer",
                                                            fontSize: "14px",
                                                        }}>{lang}</div>
                                                    ))}
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                        {/*여기까지 커스텀 Lang셀렉트*/}

                                    </div>

                                </div>

                                {titleErrorMsg ?
                                    <div className="JoinErrorMsg">
                                        <p className="JoinErrMsg">&nbsp;제목을 입력해주세요</p>
                                    </div>
                                    :
                                    null
                                }

                                {langErrorMsg ?
                                    <div className="JoinErrorMsg">
                                        <p className="JoinErrMsg">&nbsp;언어를 선택해주세요</p>
                                    </div>
                                    :
                                    null
                                }

                                <div style={{height: "auto", display: "flex", marginTop: "20px"}}>

                                    <div style={{width: "65%"}}>
                                        <div className="ordChatInputContainer"
                                             style={{height: "100%", minHeight: "600px"}}>
                    <textarea
                        placeholder="내용"
                        spellCheck="false"
                        value={contentText}
                        style={{
                            borderRadius: "6px",
                            height: "100%",
                            width: "100%",
                            borderWidth: "thin 1px",
                            borderColor: "lightgray",
                            resize: "none",
                            overflow: "auto",
                            outline: "none",
                            color: "dimgrey",
                            padding: "18px",
                            fontSize: "14px",
                            ':focus': {
                                borderColor: "#FFAF34"
                            },
                        }} onChange={handleChangeContentText}/>
                                        </div>
                                        <div className="JoinErrorMsg">
                                            {contentErrorMsg && <p className="JoinErrMsg">&nbsp;내용을 입력해주세요</p>}
                                        </div>
                                    </div>
                                    <div style={{width: "35%", paddingLeft: "10px"}}>
                                        {photoContainers.map((container, idx) => (
                                            <div key={idx} style={{
                                                border: "solid",
                                                height: "305px",
                                                borderWidth: "thin 1px",
                                                borderColor: "lightgray",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                {!container.photoImgSt ?
                                                    <div style={{display: "block", textAlign: "center"}}
                                                         onClick={(e) => {
                                                             const parentDiv = e.target.parentNode;
                                                             const fileInput = parentDiv.querySelector('input[type="file"]');
                                                             if (fileInput) fileInput.click();
                                                         }}>
                                                        <input type="file" accept="image/*" style={{display: "none"}}
                                                               onChange={photoImgHandle(idx)}/>
                                                        <img alt="uploadImg" style={{
                                                            width: "80px",
                                                            height: "80px",
                                                            padding: "10px",
                                                            filter: "opacity(0.2) drop-shadow(0 0 0 gray)",
                                                        }} src={container.photoImg}/>
                                                        <p style={{
                                                            color: "rgba(112,112,109,0.67)",
                                                            fontSize: "18px"
                                                        }}>Click me!</p>
                                                        <div className="JoinErrorMsg">
                                                            {container.photoImgError &&
                                                                <p className="JoinErrMsg">&nbsp;사진만 업로드해주세요😅</p>}
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="user-center" style={{
                                                        width: "90%",
                                                        display: "flex",
                                                        height: "90%",
                                                        position: "relative"
                                                    }}>
                                                        <img alt="photoImg" style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            maxWidth: "100%",
                                                            maxHeight: "100%",
                                                        }} src={container.photoImg}/>
                                                        <button className="orderItemHandleBt" style={{
                                                            position: "absolute",
                                                            width: "40px",
                                                            height: "40px",
                                                            top: "85%",
                                                            left: "90%",
                                                            borderRadius: "4px",
                                                            opacity: "0.6"
                                                        }} onClick={photoImgInit(idx, container.photoImg)}>
                                                            <i style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                fontSize: "26px"
                                                            }}><BsTrash/></i>
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div style={{
                                    height: "100px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <button className="orderItemHandleBt"
                                            style={{width: "100px", height: "50px", borderRadius: "4px"}}
                                            onClick={valiAndSave}>수정완료
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminNoticeModifyPg
