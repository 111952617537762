import React, {useEffect, useState} from 'react';
import '../../css/mobile/MAdmin.css';
import axios from "axios";
import {BsSquareFill} from "react-icons/bs";
import maCateImg from "../../assets/img/m-a-cate.png";
import MAdminYmdSmallEvent from "./MAdminYmdSmallEvent";
import ApexCharts from 'react-apexcharts';

function MAdminSalesStatusPg() {

    /** region DataTable */

    const [salesData, setSalesData] = useState([]);
    const [salesChartData, setSalesChartData] = useState([]);

    /** endregion */

    /** region Function */

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const startHandle = (e) => {
        setStartDate(e.target.value);
    };
    const endHandle = (e) => {
        setEndDate(e.target.value);
    };

    //region slide-card

    useEffect(() => {
        const sliderWrapper = document.querySelector('.m-a-slider-wrapper');
        if (!sliderWrapper) { return; }

        let isDragging = false;
        let startPosition = 0;
        let currentTranslate = 0;
        let prevTranslate = 0;
        let animationID = 0;
        let currentIndex = 0;

        const slides = document.querySelectorAll('.m-a-card-slider');
        const slideWidth = sliderWrapper.clientWidth;
        const indicators = document.querySelectorAll('.m-a-indicator');

        sliderWrapper.addEventListener('touchstart', touchStart);
        sliderWrapper.addEventListener('touchend', touchEnd);
        sliderWrapper.addEventListener('touchmove', touchMove);

        function touchStart(event) {
            isDragging = true;
            startPosition = event.touches[0].clientX;
            animationID = requestAnimationFrame(animation);
        }

        function touchEnd() {
            isDragging = false;
            cancelAnimationFrame(animationID);

            const movedBy = currentTranslate - prevTranslate;

            if (movedBy < -50 && currentIndex < slides.length - 1) {
                currentIndex += 1;
            } else if (movedBy > 50 && currentIndex > 0) {
                currentIndex -= 1;
            }

            setPositionByIndex();
            updateIndicators();
        }

        function touchMove(event) {
            if (isDragging) {
                const currentPosition = event.touches[0].clientX;
                currentTranslate = prevTranslate + currentPosition - startPosition;
            }
        }

        function animation() {
            setSliderPosition();
            if (isDragging) requestAnimationFrame(animation);
        }

        function setSliderPosition() {
            sliderWrapper.style.transform = `translateX(${currentTranslate}px)`;
        }

        function setPositionByIndex() {
            currentTranslate = currentIndex * -slideWidth;
            prevTranslate = currentTranslate;
            setSliderPosition();
        }

        function updateIndicators() {
            indicators.forEach((indicator, index) => {
                indicator.classList.toggle('active', index === currentIndex);
            });
        }

        updateIndicators();

        return () => {
            sliderWrapper.removeEventListener('touchstart', touchStart);
            sliderWrapper.removeEventListener('touchend', touchEnd);
            sliderWrapper.removeEventListener('touchmove', touchMove);
        };
    }, []);

    //endregion slide-card

    /** endregion */

    /** region Event */

    useEffect(() => {
        const today = new Date();
        // const oneMonthAgo = new Date(today);
        // oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        const oneYearAgo = new Date(today);
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

        const formatDateString = (date) => {
            return date.toISOString().split('T')[0];
        };

        // setStartDate(formatDateString(oneMonthAgo));
        setStartDate(formatDateString(oneYearAgo));
        setEndDate(formatDateString(today));
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            selectOutsales();
        }
    }, [startDate, endDate])

    useEffect(() => {
        if (Object.keys(salesData).length) {
            const purchaseTotal = salesData.cartPrice + salesData.domPrice + salesData.offPrice + salesData.ordPrice + salesData.trPrice + salesData.ttFee;
            const deliveryTotal = salesData.ttFedexPrice + salesData.ttDomPrice + salesData.ttEmsPrice + salesData.ttSeaPrice + salesData.ttEtcPrice + salesData.dpcPrice;
            const chartData = [
                { sDate: "1월", 구매: purchaseTotal, 배송: deliveryTotal },
                { sDate: "2월", 구매: purchaseTotal, 배송: deliveryTotal },
                { sDate: "3월", 구매: purchaseTotal, 배송: deliveryTotal },
                { sDate: "4월", 구매: purchaseTotal, 배송: deliveryTotal },
                { sDate: "5월", 구매: purchaseTotal, 배송: deliveryTotal },
                { sDate: "6월", 구매: purchaseTotal, 배송: deliveryTotal },
                { sDate: "7월", 구매: purchaseTotal, 배송: deliveryTotal },
                { sDate: "8월", 구매: purchaseTotal, 배송: deliveryTotal },
                { sDate: "9월", 구매: purchaseTotal, 배송: deliveryTotal },
                { sDate: "10월", 구매: purchaseTotal, 배송: deliveryTotal },
                { sDate: "11월", 구매: purchaseTotal, 배송: deliveryTotal },
                { sDate: "12월", 구매: purchaseTotal, 배송: deliveryTotal }
            ];

            setSalesChartData(chartData);
        }
    }, [salesData]);

    const selectOutsales = (() => {
        const formattedStartDate = startDate.replace(/-/g, '');
        const formattedEndDate = endDate.replace(/-/g, '');

        axios({
            method: 'get',
            url: '/outsales/selectOutsales',
            params: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            },
        })
            .then((response) => {
                setSalesData(response.data)
                setSalesChartData(response.data)
            })
    })

    /** endregion */

    return (
        <div id="m-a-salesSt-pg-container">
            <div className="m-a-title-frame">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">매출현황</p>
            </div>
            <div className="m-a-card  display-flex flex-column m-b-15">
                <div className="m-a-event-frame">
                    <div className="m-a-header-event display-flex flex-row just-cont-sb">
                        <div className="m-a-header-event-combo">
                            <MAdminYmdSmallEvent startDate={startDate} endDate={endDate} startHandle={startHandle} endHandle={endHandle}/>
                        </div>
                    </div>
                </div>
                <div className="m-a-tb-frame">
                    {/*<ResponsiveContainer width="100%" aspect={5.0}>*/}
                    {/*    <BarChart width={300} height={200} data={salesChartData} margin={{top: 25, right: 20, left: 20, bottom: 10,}}>*/}
                    {/*        <CartesianGrid strokeDasharray="3 3" />*/}
                    {/*        <XAxis dataKey="sDate" />*/}
                    {/*        <YAxis yAxisId="left" orientation="left" stroke="#FFAF34" />*/}
                    {/*        <YAxis yAxisId="right" orientation="right" stroke="#0059FF" />*/}
                    {/*        <Tooltip />*/}
                    {/*        <Legend />*/}
                    {/*        <Bar yAxisId="left" dataKey="구매" fill="#FFAF34" />*/}
                    {/*        <Bar yAxisId="right" dataKey="배송" fill="#91B7FF" />*/}
                    {/*    </BarChart>*/}
                    {/*</ResponsiveContainer>*/}
                </div>
            </div>
            <div className="m-a-main-slider">
                <div className="m-a-slider-wrapper">
                    <div className="m-a-card m-a-card-slider">
                        <div className="m-a-tb-frame">
                            <div style={{display: "flex"}}>
                                <div className="wd-50pc">
                                    <div className="table-title dark-font">
                                        <BsSquareFill className="admin-tb-header-notice"/>
                                        <a>구매</a>
                                    </div>
                                    <div className="wd-100pc p-r-20">
                                        <div style={{
                                            width: "100%",
                                            border: "solid",
                                            borderWidth: "thin 1px",
                                            color: "#F2F2F2",
                                            borderRadius: "4px"
                                        }}>
                                            <div style={{height: "40px", display: "flex"}}>
                                                <div style={{
                                                    width: "50%",
                                                    height: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    backgroundColor: "#F6F6F6"
                                                }}>
                                                    <p style={{margin: "0", fontSize: "13px"}}>장바구니</p>
                                                </div>
                                                <div style={{
                                                    width: "50%",
                                                    height: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                    <p style={{margin: "0"}}>{salesData && salesData?.cartPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                </div>
                                            </div>
                                            <div style={{height: "40px", display: "flex"}}>
                                                <div style={{
                                                    width: "50%",
                                                    height: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    backgroundColor: "#F6F6F6"
                                                }}>
                                                    <p style={{margin: "0", fontSize: "13px"}}>구매대행</p>
                                                </div>
                                                <div style={{
                                                    width: "50%",
                                                    height: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                    <p style={{margin: "0"}}>{salesData && salesData?.ordPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                </div>
                                            </div>
                                            <div style={{height: "40px", display: "flex"}}>
                                                <div style={{
                                                    width: "50%",
                                                    height: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    backgroundColor: "#F6F6F6"
                                                }}>
                                                    <p style={{margin: "0", fontSize: "13px"}}>오프라인</p>
                                                </div>
                                                <div style={{
                                                    width: "50%",
                                                    height: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                    <p style={{margin: "0"}}>{salesData && salesData?.offPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                </div>
                                            </div>
                                            <div style={{height: "40px", display: "flex"}}>
                                                <div style={{
                                                    width: "50%",
                                                    height: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    backgroundColor: "#F6F6F6"
                                                }}>
                                                    <p style={{margin: "0", fontSize: "13px"}}>이체대행</p>
                                                </div>
                                                <div style={{
                                                    width: "50%",
                                                    height: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                    <p style={{margin: "0"}}>{salesData && salesData?.trPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                </div>
                                            </div>
                                            <div style={{height: "40px", display: "flex"}}>
                                                <div style={{
                                                    width: "50%",
                                                    height: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    backgroundColor: "#F6F6F6"
                                                }}>
                                                    <p style={{margin: "0", fontSize: "13px"}}>국내배송</p>
                                                </div>
                                                <div style={{
                                                    width: "50%",
                                                    height: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                    <p style={{margin: "0"}}>{salesData && salesData?.domPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                </div>
                                            </div>
                                            <div style={{height: "40px", display: "flex"}}>
                                                <div style={{
                                                    width: "50%",
                                                    height: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    backgroundColor: "#F6F6F6"
                                                }}>
                                                    <p style={{margin: "0", fontSize: "13px"}}>기타비용</p>
                                                </div>
                                                <div style={{
                                                    width: "50%",
                                                    height: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                    <p style={{margin: "0"}}>{salesData && salesData?.ttEtcPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                </div>
                                            </div>
                                            <div style={{height: "40px", display: "flex"}}>
                                                <div style={{
                                                    width: "50%",
                                                    height: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    backgroundColor: "#F6F6F6"
                                                }}>
                                                    <p style={{margin: "0", fontSize: "13px"}}>총수수료</p>
                                                </div>
                                                <div style={{
                                                    width: "50%",
                                                    height: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                    <p style={{margin: "0"}}>{salesData && salesData?.ttEtcFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="m-a-card m-a-card-slider">
                        <div className="m-a-tb-frame">
                            <div style={{width: "50%"}}>
                                <div className="table-title dark-font">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>배송</a>
                                </div>
                                <div style={{width: "100%", paddingRight: "20px"}}>
                                    <div style={{
                                        width: "100%",
                                        border: "solid",
                                        borderWidth: "thin 1px",
                                        color: "#F2F2F2",
                                        borderRadius: "4px"
                                    }}>
                                        <div style={{height: "40px", display: "flex"}}>
                                            <div style={{
                                                width: "50%",
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                backgroundColor: "#F6F6F6"
                                            }}>
                                                <p style={{margin: "0", fontSize: "13px"}}>Fedex</p>
                                            </div>
                                            <div style={{
                                                width: "50%",
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <p style={{margin: "0"}}>{salesData && salesData?.ttFedexPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                            </div>
                                        </div>
                                        <div style={{height: "40px", display: "flex"}}>
                                            <div style={{
                                                width: "50%",
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                backgroundColor: "#F6F6F6"
                                            }}>
                                                <p style={{margin: "0", fontSize: "13px"}}>EMS</p>
                                            </div>
                                            <div style={{
                                                width: "50%",
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <p style={{margin: "0"}}>{salesData && salesData?.ttEmsPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                            </div>
                                        </div>
                                        <div style={{height: "40px", display: "flex"}}>
                                            <div style={{
                                                width: "50%",
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                backgroundColor: "#F6F6F6"
                                            }}>
                                                <p style={{margin: "0", fontSize: "13px"}}>Sea</p>
                                            </div>
                                            <div style={{
                                                width: "50%",
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <p style={{margin: "0"}}>{salesData && salesData?.ttSeaPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                            </div>
                                        </div>
                                        <div style={{height: "40px", display: "flex"}}>
                                            <div style={{
                                                width: "50%",
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                backgroundColor: "#F6F6F6"
                                            }}>
                                                <p style={{margin: "0", fontSize: "13px"}}>국내택배</p>
                                            </div>
                                            <div style={{
                                                width: "50%",
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <p style={{margin: "0"}}>{salesData && salesData?.ttDomPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                            </div>
                                        </div>
                                        <div style={{height: "40px", display: "flex"}}>
                                            <div style={{
                                                width: "50%",
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                backgroundColor: "#F6F6F6"
                                            }}>
                                                <p style={{margin: "0", fontSize: "13px"}}>예치금사용</p>
                                            </div>
                                            <div style={{
                                                width: "50%",
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <p style={{margin: "0"}}>{salesData && salesData?.useDeposit?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                            </div>
                                        </div>
                                        <div style={{height: "40px", display: "flex"}}>
                                            <div style={{
                                                width: "50%",
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                backgroundColor: "#F6F6F6"
                                            }}>
                                                <p style={{margin: "0", fontSize: "13px"}}>적립금사용</p>
                                            </div>
                                            <div style={{
                                                width: "50%",
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <p style={{margin: "0"}}>{salesData && salesData?.usePoint?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                            </div>
                                        </div>
                                        <div style={{height: "40px", display: "flex"}}>
                                            <div style={{
                                                width: "50%",
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                backgroundColor: "#F6F6F6"
                                            }}>
                                                <p style={{margin: "0", fontSize: "13px"}}>쿠폰사용</p>
                                            </div>
                                            <div style={{
                                                width: "50%",
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <p style={{margin: "0"}}>{salesData && salesData?.useCoupon?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="m-a-indicators">
                    <span className="m-a-indicator"></span>
                    <span className="m-a-indicator"></span>
                </div>
            </div>
        </div>
    );
}

export default MAdminSalesStatusPg
