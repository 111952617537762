import React, {createContext, useCallback, useContext, useEffect, useRef, useState} from "react";
import {createClient} from './WebsocketService';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    const [stompClient, setStompClient] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [subscriptions, setSubscriptions] = useState({});
    const reconnectTimeout = useRef(null);

    // websocketConnect 함수로 연결 관리
    const websocketConnect = async (key) => {
        if (stompClient) return stompClient; // 이미 클라이언트가 있으면 그대로 사용

        return new Promise((resolve, reject) => {
            const client = createClient();
            client.connect(
                { key },
                () => {
                    setStompClient(client);
                    setIsConnected(true);
                    resolve(client);
                },
                (error) => {
                    // 상태 체크하여 추후 사용자에게 메세지 띄우는 것도 고려
                    console.error("웹소켓 연결 오류 발생: ", error);
                    setTimeout(() => {
                        websocketConnect(key); // 일정 시간 후 다시 연결 시도
                    }, 5000);
                    setIsConnected(false);
                    reject(error);
                }
            );
        });
    };

    // 구독 관리
    const subscribe = (destination, callback, headers = {}) => {
        if (subscriptions[destination]) return subscriptions[destination];

        if (stompClient) {
            const subscription = stompClient.subscribe(destination, callback, headers);
            setSubscriptions((prev) => ({ ...prev, [destination]: subscription }));
            return subscription;
        }
    };

    const unsubscribe = (destination) => {
        if (subscriptions[destination]) {
            subscriptions[destination].unsubscribe();
            setSubscriptions((prev) => {
                const newSubscriptions = { ...prev };
                delete newSubscriptions[destination];
                return newSubscriptions;
            });
        }
    };

    // useEffect로 연결 상태를 주기적으로 확인하고, 끊겼을 때 재연결을 시도
    useEffect(() => {
        if (!stompClient) {
            // 재연결 로직: 주기적으로 연결 상태를 확인
            const checkConnection = () => {
                if (!isConnected) {
                    console.log("웹소켓 연결이 끊어졌습니다. 재연결 시도 중...");
                    websocketConnect();
                }
            };

            reconnectTimeout.current = setInterval(checkConnection, 5000); // 5초마다 연결 상태 확인 및 재연결 시도
        }

        return () => {
            if (reconnectTimeout.current) clearInterval(reconnectTimeout.current); // 컴포넌트 언마운트 시 타이머 제거
        };
    }, [stompClient, isConnected]);

    return (
        <WebSocketContext.Provider value={{ stompClient, isConnected, websocketConnect, subscribe, unsubscribe }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => useContext(WebSocketContext);
