import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../../css/mobile/MAdmin.css';
import axios from "axios";
import {connect} from "../../services/WebsocketService";
import commonCheck from "../../assets/img/commonCheck.png";
import commonClose from "../../assets/img/commonClose.png";
import maCateImg from "../../assets/img/m-a-cate.png";
import {MdKeyboardArrowDown} from "react-icons/md";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import IntegDetailPopup from "../../components/web/modal/IntegDetailPopup";
import MAdminPageSmallEvent from "./MAdminPageSmallEvent";
import MAdminSearchEvent from "./MAdminSearchEvent";
import {useWebSocket} from "../../services/WebsocketContext";

function MAdminPaymentsListPg() {

    /** region DataTable */

    const [data, setData] = useState([]);

    /** endregion*/

    /** region Function */

    const LCAT = localStorage.getItem('adminToken');
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState('');
    const [userNm, setUserNm] = useState('');

    const [adminInfo, setAdminInfo] = useState([]);
    const selectAdminInfo = (() => {
        axios({
            method: 'get',
            url: '/member/modiInfo',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data[0])
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        selectAdminInfo();
    }, [])

    const stompClientRef = useRef(null);
    const { subscribe, unsubscribe, websocketConnect, isConnected, stompClient } = useWebSocket();
    useEffect(() => {
        if (!adminInfo && !adminInfo.mbNum) return;
        const initializeSubscription = async () => {
            if (!isConnected || !stompClient) {
                stompClientRef.current = await websocketConnect(adminInfo.mbNum);
            } else {
                stompClientRef.current = stompClient;
            }
        };
        initializeSubscription();
        return () => {
            if (stompClientRef.current && stompClientRef.current.length > 0) {
                // 구독이 존재하는 경우에만 해제
                stompClientRef.current.forEach((sub) => {
                    unsubscribe(sub);
                });
            }
            stompClientRef.current = null;
        };
    }, [adminInfo, isConnected, subscribe, unsubscribe, stompClient]);

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        select();
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [data.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
        if (data.length > displayLimit && displayLimit < 30) {
            setDisplayLimit(prevLimit => prevLimit + perPg);
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e);
        setperPg(newPerPage);
        setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    const [popupVisible, setPopupVisible] = useState(false);

    const togglePopup = () => {
        setPopupVisible(!popupVisible);
    };

    useEffect(() => {
        setDisplayLimit(perPg);
    }, [perPg]);

    // endregion

    //region Alert

    const [alertShow, setAlertShow] = useState(false);
    const [alertShow2, setAlertShow2] = useState(false);
    const msgConfirmData1 = "확인 하시겠습니까?";
    const msgConfirmData2 = "결제승인이 완료됩니다.";
    const msgConfirmData3 = "정말 취소하시겠습니까?";
    const msgConfirmData4 = "결제가 취소처리 됩니다.";
    const modalCancel = useCallback (() => {setAlertShow(false);})
    const modalCancel2 = useCallback(() => {setAlertShow2(false);})
    const alert = (()=> {setAlertShow(true)})
    const alert2 = (() => {setAlertShow2(true)})

    //endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);

    const checkBoxChange = (id) => {
        setChecked((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter((item) => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    const allcheckBoxChange = (e) => {
        let visibleData = data.slice(0, displayLimit);
        if (e.target.checked) {
            const visibleIds = visibleData.map((item) => item.seqNum);
            setChecked(visibleIds);
        } else {
            setChecked([]);
        }
    };

    //endregion

    //region Search

    const [searchInput, setSearchInput] = useState('');
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const searchData = data.filter((cData) => {
        if (cData.orderdtDTO && cData.orderdtDTO.memberDTO && cData.orderdtDTO.memberDTO.userName) {
            return (
                cData.orderdtDTO.memberDTO.userName.toLowerCase().includes(searchInput.toLowerCase()) ||
                (cData.orderdtDTO.orderNumber && cData.orderdtDTO.orderNumber.toString().toLowerCase().includes(searchInput.toLowerCase()))
            );
        }
        return false;
    });


    //endregion

    //region Sort

    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortOrder("asc");
        }
    };

    let sortedData = searchData.map(item => {
        // Date 객체 생성을 위해 regTime과 modiTime 확인
        const regDate = item.regTime ? new Date(item.regTime) : new Date(0);
        const modiDate = item.modiTime ? new Date(item.modiTime) : new Date(0);

        return {
            ...item,
            mostRecentDate: new Date(Math.max(regDate, modiDate)) // 가장최신날짜
        };
    });

    // 조건에 따라 정렬
    sortedData.sort((a, b) => b.mostRecentDate - a.mostRecentDate);
    if (sortColumn) {
        sortedData.sort((a, b) => {
            const aValue = a.orderdtDTO.orderNumber;
            const bValue = b.orderdtDTO.orderNumber;

            if (aValue < bValue) {
                return sortOrder === "asc" ? -1 : 1;
            } else if (aValue > bValue) {
                return sortOrder === "asc" ? 1 : -1;
            } else {
                // // If the values are equal, use secondary sorting column
                // const secondarySortColumn = "payStatus"; // Replace with the actual data field name for the secondary column
                const cValue = a.orderdtDTO.memberDTO.userName;
                const dValue = b.orderdtDTO.memberDTO.userName;

                if (cValue < dValue) {
                    return sortOrder === "asc" ? -1 : 1;
                } else if (cValue > dValue) {
                    return sortOrder === "asc" ? 1 : -1;
                } else {
                    return 0;
                }
            }
        });
    }

    //endregion

    const [confirmPaySt, setConfirmPaySt] = useState(false);
    const confirmPayMsg1 = "입금확인을 진행하시겠습니까?";
    const confirmPayMsg2 = "진행 시 결제완료로 처리됩니다";

    const [cancelPaySt, setCancelPaySt] = useState(false);
    const cancelPayMsg1 = "입금취소를 진행하시겠습니까?";
    const cancelPayMsg2 = "진행 시 결제대기로 처리됩니다";

    const [selectedSeqNum, setSelectedSeqNum] = useState(null);

    const openConfirmPaySt = ((seqNum) => {
        setSelectedSeqNum(seqNum);
        setConfirmPaySt(true);
    })
    const closeConfirmPaySt = (() => {
        setSelectedSeqNum(null);
        setConfirmPaySt(false);
    })

    const openCancelPaySt = ((seqNum) => {
        setSelectedSeqNum(seqNum);
        setCancelPaySt(true);
    })
    const closeCancelPaySt = (() => {
        setSelectedSeqNum(null);
        setCancelPaySt(false);
    })

    const [integPayDetailPopupSt, setIntegPayDetailPopupSt] = useState(false);
    const [integPaySeqnum, setIntegPaySeqnum] = useState(null);
    const integPayDetail = ((seqnum) => {
        setIntegPaySeqnum(seqnum);
        setIntegPayDetailPopupSt(true)
    })
    const integDetailPopupClose = (() => {
        setIntegPayDetailPopupSt(false);
    })
    useEffect(() => {
        if (integPaySeqnum !== null) {
            setIntegPayDetailPopupSt(true);
        }
    }, [integPaySeqnum]);

    const convertToShipco = ((dvType, defalutShipco) => {
        if (dvType === "D00") {
            return "국내배송"
        } else if (dvType === "D01") {
            return "FedEx"
        } else if (dvType === "D02") {
            return "EMS"
        } else if (dvType === "D03") {
            return "SHIP"
        } else if (dvType === "D04") {
            return "DHL"
        } else if (dvType === "D11") {
            return "UPS"
        } else if (dvType === "D13") {
            return "K-Packet"
        } else if (dvType === "D14") {
            return "Small-Packet"
        } else {
            return defalutShipco;
        }
    })

    const [payStPopup, setPayStPopup] = useState(false);

    // const handleOptionClick = (value) => {
    //     if (value === "PS2&PS3&PS4") {
    //         setSelectedPaymentStatus(["PS2", "PS3", "PS4"]);
    //     } else {
    //         setSelectedPaymentStatus(value);
    //     }
    //     setPayStPopup(false);
    // };

    // const getDisplayText = () => {
    //     if (Array.isArray(selectedPaymentStatus)) {
    //         return "결제완료";
    //     }
    //     switch (selectedPaymentStatus) {
    //         case "":
    //             return "결제상태";
    //         case "PS1":
    //             return "결제중";
    //         case "PS5":
    //             return "취소";
    //         default:
    //             return "결제상태";
    //     }
    // };
    const handleOptionClick = (status) => {
        setSelectedPaymentStatus(status);
        setPayStPopup(false);
    };

    const getDisplayText = () => {
        switch (selectedPaymentStatus) {
            case 'PS1':
                return '결제중';
            case 'PS2&PS3&PS4':
                return '결제완료';
            case 'PS5':
                return '취소';
            default:
                return '결제상태';
        }
    };

    /** endregion Function */

    /** region Event */

    useEffect(() => {
        select();
    }, [])

    const select = () => {
        axios({
            method: 'get',
            url: '/order/selectPayinfo',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                const paySt = response.data.filter(cData => cData.payWay !== null && cData.payWay !== '');
                paySt.sort((a, b) => new Date(b.regTime) - new Date(a.regTime));

                if (response.data[0] && response.data[0].memberDTO) {
                    setUserNm(response.data[0].memberDTO.userName);
                }

                setData(paySt);
                setChecked([]);

            })
            .catch(() => {});
    };

    const payDataSucc = (seqNum) => {
        axios({
            method: 'post',
            url: '/order/adminpayconfirm',
            headers: {
                Authorization: LCAT
            },
            params: {
                seqNum: seqNum,
            }
        })
            .then(async () => {
                await stompClient.send("/app/updateOrderList", {}, JSON.stringify({userName: userNm}));
                closeConfirmPaySt()
                select();
            })
            .catch((error) => {
                console.log("입금확인 실패 : ", error)
            });
    };

    const payDataCancel = (seqNum) => {
        axios({
            method: 'post',
            url: '/order/adminpaycencel',
            headers: {
                Authorization: LCAT
            },
            params: {
                seqNum: seqNum
            }
        })
            .then(async () => {
                await stompClient.send("/app/updateOrderList", {}, JSON.stringify({userName: userNm}));
                closeCancelPaySt();
                select();
            })
            .catch((error) => {
                console.log("입금취소 실패 : ", error)
            });
    };

    /** endregion Event */

    return (
        <div id="m-a-payments-pg-container">
            <div className="m-a-title-frame">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">결제내역</p>
            </div>
            <div className="m-a-card display-flex flex-column">
                <div className="m-a-event-frame">
                    <div className="m-a-header-event">
                        <div className="m-a-combo font-t-cont font-c-default wd-55 ht-40 font-s-14 m-a-flex-jc-start m-r-10" onClick={togglePopup}>
                            <div className="font-t-cont">{perPg}</div>
                        </div>
                        {popupVisible && (
                            <MAdminPageSmallEvent perPg={perPg} handleSelectChange={handleSelectChange} togglePopup={togglePopup} />
                        )}
                        <div className="m-a-combo wd-100 ht-40 font-s-15 m-a-flex-jc-start" onClick={() => setPayStPopup(true)}>
                            {getDisplayText()}
                            {payStPopup && (
                                <div className="m-a-page-popup-container" onClick={(e) => { e.stopPropagation(); setPayStPopup(false); }}>
                                    <div className="m-a-page-popup-frame" onClick={(e) => e.stopPropagation()}>
                                        <div className="m-a-page-popup-header-frame">
                                            <div className="font-t-title font-s-18 m-t-4">상태구분</div>
                                        </div>
                                        <div className="m-a-page-popup-body-frame">
                                            <div className={`m-a-card m-t-10 m-b-10 ${selectedPaymentStatus === "" ? "m-a-selected-option" : ""}`}
                                                 onClick={() => handleOptionClick("")}>
                                                결제상태
                                            </div>
                                            <div className={`m-a-card m-t-10 m-b-10 ${selectedPaymentStatus === "PS1" ? "m-a-selected-option" : ""}`}
                                                 onClick={() => handleOptionClick("PS1")}>
                                                결제중
                                            </div>
                                            <div className={`m-a-card m-t-10 m-b-10 ${selectedPaymentStatus === "PS2&PS3&PS4" ? "m-a-selected-option" : ""}`}
                                                 onClick={() => handleOptionClick("PS2&PS3&PS4")}>
                                                결제완료
                                            </div>
                                            <div className={`m-a-card m-t-10 m-b-10 ${selectedPaymentStatus === "PS5" ? "m-a-selected-option" : ""}`}
                                                 onClick={() => handleOptionClick("PS5")}>
                                                취소
                                            </div>
                                        </div>
                                        <div className="m-a-page-popup-footer-frame">
                                            <div className="m-a-file-bt-container">
                                                <button className="m-a-colorBt ht-40 m-b-5 wd-80" onClick={(e) => { e.stopPropagation(); setPayStPopup(false); }}>
                                                    <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="m-a-header-event">
                        <div className="m-a-header-event-search">
                            <MAdminSearchEvent searchInput={searchInput} handleSearchInputChange={handleSearchInputChange} />
                        </div>
                    </div>
                </div>
                <div className="m-a-tb-frame">
                    <div className="m-a-div-tb">
                        <div className="m-a-div-tb-head ht-60">
                            <div className="wd-40">
                                <input className="m-a-admin-input-chkBox m-0-a" type="checkbox" checked={checked.length === data.length}
                                       onChange={allcheckBoxChange}/>
                            </div>
                            <div className="wd-40"><p>번호</p></div>
                            <div className="wd-150">
                                <p>이름</p>
                                {/*{sortColumn === "orderdtDTO.memberDTO.userName" && sortOrder === "asc" && (*/}
                                {/*    <BsCaretUp className="dark-font c-point m-t-5" style={{ display: "inline-block", float: "right" }} />*/}
                                {/*)}*/}
                                {/*{sortColumn === "orderdtDTO.memberDTO.userName" && sortOrder === "desc" && (*/}
                                {/*    <BsCaretDown className="dark-font c-point m-t-5" style={{ display: "inline-block", float: "right" }} />*/}
                                {/*)}*/}
                            </div>
                            <div className="wd-200">
                                <p>주문번호</p>
                                {/*{sortColumn === "orderdtDTO.orderNumber" && sortOrder === "asc" && (*/}
                                {/*    <BsCaretUp className="dark-font c-point m-t-5" style={{ display: "inline-block", float: "right" }} />*/}
                                {/*)}*/}
                                {/*{sortColumn === "orderdtDTO.orderNumber" && sortOrder === "desc" && (*/}
                                {/*    <BsCaretDown className="dark-font c-point m-t-5" style={{ display: "inline-block", float: "right" }} />*/}
                                {/*)}*/}
                            </div>
                            <div className="wd-100"><p>결제타입</p></div>
                            <div className="wd-100"><p>결제수단</p></div>
                            <div className="wd-140"><p>총금액(&#8361;)</p></div>
                            <div className="wd-140"><p>최종금액(&#8361;)</p></div>
                            <div className="wd-80"><p>예치금사용</p></div>
                            <div className="wd-80"><p>적립금사용</p></div>
                            <div className="wd-80"><p>쿠폰사용</p></div>
                            <div className="wd-80"><p>결제상태</p></div>
                            <div className="wd-140"><p>결제일시</p></div>
                            <div className="wd-60"><p>승인</p></div>
                            <div className="wd-60"><p>취소</p></div>
                        </div>
                        {sortedData.filter((cData) => !cData.childFl && cData.payStatus !== "PS0" && selectedPaymentStatus === '' || (Array.isArray(selectedPaymentStatus) ?
                            selectedPaymentStatus.includes(cData.payStatus) : cData.payStatus === selectedPaymentStatus)).slice(0, displayLimit).map((cData, i) => (<div key={i} className="m-a-div-tb-body ht-70">
                                <div className="wd-40">
                                    <input className="m-a-admin-input-chkBox m-0-a" type="checkbox" name={cData.seqNum} checked={checked.includes(cData.seqNum)}
                                           onChange={() => checkBoxChange(cData.seqNum)}/>
                                </div>
                                <div className="wd-40"><p>{i + 1}</p></div>
                                <div className="wd-150"><p>{cData.orderdtDTO.memberDTO.userName}</p></div>
                                <div className="wd-200"><p>{cData.orderdtDTO.orderNumber}</p></div>
                                <div className="user-center wd-100">
                                    <p>{cData.payType === "PT0" ? "상품" : cData.payType === "PT1" ? "배송료" : cData.payType === "PT2" ? "기타" : "통합"}</p>
                                    { cData.payType === "PT3" ?
                                        <button onClick={()=> integPayDetail(cData.seqNum)} className="user-center m-a-commonBt wd-40 ht-25">
                                            <p className="font-c-default">내역</p>
                                        </button>
                                        :
                                        null
                                    }
                                </div>
                                <div className="wd-100">
                                    <p>{cData.payWay  === "PM00" ? "무통장입금" : cData.payWay === "PM01" ? "Paypal" : cData.payWay === "PM02" ? "Wechat" :
                                        cData.payWay === "PM03" ? "Alipay" : cData.payWay === "PM05" ? "예치금결제" : "적립금결제"}</p>
                                </div>
                                <div className="wd-140">
                                    { cData.payType === "PT0" ?
                                        <p>{(cData?.ttProdExpense + cData?.ttEtcFee).toLocaleString()}</p>
                                        :
                                        cData.payType === "PT1" ?
                                            <p>
                                                {
                                                    convertToShipco(cData.dvType) === "FedEx" ? cData.ttFedexPrice.toLocaleString()
                                                        :
                                                        convertToShipco(cData.dvType) === "DHL" ? cData.ttDhlPrice.toLocaleString()
                                                            :
                                                            convertToShipco(cData.dvType) === "EMS" ? cData.ttEmsPrice.toLocaleString()
                                                                :
                                                                convertToShipco(cData.dvType) === "UPS" ? cData.ttUpsPrice.toLocaleString()
                                                                    :
                                                                    convertToShipco(cData.dvType) === "K-Packet" ? cData.ttKPackPrice.toLocaleString()
                                                                        :
                                                                        convertToShipco(cData.dvType) === "Small-Packet" ? cData.ttAirSPrice.toLocaleString()
                                                                            :
                                                                            convertToShipco(cData.dvType) === "SHIP" ? cData.ttSeaPrice.toLocaleString()
                                                                                :
                                                                                convertToShipco(cData.dvType) === "국내배송" ? cData.ttDomPrice.toLocaleString()
                                                                                    :
                                                                                    null
                                                }
                                            </p>
                                            :
                                            cData.payType === "PT2" ?
                                                <p>
                                                    {
                                                        cData.ttEtcFee > 0 ? cData.ttEtcFee.toLocaleString()
                                                            :
                                                            cData.ttEtcPrice > 0 ? cData.ttEtcPrice.toLocaleString()
                                                                :
                                                                null
                                                    }
                                                </p>
                                                :
                                                <p>{(cData?.lastPrice + cData?.depUsePrice + cData?.pointUsePrice + cData?.couponUsePrice).toLocaleString() || 0}</p>
                                    }
                                </div>
                                <div className="user-center wd-140">
                                    <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                        <p>{cData.lastPrice.toLocaleString() || 0}</p>
                                        {cData.lastForPrice > 0 ?
                                            <div className="user-center m-l-3" style={{flexDirection:"row"}}>
                                                <p className="m-0 m-l-3 font-s-11">-</p>
                                                <p className="m-0 m-l-3 font-s-13 font-c-btorage">{cData.lastForPrice}</p>
                                                <p className="m-0 m-l-3 font-s-11">{cData.forCurrency}</p>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                <div className="wd-80"><p>{cData?.depUsePrice?.toLocaleString() || 0}</p></div>
                                <div className="wd-80"><p>{cData?.pointUsePrice?.toLocaleString() || 0}</p></div>
                                <div className="wd-80"><p>{cData?.couponUsePrice?.toLocaleString() || 0}</p></div>
                                <div className="wd-80">
                                    <p style={{color: cData.payStatus === 'PS1' ? "#276AB7" : cData.payStatus === 'PS5' ? "#EE5757" : "#FFAF34"}}>
                                        {cData.payStatus === 'PS1' ? "결제중" : cData.payStatus === 'PS5' ? "취소" : "결제완료"}
                                    </p>
                                </div>
                                <div className="wd-140"><p>{cData.regTime}</p></div>
                                <div className="user-center wd-60">
                                    {cData.payStatus === 'PS1' && (
                                        <img className="c-point wd-30 ht-30 m-a-opc-06" alt="closeImage" src={commonCheck}
                                             onClick={() => openConfirmPaySt(cData.seqNum)}/>)}
                                </div>
                                <div className="user-center wd-60">
                                    {(cData.payStatus === 'PS1' || cData.payStatus === 'PS2' ) && (
                                        <img className="c-point wd-30 ht-30 m-a-opc-07" alt="closeImage" src={commonClose}
                                             onClick={() => openCancelPaySt(cData.seqNum)}/>)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {displayLimit >= 30 && data.length > displayLimit && (
                    <div className="text-center m-t-20">
                        <button className="m-a-commonBt wd-80 ht-30" onClick={loadMoreData}>더보기
                            <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                        </button>
                    </div>
                )}
            </div>
            {alertShow === true ? <MsgSelectModal alertShow={alertShow} eventHandle1={modalCancel} eventHandle2={payDataSucc} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {alertShow2 === true ? <MsgSelectModal alertShow={alertShow2} eventHandle1={modalCancel2} eventHandle2={payDataCancel} msgData1={msgConfirmData3} msgData2={msgConfirmData4}/> : null}

            { confirmPaySt ?
                <MsgSelectModal confirmPaySt={confirmPaySt} confirmPayMsg1={confirmPayMsg1} confirmPayMsg2={confirmPayMsg2}
                                closeConfirmPaySt={closeConfirmPaySt} selectedSeqNum={selectedSeqNum} payDataSucc={payDataSucc}/>
                :
                cancelPaySt ?
                    <MsgSelectModal cancelPaySt={cancelPaySt} cancelPayMsg1={cancelPayMsg1} cancelPayMsg2={cancelPayMsg2}
                                    closeCancelPaySt={closeCancelPaySt} selectedSeqNum={selectedSeqNum} payDataCancel={payDataCancel}/>
                    :
                    integPayDetailPopupSt ?
                        <IntegDetailPopup integDetailPopupClose={integDetailPopupClose} integPaySeqnum={integPaySeqnum}/>
                        :
                        null
            }
        </div>
    );
}

export default MAdminPaymentsListPg
