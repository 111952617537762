import '../../css/mobile/MAddrBookAddPg.css';
import {useTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import backIcon from "../../assets/img/backIcon.svg";

function MAddrBookAddPg({
                            addrBookModifyBackFn,
                            addrBookModifySt,
                            addrBookModifyBackFn2,
                            addrBookAddSt,
                            book,
                            getBookListFn,
                            selectedShip
                        }) {

    const {t} = useTranslation();

    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    const [taxid, setTaxid] = useState("");
    const [recname, setRecname] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [address3, setAddress3] = useState("");
    const [city, setCity] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [telnum, setTelnum] = useState("");

    const [nameInputCount, setNameInputCount] = useState(0);
    const [telnumInputCount, setTelnumInputCount] = useState(0);
    const [zipcodeInputCount, setZipcodeInputCount] = useState(0);
    const [address1InputCount, setAddress1InputCount] = useState(0);
    const [address2InputCount, setAddress2InputCount] = useState(0);
    const [address3InputCount, setAddress3InputCount] = useState(0);
    const [cityInputCount, setCityInputCount] = useState(0);

    const [taxidError, setTaxidError] = useState("");
    const [countryError, setCountryError] = useState("");
    const [recnameError, setRecnameError] = useState("");
    const [address1Error, setAddress1Error] = useState("");
    const [address2Error, setAddress2Error] = useState("");
    const [address3Error, setAddress3Error] = useState("");
    const [cityError, setCityError] = useState("");
    const [zipcodeError, setZipcodeError] = useState("");
    const [telnumError, setTelnumError] = useState("");
    const [defaultCkboxSt, setDefaultCkboxSt] = useState(false);

    const address1Length = selectedShip === "EMS" ? 200 :
        selectedShip === "UPS" ? 200 :
            selectedShip === "Small-Packet" ? 200 :
                selectedShip === "K-Packet" ? 200 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" ? 35 :
                            selectedShip === "SHIP" ? 200 :
                                selectedShip === "국내배송" ? 100 : 35;
    const address2Length = selectedShip === "EMS" ? 50 :
        selectedShip === "UPS" ? 50 :
            selectedShip === "Small-Packet" ? 50 :
                selectedShip === "K-Packet" ? 50 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" ? 35 :
                            selectedShip === "SHIP" ? 50 :
                                selectedShip === "국내배송" ? 100 : 35;
    const address3Length = selectedShip === "EMS" ? 50 :
        selectedShip === "UPS" ? 50 :
            selectedShip === "Small-Packet" ? 50 :
                selectedShip === "K-Packet" ? 50 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" ? 35 :
                            selectedShip === "SHIP" ? 50 :
                                selectedShip === "국내배송" ? 100 : 35;
    const cityLength = selectedShip === "EMS" ? 50 :
        selectedShip === "UPS" ? 50 :
            selectedShip === "Small-Packet" ? 50 :
                selectedShip === "K-Packet" ? 50 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" ? 35 :
                            selectedShip === "SHIP" ? 50 :
                                selectedShip === "국내배송" ? 100 : 35;

    const validTextTaxid = ((e) => {
        const taxidRegex = /^[a-zA-Z0-9\s\-.,]{2,50}$/; /*2~50자*/
        if (!e.target.value || taxidRegex.test(e.target.value)) setTaxidError(false);
        else setTaxidError(true);
        setTaxid(e.target.value);
    })
    const validTextRecname = ((e) => {
        const recnameRegex = /^[a-zA-Z\s]{2,25}/; /*영문 2~25자*/
        if (!e.target.value || recnameRegex.test(e.target.value)) setRecnameError(false);
        else setRecnameError(true);
        setRecname(e.target.value);
    })
    const validTextAddr1 = ((e) => {
        const Addr1Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address1Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr1Regex.test(e.target.value)) setAddress1Error(false);
        else setAddress1Error(true);
        setAddress1(e.target.value);
    })
    const validTextAddr2 = ((e) => {
        const Addr2Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address2Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr2Regex.test(e.target.value)) setAddress2Error(false);
        else setAddress2Error(true);
        setAddress2(e.target.value);
    })
    const validTextAddr3 = ((e) => {
        const Addr3Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address3Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr3Regex.test(e.target.value)) setAddress3Error(false);
        else setAddress3Error(true);
        setAddress3(e.target.value);
    })
    const validTextCity = ((e) => {
        const cityRegex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${cityLength}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || cityRegex.test(e.target.value)) setCityError(false);
        else setCityError(true);
        setCity(e.target.value);
    })
    const validTextZipcode = ((e) => {
        const zipcodeRegex = /^[a-zA-Z\d-\s]{3,10}$/; // 암호화 // 영문 또는 숫자 또는 "-" 로만 가능하게 10자리 이하
        if (!e.target.value || zipcodeRegex.test(e.target.value)) setZipcodeError(false);
        else setZipcodeError(true);
        setZipcode(e.target.value);
    })
    const validTextTelnum = ((e) => {
        const telnumRegex = /^[\d-\s]{5,15}$/; // 암호화 // 숫자 또는 "-" 로만 가능하게 10자리 이하
        if (!e.target.value || telnumRegex.test(e.target.value)) setTelnumError(false);
        else setTelnumError(true);
        setTelnum(e.target.value);
    })

    const nameInputLengthCheck = (e) => {
        setNameInputCount(e.target.value.length);
    };
    const telnumInputLengthCheck = (e) => {
        setTelnumInputCount(e.target.value.length);
    };
    const zipcodeInputLengthCheck = (e) => {
        setZipcodeInputCount(e.target.value.length);
    };
    const address1InputLengthCheck = (e) => {
        setAddress1InputCount(e.target.value.length);
    };
    const address2InputLengthCheck = (e) => {
        setAddress2InputCount(e.target.value.length);
    };
    const address3InputLengthCheck = (e) => {
        setAddress3InputCount(e.target.value.length);
    };
    const cityInputLengthCheck = (e) => {
        setCityInputCount(e.target.value.length);
    };

    const nameInputRTCheck = (e) => {
        setNameInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const telnumInputRTCheck = (e) => {
        setTelnumInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const zipcodeInputRTCheck = (e) => {
        setZipcodeInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const address1InputRTCheck = (e) => {
        setAddress1InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const address2InputRTCheck = (e) => {
        setAddress2InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const address3InputRTCheck = (e) => {
        setAddress3InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const cityInputRTCheck = (e) => {
        setCityInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const nameValid = (e) => {
        validTextRecname(e)
        nameInputLengthCheck(e);
        nameInputRTCheck(e)
    }
    const telnumValid = (e) => {
        validTextTelnum(e)
        telnumInputLengthCheck(e);
        telnumInputRTCheck(e)
    }
    const zipcodeValid = (e) => {
        validTextZipcode(e)
        zipcodeInputLengthCheck(e);
        zipcodeInputRTCheck(e)
    }
    const address1Valid = (e) => {
        validTextAddr1(e)
        address1InputLengthCheck(e);
        address1InputRTCheck(e)
    }
    const address2Valid = (e) => {
        validTextAddr2(e)
        address2InputLengthCheck(e);
        address2InputRTCheck(e)
    }
    const address3Valid = (e) => {
        validTextAddr3(e)
        address3InputLengthCheck(e);
        address3InputRTCheck(e)
    }
    const cityValid = (e) => {
        validTextCity(e)
        cityInputLengthCheck(e);
        cityInputRTCheck(e)
    }


    const validation = () => {
        let isValue = true;
        if (!selectedCountry.value || selectedCountry.value === t(`ordCrLabel`)) {
            setCountryError(true);
            isValue = false;
        }
        if (selectedCountry.value === "ID" && !taxid) {
            setTaxidError(true);
            isValue = false;
        }
        if (!recname) {
            setRecnameError(true);
            isValue = false;
        }
        if (!address1) {
            setAddress1Error(true);
            isValue = false;
        }
        if (selectedCountry.value !== "KR" && !city) {
            setCityError(true);
            isValue = false;
        }
        if (selectedCountry.value !== "KR" && !zipcode) {
            setZipcodeError(true);
            isValue = false;
        }
        if (!telnum) {
            setTelnumError(true);
            isValue = false;
        }
        return isValue;
    }

    const errorAndSave = (seqnum) => {
        if (validation()) {
            if (seqnum !== undefined) {
                addrBookModify()
            } else {
                addrBookSave();
            }
        }
    }

    const defaultCkbox = (e) => {
        if (e.target.checked) {
            setDefaultCkboxSt(true);
        } else (
            setDefaultCkboxSt(false)
        )
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (addrBookModifySt === true) {
                setTaxid(book.taxid)
                setRecname(book.recname);
                const countryData = {
                    value: book.countryDTO.countryCode,
                    label: book.countryDTO.countryNm + '(' + book.countryDTO.countryEngNm + ')'
                };
                setSelectedCountry(countryData);
                setAddress1(book.address1);
                setAddress2(book.address2);
                setAddress3(book.address3);
                setCity(book.city);
                setZipcode(book.zipcode);
                setTelnum(book.telnum);
                setDefaultCkboxSt(book.defaultAddrFl)
            }
        })
        return () => {
            clearTimeout(timer);
        }
    }, [])

    // 여기서부터 국가 커스텀셀렉트
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({value: '', label: t(`ordCrLabel`)});
    const [countryShow, setCountryShow] = useState(false);
    const countryRef = useRef(null);

    const toggleCountryShow = () => {
        setCountryShow(!countryShow);
    };

    const handleCountryChange = (country) => {
        setSelectedCountry(country);
        setCountryShow(false);
        setCountryError(false);
    };


    useEffect(() => {
        function handleClickOutside(event) {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setCountryShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트

    const selectAllCountry = (() => {
        axios({
            method: 'get',
            url: '/order/selectCountry',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                // const countries = response.data.map(country => ({
                //     value: country.countryCode,
                //     label: country.countryNm + '(' + country.countryEngNm + ')'
                // }));
                // setCountryList(countries);
                const countries = response.data
                    .map(country => ({
                        value: country.countryCode,
                        label: `${country.countryNm}(${country.countryEngNm})`,
                        engName: country.countryEngNm
                    }))
                    .sort((a, b) => a.engName.localeCompare(b.engName));

                setCountryList(countries.map(country => ({
                    value: country.value,
                    label: country.label
                })));
            })
            .catch((error) => {
                console.log(error)
            })
    })

    useEffect(() => {
        selectAllCountry();
    }, [])

    const addrBookSave = (() => {
        axios({
            method: 'post',
            url: '/member/addrBookSave',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                addrBookAddSt: addrBookAddSt,
            },
            data: {
                crCode: selectedCountry.value,
                taxid: taxid,
                recname: recname,
                address1: address1,
                address2: address2,
                address3: address3,
                city: city,
                zipcode: zipcode,
                telnum: telnum,
                defaultAddrFl: defaultCkboxSt,
            },
        })
            .then(() => {
                addrBookModifyBackFn2()
                getBookListFn()
            })
    })

    const addrBookModify = (() => {
        axios({
            method: 'post',
            url: '/member/addrBookSave',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                bookSeqnum: book.seqnum
            },
            data: {
                crCode: selectedCountry.value,
                taxid: taxid,
                recname: recname,
                address1: address1,
                address2: address2,
                address3: address3,
                city: city,
                zipcode: zipcode,
                telnum: telnum,
                defaultAddrFl: defaultCkboxSt,
            },
        })
            .then(() => {
                addrBookModifyBackFn()
                getBookListFn()
                console.log("성공")
            })
    })


    const MAddrAddInput = (isEssential,
                           title,
                           value,
                           maxLength,
                           ref,
                           onChange,
                           showLength,
                           length,
                           lengthLimit) => {
        return (
            <div className={'m-a-addr-add-input-box font-t-cont'}>
                {value.length > 0 && <div className={'m-a-addr-add-input-title'}>
                    {isEssential && <div className={'m-a-addr-add-input-essential'}>* &nbsp;</div>}
                    {title}
                </div>}
                {value.length === 0 && isEssential && <div className={'m-a-addr-add-input-essential'}>* &nbsp;</div>}
                <input className={'m-a-addr-add-input'} type="text"
                       value={value} maxLength={maxLength}
                       ref={ref}
                       placeholder={value.length === 0 ? title : ''}
                       onChange={onChange}/>
                {showLength &&
                    <div className={'m-a-addr-add-input-text'}>
                        <div className={'m-a-addr-add-input-text highlight'}> {length}</div>
                        / {lengthLimit}
                    </div>
                }
            </div>
        )
    }

    return (
        <div className={'m-a-addr-book-add-background'}>
            <div className='m-a-addr-book-add-background'>
                <div className='m-a-addr-book-add-container'>
                    {addrBookModifySt ?
                        <div className='m-a-addr-book-add-header'>
                            <img className={'m-a-addr-book-add-header-back'} src={backIcon} alt={''}
                                 onClick={addrBookModifyBackFn}/>
                            <div className='m-a-addr-book-add-title'>{t(`abModiBt`)}</div>
                        </div>
                        : addrBookAddSt ?
                            <div className='m-a-addr-book-add-header'>
                                <img className={'m-a-addr-book-add-header-back'} src={backIcon} alt={''}
                                     onClick={addrBookModifyBackFn2}/>
                                <div className='m-a-addr-book-add-title'>{t(`abAddBt`)}</div>
                            </div>
                            : null
                    }
                    <div className={'m-a-addr-book-add-divider'}/>
                    <div className={'m-a-addr-book-add-content'}>
                        <div className={'m-a-addr-book-add-country-select'} ref={countryRef}
                             onClick={toggleCountryShow}>
                            {selectedCountry.label}
                            {countryShow && (
                                <div className={'m-a-addr-book-add-country-list'}>
                                    {countryList.map((country, index) => (
                                        <div className={'m-a-addr-book-add-country-item'} key={index}
                                             onClick={() => handleCountryChange(country)}>
                                            {country.label}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        {countryError &&
                            <div className="m-a-addr-book-add-error">{t(`recCrErr`)}</div>
                        }
                        {selectedCountry.value === "ID" ?
                            <>
                                {MAddrAddInput(true,
                                    'Tax ID',
                                    taxid,
                                    5,
                                    null,
                                    validTextTaxid,
                                    false, 0, 0)}
                            </>
                            : null}
                        {taxidError &&
                            <div className="m-a-addr-book-add-error">{t(`recTaxIdErr`)}</div>
                        }
                        {MAddrAddInput(true,
                            t(`joinLabel5`),
                            recname,
                            25,
                            null,
                            nameValid,
                            selectedCountry.value !== "KR", nameInputCount, 25)}
                        {selectedCountry.value !== "KR" && recnameError &&
                            <div className="m-a-addr-book-add-error">{t(`recNmErr`)}</div>
                        }
                        {MAddrAddInput(true,
                            selectedCountry.value === "KR" ? t(`modSmallMenu14`) : t(`modSmallMenu7`),
                            address1,
                            selectedCountry.value !== "KR" ? address1Length : null,
                            null,
                            address1Valid,
                            selectedCountry.value !== "KR", address1InputCount, address1Length)}
                        {selectedCountry.value !== "KR" && address1Error &&
                            <div className="m-a-addr-book-add-error">
                                {`${t("recAddrErr1")} 2~${address1Length}${t("recAddrErr2")}`}
                            </div>
                        }
                        {selectedCountry.value !== "KR" && (
                            <>
                                {/*주소2*/}
                                {MAddrAddInput(true,
                                    t(`modSmallMenu8`),
                                    address2,
                                    address2Length,
                                    null,
                                    address2Valid,
                                    true, address2InputCount, address2Length)}
                                {address2Error &&
                                    <div className="m-a-addr-book-add-error">
                                        {`${t("recAddrErr1")} 2~${address2Length}${t("recAddrErr2")}`}
                                    </div>
                                }

                                {/*주소3*/}
                                {MAddrAddInput(true,
                                    t(`modSmallMenu9`),
                                    address3,
                                    address3Length,
                                    null,
                                    address3Valid,
                                    true, address3InputCount, address3Length)}
                                {address3Error &&
                                    <div className="m-a-addr-book-add-error">
                                        {`${t("recAddrErr1")} 2~${address3Length}${t("recAddrErr2")}`}
                                    </div>
                                }

                                {/*도시명*/}
                                {MAddrAddInput(true,
                                    t(`modSmallMenu10`),
                                    city,
                                    cityLength,
                                    null,
                                    cityValid,
                                    true, cityInputCount, cityLength)}
                                {cityError &&
                                    <div className="m-a-addr-book-add-error">
                                        {`${t("recAddrErr1")} 2~${cityLength}${t("recAddrErr2")}`}
                                    </div>
                                }

                                {/*우편번호*/}
                                {MAddrAddInput(true,
                                    t(`modNbInfo8`),
                                    zipcode,
                                    10,
                                    null,
                                    zipcodeValid,
                                    true, zipcodeInputCount, 10)}
                                {zipcodeError &&
                                    <div className="m-a-addr-book-add-error">
                                        {t("recZipErr")}
                                    </div>
                                }
                            </>
                        )}
                        {/*연락처*/}
                        {MAddrAddInput(true,
                            t(`modNbInfo5`),
                            telnum,
                            15,
                            null,
                            telnumValid,
                            selectedCountry.value !== "KR", telnumInputCount, 15)}
                        {selectedCountry.value !== "KR" && telnumError &&
                            <div className="m-a-addr-book-add-error">
                                {t("recTellErr")}
                            </div>
                        }
                        <div className={'m-a-addr-book-add-default'}>
                            <input id="defaultAddrCkbox" type="checkbox"
                                   checked={defaultCkboxSt} onChange={defaultCkbox}
                                   className={'m-a-addr-book-add-default-input'}/>
                            {t(`ordDfAddr`)}
                        </div>
                    </div>
                    <div className={'m-a-addr-book-add-pg-bottom'}>
                        <div className={'m-a-addr-book-add-pg-button'}
                             onClick={() => addrBookAddSt === true ? errorAndSave() : errorAndSave(book.seqnum)}>
                            {t(`saveBt`)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MAddrBookAddPg;
