import React, {useState} from "react";

function MSelectPopup() {

    const propDataHeader = "임시헤더"
    const propDataList = ["옵션1", "옵션2", "옵션3", "옵션4", "옵션5", "옵션6", "옵션7", "옵션8", "옵션9"]

    return(
        <div className="m-a-common-popup-container">
            <div className="m-a-common-popup-frame" onClick={(e) => e.stopPropagation()}>
                <div className="m-a-common-popup-header-frame">
                    <div className="m-a-common-popup-header-text font-t-cont">{propDataHeader}</div>
                </div>
                <div className="m-a-common-popup-body-frame">
                    {propDataList.map((op, i) => (
                        <div key={i} className="m-a-common-popup-body-data">
                            {op}
                        </div>
                    ))}
                </div>
                <div className="m-a-common-popup-footer-frame">
                    <div className="m-a-file-bt-container">
                        <button className="m-a-colorBt ht-40 m-b-5 wd-80">
                            <div className="m-a-colorBt-text font-t-cont">Close</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MSelectPopup
