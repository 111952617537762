import React, {useEffect, useState} from "react";
import axios from "axios";
import {useTranslation} from "react-i18next";
import commonClose from "../../assets/img/commonClose.png";
import "../../css/mobile/MEtcPriceDetail.css";
import commonHether from "../../assets/img/commonHether.png";
import discount from "../../assets/img/discount.png";
import etcExpense from "../../assets/img/etcExpenseColored.png";

function MEtcPriceDetail({closeDetail, paySeqnum}) {
    const {t} = useTranslation();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    const [payList, setPayList] = useState([{}]);


    const selectPayinfoDetail = () => axios({
        method: 'get',
        url: '/order/selectTotalPayInfo/',
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
        params: {
            seqNum: paySeqnum
        },
    })
        .then((response) => {
            setPayList(response.data)
        })
    useEffect(() => {
        const timer = setTimeout(() => {
            selectPayinfoDetail();
        })
        return () => {
            clearTimeout(timer);
        }
    }, [])


    return (
        <div className={'m-a-etc-price-detail-background'}>
            <div className="m-a-etc-price-detail-container">

                <div className={'m-a-etc-price-detail-header'}>
                    <div className={'m-a-etc-price-detail-header-title'}>{t(`epdHeader1`)}</div>
                    <img className={'m-a-etc-price-detail-header-icon'}
                         onClick={closeDetail} src={commonClose} alt={''}/>
                </div>

                {/*상세바디*/}
                <div className={'m-a-etc-price-detail-body'}>
                    <div className={'m-a-etc-price-detail-table-title'}>
                        <img className={'m-a-etc-price-detail-table-icon'} src={commonHether} alt={''}/>
                        {t(`epdHeader2`)}
                    </div>
                    <div className={'m-a-etc-price-detail-info-box'}>
                        <div className={'m-a-etc-price-detail-info-row'}>
                            <div className={'m-a-etc-price-detail-info-text'}>{t(`epdHeader3`)}</div>
                            <div className={'m-a-etc-price-detail-info-text'}>
                                {payList[0]?.courierPay?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                            </div>
                        </div>
                        <div className={'m-a-etc-price-detail-info-row'}>
                            <div className={'m-a-etc-price-detail-info-text'}>{t(`epdHeader4`)}</div>
                            <div className={'m-a-etc-price-detail-info-text'}>
                                {payList[0]?.returnFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                            </div>
                        </div>
                        <div className={'m-a-etc-price-detail-info-row'}>
                            <div className={'m-a-etc-price-detail-info-text'}>
                                {t(`epdHeader5`)} (
                                {payList[0]?.etcPriceNm || ''}
                                )
                            </div>
                            <div className={'m-a-etc-price-detail-info-text'}>
                                {payList[0]?.etcPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                            </div>
                        </div>

                        <div className={'m-a-etc-price-detail-divider'}/>

                        <div className={'m-a-etc-price-detail-info-row'}>
                            <div className={'m-a-etc-price-detail-info-text'}>{t(`epdHeader6`)}</div>
                            <div className={'m-a-etc-price-detail-info-text'}>
                                {payList[0]?.repackFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                            </div>
                        </div>
                        <div className={'m-a-etc-price-detail-info-row'}>
                            <div className={'m-a-etc-price-detail-info-text'}>{t(`epdHeader7`)}</div>
                            <div className={'m-a-etc-price-detail-info-text'}>
                                {payList[0]?.comprFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                            </div>
                        </div>
                        <div className={'m-a-etc-price-detail-info-row'}>
                            <div className={'m-a-etc-price-detail-info-text'}>{t(`epdHeader8`)}</div>
                            <div className={'m-a-etc-price-detail-info-text'}>
                                {payList[0]?.agencyFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                            </div>
                        </div>
                        <div className={'m-a-etc-price-detail-info-row'}>
                            <div className={'m-a-etc-price-detail-info-text'}>
                                {t(`epdHeader9`)}({payList[0]?.etcFeeNm || ''})
                            </div>
                            <div className={'m-a-etc-price-detail-info-text'}>
                                {payList[0]?.etcFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                            </div>
                        </div>
                    </div>

                    <div className={'m-a-etc-price-detail-divider-bold'}/>

                    <div className={'m-a-etc-price-detail-table-title'}>
                        <img className={'m-a-etc-price-detail-table-icon'} src={commonHether} alt={''}/>
                        {t(`modHeader10`)}
                    </div>
                    <div className={'m-a-etc-price-detail-info-box'}>
                        <img className={'m-a-etc-price-detail-info-icon'} src={etcExpense} alt={''}/>
                        <div className={'m-a-etc-price-detail-divider'}/>
                        <div className={'m-a-etc-price-detail-info-row'}>
                            <div className={'m-a-etc-price-detail-info-text'}>{t(`modSHeader9`)}</div>
                            <div className={'m-a-etc-price-detail-info-text'}>
                                {(payList[0]?.ttEtcFee + payList[0]?.ttEtcPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </div>
                        </div>

                        <img className={'m-a-etc-price-detail-info-icon'} src={discount} alt={''}/>
                        <div className={'m-a-etc-price-detail-divider'}/>
                        <div className={'m-a-etc-price-detail-info-row'}>
                            <div className={'m-a-etc-price-detail-info-text'}>{t(`myUseDep`)}</div>
                            <div className={'m-a-etc-price-detail-info-text'}>
                                {payList[0]?.depUsePrice !== 0 ?
                                    <span>- {payList[0]?.depUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : 0}
                            </div>
                        </div>
                        <div className={'m-a-etc-price-detail-info-row'}>
                            <div className={'m-a-etc-price-detail-info-text'}>
                                {t(`myUsePt`)}
                            </div>
                            <div className={'m-a-etc-price-detail-info-text'}>
                                {payList[0]?.pointUsePrice !== 0 ?
                                    <span>- {payList[0]?.pointUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : 0}
                            </div>
                        </div>
                        <div className={'m-a-etc-price-detail-info-row'}>
                            <div className={'m-a-etc-price-detail-info-text'}>
                                {t(`modSHeader13`)}({payList[0].couponNm})
                            </div>
                            <div className={'m-a-etc-price-detail-info-text'}>
                                {payList[0]?.couponUsePrice !== 0 ?
                                    <span>- {payList[0]?.couponUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : 0}
                            </div>
                        </div>
                        <div className={'m-a-etc-price-detail-divider'}/>
                        <div className={'m-a-etc-price-detail-info-row margin-top'}>
                            <div className={'m-a-etc-price-detail-info-text bold'}>
                                {t(`modFinalPay`)}
                            </div>
                            <div className={'m-a-etc-price-detail-info-text bold primary'}>
                                {payList[0]?.payStatus === "PS0" ? 0 : payList[0]?.lastPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MEtcPriceDetail;
