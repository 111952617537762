import React, {useEffect, useState} from "react";
import {AiOutlineClose, AiOutlineDoubleRight} from "react-icons/ai";
import axios from "axios";
import {useTranslation} from "react-i18next";
import commonClose from '../../../assets/img/commonClose.png'



function CouponUse({useCouponSt, closeUseCoupon, couponUseFn}) {
    const { t } = useTranslation();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');
    const [couponList, setCouponList] = useState([{}]);


    const [selectedCoupon, setSelectedCoupon] = useState(null);




    const selectCoupon = () => axios({
        method: 'get',
        url: '/coupon/selectCoupon/',
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
    })
        .then((response) => {
            console.log(response.data)
            const activeCoupons = response.data.filter(cp => cp.activeFl === true);
            setCouponList(activeCoupons);
        })

    useEffect(() => {
        const timer = setTimeout(() => {
            selectCoupon();
        })
        return () => {
            clearTimeout(timer);
        }
    }, [])

    const deadlineDate = (deadline) => {
        return deadline.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    }





    return(
        <div className="bookContainer" style={{display: "flex"}}>


            <div className="bookFrame" style={{
                width: "500px",
                height: "600px",
                top: "calc(25vh - 80px)",
                left: "calc(50vw - 240px)",
                borderRadius: "5px"
            }}>

                <div style={{width: "100%", height: "100%"}}>
                    {/*헤더*/}
                    <div style={{height: "40px"}}>
                        <div style={{
                            height: "100%",
                            fontSize: "23px",
                            border: "solid",
                            borderWidth: "thin 1px",
                            color: "#EAEAEA",
                            display: "flex"
                        }}>

                            <div style={{width: "92%", textAlign: "center", marginLeft: "55px"}}>
                                <p style={{
                                    color: "dimgrey",
                                    fontSize: "16px",
                                    width: "100%",
                                    marginTop: "4px"
                                }}>{t(`cpuBt`)}</p>
                            </div>
                            <div style={{width: "8%", textAlign: "center", cursor:"pointer"}}>
                                <img onClick={closeUseCoupon} style={{width:"25px", height:"25px", opacity:"0.6"}} src={commonClose} alt="close"/>
                            </div>

                        </div>
                    </div>

                    {/*상세바디*/}
                    <div style={{height: "94%"}}>

                        <div style={{
                            border: "solid",
                            margin: "40px 50px 20px 50px",
                            borderWidth: "thin 1px",
                            borderColor: "#EAEAEA",
                            borderRadius: "6px"
                        }}>
                            <div style={{
                                borderBottom: "solid",
                                borderWidth: "thin 1px",
                                borderColor: "#EAEAEA",
                                width: "100%",
                                height: "40px",
                                display: "flex",
                                backgroundColor: "rgba(198,193,193,0.22)"
                            }}>
                                <span style={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft:"10px",
                                    fontSize: "14px"
                                }}><AiOutlineDoubleRight/></span>
                                <span style={{
                                    height: "100%",
                                    fontSize: "14px",
                                    display: "flex",
                                    fontWeight: "bold",
                                    alignItems:"center",
                                    marginLeft:"5px",
                                    paddingBottom:"3px"
                                }}>{t(`cpuHeader1`)}</span>
                            </div>

                            <div style={{
                                height: "40px",
                                display: "flex",
                                alignItems:"center",
                                borderBottom: "solid",
                                borderWidth: "thin 1px",
                                borderColor: "#EAEAEA",
                            }}>
                                <div className="user-center" style={{width: "10%", height:"100%"}}><p style={{margin:"0", fontSize:"12px"}}></p></div>
                                <div className="user-center" style={{width: "45%", height:"100%"}}><p style={{margin:"0", fontSize:"12px"}}>{t(`cpuSMenu1`)}</p></div>
                                <div className="user-center" style={{width: "20%", height:"100%"}}><p style={{margin:"0", fontSize:"12px"}}>{t(`cpuSMenu2`)}</p></div>
                                <div className="user-center" style={{width: "25%", height:"100%"}}><p style={{margin:"0", fontSize:"12px"}}>{t(`cpuSMenu3`)}</p></div>
                            </div>


                            {/*추후 여기에 해당 금액 관련한 쿠폰데이터들 .map으로 가져오기*/}
                            {couponList.map((cp, index) => (
                            <div key={index} style={{height: "40px", display: "flex"}}>
                                <div style={{
                                    width: "10%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "13px"
                                }}><input type="radio" name="useCoupon" onChange={() => setSelectedCoupon(cp)}/>
                                </div>
                                <div style={{width: "45%", display: "flex", alignItems: "center", fontSize: "12px"}}>
                                    <span style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{cp && cp.couponMstDTO && cp.couponMstDTO.couponNm}</span>
                                </div>
                                <div style={{
                                    width: "20%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "13px"
                                }}><span>{cp && cp.couponMstDTO && cp.couponMstDTO.couponPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></div>
                                <div style={{
                                    width: "25%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "13px"
                                }}>
                                    <span style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                                    ~ {cp?.couponMstDTO?.couponExpDate ? deadlineDate(cp?.couponMstDTO?.couponExpDate) : null}
                                </span>
                                </div>
                            </div>
                                ))}
                        </div>



                        <div style={{margin: "0 50px 40px 50px", textAlign:"center"}}>
                            <button className="ReviewPgWriteBtn" style={{
                                width: "70px",
                                height: "35px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderWidth: "thin 1px",
                                borderRadius: "4px",
                                marginLeft: "5px"
                            }} onClick={closeUseCoupon}>{t(`cpuCcBt`)}
                                <div className="dot"></div>
                            </button>
                            <button className="orderItemHandleBt" style={{width: "70px",height: "35px",fontSize: "12px",fontWeight: "bold"}} onClick={()=> couponUseFn(selectedCoupon)}>
                                {t(`cpuBt`)}
                            </button>
                        </div>



                    </div>
                </div>


            </div>


        </div>
    )
}

export default CouponUse
