import React, {useEffect, useState} from "react";
import axios from "axios";
import '../../../css/web/AdminNewRegModal.css';
import orderIMG from "../../../assets/img/mypgPayinfo.png";
import {AiOutlineMinusSquare, AiOutlinePlusSquare} from "react-icons/ai";
import {IoIosArrowDropdown} from "@react-icons/all-files/io/IoIosArrowDropdown";
import {IoIosArrowDropright} from "@react-icons/all-files/io/IoIosArrowDropright";

function AdminNewRegModal() {

    /** region dataSet */

    const storedOrderData = localStorage.getItem('orderData');
    const initialOrderData = storedOrderData ? JSON.parse(storedOrderData) : null;
    const [ordData, setOrdData] = useState(initialOrderData);

    const [shipCoList, setShipCoList] = useState([]);
    const [selectedShipCo, setSelectedShipCo] = useState(ordData.shipco);

    // region Token

    const LCAT = localStorage.getItem('adminToken');

    // endregion

    // region 배송사별 기본요금

    const [fedexExData, setFedexExData] = useState([]);
    const [emsExData, setEmsExData] = useState([]);
    const [seaExData, setSeaExData] = useState([]);
    const [dhlExData, setDhlExData] = useState([]);
    const [emspExData, setEmspExData] = useState([]);
    const [kpExData, setKpExData] = useState([]);
    const [asExData, setAsExData] = useState([]);

    // endregion

    // region 배송사별 수수료

    const [fedexFee, setFedexFee] = useState(null);
    const [fedexFuelSurcharge, setFedexFuelSurcharge] = useState(null);
    const [emsFee, setEmsFee] = useState(null);
    const [seaPFee, setSeaFee] = useState(null);
    const [emsPFee, setEmsPFee] = useState(null);
    const [kpFee, setKpFee] = useState(null);
    const [asFee, setAsFee] = useState(null);

    // endregion

    /** endregion dataTable */

    /** region Function*/

    const [showCostInputs, setShowCostInputs] = useState(false);
    const [showItemInputs, setShowItemInputs] = useState(false);
    const [showBoxInputs, setShowBoxInputs] = useState(true);

    /** endregion */

    /** region Event */

    // region 주문데이터조회

    useEffect(() => {
        const storedData = localStorage.getItem('ordData');

        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setOrdData(parsedData);
            setSelectedShipCo(parsedData.shipco);
            localStorage.removeItem('ordData');
        } else {
            console.error('data select err');
        }
    }, []);

    // endregion

    // region 배송사별비용조회

    useEffect(() => {
        fedexExCost(ordData.recinfoDTO && ordData.recinfoDTO.countryDTO.seqNum);
        emsExCost(ordData.recinfoDTO && ordData.recinfoDTO.countryDTO.seqNum);
        seaExrCost();
        emspExCost();
        kpExCost(ordData.recinfoDTO && ordData.recinfoDTO.countryDTO.seqNum);
        asExCost();
    }, [ordData]);

    const fedexExCost = (countrySeqNum) => {
        console.log("fedex전달:", countrySeqNum)
        axios.get(`/shipinfo/fedexExCost`, {
            params: {
                countrySeqNum: countrySeqNum
            }
        })
            .then((response) => {
                setFedexExData(response.data);
            })
            .catch((error) => {
                console.error("FedEx금액:", error);
            });
    };

    const emsExCost = (countrySeqNum) => {
        console.log("ems전달:", countrySeqNum)
        axios.get(`/shipinfo/emsExCost`, {
            params: {
                countrySeqNum: countrySeqNum
            }
        })
            .then((response) => {
                setEmsExData(response.data);
            })
            .catch((error) => {
                console.error("EMS금액:", error);
            });
    };

    const seaExrCost = () => {
        axios.get('/shipinfo/shipexrdata')
            .then((response) => {
                const countryCode = ordData.type === 'orderdt'
                    ? ordData.recinfoDTO.countryDTO.countryCode
                    : ordData.type === 'payinfo'
                        ? ordData.appRecDTO.countryDTO.countryCode
                        : null;

                if (!countryCode) return;

                const sCountryCode = response.data.filter(cData => {
                    return cData.appShipCode === countryCode ||
                        (['CN', 'HK', 'JP', 'TW'].includes(countryCode) && cData.appShipCode === 'Z1') ||
                        (['BD', 'ID', 'MY', 'PH', 'SG', 'VN'].includes(countryCode) && cData.appShipCode === 'Z2') ||
                        (['AU', 'CA', 'DE', 'ES', 'FR', 'GB', 'IE', 'NL', 'NZ', 'PL', 'US'].includes(countryCode) && cData.appShipCode === 'Z3') ||
                        (['PE', 'CL', 'MX'].includes(countryCode) && cData.appShipCode === 'Z4');
                });
                setSeaExData(sCountryCode);
            })
            .catch((error) => {
                console.error("SEAError:", error);
            });
    }

    const emspExCost = () => {
        axios.get('/shipinfo/upsexrdata')
            .then((response) => {
                const countryCode = ordData.type === 'orderdt'
                    ? ordData.recinfoDTO.countryDTO.countryCode
                    : ordData.type === 'payinfo'
                        ? ordData.appRecDTO.countryDTO.countryCode
                        : null;

                if (!countryCode) return;

                const sCountryCode = response.data.filter(cData => {
                    return cData.appEmsPCode === countryCode ||
                        (["CN", "HK", "MO", "TW"].includes(countryCode) && cData.appEmsPCode === 'Z1') ||
                        (["BN", "KH", "JP", "LA", "MY", "SG", "TH", "VN"].includes(countryCode) && cData.appEmsPCode === 'Z2') ||
                        (["AL", "AM", "AU", "AT", "AZ", "BH", "BE", "BG", "BA", "CA", "HR", "CZ", "DK", "GR",
                            "HU", "IS", "IN", "ID", "IE", "IT", "LV", "LU", "MV", "MD", "MN", "NL", "NZ", "MK", "NO",
                            "PG", "PH", "PL", "PT", "RO", "SK", "SI", "ES", "SE", "CH", "TR", "US", "AE", "GB", "BY", "BT"
                        ].includes(countryCode) && cData.appEmsPCode === 'Z3') ||
                        (["DZ", "AO", "BS", "BB", "BJ", "BM", "BO", "BW", "BF", "CM", "CV", "TD", "CL", "CO",
                            "CR", "CI", "DJ", "EG", "ER", "ET", "FJ", "GT", "GY", "HT", "HN", "SV", "IL", "JM", "JO",
                            "KW", "LS", "LR", "ML", "MX", "MZ", "NI", "NE", "NG", "OM", "PK", "PA", "PY", "QA", "RW", "TZ", "TG", "ZM"
                        ].includes(countryCode) && cData.appEmsPCode === 'Z4') ||
                        (["RU"].includes(countryCode) && cData.appEmsPCode === 'ZR') ||
                        (["AF", "AD", "AG", "AR", "AW", "BD", "BZ", "BR", "BI", "CF", "CG", "DM", "DO", "TL", "GA",
                            "GM", "GH", "GI", "GL", "GD", "GP", "GU", "GF", "GN", "GW", "IQ", "KZ", "KE", "SZ", "KG",
                            "LB", "LI", "LT", "MG", "MW", "MT", "MP", "MQ", "MR", "MU", "YT", "FM", "MC", "MS", "MA",
                            "NA", "NP", "NC", "NF", "PW", "PE", "ZA", "RE", "ME", "KN", "LC", "VC", "AS", "SM", "SA",
                            "SN", "SC", "SL", "LK", "SR", "RS", "TO", "TN", "UG", "UA", "UY", "UZ", "VU", "VE", "VI", "VG", "WF", "WS", "ZW"
                        ].includes(countryCode) && cData.appEmsPCode === 'Z5');
                });
                setEmspExData(sCountryCode);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const kpExCost = (countrySeqNum) => {
        axios.get(`/shipinfo/kpExCost`, {
            params: {
                countrySeqNum: countrySeqNum
            }
        })
            .then((response) => {
                setKpExData(response.data);
            })
            .catch((error) => {
                console.error("K-Packet금액:", error);
            });
    };

    const asExCost = () => {
        axios.get('/shipinfo/airsexrdata')
            .then((response) => {
                const countryCode = ordData.type === 'orderdt'
                    ? ordData.recinfoDTO.countryDTO.countryCode
                    : ordData.type === 'payinfo'
                        ? ordData.appRecDTO.countryDTO.countryCode
                        : null;

                if (!countryCode) return;

                const aCountryCode = response.data.filter(cData => {
                    return cData.appAsCode === countryCode ||
                        (["CN", "HK", "JP", "MO", "TW"].includes(countryCode) && cData.appAsCode === 'Z1') ||
                        (["BD", "BN", "KH", "TL", "ID", "LA", "MY", "MN", "MM", "PH", "SG", "TW", "VN"].includes(countryCode) && cData.appAsCode === 'Z2') ||
                        (["AF", "AL", "AD", "AM", "AU", "AT", "AZ", "BH", "BY", "BE", "BT", "BG", "BA",
                            "CA", "CX", "CC", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "GE", "DE", "GI", "GR",
                            "GL", "GU", "HU", "IS", "IL", "IT", "JO", "KZ", "KW", "KG", "LV", "LB", "LI", "LT",
                            "LU", "MV", "MT", "MP", "MD", "MC", "NP", "NL", "NZ", "NU", "NF", "MK", "NO", "OM",
                            "PK", "PG", "PL", "PT", "QA", "RO", "RU", "ME", "SM", "SA", "SK", "SI", "ES", "LK",
                            "SE", "CH", "RS", "TR", "US", "UA", "AE", "GB", "UZ", "VA"
                        ].includes(countryCode) && cData.appAsCode === 'Z3') ||
                        (["DZ", "AO", "AG", "AR", "AW", "BS", "BB", "BZ", "BJ", "BM", "BW", "BR", "BF", "BI",
                            "CM", "CV", "CF", "TD", "CL", "CO", "CG", "CR", "CI", "CU", "DJ", "DM", "DO", "CG", "EG",
                            "GQ", "ER", "ET", "FJ", "FR", "GA", "GM", "GH", "GD", "GP", "GT", "GF", "GN", "GW", "GY",
                            "HN", "JM", "KE", "SZ", "LS", "LR", "MG", "MW", "ML", "MQ", "MR", "MU", "YT", "MX", "FM",
                            "MS", "MA", "MZ", "NA", "NR", "NC", "NI", "NE", "NG", "PW", "PE", "ZA", "RE", "RW", "KN",
                            "AS", "ST", "SN", "SC", "SB", "SO", "SD", "SR", "TZ", "TG", "TO", "TN", "UG", "UY", "VU",
                            "VE", "VI", "VG", "WF", "WS", "ZM", "ZW",
                        ].includes(countryCode) && cData.appAsCode === 'Z4');
                });
                setAsExData(aCountryCode);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    // endregion

    // region 배송사별수수료조회

    useEffect(() => {
        if (ordData && ordData.recinfoDTO && ordData.recinfoDTO.countryDTO) {
            setAsFee(ordData.recinfoDTO.countryDTO.airSFee);
            setEmsFee(ordData.recinfoDTO.countryDTO.emsFee);
            setSeaFee(ordData.recinfoDTO.countryDTO.seaFee);
            setEmsPFee(ordData.recinfoDTO.countryDTO.emsPFee);
            setFedexFee(ordData.recinfoDTO.countryDTO.fedexFee);
            setFedexFuelSurcharge(ordData.recinfoDTO.countryDTO.fedexFuelSurcharge);
            setKpFee(ordData.recinfoDTO.countryDTO.kpackFee);
        }
    }, [ordData]);

    //endregion

    // region 배송사조회

    const bringToShipCo = () => axios({
        method: 'get',
        url: '/shipcompany/bringToShipCo/' + "1",
        headers: {
            Authorization: LCAT,
        },
    })
        .then((response) => {
            setShipCoList(response.data)
        })

    useEffect(() => {
        bringToShipCo();
    }, [])


    // endregion

    /** endregion*/

    // console.log(ordData)
    // console.log("Fedex:", fedexExData)
    // console.log("ems:", emsExData)
    // console.log("sea:", seaExData)
    // console.log("ups:", emspExData)
    // console.log("kp:", kpExData)
    // console.log("as:", asExData)

    return(
        <div className="AdminNewRegModal font-t-cont">
            <div className="an-header-frame p-l-15 p-t-15">
                <p className="font-t-title font-s-16">비용등록</p>
            </div>
            <div className="an-ord-info-frame">
                <div className="ht-100pc display-flex flex-row just-cont-sb">
                    <div className="display-flex flex-column wd-50pc gap6">
                        <div className="display-flex flex-row">
                            <img className="wd-20 ht-20 m-t-3 m-r-5" alt="" src={orderIMG}/>
                            <p className="font-b font-s-18">{ordData.orderNumber ? ordData.orderNumber : (ordData.orderdtDTO ? ordData.orderdtDTO.orderNumber : '')} - B0008</p>
                        </div>
                        <div className="display-flex flex-row">
                            <p className="font-s-16 wd-23pc">고객명:</p>
                            <p className="font-s-16 wd-auto">{ordData.memberDTO ? ordData.memberDTO.userName : (ordData.orderdtDTO ? ordData.orderdtDTO.memberDTO.userName : '')}</p>
                        </div>
                        <div className="display-flex flex-row">
                            <p className="font-s-16 wd-23pc">수취인명:</p>
                            <p className="font-s-16 font-b">{ordData.memberDTO ? ordData.recinfoDTO.recname : (ordData.orderdtDTO ? ordData.orderdtDTO.recinfoDTO.recname : '')}</p>
                        </div>
                    </div>
                    <div className="display-flex flex-column just-cont-fend wd-50pc gap6 p-b-8">
                        <div className="display-flex flex-row">
                            <p className="font-s-16 wd-21pc">국가:</p>
                            <p className="font-s-16 font-b">
                                {ordData.recinfoDTO && ordData.recinfoDTO.countryDTO
                                    ? ordData.recinfoDTO.countryDTO.countryNm + "[" + ordData.recinfoDTO.countryDTO.countryCode + "]"
                                    : (ordData.appRecDTO && ordData.appRecDTO.countryDTO
                                        ? ordData.appRecDTO.countryDTO.countryNm + "[" + ordData.appRecDTO.countryDTO.countryCode + "]"
                                        : '')
                                }
                            </p>
                        </div>
                        <div className="display-flex flex-row">
                            <p className="font-s-16 wd-21pc">배송사:</p>
                            <select className="admin-combo wd-100 ht-25" value={selectedShipCo}
                                    onChange={(e) => {
                                        setSelectedShipCo(e.target.value);
                                        let shipCompanyCode;
                                        switch (e.target.value) {
                                            case "국내배송":
                                                shipCompanyCode = "D00";
                                                break;
                                            case "FedEx":
                                                shipCompanyCode = "D01";
                                                break;
                                            case "UPS":
                                                shipCompanyCode = "D11";
                                                break;
                                            case "EMS":
                                                shipCompanyCode = "D02";
                                                break;
                                            case "SHIP":
                                                shipCompanyCode = "D03";
                                                break;
                                            // case "DHL":
                                            //     shipCompanyCode = "D04";
                                            //     break;
                                            case "K-Packet":
                                                shipCompanyCode = "D13";
                                                break;
                                            case "항공소형":
                                                shipCompanyCode = "D14";
                                                break;
                                            default:
                                                shipCompanyCode = "";
                                        }
                                    }}>
                                {shipCoList.map((shipCo, index) => (
                                    <option key={index} value={shipCo}>
                                        {shipCo}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="an-cont-frame">
                <div className="m-a-open-bar p-l-15 p-r-10 m-b-15" onClick={() => setShowCostInputs(!showCostInputs)}>
                    <p className="font-t-title font-s-14 c-def">비용입력</p>
                    {showCostInputs ?
                        <i className="m-a-ord-accordion-icon" style={{transform: "rotate(90deg)"}}/> :
                        <i className="m-a-ord-accordion-icon"></i>
                    }
                </div>
                {showCostInputs &&
                    <div>d</div>
                }
                <div className="m-a-open-bar p-l-15 p-r-10 m-b-15" onClick={() => setShowItemInputs(!showItemInputs)}>
                    <p className="font-t-title font-s-14 c-def">상품할당</p>
                    {showItemInputs ?
                        <i className="m-a-ord-accordion-icon" style={{transform: "rotate(90deg)"}}/> :
                        <i className="m-a-ord-accordion-icon"></i>
                    }
                </div>
                {showItemInputs && (
                    <div className="m-a-top-border-card wd-100pc font-t-cont">
                        <div className="m-a-tb-frame-b ht-350">
                            <div className="m-a-div-tb">
                                <div className="m-a-div-tb-head-b ht-40 font-s-13">
                                    <div className="wd-10pc">
                                        <input className="m-a-admin-input-chkBox m-0-a" type="checkbox"/>
                                    </div>
                                    <div className="wd-15pc"><p>번호</p></div>
                                    <div className="wd-35pc"><p>품명/송장</p></div>
                                    <div className="wd-20pc"><p>상태구분</p></div>
                                    <div className="wd-20pc"><p>박스번호</p></div>
                                </div>
                                {ordData.productsDTO?.sort((a,b) => a.seqnum - b.seqnum).map((item, i) => (
                                    <div key={i} className="m-a-div-tb-body-b ht-50">
                                        <div className="wd-10pc">
                                            <input className="m-a-admin-input-chkBox m-0-a" type="checkbox"/>
                                        </div>
                                        <div className="wd-15pc font-s-14"><p>{i + 1}</p></div>
                                        <div className="wd-35pc font-s-12"><p>{item?.name}</p></div>
                                        <div className="wd-20pc"><a className="font-b font-s-13">{item?.prodSt}</a></div>
                                        <div className="wd-20pc align-center">
                                            {item?.prodSt === "IS05" && (
                                                <div className="m-a-combo wd-100pc ht-30 font-s-12 m-a-flex-jc-start">
                                                    Box {1}
                                                    {/*{boxPopup === i && (*/}
                                                    <div className="m-a-page-popup-container" >
                                                        <div className="m-a-page-popup-frame" onClick={(e) => e.stopPropagation()}>
                                                            <div className="m-a-page-popup-header-frame">
                                                                <div className="font-t-title font-s-18 m-t-4">박스선택</div>
                                                            </div>
                                                            <div className="m-a-page-popup-body-frame">
                                                                {/*{boxData.map((box, j) => (*/}
                                                                {/*    <div key={j}*/}
                                                                {/*         className={`m-a-card m-t-10 m-b-10 ${selectedBoxIndex[i] === j ? "m-a-selected-option" : ""}`}*/}
                                                                {/*         onClick={() => handleBoxSelection(i, j)}>*/}
                                                                {/*        <p>Box {j + 1}</p>*/}
                                                                {/*    </div>*/}
                                                                {/*))}*/}
                                                            </div>
                                                            <div className="m-a-page-popup-footer-frame">
                                                                <div className="m-a-file-bt-container">
                                                                    <button className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                        <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*)}*/}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                <div className="m-a-open-bar p-l-15 p-r-10">
                    <p className="font-t-title font-s-14 c-def">박스정보</p>
                    <div>
                        <AiOutlineMinusSquare className="font-c-default font-s-28 c-point btorage-hover-exp btorage-hover m-r-5"/>
                        <AiOutlinePlusSquare className="font-c-default font-s-28 c-point btorage-hover-exp btorage-hover"/>
                    </div>
                </div>
                {showBoxInputs && (
                    <div className="m-a-top-border-card display-flex flex-column wd-100pc">
                        <div className="display-flex flex-row just-cont-sb font-s-14 wd-100pc text-center gap7">
                            <p className="wd-20pc c-def">가로(cm)</p>
                            <p className="wd-20pc c-def">세로(cm)</p>
                            <p className="wd-20pc c-def">높이(cm)</p>
                            <p className="wd-20pc c-def">실무게(kg)</p>
                        </div>
                        <div className="display-flex flex-row just-cont-sb wd-100pc gap7">
                            <input className="admin-input-line font-b font-c-default wd-20pc ht-35 text-center font-s-16 com-input" inputMode="numeric" pattern="\d*"
                                   minLength="1" maxLength="3" type="text" placeholder="0"/>
                            <input className="admin-input-line font-b font-c-default wd-20pc ht-35 text-center font-s-16 com-input" inputMode="numeric" pattern="\d*"
                                   minLength="1" maxLength="3" type="text" placeholder="0"/>
                            <input className="admin-input-line font-b font-c-default wd-20pc ht-35 text-center font-s-16 com-input" inputMode="numeric" pattern="\d*"
                                   minLength="1" maxLength="3" type="text" placeholder="0"/>
                            <input className="admin-input-line font-b font-c-default wd-20pc ht-35 text-center font-s-16 com-input" inputMode="numeric" pattern="\d*"
                                   minLength="1" maxLength="5" type="text " placeholder="0.00"/>
                        </div>
                        <div className="display-flex flex-column m-t-7 m-b-25">
                            <div className="display-flex flex-row just-cont-sb">
                                <div><p className="font-s-14 font-c-btorage c-def">Box {1}</p></div>
                                <div className="display-flex flex-row text-active c-point">
                                    <p className="font-s-14">상세비용보기</p>
                                    {/*{showBoxDtPrice[index] ?*/}
                                        <IoIosArrowDropdown className="font-s-18 font-c-default"/> :
                                        <IoIosArrowDropright className="font-s-18 font-c-default"/>
                                    {/*}*/}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="an-footer-frame">
                {ordData.type === 'orderdt' && (
                    <footer>
                        <div>
                            <button className="admin-bt-color admin-btn-long">닫기</button>
                            <button className="admin-bt-color-B admin-btn-long">등록완료</button>
                        </div>
                        <div></div>
                    </footer>
                )}
                {ordData.type === 'payinfo' && (
                    <footer>
                        <div>
                            <button className="admin-bt-color admin-btn-long">닫기</button>
                            <button className="admin-bt-color-B admin-btn-long">저장</button>
                        </div>
                    </footer>
                )}
            </div>
        </div>
    )
}

export default AdminNewRegModal
