import React, {useEffect, useRef, useState} from "react";
import { HiReceiptTax } from "react-icons/hi";
import {BsArrowLeft, BsFillPersonCheckFill, BsMailbox2} from "react-icons/bs";
import {TbAddressBook} from "react-icons/tb";
import {MdPhoneIphone} from "react-icons/md";
import axios from "axios";
import UserAddrBook from "./UserAddrBook";
import {BiCaretDown} from "react-icons/bi";
import {useTranslation} from "react-i18next";

function RecmodiModal({recmodiFalseFn, recinfoSeqnum, setAddressUpdated, setUpdatedRecipientInfo, recmodiCloseFn, selectedShip}) {
    const { t } = useTranslation();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    const [taxid, setTaxid] = useState("");
    const [recname, setRecname] = useState("");
    const [country, setCountry] = useState("");
    const [countryLabel, setCountryLabel] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [address3, setAddress3] = useState("");
    const [city, setCity] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [telnum, setTelnum] = useState("");

    const [countryError, setCountryError] = useState("");
    const [taxidError, setTaxidError] = useState("");
    const [recnameError, setRecnameError] = useState("");
    const [address1Error, setAddress1Error] = useState("");
    const [address2Error, setAddress2Error] = useState("");
    const [address3Error, setAddress3Error] = useState("");
    const [cityError, setCityError] = useState("");
    const [zipcodeError, setZipcodeError] = useState("");
    const [telnumError, setTelnumError] = useState("");

    const [nameInputCount, setNameInputCount] = useState(0);
    const [telnumInputCount, setTelnumInputCount] = useState(0);
    const [zipcodeInputCount, setZipcodeInputCount] = useState(0);
    const [address1InputCount, setAddress1InputCount] = useState(0);
    const [address2InputCount, setAddress2InputCount] = useState(0);
    const [address3InputCount, setAddress3InputCount] = useState(0);
    const [cityInputCount, setCityInputCount] = useState(0);


    // 여기서부터 국가 커스텀셀렉트
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({value: '', label: t(`ordCrLabel`)});
    const [countryShow, setCountryShow] = useState(false);
    const countryRef = useRef(null);

    const toggleCountryShow = () => {
        setCountryShow(!countryShow);
    };

    const handleCountryChange = (country) => {
        setSelectedCountry(country);
        setCountryShow(false);
        setCountryError(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setCountryShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트


    const selectAllCountry = (() => {
        axios({
            method: 'get',
            url: '/order/selectCountry',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                // const countries = response.data.map(country => ({
                //     value: country.countryCode,
                //     label: country.countryNm + '(' + country.countryEngNm + ')'
                // }));
                // setCountryList(countries);
                const countries = response.data
                    .map(country => ({
                        value: country.countryCode,
                        label: `${country.countryNm}(${country.countryEngNm})`,
                        engName: country.countryEngNm
                    }))
                    .sort((a, b) => a.engName.localeCompare(b.engName));

                setCountryList(countries.map(country => ({
                    value: country.value,
                    label: country.label
                })));
            })
            .catch((error) => {
                console.log(error)
            })
    })

    useEffect(() => {
        selectAllCountry();
    }, [])


    const address1Length = selectedShip === "EMS" ? 200 :
        selectedShip === "UPS" ? 200 :
            selectedShip === "Small-Packet" ? 200 :
                selectedShip === "K-Packet" ? 200 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" ? 35 :
                            selectedShip === "SHIP" ? 200 :
                                selectedShip === "국내배송" ? 100 : 35;
    const address2Length = selectedShip === "EMS" ? 50 :
        selectedShip === "UPS" ? 50 :
            selectedShip === "Small-Packet" ? 50 :
                selectedShip === "K-Packet" ? 50 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" ? 35 :
                            selectedShip === "SHIP" ? 50 :
                                selectedShip === "국내배송" ? 100 : 35;
    const address3Length = selectedShip === "EMS" ? 50 :
        selectedShip === "UPS" ? 50 :
            selectedShip === "Small-Packet" ? 50 :
                selectedShip === "K-Packet" ? 50 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" ? 35 :
                            selectedShip === "SHIP" ? 50 :
                                selectedShip === "국내배송" ? 100 : 35;
    const cityLength = selectedShip === "EMS" ? 50 :
        selectedShip === "UPS" ? 50 :
            selectedShip === "Small-Packet" ? 50 :
                selectedShip === "K-Packet" ? 50 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" ? 35 :
                            selectedShip === "SHIP" ? 50 :
                                selectedShip === "국내배송" ? 100 : 35;

    const validTextTaxid = ((e)=> {
        const taxidRegex = /^[a-zA-Z0-9\s\-\.,]{2,50}$/; /*2~50자*/
        if (!e.target.value || taxidRegex.test(e.target.value)) setTaxidError(false);
        else setTaxidError(true);
        setTaxid(e.target.value);
    })
    const validTextRecname = ((e)=> {
        const recnameRegex = /^[a-zA-Z\s]{2,25}/; /*영문 2~25자*/
        if (!e.target.value || recnameRegex.test(e.target.value)) setRecnameError(false);
        else setRecnameError(true);
        setRecname(e.target.value);
    })
    const validTextAddr1 = ((e)=> {
        const Addr1Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address1Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr1Regex.test(e.target.value)) setAddress1Error(false);
        else setAddress1Error(true);
        setAddress1(e.target.value);
    })
    const validTextAddr2 = ((e)=> {
        const Addr2Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address2Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr2Regex.test(e.target.value)) setAddress2Error(false);
        else setAddress2Error(true);
        setAddress2(e.target.value);
    })
    const validTextAddr3 = ((e)=> {
        const Addr3Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address3Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr3Regex.test(e.target.value)) setAddress3Error(false);
        else setAddress3Error(true);
        setAddress3(e.target.value);
    })
    const validTextCity = ((e)=> {
        const cityRegex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${cityLength}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || cityRegex.test(e.target.value)) setCityError(false);
        else setCityError(true);
        setCity(e.target.value);
    })
    const validTextZipcode = ((e)=> {
        const zipcodeRegex = /^[a-zA-Z\d-\s]{3,10}$/; // 암호화 // 영문 또는 숫자 또는 "-" 로만 가능하게 10자리 이하
        if (!e.target.value || zipcodeRegex.test(e.target.value)) setZipcodeError(false);
        else setZipcodeError(true);
        setZipcode(e.target.value);
    })
    const validTextTelnum = ((e)=> {
        const telnumRegex = /^[\d-\s]{5,15}$/; // 암호화 // 숫자 또는 "-" 로만 가능하게 10자리 이하
        if (!e.target.value || telnumRegex.test(e.target.value)) setTelnumError(false);
        else setTelnumError(true);
        setTelnum(e.target.value);
    })

    const nameInputLengthCheck = (e) => {
        setNameInputCount(e.target.value.length);
    };
    const telnumInputLengthCheck = (e) => {
        setTelnumInputCount(e.target.value.length);
    };
    const zipcodeInputLengthCheck = (e) => {
        setZipcodeInputCount(e.target.value.length);
    };
    const address1InputLengthCheck = (e) => {
        setAddress1InputCount(e.target.value.length);
    };
    const address2InputLengthCheck = (e) => {
        setAddress2InputCount(e.target.value.length);
    };
    const address3InputLengthCheck = (e) => {
        setAddress3InputCount(e.target.value.length);
    };
    const cityInputLengthCheck = (e) => {
        setCityInputCount(e.target.value.length);
    };

    const nameInputRTCheck = (e) => {
        setNameInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const telnumInputRTCheck = (e) => {
        setTelnumInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const zipcodeInputRTCheck = (e) => {
        setZipcodeInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const address1InputRTCheck = (e) => {
        setAddress1InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const address2InputRTCheck = (e) => {
        setAddress2InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const address3InputRTCheck = (e) => {
        setAddress3InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const cityInputRTCheck = (e) => {
        setCityInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const nameValid = (e) => {
        validTextRecname(e)
        nameInputLengthCheck(e);
        nameInputRTCheck(e)
    }
    const telnumValid = (e) => {
        validTextTelnum(e)
        telnumInputLengthCheck(e);
        telnumInputRTCheck(e)
    }
    const zipcodeValid = (e) => {
        validTextZipcode(e)
        zipcodeInputLengthCheck(e);
        zipcodeInputRTCheck(e)
    }
    const address1Valid = (e) => {
        validTextAddr1(e)
        address1InputLengthCheck(e);
        address1InputRTCheck(e)
    }
    const address2Valid = (e) => {
        validTextAddr2(e)
        address2InputLengthCheck(e);
        address2InputRTCheck(e)
    }
    const address3Valid = (e) => {
        validTextAddr3(e)
        address3InputLengthCheck(e);
        address3InputRTCheck(e)
    }
    const cityValid = (e) => {
        validTextCity(e)
        cityInputLengthCheck(e);
        cityInputRTCheck(e)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            selectRecinfoData();
        })
        return () => {
            clearTimeout(timer);
        }
    }, [])

    const selectRecinfoData = (() => {
        axios({
            method: 'get',
            url: '/order/selectRecinfoData',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                recinfoSeqnum: recinfoSeqnum
            },
        })
            .then((response) => {
                const countryData = {
                    value: response.data.countryDTO.countryCode,
                    label: response.data.countryDTO.countryNm + '(' + response.data.countryDTO.countryEngNm + ')'
                };
                setSelectedCountry(countryData);
                setTaxid(response.data.taxid);
                setRecname(response.data.recname);
                setAddress1(response.data.address1);
                setAddress2(response.data.address2);
                setAddress3(response.data.address3);
                setCity(response.data.city);
                setZipcode(response.data.zipcode);
                setTelnum(response.data.telnum);
                setNameInputCount(response.data.recname.length)
                setAddress1InputCount(response.data.address1.length)
                setAddress2InputCount(response.data.address2.length)
                setAddress3InputCount(response.data.address3.length)
                setCityInputCount(response.data.city.length)
                setZipcodeInputCount(response.data.zipcode.length)
                setTelnumInputCount(response.data.telnum.length)
            })
    })

    const [myordBookSt, setMyordBookSt] = useState(false);
    const myordBookListFn = (() => {
        setMyordBookSt(true);
    })
    const myordBookFalseFn = (() => {
        setMyordBookSt(false);
    })

    const addrChoiceData = ((addrSeqnum) => {
        axios({
            method: 'get',
            url: '/member/choiceAddrInfo',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                seqnum: addrSeqnum
            }
        })
            .then((response) => {
                const countryData = {
                    value: response.data.countryDTO.countryCode,
                    label: response.data.countryDTO.countryNm + '(' + response.data.countryDTO.countryEngNm + ')'
                };
                setSelectedCountry(countryData);
                setTaxid(response.data.taxid)
                setRecname(response.data.recname)
                setAddress1(response.data.address1)
                setAddress2(response.data.address2)
                setAddress3(response.data.address3)
                setCity(response.data.city)
                setZipcode(response.data.zipcode)
                setTelnum(response.data.telnum)
                setNameInputCount(response.data.recname.length)
                setAddress1InputCount(response.data.address1.length)
                setAddress2InputCount(response.data.address2.length)
                setAddress3InputCount(response.data.address3.length)
                setCityInputCount(response.data.city.length)
                setZipcodeInputCount(response.data.zipcode.length)
                setTelnumInputCount(response.data.telnum.length)
                myordBookFalseFn();
            })
    })

    const modifyRecinfoData = (() => {
        axios({
            method: 'post',
            url: '/order/modifyRecinfoData',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                recinfoSeqnum: recinfoSeqnum
            },
            data: {
                crCode: selectedCountry.value,
                taxid: taxid,
                recname: recname,
                telnum: telnum,
                zipcode: zipcode,
                address1: address1,
                address2: address2,
                address3: address3,
                city: city
            },
        })
            .then((response) => {
                const updatedInfo = {
                    countryDTO: response.data,
                    taxid: taxid,
                    recname: recname,
                    telnum: telnum,
                    zipcode: zipcode,
                    address1: address1,
                    address2: address2,
                    address3: address3,
                    city: city,
                };
                recmodiFalseFn();
                setUpdatedRecipientInfo(updatedInfo);
            })
    })


    const validation = () => {
        let isValue = true;

        if (selectedCountry.value === "ID" && !taxid) {
            setTaxidError(true);
            isValue = false;
        }
        if (!recname) {
            setRecnameError(true);
            isValue = false;
        }
        if (!address1) {
            setAddress1Error(true);
            isValue = false;
        }
        if (selectedCountry.value !== "KR" && !city) {
            setCityError(true);
            isValue = false;
        }
        if (selectedCountry.value !== "KR" && !zipcode) {
            setZipcodeError(true);
            isValue = false;
        }
        if (!telnum) {
            setTelnumError(true);
            isValue = false;
        }
        if (selectedCountry.value === "" || !selectedCountry.value) {
            setCountryError(true);
            isValue = false;
        }
        return isValue;
    }
    const finalModify = () => {
        if(validation()) {
        setAddressUpdated(prevState => !prevState);
        modifyRecinfoData();
        }
    }

    return(
        <div className="bookContainer">
            <div className="bookFrame" style={{width:"440px"}}>
                <div className="bookFrameHeader">
                    <div className="headerGoBack" onClick={recmodiCloseFn}>
                        <i><BsArrowLeft/></i></div>
                    <div className="headerText font-t-title">{t(`rmmHeader`)}</div>
                </div>
                <div className="bookFrameBody font-t-cont">
                    <div className="modiCardFrame" style={{height: selectedCountry.value === "KR" ? "100%" : null}}>
                        {/*<div className="recInfoRowContainer">*/}
                        <div style={{width:"auto", marginBottom:"20px", display:"flex"}}>
                            {/*여기서부터 커스텀 국가셀렉트*/}
                            <div ref={countryRef} style={{height: "50px", width: "80%", position: "relative"}}>
                                <button className="selectParentBt" style={{width: "100%", height: "100%"}} onClick={toggleCountryShow}>
                                    <p style={{margin: "0", fontSize:"14px"}}>{selectedCountry.label}</p>
                                    <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                        <i style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "100%",
                                            color:"darkgray"
                                        }}><BiCaretDown/></i>
                                    </span>
                                </button>
                                {countryShow ?
                                    <div style={{
                                        width:"100%",
                                        border: "solid",
                                        height: "400px",
                                        borderWidth: "thin 1px",
                                        borderColor: "lightgray",
                                        position: "absolute",
                                        top:"100%",
                                        bottom:"100%",
                                        zIndex:1,
                                        marginTop: "5px",
                                        overflowY: "scroll",
                                    }}>
                                        {countryList.map((country, index) => (
                                            <div className="selectChildBt" key={index}
                                                 onClick={() => handleCountryChange(country)} style={{
                                                display: "flex", padding: "10px", justifyContent: "center",
                                                alignItems: "center", cursor: "pointer", fontSize: "14px", borderRadius: "4px", zIndex:99,
                                            }}>
                                                <p style={{margin:"0", fontSize:"13px"}}>{country.label}</p>
                                            </div>
                                        ))}
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <button className="orderItemHandleBt" onClick={myordBookListFn} style={{width:"100px", height:"50px", padding:"0 10px"}}>{t(`rmmBookBt`)}</button>
                        </div>
                        {countryError ?
                                <div className="JoinErrorMsg">
                                    <p className="JoinErrMsg font-t-cont m-b-4">&nbsp;{t(`recCrErr`)}</p>
                                </div>
                                :
                                null
                        }
                        {selectedCountry.value === "ID" ?
                            <div className="modiCardName" style={{marginBottom: "10px", display: "flex", border:"solid"}}>
                                <div className="acc-input-container acc-input-icon" style={{height: "100%"}}>
                                    <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                        <i style={{fontSize: "28px", margin: "0px 0px 5px 0px"}}><HiReceiptTax/></i>
                                    </div>
                                    <div className="acc-input-form" style={{width: "280px"}}>
                                        <input className="acc-input-inform" type="text" placeholder=" "
                                               style={{width: "98%", height: "100%", paddingLeft: "10px"}} value={taxid}
                                               onChange={validTextTaxid} maxLength={50}/>
                                        <label className="inputLabel"><span
                                            style={{color: "red", fontSize: "18px"}}>*</span> Tax ID</label>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        { taxidError ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg font-t-cont m-b-4">&nbsp;{t(`recTaxIdErr`)}</p>
                            </div>
                            :
                            null
                        }
                        <div className="modiCardName" style={{marginBottom:"10px", display:"flex"}}>
                            <div className="acc-input-container acc-input-icon" style={{width:"100%",height:"100%"}}>
                                <div className="acc-input-lg-icon" style={{width:"15%"}}>
                                    <i style={{fontSize:"28px", margin:"0px 0px 5px 0px"}}><BsFillPersonCheckFill/></i>
                                </div>
                                <div className="acc-input-form" style={{width:"310px"}}>
                                    <input className="acc-input-inform" type="text" placeholder=" " style={{width:"85%", height:"100%", paddingLeft:"10px"}} value={recname} onChange={nameValid} maxLength={25}/>
                                    <label className="inputLabel"><span style={{color:"red", fontSize:"18px"}}>*</span> {t(`joinLabel5`)}</label>
                                    {selectedCountry.value !== "KR" ?
                                        <div style={{position: "absolute", right: "0", top: "30%"}}>
                                            <div className="user-center">
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "13px",
                                                    color: "darkorange"
                                                }}>{nameInputCount}</p>
                                                <p style={{margin: "0", fontSize: "13px"}}>/25</p>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                        {selectedCountry.value !== "KR" && recnameError ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg">&nbsp;{t(`recNmErr`)}</p>
                            </div>
                            :
                            null
                        }
                        <div className="modiCardAddr1" style={{marginBottom:"10px", display:"flex"}}>
                            {/*여기서부터 */}
                            <div className="acc-input-container acc-input-icon" style={{width:"100%", height:"100%"}}>
                                <div className="acc-input-lg-icon" style={{width:"15%"}}>
                                    <i style={{fontSize:"28px", margin:"0px 0px 5px 0px"}}><TbAddressBook/></i>
                                </div>
                                <div className="acc-input-form" style={{width:"310px"}}>
                                    <input className="acc-input-inform" type="text" placeholder=" " style={{
                                        width:"80%", height:"100%", paddingLeft:"10px"}} value={address1} onChange={address1Valid} maxLength={address1Length}/>
                                    <label className="inputLabel"><span style={{color:"red", fontSize:"18px"}}>*</span> {t(`modSmallMenu7`)}</label>
                                    { selectedCountry.value !== "KR" ?
                                        <div style={{position: "absolute", right: "0", top: "30%"}}>
                                            <div className="user-center">
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "13px",
                                                    color: "darkorange"
                                                }}>{address1InputCount}</p>
                                                <p style={{margin: "0", fontSize: "13px"}}>{`/${address1Length}`}</p>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                        { selectedCountry.value !== "KR" && address1Error ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg font-s-14">{`${t("recAddrErr1")} 2~${address1Length}${t("recAddrErr2")}`}</p>
                            </div>
                            :
                            null
                        }
                        { selectedCountry.value !== "KR" ?
                        <div className="modiCardAddr2" style={{marginBottom:"10px", display:"flex"}}>
                            <div className="acc-input-container acc-input-icon" style={{width:"100%", height:"100%"}}>
                                <div className="acc-input-lg-icon" style={{width:"15%"}}>
                                    <i style={{fontSize:"28px", margin:"0px 0px 5px 0px"}}><TbAddressBook/></i>
                                </div>
                                <div className="acc-input-form" style={{width:"310px"}}>
                                    <input className="acc-input-inform" type="text" placeholder=" " style={{
                                        width:"85%", height:"100%", paddingLeft:"10px"}} value={address2} onChange={address2Valid} maxLength={address2Length}/>
                                    <label className="inputLabel">{t(`modSmallMenu8`)}</label>
                                    <div style={{position: "absolute", right: "0", top: "30%"}}>
                                        <div className="user-center">
                                            <p style={{
                                                margin: "0",
                                                fontSize: "13px",
                                                color: "darkorange"
                                            }}>{address2InputCount}</p>
                                            <p style={{margin: "0", fontSize: "13px"}}>{`/${address2Length}`}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            :
                            null
                        }
                        { address2Error ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg font-s-14 m-b-5">{`${t("recAddrErr1")} 2~${address2Length}${t("recAddrErr2")}`}</p>
                            </div>
                            :
                            null
                        }
                        {selectedCountry.value !== "KR" ?
                            <div className="modiCardAddr3" style={{marginBottom: "10px", display: "flex"}}>
                                <div className="acc-input-container acc-input-icon" style={{width:"100%", height: "100%"}}>
                                    <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                        <i style={{fontSize: "28px", margin: "0px 0px 5px 0px"}}><TbAddressBook/></i>
                                    </div>
                                    <div className="acc-input-form" style={{width: "310px"}}>
                                        <input className="acc-input-inform" type="text" placeholder=" " style={{
                                            width: "85%", height: "100%", paddingLeft: "10px"
                                        }} value={address3} onChange={address3Valid} maxLength={address3Length}/>
                                        <label className="inputLabel">{t(`modSmallMenu9`)}</label>
                                        <div style={{position: "absolute", right: "0", top: "30%"}}>
                                            <div className="user-center">
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "13px",
                                                    color: "darkorange"
                                                }}>{address3InputCount}</p>
                                                <p style={{margin: "0", fontSize: "13px"}}>{`/${address3Length}`}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        { address3Error ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg font-s-14 m-b-5">{`${t("recAddrErr1")} 2~${address3Length}${t("recAddrErr2")}`}</p>
                            </div>
                            :
                            null
                        }
                        {selectedCountry.value !== "KR" ?
                            <div className="modiCardCity" style={{marginBottom: "10px", display: "flex"}}>
                                <div className="acc-input-container acc-input-icon" style={{width:"100%", height: "100%"}}>
                                    <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                        <i style={{fontSize: "28px", margin: "0px 0px 5px 0px"}}><TbAddressBook/></i>
                                    </div>
                                    <div className="acc-input-form" style={{width: "310px"}}>
                                        <input className="acc-input-inform" type="text" placeholder=" " style={{
                                            width: "85%", height: "100%", paddingLeft: "10px"
                                        }} value={city} onChange={cityValid} maxLength={cityLength}/>
                                        <label className="inputLabel"><span
                                            style={{color: "red", fontSize: "18px"}}>*</span> {t(`modSmallMenu10`)}
                                        </label>
                                        <div style={{position: "absolute", right: "0", top: "30%"}}>
                                            <div className="user-center">
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "13px",
                                                    color: "darkorange"
                                                }}>{cityInputCount}</p>
                                                <p style={{margin: "0", fontSize: "13px"}}>{`/${cityLength}`}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        { cityError ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg font-s-14 m-b-5">{`${t("recAddrErr1")} 2~${cityLength}${t("recAddrErr2")}`}</p>
                            </div>
                            :
                            null
                        }
                        {selectedCountry.value !== "KR" ?
                            <div className="modiCardZipcode" style={{marginBottom: "10px", display: "flex"}}>
                                <div className="acc-input-container acc-input-icon" style={{width:"100%", height: "100%"}}>
                                    <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                        <i style={{fontSize: "28px", margin: "0px 0px 5px 0px"}}><BsMailbox2/></i>
                                    </div>
                                    <div className="acc-input-form" style={{width: "310px"}}>
                                        <input className="acc-input-inform" type="text" placeholder=" "
                                               style={{width: "85%", height: "100%", paddingLeft: "10px"}}
                                               value={zipcode} onChange={zipcodeValid} maxLength={10}/>
                                        <label className="inputLabel"><span
                                            style={{color: "red", fontSize: "18px"}}>*</span> {t(`modNbInfo8`)}</label>
                                        <div style={{position: "absolute", right: "0", top: "30%"}}>
                                            <div className="user-center">
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "13px",
                                                    color: "darkorange"
                                                }}>{zipcodeInputCount}</p>
                                                <p style={{margin: "0", fontSize: "13px"}}>/10</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        { zipcodeError ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg font-s-14 m-b-5">{t(`recZipErr`)}</p>
                            </div>
                            :
                            null
                        }
                        <div className="modiCardTelnum" style={{marginBottom:"10px", display:"flex"}}>
                            <div className="acc-input-container acc-input-icon" style={{width:"100%", height:"100%"}}>
                                <div className="acc-input-lg-icon" style={{width:"15%"}}>
                                    <i style={{fontSize:"28px", margin:"0px 0px 5px 0px"}}><MdPhoneIphone/></i>
                                </div>
                                <div className="acc-input-form" style={{marginTop:"5px", width:"310px"}}>
                                    <input className="acc-input-inform" type="text" placeholder=" " style={{width:"85%", height:"100%", paddingLeft:"10px"}} value={telnum} onChange={telnumValid} maxLength={15}/>
                                    <label className="inputLabel"><span style={{color:"red", fontSize:"18px"}}>*</span> {t(`modNbInfo5`)}</label>
                                    { selectedCountry.value !== "KR" ?
                                        <div style={{position: "absolute", right: "0", top: "30%"}}>
                                            <div className="user-center">
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "13px",
                                                    color: "darkorange"
                                                }}>{telnumInputCount}</p>
                                                <p style={{margin: "0", fontSize: "13px"}}>/15</p>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                        { selectedCountry.value !== "KR" && telnumError ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg font-t-cont m-b-5">{t(`recTellErr`)}</p>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                <div className="bookFrameFooter user-center" style={{display:"flex", justifyContent:"center", height:"65px", padding:"0 10px"}}>
                    <button className="colorFullBt" style={{width:"100%", height:"45px", textAlign:"center", fontSize:"15px"}} onClick={finalModify}>{t(`rmmBt`)}</button>
                </div>
            </div>
            {myordBookSt ?
            <UserAddrBook myordBookSt={myordBookSt} myordBookFalseFn={myordBookFalseFn} addrChoiceData={addrChoiceData}/>
                :
                null
            }
        </div>
    )
}

export default RecmodiModal
